import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useEffect, useState } from "react";
import {
    Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label, Button, Modal,
    ModalBody,
    ModalHeader,
    InputGroup, Table
} from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
// Formik formikObj
import * as Yup from "yup";
import { useFormik } from "formik";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { standartDateFormat, today, userDateFormat } from "utils/date";
import { axiosInstance, apiUrls } from "api";
import swal from 'sweetalert';
import { graphql } from "api";

// datatable related plugins

import CommonTable from "components/Common/CommonTable";
import usePaginator from "hooks/usePaginator";
import { useSelector, useDispatch } from "react-redux";

import * as BodyCompositionQry from "GraphQLquery/bodycompositionQry";
import * as MemberQry from "GraphQLquery/memberQry";

import { round } from "lodash";
import moment from "moment";

import logo from "assets/images/favicon.svg";
import paid from "assets/images/paid.png";
import { setIsSubmit } from "redux-form";
import { setCreateBodyComposition } from "store/actions";

const initialBodyCompositionData = {
    body_height: "",
    body_weight: "",
    body_fat_percentage: "",
    entry_date: "",
    visceral_fat_percentage: "",
    resting_metabolic_rate: "",
    body_mass_index: "",
    body_age: '',
    next_measurement_day: "",
    member_id: "",
}

let totalRowCount = ''

const AddEditBodyComposition = props => {

    const dispatch = useDispatch();
    const member_id = props.member_id;
    const bodyCompositionModal = props.bodyCompositionModal;
    const redirectPath = props.redirectPath;

    const [bodyCompositionData, setBodyCompositionData] = useState({ ...initialBodyCompositionData })
    const [editBodyComposition, setEditBodyComposition] = useState(false)
    const [isSaving, setIsSaving] = useState(false);
    const [addEditBodyCompositionModal, setAddEditbodyCompositionModal] = useState(false);
    const [viewBodyCompositionModal, setViewBodyCompositionModal] = useState(false);
    const [error, setError] = useState(null);
    const [processingList, setProcessingList] = useState(false);
    const [allBodyCompositionList, setAllBodyCompositionList] = useState([]);
    const [memberBodyCompositionList, setMemberBodyCompositionList] = useState([]);
    const [mainTrainerList, setMainTrainerList] = useState([])
    const [paginatorInfo, setPaginatorInfo, resetPaginatorInfo] = usePaginator();
    const [memberPaginatorInfo, setMemberPaginatorInfo, resetMemberPaginatorInfo] = usePaginator();
    const [searchText, setSearchText] = useState('');
    const [memberSearchText, setMemberSearchText] = useState('');
    const [memberTiming, setMemberTiming] = useState("")
    const [mainTrainer, setMainTrainer] = useState("")
    const [entry_date, setBeginingDate] = useState('');
    const [next_measurement_day, setNextMeasurementDayDate] = useState('');
    const [memberId, setMemberId] = useState("");
    const [isSubmit, setIsSubmit] = useState(true)
    const [allMemberList, setAllMemberList] = useState([]);
    const [offSet, setOffSet] = useState('10')
    const [pageURL, setPageURL] = useState('1')
    const [pageLinks, setPageLinks] = useState([])
    const [actionBtn, setActionBtn] = useState(false)

    useEffect(() => {
        fetchBodyCompositionData(paginatorInfo, searchText, mainTrainer, memberTiming)
        fetchMemberListData()
        fetchMainTrainerList()
    }, [])

    useEffect(() => {
        !!bodyCompositionModal && createBodyComposition()
    }, [bodyCompositionModal])

    const fetchBodyCompositionData = async (paginatorInfo, searchText, main_trainer, timing) => {
        let res
        try {
            setProcessingList(true)
            !!member_id ?
                res = await graphql({
                    query: BodyCompositionQry.FETCH_ALL_BODY_COMPOSITION_QUERY,
                    variables: {
                        ...paginatorInfo,
                        searchText: `%${searchText}%`,
                        member_id: !!member_id ? member_id : '%%'
                    }
                })
                :
                res = await axiosInstance.post(apiUrls.bodyComposition.memberBodyScaleList + "?page=" + pageURL, { offset: offSet, search_text: searchText, main_trainer: main_trainer, timing: timing });

            if (!!res.data.body_compositions && res.data.body_compositions.data) {
                setProcessingList(false)
                setAllBodyCompositionList(!!res.data.body_compositions ? res.data.body_compositions.data : [])
                setPaginatorInfo(res?.data?.body_compositions?.paginatorInfo);
                setSearchText(searchText);
            }
            else if (res.data) {
                setAllBodyCompositionList(!!res?.data?.data?.length > 0 ? res?.data?.data : [])
                setPageLinks(!!res?.data?.data?.length > 0 ? res?.data?.links : [])
                setProcessingList(false)
                totalRowCount = res?.data?.total
            }

            else {
                setProcessingList(false)
                setAllBodyCompositionList([])
                resetPaginatorInfo();
            }
        } catch (error) {
            setProcessingList(false)
            setAllBodyCompositionList([])
            resetPaginatorInfo();
        }
    }

    const fetchMemberListData = async () => {
        let allMember = []
        try {
            const res = await graphql({
                query: MemberQry.FETCH_ALL_MEMBERS_FOR_MEMBERSHIP_QUERY,
                variables: {
                    // ...paginatorInfo,
                    // searchText: `%${searchText}%`,
                    // id: "%%",
                    // status_id: "%%"
                }
            });

            if (res.data.members.data) {
                setAllMemberList(!!res.data.members ? res.data.members.data : [])
                allMember = !!res.data.members ? res.data.members.data : []
            }

            else {
                setAllMemberList([])
                allMember = []
            }
        } catch (error) {
            setAllMemberList([])
            allMember = []
        }
    }

    const fetchMainTrainerList = async () => {
        try {
            const res = await axiosInstance.post(apiUrls.trainer.allMainTrainerList)
            if (res.success == 1) {
                setMainTrainerList(res.data)
            }
            else {
                setMainTrainerList([])
            }
        } catch (error) {
            setMainTrainerList([])
        }
    }

    const handleTableChange = (paginatorInfo, searchText) => {
        fetchBodyCompositionData(paginatorInfo, searchText, mainTrainer, memberTiming)
    }

    const handleMemberTableChange = (memberPaginatorInfo, memberSearchText) => {
        handleMemberBodyComposition(memberPaginatorInfo, memberSearchText)
    }

    const handleOffsetChange = (e) => {
        if (e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text == 'All') {
            let pageURL = "1"
            setPageURL(pageURL)
        }
        setOffSet(e.target.value)
    }

    const getPageNumber = (e, data) => {
        e.preventDefault()
        if (parseInt(data.label)) {
            setPageURL(data.label)
        } else {
            let pageNumber = data.url.split("=")
            setPageURL(pageNumber[1])
        }

    }

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    }

    useEffect(() => {
        !!member_id == false && fetchBodyCompositionData(paginatorInfo, searchText, mainTrainer, memberTiming)
    }, [offSet, pageURL, searchText])

    const handleAction = (cell, row) => {
        localStorage.setItem("bc_id", "")
        localStorage.setItem("bc_member_id", "")
        return (
            <>
                <div className="d-flex">
                    <Link
                        to="/body-composition-view"
                        onClick={(e) => {
                            !!row.id ? localStorage.setItem("bc_id", row.id) : localStorage.setItem("bc_member_id", row.member_id)
                        }}
                        target="_blank"
                        className="btn btn-warning btn-sm ms-2">
                        <i className="fas fa-eye align-middle font-size-16" />
                    </Link>
                    <Button className="btn-info btn-sm ms-2" onClick={(e) => { handleFetch(e, row, true) }}>
                        <i className="bx bx-pencil align-middle" />
                    </Button>
                    {!!member_id == true &&
                        <Button className="btn-danger btn-sm ms-2" onClick={(e) => { handleDelete(e, row) }}>
                            <i className="bx bx-trash align-middle" />
                        </Button>
                    }
                </div>
            </>
        );
    };

    const handleBeginDate = (cell, row) => {
        return (
            <>
                {userDateFormat(row.member_details?.membership_details[0]?.beginning_date)}

            </>
        );
    };

    // Column definition for datatable
    const columns = [
        !!member_id == false && {
            // dataField: 'member_details[member_name]',
            formatter: (cell, row) => <Link to={{ pathname: "/member-details", state: { member_id: row.member_id, tabType: "6" } }}>{row.member_details.member_name}</Link>,
            text: 'Member name',
            sort: true,
        },
        !!member_id == false && {
            formatter: handleBeginDate,
            text: 'Begin Date',
            sort: true,
        },
        {
            formatter: (cell, row) => moment(row.entry_date).format("DD-MM-YYYY"),
            text: 'Measurement Date',
            sort: true,
        },
        {
            dataField: 'body_height',
            text: 'Body Height(cm)',
            sort: true,
        },
        {
            dataField: 'body_weight',
            text: 'Body Weight(Kg)',
            sort: true,
        },
        {
            dataField: 'body_age',
            text: 'Body Age',
            sort: true,
        },
        {
            dataField: 'body_fat_percentage',
            text: 'Body Fat',
            sort: true,
        },
        {
            dataField: 'visceral_fat_percentage',
            text: 'Visceral Fat',
            sort: true,
        },
        {
            dataField: 'resting_metabolic_rate',
            text: 'BMR',
            sort: true,
        },
        {
            dataField: 'body_mass_index',
            text: 'BMI',
            sort: true,
        },

        {
            formatter: (cell, row) => moment(row.next_measurement_day).format("DD-MM-YYYY"),
            //dataField: 'next_measurement_day',
            text: 'Next Measurement Day',
            sort: true,
        },
        !actionBtn && {
            dataField: '',
            text: 'ACTION',
            sort: true,
            formatter: handleAction
        }
    ];


    const handleFetch = async (e, row, isEdit) => {
        formikObj.resetForm()
        let responseData = []
        try {
            setEditBodyComposition(true)
            const res = await graphql({
                query: BodyCompositionQry.FETCH_BODY_COMPOSITION_EDIT_QUERY,
                variables: {
                    ...paginatorInfo,
                    searchText: `%${searchText}%`,
                    id: !!member_id ? row.id : row.body_compositions_id
                }
            });
            responseData = !!res.data.body_compositions ? res.data.body_compositions.data[0] : []
        } catch (error) {

        }
        if (isEdit) {

            const updatedbodyCompositionData = {
                id: responseData.id,
                member_id: responseData.member_id,
                body_weight: responseData.body_weight,
                body_height: responseData.body_height,
                body_fat_percentage: responseData.body_fat_percentage,
                entry_date: responseData.entry_date,
                next_measurement_day: responseData.next_measurement_day,
                visceral_fat_percentage: responseData.visceral_fat_percentage,
                resting_metabolic_rate: responseData.resting_metabolic_rate,
                body_mass_index: responseData.body_mass_index,
                body_age: responseData.body_age,
                status: responseData.status,
            }
            setBeginingDate(moment(new Date(responseData.entry_date)).format("DD-MM-YYYY"))
            setNextMeasurementDayDate(moment(new Date(responseData.next_measurement_day)).format("DD-MM-YYYY"))
            setBodyCompositionData(updatedbodyCompositionData)
            openModal()
        } else {
            // setInvoiceData(responseData)
            // setTimeout(() => {
            //     // setInvoiceModal(true)
            // }, 0);

        }
    }

    const handleDelete = (e, row) => {
        swal({
            title: "Are you sure?",
            text: "you want to delete this recored",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (willDelete) => {
                if (willDelete) {
                    try {
                        const response = await axiosInstance.post(apiUrls.bodyComposition.deleteBodyComposition, { id: row.id });
                        if (response.success == 1) {
                            swal("Success!", response.message, "success");
                            fetchBodyCompositionData(paginatorInfo, searchText, mainTrainer, memberTiming)
                        }
                        else {
                            swal("Oops!", response.message, "error");
                        }
                    } catch (err) {
                        swal("Oops!", "Something went wrong!", "error");
                        setError(err.message)
                    }
                }
            });
    }

    const beginingDateChange = (beginingDates) => {
        if (!!beginingDates[0]) {
            setBeginingDate(beginingDates[0].getDate() + '-' + (beginingDates[0].getMonth() + 1) + '-' + beginingDates[0].getFullYear())
            formikObj.setFieldValue("entry_date", standartDateFormat(beginingDates[0]))
        } else {
            setBeginingDate('');
            formikObj.setFieldValue("entry_date", '');
        }
    }

    const nextmeasurementdayDate = (endDates) => {
        if (!!endDates[0]) {
            setNextMeasurementDayDate(endDates[0].getDate() + '-' + (endDates[0].getMonth() + 1) + '-' + endDates[0].getFullYear())
            formikObj.setFieldValue("next_measurement_day", standartDateFormat(endDates[0]))
        } else {
            setNextMeasurementDayDate('');
            formikObj.setFieldValue("next_measurement_day", '');
        }
    }

    const formikObj = useFormik({
        enableReinitialize: true,

        initialValues: { ...bodyCompositionData },
        validationSchema: Yup.object({
            body_height:Yup.string().required("Please Enter Body Height"),
            body_weight: Yup.string().required("Please Enter Body Weight"),
            // body_fat_percentage: Yup.string().required("Please Enter Body Fat"),
            // visceral_fat_percentage: Yup.string().required("Please Enter Visceral Fat"),
            // entry_date: Yup.string().required("Please Select Begining Date"),
        }),
        onSubmit: (values) => {
            values.member_id = !!member_id ? member_id : values.member_id
            if (editBodyComposition == false) {
                addBodyCompositionData(values)
            }
            else {
                updateBodyCompositionData(values)
            }

        }
    });

    const handleDecimalValuesChange = (e) => {
        if (/^\d*\.?\d*$/g.test(e.target.value) || e.target.value == '') {
            formikObj.setFieldValue(e.target.name, e.target.value)
        }
    }

    const addBodyCompositionData = async (values) => {
        try {
            setIsSaving(true)
            const response = await axiosInstance.post(apiUrls.bodyComposition.addAndUpdateBodyComposition, { ...values });
            if (response.success == 1) {
                formikObj.resetForm()
                setIsSaving(false)
                closeModal()
                dispatch(setCreateBodyComposition(true));
                swal("Success!", response.message, "success");
                fetchBodyCompositionData(paginatorInfo, searchText, mainTrainer, memberTiming)
                setBodyCompositionData([])
                formikObj.setFieldValue("entry_date", standartDateFormat(new Date()))
            }
            else {
                setIsSaving(false)
                swal("Oops!", response.message, "error");
            }
        } catch (err) {
            setIsSaving(false)
            swal("Oops!", "Something went wrong!", "error");
            setError(err.message)
        }
    }

    const updateBodyCompositionData = async (values) => {
        try {
            setIsSaving(true)
            const response = await axiosInstance.post(apiUrls.bodyComposition.addAndUpdateBodyComposition, { ...values });
            if (response.success == 1) {
                formikObj.resetForm()
                setIsSaving(false)
                closeModal()
                dispatch(setCreateBodyComposition(true));
                swal("Success!", response.message, "success");
                fetchBodyCompositionData(paginatorInfo, searchText, mainTrainer, memberTiming)
                handleMemberBodyComposition(memberPaginatorInfo, memberSearchText)
                setBodyCompositionData([])
                setEditBodyComposition(false)
                formikObj.setFieldValue("entry_date", standartDateFormat(new Date()))
            }
            else {
                setIsSaving(false)
                swal("Oops!", response.message, "error");
            }
        } catch (err) {
            setIsSaving(false)
            swal("Oops!", "Something went wrong!", "error");
            setError(err.message)
        }
    }

    const filterMemberBodyByMainTrainer = (e) => {
        let mainTrainer = ""
        let paginator = paginatorInfo
        paginator.currentPage = 1
        if (e.target.value != "") {
            mainTrainer = parseInt(e.target.value)
            setMainTrainer(mainTrainer)
        }
        else {
            setMainTrainer(mainTrainer)
        }
        fetchBodyCompositionData(paginatorInfo, searchText, mainTrainer, memberTiming)
    }

    const filterMemberBodyByTiming = (e) => {
        let memberTiming = ""
        let paginator = paginatorInfo
        paginator.currentPage = 1
        if (e.target.value != "") {
            memberTiming = parseInt(e.target.value)
            setMemberTiming(memberTiming)
        }
        else {
            setMemberTiming(memberTiming)
        }
        fetchBodyCompositionData(paginatorInfo, searchText, mainTrainer, memberTiming)
    }

    const openModal = () => {
        setAddEditbodyCompositionModal(true);
    }

    const closeModal = () => {
        formikObj.resetForm()
        formikObj.setFieldValue("entry_date", "")
        formikObj.setFieldValue("next_measurement_day", "")
        setNextMeasurementDayDate('')
        setBeginingDate('')
        setAddEditbodyCompositionModal(false);
        dispatch(setCreateBodyComposition(false));
    }

    const closeViewModal = () => {
        setActionBtn(false)
        setViewBodyCompositionModal(false)
    }

    const closeInvoiceModal = () => {
        // setInvoiceModal(false)
    }

    const createBodyComposition = () => {
        setEditBodyComposition(false)
        setBodyCompositionData({ ...initialBodyCompositionData });
        formikObj.resetForm();
        formikObj.setFieldValue("entry_date", standartDateFormat(new Date()))
        openModal()
    }

    const printInvoice = () => {
        window.print();
    };

    const handleMemberBodyComposition = async (e, item) => {
        setMemberId(item.member_id)
        setActionBtn(true)
        try {
            const res = await graphql({
                query: BodyCompositionQry.FETCH_ALL_BODY_COMPOSITION_QUERY,
                variables: {
                    ...paginatorInfo,
                    searchText: `%${memberSearchText}%`,
                    member_id: item.member_id || memberId
                }
            })
            if (!!res.data.body_compositions && res.data.body_compositions.data) {
                setMemberBodyCompositionList(!!res.data.body_compositions ? res.data.body_compositions.data : [])
                setMemberPaginatorInfo(res?.data?.body_compositions?.paginatorInfo);
                setMemberSearchText(memberSearchText);
            }
            else {
                setMemberBodyCompositionList([])
                resetMemberPaginatorInfo()
            }
        } catch (error) {
            setMemberBodyCompositionList([])
            resetMemberPaginatorInfo()
        }
    }

    useEffect(() => {
        if (memberBodyCompositionList.length > 0) {
            setViewBodyCompositionModal(true)
        }
    }, [memberBodyCompositionList])

    return (
        <React.Fragment>
            <div className={!!member_id == false && "page-content"}>
                {!!member_id == false && <MetaTags>
                    <title>Body Composition</title>
                </MetaTags>}
                <Container fluid={!!member_id == false && true}>
                    {!!member_id == false &&
                        <React.Fragment>
                            <Breadcrumbs title="Body Composition" breadcrumbItem="Body Composition List" />
                            <Row>
                                <Col className="col-12">
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <Col lg={3}>
                                                    <Button
                                                        color="primary"
                                                        className="font-size-13 btn-block"
                                                        onClick={createBodyComposition}
                                                    >
                                                        <i className="mdi mdi-plus-circle-outline me-1 align-middle font-size-16" />
                                                        Create
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </React.Fragment>}

                    {/* Datatable view start */}

                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    {!!member_id == false &&
                                        <Row>
                                            <Col lg={8}>
                                                <h3>All Body Composition List</h3><br />
                                            </Col>
                                            {/* <Col lg={4}>
                                            <select className="btn btn-outline-primary ms-md-2 form-select w-auto pe-5 mb-3 mb-md-0" onChange={(e) => filterMemberBodyByMainTrainer(e)}>
                                                <option value=""> Main Trainer</option>
                                                {mainTrainerList.map((trainer, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <option value={trainer.id}>{trainer.name}</option>
                                                        </React.Fragment>
                                                    )
                                                })
                                                }
                                            </select>

                                            <select className="btn btn-outline-primary ms-md-2 form-select w-auto pe-5 mb-3 mb-md-0" onChange={(e) => filterMemberBodyByTiming(e)}>
                                                <option value="">Timing</option>
                                                <option value="1">Morning</option>
                                                <option value="2">Evening</option>
                                            </select>
                                        </Col> */}
                                        <Col lg={2}>
                                            <Input
                                                type="select"
                                                id="formrow-serviceType"
                                                className="form-control"
                                                onChange={(e) => filterMemberBodyByMainTrainer(e)}
                                            >
                                                <option value=""> Main Trainer</option>
                                                {mainTrainerList.map((trainer, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <option value={trainer.id}>{trainer.name}</option>
                                                        </React.Fragment>
                                                    )
                                                })
                                                }
                                            </Input>
                                            </Col>
                                            <Col lg={2}>
                                            <Input
                                                type="select"
                                                id="formrow-serviceType"
                                                className="form-control"
                                                onChange={(e) => filterMemberBodyByTiming(e)}
                                            >
                                                <option value="">Timing</option>
                                                <option value="1">Morning</option>
                                                <option value="2">Evening</option>
                                            </Input>
                                            </Col>
                                        </Row>
                                    }
                                    {processingList &&
                                        <Col lg={12} style={{ textAlign: "center" }}>
                                            <h5><i className="bx bx-hourglass bx-spin font-size-24 align-middle me-2"></i>
                                                Loading...</h5>
                                        </Col>
                                    }
                                    {!processingList &&
                                        <>
                                            {!!member_id == false ?
                                                <>{allBodyCompositionList.length > 0 ?
                                                    <>
                                                        <div className="search-box me-2 mb-2 d-inline-block">
                                                            <div className="position-relative">
                                                                <Label for="search-bar-0" className="search-label">
                                                                    <span id="search-bar-0-label" className="sr-only">Search this table</span>
                                                                    <Input id="search-bar-0"
                                                                        type="text"
                                                                        aria-labelledby="search-bar-0-label"
                                                                        className="form-control"
                                                                        placeholder="Search"
                                                                        value={searchText}
                                                                        onChange={(e) => handleSearch(e)}
                                                                    />
                                                                </Label>
                                                                <i className="bx bx-search-alt search-icon"></i>
                                                            </div>
                                                        </div>
                                                        <Row>
                                                            <Col lg={12}>
                                                                <div className="table-responsive col-fixed">
                                                                    <Table className="table mb-0">
                                                                        <thead>
                                                                            <tr style={{ whiteSpace: "nowrap" }}>
                                                                                <th>ID</th>
                                                                                <th>MEMBER NAME</th>
                                                                                <th>BEGIN DATE</th>
                                                                                <th>MAIN TRAINER</th>
                                                                                <th>MEMBERSHIP PLAN</th>
                                                                                <th>TIMING</th>
                                                                                <th>LAST MEASUREMENT DATE</th>
                                                                                <th>HEIGHT</th>
                                                                                <th>WEIGHT</th>
                                                                                <th>BODY AGE</th>
                                                                                <th>BODY FAT</th>
                                                                                <th>VISCERAL FAT</th>
                                                                                <th>BMR</th>
                                                                                <th>BMI</th>
                                                                                <th>NEXT MEASUREMENT DATE</th>
                                                                                <th>ACTION</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {allBodyCompositionList.map((item, index) => {
                                                                                return (
                                                                                    <tr key={index}>
                                                                                        <th scope="row">
                                                                                            <Link to={{ pathname: "/member-details", state: { member_id: item.member_id, tabType: "6" } }}>{item.member_id}</Link>
                                                                                        </th>
                                                                                        <td>
                                                                                            <div className="d-flex align-items-center whitespace-no-wrap">
                                                                                                <Link to={{
                                                                                                    pathname: "/member-details",
                                                                                                    state: { member_id: item.member_id, tabType: "6" }
                                                                                                }}
                                                                                                    style={{ color: item.body_scale_flag == 1 && '#d50c38' }}
                                                                                                >
                                                                                                    {item.member_name}
                                                                                                </Link>&nbsp;
                                                                                                <span className="badge rounded-pill bg-success ms-2 px-2 py-1" onClick={(e) => handleMemberBodyComposition(e, item)}>{item?.total_body_composition}</span>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td className="whitespace-no-wrap">{!!item.begin_date ? moment(item.begin_date).format('DD-MM-YYYY') : '-'}</td>
                                                                                        <td>{item.main_trainers ?
                                                                                                (item.main_trainers.map((mTrainer, index) => (
                                                                                                   <div key={index}>
                                                                                                        <div className="bg-primary bg-soft p-2 my-1 text-primary badge">{mTrainer.nick_name}</div>
                                                                                                   </div> 
                                                                                                ))) 
                                                                                            : 
                                                                                                ""
                                                                                        }</td>
                                                                                        <td>{!!item.membership_details ? 
                                                                                            (item.membership_details.map((details, index) => (
                                                                                            <div key={index} style={{ whiteSpace: "normal" }} >
                                                                                                <div className="bg-primary bg-soft p-2 my-1 text-primary badge">
                                                                                                    {details.plan_name}
                                                                                                </div>
                                                                                            </div>
                                                                                            ))) 
                                                                                        : 
                                                                                            ""
                                                                                        }</td>
                                                                                        <td>{!!item.membership_details ? 
                                                                                            (item.membership_details.map((details, index) => (
                                                                                            <div key={index} style={{ whiteSpace: "normal" }} >
                                                                                                <div className="badge bg-primary bg-soft p-2 my-1 text-primary">
                                                                                                    <span style={{ fontWeight: "bold", color: "#00008B" }}>
                                                                                                        {details.preferred_timing_name}
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                            ))) 
                                                                                        : 
                                                                                            ""
                                                                                        }</td>
                                                                                        {/* <td>{!!item.timing ? <div className="badge bg-primary bg-soft p-2 my-1 text-primary"><span style={{ fontWeight: "bold", color: "#00008B" }}>{((item.timing == 1) ? "Morning" : "Evening")}</span></div> : "-" }</td> */}
                                                                                        <td>{!!item.body_measurement_date ? moment(item.body_measurement_date).format('DD-MM-YYYY') : "-"}</td>
                                                                                        <td>{item.body_height}{" cm"}</td>
                                                                                        <td>{item.body_weight}{" Kg"}</td>
                                                                                        <td>{item.body_age}</td>
                                                                                        <td>{item.body_fat_percentage}</td>
                                                                                        <td>{item.visceral_fat_percentage}</td>
                                                                                        <td>{item.resting_metabolic_rate}</td>
                                                                                        <td>{item.body_mass_index}</td>
                                                                                        <td style={{ color: item.body_scale_flag == 1 && '#d50c38' }}>{!!item.next_measurement_date ? moment(item.next_measurement_date).format('DD-MM-YYYY') : '-'}</td>
                                                                                        <td style={{ whiteSpace: "nowrap" }}>{handleAction(null, item)}</td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                            }
                                                                        </tbody>
                                                                    </Table>
                                                                </div>
                                                            </Col>
                                                            <div className="mt-2">
                                                                <div style={{ float: "left" }}>
                                                                    <Input
                                                                        type="select"
                                                                        id="formrow-serviceType"
                                                                        className="form-control"
                                                                        name={'offset'}
                                                                        onChange={(e) => handleOffsetChange(e)}
                                                                        value={offSet}
                                                                    >
                                                                        <option value="10">10</option>
                                                                        <option value="20">20</option>
                                                                        <option value="50">50</option>
                                                                        <option value="100">100</option>
                                                                        <option value={totalRowCount}>All</option>
                                                                    </Input>
                                                                </div>
                                                                <div style={{ justifyContent: "end", display: "flex" }}>
                                                                    <ul className="pagination react-bootstrap-table-page-btns-ul">
                                                                        {pageLinks.map((btn, index) => {
                                                                            return (
                                                                                <React.Fragment key={index}>
                                                                                    {!!btn.url &&
                                                                                        <li className={!!btn.active ? "active page-item" : "page-item"}>
                                                                                            <a className="page-link" href="#" onClick={(e) => getPageNumber(e, btn)}>
                                                                                                {btn.label.includes("Next") ? ">" : btn.label.includes("Previous") ? "<" : btn.label}
                                                                                            </a>
                                                                                        </li>
                                                                                    }
                                                                                </React.Fragment>
                                                                            )
                                                                        })
                                                                        }
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </Row>
                                                    </>
                                                    :
                                                    <Col lg={12} style={{ textAlign: "center" }}>
                                                        <h5>No Data Found</h5>
                                                    </Col>
                                                }
                                                </>
                                                :
                                                <Row>
                                                    <Col lg={12}>
                                                        <CommonTable
                                                            paginatorInfo={paginatorInfo}
                                                            searchText={searchText}
                                                            onTableChange={handleTableChange}
                                                            keyField='id'
                                                            columns={columns}
                                                            data={allBodyCompositionList}
                                                            loading={processingList}
                                                        />
                                                    </Col>
                                                </Row>
                                            }
                                        </>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    {/* Datatable view end */}

                    <Modal
                        isOpen={addEditBodyCompositionModal}
                        toggle={closeModal}
                        size="lg"
                        backdrop="static"
                    // className={props.className}
                    >
                        <ModalHeader toggle={closeModal} tag="h4">
                            {editBodyComposition == false ? "Create New Body Composition Plan" : "Edit Body Composition Plan"}
                        </ModalHeader>
                        <ModalBody>
                            <Form>
                                {!!member_id == false &&
                                    <Row>
                                        <Col lg={12}>
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-InputService">Member <span className="text-danger">*</span></Label>
                                                <Input
                                                    type="select"
                                                    id="formrow-serviceType"
                                                    className="form-control"
                                                    name={'member_id'}
                                                    onChange={formikObj.handleChange}
                                                    value={formikObj.values.member_id}
                                                    invalid={
                                                        formikObj.touched.member_id && formikObj.errors.member_id ? true : false
                                                    }
                                                    disabled={editBodyComposition ? true : false}
                                                >
                                                    <option value="">Select</option>
                                                    {allMemberList.map((item) => (
                                                        <option value={item.id} key={item.id}>{item.member_name}</option>
                                                    ))}

                                                </Input>
                                                {formikObj.touched.member_id && formikObj.errors.member_id ? (
                                                    <FormFeedback type="invalid">{formikObj.errors.member_id}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>
                                    </Row>
                                }
                                <Row>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-InputService">Height(in c.m)<span className="text-danger">*</span></Label>
                                            <Input
                                                type="text"
                                                id="formrow-InputService"
                                                className="form-control"
                                                name="body_height"
                                                onChange={(e) => { handleDecimalValuesChange(e) }}
                                                value={formikObj.values.body_height}
                                                placeholder="Enter Height"
                                                invalid={
                                                    formikObj.touched.body_height && formikObj.errors.body_height ? true : false
                                                }
                                            />
                                            {formikObj.touched.body_height && formikObj.errors.body_height ? (
                                                <FormFeedback type="invalid">{formikObj.errors.body_height}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-InputService">Body Weight(in k.g) <span className="text-danger">*</span></Label>
                                            <Input
                                                type="text"
                                                id="formrow-InputService"
                                                className="form-control"
                                                name="body_weight"
                                                onChange={(e) => { handleDecimalValuesChange(e) }}
                                                value={formikObj.values.body_weight}
                                                placeholder="Enter Body Weight"
                                                invalid={
                                                    formikObj.touched.body_weight && formikObj.errors.body_weight ? true : false
                                                }
                                            />
                                            {formikObj.touched.body_weight && formikObj.errors.body_weight ? (
                                                <FormFeedback type="invalid">{formikObj.errors.body_weight}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-serviceType">Body Fat (%)</Label>
                                            <Input
                                                type="text"
                                                id="formrow-InputService"
                                                className="form-control"
                                                name="body_fat_percentage"
                                                onChange={(e) => { handleDecimalValuesChange(e) }}
                                                value={formikObj.values.body_fat_percentage}
                                                placeholder="Enter Body Fat"
                                                invalid={
                                                    formikObj.touched.body_fat_percentage && formikObj.errors.body_fat_percentage ? true : false
                                                }
                                            />
                                            {formikObj.touched.body_fat_percentage && formikObj.errors.body_fat_percentage ? (
                                                <FormFeedback type="invalid">{formikObj.errors.body_fat_percentage}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-InputService">Visceral Fat (%)</Label>
                                            <Input
                                                type="text"
                                                id="formrow-InputService"
                                                className="form-control"
                                                name="visceral_fat_percentage"
                                                onChange={(e) => { handleDecimalValuesChange(e) }}
                                                value={formikObj.values.visceral_fat_percentage}
                                                placeholder="Enter Body Visceral Fat"
                                                invalid={
                                                    formikObj.touched.visceral_fat_percentage && formikObj.errors.visceral_fat_percentage ? true : false
                                                }
                                            />
                                            {formikObj.touched.visceral_fat_percentage && formikObj.errors.visceral_fat_percentage ? (
                                                <FormFeedback type="invalid">{formikObj.errors.visceral_fat_percentage}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-serviceType">RMR (Resting Metabolic Rate)</Label>
                                            <Input
                                                type="text"
                                                id="formrow-InputService"
                                                className="form-control"
                                                name="resting_metabolic_rate"
                                                onChange={(e) => { handleDecimalValuesChange(e) }}
                                                value={formikObj.values.resting_metabolic_rate}
                                                placeholder="Enter RMR (Resting Metabolic Rate)"

                                            />
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-InputService">Body Age</Label>
                                            <Input
                                                type="text"
                                                id="formrow-InputService"
                                                className="form-control"
                                                name="body_age"
                                                onChange={(e) => { handleDecimalValuesChange(e) }}
                                                value={formikObj.values.body_age}
                                                placeholder="Enter Body Age"

                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-InputService">BMI (Body Mass Index)</Label>
                                            <Input
                                                type="text"
                                                id="formrow-InputService"
                                                className="form-control"
                                                name="body_mass_index"
                                                onChange={(e) => { handleDecimalValuesChange(e) }}
                                                value={formikObj.values.body_mass_index}
                                                placeholder="Enter BMI (Body Mass Index)"

                                            />
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-startDate-Input">Measurement Date</Label>
                                            <Flatpickr
                                                className="form-control d-block"
                                                name="entry_date"
                                                placeholder="DD-MM-YYYY"
                                                options={{
                                                    altInput: true,
                                                    altFormat: "d-m-Y",
                                                    dateFormat: "d-m-Y",
                                                    // minDate: today
                                                }}
                                                onChange={beginingDateChange}
                                                // value={entry_date || today}
                                                value={entry_date}
                                            />
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-InputService">Next Measurement Day</Label>
                                            <Flatpickr
                                                className="form-control d-block"
                                                name="next_measurement_day"
                                                placeholder="DD-MM-YYYY"
                                                options={{
                                                    altInput: true,
                                                    altFormat: "d-m-Y",
                                                    dateFormat: "d-m-Y",
                                                    // minDate: entry_date
                                                }}
                                                onChange={nextmeasurementdayDate}
                                                // value={next_measurement_day || entry_date}
                                                value={next_measurement_day}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </ModalBody>
                        <div className="modal-footer">
                            {!!isSaving ?
                                <button className="btn btn-primary " disabled>
                                    Processing
                                </button>
                                :
                                <>
                                    <button
                                        type="button"
                                        onClick={closeModal}
                                        className="btn btn-secondary "
                                        data-dismiss="modal"
                                    >
                                        Close
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn btn-primary "
                                        onClick={(e) => {
                                            e.preventDefault();
                                            formikObj.handleSubmit();
                                            return false;
                                        }}
                                    >
                                        {editBodyComposition == false ? "Save" : "Update"}
                                    </button>
                                </>
                            }
                        </div>
                    </Modal>

                    {/* View member body composition */}

                    <Modal
                        isOpen={viewBodyCompositionModal}
                        toggle={closeViewModal}
                        size="lg"
                        backdrop="static"
                    >
                        <ModalHeader toggle={closeViewModal} tag="h4">
                            Member Body Composition List
                        </ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col lg={12}>
                                    <CommonTable
                                        paginatorInfo={memberPaginatorInfo}
                                        searchText={memberSearchText}
                                        onTableChange={handleMemberTableChange}
                                        keyField='id'
                                        columns={columns}
                                        data={memberBodyCompositionList}
                                        loading={processingList}
                                    />
                                </Col>
                            </Row>
                        </ModalBody>
                        <div className="modal-footer">
                            <button
                                type="button"
                                onClick={closeViewModal}
                                className="btn btn-secondary "
                                data-dismiss="modal"
                            >
                                Close
                            </button>
                        </div>
                    </Modal>

                </Container>
            </div>
        </React.Fragment>
    );

}

export default withRouter(AddEditBodyComposition);

AddEditBodyComposition.propTypes = {
    history: PropTypes.object,
    member_id: PropTypes.number,
    bodyCompositionModal: PropTypes.bool,
    redirectPath: PropTypes.string
};