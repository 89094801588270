import { generateSearchQuery } from "utils/graphql"

// FETCH FLOOR TYPES
export const FLOOR_TYPES_QUERY = `query floorTypes{
    floors
    {
        id
        floor_name
    }
}
`

// FETCH PREFERRED TIMINGS 
export const PREFERRED_TIMINGS_TYPES_QUERY = `query preferredTimingTypes{
    preferred_timings 
    {
        id
        preferred_timing_name
    }
}
`

// FETCH ALL MEMBERSHIPS
const searchQry = generateSearchQuery(['status'])
export const FETCH_ALL_MEMBERSHIPS_QUERY = `query allMembershipsList($perPage: Int!, $currentPage: Int, $searchText: Mixed, $id: Mixed){
    memberships (
            first:$perPage,page:$currentPage,
            where:{
                AND:[{column:_id, operator: LIKE value: $id}]
                ${searchQry}
            }
            orderBy:{column:_id ,order:DESC}
        ) 
        {
          data {
            id
            member_id
            subscription_plan_id
            invoice_num
            subscription_details{
                id
                plan_pattern {
                    id
                    plan_pattern_name
                  }
                plan_type {
                    id
                    plan_type_name
                  }
                plan_name
                total_amount
                status
            }
            member_details{
                id
                member_name
                member_age
                profession
                gender
                mobile_no
                email_id
                status
                address
            }
            membership_floors{
                id
                membership_id
                floor_id
                floor_details{
                    id
                    floor_name
                }
            }
            payment_mode{
                id
                payment_mode
            }
            preferred_timing_details {
                id
                preferred_timing_name
            }
            beginning_date
            end_date
            number_of_sessions
            subscription_plan_charges
            discount
            gst_amount
            total_amount
            gym_timing_from
            gym_timing_to
            status
            membership_plan_activities {
                id
                number_of_session
                activity_details {
                  id
                  activity
                }
                activity_type_details
                {
                  id
                  activity_type
                }
            }
          }
          paginatorInfo {
            currentPage
            total
            perPage
        }
        }
        
      }

`