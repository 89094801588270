import React, { useEffect, useState } from 'react'
import { withRouter, useLocation, Link, useParams } from 'react-router-dom'
import PropTypes, { any } from "prop-types";
import MetaTags from "react-meta-tags";
import {
    Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label, Button, Modal,
    ModalBody,
    ModalHeader,
    InputGroup,
    Spinner,
    CardTitle,
    Collapse,
    CardHeader,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from "reactstrap";
import { apiUrls, axiosInstance } from 'api';
import * as SubscriptionQry from "GraphQLquery/subscriptionQry";
import { FieldArray, useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import { graphql } from "api";
import CommonTable from 'components/Common/CommonTable';
import usePaginator from 'hooks/usePaginator';
import { standartDateFormat, today, userDateFormat, userTimeFormat } from 'utils/date';
import Flatpickr from "react-flatpickr";
import moment from 'moment';
import swal from 'sweetalert';
import classnames from "classnames";
import SessionUpcomingCompletedModal from './SessionUpcomingCompletedModal';

const initialAcitivityData = {
    id: '',
    activity_id: '',
    sessions_available: '',
    sessions_completed: '',

    alloted_batch: [],
    assign_date: '',
    timing_from: '',
    timing_to: '',
    note: ''
}

const activityIds = [2, 3, 4, 5, 7]
let alloted_batch = []
const zumbaYogaArr = [2, 3, 7]
const steamBathId = 4
const personalTrainingId = 5
let steamBathPersonalTrainingId = ''

const Activity = props => {
    const member_id = props.member_id;
    const activityId = props.activity
    const redirectPath = props.redirectPath;
    const [acitivityData, setAcitivityData] = useState({ ...initialAcitivityData })
    const [processingList, setProcessingList] = useState(false);
    const [processingActivityList, setProcessingActivityList] = useState(false);
    const [processingAvlSession, setProcessingAvlSession] = useState(false);
    const [paginatorInfo, setPaginatorInfo, resetPaginatorInfo] = usePaginator();
    const [memberActivityPaginatorInfo, setMemberActivityPaginatorInfo, resetMemberActivityPaginatorInfo] = usePaginator();
    const [searchText, setSearchText] = useState('');
    const [activityList, setActivityList] = useState([])
    const [assignSessionModal, setAssignSessionModal] = useState(false)
    const [isSaving, setIsSaving] = useState(false);
    const [activityBatchList, setActivityBatchList] = useState([])
    const [sessionCompletedModal, setSessionCompletedModal] = useState(false)
    const [sessionCompletedLog, setSessionCompletedLog] = useState([])
    const [memberwiseActivityList, setMemberwiseActivityList] = useState([])
    const [assignedDate, setAssignedDate] = useState('');
    const [customActiveTab, setcustomActiveTab] = useState(!!activityId ? activityId : 2);
    const [activityDetails, setActivityDetails] = useState([])

    useEffect(() => {
        fetchActivities();
        fetchActivityDetails();
        formikObj.setFieldValue("activity_id", customActiveTab)
        steamBathPersonalTrainingId = (activityId == 4 || activityId == 5) ? activityId : ''
    }, [])

    const handleValidationSchema = () => {
        return Yup.object({
            assign_date: (formikObj.values.activity_id == 4 || formikObj.values.activity_id == 5) && Yup.string().required("Please Select Assigned Date"),
            timing_from: (formikObj.values.activity_id == 4) && Yup.string().required("Please Select From Time"),
            timing_to: (formikObj.values.activity_id == 4) && Yup.string().required("Please Select To Time"),
        })
    }

    const formikObj = useFormik({
        enableReinitialize: true,

        initialValues: { ...acitivityData },
        validationSchema: handleValidationSchema,
        onSubmit: async (values) => {
            try {
                setIsSaving(true)
                let param = null
                let apiEndPoint = null
                if (values.activity_id == 4 || values.activity_id == 5) {
                    apiEndPoint = apiUrls.activity.addAndUpdateSteamBathAndPersonalTrainerActivities
                    param = {
                        id: values?.id,
                        activity_id: values.activity_id,
                        assign_date: values.assign_date,
                        timing_from: values.timing_from,
                        timing_to: values.timing_to,
                        note: values.note,
                        member_id: member_id
                    }
                }
                else {
                    apiEndPoint = apiUrls.activity.allotMemberInBatch
                    param = {
                        alloted_batch: values.alloted_batch
                    }
                }
                const res = await axiosInstance.post(apiEndPoint, param);
                if (res.success == 1) {
                    formikObj.resetForm()
                    setIsSaving(false)
                    closeModal()
                    swal("Success!", res.message, "success");
                    alloted_batch = []
                    setAssignedDate('')
                    formikObj.setFieldValue("assign_date", "")
                    formikObj.setFieldValue("timing_from", "")
                    formikObj.setFieldValue("timing_to", "")
                    formikObj.setFieldValue("id", "")
                    formikObj.setFieldValue("note", "")
                    fetchMemberWiseActivitiesDetails(memberActivityPaginatorInfo, searchText);
                } else {
                    setIsSaving(false)
                    swal("Oops!", res.message, "error");
                }
            } catch (error) {
                setIsSaving(false)
                swal("Oops!", "Something went wrong!", "error");
            }
        }
    });

    const fetchActivities = async () => {
        try {
            const res = await graphql({
                query: SubscriptionQry.ACTIVITIES_LIST_QUERY
            });
            if (res.data.activities) {
                let activities = res?.data?.activities || []
                activities = activities.filter(item => activityIds.includes(item.id))
                setActivityList(activities)
            } else {
                setActivityList([])
            }
        } catch (error) {
            setActivityList([])
        }
    }

    const fetchActivityDetails = async () => {
        try {
            const res = await axiosInstance.post(apiUrls.activity.activityDetails, { member_id: member_id })
            if (res.data) {
                let activityDetails = res?.data || []
                setActivityDetails(activityDetails)
            } else {
                setActivityDetails([])
            }
        } catch (error) {
            setActivityDetails([])
        }
    }

    const fetchMemberWiseActivitiesDetails = async (memberActivityPaginatorInfo, searchText) => {
        try {
            setProcessingActivityList(true)
            let params = {
                offset: !!memberActivityPaginatorInfo.perPage ? memberActivityPaginatorInfo.perPage : 10,
                search_text: searchText,
                member_id: member_id,
                activity_id: formikObj.values.activity_id
            }
            let apiEndPoint = null
            if (formikObj.values.activity_id == 4 || formikObj.values.activity_id == 5) {
                apiEndPoint = apiUrls.activity.fetchSteamBathAndPersonalTrainerActivities + "?page=" + memberActivityPaginatorInfo.currentPage
            }
            else {
                apiEndPoint = apiUrls.activity.memberWiseActivitiesDetails + "?page=" + memberActivityPaginatorInfo.currentPage
            }
            const res = await axiosInstance.post(apiEndPoint, params)
            if (res.success == 1) {
                let data = res?.data?.data
                setMemberwiseActivityList(data)
                let memberActivityPaginatorInfo = {
                    currentPage: parseInt(res?.data?.current_page),
                    perPage: parseInt(res?.data?.per_page),
                    total: parseInt(res?.data?.total),
                }
                setMemberActivityPaginatorInfo(memberActivityPaginatorInfo);
                setSearchText(searchText);
                setProcessingActivityList(false)
                fetchActivitywiseSessionDetails()
            } else {
                setProcessingActivityList(false)
                resetMemberActivityPaginatorInfo()
            }
        } catch (error) {
            setProcessingActivityList(false)
            resetMemberActivityPaginatorInfo()
        }
    }

    const handleAction = (cell, row) => {
        return (
            <>
                {(steamBathPersonalTrainingId == 4 || steamBathPersonalTrainingId == 5) &&
                    <Button className="btn-info btn-sm" onClick={(e) => { handleFetch(e, row) }}>
                        <i className="bx bx-pencil align-middle" />
                    </Button>
                }
                &nbsp;
                <Button className="btn-danger btn-sm" onClick={(e) => { handleDelete(e, row) }} title="Drop">
                    <i className="bx bx-user-minus"></i>
                </Button>
            </>
        )
    }

    const handleFetch = async (e, row) => {
        let param = {
            id: row.id,
            member_id: member_id,
            activity_id: formikObj.values.activity_id
        }
        try {
            const res = await axiosInstance.post(apiUrls.activity.fetchSteamBathAndPersonalTrainerActivities, param)
            let resData = !!res.data ? res?.data?.[0] : []
            const data = {
                id: resData?.id,
                member_id: resData?.member_id,
                activity_id: resData?.activity_id,
                assign_date: resData?.assign_date,
                timing_from: resData?.timing_from,
                timing_to: resData?.timing_to,
                note: resData?.note
            }
            setAssignedDate(userDateFormat(resData?.assign_date))
            setAcitivityData(data)
            setAssignSessionModal(true)
        } catch (error) {

        }
    }

    const handleDelete = (e, row) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (willDelete) => {
                if (willDelete) {
                    try {
                        let apiEndPoint = null
                        let param = null
                        if (formikObj.values.activity_id == 4 || formikObj.values.activity_id == 5) {
                            apiEndPoint = apiUrls.activity.dropMemberFromSteamBathAndPersonalTrainerActivities
                            param = {
                                id: row.id,
                                member_id: member_id
                            }
                        }
                        else {
                            apiEndPoint = apiUrls.batch.dropMemberFromBatch
                            param = {
                                batch_id: row.id,
                                member_id: member_id
                            }
                        }
                        const response = await axiosInstance.post(apiEndPoint, param);
                        if (response.success == 1) {
                            swal("Success!", response.message, "success");
                            fetchMemberWiseActivitiesDetails(memberActivityPaginatorInfo, searchText);
                        }
                        else {
                            swal("Oops!", response.message, "error");
                        }
                    } catch (err) {
                        swal("Oops!", "Something went wrong!", "error");
                    }
                }
            });
    }

    const handleBatchDate = (cell, row) => {
        if (row?.alloted_status == 1) {
            return <>
                <div>{userDateFormat(row?.start_date)}</div>
                <div>{userDateFormat(row?.end_date)}</div>
            </>
        } else {
            return <div>
                {userDateFormat(row?.batch_date || row?.assign_date)}
            </div>
        }
    }

    const columns = [
        {
            dataField: 'batch_id',
            text: 'BATCH ID',
            sort: true,
        },
        {
            dataField: "activity_name",
            text: 'ACTIVITY NAME',
            sort: true,
        },
        {
            dataField: 'day_name',
            text: 'DAY NAME',
            sort: true,
        },
        {
            formatter: (cell, row) => userDateFormat(row?.date),
            text: 'DATE',
            sort: true,
        },
        {
            dataField: "timing_from",
            text: 'TIMING FROM',
            sort: true,
        },
        {
            dataField: "timing_to",
            text: 'TIMING TO',
            sort: true,
        },
        {
            dataField: 'location',
            text: 'LOCATION',
            sort: true,
        }
    ];

    const activityColumns = [
        zumbaYogaArr.includes(formikObj.values.activity_id) &&
        {
            dataField: 'batch_id',
            text: 'BATCH ID',
            sort: true,
        },
        {
            dataField: 'activity',
            text: 'ACTIVITY',
            sort: true,
        },
        {
            formatter: handleBatchDate,
            text: 'BATCH DATE',
            sort: true,
        },
        zumbaYogaArr.includes(formikObj.values.activity_id) &&
        {
            dataField: 'day_name',
            text: 'DAY NAME',
            sort: true,
        },
        (personalTrainingId != formikObj.values.activity_id) &&
        {
            formatter: (cell, row) => row?.alloted_status == 1 ? "" : userTimeFormat(row?.timing_from),
            text: 'TIMING FROM',
            sort: true,
        },
        (personalTrainingId != formikObj.values.activity_id) &&
        {
            formatter: (cell, row) => row?.alloted_status == 1 ? "" : userTimeFormat(row?.timing_to),
            text: 'TIMING TO',
            sort: true,
        },
        zumbaYogaArr.includes(formikObj.values.activity_id) &&
        {
            formatter: (cell, row) => row.alloted_status == 1 ? <span className="badge rounded-pill bg-success px-2 py-1">Full</span> : <span className="badge rounded-pill bg-warning float-start">Partial</span>,
            text: 'ALLOTMENT TYPE',
            sort: true,
        },
        zumbaYogaArr.includes(formikObj.values.activity_id) &&
        {
            formatter: (cell, row) => (row.batch_status == 1 && <span className="badge rounded-pill bg-warning px-2 py-1">Upcoming</span>) || (row.batch_status == 2 && <span className="badge rounded-pill bg-success float-start">Completed</span>) || (row.batch_status == 3 && <span className="badge rounded-pill bg-primary float-start">Ongoing</span>),
            text: 'BATCH STATUS',
            sort: true,
        },
        !zumbaYogaArr.includes(formikObj.values.activity_id) &&
        {
            formatter: (cell, row) => row.status == 1 ? <span className="badge rounded-pill bg-warning px-2 py-1">Upcoming</span> : <span className="badge rounded-pill bg-success float-start">Completed</span>,
            text: 'BATCH STATUS',
            sort: true,
        },
        zumbaYogaArr.includes(formikObj.values.activity_id) && {
            dataField: 'location',
            text: 'LOCATION',
            sort: true,
        },
        {
            formatter: handleAction,
            text: 'ACTION'
        }
    ]

    const handleTableChange = (paginatorInfo, searchText) => {
        fetchActivitywiseBatchList(paginatorInfo, searchText)
    }

    const handleMemberAcitivityTableChange = (memberActivityPaginatorInfo, searchText) => {
        fetchMemberWiseActivitiesDetails(memberActivityPaginatorInfo, searchText)
    }

    const handleActivitySelect = (row, isSelected) => {
        if (isSelected) {
            alloted_batch.push({
                unique_id: row.unique_id,
                batch_id: row.batch_id,
                member_id: member_id,
                batch_date: row.date,
                day_id: row.day_id
            })
        }
        else {
            alloted_batch = alloted_batch.filter(item => item.unique_id != row.unique_id)
        }

        formikObj.setFieldValue("alloted_batch", alloted_batch)
    }

    const selectRow = {
        mode: 'checkbox',
        clickToSelect: true,
        onSelect: handleActivitySelect
    }

    const fetchActivitywiseBatchList = async (paginatorInfo, searchText) => {
        setAssignSessionModal(true)
        try {
            setProcessingList(true)
            let params = {
                offset: !!paginatorInfo.perPage ? paginatorInfo.perPage : 10,
                search_text: searchText,
                activity_id: formikObj.values.activity_id,
                member_id: member_id
            }
            const res = await axiosInstance.post(apiUrls.activity.ActivityWiseBatchList + "?page=" + (!!paginatorInfo.currentPage ? paginatorInfo.currentPage : 1), params)

            if (res.success == 1) {
                setProcessingList(false)
                setActivityBatchList(!!res?.data?.data ? res?.data?.data : [])
                let paginatorInfo = {
                    currentPage: parseInt(res?.data?.current_page),
                    perPage: parseInt(res?.data?.per_page),
                    total: parseInt(res?.data?.total),
                }
                setPaginatorInfo(paginatorInfo);
                setSearchText(searchText);
            }

            else {
                setProcessingList(false)
                setActivityBatchList([])
                resetPaginatorInfo();
            }
        } catch (error) {
            setProcessingList(false)
            setActivityBatchList([])
            resetPaginatorInfo();
        }

    }

    const closeModal = () => {
        setAssignSessionModal(false)
    }

    const closeSessionCompletedModal = () => {
        setSessionCompletedModal(false)
    }

    useEffect(async () => {

        if (formikObj.values.activity_id != '') {
            fetchActivitywiseSessionDetails()
            setMemberwiseActivityList([])
            fetchMemberWiseActivitiesDetails(memberActivityPaginatorInfo, searchText);

        }
    }, [formikObj.values.activity_id])

    const fetchActivitywiseSessionDetails = async () => {
        try {
            let param = {
                activity_id: formikObj.values.activity_id,
                member_id: member_id
            }
            const res = await axiosInstance.post(apiUrls.activity.activityWiseSessionDetails, param)
            if (res.success == 1) {
                formikObj.setFieldValue("sessions_completed", res?.data?.sessions_completed)
                formikObj.setFieldValue("sessions_available", res?.data?.sessions_available)
                let sessionCompletedLog = res?.data?.sessions_completed_logs
                setSessionCompletedLog(sessionCompletedLog)
            }
            else {
                formikObj.setFieldValue("sessions_completed", '')
                formikObj.setFieldValue("sessions_available", '')
                setSessionCompletedLog([])
            }
        } catch (error) {
            formikObj.setFieldValue("sessions_completed", '')
            formikObj.setFieldValue("sessions_available", '')
            setSessionCompletedLog([])
        }
    }

    const handleSessionCompleted = (e) => {
        setSessionCompletedModal(true)
    }

    const getActivityName = () => {
        let activity = activityList.filter(item => item.id == formikObj.values.activity_id)
        return activity[0]?.activity
    }

    const assignDateChange = (assignedDate) => {
        setAssignedDate(assignedDate[0].getDate() + '-' + (assignedDate[0].getMonth() + 1) + '-' + assignedDate[0].getFullYear())
        formikObj.setFieldValue("assign_date", standartDateFormat(assignedDate[0]))
    }

    const setFromTimeChange = (timeChange) => {
        formikObj.setFieldValue("timing_from", timeChange[0].getHours() + ":" + timeChange[0].getMinutes())
    }

    const setToTimeChange = (timeChange) => {
        formikObj.setFieldValue("timing_to", timeChange[0].getHours() + ":" + timeChange[0].getMinutes())
    }

    const handleSaveAvailableSession = async (e) => {
        let param = {
            activity_id: formikObj.values.activity_id,
            member_id: member_id,
            available_session: formikObj.values.sessions_available
        }
        try {
            setProcessingAvlSession(true)
            const response = await axiosInstance.post(apiUrls.activity.addCustomActivities, param)
            if (response.success == 1) {
                setProcessingAvlSession(false)
                swal("Success!", response.message, "success");
            } else {
                setProcessingAvlSession(false)
                swal("Oops!", response.message, "error");
            }
        } catch (error) {
            console.log(error);
            setProcessingAvlSession(false)
            swal("Oops!", "Something went wrong!", "error");
        }
    }

    const toggleActivity = tab => {

        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
            formikObj.setFieldValue("activity_id", tab)
            steamBathPersonalTrainingId = tab
        }
    };

    return (
        <React.Fragment>
            <Row className='activity-tab'>
                <Nav tabs className="nav-tabs-custom nav-justified custom-tab" style={{ display: "ruby" }}>
                    {activityDetails.map((item) => (
                        <NavItem className="whitespace-no-wrap" key={item.id}>
                            <NavLink className={classnames({
                                'cursor-pointer': true, active: customActiveTab === item.id,
                            })}
                                onClick={() => {
                                    toggleActivity(item.id);
                                }}
                            >
                                <span className="d-none d-sm-block">{item.activity}
                                    <span className="badge rounded-pill bg-warning ms-2 px-2 py-1">{item?.available_session}</span>
                                </span>
                            </NavLink>
                        </NavItem>
                    ))}
                </Nav>
            </Row>
            <Row className='mb-2'>
                {/* <Col lg={3} className="mb-3 mb-lg-0">
                    <Label htmlFor="formrow-InputService">Activity</Label>
                    <Input
                        type="select"
                        id="formrow-serviceType"
                        className="form-control"
                        name={"activity_id"}
                        onChange={formikObj.handleChange}
                        value={formikObj.values.activity_id}
                        invalid={
                            formikObj.touched.activity_id && formikObj.errors.activity_id ? true : false
                        }
                    >
                        <option value="">Select</option>
                        {activityList.map((item) => (
                            <option value={item.id} key={item.id}>{item.activity}</option>
                        ))}

                    </Input>
                </Col> */}
                <Col lg={5} className="mb-3 mb-lg-0">
                    <Label htmlFor="formrow-InputService">Session(s) Available</Label>
                    <Input
                        type="text"
                        id="formrow-InputService"
                        className="form-control"
                        name="sessions_available"
                        onChange={formikObj.handleChange}
                        value={formikObj.values.sessions_available}
                        invalid={
                            formikObj.touched.sessions_available && formikObj.errors.sessions_available ? true : false
                        }
                    />
                </Col>

                <Col lg={1} className="mb-3 mb-lg-0">
                    {(!!formikObj.values.sessions_available && formikObj.values.sessions_available >= 0) &&
                        <>
                            {processingAvlSession ?
                                <Spinner animation="border" size="sm" color="primary" className='margin-top' />
                                :
                                <Button className="btn-info btn-sm margin-top" onClick={(e) => handleSaveAvailableSession(e)}>
                                    <i className='bx bx-save'></i>
                                </Button>
                            }
                        </>
                    }
                </Col>

                <Col lg={3} className="mb-3 mb-lg-0">
                    <Label htmlFor="formrow-InputService">Session(s) Completed</Label>
                    <br />
                    <span className="badge rounded-pill bg-success ms-2 px-2 py-1 cursor-pointer" onClick={(e) => handleSessionCompleted(e)}>{formikObj.values.sessions_completed || 0}</span>
                    {/* <Input
                        type="text"
                        id="formrow-InputService"
                        className="form-control"
                        name="sessions_completed"
                        onChange={formikObj.handleChange}
                        value={formikObj.values.sessions_completed}
                        invalid={
                            formikObj.touched.sessions_completed && formikObj.errors.sessions_completed ? true : false
                        }
                    /> */}
                </Col>
                <Col lg={3} className="mt-auto">
                    <Button
                        color="primary"
                        className="btn-block"
                        onClick={fetchActivitywiseBatchList}
                    // disabled={!(!!formikObj.values.activity_id)}
                    >
                        <i className="mdi mdi-plus-circle-outline align-middle me-1 font-size-14" />
                        Assign Session
                    </Button>
                </Col>
            </Row>

            <Row className='m-t-4'>
                <Col lg={12}>
                    <CommonTable
                        paginatorInfo={memberActivityPaginatorInfo}
                        searchText={searchText}
                        onTableChange={handleMemberAcitivityTableChange}
                        keyField='id'
                        columns={activityColumns}
                        data={memberwiseActivityList}
                        loading={processingActivityList}
                    />
                </Col>
            </Row>

            <Modal
                isOpen={assignSessionModal}
                toggle={closeModal}
                size="lg"
                backdrop="static"
            >
                <ModalHeader toggle={closeModal} tag="h4">
                    Assign a Session
                </ModalHeader>
                <ModalBody>
                    {(formikObj.values.activity_id == 4 || formikObj.values.activity_id == 5) ?
                        <Form>
                            <Row>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label htmlFor="formrow-serviceType">Assigned Date<span className="text-danger">*</span></Label>
                                        <Flatpickr
                                            className="form-control d-block"
                                            name="assign_date"
                                            placeholder="DD-MM-YYYY"
                                            options={{
                                                altInput: true,
                                                altFormat: "d-m-Y",
                                                dateFormat: "d-m-Y",
                                                // minDate: today
                                            }}
                                            onChange={assignDateChange}
                                            value={assignedDate}
                                        />
                                        {formikObj.touched.assign_date && formikObj.errors.assign_date ? (
                                            <div style={{ fontSize: "smaller", color: "#f46a6a" }}>{formikObj.errors.assign_date}</div>
                                        ) : null}
                                    </div>
                                </Col>
                                {(formikObj.values.activity_id != 5) &&
                                    <React.Fragment>
                                        <Col lg={4}>
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-serviceType">Time From<span className="text-danger">*</span></Label>
                                                <Flatpickr
                                                    className="form-control d-block"
                                                    name={"timing_from"}
                                                    options={{
                                                        noCalendar: true,
                                                        enableTime: true,
                                                        dateFormat: 'h:i K',
                                                    }}
                                                    onChange={setFromTimeChange}
                                                    value={formikObj.values.timing_from}
                                                />
                                                {formikObj.touched.timing_from && formikObj.errors.timing_from ? (
                                                    <div style={{ fontSize: "smaller", color: "#f46a6a" }}>{formikObj.errors.timing_from}</div>
                                                ) : null}
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-serviceType">Time To<span className="text-danger">*</span></Label>
                                                <Flatpickr
                                                    className="form-control d-block"
                                                    name={"timing_to"}
                                                    options={{
                                                        noCalendar: true,
                                                        enableTime: true,
                                                        dateFormat: 'h:i K',
                                                    }}
                                                    // onChange={(e, timeChange) => {
                                                    //     formikObj.setFieldValue("timing_to", timeChange[0].getHours() + ":" + timeChange[0].getMinutes())
                                                    // }}
                                                    onChange={setToTimeChange}
                                                    value={formikObj.values.timing_to}
                                                />
                                                {formikObj.touched.timing_to && formikObj.errors.timing_to ? (
                                                    <div style={{ fontSize: "smaller", color: "#f46a6a" }}>{formikObj.errors.timing_to}</div>
                                                ) : null}
                                            </div>
                                        </Col>
                                    </React.Fragment>
                                }
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <div className="mb-3">
                                        <Label htmlFor="formrow-serviceType">Note</Label>
                                        <Input
                                            type="textarea"
                                            id="formrow-InputService"
                                            className="form-control"
                                            name="note"
                                            onChange={formikObj.handleChange}
                                            value={formikObj.values.note}
                                            placeholder="Enter Note"
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                        :
                        <Row>
                            <Col lg={12}>
                                <CommonTable
                                    paginatorInfo={paginatorInfo}
                                    searchText={searchText}
                                    onTableChange={handleTableChange}
                                    keyField='unique_id'
                                    selectRow={selectRow}
                                    columns={columns}
                                    data={activityBatchList}
                                    loading={processingList}
                                />
                            </Col>
                        </Row>
                    }
                </ModalBody>
                {!processingList &&
                    <div className="modal-footer">
                        {!!isSaving ?
                            <button className="btn btn-primary " disabled>
                                Processing
                            </button>
                            :
                            <>
                                {((activityBatchList.length > 0) || (formikObj.values.activity_id == 4 || formikObj.values.activity_id == 5)) &&
                                    <>
                                        <button
                                            type="button"
                                            onClick={closeModal}
                                            className="btn btn-secondary "
                                            data-dismiss="modal"
                                        >
                                            Close
                                        </button>
                                        <button
                                            type="submit"
                                            className="btn btn-primary "
                                            onClick={(e) => {
                                                e.preventDefault();
                                                formikObj.handleSubmit();
                                                return false;
                                            }}
                                        >
                                            Save
                                        </button>
                                    </>
                                }
                            </>
                        }
                    </div>
                }
            </Modal>

            <SessionUpcomingCompletedModal
                isOpenModal={sessionCompletedModal}
                onCloseClick={() => setSessionCompletedModal(false)}
                activityName={getActivityName()}
                activityId={formikObj.values.activity_id}
                sessionLog={sessionCompletedLog}
                sessionType={1}
            />

        </React.Fragment>
    )
}

export default withRouter(Activity);

Activity.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    member_id: PropTypes.number,
    redirectPath: PropTypes.string,
    activity: PropTypes.number,
};