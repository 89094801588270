import { authStorage } from "utils/auth";
import {
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS
} from "./actionTypes"

const initialState = {
  user: "",
  token: authStorage.getToken() || null,
  error: "",
  loading: false,
}

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      state = {
        ...state,
        token: action.payload.token,
      }
      break;
    case LOGOUT_SUCCESS:
      state = {
        ...state,
        token: null,
      }
      break;
    default:
      state = { ...state };
      break;
  }
  return state
}

export default login
