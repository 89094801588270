import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useState } from "react";

import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// Formik formik 
import * as Yup from "yup";
import { useFormik } from "formik";
// actions
import { loginSuccess, loginUser } from "../../store/actions";

// import images
import profile from "assets/images/profile-img.png";
import logo from "assets/images/favicon.svg";
import { axiosInstance, apiUrls, graphql } from "api";
import { authStorage } from "utils/auth";

import { setProfileData, setProfileLoading } from 'store/actions'
import { VIEW_PROFILE_QUERY } from 'GraphQLquery/profileQry'

const Login = props => {
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false)
  const dispatch = useDispatch();

  const login = async (values) => {
    setProcessing(true);
    authStorage.deleteActivePaths();
    try {
      const response = await axiosInstance.post(apiUrls.auth.login, values);
      if (!!response) {
        dispatch(loginSuccess(response));
        setProcessing(false)
        authStorage.setToken(response.token);
        getProfileDetails();
        props.history.push("/dashboard");
      }
      else {
        setProcessing(false)
        setError("Login failed! Please Try again.")
      }
    } catch (err) {
      setProcessing(false)
      setError("Login failed! Please Try again.")
    }
  }

  const getProfileDetails = async () => {
    try {
      dispatch(setProfileLoading(true))
      // const res = await graphql({
      //   query: VIEW_PROFILE_QUERY
      // });
      const res = await axiosInstance.post(apiUrls.profile.me);
      const data = res.data;
      localStorage.setItem("loggedIn_user_id", res.data.id)
      // if (res.data.profile_id != 1) {
      // }
      dispatch(setProfileData({ ...data}));
      
    } catch (error) {
      dispatch(setProfileLoading(false))
    }

  }
  
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
      profile_id: 1,
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: login
  });

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login</title>
      </MetaTags>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        formik.handleSubmit();
                        return false;
                      }}
                    >
                      {error ? <Alert color="danger">{error}</Alert> : null}

                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email || ""}
                          invalid={
                            formik.touched.email && formik.errors.email ? true : false
                          }
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <FormFeedback type="invalid">{formik.errors.email}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <Input
                          name="password"
                          value={formik.values.password || ""}
                          type="password"
                          placeholder="Enter Password"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          invalid={
                            formik.touched.password && formik.errors.password ? true : false
                          }
                        />
                        {formik.touched.password && formik.errors.password ? (
                          <FormFeedback type="invalid">{formik.errors.password}</FormFeedback>
                        ) : null}
                      </div>

                      {!!processing ?
                        <div className="mt-3 d-grid">
                          <button
                            className="btn btn-primary btn-block"
                            disabled={true}
                          >
                            Processing
                          </button>
                        </div>
                        :
                        <div className="mt-3 d-grid">
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                          >
                            Log In
                          </button>
                        </div>}

                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password?
                        </Link>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
