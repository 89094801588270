import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useEffect, useState } from "react";
import {
    Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label, Button, Modal,
    ModalBody,
    ModalHeader,
    InputGroup
} from "reactstrap";
import { withRouter, Link } from "react-router-dom";

// Formik formikObj
import * as Yup from "yup";
import { useFormik } from "formik";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { standartDateFormat } from "utils/date";
import { axiosInstance, apiUrls } from "api";
import { authStorage } from "utils/auth";
import swal from 'sweetalert';
import profile_ids from "utils/profile_ids";
import { graphql } from "api";
import CommonTable from "components/Common/CommonTable";
import usePaginator from "hooks/usePaginator";
import * as TrainerQry from "GraphQLquery/trainerQry";
import { useSelector, useDispatch } from "react-redux";
import { setTrainerUpdated } from "../../store/actions";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import images from "assets/images";

const initialTrainerData = {
    name: "",
    trainer_age: "",
    profession: "",
    gender: "",
    // (1-Male /2-Female/3-transgender)
    address: "",
    primary_contact_no: "",
    email_id: "",
    status: 1,
    //member_access_code: "",
    secondary_contact_no: "",
    nick_name: "",
    joining_date: "",
    experience: "",
    position: "",
    trainer_type_details: [],
    profile_pic: "",
}
let trainerTypeArr = [];
let imageSrc = ''
const baseURL = process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_TRAINER_IMAGE

const EditTrainer = props => {
    const dispatch = useDispatch();
    const [trainerData, setTrainerData] = useState({ ...initialTrainerData })
    const [trainerId, setTrainerId] = useState(null)
    const [isSaving, setIsSaving] = useState(false);
    const [addEditTrainerModal, setAddEditTrainerModal] = useState(false);
    const [error, setError] = useState(null);
    const [invalidInputGender, setInvalidInputGender] = useState(true);
    const [invalidInputTrainer, setInvalidInputTrainer] = useState(true);
    const [paginatorInfo, setPaginatorInfo, resetPaginatorInfo] = usePaginator();
    const [searchText, setSearchText] = useState('');
    const [isPageLoading, setPageLoading] = useState(true);
    const [joining_date, setJoiningDate] = useState('');
    const [date_of_birth, setDateOfBirth] = useState('');
    const [trainerTypes, setTrainerType] = useState([]);
    const [processingList, setProcessingList] = useState(false);
    const [activityList, setActivityList] = useState([])

    const trainer_id = props.trainer_id

    useEffect(() => {
        fetchActivities()
        initAutocomplete()
        fetchTrainerType();
        handleFetch(trainer_id)
    }, [])

    const handleFetch = async (trainer_id) => {
        setTrainerId(trainer_id)
        const res = await graphql({
            query: TrainerQry.FETCH_ALL_TRAINER_QUERY,
            variables: {
                ...paginatorInfo,
                searchText: `%${searchText}%`,
                id: trainer_id,
                status_id: "%%"
            }
        });
        let responseData = !!res.data.trainers ? res.data.trainers.data[0] : []

        if (responseData?.trainer_type_details?.length > 0) {
            for (let index = 0; index < responseData?.trainer_type_details?.length; index++) {
                trainerTypeArr.push(responseData?.trainer_type_details?.[index]?.trainer_type_id)
            }
        }

        const fetchTrainerData = {
            id: responseData.id,
            name: responseData.name,
            nick_name: responseData.nick_name,
            gender: responseData.gender,
            address: responseData.address,
            primary_contact_no: responseData.primary_contact_no,
            email_id: responseData.email_id,
            status: responseData.status,
            secondary_contact_no: responseData.secondary_contact_no,
            joining_date: responseData.joining_date,
            experience: responseData.experience,
            position: responseData.position,
            date_of_birth: responseData.date_of_birth,
            trainer_type_details: trainerTypeArr,
            // profile_pic: responseData.profile_pic,
        }
        imageSrc = !!responseData.profile_pic ? baseURL + responseData?.profile_pic : images.defaultAvatar
        !!responseData.joining_date ? setJoiningDate(moment(new Date(responseData.joining_date)).format("DD-MM-YYYY")) : setJoiningDate("")
        !!responseData.date_of_birth ? setDateOfBirth(moment(new Date(responseData.date_of_birth)).format("DD-MM-YYYY")) : setDateOfBirth("")
        setTrainerData(fetchTrainerData)
        openModal()
    }

    const handleTrainerTypeChange = (e) => {
        let value = parseInt(e.target.value)
        if (e.target.checked) {
            let index = trainerTypeArr.indexOf(value)
            trainerTypeArr.splice(index, 1)
        }
        else {
            trainerTypeArr.push(value);
        }
        formikObj.setFieldValue(e.target.name, trainerTypeArr)
    }

    const fetchActivities = async () => {
        try {
            const res = await graphql({
                query: TrainerQry.ACTIVITIES_LIST_QUERY
            });
            if (res.data.activities) {
                let activities = res?.data?.activities || []
                setActivityList(activities)
            } else {
                setActivityList([])
            }
        } catch (error) {
            setActivityList([])
        }
    }


    const handleGenderChange = (e) => {
        formikObj.setFieldValue(e.target.name, e.target.value)
    }

    const initAutocomplete = () => {

        let autocomplete = new window.google.maps.places.Autocomplete(
            /** @type {!HTMLInputElement} */(document.getElementById("address")),
            { types: [] });

        window.google.maps.event.addListener(autocomplete, 'place_changed', () => {
            let place = autocomplete.getPlace();
            formikObj.setFieldValue("address", place.formatted_address)
            var componentForm = {
                country: 'long_name',
                locality: 'long_name',
                administrative_area_level_1: 'long_name',
                postal_code: 'short_name'
            };
            for (var i = 0; i < place.address_components.length; i++) {
                var addressType = place.address_components[i].types[0];
                if (componentForm[addressType]) {
                    var val = place.address_components[i][componentForm[addressType]];
                    if (addressType == "administrative_area_level_1") {
                        formikObj.setFieldValue("state", val)
                    }
                    if (addressType == "locality") {
                        formikObj.setFieldValue("city", val)
                    }
                    if (addressType == "postal_code") {
                        formikObj.setFieldValue("zip_code", val)
                    }
                    else {
                        formikObj.setFieldValue("zip_code", '')
                    }
                }
            }
        })

    }

    const formikObj = useFormik({
        enableReinitialize: true,

        initialValues: { ...trainerData },
        validationSchema: Yup.object({
            nick_name: Yup.string().required("Please Enter Nick Name"),
            name: Yup.string().required("Please Enter Trainer Name"),
            //trainer_age: Yup.number().required("Please Enter Age"),
            primary_contact_no: Yup.number().required("Please Enter Contact No."),
            email_id: Yup.string().required("Please Enter Email Id"),
        }),
        onSubmit: (values) => {
            if (values.gender == '') {
                setInvalidInputGender(false)
            }
            else if(values.trainer_type_details.length == 0){
                setInvalidInputTrainer(false)
            }
            else {
                setInvalidInputGender(true)
                setInvalidInputTrainer(true)
                updateTrainerData(values)
            }

        }
    });

    const updateTrainerData = async (values) => {

        try {
            setIsSaving(true)
            const formData = new FormData();
            for (let [key, value] of Object.entries(values)) {
                !!value ? formData.append(key, value) : formData.append(key, "")
            }
            const response = await axiosInstance.post(apiUrls.trainer.addAndUpdateTrainer, formData);
            if (response.success == 1) {
                formikObj.resetForm()
                setIsSaving(false)
                closeModal()
                swal("Success!", response.message, "success");
                dispatch(setTrainerUpdated(true));
                setTrainerData([])
                setTrainerId(null)
                //formikObj.setFieldValue("joining_date", standartDateFormat(new Date()))
            }
            else {
                setIsSaving(false)
                swal("Oops!", response.message, "error");
            }
        } catch (err) {
            setIsSaving(false)
            swal("Oops!", "Something went wrong!", "error");
            setError(err.message)
        }
    }

    const openModal = () => {
        setTimeout(() => {
            setAddEditTrainerModal(true);
            initAutocomplete()
        }, 2000);
    }

    const closeModal = () => {
        imageSrc = ''
        trainerTypeArr = []
        setAddEditTrainerModal(false);
        dispatch(setTrainerUpdated(false));
        formikObj.setFieldValue("date_of_birth", "")
        formikObj.setFieldValue("joining_date","")
        setDateOfBirth('')
        setJoiningDate('')
    }

    const handleNumber = (e) => {
        if (/^[0-9]+$/g.test(e.target.value) || e.target.value == '') {
            formikObj.setFieldValue(e.target.name, e.target.value)
        }
    }

    const joiningDateChange = (joiningDate) => {
        if (!!joiningDate[0]) {
            setJoiningDate(joiningDate[0].getDate() + '-' + (joiningDate[0].getMonth() + 1) + '-' + joiningDate[0].getFullYear())
            formikObj.setFieldValue("joining_date", standartDateFormat(joiningDate[0]))
        } else {
            setJoiningDate('')
            formikObj.setFieldValue("joining_date", '')
        }
    }

    const dateOfBirthSet = (dateofbirth) => {
        if (!!dateofbirth[0]) {
            setDateOfBirth(dateofbirth[0].getDate() + '-' + (dateofbirth[0].getMonth() + 1) + '-' + dateofbirth[0].getFullYear())
            formikObj.setFieldValue("date_of_birth", standartDateFormat(dateofbirth[0]))
        } else {
            setDateOfBirth('')
            formikObj.setFieldValue("date_of_birth", '')
        }
    }

    const fetchTrainerType = async () => {
        try {
            setProcessingList(true)
            const res = await graphql({
                query: TrainerQry.FETCH_TRAINER_TYPE_QUERY,
            });

            if (res.data.trainer_types) {
                setProcessingList(false)
                setTrainerType(!!res.data.trainer_types ? res.data.trainer_types : [])
            }

            else {
                setProcessingList(false)
                setTrainerType([])
            }
        } catch (error) {
            setProcessingList(false)
            setTrainerType([])
        }
    }

    const handleChangeFile = (e) => {
        imageSrc = URL.createObjectURL(e.target.files[0])
        if (e.target.files[0])
            formikObj.setFieldValue('profile_pic', e.target.files[0]);
    }


    return (
        <React.Fragment>
            <Modal
                isOpen={addEditTrainerModal}
                toggle={closeModal}
                size="lg"
                backdrop="static"
            // className={props.className}
            >
                <ModalHeader toggle={closeModal} tag="h4">
                    Edit Trainer
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col lg={12}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-InputService">Trainer Nick Name <span className="text-danger">*</span></Label>
                                    <Input
                                        type="text"
                                        id="formrow-InputService"
                                        className="form-control"
                                        name="nick_name"
                                        onChange={formikObj.handleChange}
                                        value={formikObj.values.nick_name}
                                        placeholder="Enter Nick Name"
                                        invalid={
                                            formikObj.touched.nick_name && formikObj.errors.nick_name ? true : false
                                        }
                                    />
                                    {formikObj.touched.nick_name && formikObj.errors.nick_name ? (
                                        <FormFeedback type="invalid">{formikObj.errors.nick_name}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-InputService">Trainer Full Name <span className="text-danger">*</span></Label>
                                    <Input
                                        type="text"
                                        id="formrow-InputService"
                                        className="form-control"
                                        name="name"
                                        onChange={formikObj.handleChange}
                                        value={formikObj.values.name}
                                        placeholder="Enter Trainer Full Name"
                                        invalid={
                                            formikObj.touched.name && formikObj.errors.name ? true : false
                                        }
                                    />
                                    {formikObj.touched.name && formikObj.errors.name ? (
                                        <FormFeedback type="invalid">{formikObj.errors.name}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-InputService">Primary Contact No. <span className="text-danger">*</span></Label>
                                    <Input
                                        type="text"
                                        id="formrow-InputService"
                                        className="form-control"
                                        name="primary_contact_no"
                                        onChange={(e) => { handleNumber(e) }}
                                        value={formikObj.values.primary_contact_no}
                                        placeholder="Enter Contact No."
                                        maxLength={10}
                                        invalid={
                                            formikObj.touched.primary_contact_no && formikObj.errors.primary_contact_no ? true : false
                                        }
                                    />
                                    {formikObj.touched.primary_contact_no && formikObj.errors.primary_contact_no ? (
                                        <FormFeedback type="invalid">{formikObj.errors.primary_contact_no}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-InputService">Secondary Contact No. <span className="text-danger">*</span></Label>
                                    <Input
                                        type="text"
                                        id="formrow-InputService"
                                        className="form-control"
                                        name="secondary_contact_no"
                                        onChange={(e) => { handleNumber(e) }}
                                        value={formikObj.values.secondary_contact_no}
                                        placeholder="Enter Secondary Contact No."
                                        maxLength={10}
                                        invalid={
                                            formikObj.touched.secondary_contact_no && formikObj.errors.secondary_contact_no ? true : false
                                        }
                                    />
                                    {formikObj.touched.secondary_contact_no && formikObj.errors.secondary_contact_no ? (
                                        <FormFeedback type="invalid">{formikObj.errors.secondary_contact_no}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-InputService">Email Address <span className="text-danger">*</span></Label>
                                    <Input
                                        type="email"
                                        id="formrow-InputService"
                                        className="form-control"
                                        name="email_id"
                                        onChange={formikObj.handleChange}
                                        value={formikObj.values.email_id}
                                        placeholder="Enter Email Address"
                                        invalid={
                                            formikObj.touched.email_id && formikObj.errors.email_id ? true : false
                                        }
                                    />
                                    {formikObj.touched.email_id && formikObj.errors.email_id ? (
                                        <FormFeedback type="invalid">{formikObj.errors.email_id}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                        </Row>
                        <Row>


                        </Row>
                        <Row>
                            <Col lg={8}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-InputService">Select Gender <span className="text-danger">*</span></Label>
                                    <div>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="gender"
                                            id="exampleRadios2"
                                            value="1"
                                            onClick={(e) => { handleGenderChange(e) }}
                                            checked={formikObj.values.gender == 1}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="exampleRadios2"
                                        >
                                            Male
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="gender"
                                            id="exampleRadios2"
                                            value="2"
                                            onClick={(e) => { handleGenderChange(e) }}
                                            checked={formikObj.values.gender == 2}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="exampleRadios2"
                                        >
                                            Female
                                        </label>
                                    </div>
                                    </div>
                                    {invalidInputGender == false && <div className="custom-invalid">Please Select Gender</div>}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-InputService">Joining Date</Label>
                                    <Flatpickr
                                        className="form-control d-block"
                                        name="joining_date"
                                        placeholder="DD-MM-YYYY"
                                        options={{
                                            altInput: true,
                                            altFormat: "d-m-Y",
                                            dateFormat: "d-m-Y",
                                            //minDate: today
                                        }}
                                        onChange={joiningDateChange}
                                        value={joining_date}
                                    />
                                </div>
                            </Col>

                            <Col lg={6}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-InputService">Date Of Birth</Label>
                                    <Flatpickr
                                        className="form-control d-block"
                                        name="date_of_birth"
                                        placeholder="DD-MM-YYYY"
                                        options={{
                                            altInput: true,
                                            altFormat: "d-m-Y",
                                            dateFormat: "d-m-Y",
                                            // minDate: entry_date
                                        }}
                                        onChange={dateOfBirthSet}
                                        // value={next_measurement_day || entry_date}
                                        value={date_of_birth}
                                    />
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={12}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-InputAddress">Address</Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        id="address"
                                        placeholder="Enter Address"
                                        name="address"

                                        // -------- for autocomplete --------
                                        onChange={formikObj.handleChange}
                                        value={formikObj.values.address}
                                        autoComplete="address"
                                        invalid={
                                            formikObj.touched.address && formikObj.errors.address ? true : false
                                        }
                                    />
                                    {formikObj.touched.address && formikObj.errors.address ? (
                                        <FormFeedback type="invalid">{formikObj.errors.address}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-InputAddress">Profile Image</Label>
                                    <Input
                                        type="file"
                                        className="form-control"
                                        name="profile_pic"
                                        onChange={(e) => handleChangeFile(e)}
                                    />
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <span className="logo-lg">
                                        <img src={imageSrc} height={100} width={100} alt="profile" className="avatar-sm rounded-circle img-thumbnail" />
                                        <span className="extra-space"></span>
                                    </span>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={12}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-InputService">Trainer Type <span className="text-danger">*</span></Label>
                                    <div>
                                        {trainerTypes.map((item, index) => (
                                            <div key={index} className="form-check form-check-inline font-size-16">
                                                <Input
                                                    type="checkbox"
                                                    id="customRadioInline"
                                                    name="trainer_type_details"
                                                    className="form-check-input"
                                                    onClick={(e) => { handleTrainerTypeChange(e) }}
                                                    value={item.id}
                                                    checked={formikObj.values?.trainer_type_details?.indexOf(item.id) > -1}
                                                />
                                                <Label
                                                    className="form-check-label font-size-13"
                                                    htmlFor="customRadioInline"
                                                >
                                                    {item.trainer_type}
                                                </Label>
                                            </div>
                                        ))
                                        }
                                    </div>
                                    {invalidInputTrainer == false && <div className="custom-invalid">Please Select Trainer(s)</div>}
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-InputService">Experience</Label>
                                    <Input
                                        type="text"
                                        id="formrow-InputService"
                                        className="form-control"
                                        name="experience"
                                        onChange={formikObj.handleChange}
                                        value={formikObj.values.experience}
                                        placeholder="Enter Your Experience"
                                        invalid={
                                            formikObj.touched.experience && formikObj.errors.experience ? true : false
                                        }
                                    />
                                    {formikObj.touched.experience && formikObj.errors.experience ? (
                                        <FormFeedback type="invalid">{formikObj.errors.experience}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-InputService">Position</Label>
                                    <Input
                                        type="text"
                                        id="formrow-InputService"
                                        className="form-control"
                                        name="position"
                                        onChange={formikObj.handleChange}
                                        value={formikObj.values.position}
                                        placeholder="Enter Position"
                                        invalid={
                                            formikObj.touched.position && formikObj.errors.position ? true : false
                                        }
                                    />
                                    {formikObj.touched.position && formikObj.errors.position ? (
                                        <FormFeedback type="invalid">{formikObj.errors.position}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                        </Row>
                    </Form>

                </ModalBody>
                <div className="modal-footer">
                    {!!isSaving ?
                        <button className="btn btn-primary " disabled>
                            Processing
                        </button>
                        :
                        <>
                            <button
                                type="button"
                                onClick={closeModal}
                                className="btn btn-secondary "
                                data-dismiss="modal"
                            >
                                Close
                            </button>
                            <button
                                type="submit"
                                className="btn btn-primary "
                                onClick={(e) => {
                                    e.preventDefault();
                                    formikObj.handleSubmit();
                                    return false;
                                }}
                            >
                                {trainerId == null ? "Save" : "Update"}
                            </button>
                        </>
                    }
                </div>
            </Modal>
        </React.Fragment>
    )
}

export default withRouter(EditTrainer);

EditTrainer.propTypes = {
    history: PropTypes.object,
    trainer_id: PropTypes.number
};