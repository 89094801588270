import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { Fragment, useEffect, useState } from "react";
import {
    Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label, Button, Modal,
    ModalBody,
    ModalHeader,
    InputGroup, Table
} from "reactstrap";
import { withRouter, Link } from "react-router-dom";

// Formik formikObj
import * as Yup from "yup";
import { useFormik } from "formik";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { standartDateFormat } from "utils/date";
import { axiosInstance, apiUrls } from "api";
import swal from 'sweetalert';
import { graphql } from "api";
import CommonTable from "components/Common/CommonTable";
import usePaginator from "hooks/usePaginator";
import moment from "moment";
import images from "assets/images";
const imageURL = process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_ADS;


const initialAdsData = {
    ads_img: "",
    remarks: ""
}
let accessoryArr = [];
let activities_data = []
let imageSrc = '';

const ManageAds = props => {

    const [adsData, setAdsData] = useState({ ...initialAdsData })
    const [adsId, setAdsId] = useState(null)
    const [isSaving, setIsSaving] = useState(false);
    const [processingList, setProcessingList] = useState(false);
    const [addEditAdsModal, setAddEditAdsModal] = useState(false);
    const [error, setError] = useState(null);
    const [allAdsList, setAllAdsList] = useState([]);
    const [planPatterns, setPlanPatterns] = useState([]);
    const [planTypes, setPlanTypes] = useState([]);
    const [selectPlanType, setSelectPlanType] = useState(true)
    const [paginatorInfo, setPaginatorInfo, resetPaginatorInfo] = usePaginator();
    const [searchText, setSearchText] = useState('');
    const [accessories, setAccessories] = useState([]);

    const [activityList, setActivityList] = useState([])
    const [activityTypes, setActivityTypes] = useState([]);
    const [activityError, setActivityError] = useState(false);

    useEffect(async () => {
        getAllAds(paginatorInfo, searchText);

    }, [])

    const handleFetch = async (e, row) => {
        setMemberId(row.id)
        setEditMemberModal(true)
    }

    const handleDelete = (e, row) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (willDelete) => {
                if (willDelete) {
                    try {
                        const response = await axiosInstance.post(apiUrls.ads.deleteAds, { id: row.id });
                        if (response.success == 1) {
                            swal("Success!", response.message, "success");
                            getAllAds(paginatorInfo, searchText);
                        }
                        else {
                            swal("Oops!", response.message, "error");
                        }
                    } catch (err) {
                        swal("Oops!", "Something went wrong!", "error");
                        setError(err.message)
                    }
                }
            });
    }

    const handleChangeStatus = (e, row) => {
        swal({
            title: "Are you sure?",
            text: "You want to change the Ad status!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (willChange) => {
                if (willChange) {
                    try {
                        const response = await axiosInstance.post(apiUrls.ads.activeAndInactiveAds, { id: row.id, status: row.status == 0 ? 1 : 0 });
                        if (response.success == 1) {
                            swal("Success!", response.message, "success");
                            getAllAds(paginatorInfo, searchText);
                        }
                        else {
                            swal("Oops!", response.message, "error");
                        }
                    } catch (err) {
                        swal("Oops!", "Something went wrong!", "error");
                        setError(err.message)
                    }
                }
            });
    }

    const handleAction = (cell, row) => {
        return (
            <>
                <div className="d-flex">
                    {/* <Button className="btn btn-info btn-sm ms-2" onClick={(e) => { handleFetch(e, row) }}>
                        <i className="bx bx-pencil align-middle" />
                    </Button> */}
                    <Button className="btn btn-danger btn-sm ms-2" onClick={(e) => { handleDelete(e, row) }}>
                        <i className="bx bx-trash align-middle" />
                    </Button>
                    <Button className="btn btn-secondary btn-sm ms-2" onClick={(e) => { handleChangeStatus(e, row) }}>
                        {row.status == 0 ? "Active" : "Inactive"}
                    </Button>
                </div>
            </>
        );
    };


    const columns = [
        {
            formatter: (cell, row) => <img
                src={imageURL + row.ads_img}
                alt="ads"
                height={150}
                width={150}
                className="img-thumbnail"
            />,
            dataField: 'ads_img',
            text: 'ADs',
            sort: true,
        },
        {
            dataField: 'remarks',
            text: 'REMARKS',
            sort: true,
        },
        {
            formatter: (cell, row) => (row.status == 1 ? <span className="badge rounded-pill bg-success px-2 py-1">Active</span> : <span className="badge rounded-pill bg-danger px-2 py-1">Inactive</span>),
            text: 'STATUS',
            sort: true,
        },
        {
            dataField: '',
            text: 'ACTION',
            sort: true,
            formatter: handleAction
        }
    ]

    const getAllAds = async (paginatorInfo, searchText) => {
        setProcessingList(true);
        try {
            const response = await axiosInstance.post(apiUrls.ads.listOfAds + "?page=" + paginatorInfo.currentPage,
                {
                    offset: !!paginatorInfo.perPage ? paginatorInfo.perPage : offSet,
                    search_text: searchText
                });
            if (response.data.length > 0) {
                setAllAdsList(response.data);
                let paginatorInfo = {
                    currentPage: parseInt(response?.current_page),
                    perPage: parseInt(response?.per_page),
                    total: parseInt(response?.total),
                }
                setPaginatorInfo(paginatorInfo);
                setSearchText(searchText);
            }
            else {
                setAllAdsList([]);
                resetPaginatorInfo();
            }
        } catch (error) {
            setAllAdsList([]);
            resetPaginatorInfo();

        }
        finally {
            setProcessingList(false);
        }

    }

    const formikObj = useFormik({
        enableReinitialize: true,

        initialValues: { ...adsData },
        // validationSchema: Yup.object({
        //     plan_name: Yup.string().required("Please Enter Plan Name"),
        //     plan_pattern_id: Yup.string().required("Please Select Plan Pattern"),
        //     total_amount: Yup.string().required("Please Enter Price"),
        // }),
        onSubmit: async (values) => {
            try {
                const formData = new FormData();
                for (let [key, value] of Object.entries(values)) {
                    // formData.append(key, value)
                    !!value ? formData.append(key, value) : formData.append(key, "")
                }

                setIsSaving(true)
                const response = await axiosInstance.post(apiUrls.ads.createNewAds, formData);
                if (response.success == 1) {
                    formikObj.resetForm()
                    setIsSaving(false)
                    closeModal()
                    swal("Success!", response.message, "success");
                    // fetchMemberListData(paginatorInfo, searchText, memberStatus, memberMember_Status, memberTiming, mainTrainer, subscriptionPlan, userStatus, activityId, startDate, endDate)
                    // setMemberData([])
                }
                else {
                    setIsSaving(false)
                    swal("Oops!", response.message, "error");
                }
            } catch (err) {
                setIsSaving(false)
                swal("Oops!", "Something went wrong!", "error");
                setError(err.message)
            }
        }
    });

    const createSubscription = () => {
        setAdsId(null)
        setAdsData({ ...initialAdsData });
        accessoryArr = []
        formikObj.resetForm();
        openModal()
    }

    const openModal = () => {
        imageSrc = images.defaultAvatar;
        setAddEditAdsModal(true);
    }

    const closeModal = () => {
        accessoryArr = []
        activities_data = []
        formikObj.resetForm();
        setAddEditAdsModal(false);
        getAllAds(paginatorInfo, searchText);
    }

    const handleChangeFile = (e) => {
        imageSrc = URL.createObjectURL(e.target.files[0])
        if (e.target.files[0])
            formikObj.setFieldValue('ads_img', e.target.files[0]);
    }

    const handleTableChange = (paginatorInfo, searchText) => {
        fetchMemberListData(paginatorInfo, searchText, memberStatus, memberMember_Status, memberTiming, mainTrainer, subscriptionPlan, userStatus, activityId, startDate, endDate)
        getAllAds(paginatorInfo, searchText);

    }

    return (
        <Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Manage Ads</title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumbs title="Manage Ads" breadcrumbItem="Manage Ads" />
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col lg={3}>
                                            <Button
                                                color="primary"
                                                className="font-size-13 btn-block"
                                                onClick={createSubscription}
                                            >
                                                <i className="mdi mdi-plus-circle-outline me-1 align-middle font-size-16" />
                                                Create
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    {/* Datatable view start */}

                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col lg={12}>
                                            <h3>All Ads List</h3><br />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                            <CommonTable
                                                paginatorInfo={paginatorInfo}
                                                searchText={searchText}
                                                onTableChange={handleTableChange}
                                                keyField='id'
                                                columns={columns}
                                                data={allAdsList}
                                                loading={processingList}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    {/* Datatable view end */}

                    <Modal
                        isOpen={addEditAdsModal}
                        toggle={closeModal}
                        size="lg"
                        backdrop="static"
                    // className={props.className}
                    >
                        <ModalHeader toggle={closeModal} tag="h4">
                            {adsId == null ? "Create New Ads" : "Edit Ads"}
                        </ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col lg={6}>
                                    <div className="mb-3">
                                        <Label htmlFor="formrow-InputAddress">Image</Label>
                                        <Input
                                            type="file"
                                            className="form-control"
                                            name="ads_img"
                                            onChange={(e) => handleChangeFile(e)}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row style={{ textAlign: "center" }}>
                                <Col lg={12}>
                                    <div className="mb-3">
                                        <span className="logo-lg">
                                            <img src={imageSrc} height={250} width={250} alt="profile" className="" />
                                            <span className="extra-space"></span>
                                        </span>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <div className="mb-3">
                                        <Label htmlFor="formrow-InputAddress">Description</Label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            name="remarks"
                                            onChange={formikObj.handleChange}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </ModalBody>
                        <div className="modal-footer">
                            {!!isSaving ?
                                <button className="btn btn-primary " disabled>
                                    Processing
                                </button>
                                :
                                <>
                                    <button
                                        type="button"
                                        onClick={closeModal}
                                        className="btn btn-secondary "
                                        data-dismiss="modal"
                                    >
                                        Close
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn btn-primary "
                                        onClick={(e) => {
                                            e.preventDefault();
                                            formikObj.handleSubmit();
                                            return false;
                                        }}
                                    >
                                        Save
                                    </button>
                                </>
                            }
                        </div>
                    </Modal>

                </Container>
            </div>
        </Fragment>
    );
}
export default withRouter(ManageAds);

ManageAds.propTypes = {
    history: PropTypes.object,
};