import { MEMBER_UPDATED } from "./actionTypes";

const initialState = {
    memberUpdateStatus: false
}

const MemberUpdated = (state = initialState, action) => {
    switch (action.type) {
        case MEMBER_UPDATED:
            state = { memberUpdateStatus: action.memberUpdateStatus }
            break;
        default:
            state = { memberUpdateStatus: null };
            break;
    }
    return state;
}

export default MemberUpdated