import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useEffect, useState } from "react";
import {
    Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label, Button, Modal,
    ModalBody,
    ModalHeader,
    InputGroup,
    Collapse
} from "reactstrap";
import { withRouter, Link } from "react-router-dom";

// Formik formikObj
import * as Yup from "yup";
import { useFormik } from "formik";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { standartDateFormat } from "utils/date";
import { axiosInstance, apiUrls } from "api";
import swal from 'sweetalert';
import { graphql } from "api";

// datatable related plugins

import CommonTable from "components/Common/CommonTable";
import usePaginator from "hooks/usePaginator";
import * as DietQry from "GraphQLquery/dietQry";

const ManageExercise = props => {

    const [error, setError] = useState(null);
    const [processingList, setProcessingList] = useState(false);
    const [allDietPlanList, setAllDietPlanList] = useState([]);
    const [paginatorInfo, setPaginatorInfo, resetPaginatorInfo] = usePaginator();
    const [searchText, setSearchText] = useState('');
    const [dietPlanData, setDietPlanData] = useState({})
    const [viewDietPlanModal, setViewDietPlanModal] = useState(false);
    const [colIndex, setColIndex] = useState(0);
    let colCount = []

    useEffect(() => {
        fetchAllDietPlanListData(paginatorInfo, searchText)
    }, [])

    const fetchAllDietPlanListData = async (paginatorInfo, searchText) => {
        try {
            setProcessingList(true)
            const res = await graphql({
                query: DietQry.FETCH_ALL_DIET_PLAN_QUERY,
                variables: {
                    ...paginatorInfo,
                    searchText: `%${searchText}%`,
                    id: "%%"
                }
            });

            if (res.data.diet_plans.data) {
                setProcessingList(false)
                setAllDietPlanList(!!res.data.diet_plans ? res.data.diet_plans.data : [])
                setPaginatorInfo(res?.data?.diet_plans?.paginatorInfo);
            }

            else {
                setProcessingList(false)
                setAllDietPlanList([])
                resetPaginatorInfo();
            }
        } catch (error) {
            setProcessingList(false)
            setAllDietPlanList([])
            resetPaginatorInfo();
        }
    }

    const handleTableChange = (paginatorInfo, searchText) => {
        fetchAllDietPlanListData(paginatorInfo, searchText)
    }

    const handleAction = (cell, row) => {
        localStorage.setItem("d_memberId","")
        localStorage.setItem("d_id","")
        return (
            <>
                <div className="d-flex">
                    <Link
                            to="/diet-view"
                            onClick={(e) => {
                                localStorage.setItem("memberWise", false)
                                !!row.id ? localStorage.setItem("d_id", row.id) : localStorage.setItem("d_member_id", row.member_id)
                            }}
                            target="_blank"
                            className="btn btn-warning  btn btn-info btn-sm ms-2">
                            <i className="fas fa-eye font-size-16 align-middle" />
                        </Link>
                        <Button className="btn-info btn-sm ms-2" onClick={(e) =>
                            props.history.push({
                                pathname: "/edit-diet-plan/",
                                state: { dietPlanId: row.id, assignMemberDietPlan: false }
                            }
                            )}
                        >
                            <i className="bx bx-pencil align-middle" />
                        </Button>
                        <Button className="btn-danger btn-sm ms-2" onClick={(e) => { handleDelete(e, row) }}>
                            <i className="bx bx-trash align-middle" />
                        </Button>
                        {/* &nbsp;
                        <Button className="btn btn-info  btn btn-info btn-sm" onClick={(e) => { handleChangeStatus(e, row) }}>
                            {row.status == 0 ? "Active" : "Inactive"}
                        </Button> */}
                </div>
            </>
        );
    };

    // Column definition for datatable
    const columns = [{
        dataField: 'plan_name',
        text: 'PLAN NAME',
        sort: true,
    },
    {
        dataField: 'dietitian_name',
        text: 'DIETITIAN NAME',
        sort: true,
    },
    {
        dataField: 'diet_plan_category_details[category_name]',
        text: 'CATEGORY',
        sort: true,
    },
    {
        dataField: '',
        text: 'ACTION',
        sort: true,
        formatter: handleAction
    }];

    const handleFetch = async (e, row) => {
        try {
            const res = await graphql({
                query: DietQry.FETCH_ALL_DIET_PLAN_QUERY,
                variables: {
                    ...paginatorInfo,
                    searchText: `%${searchText}%`,
                    id: row.id
                }
            });
            if (res.data.diet_plans.data) {
                setDietPlanData(!!res.data.diet_plans ? res.data.diet_plans.data : [])
                setViewDietPlanModal(true);
            }

            else {
                setDietPlanData(null)
            }
        } catch (error) {
            setError(error)
            setDietPlanData(null)
        }
    }

    const handleDelete = (e, row) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (willDelete) => {
                if (willDelete) {
                    try {
                        const response = await axiosInstance.post(apiUrls.diet.deleteDietPlan, { id: row.id });
                        if (response.success == 1) {
                            swal("Success!", response.message, "success");
                            fetchAllDietPlanListData(paginatorInfo, searchText)
                        }
                        else {
                            swal("Oops!", response.message, "error");
                        }
                    } catch (err) {
                        swal("Oops!", "Something went wrong!", "error");
                        setError(err.message)
                    }
                }
            });
    }

    const closeModal = () => {
        setViewDietPlanModal(false);
    }

    const handleCol = (e, index) => {
        setColIndex(index)
    }

    // const renderDietPlanDetails = (diet_plan_details) => {
    //     return diet_plan_details.map((data, index) => {
    //         return <div className="accordion-item" key={index}>
    //             <h1 className="accordion-header" id="headingOne">
    //                 <button
    //                     onClick={(e) => handleCol(e, index)}
    //                     data-event={index}
    //                     style={{ cursor: "pointer" }}
    //                     className={classnames(
    //                         "accordion-button",
    //                         "fw-medium",
    //                         { collapsed: colIndex == index ? false : true }
    //                     )}
    //                 >
    //                     {data?.time}&nbsp;&nbsp;
    //                 </button>
    //             </h1>
    //             <Collapse isOpen={colIndex === index} className="accordion-collapse">
    //                 <CardBody className="accordion-body">
    //                     <Row>
    //                         <Col lg={4}>
    //                             <Label className="col-form-label">
    //                                 <b>Start Date</b>
    //                             </Label>
    //                             <p>{moment(data?.beginning_date).format('DD-MM-YYYY')}</p>
    //                         </Col>
    //                         <Col lg={4}>
    //                             <Label className="col-form-label">
    //                                 <b>End Date</b>
    //                             </Label>
    //                             <p>{moment(data?.end_date).format('DD-MM-YYYY')}</p>
    //                         </Col>
    //                         <Col lg={4}>
    //                             <Label className="col-form-label">
    //                                 <b>Invoice No.</b>
    //                             </Label>
    //                             <p>{data?.invoice_num}</p>
    //                         </Col>
    //                     </Row>
    //                 </CardBody>
    //             </Collapse>
    //         </div>

    //     })

    // }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Manage Diet Plan</title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumbs title="Diet Plan" breadcrumbItem="Manage Diet Plan" />
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col lg={3}>
                                            <Link to="/add-diet-plan">
                                                <Button
                                                    color="primary"
                                                    className="font-size-13 btn-block"
                                                >
                                                    <i className="mdi mdi-plus-circle-outline me-1 align-middle font-size-16" />Create
                                                </Button>
                                            </Link>

                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    {/* Datatable view start */}

                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col lg={12}>
                                            <h3>All Diet Plan List</h3><br />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                            <CommonTable
                                                paginatorInfo={paginatorInfo}
                                                searchText={searchText}
                                                onTableChange={handleTableChange}
                                                keyField='id'
                                                columns={columns}
                                                data={allDietPlanList}
                                                loading={processingList}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    {/* Datatable view end */}
                </Container>
            </div>

            <Modal
                isOpen={viewDietPlanModal}
                toggle={closeModal}
                size="lg"
                backdrop="static"
            // className={props.className}
            >
                <ModalHeader toggle={closeModal} tag="h4">
                    Diet Plan Detail
                </ModalHeader>
                <ModalBody>
                    <Card>
                        <CardBody>
                            {/* {renderDietPlanDetails(dietPlanData?.diet_plan_details)} */}
                        </CardBody>
                    </Card>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );

}

export default withRouter(ManageExercise);

ManageExercise.propTypes = {
    history: PropTypes.object,
};