import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import { apiUrls, axiosInstance } from "api";
import { useSelector } from "react-redux";

const SidebarContent = props => {
  const ref = useRef();
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously

  useEffect(() => {
    const pathName = process.env.REACT_APP_BASE_PATHNAME + props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <Link to="/dashboard">
                <i className="bx bx-home-circle"></i>
                <span>Dashboard</span>
              </Link>
            </li>
            <li>
              <Link to="/subscription">
                <i className="bx bx-money"></i>
                <span>Subscription Plan</span>
              </Link>
            </li>
            <li>
              <Link to="/member">
                <i className="fas fa-user-friends font-size-15"></i>
                <span>Manage Members</span>
              </Link>
            </li>
            <li>
              <Link to="/membership">
                <i className="bx bx-user-plus"></i>
                <span>Manage Membership</span>
              </Link>
            </li>

            <li>
              <Link to="/trainer">
                <i className="bx bx-walk"></i>
                <span>{props.t("Trainer")}</span>
              </Link>
            </li>

            {/* <li>
              <Link to="/members-workout-plan">
                <i className="bx bx-walk"></i>
                <span>{props.t("Members Workout Plan")}</span>
              </Link>
            </li> */}

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-dish"></i>
                <span>{props.t("Diet Chart")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="true">
                <li>
                  <Link to="/manage-diet-plan">{props.t("Manage Diet Plan")}</Link>
                </li>
                <li>
                  <Link to="/members-diet-chart">{props.t("Members Diet chart")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-dumbbell"></i>
                <span>{props.t("Workout Plan")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="true">
                <li>
                  <Link to="/manage-exercise">{props.t("Manage Exercise")}</Link>
                </li>
                <li>
                  <Link to="/manage-workout-plan">{props.t("Manage Workout Plan")}</Link>
                </li>
                <li>
                  <Link to="/members-workout-plan">{props.t("Members Workout Plan")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/manage-body-composition">
                <i className="bx bx-chart"></i>
                <span>Body Composition</span>
              </Link>
            </li>

            <li>
              <Link to="/manage-batch">
                <i className="bx bx-notepad"></i>
                <span>Manage Batch</span>
              </Link>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-wifi-0"></i>
                <span>{props.t("Report")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="true">
                <li>
                  <Link to="/financial-report">{props.t("Financial Report")}</Link>
                </li>
                <li>
                  <Link to="/renewal-report">{props.t("Renewal Report")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-dots-vertical-rounded"></i>
                <span>{props.t("Activities")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="true">
                <li>
                  <Link to="/assign-zumba-classes">{props.t("Zumba Classes")}</Link>
                </li>
                <li>
                  <Link to="/yoga-classes">{props.t("Yoga Classes")}</Link>
                </li>
                <li>
                  <Link to="/steam-bath">{props.t("Steam Bath")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/manage-ads">
                <i className="bx bx-notepad"></i>
                <span>Manage Ads</span>
              </Link>
            </li>

          </ul>
        </div>


      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
