import { TRAINER_UPDATED } from "./actionTypes";

const initialState = {
    trainerUpdateStatus: false
}

const TrainerUpdated = (state = initialState, action) => {
    switch (action.type) {
        case TRAINER_UPDATED:
            state = { trainerUpdateStatus: action.trainerUpdateStatus }
            break;
        default:
            state = { trainerUpdateStatus: null };
            break;
    }
    return state;
}

export default TrainerUpdated