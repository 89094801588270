import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import AssignMemberWorkoutPlan from "pages/Common/AssignMemberWorkoutPlan";

const MemberWorkoutPlan = props => {

    return (
        <React.Fragment>
            <AssignMemberWorkoutPlan
                member_id={null}
                assignWorkoutModal={false}
                redirectPath="/members-workout-plan"
            />
        </React.Fragment>
    );
}

export default withRouter(MemberWorkoutPlan);

MemberWorkoutPlan.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
};