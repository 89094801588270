import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useEffect, useState } from "react";
import {
    Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label, Button, Modal,
    ModalBody,
    ModalHeader,
    InputGroup, Table
} from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
// Formik formikObj
import * as Yup from "yup";
import { FieldArray, FormikProvider, useFormik } from "formik";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { standartDateFormat, today, userDateFormat } from "utils/date";
import { axiosInstance, apiUrls } from "api";
import swal from 'sweetalert';
import { graphql } from "api";

// datatable related plugins

import CommonTable from "components/Common/CommonTable";
import usePaginator from "hooks/usePaginator";
import * as SubscriptionQry from "GraphQLquery/subscriptionQry";
import * as MemberQry from "GraphQLquery/memberQry";
import * as MembershipQry from "GraphQLquery/membershipQry";
import { round } from "lodash";
import moment from "moment";

import logo from "assets/images/favicon.svg";
import paid from "assets/images/paid.png";
import { setIsSubmit } from "redux-form";
import Invoice from "pages/Common/Invoice";
import Select from "react-select";

const initialMembershipData = {
    member_id: "",
    subscription_plan_id: "",
    beginning_date: "",
    end_date: "",
    number_of_sessions: "",
    subscription_plan_charges: 0,
    discount: "",
    preferred_timing: "1",
    // (1- Any time / 2-Off Peak)
    gym_timing_from: "",
    gym_timing_to: "",
    floor: [],
    // (1-Cardio /2-Strength)
    member_access_code: "",
    gst_amount: 0,
    total_amount: 0,
    status: 1,
    payment_mode_id: "",

    // activities_data: [{
    //     activity_id: "",
    //     activity_type_id: "",
    //     no_of_sessions: ""
    // }]
}

const gst_rate = 0.18
let editGrossAmount = ''
let editSubscriptionPlanType = ''
let editSubscriptionAmount = ''
let sub_total = 0
let allMember = []
let floorArr = [];

const Membership = props => {

    const [membershipData, setMembershipData] = useState({ ...initialMembershipData })
    const [membershipId, setMembershipId] = useState(null)
    const [isSaving, setIsSaving] = useState(false);
    const [addEditMembershipModal, setAddEditMembershipModal] = useState(false);
    const [invoiceModalOpen, setInvoiceModalOpen] = useState(false);
    const [error, setError] = useState(null);
    const [processingList, setProcessingList] = useState(false);
    const [membershipProcessingList, setMembershipProcessingList] = useState(false);
    const [processingMemberList, setProcessingMemberList] = useState(false);
    const [allSubscriptionList, setAllSubscriptionList] = useState([]);
    const [allMemberList, setAllMemberList] = useState([]);
    const [allMembershipsList, setAllMembershipsList] = useState([]);
    const [allFloorTypeList, setAllFloorTypeList] = useState([]);
    const [paginatorInfo, setPaginatorInfo, resetPaginatorInfo] = usePaginator();
    const [searchText, setSearchText] = useState('');
    const [beginning_date, setBeginingDate] = useState('');
    const [end_date, setEndDate] = useState('');
    const [subscriptionPlanType, setSubscriptionPlanType] = useState('')
    const [grossAmount, setGrossAmount] = useState('')
    const [subscriptionAmount, setSubscriptionAmount] = useState('')
    const [planPattern, setPlanPattern] = useState('')
    const [minDate, setMinDate] = useState("");
    const [invoiceData, setInvoiceData] = useState('')
    const [discountErrorMsg, setDiscountErrorMsg] = useState('')
    const [isSubmit, setIsSubmit] = useState(true)
    const [preferTimingType, setPreferTimingType] = useState([])
    const [activityList, setActivityList] = useState([])
    const [activityTypes, setActivityTypes] = useState([])
    const [paymenyType, setPaymenyType] = useState([]);
    const [offSet, setOffSet] = useState('10')
    const [pageLinks, setPageLinks] = useState([])
    const [pageURL, setPageURL] = useState('1')
    const [status, setStatus] = useState(1)
    const [membershipTiming, setMembershipTiming] = useState("")
    const [membershipMode, setMembershipMode] = useState("")
    const [subscriptionPlanList, setSubscriptionPlanList] = useState([])
    const [subscriptionPlan, setSubscriptionPlan] = useState("")
    const [membershipName, setMembershipName] = useState("")
    const [searchMemberId, setSearchMemberId] = useState(null);
    const [tempMemberArr, setTempMemberArr]= useState([])

    useEffect(() => {
        fetchMembershipsListData(paginatorInfo, searchText, membershipName, subscriptionPlan, membershipMode, membershipTiming, status)
        fetchMemberListData()
        // fetchSubscriptionPlanList();
        fetchSubscriptionListData(paginatorInfo, searchText)
        fetchFloorTypes()
        fetchPreferredTimingTypes()
        fetchPaymenyType();
        // fetchActivityTypes();
        // fetchActivities();
    }, [])

    const fetchMembershipsListData = async (paginatorInfo, searchText, membershipName, subscriptionPlan, membershipMode, membershipTiming, status) => {
        try {
            setMembershipProcessingList(true)
            const res = await axiosInstance.post(apiUrls.membership.allMembershipList + "?page=" + pageURL, { offset: offSet, search_text: searchText, name: membershipName, subscription_plan: subscriptionPlan, mode: membershipMode, timing: membershipTiming, status });
            if (res.data) {
                setMembershipProcessingList(false)
                setAllMembershipsList(!!res.data ? res.data : [])
                setPageLinks(!!res.data.length > 0 ? res?.links : [])

            }

            else {
                setMembershipProcessingList(false)
                setAllMembershipsList([])
                resetPaginatorInfo();
            }
        } catch (error) {
            setMembershipProcessingList(false)
            setAllMembershipsList([])
            resetPaginatorInfo();
        }
    }

    const fetchSubscriptionPlanList = async () => {
        try {
            const res = await graphql({
                query: SubscriptionQry.FETCH_ALL_SUBSCRIPTIONS_QUERY_FOR_MEMBER
            });

            if (res.data.subscription_plans.data) {
                setSubscriptionPlanList(!!res.data.subscription_plans ? res.data.subscription_plans.data : []);
            }
            else {
                setSubscriptionPlanList([]);
            }
        } catch (error) {
            setSubscriptionPlanList([]);
            console.log(error);
        }
    }

    useEffect(() => {
        // !!membershipId == false && 
        fetchMembershipsListData(paginatorInfo, searchText, membershipName, subscriptionPlan, membershipMode, membershipTiming, status)
    }, [offSet, pageURL, searchText])

    const handleOffsetChange = (e) => {
        setOffSet(e.target.value)
    }

    const getPageNumber = (e, data) => {
        e.preventDefault()
        if (parseInt(data.label)) {
            setPageURL(data.label)
        } else {
            let pageNumber = data.url.split("=")
            setPageURL(pageNumber[1])
        }

    }

    const fetchMemberListData = async () => {
        allMember = []
        try {
            setProcessingMemberList(true)
            const res = await graphql({
                query: MemberQry.FETCH_ALL_MEMBERS_FOR_MEMBERSHIP_QUERY,
                variables: {
                    // ...paginatorInfo,
                    // searchText: `%${searchText}%`,
                    // id: "%%",
                    // status_id: "%%"
                }
            });

            if (res.data.members.data) {
                setProcessingMemberList(false)
                setAllMemberList(!!res.data.members ? res.data.members.data : [])
                allMember = !!res.data.members ? res.data.members.data : []
                let tempMemberArr = []
                res.data?.members?.data.map((item) => (
                    tempMemberArr.push({ label: item.member_name, value: item.id })
                ))
                setTempMemberArr(tempMemberArr)
            }

            else {
                setProcessingMemberList(false)
                setAllMemberList([])
                allMember = []
            }
        } catch (error) {
            setProcessingMemberList(false)
            setAllMemberList([])
            allMember = []
        }
    }

    const fetchPaymenyType = async () => {
        try {
            let response = null;
            response = await graphql({
                query: SubscriptionQry.FETCH_PAYMENT_MODE_QUERY,
                variables: {}
            });
            let paymenyType = []
            paymenyType = response?.data?.payment_mode || [];
            setPaymenyType(paymenyType);
        } catch (err) {
            setPaymenyType([]);
        }
    }

    const fetchSubscriptionListData = async (paginatorInfo, searchText) => {
        try {
            setProcessingList(true)
            const res = await graphql({
                query: SubscriptionQry.FETCH_ALL_SUBSCRIPTIONS_QUERY_FOR_MEMBER
            });

            if (res.data.subscription_plans.data) {
                setProcessingList(false)
                setAllSubscriptionList(!!res.data.subscription_plans ? res.data.subscription_plans.data : [])
            }

            else {
                setProcessingList(false)
                setAllSubscriptionList([])
            }
        } catch (error) {
            setProcessingList(false)
            setAllSubscriptionList([])
        }
    }

    const fetchFloorTypes = async () => {
        try {
            setProcessingList(true)
            const res = await graphql({
                query: MembershipQry.FLOOR_TYPES_QUERY,
            });

            if (res.data.floors) {
                setProcessingList(false)
                setAllFloorTypeList(!!res.data.floors ? res.data.floors : [])
            }

            else {
                setProcessingList(false)
                setAllFloorTypeList([])
            }
        } catch (error) {
            setProcessingList(false)
            setAllFloorTypeList([])
        }
    }

    const fetchPreferredTimingTypes = async () => {
        try {
            // setPreferTimingType([{ id: 1, timing: "Any Time" }, { id: 2, timing: "Off Peak" }])
            setProcessingList(true)
            const res = await graphql({
                query: MembershipQry.PREFERRED_TIMINGS_TYPES_QUERY,
            });

            if (res.data.preferred_timings) {
                setProcessingList(false)
                setPreferTimingType(!!res.data.preferred_timings ? res.data.preferred_timings : [])
            }

            else {
                setProcessingList(false)
                setPreferTimingType([])
            }
        } catch (error) {
            setProcessingList(false)
            setPreferTimingType([])
        }
    }

    const fetchActivityTypes = async () => {
        try {
            const res = await graphql({
                query: SubscriptionQry.ACTIVITY_TYPES_QUERY
            });
            if (res.data.activity_types) {
                let activity_types = res?.data?.activity_types || []
                setActivityTypes(activity_types)
            } else {
                setActivityTypes([])
            }
        } catch (error) {
            setActivityTypes([])
        }
    }

    const fetchActivities = async () => {
        try {
            const res = await graphql({
                query: SubscriptionQry.ACTIVITIES_LIST_QUERY
            });
            if (res.data.activities) {
                let activities = res?.data?.activities || []
                setActivityList(activities)
            } else {
                setActivityList([])
            }
        } catch (error) {
            setActivityList([])
        }
    }

    const handleTableChange = (paginatorInfo, searchText) => {
        fetchMembershipsListData(paginatorInfo, searchText, membershipName, subscriptionPlan, membershipMode, membershipTiming, status)
    }

    const handleAction = (cell, row) => {
        return (

            <>
                <div className="d-flex">
                    <Button className="btn-info btn-sm ms-2" onClick={(e) => { handleFetch(e, row, true) }}>
                        <i className="bx bx-pencil align-middle" />
                    </Button>
                    &nbsp;
                    <Button className="btn-danger btn-sm ms-2" onClick={(e) => { handleDelete(e, row) }}>
                        <i className="bx bx-trash align-middle" />
                    </Button>
                    {/* &nbsp;
                    <Button className="btn btn-info  btn btn-info btn-sm" onClick={(e) => { handleChangeStatus(e, row) }}>
                        {row.status == 0 ? "Ongoing" : "Expired"}
                    </Button> */}
                    &nbsp;
                    <Button className="btn-primary btn-sm ms-2" onClick={(e) => { handleFetch(e, row, false) }} title="Invoice">
                        <i className="bx bx-receipt"></i>
                    </Button>
                </div>
            </>
        );
    };

    // Column definition for datatable
    const columns = [{
        // dataField: 'member_details[member_name]',
        formatter: (cell, row) => <Link to={{ pathname: "/member-details", state: { member_id: row.member_id, tabType: "2" } }}>{row.member_details.member_name}</Link>,
        text: 'MEMBER NAME',
        sort: true,
    },
    {
        dataField: 'subscription_details[plan_name]',
        text: 'SUBSCRIPTION PLAN',
        sort: true,
    },
    {
        formatter: (cell, row) => moment(row.beginning_date).format("MM-DD-YYYY"),
        text: 'START DATE',
        sort: true,
    },
    {
        formatter: (cell, row) => moment(row.end_date).format("MM-DD-YYYY"),
        text: 'END DATE',
        sort: true,
    },
    {
        dataField: 'preferred_timing_details[preferred_timing_name]',
        text: 'TIMING',
        sort: true,
    },
    {
        dataField: 'subscription_plan_charges',
        text: 'CHARGE',
        sort: true,
    },
    {
        dataField: 'discount',
        text: 'DISCOUNT',
        sort: true,
    },
    {
        dataField: 'total_amount',
        text: 'TOTAL',
        sort: true,
    },
    {
        dataField: 'payment_mode[payment_mode]',
        text: 'MODE',
        sort: true,
    },
    {
        formatter: (cell, row) => (row.status == 1 ? <span className="badge rounded-pill bg-success float-start">Ongoing</span> : <span className="badge rounded-pill bg-danger float-start">Expired</span>),
        text: 'STATUS',
        sort: true,
    },
    {
        dataField: '',
        text: 'ACTION',
        sort: true,
        formatter: handleAction
    }];


    const handleFetch = async (e, row, isEdit) => {
        formikObj.resetForm()
        let responseData = []
        try {

            setMembershipId(row.id)
            const res = await graphql({
                query: MembershipQry.FETCH_ALL_MEMBERSHIPS_QUERY,
                variables: {
                    ...paginatorInfo,
                    searchText: `%${searchText}%`,
                    id: row.id
                }
            });
            responseData = !!res.data.memberships ? res.data.memberships.data[0] : []

        } catch (error) {

        }
        if (isEdit) {

            let floor_arr = []
            if (responseData?.membership_floors?.length > 0) {
                for (let index = 0; index < responseData?.membership_floors?.length; index++) {
                    floorArr.push(responseData?.membership_floors?.[index]?.floor_details?.id)
                }
            }

            const updatedMembershipsData = {
                id: responseData.id,
                member_id: responseData.member_id,
                subscription_plan_id: responseData.subscription_plan_id,
                beginning_date: responseData.beginning_date,
                end_date: responseData.end_date,
                number_of_sessions: !!responseData.number_of_sessions ? responseData.number_of_sessions : '',
                subscription_plan_charges: responseData.subscription_plan_charges,
                discount: responseData.discount,
                gym_timing_from: responseData.gym_timing_from,
                gym_timing_to: responseData.gym_timing_to,
                floor: floorArr,
                preferred_timing: responseData.preferred_timing_details?.id,
                gst_amount: responseData.gst_amount,
                total_amount: responseData.total_amount,
                status: responseData.status,
                payment_mode_id: responseData?.payment_mode?.id,
                // activities_data: activities_data
            }
            // !!responseData.end_date ? setEndDate(moment(new Date(responseData.end_date)).format("MM-DD-YYYY")) : setEndDate("")
            // setBeginingDate(moment(responseData?.beginning_date).format("MM-DD-YYYY"))
            setBeginingDate(userDateFormat(responseData?.beginning_date))
            setEndDate(userDateFormat(responseData?.end_date))
            setSearchMemberId({label:responseData?.member_details?.member_name, value: responseData?.member_id})
            // setEndDate(moment(responseData?.end_date).format("MM-DD-YYYY"))
            formikObj.setFieldValue("beginning_date", responseData?.beginning_date)
            formikObj.setFieldValue("end_date", responseData?.end_date)
            setMembershipData(updatedMembershipsData)
            openModal()
        } else {
            setInvoiceData(responseData)
            setTimeout(() => {
                setInvoiceModalOpen(true)
            }, 0);

        }
    }

    const handleDelete = (e, row) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (willDelete) => {
                if (willDelete) {
                    try {
                        const response = await axiosInstance.post(apiUrls.membership.deleteMembership, { id: row.id });
                        if (response.success == 1) {
                            swal("Success!", response.message, "success");
                            fetchMembershipsListData(paginatorInfo, searchText, membershipName, subscriptionPlan, membershipMode, membershipTiming, status)
                        }
                        else {
                            swal("Oops!", response.message, "error");
                        }
                    } catch (err) {
                        swal("Oops!", "Something went wrong!", "error");
                        setError(err.message)
                    }
                }
            });
    }

    const handleChangeStatus = (e, row) => {
        swal({
            title: "Are you sure?",
            text: "You want to change the Membership status!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (willChange) => {
                if (willChange) {
                    try {
                        const response = await axiosInstance.post(apiUrls.subscription.activeInactiveSubscriptionPlan, { id: row.id, status: row.status == 0 ? 1 : 0 });
                        if (response.success == 1) {
                            swal("Success!", response.message, "success");
                            // fetchSubscriptionListData(paginatorInfo, searchText)
                        }
                        else {
                            swal("Oops!", response.message, "error");
                        }
                    } catch (err) {
                        swal("Oops!", "Something went wrong!", "error");
                        setError(err.message)
                    }
                }
            });
    }

    const handleSessionsChange = (e) => {
        if (/^[0-9]+$/g.test(e.target.value) || e.target.value == '') {
            formikObj.setFieldValue(e.target.name, e.target.value)
        }
    }

    const beginingDateChange = (beginingDates) => {
        if (!!beginingDates[0]) {
            setBeginingDate(beginingDates[0].getDate() + '-' + (beginingDates[0].getMonth() + 1) + '-' + beginingDates[0].getFullYear())
            formikObj.setFieldValue("beginning_date", standartDateFormat(beginingDates[0]))
            if (planPattern == 1) {
                setEndDate(moment(standartDateFormat(beginingDates[0])).add(1, 'Y').format('DD-MM-YYYY'))
                formikObj.setFieldValue("end_date", moment(standartDateFormat(beginingDates[0])).add(1, 'Y').format('YYYY-MM-DD'))
            }
            else if (planPattern == 2) {
                setEndDate(moment(standartDateFormat(beginingDates[0])).add(6, 'M').format('DD-MM-YYYY'))
                formikObj.setFieldValue("end_date", moment(standartDateFormat(beginingDates[0])).add(6, 'M').format('YYYY-MM-DD'))
            }
            else if (planPattern == 3) {
                setEndDate(moment(standartDateFormat(beginingDates[0])).add(3, 'M').format('DD-MM-YYYY'))
                formikObj.setFieldValue("end_date", moment(standartDateFormat(beginingDates[0])).add(3, 'M').format('YYYY-MM-DD'))
            }
            else if (planPattern == 4) {
                setEndDate(moment(standartDateFormat(beginingDates[0])).add(1, 'M').format('DD-MM-YYYY'))
                formikObj.setFieldValue("end_date", moment(standartDateFormat(beginingDates[0])).add(1, 'M').format('YYYY-MM-DD'))
            }
            else {
            }
        } else {
            setBeginingDate('')
            formikObj.setFieldValue("beginning_date", '')
        }
    }


    const endDateChange = (endDates) => {
        if (!!endDates[0]) {
            setEndDate(endDates[0].getDate() + '-' + (endDates[0].getMonth() + 1) + '-' + endDates[0].getFullYear())
            formikObj.setFieldValue("end_date", standartDateFormat(endDates[0]))
        } else {
            setEndDate('')
            formikObj.setFieldValue("end_date", '')
        }
    }

    const formikObj = useFormik({
        enableReinitialize: true,

        initialValues: { ...membershipData },
        validationSchema: Yup.object({
            member_id: Yup.string().required("Please Select Member"),
            subscription_plan_id: Yup.string().required("Please Select Subscription Plan"),
            beginning_date: Yup.string().required("Please Select Begining Date"),
            end_date: Yup.string().required("Please Select End Date"),
            member_access_code: Yup.string().required("Please Enter Access Card No."),
            payment_mode_id: Yup.string().required("Please Select Payment Mode"),
        }),
        onSubmit: (values) => {
            if (membershipId == null) {
                addMembershipData(values)
            }
            else {
                updateMembershipData(values)
            }

        }
    });

    const handleDiscountChange = (e) => {
        if (/^[0-9]+$/g.test(e.target.value) || e.target.value == '') {
            formikObj.setFieldValue(e.target.name, e.target.value)
        }
    }

    useEffect(() => {

        if (!!formikObj.values.member_id) {
            fetchMemberListData()
            setTimeout(() => {
                let memberArr = allMember.filter(item => item.id == formikObj.values.member_id)
                !!memberArr[0]?.member_access_code ? formikObj.setFieldValue("member_access_code", memberArr[0]?.member_access_code) : formikObj.setFieldValue("member_access_code", "")
            }, 3000);

        }
    }, [formikObj.values.member_id])

    useEffect(() => {
        formikObj.setFieldValue("total_amount", "")
        formikObj.setFieldValue("discount", membershipData.discount)
        formikObj.setFieldValue("gst_amount", "")

        if (formikObj.values.subscription_plan_id !== '') {
            let subscription = allSubscriptionList.filter(item => item.id == formikObj.values.subscription_plan_id)
            editSubscriptionAmount = subscription[0]?.total_amount
            setSubscriptionAmount(subscription[0]?.total_amount)
            formikObj.setFieldValue("subscription_plan_charges", subscription[0]?.total_amount)
            editSubscriptionPlanType = subscription[0]?.plan_type.id
            setSubscriptionPlanType(subscription[0]?.plan_type.id)
            let planPattern = subscription[0]?.plan_pattern.id
            setPlanPattern(subscription[0]?.plan_pattern.id)
            if (subscription[0]?.plan_type.id == 1) {
                let amount = parseFloat(subscription[0]?.total_amount)
                let gst_amount = amount * gst_rate
                formikObj.setFieldValue('gst_amount', gst_amount)
                amount += gst_amount
                formikObj.setFieldValue("total_amount", round(amount))
            }

            if (!!membershipData.id == false) {
                // Adjust Dates
                setBeginingDate(moment(new Date()).format("DD-MM-YYYY"))
                setMinDate(moment(new Date()).format("DD-MM-YYYY"))
                formikObj.setFieldValue("beginning_date", moment(new Date()).format("YYYY-MM-DD"))
                if (planPattern == 1) {
                    setEndDate(moment().add(1, 'Y').format('DD-MM-YYYY'))
                    formikObj.setFieldValue("end_date", moment().add(1, 'Y').format('YYYY-MM-DD'))
                }
                else if (planPattern == 2) {
                    setEndDate(moment().add(6, 'M').format('DD-MM-YYYY'))
                    formikObj.setFieldValue("end_date", moment().add(6, 'M').format('YYYY-MM-DD'))
                }
                else if (planPattern == 3) {
                    setEndDate(moment().add(3, 'M').format('DD-MM-YYYY'))
                    formikObj.setFieldValue("end_date", moment().add(3, 'M').format('YYYY-MM-DD'))
                }
                else if (planPattern == 4) {
                    setEndDate(moment().add(1, 'M').format('DD-MM-YYYY'))
                    formikObj.setFieldValue("end_date", moment().add(1, 'M').format('YYYY-MM-DD'))
                }
                else {
                }
            }
        }
    }, [formikObj.values.subscription_plan_id])

    useEffect(() => {
        if (formikObj.values.number_of_sessions != '') {
            let amount = (parseInt(formikObj.values.number_of_sessions) * parseFloat(formikObj.values.subscription_plan_charges))
            sub_total = amount
            let gst_amount = amount * gst_rate
            formikObj.setFieldValue('gst_amount', gst_amount)
            editGrossAmount = amount
            setGrossAmount(amount)
            amount += gst_amount
            formikObj.setFieldValue("total_amount", round(amount))
        }
    }, [formikObj.values.number_of_sessions])

    const checkDiscountAmount = (amount) => {
        if (parseFloat(formikObj.values.discount) > amount) {
            setDiscountErrorMsg('Discount amount cannot be greater than Subscription Amount')
            setIsSubmit(false)
            return false
        }
        else {
            setDiscountErrorMsg('')
            setIsSubmit(true)
            return true
        }
    }

    useEffect(() => {
        if (formikObj.values.discount != '') {
            let amount = ''
            if (!!formikObj.values.discount) {
                if (editSubscriptionPlanType != 2) {
                    if (checkDiscountAmount(parseFloat(editSubscriptionAmount))) {
                        amount = parseFloat(editSubscriptionAmount) - parseFloat(formikObj.values.discount)
                    }
                }
                else {
                    if (checkDiscountAmount(editGrossAmount)) {
                        amount = editGrossAmount - parseFloat(formikObj.values.discount)
                    }

                }
            }
            else {
                if (editSubscriptionPlanType != 2) {
                    amount = parseFloat(editSubscriptionAmount)
                } else {
                    amount = parseFloat(editGrossAmount)
                }
            }
            formikObj.setFieldValue('gst_amount', (amount * gst_rate))
            amount += amount * gst_rate
            formikObj.setFieldValue("total_amount", round(amount))
        }
    }, [formikObj.values.discount])

    const addMembershipData = async (values) => {
        try {
            setIsSaving(true)
            const response = await axiosInstance.post(apiUrls.membership.addAndUpdateMembership, { ...values });
            if (response.success == 1) {
                formikObj.resetForm()
                setIsSaving(false)
                closeModal()
                swal("Success!", response.message, "success");
                fetchMembershipsListData(paginatorInfo, searchText, membershipName, subscriptionPlan, membershipMode, membershipTiming, status)
                setMembershipData([])
            }
            else {
                setIsSaving(false)
                swal("Oops!", response.message, "error");
            }
        } catch (err) {
            setIsSaving(false)
            swal("Oops!", "Something went wrong!", "error");
            setError(err.message)
        }
    }

    const updateMembershipData = async (values) => {

        try {
            setIsSaving(true)
            const response = await axiosInstance.post(apiUrls.membership.addAndUpdateMembership, { ...values, id: membershipData.id });
            if (response.success == 1) {
                formikObj.resetForm()
                setIsSaving(false)
                closeModal()
                swal("Success!", response.message, "success");
                fetchMembershipsListData(paginatorInfo, searchText, membershipName, subscriptionPlan, membershipMode, membershipTiming, status)
                setMembershipData([])
                setMembershipId(null)
                setBeginingDate('')
                setEndDate('')
                formikObj.setFieldValue("beginning_date", "")
                formikObj.setFieldValue("end_date", "")
            }
            else {
                setIsSaving(false)
                swal("Oops!", response.message, "error");
            }
        } catch (err) {
            setIsSaving(false)
            swal("Oops!", "Something went wrong!", "error");
            setError(err.message)
        }
    }

    const openModal = () => {
        setAddEditMembershipModal(true);
    }

    const closeModal = () => {
        formikObj.resetForm()
        setAddEditMembershipModal(false);
        formikObj.setFieldValue("beginning_date", "")
        formikObj.setFieldValue("end_date", "")
        formikObj.setFieldValue("gst_amount", 0)
        formikObj.setFieldValue("total_amount", 0)
        setBeginingDate("")
        setEndDate("")
    }

    const createSubscription = () => {
        setMembershipId(null)
        setMembershipData({ ...initialMembershipData });
        formikObj.resetForm();
        formikObj.setFieldValue("beginning_date", "")
        formikObj.setFieldValue("end_date", "")
        formikObj.setFieldValue("gst_amount", 0)
        formikObj.setFieldValue("total_amount", 0)
        setBeginingDate("")
        setEndDate("")
        openModal()
    }

    const handleStartTimeChange = (timeChange) => {
        formikObj.setFieldValue("gym_timing_from", timeChange[0].getHours() + ":" + timeChange[0].getMinutes())
    }
    const handleEndTimeChange = (timeChange) => {
        formikObj.setFieldValue("gym_timing_to", timeChange[0].getHours() + ":" + timeChange[0].getMinutes())
    }

    const handlePreferredTimingChange = (e) => {
        formikObj.setFieldValue(e.target.name, e.target.value)
    }

    const handleFloorChange = (e) => {
        let value = parseInt(e.target.value)
        if (e.target.checked) {
            let index = floorArr.indexOf(value)
            floorArr.splice(index, 1)
        }
        else {
            floorArr.push(value);
        }
        formikObj.setFieldValue(e.target.name, floorArr)
    }

    const filterMemberByName = (e) => {
        let memberName = '';
        if (e.target.value != '') {
            memberName = parseInt(e.target.value);
            setPageURL("1");
            setMembershipName(memberName);
        } else {
            setMembershipName(memberName);
        }
        fetchMembershipsListData(paginatorInfo, searchText, memberName, subscriptionPlan, membershipMode, membershipTiming, status);
    }

    const filterMemberBySubscriptionPlan = (e) => {
        let subPlan = '';
        if (e.target.value != '') {
            subPlan = parseInt(e.target.value);
            setPageURL("1");
            setSubscriptionPlan(subPlan);
        } else {
            setSubscriptionPlan(subPlan);
        }
        fetchMembershipsListData(paginatorInfo, searchText, membershipName, subPlan, membershipMode, membershipTiming, status);
    }

    const filterMembershipByMode = (e) => {
        let modeValue = '';
        if (e.target.value != '') {
            modeValue = parseInt(e.target.value);
            setPageURL("1");
            setMembershipMode(modeValue);
        } else {
            setMembershipMode(modeValue);
        }
        fetchMembershipsListData(paginatorInfo, searchText, membershipName, subscriptionPlan, modeValue, membershipTiming, status);
    }

    const filterMembershipByTiming = (e) => {
        let timingValue = '';
        if (e.target.value != '') {
            timingValue = parseInt(e.target.value);
            setPageURL("1");
            setMembershipTiming(timingValue);
        } else {
            setMembershipTiming(timingValue);
        }
        fetchMembershipsListData(paginatorInfo, searchText, membershipName, subscriptionPlan, membershipMode, timingValue, status);
    }

    const filterMembershipByStatus = (e) => {
        if (e.target.value != '') {
            setPageURL("1")
            setStatus(parseInt(e.target.value))
            fetchMembershipsListData(paginatorInfo, searchText, membershipName, subscriptionPlan, membershipMode, membershipTiming, parseInt(e.target.value));
        }
    }

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    }

    const handleMemberIdSearch = (searchMemberId) => {
        setSearchMemberId(searchMemberId);
        formikObj.setFieldValue('member_id', searchMemberId.value)
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Manage Membership</title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumbs title="Manage Membership" breadcrumbItem="Membership List" />
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col lg={3}>
                                            <Button
                                                color="primary"
                                                className="font-size-13 btn-block"
                                                onClick={createSubscription}
                                            >
                                                <i className="mdi mdi-plus-circle-outline me-1 align-middle font-size-16" />
                                                Create
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    {/* Datatable view start */}
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>

                                    <Row>
                                        <Col lg={12}>
                                            <h3>All Membership List</h3><br />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col col={4}>
                                            <div className="search-box me-2 mb-2 d-inline-block">
                                                <div className="position-relative">
                                                    <Label for="search-bar-0" className="search-label">
                                                        <span id="search-bar-0-label" className="sr-only">Search this table</span>
                                                        <Input id="search-bar-0"
                                                            type="text"
                                                            aria-labelledby="search-bar-0-label"
                                                            className="form-control"
                                                            placeholder="Search"
                                                            value={searchText}
                                                            onChange={(e) => handleSearch(e)}
                                                        />
                                                    </Label>
                                                    <i className="bx bx-search-alt search-icon"></i>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col col={8}>
                                            <div className="text-end mb-3">
                                                {/* <select className="btn btn-outline-primary form-select w-auto pe-5 mb-3 mb-md-0" onChange={(e) => filterMemberByName(e)}>
                                                <option value="">Member</option>
                                                {allMemberList.map((item) => (
                                                    <option value={item.id} key={item.id}>{item.member_name}</option>
                                                ))}
                                                </select>
                                                &nbsp;
                                                <select className="btn btn-outline-primary form-select w-25 pe-5 mb-3 mb-md-0"  onChange={(e) => filterMemberBySubscriptionPlan(e)}>
                                                    <option value=""> Subscription Plan</option>
                                                    {subscriptionPlanList.map((plan, index) => {
                                                    return (
                                                        <option key={index} value={plan.id}>{plan.plan_name}</option>
                                                    )
                                                })
                                                }
                                                </select>
                                                &nbsp;
                                                <select className="btn btn-outline-primary form-select w-auto pe-5 mb-3 mb-md-0" onChange={(e) => filterMembershipByMode(e)}>
                                                    <option value="">Mode</option>
                                                    <option value="1">Bank</option>
                                                    <option value="2">Cash</option>
                                                </select>
                                                &nbsp;
                                                <select className="btn btn-outline-primary form-select w-auto pe-5 mb-3 mb-md-0" onChange={(e) => filterMembershipByTiming(e)}>
                                                    <option value="">Timing</option>
                                                    <option value="1">Morning</option>
                                                    <option value="2">Evening</option>
                                                </select>
                                                &nbsp; */}
                                                <select className="btn btn-outline-primary form-select w-auto pe-5 mb-3 mb-md-0" onChange={(e) => filterMembershipByStatus(e)}>
                                                    <option value="">Select</option>
                                                    <option value="1" selected>Ongoing</option>
                                                    <option value="0">Expired</option>
                                                </select>
                                            </div>
                                        </Col>
                                    </Row>
                                    {membershipProcessingList ?
                                        <Row>
                                            <Col lg={12} style={{ textAlign: "center" }}>
                                                <h5><i className="bx bx-hourglass bx-spin font-size-24 align-middle me-2"></i>
                                                    Loading...</h5>
                                            </Col>
                                        </Row>
                                        :
                                        <React.Fragment>
                                            {allMembershipsList.length > 0 ?
                                                <Row>
                                                    <Col lg={12}>
                                                        <div className="table-responsive">
                                                            {/* <Table className="table mb-0 membership-table"> */}
                                                            <Table className="table mb-0">
                                                                <thead>
                                                                    <tr style={{ whiteSpace: "nowrap" }}>
                                                                        <th>ID</th>
                                                                        <th>MEMBER NAME</th>
                                                                        <th>SUBSCRIPTION PLAN</th>
                                                                        <th>STATUS</th>
                                                                        <th>START DATE</th>
                                                                        <th>END DATE</th>
                                                                        <th>TIMING</th>
                                                                        <th>CHARGE</th>
                                                                        <th>DISCOUNT</th>
                                                                        <th>TOTAL</th>
                                                                        <th>MODE</th>
                                                                        <th>ACTION</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {allMembershipsList.map((item, index) => {

                                                                        return (
                                                                            <tr key={index}>
                                                                                <td scope="row">
                                                                                    <Link to={{ pathname: "/member-details", state: { member_id: item.member_id, tabType: "2" } }}>{item?.id}</Link>
                                                                                </td>
                                                                                <td>
                                                                                    <div className="d-flex">
                                                                                        <Link to={{
                                                                                            pathname: "/member-details",
                                                                                            state: { member_id: item.member_id, tabType: "2" }
                                                                                        }}
                                                                                            style={{ color: item.membership_flag == 1 && '#d50c38' }}
                                                                                        >
                                                                                            {item.member_details[0].member_name}
                                                                                        </Link>&nbsp;
                                                                                        <span className="badge rounded-pill bg-success cursor-pointer px-2 py-1 ms-1" onClick={(e) => handleMemberBodyComposition(e, item)}>{item?.total_body_composition}</span>
                                                                                    </div>
                                                                                </td>
                                                                                <td className="whitespace-no-wrap">{item.subscription_details[0].plan_name}</td>
                                                                                <td>{item.status == 1 ? <span className="badge rounded-pill bg-success px-2 py-1">Ongoing</span> : <span className="badge rounded-pill bg-danger px-2 py-1">Expired</span>}</td>
                                                                                <td>{!!item.beginning_date ? moment(item.beginning_date).format('DD-MM-YYYY') : '-'}</td>
                                                                                <td className="whitespace-no-wrap">{!!item.end_date ? moment(item.end_date).format('DD-MM-YYYY') : "-"}</td>
                                                                                {/* <td>{item.preferred_timing_details[0].preferred_timing_name}</td> */}
                                                                                <td>
                                                                                    <div className="bg-primary bg-soft p-2 my-1 text-primary badge">
                                                                                        <p className="m-0" style={{ fontWeight: "bold", color: "#00008B" }}>{" " + item?.preferred_timing_details[0]?.preferred_timing_name + ""}</p>
                                                                                    </div>
                                                                                </td>
                                                                                <td>{item.subscription_plan_charges}</td>
                                                                                <td>{item.discount}</td>
                                                                                <td>{item.total_amount}</td>
                                                                                <td>{item?.payment_mode?.[0]?.payment_mode}</td>
                                                                                {/* <td>{item.body_fat_percentage}</td>
                                                                                <td>{item.visceral_fat_percentage}</td>
                                                                                <td>{item.resting_metabolic_rate}</td>
                                                                                <td>{item.body_mass_index}</td> */}
                                                                                {/* <td style={{ color: item.body_scale_flag == 1 && '#d50c38' }}>{!!item.next_measurement_date ? moment(item.next_measurement_date).format('MM-DD-YYYY') : '-'}</td> */}
                                                                                <td className="whitespace-no-wrap">{handleAction(null, item)}</td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                    }
                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                    </Col>
                                                    <div className="mt-2">
                                                        <div style={{ float: "left" }}>
                                                            <Input
                                                                type="select"
                                                                id="formrow-serviceType"
                                                                className="form-control"
                                                                name={'offset'}
                                                                onChange={(e) => handleOffsetChange(e)}
                                                                value={offSet}
                                                            >
                                                                <option value="10">10</option>
                                                                <option value="5">5</option>
                                                                <option value="10">10</option>
                                                                <option value="15">15</option>
                                                            </Input>
                                                        </div>
                                                        <div style={{ justifyContent: "end", display: "flex" }}>
                                                            <ul className="pagination react-bootstrap-table-page-btns-ul">
                                                                {pageLinks.map((btn, index) => {
                                                                    return (
                                                                        <React.Fragment key={index}>
                                                                            {!!btn.url &&
                                                                                <li className={!!btn.active ? "active page-item" : "page-item"}>
                                                                                    <a className="page-link" href="#" onClick={(e) => getPageNumber(e, btn)}>
                                                                                        {btn.label.includes("Next") ? ">" : btn.label.includes("Previous") ? "<" : btn.label}
                                                                                    </a>
                                                                                </li>
                                                                            }
                                                                        </React.Fragment>
                                                                    )
                                                                })
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </Row>
                                                :
                                                <Row>
                                                    <Col lg={12} style={{ textAlign: "center" }}>
                                                        <h5>No Data Found</h5>
                                                    </Col>
                                                </Row>
                                            }
                                        </React.Fragment>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    {/* Datatable view end */}

                    <Modal
                        isOpen={addEditMembershipModal}
                        toggle={closeModal}
                        size="lg"
                        backdrop="static"
                    // className={props.className}
                    >
                        <ModalHeader toggle={closeModal} tag="h4">
                            {membershipId == null ? "Create New Membership Plan" : "Edit Membership Plan"}
                        </ModalHeader>
                        <ModalBody>
                            <Form>
                                <Row>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-InputService">Member<span className="text-danger">*</span></Label>
                                            {/* <Input
                                                type="select"
                                                id="formrow-serviceType"
                                                className="form-control"
                                                name={'member_id'}
                                                onChange={formikObj.handleChange}
                                                value={formikObj.values.member_id}
                                                invalid={
                                                    formikObj.touched.member_id && formikObj.errors.member_id ? true : false
                                                }
                                            >
                                                <option value="">Select</option>
                                                {allMemberList.map((item) => (
                                                    <option value={item.id} key={item.id}>{item.member_name}</option>
                                                ))}

                                            </Input> */}
                                            <Select
                                                name={'member_id'}
                                                value={searchMemberId}
                                                onChange={(member) => {
                                                    handleMemberIdSearch(member);
                                                }}
                                                options={tempMemberArr}
                                                classNamePrefix="select2-selection"
                                            />
                                            {formikObj.touched.member_id && formikObj.errors.member_id ? (
                                                <FormFeedback type="invalid">{formikObj.errors.member_id}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-serviceType">Subscription Plan<span className="text-danger">*</span></Label>
                                            <Input
                                                type="select"
                                                id="formrow-serviceType"
                                                className="form-control"
                                                name={'subscription_plan_id'}
                                                onChange={formikObj.handleChange}
                                                value={formikObj.values.subscription_plan_id}
                                                invalid={
                                                    formikObj.touched.subscription_plan_id && formikObj.errors.subscription_plan_id ? true : false
                                                }
                                            >
                                                <option defaultValue>Select</option>
                                                {allSubscriptionList.map((item) => (
                                                    <option value={item.id} key={item.id}>{item.plan_name}</option>
                                                ))}

                                            </Input>
                                            {formikObj.touched.subscription_plan_id && formikObj.errors.subscription_plan_id ? (
                                                <FormFeedback type="invalid">{formikObj.errors.subscription_plan_id}</FormFeedback>
                                            ) : null}

                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-startDate-Input">Start Date<span className="text-danger">*</span></Label>
                                            <Flatpickr
                                                className="form-control d-block"
                                                style={{ borderColor: "#f46a6a" }}
                                                name="beginning_date"
                                                placeholder="DD-MM-YYYY"
                                                options={{
                                                    altInput: true,
                                                    altFormat: "d-m-Y",
                                                    dateFormat: "d-m-Y",
                                                    // minDate: today
                                                }}
                                                onChange={beginingDateChange}
                                                value={beginning_date}
                                            />
                                            {formikObj.touched.beginning_date && formikObj.errors.beginning_date ? (
                                                <div style={{ fontSize: "smaller", color: "#f46a6a" }}>{formikObj.errors.beginning_date}</div>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-endDate-Input">End Date<span className="text-danger">*</span></Label>
                                            <React.Fragment>
                                                <Flatpickr
                                                    className="form-control d-block"
                                                    name="end_date"
                                                    placeholder="DD-MM-YYYY"
                                                    options={{
                                                        altInput: true,
                                                        altFormat: "d-m-Y",
                                                        dateFormat: "d-m-Y",
                                                        // minDate: today
                                                    }}
                                                    onChange={endDateChange}
                                                    value={end_date}
                                                />
                                                {formikObj.touched.end_date && formikObj.errors.end_date ? (
                                                    <div style={{ fontSize: "smaller", color: "#f46a6a" }}>{formikObj.errors.end_date}</div>
                                                ) : null}
                                            </React.Fragment>
                                            {/* {planPattern == 5 ?
                                                <React.Fragment>
                                                    <Flatpickr
                                                        className="form-control d-block"
                                                        name="end_date"
                                                        placeholder="DD-MM-YYYY"
                                                        options={{
                                                            altInput: true,
                                                            altFormat: "d-m-Y",
                                                            dateFormat: "d-m-Y",
                                                            // minDate: today
                                                        }}
                                                        onChange={endDateChange}
                                                        value={end_date}
                                                    />
                                                    {formikObj.touched.end_date && formikObj.errors.end_date ? (
                                                        <div style={{ fontSize: "smaller", color: "#f46a6a" }}>{formikObj.errors.end_date}</div>
                                                    ) : null}
                                                </React.Fragment>

                                                :
                                                <Input
                                                    type="text"
                                                    name="end_date"
                                                    placeholder="DD-MM-YYYY"
                                                    value={end_date}
                                                    readOnly
                                                />
                                            } */}

                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-InputService">Preferred Timing</Label>&nbsp;&nbsp;
                                            {preferTimingType.map((item, index) => (
                                                <div key={index} className="form-check form-check-inline font-size-16">
                                                    <Input
                                                        type="radio"
                                                        value={item.id}
                                                        id="customRadioInline1"
                                                        name="preferred_timing"
                                                        className="form-check-input"
                                                        onClick={(e) => { handlePreferredTimingChange(e) }}
                                                        checked={item.id == formikObj.values.preferred_timing ? true : false}
                                                    />
                                                    <Label
                                                        className="form-check-label font-size-13"
                                                        htmlFor="customRadioInline1"
                                                    >
                                                        {item.preferred_timing_name}
                                                    </Label>
                                                </div>
                                            ))
                                            }
                                            {/* {selectPlanType == false && <span style={{ color: "red", fontSize: "10px" }}>Please Select Plan Type</span>} */}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-InputService">Time From</Label>
                                            <Flatpickr
                                                className="form-control d-block"
                                                name="gym_timing_from"
                                                options={{
                                                    noCalendar: true,
                                                    enableTime: true,
                                                    dateFormat: 'h:i K',
                                                    // minTime: formikObj.values.preferred_timing == 2 ? '10:0' : '',
                                                    // maxTime: formikObj.values.preferred_timing == 2 ? '16:0' : ''
                                                }}
                                                onChange={handleStartTimeChange}
                                                value={formikObj.values.gym_timing_from}
                                            />
                                            {/* <Input
                                                type="time"
                                                id="formrow-InputService"
                                                className="form-control"
                                                name="gym_timing_from"
                                                onChange={formikObj.handleChange}
                                                onKeyPress={formikObj.handleChange}
                                                defaultValue="00:00:00"
                                                value={formikObj.values.gym_timing_from}
                                                placeholder="Enter Time From"
                                                invalid={
                                                    formikObj.touched.gym_timing_from && formikObj.errors.gym_timing_from ? true : false
                                                }
                                            />
                                            {formikObj.touched.gym_timing_from && formikObj.errors.gym_timing_from ? (
                                                <FormFeedback type="invalid">{formikObj.errors.gym_timing_from}</FormFeedback>
                                            ) : null} */}
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-InputService">Time To</Label>
                                            <Flatpickr
                                                className="form-control d-block"
                                                name="gym_timing_to"
                                                options={{
                                                    noCalendar: true,
                                                    enableTime: true,
                                                    dateFormat: 'h:i K',
                                                    // minTime: formikObj.values.preferred_timing == 2 ? '10:0' : '',
                                                    // maxTime: formikObj.values.preferred_timing == 2 ? '16:0' : ''
                                                }}
                                                onChange={handleEndTimeChange}
                                                value={formikObj.values.gym_timing_to}
                                            />
                                            {/* <Input
                                                type="time"
                                                id="formrow-InputService"
                                                className="form-control"
                                                name="gym_timing_to"
                                                onChange={formikObj.handleChange}
                                                onKeyPress={formikObj.handleChange}
                                                defaultValue="00:00:00"
                                                value={formikObj.values.gym_timing_to}
                                                placeholder="Enter Time To"
                                                invalid={
                                                    formikObj.touched.gym_timing_to && formikObj.errors.gym_timing_to ? true : false
                                                }
                                            />
                                            {formikObj.touched.gym_timing_to && formikObj.errors.gym_timing_to ? (
                                                <FormFeedback type="invalid">{formikObj.errors.gym_timing_to}</FormFeedback>
                                            ) : null} */}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-InputService">Floor</Label>&nbsp;&nbsp;
                                            {allFloorTypeList.map((item, index) => (
                                                <div className="form-check mb-3 form-check-inline" key={index}>
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="defaultCheck1"
                                                        name="floor"
                                                        onClick={(e) => { handleFloorChange(e) }}
                                                        // onChange={formikObj.handleChange}
                                                        value={item.id}
                                                        checked={formikObj.values?.floor?.indexOf(item.id) !== -1}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="defaultCheck1"
                                                    >
                                                        {item.floor_name}
                                                    </label>
                                                </div>
                                            ))
                                            }
                                        </div>
                                    </Col>
                                    {subscriptionPlanType == 2 &&
                                        <Col lg={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-InputService">Quantity</Label>
                                                <Input
                                                    type="text"
                                                    id="formrow-InputService"
                                                    className="form-control"
                                                    name="number_of_sessions"
                                                    onChange={(e) => { handleSessionsChange(e) }}
                                                    value={formikObj.values.number_of_sessions}
                                                    placeholder="Enter Quantity"
                                                    invalid={
                                                        formikObj.touched.number_of_sessions && formikObj.errors.number_of_sessions ? true : false
                                                    }
                                                />
                                                {formikObj.touched.number_of_sessions && formikObj.errors.number_of_sessions ? (
                                                    <FormFeedback type="invalid">{formikObj.errors.number_of_sessions}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>
                                    }
                                </Row>
                                <Row>
                                    <Col lg={12}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-InputService">Access Card No.<span className="text-danger">*</span></Label>
                                            <Input
                                                type="text"
                                                id="formrow-InputService"
                                                className="form-control"
                                                name="member_access_code"
                                                onChange={formikObj.handleChange}
                                                value={formikObj.values.member_access_code}
                                                placeholder="Enter Access Card No."
                                                invalid={
                                                    formikObj.touched.member_access_code && formikObj.errors.member_access_code ? true : false
                                                }
                                            />
                                            {formikObj.touched.member_access_code && formikObj.errors.member_access_code ? (
                                                <FormFeedback type="invalid">{formikObj.errors.member_access_code}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={editSubscriptionPlanType == 2 ? 4 : 6}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-InputService">Subscription Plan Charges</Label>
                                            <Input
                                                type="text"
                                                id="formrow-InputService"
                                                className="form-control"
                                                name="subscription_plan_charges"
                                                onChange={formikObj.handleChange}
                                                value={formikObj.values.subscription_plan_charges}
                                                placeholder="Enter Subscription Plan Charges"
                                                invalid={
                                                    formikObj.touched.subscription_plan_charges && formikObj.errors.subscription_plan_charges ? true : false
                                                }
                                                readOnly
                                            />
                                            {formikObj.touched.subscription_plan_charges && formikObj.errors.subscription_plan_charges ? (
                                                <FormFeedback type="invalid">{formikObj.errors.subscription_plan_charges}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                    {editSubscriptionPlanType == 2 &&
                                        <Col lg={4}>
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-InputService">Sub Total</Label>
                                                <Input
                                                    type="text"
                                                    id="formrow-InputService"
                                                    className="form-control"
                                                    value={sub_total}
                                                    placeholder="Sub Total"
                                                    readOnly
                                                />
                                            </div>
                                        </Col>
                                    }
                                    <Col lg={editSubscriptionPlanType == 2 ? 4 : 6}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-InputService">Discount</Label>
                                            <Input
                                                type="text"
                                                id="formrow-InputService"
                                                className="form-control"
                                                name="discount"
                                                // onChange={formikObj.handleChange}
                                                onChange={(e) => { handleDiscountChange(e) }}
                                                value={formikObj.values.discount}
                                                placeholder="Enter Discount"
                                                invalid={
                                                    formikObj.touched.discount && formikObj.errors.discount ? true : false
                                                }
                                            />
                                            <span className="text-danger">{discountErrorMsg}</span>
                                            {formikObj.touched.discount && formikObj.errors.discount ? (
                                                <FormFeedback type="invalid">{formikObj.errors.subscription_plan_charges}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={4}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-InputService">GST @18%</Label>
                                            <Input
                                                type="text"
                                                id="formrow-InputService"
                                                className="form-control"
                                                name="gst_amount"
                                                onChange={formikObj.handleChange}
                                                value={formikObj.values.gst_amount}
                                                placeholder="Enter GST"
                                                invalid={
                                                    formikObj.touched.gst_amount && formikObj.errors.gst_amount ? true : false
                                                }
                                                readOnly
                                            />
                                            {formikObj.touched.gst_amount && formikObj.errors.gst_amount ? (
                                                <FormFeedback type="invalid">{formikObj.errors.subscription_plan_charges}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>

                                    <Col lg={4}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-InputService">Total Amount</Label>
                                            <Input
                                                type="text"
                                                id="formrow-InputService"
                                                className="form-control"
                                                name="total_amount"
                                                // onChange={formikObj.handleChange}
                                                value={formikObj.values.total_amount}
                                                placeholder="Enter Total Amount"
                                                invalid={
                                                    formikObj.touched.total_amount && formikObj.errors.total_amount ? true : false
                                                }
                                                readOnly
                                            />
                                            {formikObj.touched.total_amount && formikObj.errors.total_amount ? (
                                                <FormFeedback type="invalid">{formikObj.errors.subscription_plan_charges}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col lg={4}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-serviceType">Select Payment<span className="text-danger">*</span></Label>
                                            <Input
                                                type="select"
                                                id="formrow-serviceType"
                                                className="form-control"
                                                name={'payment_mode_id'}
                                                onChange={formikObj.handleChange}
                                                value={formikObj.values.payment_mode_id}
                                                invalid={
                                                    formikObj.touched.payment_mode_id && formikObj.errors.payment_mode_id ? true : false
                                                }
                                            >
                                                <option value="">Select</option>
                                                {paymenyType.map((item) => (
                                                    <option value={item.id} key={item.id}>{item.payment_mode}</option>
                                                ))}

                                            </Input>
                                            {formikObj.touched.payment_mode_id && formikObj.errors.payment_mode_id ? (
                                                <FormFeedback type="invalid">{formikObj.errors.payment_mode_id}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                </Row>

                            </Form>
                        </ModalBody>
                        <div className="modal-footer">
                            {!!isSaving ?
                                <button className="btn btn-primary " disabled>
                                    Processing
                                </button>
                                :
                                <>
                                    <button
                                        type="button"
                                        onClick={closeModal}
                                        className="btn btn-secondary "
                                        data-dismiss="modal"
                                    >
                                        Close
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn btn-primary "
                                        onClick={(e) => {
                                            e.preventDefault();
                                            formikObj.handleSubmit();
                                            return false;
                                        }}
                                        disabled={!!isSubmit ? false : true}
                                    >
                                        {membershipId == null ? "Save" : "Update"}
                                    </button>
                                </>
                            }
                        </div>
                    </Modal>

                    <Invoice
                        isOpenModal={invoiceModalOpen}
                        onCloseClick={() => setInvoiceModalOpen(false)}
                        invoiceData={invoiceData}
                    />

                </Container>
            </div>
        </React.Fragment>
    );

}

export default withRouter(Membership);

Membership.propTypes = {
    history: PropTypes.object,
};