import PropTypes from 'prop-types'
import React, { useEffect, useLayoutEffect } from "react"

import { Switch, BrowserRouter as Router } from "react-router-dom"
import { connect, useDispatch, useSelector } from "react-redux"

// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes"

// Import all middleware
import Authmiddleware from "./routes/route"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"
import { apiUrls, axiosInstance, graphql } from 'api'
import { VIEW_PROFILE_QUERY } from 'GraphQLquery/profileQry'
import { setProfileData, setProfileLoading } from 'store/actions'
import profile_ids from "utils/profile_ids";
import { flatten, flattenDepth } from 'lodash'
import { authStorage } from 'utils/auth'

const App = props => {
  const dispatch = useDispatch();
  const hasToken = useSelector(state => !!state.Login.token);
  function getLayout() {
    let layoutCls = VerticalLayout
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }

  const Layout = getLayout();
  const getProfileDetails = async () => {
    try {
      dispatch(setProfileLoading(true))
      // const res = await graphql({
      //   query: VIEW_PROFILE_QUERY
      // });
      const res = await axiosInstance.post(apiUrls.profile.me);
      const data = res.data;
      localStorage.setItem("loggedIn_user_id", res.data.id)
      // if (res.data.profile_id != 1) {
      // }
      dispatch(setProfileData({ ...data}));
      
    } catch (error) {
      dispatch(setProfileLoading(false))
    }

  }

  useLayoutEffect(() => {
    if (hasToken) {
      getProfileDetails();
    }
  }, [hasToken]);

  return (
    <React.Fragment>
      <Router basename={process.env.REACT_APP_BASE_PATHNAME}>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}

          {authProtectedRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              isCommon={route.isCommon}
              exact
            />
          ))}
        </Switch>
      </Router>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
