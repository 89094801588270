import React from "react"
import { Redirect } from "react-router-dom"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
// Profile
import UserProfile from "../pages/Authentication/UserProfile"

// Dashboard
import Dashboard from "../pages/Dashboard/index"


import ChangePassword from "pages/Authentication/ChangePassword"


import Inbox from "pages/Messages/Inbox"
import ComposeMessage from "pages/Messages/ComposeMessage"
import InboxDetails from "pages/Messages/InboxDetails"
import Subscription from "pages/Subscription/Subscription"
import Member from "pages/Member/Member"
import Membership from "pages/Membership/Membership"
import ManageExercise from "pages/Workout/ManageExercise"
import AddExercise from "pages/Workout/AddExercise"
import EditExercise from "pages/Workout/EditExercise"
import ManageWorkoutPlan from "pages/Workout/ManageWorkoutPlan"
import AddWorkoutPlan from "pages/Workout/AddWorkoutPlan"
import EditWorkoutPlan from "pages/Workout/EditWorkoutPlan"
import WorkoutView from "pages/Workout/WorkoutView"
import MemberDetails from "pages/Member/MemberDetails"
import ManageDietPlan from "pages/Diet/ManageDietPlan"
import AddDietPlan from "pages/Diet/AddDietPlan"
import MemberWorkoutPlan from "pages/MemberWorkout/MemberWorkoutPlan"
import EditDietPlan from "pages/Common/EditDietPlan"
import MemberDietPlan from "pages/Diet/MemberDietPlan"
import AddTrainer from "pages/Trainer/AddTrainer"
import ManageBodyComposition from "pages/BodyComposition/ManageBodyComposition"
import DietView from "pages/Diet/DietView"
import BodyCompositionView from "pages/BodyComposition/BodyCompositionView"

import ManageBatch from "pages/Batch/ManageBatch"
import AddEditBatch from "pages/Batch/AddEditBatch"
import FinancialReport from "pages/Report/FinancialReport"
import RenewalReport from "pages/Report/RenewalReport"
import AssignZumbaClasses from "pages/AssignClasses/AssignZumbaClasses"
import YogaClasses from "pages/AssignClasses/YogaClasses"
import ManageSteamBath from "pages/AssignClasses/ManageSteamBath"
import ManageAds from "pages/Ads/ManageAds"

const authProtectedRoutes = [
  { path: "/subscription", component: Subscription },
  { path: "/member", component: Member },
  { path: "/membership", component: Membership },
  { path: "/manage-exercise", component: ManageExercise },
  { path: "/create-exercise", component: AddExercise },
  { path: "/edit-exercise/:exercise_id", component: EditExercise },
  { path: "/manage-workout-plan", component: ManageWorkoutPlan },
  { path: "/create-workout-plan", component: AddWorkoutPlan },
  { path: "/edit-workout-plan", component: EditWorkoutPlan },

  { path: "/workout-view", component: WorkoutView },

  { path: "/member-details", component: MemberDetails },
  { path: "/members-workout-plan", component: MemberWorkoutPlan },

  { path: "/inbox", component: Inbox },
  { path: "/compose-message", component: ComposeMessage },
  { path: "/inbox/inbox-details", component: InboxDetails },

  { path: "/dashboard", component: Dashboard, isCommon: true },
  { path: "/profile", component: UserProfile, isCommon: true },
  { path: "/change-password", component: ChangePassword, isCommon: true },

  { path: "/manage-diet-plan", component: ManageDietPlan },
  { path: "/add-diet-plan", component: AddDietPlan },
  { path: "/edit-diet-plan", component: EditDietPlan },
  { path: "/members-diet-chart", component: MemberDietPlan },
  { path: "/diet-view", component: DietView },

  { path: "/manage-body-composition", component: ManageBodyComposition },
  { path: "/body-composition-view", component: BodyCompositionView },
  { path: "/trainer", component: AddTrainer },

  { path: "/manage-batch", component: ManageBatch },
  { path: "/add-update-batch", component: AddEditBatch },
  { path: "/financial-report", component: FinancialReport },
  { path: "/renewal-report", component: RenewalReport },
  { path: "/assign-zumba-classes", component: AssignZumbaClasses },
  { path: "/yoga-classes", component: YogaClasses },
  { path: "/steam-bath", component: ManageSteamBath },
  { path: "/manage-ads", component: ManageAds },
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
]

export { authProtectedRoutes, publicRoutes }
