import { BOOKED_DIET_APPOINTMENT } from "./actionTypes"

const initialState = {
    dietAppointmentBookingStatus: false
}

const DietBookedAppointment = (state = initialState, action) => {
    switch (action.type) {
        case BOOKED_DIET_APPOINTMENT:
            state = { dietAppointmentBookingStatus: action.dietAppointmentBookingStatus }
            break;
        default:
            state = { dietAppointmentBookingStatus: null}
            break;
    }
    return state;
}

export default DietBookedAppointment;