import MetaTags from "react-meta-tags";
import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";
// actions
import { setProfileData } from "../../store/actions";
import { apiUrls, axiosInstance, graphql } from "api";
import { VIEW_PROFILE_QUERY } from "GraphQLquery/profileQry";
import LoadingButton from "components/Common/LoadingButton";
import images from "assets/images";

const UserProfile = props => {
  const dispatch = useDispatch();
  const profileData = useSelector(state => state.Profile.data);
  const [isSaving, setIsSaving] = useState(false);
  const [editProfileModal, setEditProfileModal] = useState(false);
  const baseURL = process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_ADMIN_IMAGE

  const toggleEditProfileModal = () => {
    // formikObj.resetForm()
    setEditProfileModal(!editProfileModal);
  };
  const openEditProfileModal = () => {
    setTimeout(() => {
      initAutocomplete()
    }, 0);
    setEditProfileModal(true);
  };
  const getProfileDetails = async () => {
    try {
      const res = await axiosInstance.post(apiUrls.profile.me);
      // const res = await graphql({
      //   query: VIEW_PROFILE_QUERY
      // });
      const data = res.data;
      dispatch(setProfileData(data));
    } catch (error) {

    }
  }




  const updateProfile = async (values) => {
    try {
      setIsSaving(true);
      const formData = new FormData();
      for (let [key, value] of Object.entries(values)) {
        formData.append(key, value)
      }
      const res = await axiosInstance.post(apiUrls.auth.updateProfile, formData);
      if (res.success) {
        getProfileDetails();
        swal("Success!", res.message, "success");
      } else {
        swal("Oops!", "Something went wrong!", "error");
      }
    } catch (error) {
      swal("Oops!", "Something went wrong!", "error");
    } finally {
      // formikObj.resetForm()
      setIsSaving(false);
      toggleEditProfileModal();
    }
  }
  const formikObj = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: profileData,
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Your Name"),
      email: Yup.string().required("Please Enter Your Email"),
      salutation: Yup.string().required("Please Select Salutation"),
      primary_contact_no: Yup.number().required("Please Enter Your Contact No."),
    }),
    onSubmit: (values) => {
      updateProfile(values)
    }
  });
  const initAutocomplete = () => {

    let autocomplete = new window.google.maps.places.Autocomplete(
        /** @type {!HTMLInputElement} */(document.getElementById("address")),
      { types: [] });

    window.google.maps.event.addListener(autocomplete, 'place_changed', () => {
      let place = autocomplete.getPlace();
      formikObj.setFieldValue("address", place.formatted_address)
      var componentForm = {
        country: 'long_name',
        locality: 'long_name',
        administrative_area_level_1: 'long_name',
        postal_code: 'short_name'
      };
      for (var i = 0; i < place.address_components.length; i++) {
        var addressType = place.address_components[i].types[0];

        if (componentForm[addressType]) {
          var val = place.address_components[i][componentForm[addressType]];
          if (addressType == "administrative_area_level_1") {
            formikObj.setFieldValue("state", val)
          }
          if (addressType == "locality") {
            formikObj.setFieldValue("city", val)
          }
          if (addressType == "postal_code") {
            formikObj.setFieldValue("zip_code", val)
          }
          else {
            formikObj.setFieldValue("zip_code", '')
          }
        }
      }
    })
  }

  const handleChangeFile = (e) => {
    if (e.target.files[0])
      formikObj.setFieldValue('profile_image', e.target.files[0]);
  }
  useEffect(() => {
    getProfileDetails()
  }, []);

  const handleContactChange = (e) => {
    if (/^[0-9]+$/g.test(e.target.value) || e.target.value == '') {
      formikObj.setFieldValue(e.target.name, e.target.value)
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Profile</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Admin" breadcrumbItem="Profile" />

          <Row>
            <Col lg="12">
              {/* {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? <Alert color="success">{success}</Alert> : null} */}
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md={9} className="order-2 order-md-1">
                      <div className="d-flex">
                        <div className="me-3">
                          <img
                            src={!!profileData.profile_image
                              ? profileData.profile_image
                              : images.defaultAvatar
                            }
                            alt="profile"
                            className="avatar-md rounded-circle img-thumbnail"
                          />
                        </div>
                        <div className="flex-grow-1 align-self-center">
                          <div className="text-muted">
                            <h5>{profileData?.name}</h5>
                            <p className="mb-1">{profileData?.email}</p>
                            <p className="mb-0">{profileData?.primary_contact_no}</p>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md={3} className="align-self-start text-end order-1 order-md-2 mb-3 mb-md-0">
                      <div className="text-end">
                        <Button
                          color="primary"
                          className="font-16 btn-block"
                          onClick={openEditProfileModal}
                        >
                          <i className="fas fa-user-edit me-2" />
                          Edit Profile
                        </Button>
                      </div>

                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={6}>
                      <Label className="mb-1">Name</Label>
                      <p>{profileData?.name}</p>
                    </Col>
                    <Col md={6}>
                      <Label className="mb-1">Email</Label>
                      <p>{profileData?.email}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Label className="mb-1">Primary Contact</Label>
                      <p>{profileData?.primary_contact_no || '---'}</p>
                    </Col>
                    <Col md={6}>
                      <Label className="mb-1">Secondary Contact</Label>
                      <p>{profileData?.secondary_contact_no || '---'}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <Label className="mb-1">Address</Label>
                      <p>{profileData?.address || '---'}</p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal isOpen={editProfileModal} toggle={toggleEditProfileModal}>
            <ModalHeader toggle={toggleEditProfileModal} tag="h4">
              Edit Profile
            </ModalHeader>
            <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  formikObj.handleSubmit()
                  return false
                }}
              >
            <ModalBody>
                <Row>
                  <Col lg={3}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-InputSalutation">Salutation <span className="text-danger">*</span></Label>
                      <Input
                        type="select"
                        id="formrow-InputSalutation"
                        className="form-control"
                        name="salutation"
                        onChange={formikObj.handleChange}
                        value={formikObj.values.salutation}
                        invalid={
                          formikObj.touched.salutation && formikObj.errors.salutation ? true : false
                        }
                      >
                        <option defaultValue>Select</option>
                        <option value="Mr.">Mr.</option>
                        <option value="Mrs.">Mrs.</option>
                        <option value="Ms.">Ms.</option>
                      </Input>
                      {formikObj.touched.salutation && formikObj.errors.salutation ? (
                        <FormFeedback type="invalid">{formikObj.errors.salutation}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col lg={9}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-firstname-Input">Name <span className="text-danger">*</span></Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-firstname-Input"
                        placeholder="Enter Your Name"
                        name="name"
                        onChange={formikObj.handleChange}
                        value={formikObj.values.name}
                        invalid={
                          formikObj.touched.name && formikObj.errors.name ? true : false
                        }
                      />
                      {formikObj.touched.name && formikObj.errors.name ? (
                        <FormFeedback type="invalid">{formikObj.errors.name}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-email-Input">Email <span className="text-danger">*</span></Label>
                      <Input
                        type="email"
                        name="email"
                        className="form-control"
                        id="formrow-email-Input"
                        placeholder="Enter Your Email ID"
                        value={formikObj.values.email}
                        readOnly
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-contact-Input">Primary Contact No. <span className="text-danger">*</span></Label>
                      <Input
                        type="text"
                        name="primary_contact_no"
                        className="form-control"
                        id="formrow-contact-Primary-Input"
                        placeholder="Enter Your Contact No."
                        maxLength={10}
                        onChange={(e) => { handleContactChange(e) }}
                        value={formikObj.values.primary_contact_no}
                        invalid={
                          formikObj.touched.primary_contact_no && formikObj.errors.primary_contact_no ? true : false
                        }
                      />
                      {formikObj.touched.primary_contact_no && formikObj.errors.primary_contact_no ? (
                        <FormFeedback type="invalid">{formikObj.errors.primary_contact_no}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-contact-Input">Secondary Contact No.</Label>
                      <Input
                        type="text"
                        className="form-control"
                        name="secondary_contact_no"
                        id="formrow-contact-Secondary-Input"
                        maxLength={10}
                        placeholder="Enter Your Contact No."
                        // onChange={formikObj.handleChange}
                        onChange={(e) => { handleContactChange(e) }}
                        value={formikObj.values.secondary_contact_no}
                        invalid={
                          formikObj.touched.secondary_contact_no && formikObj.errors.secondary_contact_no ? true : false
                        }
                      />
                      {formikObj.touched.secondary_contact_no && formikObj.errors.secondary_contact_no ? (
                        <FormFeedback type="invalid">{formikObj.errors.secondary_contact_no}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg={12}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-InputAddress">Address</Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="address"
                        placeholder="Enter Address"
                        name="address"
                        autoComplete="off"
                        // -------- for autocomplete --------
                        onChange={formikObj.handleChange}
                        value={formikObj.values.address}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg={4}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-InputCity">City</Label>
                      <Input
                        type="text"
                        name="city"
                        className="form-control"
                        id="city"
                        placeholder="Enter City"
                        onChange={formikObj.handleChange}
                        value={formikObj.values.city}
                      />
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-InputState">State</Label>
                      <Input
                        type="text"
                        id="state"
                        className="form-control"
                        placeholder="Enter State"
                        name="state"
                        onChange={formikObj.handleChange}
                        value={formikObj.values.state}
                      />
                    </div>
                  </Col>

                  <Col lg={4}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-InputZip">Zip</Label>
                      <Input
                        type="text"
                        name="zip_code"
                        className="form-control"
                        id="zip_code"
                        placeholder="Enter Zip Code"
                        onChange={formikObj.handleChange}
                        value={formikObj.values.zip_code}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-InputAddress">Profile Image</Label>
                      <Input
                        type="file"
                        className="form-control"
                        name="profile_image"
                        onChange={handleChangeFile}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <div className="mb-3">
                      <Input
                        type="hidden"
                        className="form-control"
                        name="profile_id"
                        id="formrow-contact-Secondary-Input"
                        maxLength={10}
                        placeholder="Profile ID"
                        // onChange={formikObj.handleChange}
                        onChange={(e) => { handleContactChange(e) }}
                        value={formikObj.values.profile_id}
                        invalid={
                          formikObj.touched.profile_id && formikObj.errors.profile_id ? true : false
                        }
                      />
                      {formikObj.touched.profile_id && formikObj.errors.profile_id ? (
                        <FormFeedback type="invalid">{formikObj.errors.profile_id}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
              <div>
                    <LoadingButton
                      type="submit"
                      color="primary"
                      loading={isSaving}
                    >
                      Update
                    </LoadingButton>
                </div>
            </ModalFooter>
            </Form>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
