import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useEffect, useState } from "react";
import { Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { userForgetPassword } from "../../store/actions";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";
import { axiosInstance, apiUrls } from "api";
import swal from 'sweetalert';
import LoadingButton from "components/Common/LoadingButton";

const ChangePassword = props => {
    const dispatch = useDispatch();
    const [isSubmitting, setSubmitting] = useState(false)
    const updatePassword = async (values) => {
        setSubmitting(true)
        try {
            const response = await axiosInstance.post(apiUrls.auth.changePassword, values);
            if (response.success == 1) {
                formikObj.resetForm();
                swal("Success!", response.message, "success");
            }
            else {
                swal("Oops!", response.message, "error");
            }
        } catch (err) {
            swal("Oops!", "Something went wrong!", "error");
        }finally{
            setSubmitting(false)
        }
    }

    const formikObj = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            old_password: '',
            new_password: "",
            confirm_password: "",
        },
        validationSchema: Yup.object({
            old_password: Yup
                .string()
                .required("Please Enter Your Old Password"),
            new_password: Yup
                .string()
                .required("Please Enter Your New Password"),
            confirm_password: Yup
                .string()
                .required("Please Enter Confirm Password")
                .oneOf([Yup.ref('new_password')], 'Passwords must match'),
        }),
        onSubmit: (values) => {
            updatePassword(values)
        }
    });


    return (
        <React.Fragment>
            <MetaTags>
                <title>
                    Change Password
                </title>
            </MetaTags>
            <div className="home-btn d-none d-sm-block">
                <Link to="/" className="text-dark">
                    <i className="fas fa-home h2" />
                </Link>
            </div>
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden">
                                <div className="bg-primary bg-soft">
                                    <Row>
                                        <Col xs={7}>
                                            <div className="text-primary p-4">
                                                <h5 className="text-primary">Change Password !</h5>
                                            </div>
                                        </Col>
                                        <Col className="col-5 align-self-end">
                                            <img src={profile} alt="" className="img-fluid" />
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody className="pt-0">
                                    <div>
                                        <Link to="/">
                                            <div className="avatar-md profile-user-wid mb-4">
                                                <span className="avatar-title rounded-circle bg-light">
                                                    <img
                                                        src={logo}
                                                        alt=""
                                                        className="rounded-circle"
                                                        height="34"
                                                    />
                                                </span>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="p-2">
                                        <Form
                                            className="form-horizontal"
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                formikObj.handleSubmit();
                                                return false;
                                            }}
                                        >
                                            <Row>
                                                <Col lg={12}>
                                                    <div className="mb-3">
                                                        <Label className="form-label">Enter Old Password <span className="text-danger">*</span></Label>
                                                        <Input
                                                            name="old_password"
                                                            className="form-control"
                                                            placeholder="Enter Old Password"
                                                            type="password"
                                                            onChange={formikObj.handleChange}
                                                            value={formikObj.values.old_password}
                                                            invalid={
                                                                formikObj.touched.old_password && formikObj.errors.old_password ? true : false
                                                            }
                                                        />
                                                        {formikObj.touched.old_password && formikObj.errors.old_password ? (
                                                            <FormFeedback type="invalid">
                                                                {formikObj.errors.old_password}
                                                            </FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="new-password-Input">New Password <span className="text-danger">*</span></Label>
                                                        <Input
                                                            type="password"
                                                            name="new_password"
                                                            className="form-control"
                                                            id="new-password-Input"
                                                            placeholder="Enter Your New Password"
                                                            onChange={formikObj.handleChange}
                                                            value={formikObj.values.new_password}
                                                            invalid={
                                                                formikObj.touched.new_password && formikObj.errors.new_password ? true : false
                                                            }
                                                        />
                                                        {formikObj.touched.new_password && formikObj.errors.new_password ? (
                                                            <FormFeedback type="invalid">{formikObj.errors.new_password}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-confirm-password-Input">Confirm Password <span className="text-danger">*</span></Label>
                                                        <Input
                                                            type="password"
                                                            name="confirm_password"
                                                            className="form-control"
                                                            id="formrow-confirm-password-Input"
                                                            placeholder="Confirm Your Password"
                                                            onChange={formikObj.handleChange}
                                                            value={formikObj.values.confirm_password}
                                                            invalid={
                                                                formikObj.touched.confirm_password && formikObj.errors.confirm_password ? true : false
                                                            }
                                                        />
                                                        {formikObj.touched.confirm_password && formikObj.errors.confirm_password ? (
                                                            <FormFeedback type="invalid">{formikObj.errors.confirm_password}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                            </Row>
                                            
                                            <Row>
                                                <Col sm={12}>
                                                    <div className="text-end mt-1">
                                                        <LoadingButton loading={isSubmitting}>Submit</LoadingButton>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>

                                    </div>
                                </CardBody>
                            </Card>

                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};



export default ChangePassword;
