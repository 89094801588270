import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useEffect, useState } from "react";
import {
    Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label, Button, Modal,
    ModalBody,
    ModalHeader,
    InputGroup,
    CardTitle
} from "reactstrap";
import { withRouter, Link } from "react-router-dom";

// Formik formikObj
import * as Yup from "yup";
import { FieldArray, useFormik, FormikProvider } from "formik";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { standartDateFormat } from "utils/date";
import { axiosInstance, apiUrls } from "api";
import swal from 'sweetalert';
import { graphql } from "api";
// Subhadip Work Start
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
// Subhadip Work End
import CommonTable from "components/Common/CommonTable";
import usePaginator from "hooks/usePaginator";
import * as DietQry from "GraphQLquery/dietQry";
// Form Editor
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
    EditorState,
    convertToRaw,
    ContentState,
    convertFromHTML
} from "draft-js";
import draftToHtml from "draftjs-to-html";

const initialDietData = {
    diet_plan_category_id: "",
    plan_name: "",
    dietitian_name: "",
    issues: '',
    diet_goal: '',
    daily_ration_nutrients: "",
    daily_ration_foods: "",
    pulpy_seasonal_foods: '',
    citrus_fruits: '',
    foods_to_avoid: "",
    note: "",
    diet_plan_details: [{
        time: "",
        meal: "",
    }]
}

const initialCategoryData = {
    category_items: [{
        id: "",
        category_name: ""
    }]
}

const initialCategoryDataAdd = {
    category_items: [{
        category_name: ""
    }]
}

const AddDietPlan = props => {
    const [dietData, setDietData] = useState({ ...initialDietData })
    const [categoryData, setCategoryData] = useState({ ...initialCategoryData })
    const [categoryDataAdd, setCategoryDataAdd] = useState({ ...initialCategoryDataAdd })
    const [exerciseId, setExerciseId] = useState(null)
    const [isSaving, setIsSaving] = useState(false);
    const [isDietSaving, setIsDietSaving] = useState(false);
    const [dietCategory, setDietCategory] = useState([])
    const [error, setError] = useState(null);
    const [addEditCategoryModal, setAddEditCategoryModal] = useState(false);
    const [editId, setEditId] = useState('')
    const [addingNewCategory, setAddingNewCategory] = useState(false)
    const [updatingCategory, setUpdatingCategory] = useState(false)
    const [categoryDelete, setCategoryDelete] = useState(false)
    const [paginatorInfo, setPaginatorInfo, resetPaginatorInfo] = usePaginator();

    useEffect(() => {
        fetchDietCategories();
    }, [])

    const fetchDietCategories = async () => {
        try {
            let response = null;
            response = await graphql({
                query: DietQry.DIET_CATEGORY_QUERY,
                variables: {
                    ...paginatorInfo
                }
            });
            let dietCategory = []
            const category_items = []
            dietCategory = response?.data?.diet_plan_categories?.data || [];
            setDietCategory(dietCategory);

            for (let index = 0; index < dietCategory.length; index++) {
                category_items.push({
                    id: dietCategory[index].id,
                    category_name: dietCategory[index].category_name
                })
            }
            setCategoryData(prev => {
                return ({ ...prev, category_items: category_items })
            }
            )

        } catch (err) {
            setDietCategory([]);
        }
    }

    const formikObj = useFormik({
        enableReinitialize: true,

        initialValues: { ...dietData },
        validationSchema: Yup.object({
            diet_plan_category_id: Yup.string().required("Please Select Category"),
            plan_name: Yup.string().required("Please Enter Plan Name"),
        }),
        onSubmit: async (values) => {
            try {
                setIsDietSaving(true)
                const response = await axiosInstance.post(apiUrls.diet.addAndUpdateDietPlan, { ...values });
                if (response.success == 1) {
                    formikObj.resetForm()
                    setIsDietSaving(false)
                    swal("Success!", response.message, "success");
                    props.history.goBack()
                }
                else {
                    setIsDietSaving(false)
                    swal("Oops!", response.message, "error");
                }
            } catch (err) {
                setIsDietSaving(false)
                swal("Oops!", "Something went wrong!", "error");
                setError(err.message)
            }
        }
    });

    const addExerciseData = async (values) => {
        try {
            setIsSaving(true)
            const response = await axiosInstance.post(apiUrls.diet.addAndUpdateDietPlan, { ...values });
            if (response.success == 1) {
                formikObj.resetForm()
                setIsSaving(false)
                swal("Success!", response.message, "success");
                props.history.goBack()
            }
            else {
                setIsSaving(false)
                swal("Oops!", response.message, "error");
            }
        } catch (err) {
            setIsSaving(false)
            swal("Oops!", "Something went wrong!", "error");
            setError(err.message)
        }
    }

    const handleExerciseTypeChange = (e) => {
        formikObj.setFieldValue(e.target.name, e.target.value)
    }

    const formikCategoryObj = useFormik({
        enableReinitialize: true,
        initialValues: { ...categoryData },
        validationSchema: Yup.object({

        }),
        onSubmit: async (values) => {
        }
    })

    const formikCategoryObjAdd = useFormik({
        enableReinitialize: true,
        initialValues: { ...categoryDataAdd },
        validationSchema: Yup.object({

        }),
        onSubmit: async (values) => {
        }
    })

    const openModal = () => {
        setAddEditCategoryModal(true);
    }

    const closeModal = () => {
        setAddEditCategoryModal(false);
    }

    const createCategory = () => {
        setEditId('')
        setUpdatingCategory(false)
        setAddingNewCategory(false)
        setCategoryDelete(false)
        formikCategoryObj.resetForm();
        formikCategoryObjAdd.resetForm();
        openModal()
    }

    const getEditDeleteId = async (e, item, isDelete) => {
        setCategoryDelete(false)
        let editId = ''
        let res = formikCategoryObj.values.category_items.filter(data => data.id == item.id)
        editId = res[0].id
        setEditId(editId)
        if (isDelete == true) {
            setCategoryDelete(true)
            swal({
                title: "Are you sure?",
                text: "Once deleted, you will not be able to recover",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then(async (willDelete) => {
                    if (willDelete) {
                        try {
                            setIsSaving(true)
                            const response = await axiosInstance.post(apiUrls.diet.deleteDietPlanCategory, { id: item.id });
                            if (response.success == 1) {
                                setIsSaving(false)
                                closeModal()
                                fetchDietCategories();
                                swal("Success!", response.message, "success");
                            }
                            else {
                                setIsSaving(false)
                                swal("Oops!", response.message, "error");
                            }
                        } catch (err) {
                            setIsSaving(false)
                            swal("Oops!", "Something went wrong!", "error");
                            setError(err.message)
                        }
                    }
                });
        }
    }

    const addEditWorkCategory = async (editValues, addValues) => {
        let values
        if (addingNewCategory == true && updatingCategory == false) {
            values = addValues
        }
        if (addingNewCategory == false && updatingCategory == true) {
            values = editValues
        }
        if (addingNewCategory == true && updatingCategory == true) {
            addValues.category_items.forEach(element => {
                editValues.category_items.push(element)
            });
            values = editValues
        }
        // return
        try {
            setIsSaving(true)
            const response = await axiosInstance.post(apiUrls.diet.addAndUpdateDietPlanCategory, { ...values });
            if (response.success == 1) {
                setIsSaving(false)
                closeModal()
                fetchDietCategories();
                swal("Success!", response.message, "success");
            }
            else {
                setIsSaving(false)
                swal("Oops!", response.message, "error");
            }
        } catch (err) {
            setIsSaving(false)
            swal("Oops!", "Something went wrong!", "error");
            setError(err.message)
        }

    }

    const handleDailyRationNutrients = (editorState) => {
        let message = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        formikObj.setFieldValue("daily_ration_nutrients", message)
    }

    const handleDailyRationFoods = (editorState) => {
        let message = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        formikObj.setFieldValue("daily_ration_foods", message)
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Add Diet Plan</title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumbs title="Diet Plan" breadcrumbItem="Add Diet Plan" />
                    <Card>
                        <CardBody>
                            {/* <CardTitle className="mb-4 text-primary">Add Diet Plan</CardTitle> */}
                            <Form>
                                <div className="row mb-4">
                                    <Label className="col-sm-3 col-form-label">
                                        Plan Name <span className="text-danger">*</span>
                                    </Label>
                                    <Col sm={9}>
                                        <Input
                                            type="text"
                                            id="formrow-InputService"
                                            className="form-control"
                                            name="plan_name"
                                            onChange={formikObj.handleChange}
                                            value={formikObj.values.plan_name}
                                            placeholder="Enter Plan Name"
                                            invalid={
                                                formikObj.touched.plan_name && formikObj.errors.plan_name ? true : false
                                            }
                                        />
                                        {formikObj.touched.plan_name && formikObj.errors.plan_name ? (
                                            <FormFeedback type="invalid">{formikObj.errors.plan_name}</FormFeedback>
                                        ) : null}
                                    </Col>
                                </div>
                                <div className="row mb-4">
                                    <Label className="col-sm-3 col-form-label">
                                        Dietitian Name
                                    </Label>
                                    <Col sm={9}>
                                        <Input
                                            type="text"
                                            id="formrow-InputService"
                                            className="form-control"
                                            name="dietitian_name"
                                            onChange={formikObj.handleChange}
                                            value={formikObj.values.dietitian_name}
                                            placeholder="Enter Dietitian Name"
                                            invalid={
                                                formikObj.touched.dietitian_name && formikObj.errors.dietitian_name ? true : false
                                            }
                                        />
                                        {formikObj.touched.dietitian_name && formikObj.errors.dietitian_name ? (
                                            <FormFeedback type="invalid">{formikObj.errors.dietitian_name}</FormFeedback>
                                        ) : null}
                                    </Col>
                                </div>
                                <div className="row mb-4">
                                    <Label className="col-sm-3 col-form-label">
                                        Select Category <span className="text-danger">*</span>
                                    </Label>
                                    <Col sm={8}>
                                        <Input
                                            type="select"
                                            id="formrow-serviceType"
                                            className="form-control"
                                            name={'diet_plan_category_id'}
                                            onChange={formikObj.handleChange}
                                            value={formikObj.values.diet_plan_category_id}
                                            invalid={
                                                formikObj.touched.diet_plan_category_id && formikObj.errors.diet_plan_category_id ? true : false
                                            }
                                        >
                                            <option defaultValue>Select</option>
                                            {dietCategory.map((item) => (
                                                <option value={item.id} key={item.id}>{item.category_name}</option>
                                            ))}

                                        </Input>
                                        {formikObj.touched.diet_plan_category_id && formikObj.errors.diet_plan_category_id ? (
                                            <FormFeedback type="invalid">{formikObj.errors.diet_plan_category_id}</FormFeedback>
                                        ) : null}
                                    </Col>
                                    <Col sm={1}>
                                        <button
                                            type="button"
                                            className="btn-rounded  btn btn-primary mx-2 btn-sm"
                                            onClick={createCategory}
                                        >
                                            <i className="bx bxs-cog align-middle" />
                                        </button>
                                    </Col>
                                </div>
                                <div className="row mb-4">
                                    <Label className="col-sm-3 col-form-label">
                                        Issues
                                    </Label>
                                    <Col sm={9}>
                                        <Input
                                            type="text"
                                            id="formrow-InputService"
                                            className="form-control"
                                            name="issues"
                                            onChange={formikObj.handleChange}
                                            value={formikObj.values.issues}
                                            placeholder="Enter Issues"
                                            invalid={
                                                formikObj.touched.issues && formikObj.errors.issues ? true : false
                                            }
                                        />
                                        {formikObj.touched.issues && formikObj.errors.issues ? (
                                            <FormFeedback type="invalid">{formikObj.errors.issues}</FormFeedback>
                                        ) : null}
                                    </Col>
                                </div>
                                <div className="row mb-4">
                                    <Label className="col-sm-3 col-form-label">
                                        Diet Goal
                                    </Label>
                                    <Col sm={9}>
                                        <Input
                                            type="text"
                                            id="formrow-InputService"
                                            className="form-control"
                                            name="diet_goal"
                                            onChange={formikObj.handleChange}
                                            value={formikObj.values.diet_goal}
                                            placeholder="Enter Diet Goal"
                                            invalid={
                                                formikObj.touched.diet_goal && formikObj.errors.diet_goal ? true : false
                                            }
                                        />
                                        {formikObj.touched.diet_goal && formikObj.errors.diet_goal ? (
                                            <FormFeedback type="invalid">{formikObj.errors.diet_goal}</FormFeedback>
                                        ) : null}
                                    </Col>
                                </div>
                                <div className="row mb-4">
                                    <Label className="col-sm-3 col-form-label">
                                        Daily Ration (Nutrients)
                                    </Label>
                                    <Col sm={9}>
                                        <Editor
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            onEditorStateChange={handleDailyRationNutrients}
                                        />
                                        {/* <Input
                                            type="text"
                                            id="formrow-InputService"
                                            className="form-control"
                                            name="daily_ration_nutrients"
                                            onChange={formikObj.handleChange}
                                            value={formikObj.values.daily_ration_nutrients}
                                            placeholder="Enter Daily Ration (Nutrients)"
                                            invalid={
                                                formikObj.touched.daily_ration_nutrients && formikObj.errors.daily_ration_nutrients ? true : false
                                            }
                                        />
                                        {formikObj.touched.daily_ration_nutrients && formikObj.errors.daily_ration_nutrients ? (
                                            <FormFeedback type="invalid">{formikObj.errors.daily_ration_nutrients}</FormFeedback>
                                        ) : null} */}
                                    </Col>
                                </div>
                                <div className="row mb-4">
                                    <Label className="col-sm-3 col-form-label">
                                        Daily Ration (Foods)
                                    </Label>
                                    <Col sm={9}>
                                        <Editor
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            onEditorStateChange={handleDailyRationFoods}
                                        />
                                        {/* <Input
                                            type="text"
                                            id="formrow-InputService"
                                            className="form-control"
                                            name="daily_ration_foods"
                                            onChange={formikObj.handleChange}
                                            value={formikObj.values.daily_ration_foods}
                                            placeholder="Enter Daily Ration (Foods)"
                                            invalid={
                                                formikObj.touched.daily_ration_foods && formikObj.errors.daily_ration_foods ? true : false
                                            }
                                        />
                                        {formikObj.touched.daily_ration_foods && formikObj.errors.daily_ration_foods ? (
                                            <FormFeedback type="invalid">{formikObj.errors.daily_ration_foods}</FormFeedback>
                                        ) : null} */}
                                    </Col>
                                </div>
                                <div className="row mb-4">
                                    <Label className="col-sm-3 col-form-label">
                                        Pulpy Seasonal Foods
                                    </Label>
                                    <Col sm={9}>
                                        <Input
                                            type="text"
                                            id="formrow-InputService"
                                            className="form-control"
                                            name="pulpy_seasonal_foods"
                                            onChange={formikObj.handleChange}
                                            value={formikObj.values.pulpy_seasonal_foods}
                                            placeholder="Enter Pulpy Seasonal Foods"
                                            invalid={
                                                formikObj.touched.pulpy_seasonal_foods && formikObj.errors.pulpy_seasonal_foods ? true : false
                                            }
                                        />
                                        {formikObj.touched.pulpy_seasonal_foods && formikObj.errors.pulpy_seasonal_foods ? (
                                            <FormFeedback type="invalid">{formikObj.errors.pulpy_seasonal_foods}</FormFeedback>
                                        ) : null}
                                    </Col>
                                </div>
                                <div className="row mb-4">
                                    <Label className="col-sm-3 col-form-label">
                                        Citrus Fruits
                                    </Label>
                                    <Col sm={9}>
                                        <Input
                                            type="text"
                                            id="formrow-InputService"
                                            className="form-control"
                                            name="citrus_fruits"
                                            onChange={formikObj.handleChange}
                                            value={formikObj.values.citrus_fruits}
                                            placeholder="Enter Citrus Fruits"
                                            invalid={
                                                formikObj.touched.citrus_fruits && formikObj.errors.citrus_fruits ? true : false
                                            }
                                        />
                                        {formikObj.touched.citrus_fruits && formikObj.errors.citrus_fruits ? (
                                            <FormFeedback type="invalid">{formikObj.errors.citrus_fruits}</FormFeedback>
                                        ) : null}
                                    </Col>
                                </div>
                                <div className="row mb-4">
                                    <Label className="col-sm-3 col-form-label">
                                        Foods To Avoid
                                    </Label>
                                    <Col sm={9}>
                                        <Input
                                            type="textarea"
                                            id="formrow-InputService"
                                            className="form-control"
                                            name="foods_to_avoid"
                                            onChange={formikObj.handleChange}
                                            value={formikObj.values.foods_to_avoid}
                                            placeholder="Enter Foods To Avoid"
                                            invalid={
                                                formikObj.touched.foods_to_avoid && formikObj.errors.foods_to_avoid ? true : false
                                            }
                                        />
                                        {formikObj.touched.foods_to_avoid && formikObj.errors.foods_to_avoid ? (
                                            <FormFeedback type="invalid">{formikObj.errors.foods_to_avoid}</FormFeedback>
                                        ) : null}
                                    </Col>
                                </div>
                                <FormikProvider value={formikObj}>
                                    <FieldArray
                                        name="diet_plan_details"
                                        render={arrayHelpers => (
                                            <>
                                                {formikObj.values.diet_plan_details.map((item, index) => {
                                                    const isLastRow = (formikObj.values?.diet_plan_details?.length - 1) === index;
                                                    const getFieldName = name => `${arrayHelpers.name}[${index}][${name}]`;
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <div className="row mb-4">
                                                                <Label className="col-sm-3 col-form-label">
                                                                    Time
                                                                </Label>
                                                                <Col sm={9}>
                                                                    <Flatpickr
                                                                        className="form-control d-block"
                                                                        name={getFieldName('time')}
                                                                        options={{
                                                                            noCalendar: true,
                                                                            enableTime: true,
                                                                            dateFormat: 'h:i K',
                                                                        }}
                                                                        // onChange={handleStartTimeChange}
                                                                        onChange={(e, timeChange) => {
                                                                            formikObj.setFieldValue(getFieldName("time"), timeChange)
                                                                        }}
                                                                        value={item.time}
                                                                    />
                                                                </Col>
                                                            </div>

                                                            <div className="row mb-4">
                                                                <Label className="col-sm-3 col-form-label">
                                                                    Meal
                                                                </Label>
                                                                <Col sm={9}>
                                                                    <Input
                                                                        type="textarea"
                                                                        id="formrow-InputService"
                                                                        className="form-control"
                                                                        name={getFieldName('meal')}
                                                                        onChange={formikObj.handleChange}
                                                                        value={item.meal}
                                                                        placeholder="Enter Meal"
                                                                        invalid={
                                                                            formikObj.touched.meal && formikObj.errors.meal ? true : false
                                                                        }
                                                                    />
                                                                    {formikObj.touched.meal && formikObj.errors.meal ? (
                                                                        <FormFeedback type="invalid">{formikObj.errors.meal}</FormFeedback>
                                                                    ) : null}
                                                                </Col>
                                                            </div>

                                                            <div className="d-flex align-items-center mb-3" style={{ justifyContent: "end" }}>
                                                                <div className="">
                                                                    {formikObj.values?.diet_plan_details.length > 1 && <button
                                                                        type="button"
                                                                        className="btn-rounded  btn btn-danger ms-2 btn-sm"
                                                                        onClick={() => arrayHelpers.remove(index)}
                                                                    > <i className="bx bx-minus align-middle" />
                                                                    </button>
                                                                    }
                                                                </div>
                                                                <div className="">
                                                                    {/* ADD Button */}
                                                                    {isLastRow && <button
                                                                        type="button"
                                                                        className="btn-rounded  btn btn-success ms-2 btn-sm"
                                                                        onClick={() => arrayHelpers.push({
                                                                            time: "",
                                                                            meal: ""
                                                                        })}>
                                                                        <i className="bx bx-plus align-middle" />
                                                                    </button>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                    )
                                                })}
                                            </>
                                        )}
                                    >

                                    </FieldArray>
                                </FormikProvider>

                                <div className="row mb-4">
                                    <Label className="col-sm-3 col-form-label">
                                        Note
                                    </Label>
                                    <Col sm={9}>
                                        <Input
                                            type="textarea"
                                            id="formrow-InputService"
                                            className="form-control"
                                            name="note"
                                            onChange={formikObj.handleChange}
                                            //value={formikObj.values.note}
                                            placeholder="Enter Note"
                                        // invalid={
                                        //     formikObj.touched.note && formikObj.errors.note ? true : false
                                        // }
                                        />
                                        {/* {formikObj.touched.note && formikObj.errors.note ? (
                                                <FormFeedback type="invalid">{formikObj.errors.note}</FormFeedback>
                                            ) : null} */}
                                    </Col>
                                </div>


                                <div className="row justify-content-end">
                                    <Col sm={9}>
                                        {!!isDietSaving ?
                                            <div>
                                                <button className="btn btn-primary " disabled>
                                                    Processing
                                                </button>
                                            </div>
                                            :
                                            <div className="d-flex justify-content-end">
                                                <Button
                                                    type="submit"
                                                    color="primary"
                                                    className="w-md"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        formikObj.handleSubmit();
                                                        return false;
                                                    }}
                                                >
                                                    Submit
                                                </Button>
                                            </div>
                                        }


                                    </Col>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </Container>
                <Modal
                    isOpen={addEditCategoryModal}
                    toggle={closeModal}
                    size="lg"
                    backdrop="static"
                // className={props.className}
                >
                    <ModalHeader toggle={closeModal} tag="h4">
                        Manage Category
                    </ModalHeader>
                    <ModalBody>
                        <FormikProvider value={formikCategoryObj}>

                            {/* EDIT CATEGORY */}

                            <Form>
                                <FieldArray
                                    name="category_items"
                                    render={arrayHelpers => (
                                        <>
                                            {formikCategoryObj.values.category_items.map((item, index) => {
                                                const getFieldName = name => `${arrayHelpers.name}[${index}][${name}]`;
                                                return (
                                                    <Row key={index}>
                                                        <Col lg={10}>
                                                            <div className="mb-3">
                                                                <Input
                                                                    type="text"
                                                                    id={item.id}
                                                                    className="form-control"
                                                                    name={getFieldName('category_name')}
                                                                    onChange={(e) => { formikCategoryObj.handleChange(e); setUpdatingCategory(true); }}
                                                                    value={item.category_name}
                                                                    readOnly={categoryDelete ? true : editId == item.id ? false : true}
                                                                    invalid={
                                                                        formikCategoryObj.touched.category_name && formikCategoryObj.errors.category_name ? true : false
                                                                    }
                                                                >
                                                                </Input>
                                                            </div>
                                                        </Col>
                                                        <Col lg={2}>
                                                            <button
                                                                type="button"
                                                                className="btn-rounded  btn btn-primary ms-2 btn-sm"
                                                                onClick={(e) => getEditDeleteId(e, item, false)}
                                                            >
                                                                <i className="bx bx-pencil" />
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn-rounded  btn btn-danger mx-2 btn-sm"
                                                                onClick={(e) => getEditDeleteId(e, item, true)}
                                                            >
                                                                <i className="bx bx-trash align-middle" />
                                                            </button>
                                                        </Col>
                                                    </Row>
                                                )
                                            })}
                                        </>
                                    )}
                                />
                            </Form>
                        </FormikProvider>

                        {/* ADD NEW CATEGORY */}

                        <FormikProvider value={formikCategoryObjAdd}>
                            <Form>
                                <FieldArray
                                    name="category_items"
                                    render={arrayHelpersAdd => (
                                        <>
                                            {formikCategoryObjAdd.values.category_items.map((item, index) => {
                                                const isLastRow = (formikCategoryObjAdd.values?.category_items?.length - 1) === index;
                                                const getFieldName = name => `${arrayHelpersAdd.name}[${index}][${name}]`;
                                                return (
                                                    <Row key={index}>
                                                        <Col lg={10}>
                                                            <div className="mb-3">
                                                                <Input
                                                                    type="text"
                                                                    id={item.id}
                                                                    className="form-control"
                                                                    name={getFieldName('category_name')}
                                                                    onChange={(e) => { formikCategoryObjAdd.handleChange(e); setAddingNewCategory(true) }}
                                                                    value={item.category_name}
                                                                    placeholder="Enter Category Name"
                                                                    invalid={
                                                                        formikCategoryObjAdd.touched.category_name && formikCategoryObjAdd.errors.category_name ? true : false
                                                                    }
                                                                >
                                                                </Input>
                                                            </div>
                                                        </Col>
                                                        <Col lg={2} className="d-flex align-items-center mb-3">
                                                            <div className="">
                                                                {formikCategoryObjAdd.values?.category_items.length > 1 && <button
                                                                    type="button"
                                                                    className="btn-rounded  btn btn-danger ms-2 btn-sm"
                                                                    onClick={() => arrayHelpersAdd.remove(index)}
                                                                > <i className="bx bx-minus align-middle" />
                                                                </button>
                                                                }
                                                            </div>
                                                            <div className="">
                                                                {/* ADD Button */}
                                                                {isLastRow && <button
                                                                    type="button"
                                                                    className="btn-rounded  btn btn-success ms-2 btn-sm"
                                                                    onClick={() => arrayHelpersAdd.push({
                                                                        category_name: ""
                                                                    })}>
                                                                    <i className="bx bx-plus align-middle" />
                                                                </button>
                                                                }
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                )
                                            })}
                                        </>
                                    )}
                                />
                            </Form>
                        </FormikProvider>
                    </ModalBody>
                    <div className="modal-footer">
                        {!!isSaving ?
                            <button className="btn btn-primary " disabled>
                                Processing
                            </button>
                            :
                            <>
                                <button
                                    type="submit"
                                    className="btn btn-primary "
                                    onClick={(e) => {
                                        e.preventDefault();
                                        addEditWorkCategory(formikCategoryObj.values, formikCategoryObjAdd.values)
                                        return false;
                                    }}
                                >
                                    SAVE
                                </button>
                            </>
                        }
                    </div>
                </Modal>
            </div>
        </React.Fragment>
    )
}

export default withRouter(AddDietPlan);

AddDietPlan.propTypes = {
    history: PropTypes.object,
};