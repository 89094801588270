import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useEffect, useState } from "react";
import {
    Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label, Button, Modal,
    ModalBody,
    ModalHeader,
    InputGroup
} from "reactstrap";
import { withRouter, Link } from "react-router-dom";

// Formik formikObj
import * as Yup from "yup";
import { FieldArray, FormikProvider, useFormik } from "formik";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { standartDateFormat, today } from "utils/date";
import { axiosInstance, apiUrls } from "api";
import { authStorage } from "utils/auth";
import swal from 'sweetalert';
import profile_ids from "utils/profile_ids";
import { graphql } from "api";
import CommonTable from "components/Common/CommonTable";
import usePaginator from "hooks/usePaginator";
import * as MemberQry from "GraphQLquery/memberQry";
import EditMember from "../Common/EditMember";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import * as SubscriptionQry from "GraphQLquery/subscriptionQry";
import images from "assets/images";
import { values } from "lodash";
import Flatpickr from "react-flatpickr";
import { FILETYPE, checkFileType } from "utils/fileType";


const initialMemberData = {
    member_name: "",
    member_age: "",
    profession: "",
    gender: "",
    // (1-Male /2-Female/3-transgender)
    address: "",
    mobile_no: "",
    email_id: "",
    status: 1,
    profile_image: "",
    id_proof: ""
}

const initialNoteData = {
    notes: [{
        note: ""
    }]
}


let imageSrc = ''
let idProofSrc = ''
const Member = props => {
    const memberUpdated = useSelector(state => state.MemberUpdated.memberUpdateStatus);
    const [memberData, setMemberData] = useState({ ...initialMemberData })
    const [noteData, setNoteData] = useState({ ...initialNoteData })
    const [memberWiseNoteData, setMemberWiseNoteData] = useState([])
    const [memberWiseNoteDataLength, setMemberWiseNoteDataLength] = useState(0)
    const [memberId, setMemberId] = useState(null)
    const [memberIdForNote, setMemberIdForNote] = useState(null)
    const [isSaving, setIsSaving] = useState(false);
    const [isNoteSaving, setIsNoteSaving] = useState(false);
    const [isGetingNoteData, setIsGetingNoteData] = useState(false);
    const [addEditMemberModal, setAddEditMemberModal] = useState(false);
    const [error, setError] = useState(null);
    const [processingList, setProcessingList] = useState(false);
    const [allMemberList, setAllMemberList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [serviceTypes, setServiceTypes] = useState([]);
    const [hasGender, setHasGender] = useState(true);
    const [paginatorInfo, setPaginatorInfo, resetPaginatorInfo] = usePaginator();
    const [searchText, setSearchText] = useState('');
    const [editMemberModal, setEditMemberModal] = useState(false)
    const [noteModel, setNoteModel] = useState(false)
    const [memberStatus, setMemberStatus] = useState(1)
    const [userStatus, setuserStatus] = useState(1)
    const [memberMember_Status, setMemberMember_Status] = useState('')
    const [memberTiming, setMemberTiming] = useState("")
    const [mainTrainer, setMainTrainer] = useState("")
    const [mainTrainerList, setMainTrainerList] = useState([])
    const [subscriptionPlan, setSubscriptionPlan] = useState('')
    const [subscriptionPlanList, setSubscriptionPlanList] = useState([])
    const [membershipPlanList, setMembershipPlanList] = useState([])
    const [membershipModel, setMembershipModel] = useState(false)
    const [activityList, setActivityList] = useState([]);
    const [activityId, setActivityId] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    useEffect(() => {
        fetchMemberListData(paginatorInfo, searchText, memberStatus, memberMember_Status, memberTiming, mainTrainer, subscriptionPlan, userStatus, activityId, startDate, endDate)
        setEditMemberModal(false)
        fetchMainTrainerList()
        fetchSubscriptionPlanList();
        fetchActivities();
    }, [memberUpdated])

    const fetchActivities = async () => {
        try {
            const res = await graphql({
                query: SubscriptionQry.ACTIVITIES_LIST_QUERY
            });
            if (res.data.activities) {
                let activities = res?.data?.activities || []
                setActivityList(activities)
            } else {
                setActivityList([])
            }
        } catch (error) {
            setActivityList([])
        }
    }

    const fetchMainTrainerList = async () => {
        try {
            const res = await axiosInstance.post(apiUrls.trainer.allMainTrainerList)
            if (res.success == 1) {
                setMainTrainerList(res.data)
            }
            else {
                setMainTrainerList([])
            }
        } catch (error) {
            setMainTrainerList([])
        }
    }

    const fetchSubscriptionPlanList = async () => {
        try {
            const res = await graphql({
                query: SubscriptionQry.FETCH_ALL_SUBSCRIPTIONS_QUERY_FOR_MEMBER
            });

            if (res.data.subscription_plans.data) {
                setSubscriptionPlanList(!!res.data.subscription_plans ? res.data.subscription_plans.data : []);
            }
            else {
                setSubscriptionPlanList([]);
            }
        } catch (error) {
            setSubscriptionPlanList([]);
            console.log(error);
        }
    }

    const fetchMemberListData = async (paginatorInfo, searchText, memberStatus, memberMember_Status, memberTiming, mainTrainer, subscriptionPlan, userStatus, activityId, startDate, endDate) => {
        setAllMemberList([]);
        try {
            setProcessingList(true)
            const res = await axiosInstance.post(apiUrls.member.memberList + "?page=" + paginatorInfo.currentPage,
                {
                    offset: !!paginatorInfo.perPage ? paginatorInfo.perPage : offSet,
                    search_text: searchText,
                    status: memberStatus,
                    member_status: memberMember_Status,
                    main_trainer: mainTrainer,
                    timing: memberTiming,
                    subscription_plan: subscriptionPlan,
                    user_status: userStatus,
                    activity_id: activityId,
                    start_date: startDate,
                    end_date: endDate
                });

            if (res.success == 1) {
                setProcessingList(false)
                setAllMemberList(!!res.data.data ? res?.data?.data : [])
                let paginatorInfo = {
                    currentPage: parseInt(res?.data?.current_page),
                    perPage: parseInt(res?.data?.per_page),
                    total: parseInt(res?.data?.total),
                }
                setPaginatorInfo(paginatorInfo);
                setSearchText(searchText);
            }

            else {
                setProcessingList(false)
                setAllMemberList([])
                resetPaginatorInfo();
            }
        } catch (error) {
            setProcessingList(false)
            setAllMemberList([])
            resetPaginatorInfo();
        }
    }

    const filterMemberByStatus = (e) => {
        let memberStatus = ""
        let paginator = paginatorInfo
        paginator.currentPage = 1
        if (e.target.value != "") {
            memberStatus = parseInt(e.target.value)
            setMemberStatus(memberStatus)
        }
        else {
            memberStatus = 1
            setMemberStatus(memberStatus)
        }
        fetchMemberListData(paginator, searchText, memberStatus, memberMember_Status, memberTiming, mainTrainer, subscriptionPlan, userStatus, activityId, startDate, endDate)
    }

    const filterMemberByMemberStatus = (e) => {
        let member_Status = ""
        let paginator = paginatorInfo
        paginator.currentPage = 1
        if (e.target.value != "") {
            member_Status = parseInt(e.target.value)
            setMemberMember_Status(member_Status)
        }
        else {
            setMemberMember_Status(member_Status)
        }
        fetchMemberListData(paginator, searchText, memberStatus, member_Status, memberTiming, mainTrainer, subscriptionPlan, userStatus, activityId, startDate, endDate)
    }

    const filterMemberByTiming = (e) => {
        let memberTiming = ""
        let paginator = paginatorInfo
        paginator.currentPage = 1
        if (e.target.value != "") {
            memberTiming = parseInt(e.target.value)
            setMemberTiming(memberTiming)
        }
        else {
            setMemberTiming(memberTiming)
        }
        fetchMemberListData(paginator, searchText, memberStatus, memberMember_Status, memberTiming, mainTrainer, subscriptionPlan, userStatus, activityId, startDate, endDate)
    }

    const filterMemberByMainTrainer = (e) => {
        let mainTrainer = ""
        let paginator = paginatorInfo
        paginator.currentPage = 1
        if (e.target.value != "") {
            mainTrainer = parseInt(e.target.value)
            setMainTrainer(mainTrainer)
        }
        else {
            setMainTrainer(mainTrainer)
        }
        fetchMemberListData(paginator, searchText, memberStatus, memberMember_Status, memberTiming, mainTrainer, subscriptionPlan, userStatus, activityId, startDate, endDate)
    }

    const filterMemberBySubscriptionPlan = (e) => {
        let subscriptionPlan = '';
        let paginator = paginatorInfo
        paginator.currentPage = 1
        if (e.target.value != '') {
            subscriptionPlan = parseInt(e.target.value);
            setSubscriptionPlan(subscriptionPlan);
        } else {
            setSubscriptionPlan(subscriptionPlan);
        }
        fetchMemberListData(paginator, searchText, memberStatus, memberMember_Status, memberTiming, mainTrainer, subscriptionPlan, userStatus, activityId, startDate, endDate)
    }

    const filterByUserStatus = (e) => {
        let userStatus = '';
        if (e.target.checked) {
            userStatus = '';
            setuserStatus('');
        } else {
            userStatus = 1;
            setuserStatus(1);
        }
        let paginator = paginatorInfo
        paginator.currentPage = 1
        fetchMemberListData(paginator, searchText, memberStatus, memberMember_Status, memberTiming, mainTrainer, subscriptionPlan, userStatus, activityId, startDate, endDate)

    }

    const resetAllFilter = (e) => {
        let memberStatus = 1
        let memberTiming = ''
        let mainTrainer = 0
        let subscriptionPlan = ''
        let member_Status = ''
        setMemberStatus(memberStatus)
        setMemberTiming(memberTiming)
        setMainTrainer(mainTrainer)
        setMemberMember_Status(member_Status)
        fetchMemberListData(paginatorInfo, searchText, memberStatus, member_Status, memberTiming, mainTrainer, subscriptionPlan, userStatus, activityId, startDate, endDate)
    }

    const handleTableChange = (paginatorInfo, searchText) => {
        fetchMemberListData(paginatorInfo, searchText, memberStatus, memberMember_Status, memberTiming, mainTrainer, subscriptionPlan, userStatus, activityId, startDate, endDate)
    }


    const handleAction = (cell, row) => {
        return (
            <>
                <div className="d-flex">
                    <Button className="btn btn-info btn-sm ms-2" onClick={(e) => { handleFetch(e, row) }}>
                        <i className="bx bx-pencil align-middle" />
                    </Button>
                    <Button className="btn btn-danger btn-sm ms-2" onClick={(e) => { handleDelete(e, row) }}>
                        <i className="bx bx-trash align-middle" />
                    </Button>
                    <Button disabled={isGetingNoteData} className={row?.note_count > 0 ? "btn btn-info btn-sm ms-2 position-relative" : "btn btn-warning btn-sm ms-2 position-relative"} onClick={(e) => { handleAddNote(e, row) }}>
                        <i className={row?.note_count > 0 ? "bx bxs-note" : "bx bx-note align-middle"} />
                        {/*  badge add */}
                        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                            {row?.note_count > 0 ? row?.note_count : ""}
                        </span>
                    </Button>
                    <Button className={row.user_status == 1 ? "btn btn-success btn-sm ms-2" : "btn btn-danger btn-sm ms-2"} onClick={(e) => { handleChangeStatus(e, row) }}>
                        {row.user_status == 1 ? "Active" : "Inactive"}
                    </Button>
                </div>
            </>
        );
    };

    const handleMemberCACStatus = (cell, row) => {
        if (!!row?.member_access_code_status) {
            if (row?.member_access_code_status == 1) {
                return <div className="d-flex align-items-center"><div>{row?.member_access_code}</div><div><span className="badge rounded-pill bg-success ms-1 px-2 py-1">Assigned</span></div></div>
            } else {
                return <div className="d-flex align-items-center"><div>{row?.member_access_code}</div><div><span className="badge rounded-pill bg-danger ms-1 px-2 py-1">Unassigned</span></div></div>
            }
        } else {

        }
    }


    const handleMember = (cell, row) => {
        return (
            <div>
                {/* <Link
                    to={{ pathname: "/member-details", state: { member_id: row.id, tabType: "1" } }}
                  
                    style={{ color: row?.name_color_status == 1 && "#d50c38" }}
                >
                    <div>
                        {row.member_name}
                        <br />
                        {!!row.member_status ? ((row.member_status == 1 ? <small className="badge rounded-pill bg-warning px-2 py-1">Irregular</small> : (row.member_status == 2) ? <small className="badge rounded-pill bg-info px-2 py-1">Discontinued</small> : "")) : ""}
                    </div>
                </Link> */}

                <Link
                    to={{ pathname: "/member-details", search: `?member_id=${row.id}&tabType=1` }}
                    style={{ color: row?.name_color_status == 1 && "#d50c38" }}
                >
                    <div>
                        {row.member_name}
                        <br />
                        {!!row.member_status ? ((row.member_status == 1 ? <small className="badge rounded-pill bg-warning px-2 py-1">Irregular</small> : (row.member_status == 2) ? <small className="badge rounded-pill bg-info px-2 py-1">Discontinued</small> : "")) : ""}
                    </div>
                </Link>
            </div>
        )
    }

    const handleBodyCompositionAssigned = (cell, row) => {

        if (row?.body_composition_status == 1) {
            return <span className="p-l-4"> NA</span>
        }

        else if (row?.body_composition_status == 2) {
            return <span style={{ color: "#29a329" }}>Completed</span>
        }

        else if (row?.body_composition_status == 3) {
            if (row?.body_composition_color_status == 1) {
                return <span style={{ color: "#d50c38" }}>Incomplete</span>
            } else {
                return <span>Incomplete</span>
            }
        }
        else {
            return ""
        }

    }

    const handleWorkoutChartAssigned = (cell, row) => {
        if (row?.workout_chart_status == 1) {
            return <span className="p-l-4"> NA</span>
        }

        else if (row?.workout_chart_status == 2) {
            return <span style={{ color: "#29a329" }}>Assigned</span>
        }

        else if (row?.workout_chart_status == 3) {
            if (row?.workout_chart_color_status == 1) {
                return <span style={{ color: "#d50c38" }}>Unassigned</span>
            } else {
                return <span>Unassigned</span>
            }
        }
        else {
            return ""
        }

    }

    const handleDietChartAssigned = (cell, row) => {
        if (row?.diet_chart_status == 1) {
            return <span className="p-l-4"> NA</span>
        }

        else if (row?.diet_chart_status == 2) {
            return <span style={{ color: "#29a329" }}>Assigned</span>
        }

        else if (row?.diet_chart_status == 3) {
            if (row?.diet_chart_color_status == 1) {
                return <span style={{ color: "#d50c38" }}>Unassigned</span>
            } else {
                return <span>Unassigned</span>
            }
        }
        else {
            return ""
        }

    }

    const handleAccessoriesAssigned = (cell, row) => {

        return <div className="d-flex align-items-center">

            {(row?.gymbag_accessories_status == 1 && <span className="p-r-2">NA</span>) ||
                (row?.gymbag_accessories_status == 2 && <div className="size-24"><i className="bx bxs-check-circle color-success"></i></div>) ||
                (row?.gymbag_accessories_status == 3 && <div className="size-24"><i className="bx bxs-x-circle color-danger"></i></div>)}

            {(row?.shippper_accessories_status == 1 && <span className="p-r-2">NA</span>) ||
                (row?.shippper_accessories_status == 2 && <div className="size-24"><i className="bx bxs-check-circle color-success"></i></div>) ||
                (row?.shippper_accessories_status == 3 && <div className="size-24"><i className="bx bxs-x-circle color-danger"></i></div>)}
        </div>
    }

    // Column definition for datatable

    const columns = [{
        formatter: (cell, row) => <Link to={{
            pathname: "/member-details",
            state: { member_id: row.id, tabType: "1" }
        }}
            style={{ color: row?.name_color_status == 1 && "#d50c38" }}>{row.id}</Link>,
        dataField: 'id',
        text: 'ID',
        sort: true,
    },
    {
        formatter: handleMember,
        text: 'NAME',
        sort: true,
    },
    {
        dataField: 'mobile_no',
        text: 'MOBILE NO.',
        sort: true,
    },
    {
        formatter: handleMemberCACStatus,
        text: 'ACCESS CARD NO.',
        sort: true,
    },
    {
        formatter: (cell, row) => row.membership_details.map((item, index) => (
            <div key={index} style={{ whiteSpace: "normal" }}>
                <div className="bg-primary bg-soft p-2 my-1 text-primary badge">
                    <span style={{ fontWeight: "bold", color: "#00008B" }}>{" " + item?.preferred_timing_name + ""}</span>
                </div>
            </div>
        )),
        text: 'TIMING',
        sort: true,
    },
    {
        formatter: (cell, row) => row.membership_details?.[0]?.last_plan_activity?.length > 0 ? row.membership_details?.[0]?.last_plan_activity?.map((item, index) => (
            <div key={index} style={{ whiteSpace: "normal" }}>
                <div className="bg-primary bg-soft p-2 my-1 text-primary badge">
                    <span style={{ fontWeight: "bold", color: "#00008B" }}>{" " + item?.activity + ""}</span>
                </div>
            </div>
        )) : '',
        text: 'ACTIVITY',
        sort: true,
    },
    {
        formatter: (cell, row) => ((row.status == 0 && <span className="badge rounded-pill bg-danger px-2 py-1">Expired</span>) || (row.status == 1 && <span className="badge rounded-pill bg-success  px-2 py-1">Ongoing</span>) || (row.status == 2 && '')),
        text: 'STATUS',
        sort: true,
    },
    // {
    //     formatter: (cell, row) => !!row?.registration_date ? moment(row?.registration_date).format("DD-MM-YYYY") : '',
    //     text: 'REGISTRATION DATE',
    //     sort: true,
    // },
    {
        formatter: (cell, row) => row.membership_details.map((item, index) => (
            <div key={index} style={{ whiteSpace: "normal" }} onClick={(e) => {
                setMembershipPlanList([]);
                setMembershipModel(true);
                setMembershipPlanList(item?.all_plans);
            }}>
                <div className="bg-primary bg-soft p-2 my-1 text-primary badge">
                    {item?.plan_name}
                    <span className="badge rounded-pill bg-danger ms-1 px-2 py-1 font-size-10">{item?.total_plans}</span>
                </div>
            </div>
        )),
        text: 'MEMBERSHIP PLAN',
        sort: true,
    },
    {
        formatter: (cell, row) => !!row?.beginning_date ? moment(row?.beginning_date).format("DD-MM-YYYY") : '',
        text: 'BEGIN DATE',
        sort: true,
    },
    {
        formatter: (cell, row) => !!row?.end_date ? moment(row?.end_date).format("DD-MM-YYYY") : '',
        text: 'END DATE',
        sort: true,
    },
    {
        formatter: handleBodyCompositionAssigned,
        text: 'BODY COMPOSITION',
        sort: true,
    },
    {
        formatter: handleWorkoutChartAssigned,
        text: 'WORKOUT CHART',
        sort: true,
    },
    {
        formatter: handleDietChartAssigned,
        text: 'DIET CHART',
        sort: true,
    },
    {
        formatter: handleAccessoriesAssigned,
        text: 'GYMBAG SHIPPER',
        sort: true,
    },
    {
        formatter: (cell, row) => row.main_trainers.length > 0 ? row.main_trainers.map((item, index) => (
            <div key={index} style={{ whiteSpace: "normal" }}>
                <div className="bg-primary bg-soft p-2 my-1 text-primary badge">
                    {item?.trianer_name}
                </div>
            </div>
        )) : <div>NA</div>,
        text: 'MAIN TRAINER',
        sort: true,
    },
    // {
    //     formatter: (cell, row) => ((row.user_status == 0 && <span className="badge rounded-pill bg-danger px-2 py-1">Inactive</span>) || (row.user_status == 1 && <span className="badge rounded-pill bg-success px-2 py-1">Active</span>)),
    //     text: 'MEMBER STATUS',
    //     sort: true,
    // },
    {
        dataField: '',
        text: 'ACTION',
        sort: true,
        formatter: handleAction
    }];

    const handleAddNote = async (e, row) => {
        setMemberIdForNote(row.id)
        try {
            setIsGetingNoteData(true)
            const res = await axiosInstance.post(apiUrls.member.listMemberWiseNote, { member_id: row.id });
            if (res.success == 1) {
                setMemberWiseNoteData(res.data)
                setMemberWiseNoteDataLength(res.data.length)
                fetchNotesData(res.data)
                // fetchMemberListData(paginator, searchText, memberStatus, memberMember_Status, memberTiming, mainTrainer, subscriptionPlan)  
            } else {
                setNoteData({ ...initialNoteData })
                setMemberWiseNoteData([])
                setMemberWiseNoteDataLength(0)
                setIsGetingNoteData(false)
                openNoteModel()
            }
        } catch (error) {
            setNoteData({ ...initialNoteData })
            setMemberWiseNoteData([])
            setMemberWiseNoteDataLength(0)
            setIsGetingNoteData(false)
        }
    }

    const fetchNotesData = (data = []) => {
        let notes = []
        for (let item of data) {
            try {
                notes.push({
                    id: item?.id,
                    note: item?.note,
                    date: item?.date,
                    time: item?.time
                })
            } catch (error) {
                notes.push({
                    id: item?.id,
                    note: item?.note,
                    date: item?.date,
                    time: item?.time
                })
            }
        }
        if (notes.length > 0) {
            setNoteData((prev) => {
                return ({ ...prev, notes })
            })
        }
        setIsGetingNoteData(false)
        openNoteModel()
    }

    const handleFetch = async (e, row) => {
        setMemberId(row.id)
        setEditMemberModal(true)
    }

    const handleDelete = (e, row) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (willDelete) => {
                if (willDelete) {
                    try {
                        const response = await axiosInstance.post(apiUrls.member.deleteMember, { id: row.id });
                        if (response.success == 1) {
                            swal("Success!", response.message, "success");
                            fetchMemberListData(paginatorInfo, searchText, memberStatus, memberMember_Status, memberTiming, mainTrainer, subscriptionPlan, userStatus, activityId, startDate, endDate)
                        }
                        else {
                            swal("Oops!", response.message, "error");
                        }
                    } catch (err) {
                        swal("Oops!", "Something went wrong!", "error");
                        setError(err.message)
                    }
                }
            });
    }

    const handleChangeStatus = (e, row) => {
        swal({
            title: "Are you sure?",
            text: "You want to change the Member status!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (willChange) => {
                if (willChange) {
                    try {
                        const response = await axiosInstance.post(apiUrls.member.activeInactiveMember, { id: row.id, status: row.user_status == 0 ? 1 : 0 });
                        if (response.success == 1) {
                            swal("Success!", response.message, "success");
                            fetchMemberListData(paginatorInfo, searchText, memberStatus, memberMember_Status, memberTiming, mainTrainer, subscriptionPlan, userStatus, activityId, startDate, endDate)
                        }
                        else {
                            swal("Oops!", response.message, "error");
                        }
                    } catch (err) {
                        swal("Oops!", "Something went wrong!", "error");
                        setError(err.message)
                    }
                }
            });
    }

    const handleNumber = (e) => {
        if (/^[0-9]+$/g.test(e.target.value) || e.target.value == '') {
            formikObj.setFieldValue(e.target.name, e.target.value)
        }
    }
    const handleGenderChange = (e) => {
        formikObj.setFieldValue(e.target.name, e.target.value)
    }

    const initAutocomplete = () => {

        let autocomplete = new window.google.maps.places.Autocomplete(
            /** @type {!HTMLInputElement} */(document.getElementById("address")),
            { types: [] });

        window.google.maps.event.addListener(autocomplete, 'place_changed', () => {
            let place = autocomplete.getPlace();
            formikObj.setFieldValue("address", place.formatted_address)
            var componentForm = {
                country: 'long_name',
                locality: 'long_name',
                administrative_area_level_1: 'long_name',
                postal_code: 'short_name'
            };
            for (var i = 0; i < place.address_components.length; i++) {
                var addressType = place.address_components[i].types[0];
                if (componentForm[addressType]) {
                    var val = place.address_components[i][componentForm[addressType]];
                    if (addressType == "administrative_area_level_1") {
                        formikObj.setFieldValue("state", val)
                    }
                    if (addressType == "locality") {
                        formikObj.setFieldValue("city", val)
                    }
                    if (addressType == "postal_code") {
                        formikObj.setFieldValue("zip_code", val)
                    }
                    else {
                        formikObj.setFieldValue("zip_code", '')
                    }
                }
            }
        })
    }

    const formikObj = useFormik({
        enableReinitialize: true,

        initialValues: { ...memberData },
        validationSchema: Yup.object({
            member_name: Yup.string().required("Please Enter Member Name"),
            member_age: Yup.number().required("Please Enter Age"),
            mobile_no: Yup.number().required("Please Enter Contact No."),
            email_id: Yup.string().required("Please Enter Email Id"),
        }),
        onSubmit: (values) => {
            if (values.gender == '') {
                setHasGender(false)
            }
            else {
                setHasGender(true)
                if (memberId == null) {
                    addMemberData(values)
                }
                else {
                    // updateMemberData(values)
                }
            }

        }
    });

    const formikNoteObj = useFormik({
        enableReinitialize: true,

        initialValues: { id: memberIdForNote, ...noteData },
        validationSchema: Yup.object({
            notes: Yup.mixed().when((value) => {
                return Yup.array().of(Yup.object({
                    note: Yup.string().required('Please Write Note')
                }))
            })
        }),
        onSubmit: (values) => {
            let notes = []
            values.notes.map((note, index) => {
                if (!!note.id) {
                    if (memberWiseNoteData[index].note != note.note) {
                        notes.push(note)
                    }
                } else {
                    notes.push(note)
                }
            })
            if (notes.length > 0) {
                values = { ...values, notes }
                submitNote(values)
            } else {
                swal("Please", "Write Note a New note or Edit a note Before Submit", "error")
            }
        }
    })

    const submitNote = async (values) => {
        try {
            setIsNoteSaving(true)
            const res = await axiosInstance.post(apiUrls.member.memberWiseNote, values);
            if (res.success == 1) {
                setIsNoteSaving(false)
                closeNoteModel()
                swal("Success!", res.message, "success");
                setMemberIdForNote(null)
            } else {
                setIsNoteSaving(false)
                swal("Oops!", res.message, "error");
            }
        } catch (error) {
            setIsNoteSaving(false)
            swal("Oops!", "Something went wrong!", "error");
            setError(error.message)
        }
    }
    const deleteNote = (field, remove) => {
        if (field.id) {
            swal({
                title: "Are you sure?",
                text: "Once deleted, you will not be able to recover",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then(async (willDelete) => {
                if (willDelete) {
                    try {
                        const res = await axiosInstance.post(apiUrls.member.deleteMemberWiseNote, { id: field.id });
                        if (res.success == 1) {
                            setMemberWiseNoteDataLength(memberWiseNoteDataLength - 1)
                            swal("Success!", res.message, "success");
                            let notes = [...memberWiseNoteData]
                            let note = notes.findIndex((val) => val.id === field.id)
                            notes.splice(note, 1)
                            setMemberWiseNoteData(notes)
                            remove()
                        } else {
                            swal("Oops!", res.message, "error");
                        }
                    } catch (error) {
                        swal("Oops!", "Something went wrong!", "error");
                    }
                }
            });
        }
    }

    const addMemberData = async (values) => {
        try {
            const formData = new FormData();
            for (let [key, value] of Object.entries(values)) {
                // formData.append(key, value)
                !!value ? formData.append(key, value) : formData.append(key, "")
            }

            setIsSaving(true)
            const response = await axiosInstance.post(apiUrls.member.addAndUpdateMember, formData);
            if (response.success == 1) {
                formikObj.resetForm()
                setIsSaving(false)
                closeModal()
                swal("Success!", response.message, "success");
                fetchMemberListData(paginatorInfo, searchText, memberStatus, memberMember_Status, memberTiming, mainTrainer, subscriptionPlan, userStatus, activityId, startDate, endDate)
                setMemberData([])
            }
            else {
                setIsSaving(false)
                swal("Oops!", response.message, "error");
            }
        } catch (err) {
            setIsSaving(false)
            swal("Oops!", "Something went wrong!", "error");
            setError(err.message)
        }
    }

    const handleChangeFile = (e) => {
        imageSrc = URL.createObjectURL(e.target.files[0])
        if (e.target.files[0])
            formikObj.setFieldValue('profile_image', e.target.files[0]);
    }

    const handleIDProofChangeFile = (e) => {
        if (checkFileType(e.target.files[0].name) === FILETYPE.IMAGE) {
            idProofSrc = URL.createObjectURL(e.target.files[0]);
        }
        else if (checkFileType(e.target.files[0].name) === FILETYPE.PDF) {
            idProofSrc = images.pdf;
        }
        else {
            idProofSrc = images.otherFile;
        }
        if (e.target.files[0])
            formikObj.setFieldValue('id_proof', e.target.files[0]);
    }

    const openModal = () => {
        imageSrc = images.defaultAvatar;
        idProofSrc = images.defaultDocument;
        setTimeout(() => {
            setAddEditMemberModal(true);
            initAutocomplete()
        }, 2000);
    }

    const closeModal = () => {
        imageSrc = '';
        idProofSrc = '';
        setAddEditMemberModal(false);
    }

    const openNoteModel = () => {
        setNoteModel(true)
    }

    const closeNoteModel = () => {
        setNoteModel(false)
        formikNoteObj.resetForm()
    }

    const createMember = () => {
        imageSrc = ''
        setMemberId(null)
        setMemberData({ ...initialMemberData });
        formikObj.resetForm();
        openModal()
    }

    const filterMemberListByActivityId = (e) => {
        setActivityId(e.target.value)
        fetchMemberListData(paginatorInfo, searchText, memberStatus, memberMember_Status, memberTiming, mainTrainer, subscriptionPlan, userStatus, e.target.value, startDate, endDate);
    }

    const filterMemberListByDateRange = (e) => {
        let startDate = '';
        let endDate = '';
        if (e[0] && e[1]) {
            startDate = standartDateFormat(e[0]);
            endDate = standartDateFormat(e[1]);
            setStartDate(standartDateFormat(e[0]))
            setEndDate(standartDateFormat(e[1]))
        }
        else if (e.length == '') {
            setStartDate('');
            setEndDate('');
        }
        fetchMemberListData(paginatorInfo, searchText, memberStatus, memberMember_Status, memberTiming, mainTrainer, subscriptionPlan, userStatus, activityId, startDate, endDate);
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Member</title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumbs title="Member" breadcrumbItem="Member" />
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col lg={3}>
                                            <Button
                                                color="primary"
                                                className="font-size-13 btn-block"
                                                onClick={createMember}
                                            >
                                                <i className="mdi mdi-plus-circle-outline me-1 align-middle font-size-16" />
                                                Create
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    {/* Datatable view start */}

                    <Row>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col md={12}>
                                        <div className="d-flex m-t-2 m-b-2 justify-content-center">
                                            {/* justify-content-end flex-sm-column flex-md-row mb-3 flex-wrap */}

                                            <select className="ms-md-2 form-select " onChange={(e) => filterMemberBySubscriptionPlan(e)}>
                                                <option value="">Subscription Plan</option>
                                                {subscriptionPlanList.map((plan, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <option value={plan.id}>{plan.plan_name}</option>
                                                        </React.Fragment>
                                                    )
                                                })
                                                }
                                            </select>

                                            <select className="ms-md-2 form-select  " onChange={(e) => filterMemberByMainTrainer(e)}>
                                                <option value="">Main Trainer</option>
                                                {mainTrainerList.map((trainer, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <option value={trainer.id}>{trainer.name}</option>
                                                        </React.Fragment>
                                                    )
                                                })
                                                }
                                            </select>

                                            <select className="ms-md-2 form-select  " onChange={(e) => filterMemberByTiming(e)}>
                                                <option value="">Timing</option>
                                                <option value="1">Morning</option>
                                                <option value="2">Evening</option>
                                            </select>

                                            <select className="ms-md-2 form-select  " onChange={(e) => filterMemberByStatus(e)}>
                                                <option value="">Membership Status</option>
                                                <option value="1" selected>Ongoing</option>
                                                <option value="0">Expired</option>
                                                <option value="2">New</option>
                                            </select>

                                            <select className="ms-md-2 form-select  " onChange={(e) => filterMemberByMemberStatus(e)}>
                                                <option value="">Regularity</option>
                                                <option value="1">Irregular</option>
                                                <option value="2">Discontinued</option>
                                            </select>
                                            {/* &nbsp;&nbsp;
                                            <button
                                                type="button"
                                                onClick={(e) => resetAllFilter(e)}
                                                className="btn btn-outline-warning"
                                            >
                                                <i className="bx bx-reset"></i>{' '}
                                                Reset All
                                            </button> */}
                                            <select
                                                className="ms-md-2 form-select w-auto me-2 pe-5 mb-3 mb-md-0"
                                                onChange={e => filterMemberListByActivityId(e)}
                                            >
                                                <option value="">Filter by All Activity</option>
                                                {activityList.map((activityName, index) => (
                                                    <option key={index} value={activityName.id}>{activityName.activity}</option>
                                                ))}
                                            </select>
                                            <Flatpickr
                                                className="form-control filter-date-width"
                                                placeholder="Filter by Date"
                                                options={{
                                                    mode: "range",
                                                    dateFormat: "d-m-Y",
                                                }}
                                                onChange={e => filterMemberListByDateRange(e)}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <div className="d-flex justify-content-end mt-3">
                                    <div className="form-check form-check-inline font-size-16">
                                        <Input
                                            type="checkbox"
                                            value={userStatus}
                                            id="customRadioInline"
                                            name="accessories_type_id"
                                            className="form-check-input"
                                            onClick={(e) => filterByUserStatus(e)}
                                        />
                                        <span
                                            className="form-check-label font-size-16"
                                            htmlFor="customRadioInline"
                                        >
                                            Include Inactive Members
                                        </span>
                                    </div>
                                </div>

                                <Row className="mt-2">
                                    <Col lg={12}>
                                        <div className="memberlist-table">
                                            <CommonTable
                                                paginatorInfo={paginatorInfo}
                                                searchText={searchText}
                                                onTableChange={handleTableChange}
                                                keyField='unique_key'
                                                columns={columns}
                                                data={allMemberList}
                                                loading={processingList}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Row>

                    {/* Datatable view end */}

                    {editMemberModal && <EditMember member_id={memberId} />}

                    <Modal
                        isOpen={addEditMemberModal}
                        toggle={closeModal}
                        size="lg"
                        backdrop="static"
                    // className={props.className}
                    >
                        <ModalHeader toggle={closeModal} tag="h4">
                            Create New Member
                        </ModalHeader>
                        <ModalBody>
                            <Form>
                                <Row>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-InputService">Enter Member Name <span className="text-danger">*</span></Label>
                                            <Input
                                                type="text"
                                                id="formrow-InputService"
                                                className="form-control"
                                                name="member_name"
                                                onChange={formikObj.handleChange}
                                                value={formikObj.values.member_name}
                                                placeholder="Enter Member Name"
                                                invalid={
                                                    formikObj.touched.member_name && formikObj.errors.member_name ? true : false
                                                }
                                            />
                                            {formikObj.touched.member_name && formikObj.errors.member_name ? (
                                                <FormFeedback type="invalid">{formikObj.errors.member_name}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-InputService">Enter Contact No. <span className="text-danger">*</span></Label>
                                            <Input
                                                type="text"
                                                id="formrow-InputService"
                                                className="form-control"
                                                name="mobile_no"
                                                onChange={(e) => { handleNumber(e) }}
                                                value={formikObj.values.mobile_no}
                                                placeholder="Enter Contact No."
                                                maxLength={10}
                                                invalid={
                                                    formikObj.touched.mobile_no && formikObj.errors.mobile_no ? true : false
                                                }
                                            />
                                            {formikObj.touched.mobile_no && formikObj.errors.mobile_no ? (
                                                <FormFeedback type="invalid">{formikObj.errors.mobile_no}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-InputService">Enter Email Address <span className="text-danger">*</span></Label>
                                            <Input
                                                type="email"
                                                id="formrow-InputService"
                                                className="form-control"
                                                name="email_id"
                                                onChange={formikObj.handleChange}
                                                value={formikObj.values.email_id}
                                                placeholder="Enter Email Address"
                                                invalid={
                                                    formikObj.touched.email_id && formikObj.errors.email_id ? true : false
                                                }
                                            />
                                            {formikObj.touched.email_id && formikObj.errors.email_id ? (
                                                <FormFeedback type="invalid">{formikObj.errors.email_id}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-InputService">Enter Age <span className="text-danger">*</span></Label>
                                            <Input
                                                type="text"
                                                id="formrow-InputService"
                                                className="form-control"
                                                name="member_age"
                                                onChange={(e) => { handleNumber(e) }}
                                                value={formikObj.values.member_age}
                                                placeholder="Enter Age"
                                                maxLength={2}
                                                invalid={
                                                    formikObj.touched.member_age && formikObj.errors.member_age ? true : false
                                                }
                                            />
                                            {formikObj.touched.member_age && formikObj.errors.member_age ? (
                                                <FormFeedback type="invalid">{formikObj.errors.member_age}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-InputService">Select Gender <span className="text-danger">*</span></Label>
                                            <div className="mb-2">
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="gender"
                                                        id="exampleRadios2"
                                                        value="1"
                                                        onClick={(e) => { handleGenderChange(e) }}
                                                        checked={formikObj.values.gender == 1}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="exampleRadios2"
                                                    >
                                                        Male
                                                    </label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="gender"
                                                        id="exampleRadios2"
                                                        value="2"
                                                        onClick={(e) => { handleGenderChange(e) }}
                                                        checked={formikObj.values.gender == 2}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="exampleRadios2"
                                                    >
                                                        Female
                                                    </label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="gender"
                                                        id="exampleRadios2"
                                                        value="3"
                                                        onClick={(e) => { handleGenderChange(e) }}
                                                        checked={formikObj.values.gender == 3}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="exampleRadios2"
                                                    >
                                                        Transgender
                                                    </label>
                                                </div>
                                            </div>
                                            {hasGender == false && <span className="my-2" style={{ color: "red", fontSize: "10px" }}>Please Select Gender</span>}
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-InputService">Enter Profession</Label>
                                            <Input
                                                type="text"
                                                id="formrow-InputService"
                                                className="form-control"
                                                name="profession"
                                                onChange={formikObj.handleChange}
                                                value={formikObj.values.profession}
                                                placeholder="Enter Profession"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-InputAddress">Profile Image</Label>
                                            <Input
                                                type="file"
                                                className="form-control"
                                                name="profile_image"
                                                onChange={(e) => handleChangeFile(e)}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <span className="logo-lg">
                                                <img src={imageSrc} height={100} width={100} alt="profile" className="avatar-lg rounded-circle img-thumbnail" />
                                                <span className="extra-space"></span>
                                            </span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-InputAddress">ID Proof</Label>
                                            <Input
                                                type="file"
                                                className="form-control"
                                                name="id_proof"
                                                onChange={(e) => handleIDProofChangeFile(e)}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <span className="logo-lg">
                                                <img src={idProofSrc} height={100} width={100} alt="id_proof" className="avatar-lg rounded-circle img-thumbnail" />
                                                <span className="extra-space"></span>
                                            </span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-InputAddress">Address</Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                id="address"
                                                placeholder="Enter Address"
                                                name="address"

                                                // -------- for autocomplete --------
                                                onChange={formikObj.handleChange}
                                                value={formikObj.values.address}
                                                autoComplete="address"
                                                invalid={
                                                    formikObj.touched.address && formikObj.errors.address ? true : false
                                                }
                                            />
                                            {formikObj.touched.address && formikObj.errors.address ? (
                                                <FormFeedback type="invalid">{formikObj.errors.address}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </ModalBody>
                        <div className="modal-footer">
                            {!!isSaving ?
                                <button className="btn btn-primary " disabled>
                                    Processing
                                </button>
                                :
                                <>
                                    <button
                                        type="button"
                                        onClick={closeModal}
                                        className="btn btn-secondary "
                                        data-dismiss="modal"
                                    >
                                        Close
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn btn-primary "
                                        onClick={(e) => {
                                            e.preventDefault();
                                            formikObj.handleSubmit();
                                            return false;
                                        }}
                                    >
                                        Save
                                    </button>
                                </>
                            }
                        </div>
                    </Modal>

                    {/* Note Modal Start */}

                    <Modal
                        isOpen={noteModel}
                        toggle={closeNoteModel}
                        size="lg"
                        backdrop="static"
                    >
                        <ModalHeader toggle={closeNoteModel} tag="h4">Notes</ModalHeader>

                        <ModalBody>
                            <FormikProvider value={formikNoteObj}>
                                <Form>
                                    <FieldArray
                                        name="notes"
                                        render={(arrayHelpers) => (
                                            <>
                                                {formikNoteObj.values.notes.map((field, index) => {
                                                    const isLastRow = ((formikNoteObj.values.notes.length - 1) === index);
                                                    const getFieldName = name => `${arrayHelpers.name}[${index}][${name}]`;
                                                    const getErrorMsg = name => ((formikNoteObj.touched.notes?.[index]?.[name] && formikNoteObj.errors.notes?.[index]?.[name]) ?
                                                        formikNoteObj.errors.notes?.[index]?.[name]
                                                        :
                                                        ""
                                                    );
                                                    return (
                                                        <div key={index}>
                                                            <Row>
                                                                <Col md={10}>
                                                                    <div className="mb-3">
                                                                        <Label htmlFor="formrow-InputNote">
                                                                            Note<span className="text-danger">*</span>
                                                                        </Label>
                                                                        <Input
                                                                            type="textarea"
                                                                            id="formrow-InputNote"
                                                                            className="form-control"
                                                                            name={getFieldName('note')}
                                                                            onChange={(e) => { formikNoteObj.handleChange(e) }}
                                                                            value={field.note}
                                                                            placeholder="Note"
                                                                            invalid={!!getErrorMsg('note')}
                                                                        />
                                                                        {((!!memberWiseNoteData[index]?.date) && (!!memberWiseNoteData[index]?.time)) && <small><i className="bx bx-calendar" />&nbsp;{moment((memberWiseNoteData[index].date)).format("MM-DD-YYYY")}{'  '}{' '}<i className="mdi mdi-clock-outline" />&nbsp;{memberWiseNoteData[index].time}</small>}
                                                                        {getErrorMsg('note') && <FormFeedback type="invalid">{getErrorMsg('note')}</FormFeedback>}
                                                                    </div>
                                                                </Col>
                                                                {(memberWiseNoteDataLength > 0) ?
                                                                    <Col md={2} className="d-flex align-items-center">
                                                                        <div>
                                                                            {formikNoteObj.values.notes.length > 0 &&
                                                                                <>
                                                                                    {(memberWiseNoteDataLength > (index)) ?
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn-rounded  btn btn-danger ms-2 btn-sm"
                                                                                            onClick={() =>
                                                                                                deleteNote(
                                                                                                    field,
                                                                                                    () => {
                                                                                                        arrayHelpers.remove(index)
                                                                                                    }
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <i className="bx bx-trash align-middle" />
                                                                                        </button>
                                                                                        :
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn-rounded  btn btn-danger ms-2 btn-sm"
                                                                                            onClick={() => arrayHelpers.remove(index)}
                                                                                        >
                                                                                            <i className="bx bx-minus align-middle" />
                                                                                        </button>
                                                                                    }
                                                                                </>

                                                                            }
                                                                        </div>
                                                                        <div>
                                                                            {isLastRow &&
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn-rounded  btn btn-success ms-2 btn-sm"
                                                                                    onClick={() => arrayHelpers.push({
                                                                                        note: ""
                                                                                    })}
                                                                                >
                                                                                    <i className="bx bx-plus align-middle" />
                                                                                </button>
                                                                            }
                                                                        </div>
                                                                    </Col>
                                                                    :
                                                                    <Col md={2} className="d-flex align-items-center">
                                                                        <div>
                                                                            {formikNoteObj.values.notes.length > 1 &&
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn-rounded  btn btn-danger ms-2 btn-sm"
                                                                                    onClick={() => arrayHelpers.remove(index)}
                                                                                >
                                                                                    <i className="bx bx-minus align-middle" />
                                                                                </button>
                                                                            }
                                                                        </div>
                                                                        <div>
                                                                            {isLastRow &&
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn-rounded  btn btn-success ms-2 btn-sm"
                                                                                    onClick={() => arrayHelpers.push({
                                                                                        note: ""
                                                                                    })}
                                                                                >
                                                                                    <i className="bx bx-plus align-middle" />
                                                                                </button>
                                                                            }
                                                                        </div>
                                                                    </Col>
                                                                }

                                                            </Row>
                                                        </div>
                                                    )
                                                })}
                                            </>
                                        )}
                                    />
                                </Form>
                            </FormikProvider>
                        </ModalBody>
                        <div className="modal-footer">
                            {!!isNoteSaving ?
                                <button className="btn btn-primary " disabled>
                                    Processing
                                </button>
                                :
                                <>
                                    <button
                                        type="button"
                                        onClick={closeNoteModel}
                                        className="btn btn-secondary "
                                        data-dismiss="modal"
                                    >
                                        Close
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn btn-primary "
                                        onClick={(e) => {
                                            e.preventDefault();
                                            formikNoteObj.handleSubmit();
                                            return false;
                                        }}
                                    >
                                        Save
                                    </button>
                                </>
                            }
                        </div>
                    </Modal>

                    {/* Note Modal End */}

                    {/* membership Modal Start */}

                    <Modal
                        isOpen={membershipModel}
                        toggle={() => setMembershipModel(false)}
                        size="lg"
                        backdrop="static"
                    >
                        <ModalHeader toggle={() => setMembershipModel(false)} tag="h4">Membership Details</ModalHeader>
                        <ModalBody>
                            {membershipPlanList.length > 0 ?
                                <>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th style={{ padding: '10px' }}>Invoice</th>
                                                <th style={{ padding: '10px' }}>Plan Name</th>
                                                <th style={{ padding: '10px' }}>Begin Date</th>
                                                <th style={{ padding: '10px' }}>End Date</th>
                                                <th style={{ padding: '10px' }}>Timing</th>
                                                <th style={{ padding: '10px' }}>Status</th>
                                                <th style={{ padding: '10px' }}>Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {membershipPlanList?.map((item, index) => {
                                                return <tr key={index}>
                                                    <td style={{ padding: '10px' }}>{item?.invoice_num}</td>
                                                    <td style={{ padding: '10px' }}>{item?.plan_name}</td>
                                                    <td style={{ padding: '10px' }}>{moment(item?.beginning_date).format("DD-MM-YYYY")}</td>
                                                    <td style={{ padding: '10px' }}>{moment(item?.end_date).format("DD-MM-YYYY")}</td>
                                                    <td style={{ padding: '10px' }}>{item?.preferred_timing_name}</td>
                                                    <td style={{ padding: '10px' }}>{item?.status == 0 ? <span className="badge rounded-pill bg-danger ms-1 px-2 py-1 font-size-10">Expired</span> : <span className="badge rounded-pill bg-success ms-1 px-2 py-1 font-size-10">Ongoing</span>}</td>
                                                    <td style={{ padding: '10px' }}>{item?.total_amount}</td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </>
                                :
                                <span><center><i>No Results Found</i></center></span>
                            }

                        </ModalBody>
                    </Modal>

                    {/* membership Modal end */}

                </Container>
            </div>
        </React.Fragment >
    );

}

export default withRouter(Member);

Member.propTypes = {
    history: PropTypes.object,
};