import { withRouter } from "react-router-dom"
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useEffect, useState } from "react";
import {
    Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label, Button, Modal,
    ModalBody,
    ModalHeader,
    InputGroup, Table, CardTitle, CardHeader
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { setAssignedMemberModalState } from "../../store/actions";
import { userDateFormat } from "utils/date";

let assignedMembers = null
let arr = [1, 2, 3];

const AssignedMemberModal = props => {
    const { isOpenAssignedMemberModal, data } = props
    const dispatch = useDispatch();
    const [openModal, setOpenModal] = useState(false)

    useEffect(() => {
        setOpenModal(isOpenAssignedMemberModal)
    }, [isOpenAssignedMemberModal])

    const closeModal = () => {
        setOpenModal(false)
        dispatch(setAssignedMemberModalState(false));
    }

    useEffect(() => {
        let memberinfo = {};
        if (!!data.length > 0) {
            data.map((member, index) => {
                if(!!member.assigned_date){
                    let assignedDate = member.assigned_date || null;
                    if (assignedDate && !(assignedDate in memberinfo)) {
                        memberinfo[member.assigned_date] = [member];
                    } else {
                        memberinfo[member.assigned_date].push(member);
                    }
                }
            });
            assignedMembers = [memberinfo]
        }
    }, [data])

    return (
        <React.Fragment>
            <Modal
                isOpen={openModal}
                toggle={closeModal}
                size="lg"
                backdrop="static"
            >
                <ModalHeader toggle={closeModal} tag="h4">
                    Member Details
                </ModalHeader>
                <ModalBody>
                    <div className="p-3 pt-0">
                        <Form>
                            <Row className="border border-primary border-bottom-0 bg-light">
                                <Col col={6} className="d-flex align-items-center p-2 border-primary border-end">
                                    <Label className="mb-0">
                                        <b>Assigned Date</b>{' '}
                                    </Label>
                                </Col>
                                <Col col={6} className="d-flex align-items-center p-2">
                                    <Label className="mb-0">
                                        <b>Member(s)</b>{' '}
                                    </Label>
                                </Col>
                            </Row>

                            {!!assignedMembers && Object.keys(assignedMembers[0]).map(item => {
                                return <>
                                    <Row className="border-primary">
                                        <Col col={6} className="d-flex align-items-center p-2 border border-top-0 border-primary">
                                            {userDateFormat(item)}
                                        </Col>
                                        <Col col={6} className="d-flex align-items-center p-2 border-bottom border-end border-primary">
                                            <Row>
                                                {assignedMembers[0][item].map((member, i) => {
                                                    return (
                                                        <React.Fragment key={i}>
                                                            <Col sm={12}>
                                                                {member.member_name}
                                                            </Col>
                                                        </React.Fragment>
                                                    )
                                                })}
                                            </Row>
                                        </Col>

                                    </Row>
                                </>
                            })}
                        </Form>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
}
export default withRouter(AssignedMemberModal);

AssignedMemberModal.propTypes = {
    history: PropTypes.object,
    isOpenAssignedMemberModal: PropTypes.bool,
    data: PropTypes.array
};