import Breadcrumb from "components/Common/Breadcrumb";
import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { withRouter, Link } from "react-router-dom";
import {
    Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label, Button, Modal,
    ModalBody,
    ModalHeader,
    InputGroup,
    Spinner,
    CardTitle,
    Collapse,
    CardHeader
} from "reactstrap";
import PropTypes from "prop-types";
import { apiUrls, axiosInstance, graphql } from "api";
import usePaginator from "hooks/usePaginator";
import Flatpickr from "react-flatpickr";
import swal from 'sweetalert';
import { standartDateFormat, today, userDateFormat, userTimeFormat } from 'utils/date';
import CommonTable from 'components/Common/CommonTable';
import { FieldArray, useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import * as SubscriptionQry from "GraphQLquery/subscriptionQry";
import SessionUpcomingCompletedModal from "pages/Common/SessionUpcomingCompletedModal";

const initialAcitivityData = {
    id: '',
    activity_id: 2,
    sessions_available: '',
    sessions_completed: '',

    members_id: [],
    assign_date: '',
    timing_from: '',
    timing_to: '',
    note: '',
    member_id: '',
    gender: "",
    batch_id: '',
    alloted_batch: []
}
let members_id = []
let member_id = 0
let member_name = ""
let alloted_batch = []
let plan_pattern_id = ""
const AssignZumbaClasses = (props) => {

    const [acitivityData, setAcitivityData] = useState({ ...initialAcitivityData })
    const [memberwiseActivityList, setMemberwiseActivityList] = useState([])
    const [processingActivityList, setProcessingActivityList] = useState(false);
    const [processingActivitywiseBatchList, setProcessingActivitywiseBatchList] = useState(false)
    const [paginatorInfo, setPaginatorInfo, resetPaginatorInfo] = usePaginator();
    const [memberActivityPaginatorInfo, setMemberActivityPaginatorInfo, resetMemberActivityPaginatorInfo] = usePaginator();
    const [activitywiseBatchPaginatorInfo, setActivitywiseBatchPaginatorInfo, resetActivitywiseBatchPaginatorInfo] = usePaginator();
    const [searchText, setSearchText] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const [isSingleSaving, setIsSingleSaving] = useState(false);
    const [assignSessionModal, setAssignSessionModal] = useState(false)
    const [assignedDate, setAssignedDate] = useState('');
    const [zumbaMemberList, setZumbaMemberList] = useState([])
    const [zumbaMemberListProcessing, setZumbaMemberListProcessing] = useState(false)
    const [processingList, setProcessingList] = useState(false);
    const [isSingleMemberAssign, setIsSingleMemberAssign] = useState(false)
    const [isUpdate, setisUpdate] = useState(false)
    const [allBatchList, setAllBatchList] = useState([]);
    const [activityBatchList, setActivityBatchList] = useState([])
    const [multiMemberSelected, setMultiMemberSelected] = useState(false)
    const [planPatterns, setPlanPatterns] = useState([]);
    const [sessionUpcomingCompletedModal, setSessionUpcomingCompletedModal] = useState(false)
    const [sessionUpcomingCompletedLog, setSessionUpcomingCompletedLog] = useState([])
    const [sessionType, setSessionType] = useState('')

    useEffect(() => {
        // fetchMemberWiseActivitiesDetails(memberActivityPaginatorInfo, searchText)
        fetchMemberListofZumba(paginatorInfo, searchText)
        fetchAllBatchListData()
        fetchPlanPatterns()
        return () => {
            reset()
        }
    }, [])

    const fetchPlanPatterns = async () => {
        try {
            let response = null;
            response = await graphql({
                query: SubscriptionQry.PLAN_PATTERNS_QUERY,
                variables: {}
            });
            let planPatterns = []
            if (response?.data?.plan_patterns?.length > 0) {
                planPatterns = response?.data?.plan_patterns.filter(item => (item.plan_pattern_name == "Monthly" || item.plan_pattern_name == "Custom"))
                setPlanPatterns(planPatterns);
            }
        } catch (err) {
            setPlanPatterns([]);
        }
    }

    const fetchMemberWiseActivitiesDetails = async (memberActivityPaginatorInfo, searchText) => {
        try {
            setProcessingActivityList(true)
            let params = {
                offset: !!memberActivityPaginatorInfo.perPage ? memberActivityPaginatorInfo.perPage : 10,
                search_text: searchText,
                // member_id: member_id,
                activity_id: formikObj.values.activity_id
            }
            const res = await axiosInstance.post(apiUrls.activity.memberWiseActivitiesDetails + "?page=" + memberActivityPaginatorInfo.currentPage, params)
            if (res.success == 1) {
                let data = res?.data?.data
                setMemberwiseActivityList(data)
                let memberActivityPaginatorInfo = {
                    currentPage: parseInt(res?.data?.current_page),
                    perPage: parseInt(res?.data?.per_page),
                    total: parseInt(res?.data?.total),
                }
                setMemberActivityPaginatorInfo(memberActivityPaginatorInfo);
                setSearchText(searchText);
                setProcessingActivityList(false)
            } else {
                setProcessingActivityList(false)
                resetMemberActivityPaginatorInfo()
            }
        } catch (error) {
            setProcessingActivityList(false)
            resetMemberActivityPaginatorInfo()
        }
    }

    const fetchAllBatchListData = async () => {

        try {
            let param = {
                is_upcoming: 1,
                activity_id: formikObj.values.activity_id
            }
            const res = await axiosInstance.post(apiUrls.batch.batchList, param);

            if (res.success == 1) {
                setAllBatchList(!!res.data ? res?.data : [])
            }

            else {
                setAllBatchList([])
            }
        } catch (error) {
            setAllBatchList([])
        }
    }

    const formikObj = useFormik({
        enableReinitialize: true,

        initialValues: { ...acitivityData },
        validationSchema: Yup.object({
            batch_id: member_id == 0 && Yup.string().required("Please Select Batch")
        }),
        onSubmit: async (values) => {
            member_id == 0 ? assignBatchMultipleMember(values) : assignBatchSingleMember(values)
        }
    });

    const assignBatchMultipleMember = async (values) => {
        try {
            setIsSaving(true)
            let param = {
                batch_id: values.batch_id,
                members_id: values.members_id
            }
            const res = await axiosInstance.post(apiUrls.activity.allotMemberInFullBatch, param);
            if (res.success == 1) {
                formikObj.resetForm()
                setIsSaving(false)
                swal("Success!", res.message, "success");
                // closeModal()
                reset()
            } else {
                setIsSaving(false)
                swal("Oops!", res.message, "error");
            }
        } catch (error) {
            setIsSaving(false)
            swal("Oops!", "Something went wrong!", "error");
        }
    }

    const assignBatchSingleMember = async (values) => {
        try {
            setIsSingleSaving(true)
            let param = {
                alloted_batch: values.alloted_batch
            }
            const res = await axiosInstance.post(apiUrls.activity.allotMemberInBatch, param);
            if (res.success == 1) {
                formikObj.resetForm()
                setIsSingleSaving(false)
                // alloted_batch = []
                swal("Success!", res.message, "success");
                // setIsSingleMemberAssign(false)

                closeModal()
            } else {
                setIsSingleSaving(false)
                swal("Oops!", res.message, "error");
            }
        } catch (error) {
            setIsSingleSaving(false)
            swal("Oops!", "Something went wrong!", "error");
        }
    }

    const handleBatchDate = (cell, row) => {
        if (row?.alloted_status == 1) {
            return <>
                <div>{userDateFormat(row?.start_date)}</div>
                <div>{userDateFormat(row?.end_date)}</div>
            </>
        } else {
            return <div>
                {userDateFormat(row?.batch_date || row?.assign_date)}
            </div>
        }
    }

    const handleAction = (cell, row) => {
        return (
            <>
                {/* <Button className="btn-info btn-sm" onClick={(e) => { handleFetch(e, row) }}>
                    <i className="bx bx-pencil align-middle" />
                </Button>
                &nbsp; */}
                <Button className="btn btn-danger btn-sm ms-2" onClick={(e) => { handleDelete(e, row) }}>
                    <i className="bx bx-trash align-middle" />
                </Button>
            </>
        )
    }

    const handleDelete = (e, row) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (willDelete) => {
                if (willDelete) {
                    try {
                        let param = {
                            batch_id: row.id,
                            member_id: row.member_id
                        }
                        const response = await axiosInstance.post(apiUrls.batch.dropMemberFromBatch, param);
                        if (response.success == 1) {
                            swal("Success!", response.message, "success");
                            fetchMemberWiseActivitiesDetails(memberActivityPaginatorInfo, searchText)
                        }
                        else {
                            swal("Oops!", response.message, "error");
                        }
                    } catch (err) {
                        swal("Oops!", "Something went wrong!", "error");
                    }
                }
            });
    }

    const activityColumns = [
        {
            formatter: (cell, row) => <Link to={{
                pathname: "/member-details",
                state: { member_id: row.member_id, tabType: "8", activity: formikObj.values.activity_id }
            }}>{row.id}</Link>,
            dataField: 'member_id',
            text: 'MEMBER ID',
            sort: true,
        },
        {
            dataField: "member_name",
            text: 'MEMBER NAME',
            sort: true,
        },
        {
            formatter: handleBatchDate,
            text: 'BATCH DATE',
            sort: true,
        },
        {
            formatter: (cell, row) => row?.alloted_status == 1 ? "" : userTimeFormat(row?.timing_from),
            text: 'TIMING FROM',
            sort: true,
        },
        {
            formatter: (cell, row) => row?.alloted_status == 1 ? "" : userTimeFormat(row?.timing_to),
            text: 'TIMING TO',
            sort: true,
        },
        {
            formatter: (cell, row) => row.alloted_status == 1 ? <span className="badge rounded-pill bg-success px-2 py-1">Full</span> : <span className="badge rounded-pill bg-warning float-start">Partial</span>,
            text: 'ALLOTMENT TYPE',
            sort: true,
        },
        {
            formatter: (cell, row) => row.batch_status == 1 ? <span className="badge rounded-pill bg-success px-2 py-1">Upcoming</span> : <span className="badge rounded-pill bg-warning float-start">Completed</span>,
            text: 'BATCH STATUS',
            sort: true,
        },
        {
            formatter: handleAction,
            text: 'ACTION'
        }
    ]

    const handleActivityAssign = (cell, row) => {
        member_name = ""
        return (
            <>
                <Button className="btn-info btn-sm"
                    onClick={(e) => {
                        member_id = row.member_id
                        member_name = row.member_name
                        setMultiMemberSelected(false)
                        fetchActivitywiseBatchList(activitywiseBatchPaginatorInfo, searchText)
                    }}
                    title="Assign"
                >
                    <i className="bx bx-user-check" />
                </Button>
            </>
        )
    }

    const fetchActivitywiseBatchList = async (activitywiseBatchPaginatorInfo, searchText) => {
        openModal()
        setProcessingActivitywiseBatchList(true)
        setIsSingleMemberAssign(true)
        try {
            let params = {
                offset: !!activitywiseBatchPaginatorInfo.perPage ? activitywiseBatchPaginatorInfo.perPage : 10,
                search_text: searchText,
                activity_id: formikObj.values.activity_id,
                member_id: member_id
            }
            const res = await axiosInstance.post(apiUrls.activity.ActivityWiseBatchList + "?page=" + (!!activitywiseBatchPaginatorInfo.currentPage ? activitywiseBatchPaginatorInfo.currentPage : 1), params)

            if (res.success == 1) {

                setProcessingActivitywiseBatchList(false)
                setActivityBatchList(!!res?.data?.data ? res?.data?.data : [])
                let activitywiseBatchPaginatorInfo = {
                    currentPage: parseInt(res?.data?.current_page),
                    perPage: parseInt(res?.data?.per_page),
                    total: parseInt(res?.data?.total),
                }
                setActivitywiseBatchPaginatorInfo(activitywiseBatchPaginatorInfo);
                setSearchText(searchText);
            }

            else {
                setProcessingActivitywiseBatchList(false)
                setActivityBatchList([])
                resetActivitywiseBatchPaginatorInfo();
            }
        } catch (error) {
            setProcessingActivitywiseBatchList(false)
            setActivityBatchList([])
            resetActivitywiseBatchPaginatorInfo();
        }

    }

    const activityBatchColumns = [
        {
            dataField: 'batch_id',
            text: 'BATCH ID',
            sort: true,
        },
        {
            dataField: "activity_name",
            text: 'ACTIVITY NAME',
            sort: true,
        },
        {
            dataField: 'day_name',
            text: 'DAY NAME',
            sort: true,
        },
        {
            formatter: (cell, row) => userDateFormat(row?.date),
            text: 'DATE',
            sort: true,
        },
        {
            dataField: "timing_from",
            text: 'TIMING FROM',
            sort: true,
        },
        {
            dataField: "timing_to",
            text: 'TIMING TO',
            sort: true,
        },
        {
            dataField: 'location',
            text: 'LOCATION',
            sort: true,
        }
    ];

    const columns = [
        {
            formatter: (cell, row) => <Link to={{
                pathname: "/member-details",
                state: { member_id: row.member_id, tabType: "8", activity: formikObj.values.activity_id }
            }}>{row.member_id}</Link>,
            text: 'MEMBER ID',
            sort: true,
        },
        {
            dataField: "member_name",
            text: 'MEMBER NAME',
            sort: true,
        },
        {
            dataField: "mobile_no",
            text: 'CONTACT NO.',
            sort: true,
        },
        {
            formatter: (cell, row) => !!row.subscription_details ?
                <>
                    {
                        row?.subscription_details.map((item, index) => (
                            <div key={index} style={{ whiteSpace: "normal" }}>
                                <div className="bg-primary bg-soft p-2 my-1 text-primary badge">
                                    {item?.plan_name + " (" + item?.plan_pattern_name + ")"}
                                </div>
                            </div>
                        ))
                    }
                </>
                :
                ''
            ,
            text: 'PACKAGE',
            sort: true,
        },
        {
            formatter: (cell, row) => <div className="bg-primary bg-soft p-2 my-1 text-primary badge">
                <span style={{ fontWeight: "bold", color: "#00008B" }}>{" " + row?.preferred_timing_name + ""}</span>
            </div>,
            text: 'TIMING',
            sort: true,
        },
        {
            dataField: "available_session",
            text: 'AVAILABLE',
            sort: true,
        },
        {
            // dataField: "complete_session",
            formatter: (cell, row) => <div className="text-center cursor-pointer"><span className="badge rounded-pill bg-success px-2 py-1" onClick={(e) => {
                setSessionUpcomingCompletedModal(true);
                setSessionUpcomingCompletedLog(row?.completed_whole_session_logs_data);
                setSessionType(1)
            }}>{row.complete_session}</span></div>,
            text: 'COMPLETED',
            sort: true,
        },
        {
            // dataField: 'upcoming_session_tocount',
            formatter: (cell, row) => <div className="text-center cursor-pointer"><span className="badge rounded-pill bg-success px-2 py-1" onClick={(e) => {
                setSessionUpcomingCompletedModal(true);
                setSessionUpcomingCompletedLog(row?.upcoming_whole_session_logs_data);
                setSessionType(2)
            }}>{row.upcoming_session_tocount}</span></div>,
            text: 'UPCOMING',
            sort: true,
        },
        {
            formatter: handleActivityAssign,
            text: 'ACTION'
        }
    ];


    const handleMemberAcitivityTableChange = (memberActivityPaginatorInfo, searchText) => {
        fetchMemberWiseActivitiesDetails(memberActivityPaginatorInfo, searchText)
    }

    const fetchMemberList = () => {
        openModal()
        fetchMemberListofZumba(paginatorInfo, searchText)
    }
    const fetchMemberListofZumba = async (paginatorInfo, searchText) => {
        setZumbaMemberListProcessing(true)
        try {
            let params = {
                activity_id: formikObj.values.activity_id,
                plan_pattern_id: plan_pattern_id,
                offset: !!paginatorInfo.perPage ? paginatorInfo.perPage : 10,
                search_text: searchText
            }
            const res = await axiosInstance.post(apiUrls.activity.memberListOfActivities + "?page=" + paginatorInfo.currentPage, params);
            if (res.success == 1) {
                let paginatorInfo = {
                    currentPage: parseInt(res?.data?.current_page),
                    perPage: parseInt(res?.data?.per_page),
                    total: parseInt(res?.data?.total),
                }
                setZumbaMemberList(res?.data?.data)
                setPaginatorInfo(paginatorInfo);
                setSearchText(searchText);
                setZumbaMemberListProcessing(false)
            }
            else {
                resetPaginatorInfo()
                setZumbaMemberList([])
                setZumbaMemberListProcessing(false)
            }
        } catch (err) {
            resetPaginatorInfo()
            setZumbaMemberList([])
            setZumbaMemberListProcessing(false)
        }
    }

    const handleTableChange = (paginatorInfo, searchText) => {
        fetchMemberListofZumba(paginatorInfo, searchText)
    }

    const handleActivityBatchTableChange = (activitywiseBatchPaginatorInfo, searchText) => {
        fetchActivitywiseBatchList(activitywiseBatchPaginatorInfo, searchText)
    }

    const openModal = () => {
        setAssignSessionModal(true)
    }

    const closeModal = () => {
        members_id = []
        setAssignSessionModal(false)
        setIsSingleMemberAssign(false)
        // fetchMemberWiseActivitiesDetails(memberActivityPaginatorInfo, searchText)
        fetchMemberListofZumba(paginatorInfo, searchText)
        reset()
    }

    const handleMultiMemberSelect = (row, isSelected) => {
        if (isSelected) {
            members_id.push(row?.member_id)
        }
        else {
            let index = members_id.indexOf(row?.member_id)
            members_id.splice(index, 1)
        }
        formikObj.setFieldValue("members_id", members_id)
        members_id.length > 0 ? setMultiMemberSelected(true) : setMultiMemberSelected(false)
    }

    const handleMultiBatchSelect = (row, isSelected) => {
        if (isSelected) {
            alloted_batch.push({
                unique_id: row.unique_id,
                batch_id: row.batch_id,
                member_id: member_id,
                batch_date: row.date,
                day_id: row.day_id
            })
        }
        else {
            alloted_batch = alloted_batch.filter(item => item.unique_id != row.unique_id)
        }

        formikObj.setFieldValue("alloted_batch", alloted_batch)
    }

    // level 1
    const selectRow = {
        mode: 'checkbox',
        // clickToSelect: true,
        onSelect: handleMultiMemberSelect
    }

    // level 2
    const activityBatchSelectRow = {
        mode: 'checkbox',
        // clickToSelect: true,
        onSelect: handleMultiBatchSelect
    }

    useEffect(() => {
        fetchMemberListofZumba(paginatorInfo, searchText)
    }, [plan_pattern_id])

    const reset = (e) => {
        member_id = 0
        plan_pattern_id = ""
        members_id = []
        member_name == ""
        alloted_batch = []
        setIsSingleMemberAssign(false)
        setMultiMemberSelected(false)
        fetchMemberListofZumba(paginatorInfo, searchText)
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Zumba Classes</title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumb title="Activities" breadcrumbItem="Zumba Classes" />
                    {(!!multiMemberSelected) &&
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        {/* <div className=" d-flex justify-content-end"> */}
                                        {/* <Button
                                                    color="primary"
                                                    className="btn-block"
                                                    onClick={fetchMemberList}
                                                >
                                                    <i className="mdi mdi-plus-circle-outline align-middle me-1" />
                                                    Assign111
                                                </Button> */}
                                        {/* </div> */}

                                        <Form>
                                            <div className="row">
                                                <Label className="col-sm-2 col-form-label">
                                                    {(!!multiMemberSelected) &&
                                                        <>Select Batch <span className="text-danger">*</span></>
                                                    }
                                                </Label>
                                                <Col sm={6}>
                                                    {(!!multiMemberSelected) &&
                                                        <>
                                                            <Input
                                                                type="select"
                                                                id="formrow-serviceType"
                                                                className="form-control"
                                                                name="batch_id"
                                                                onChange={formikObj.handleChange}
                                                                value={formikObj.values.batch_id}
                                                                invalid={
                                                                    formikObj.touched.batch_id && formikObj.errors.batch_id ? true : false
                                                                }
                                                            >
                                                                <option value="">Select</option>
                                                                {allBatchList.map((item) => (
                                                                    <option value={item.id} key={item.id}>
                                                                        {item?.id}{' - '}{item.activity}{" ("}{userDateFormat(item?.start_date)}{" TO "}{userDateFormat(item?.end_date)}{")"}
                                                                    </option>
                                                                ))}

                                                            </Input>
                                                            {formikObj.touched.batch_id && formikObj.errors.batch_id ? (
                                                                <FormFeedback type="invalid">{formikObj.errors.batch_id}</FormFeedback>
                                                            ) : null}
                                                        </>
                                                    }
                                                </Col>
                                                <Col sm={4}>
                                                    <div className="d-flex justify-content-end">
                                                        {!!isSaving ?
                                                            <Button className="btn btn-primary " disabled>
                                                                Processing
                                                            </Button>
                                                            :
                                                            <>
                                                                {/* {(!!isSingleMemberAssign) &&
                                                                    <Button
                                                                        onClick={(e) => {
                                                                            reset(e)
                                                                        }}
                                                                        className="btn btn-secondary m-r-3"
                                                                    >
                                                                        Back
                                                                    </Button>
                                                                } */}
                                                                {(!!multiMemberSelected) &&
                                                                    <Button
                                                                        color="primary"
                                                                        className="btn-block"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            formikObj.handleSubmit();
                                                                            return false;
                                                                        }}
                                                                    >
                                                                        <i className="mdi mdi-plus-circle-outline align-middle me-1" />
                                                                        Assign In Batch
                                                                        {/* {!!multiMemberSelected && "Assign In Batch"} */}
                                                                        {/* {!!isSingleMemberAssign && "Assign In Class"} */}
                                                                    </Button>
                                                                }
                                                            </>
                                                        }
                                                    </div>
                                                </Col>
                                            </div>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    }
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <Row className="mb-4">
                                        <Col lg={10}>
                                            <h3> All Members List</h3>
                                        </Col>
                                        <Col lg={2}>
                                            {/* {!(!!isSingleMemberAssign) && */}
                                            <>
                                                <Input
                                                    type="select"
                                                    id="formrow-serviceType"
                                                    className="form-control"
                                                    onChange={(e) => { plan_pattern_id = e.target.value }}
                                                >
                                                    <option value="">All Package</option>
                                                    {planPatterns.map((item) => (
                                                        <option value={item.id} key={item.id}>
                                                            {item.plan_pattern_name}
                                                        </option>
                                                    ))}
                                                </Input>
                                            </>
                                            {/* } */}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                            {/* <CommonTable
                                                paginatorInfo={memberActivityPaginatorInfo}
                                                searchText={searchText}
                                                onTableChange={handleMemberAcitivityTableChange}
                                                keyField='id'
                                                columns={activityColumns}
                                                data={memberwiseActivityList}
                                                loading={processingActivityList}
                                            /> */}

                                            {/* {!!isSingleMemberAssign ?
                                                <CommonTable
                                                    paginatorInfo={activitywiseBatchPaginatorInfo}
                                                    searchText={searchText}
                                                    onTableChange={handleActivityBatchTableChange}
                                                    keyField='unique_id'
                                                    columns={activityBatchColumns}
                                                    selectRow={activityBatchSelectRow}
                                                    data={activityBatchList}
                                                    loading={processingActivitywiseBatchList}
                                                />
                                                : */}
                                            <CommonTable
                                                paginatorInfo={paginatorInfo}
                                                searchText={searchText}
                                                onTableChange={handleTableChange}
                                                keyField='member_id'
                                                columns={columns}
                                                selectRow={selectRow}
                                                data={zumbaMemberList}
                                                loading={zumbaMemberListProcessing}
                                            />
                                            {/* } */}
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Modal
                isOpen={assignSessionModal}
                toggle={closeModal}
                size="lg"
                backdrop="static"
            >
                <ModalHeader toggle={closeModal} tag="h4">
                    {/* Assign Zumba Class */}
                    {member_name} <span className="badge rounded-pill" style={{ backgroundColor: "#DC2450", fontSize: "initial" }}>ID:{member_id}</span>
                </ModalHeader>
                <ModalBody>
                    {(!!isSingleMemberAssign) &&
                        <Form>
                            <div className="row">
                                <Col sm={12}>
                                    <div className=" d-flex justify-content-end">
                                        {!!isSingleSaving ?
                                            <Button className="btn btn-primary " disabled>
                                                Processing
                                            </Button>
                                            :
                                            <Button
                                                color="primary"
                                                className="btn-block"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    formikObj.handleSubmit();
                                                    return false;
                                                }}
                                                style={{ display: !!isSingleMemberAssign ? alloted_batch.length > 0 ? 'block' : 'none' : 'block' }}
                                            >
                                                <i className="mdi mdi-plus-circle-outline align-middle me-1" />
                                                Assign In Class
                                            </Button>
                                        }
                                    </div>
                                </Col>
                            </div>
                        </Form>
                    }
                    <Row>
                        <Col lg={12}>
                            {!!isSingleMemberAssign &&
                                <CommonTable
                                    paginatorInfo={activitywiseBatchPaginatorInfo}
                                    searchText={searchText}
                                    onTableChange={handleActivityBatchTableChange}
                                    keyField='unique_id'
                                    columns={activityBatchColumns}
                                    selectRow={activityBatchSelectRow}
                                    data={activityBatchList}
                                    loading={processingActivitywiseBatchList}
                                />
                            }
                        </Col>
                    </Row>
                </ModalBody>
                {/* <div className="modal-footer d-flex">
                    {(!!isSingleMemberAssign) ?
                        <button
                            type="button"
                            onClick={(e) => {
                                member_id = 0
                                setIsSingleMemberAssign(false)
                            }}
                            className="btn btn-secondary "
                            data-dismiss="modal"
                        >
                            Back
                        </button>
                        :
                        <button
                            type="button"
                            onClick={closeModal}
                            className="btn btn-secondary "
                            data-dismiss="modal"
                        >
                            Close
                        </button>
                    }
                </div> */}
            </Modal>

            <SessionUpcomingCompletedModal
                isOpenModal={sessionUpcomingCompletedModal}
                onCloseClick={() => setSessionUpcomingCompletedModal(false)}
                activityName={'Zumba Class'}
                activityId={formikObj.values.activity_id}
                sessionLog={sessionUpcomingCompletedLog}
                sessionType={sessionType}
            />

        </React.Fragment>
    );
}


export default withRouter(AssignZumbaClasses);