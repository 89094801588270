import { assetProfileImg } from "utils/asset";
import { PROFILE_LOADING, SET_PROFILE_DATA } from "./actionTypes";

const initialState = {
  loading: true,
  data: {
    name: "",
    email: "",
    salutation: "",
    primary_contact_no: "",
    secondary_contact_no: "",
    address: "",
    city: "",
    state: "",
    zip_code: "",
    profile_image: null,
    sideBarMenus:[],

  }
};

const profile = (state = initialState, action) => {
  switch (action.type) {
    case PROFILE_LOADING:
      state = {
        ...state,
        loading: action.loading
      }
      break;
    case SET_PROFILE_DATA:
      state = {
        ...state,
        data: {
          ...state.data,
          ...action.user,
          profile_image: assetProfileImg(action.user?.profile_image)
        },
        loading: false
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default profile;
