import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useEffect, useState } from "react";
import {
    Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label, Button, Modal,
    ModalBody,
    ModalHeader,
    InputGroup
} from "reactstrap";
import { withRouter, Link } from "react-router-dom";

// Formik formikObj
import * as Yup from "yup";
import { FieldArray, FormikProvider, useFormik } from "formik";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { standartDateFormat } from "utils/date";
import { axiosInstance, apiUrls } from "api";
import swal from 'sweetalert';
import { graphql } from "api";

// datatable related plugins

import CommonTable from "components/Common/CommonTable";
import usePaginator from "hooks/usePaginator";
import * as SubscriptionQry from "GraphQLquery/subscriptionQry";

const initialSubscriptionData = {
    plan_name: "",
    plan_pattern_id: "",
    plan_type_id: "",
    total_amount: "",
    status: 1,
    accessories_type_id: [],
    activities_data: [{
        activity_id: "",
        activity_type_id: "",
        no_of_sessions: "",
        is_primary: 0
    }]
}
let accessoryArr = [];
let activities_data = []
const Subscription = props => {

    const [subscriptionData, setSubscriptionData] = useState({ ...initialSubscriptionData })
    const [subscriptionId, setSubscriptionId] = useState(null)
    const [isSaving, setIsSaving] = useState(false);
    const [addEditSubscriptionModal, setAddEditSubscriptionModal] = useState(false);
    const [error, setError] = useState(null);
    const [processingList, setProcessingList] = useState(false);
    const [allSubscriptionList, setAllSubscriptionList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [planPatterns, setPlanPatterns] = useState([]);
    const [planTypes, setPlanTypes] = useState([]);
    const [selectPlanType, setSelectPlanType] = useState(true)
    const [paginatorInfo, setPaginatorInfo, resetPaginatorInfo] = usePaginator();
    const [searchText, setSearchText] = useState('');
    const [accessories, setAccessories] = useState([]);

    const [activityList, setActivityList] = useState([])
    const [activityTypes, setActivityTypes] = useState([]);
    const [activityError, setActivityError] = useState(false);

    useEffect(() => {
        fetchSubscriptionListData(paginatorInfo, searchText)
        fetchPlanPatterns();
        fetchPlantypes();
        fetchAccessories();
        fetchActivityTypes();
        fetchActivities();
    }, [])

    const fetchActivityTypes = async () => {
        try {
            const res = await graphql({
                query: SubscriptionQry.ACTIVITY_TYPES_QUERY
            });
            if (res.data.activity_types) {
                let activity_types = res?.data?.activity_types || []
                setActivityTypes(activity_types)
            } else {
                setActivityTypes([])
            }
        } catch (error) {
            setActivityTypes([])
        }
    }

    const fetchActivities = async () => {
        try {
            const res = await graphql({
                query: SubscriptionQry.ACTIVITIES_LIST_QUERY
            });
            if (res.data.activities) {
                let activities = res?.data?.activities || []
                setActivityList(activities)
            } else {
                setActivityList([])
            }
        } catch (error) {
            setActivityList([])
        }
    }
    const fetchSubscriptionListData = async (paginatorInfo, searchText) => {
        try {
            setProcessingList(true)
            const res = await graphql({
                query: SubscriptionQry.FETCH_ALL_SUBSCRIPTIONS_QUERY,
                variables: {
                    ...paginatorInfo,
                    searchText: `%${searchText}%`,
                    id: "%%"
                }
            });

            if (res.data.subscription_plans.data) {
                setProcessingList(false)
                setAllSubscriptionList(!!res.data.subscription_plans ? res.data.subscription_plans.data : [])
                setPaginatorInfo(res?.data?.subscription_plans?.paginatorInfo);
            }

            else {
                setProcessingList(false)
                setAllSubscriptionList([])
                resetPaginatorInfo();
            }
        } catch (error) {
            setProcessingList(false)
            setAllSubscriptionList([])
            resetPaginatorInfo();
        }
    }

    const handleTableChange = (paginatorInfo, searchText) => {
        fetchSubscriptionListData(paginatorInfo, searchText)
    }

    const fetchPlanPatterns = async () => {
        try {
            let response = null;
            response = await graphql({
                query: SubscriptionQry.PLAN_PATTERNS_QUERY,
                variables: {}
            });
            let planPatterns = []
            planPatterns = response?.data?.plan_patterns || [];
            setPlanPatterns(planPatterns);
        } catch (err) {
            setPlanPatterns([]);
        }
    }

    const fetchPlantypes = async () => {
        try {
            let response = null;
            response = await graphql({
                query: SubscriptionQry.PLAN_TYPES_QUERY,
                variables: {}
            });
            let planTypes = []
            planTypes = response?.data?.plan_types || [];
            setPlanTypes(planTypes);
        } catch (err) {
            setPlanTypes([]);
        }
    }

    const fetchAccessories = async () => {
        try {
            let response = null;
            response = await graphql({
                query: SubscriptionQry.PLAN_ACCESSORIES_QUERY,
                variables: {}
            });
            let accessories = []
            accessories = response?.data?.accessories || [];
            setAccessories(accessories);
        } catch (err) {
            setAccessories([]);
        }
    }

    const handleAction = (cell, row) => {
        return (
            <>
                <div className="d-flex">
                    <Button className="btn btn-info btn-sm ms-2" onClick={(e) => {
                        setSubscriptionId(row.id)
                        handleFetch(e, row.id)
                    }}>
                        <i className="bx bx-pencil align-middle" />
                    </Button>
                    <Button className="btn btn-danger btn-sm ms-2" onClick={(e) => { handleDelete(e, row) }}>
                        <i className="bx bx-trash align-middle" />
                    </Button>
                    <Button className="btn btn-secondary btn-sm ms-2" onClick={(e) => { handleChangeStatus(e, row) }}>
                        {row.status == 0 ? "Active" : "Inactive"}
                    </Button>
                </div>
            </>
        );
    };

    // Column definition for datatable
    const columns = [{
        dataField: 'plan_name',
        text: 'PLAN NAME',
        sort: true,
    },
    {
        dataField: 'plan_pattern[plan_pattern_name]',
        text: 'PLAN PATTERN',
        sort: true,
    },
    {
        dataField: 'plan_type[plan_type_name]',
        text: 'PLAN TYPE',
        sort: true,
    },
    {
        dataField: 'total_amount',
        text: 'PRICE',
        sort: true,
    },
    {
        formatter: (cell, row) => (row.status == 1 ? <span className="badge rounded-pill bg-success px-2 py-1">Active</span> : <span className="badge rounded-pill bg-danger px-2 py-1">Inactive</span>),
        text: 'STATUS',
        sort: true,
    },
    {
        dataField: '',
        text: 'ACTION',
        sort: true,
        formatter: handleAction
    }];


    const handleFetch = async (e, id) => {
        // setSubscriptionId(row.id)
        const res = await graphql({
            query: SubscriptionQry.FETCH_EDIT_SUBSCRIPTIONS_QUERY,
            variables: {
                id: id
            }
        });
        let responseData = !!res.data.subscription_plans ? res.data.subscription_plans.data[0] : []

        let subscription_plan_accessories = responseData?.subscription_plan_accessories?.map(item => item.accessories_id)
        accessoryArr = subscription_plan_accessories

        if (responseData?.subscription_plan_activities?.length > 0) {
            responseData?.subscription_plan_activities?.forEach(element => {
                activities_data.push({
                    id: element.id,
                    activity_id: element?.activity_details?.id,
                    activity_type_id: element?.activity_type_details?.id,
                    no_of_sessions: element?.number_of_session,
                    is_primary: element?.is_primary
                })
            });
        }
        else {
            activities_data.push({
                activity_id: "",
                activity_type_id: "",
                no_of_sessions: "",
                is_primary: 0
            })
        }

        const updatedServiceData = {
            id: responseData.id,
            plan_name: responseData.plan_name,
            total_amount: responseData.total_amount,
            plan_pattern_id: responseData.plan_pattern.id,
            plan_type_id: responseData.plan_type.id,
            status: responseData.status,
            accessories_type_id: subscription_plan_accessories,
            activities_data: activities_data
        }
        setSubscriptionData(updatedServiceData)
        openModal()
    }

    const handleDelete = (e, row) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (willDelete) => {
                if (willDelete) {
                    try {
                        const response = await axiosInstance.post(apiUrls.subscription.deleteSubscriptionPlan, { id: row.id });
                        if (response.success == 1) {
                            swal("Success!", response.message, "success");
                            fetchSubscriptionListData(paginatorInfo, searchText)
                        }
                        else {
                            swal("Oops!", response.message, "error");
                        }
                    } catch (err) {
                        swal("Oops!", "Something went wrong!", "error");
                        setError(err.message)
                    }
                }
            });
    }

    const handleActivityDelete = (e, id) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (willDelete) => {
                if (willDelete) {
                    try {
                        const response = await axiosInstance.post(apiUrls.subscription.deleteSubscriptionActivities, { id: id });
                        if (response.success == 1) {
                            swal("Success!", response.message, "success");
                            // fetchSubscriptionListData(paginatorInfo, searchText)
                            activities_data = []
                            handleFetch('', subscriptionId)
                        }
                        else {
                            swal("Oops!", response.message, "error");
                        }
                    } catch (err) {
                        swal("Oops!", "Something went wrong!", "error");
                        setError(err.message)
                    }
                }
            });
    }

    const handleChangeStatus = (e, row) => {
        swal({
            title: "Are you sure?",
            text: "You want to change the Subscription status!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (willChange) => {
                if (willChange) {
                    try {
                        const response = await axiosInstance.post(apiUrls.subscription.activeInactiveSubscriptionPlan, { id: row.id, status: row.status == 0 ? 1 : 0 });
                        if (response.success == 1) {
                            swal("Success!", response.message, "success");
                            fetchSubscriptionListData(paginatorInfo, searchText)
                        }
                        else {
                            swal("Oops!", response.message, "error");
                        }
                    } catch (err) {
                        swal("Oops!", "Something went wrong!", "error");
                        setError(err.message)
                    }
                }
            });
    }

    const handlePriceChange = (e) => {
        //Regex to accept only decimal number ==> /^\d*\.?\d*$/ 
        //Regex to accept only number ==> /^[0-9]+$/
        if (/^\d*\.?\d*$/g.test(e.target.value) || e.target.value == '') {
            formikObj.setFieldValue(e.target.name, e.target.value)
        }
    }
    const handlePlanTypeChange = (e) => {
        formikObj.setFieldValue(e.target.name, e.target.value)
    }

    const handleAccessoryChange = (e) => {
        let value = parseInt(e.target.value)
        if (e.target.checked) {
            let index = accessoryArr.indexOf(value)
            accessoryArr.splice(index, 1)
        }
        else {
            accessoryArr.push(value);
        }
        formikObj.setFieldValue(e.target.name, accessoryArr)
    }

    const formikObj = useFormik({
        enableReinitialize: true,

        initialValues: { ...subscriptionData },
        validationSchema: Yup.object({
            plan_name: Yup.string().required("Please Enter Plan Name"),
            plan_pattern_id: Yup.string().required("Please Select Plan Pattern"),
            total_amount: Yup.string().required("Please Enter Price"),
        }),
        onSubmit: (values) => {
            values.accessories_type_id = accessoryArr
            // console.log(values);
            // return
            if (values.plan_type_id == '') {
                setSelectPlanType(false)
            }
            else {
                setSelectPlanType(true)
                if (values?.activities_data?.length > 0) {
                    let len = 0;
                    len = (values?.activities_data?.filter(el => el.activity_id == "")).length;
                    if (len !== 0) {
                        setActivityError(true);
                    }
                    else {
                        if (subscriptionId == null) {
                            addSubscriptionData(values)
                        }
                        else {
                            updateSubscriptionData(values)
                        }
                        setActivityError(false);
                    }
                }

            }
        }
    });

    const addSubscriptionData = async (values) => {
        try {
            setIsSaving(true)
            const response = await axiosInstance.post(apiUrls.subscription.addAndUpdateSubscriptionPlan, { ...values });
            if (response.success == 1) {
                formikObj.resetForm()
                setIsSaving(false)
                closeModal()
                swal("Success!", response.message, "success");
                fetchSubscriptionListData(paginatorInfo, searchText)
                setSubscriptionData([])
            }
            else {
                setIsSaving(false)
                swal("Oops!", response.message, "error");
            }
        } catch (err) {
            setIsSaving(false)
            swal("Oops!", "Something went wrong!", "error");
            setError(err.message)
        }
    }

    const updateSubscriptionData = async (values) => {

        try {
            setIsSaving(true)
            const response = await axiosInstance.post(apiUrls.subscription.addAndUpdateSubscriptionPlan, { ...values, id: subscriptionData.id });
            if (response.success == 1) {
                formikObj.resetForm()
                setIsSaving(false)
                closeModal()
                swal("Success!", response.message, "success");
                fetchSubscriptionListData(paginatorInfo, searchText)
                setSubscriptionData([])
                setSubscriptionId(null)
            }
            else {
                setIsSaving(false)
                swal("Oops!", response.message, "error");
            }
        } catch (err) {
            setIsSaving(false)
            swal("Oops!", "Something went wrong!", "error");
            setError(err.message)
        }
    }

    const openModal = () => {
        setAddEditSubscriptionModal(true);
    }

    const closeModal = () => {
        accessoryArr = []
        activities_data = []
        formikObj.resetForm();
        setAddEditSubscriptionModal(false);
    }

    const createSubscription = () => {
        setSubscriptionId(null)
        setSubscriptionData({ ...initialSubscriptionData });
        accessoryArr = []
        formikObj.resetForm();
        openModal()
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Subscription</title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumbs title="Subscription" breadcrumbItem="Subscription" />
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col lg={3}>
                                            <Button
                                                color="primary"
                                                className="font-size-13 btn-block"
                                                onClick={createSubscription}
                                            >
                                                <i className="mdi mdi-plus-circle-outline me-1 align-middle font-size-16" />
                                                Create
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    {/* Datatable view start */}

                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col lg={12}>
                                            <h3>All Subscription List</h3><br />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                            <CommonTable
                                                paginatorInfo={paginatorInfo}
                                                searchText={searchText}
                                                onTableChange={handleTableChange}
                                                keyField='id'
                                                columns={columns}
                                                data={allSubscriptionList}
                                                loading={processingList}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    {/* Datatable view end */}

                    <Modal
                        isOpen={addEditSubscriptionModal}
                        toggle={closeModal}
                        size="lg"
                        backdrop="static"
                    // className={props.className}
                    >
                        <ModalHeader toggle={closeModal} tag="h4">
                            {subscriptionId == null ? "Create New Subscription Plan" : "Edit Subscription Plan"}
                        </ModalHeader>
                        <ModalBody>
                            <FormikProvider value={formikObj}>
                                <Row>
                                    <Col lg={12}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-InputService">Enter Plan Name <span className="text-danger">*</span></Label>
                                            <Input
                                                type="text"
                                                id="formrow-InputService"
                                                className="form-control"
                                                name="plan_name"
                                                onChange={formikObj.handleChange}
                                                value={formikObj.values.plan_name}
                                                placeholder="Enter Plan Name"
                                                invalid={
                                                    formikObj.touched.plan_name && formikObj.errors.plan_name ? true : false
                                                }
                                            />
                                            {formikObj.touched.plan_name && formikObj.errors.plan_name ? (
                                                <FormFeedback type="invalid">{formikObj.errors.plan_name}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-serviceType">Select Plan Pattern <span className="text-danger">*</span></Label>
                                            <Input
                                                type="select"
                                                id="formrow-serviceType"
                                                className="form-control"
                                                name={'plan_pattern_id'}
                                                onChange={formikObj.handleChange}
                                                value={formikObj.values.plan_pattern_id}
                                                invalid={
                                                    formikObj.touched.plan_pattern_id && formikObj.errors.plan_pattern_id ? true : false
                                                }
                                            >
                                                <option value="">Select</option>
                                                {planPatterns.map((item) => (
                                                    <option value={item.id} key={item.id}>{item.plan_pattern_name}</option>
                                                ))}

                                            </Input>
                                            {formikObj.touched.plan_pattern_id && formikObj.errors.plan_pattern_id ? (
                                                <FormFeedback type="invalid">{formikObj.errors.plan_pattern_id}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-InputCategory">Select Plan Type <span className="text-danger">*</span></Label>
                                            <div>
                                                {planTypes.map((item, index) => (
                                                    <div key={index} className="form-check form-check-inline font-size-16">
                                                        <Input
                                                            type="radio"
                                                            value={item.id}
                                                            id="customRadioInline1"
                                                            name="plan_type_id"
                                                            className="form-check-input"
                                                            onClick={(e) => { handlePlanTypeChange(e) }}
                                                            checked={item.id == formikObj.values.plan_type_id ? true : false}
                                                        />
                                                        <Label
                                                            className="form-check-label font-size-13"
                                                            htmlFor="customRadioInline1"
                                                        >
                                                            {item.plan_type_name}
                                                        </Label>
                                                    </div>
                                                ))
                                                }
                                                {selectPlanType == false && <span style={{ color: "red", fontSize: "10px" }}>Please Select Plan Type</span>}

                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-InputServicePrice">
                                                {formikObj.values.plan_type_id == 1 ? 'Total Charge' : 'Charge Per Session'} <span className="text-danger">*</span>
                                            </Label>
                                            <Input
                                                type="text"
                                                id="formrow-InputServicePrice"
                                                className="form-control"
                                                name="total_amount"
                                                // onChange={formikObj.handleChange}
                                                onChange={(e) => { handlePriceChange(e) }}
                                                value={formikObj.values.total_amount}
                                                placeholder="Enter Price"
                                                invalid={
                                                    formikObj.touched.total_amount && formikObj.errors.total_amount ? true : false
                                                }
                                            />
                                            {formikObj.touched.total_amount && formikObj.errors.total_amount ? (
                                                <FormFeedback type="invalid">{formikObj.errors.total_amount}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-InputCategory">Accessory Name</Label>
                                            <div>
                                                {accessories.map((item, index) => (
                                                    <div key={index} className="form-check form-check-inline font-size-16">
                                                        <Input
                                                            type="checkbox"
                                                            value={item.id}
                                                            id="customRadioInline"
                                                            name="accessories_type_id"
                                                            className="form-check-input"
                                                            onClick={(e) => { handleAccessoryChange(e) }}
                                                            checked={formikObj.values?.accessories_type_id?.indexOf(item.id) > -1}
                                                        />
                                                        <Label
                                                            className="form-check-label font-size-13"
                                                            htmlFor="customRadioInline"
                                                        >
                                                            {item.accessory_name}
                                                        </Label>
                                                    </div>
                                                ))
                                                }
                                                {/* {selectPlanType == false && <span style={{ color: "red", fontSize: "10px" }}>Please Select Plan Type</span>} */}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <FieldArray
                                    name="activities_data"
                                    render={arrayHelpersAdd => (
                                        <>
                                            {formikObj.values?.activities_data?.map((data, index) => {
                                                const isLastRow = (formikObj.values?.activities_data?.length - 1) === index;
                                                const getFieldName = name => `${arrayHelpersAdd.name}[${index}][${name}]`;
                                                const getErrorMsg = name => (formikObj.errors.activities_data?.[index]?.[name] && formikObj.touched.activities_data?.[index]?.[name]
                                                    ? formikObj.errors.activities_data?.[index]?.[name]
                                                    : "")
                                                return (
                                                    <React.Fragment key={index}>
                                                        <Row>
                                                            <Col lg={3}>
                                                                <div className="mb-3">
                                                                    <Label htmlFor="formrow-serviceType">
                                                                        {(formikObj.values.plan_type_id == "1" || formikObj.values.plan_type_id == 1) ? "Select Activities" : "Select Items"}
                                                                        <span className="text-danger">*</span>
                                                                    </Label>
                                                                    <Input
                                                                        type="select"
                                                                        id="formrow-serviceType"
                                                                        className="form-control"
                                                                        name={getFieldName("activity_id")}
                                                                        onChange={formikObj.handleChange}
                                                                        value={data.activity_id}
                                                                    >
                                                                        <option value="">Select</option>
                                                                        {activityList.map((item) => (
                                                                            <option value={item.id} key={item.id}>{item.activity}</option>
                                                                        ))}

                                                                    </Input>
                                                                    {activityError == true && <span style={{ color: "red", fontSize: "10px" }}>
                                                                        {(formikObj.values.plan_type_id == "1" || formikObj.values.plan_type_id == 1) ? "Please Select Activities" : "Please Select Items"}
                                                                    </span>
                                                                    }
                                                                </div>
                                                            </Col>
                                                            {(formikObj.values.plan_type_id == "1" || formikObj.values.plan_type_id == 1) &&
                                                                <Col lg={6}>
                                                                    <div className="mb-3">
                                                                        <Label htmlFor="formrow-InputCategory">Select Activity Type</Label>
                                                                        <br />
                                                                        {activityTypes.map((item, index) => (
                                                                            <div key={index} className="form-check form-check-inline font-size-16">
                                                                                <Input
                                                                                    type="radio"
                                                                                    value={item.id}
                                                                                    id="customRadioInline1"
                                                                                    name={getFieldName("activity_type_id")}
                                                                                    className="form-check-input"
                                                                                    onClick={(e) => {
                                                                                        formikObj.setFieldValue(getFieldName("activity_type_id"), e.target.value)
                                                                                    }}
                                                                                    checked={item.id == data.activity_type_id ? true : false}
                                                                                />
                                                                                <Label
                                                                                    className="form-check-label font-size-13"
                                                                                    htmlFor="customRadioInline1"
                                                                                >
                                                                                    {item.activity_type}
                                                                                </Label>
                                                                            </div>
                                                                        ))
                                                                        }
                                                                        <Input
                                                                            type="checkbox"
                                                                            id="customRadioInline"
                                                                            name={getFieldName("is_primary")}
                                                                            className="form-check-input me-2"
                                                                            onClick={(e) => {
                                                                                const isChecked = e.target.checked;
                                                                                formikObj.setFieldValue(getFieldName("is_primary"), isChecked ? 1 : 0);
                                                                            }}
                                                                            defaultChecked={data.is_primary == 1}
                                                                        />
                                                                        <Label
                                                                            className="form-check-label font-size-13"
                                                                            htmlFor="customRadioInline"
                                                                        >
                                                                            Primary
                                                                        </Label>
                                                                        {/* {selectPlanType == false && <span style={{ color: "red", fontSize: "10px" }}>Please Select Plan Type</span>} */}
                                                                    </div>
                                                                </Col>
                                                            }
                                                            {data.activity_type_id == 2 &&
                                                                <Col lg={3}>
                                                                    <div className="mb-3">
                                                                        <Label htmlFor="formrow-InputService">No. of Sessions</Label>
                                                                        <Input
                                                                            type="text"
                                                                            id="formrow-InputService"
                                                                            className="form-control"
                                                                            name={getFieldName("no_of_sessions")}
                                                                            onChange={(e) => {
                                                                                if (/^[0-9]+$/g.test(e.target.value) || e.target.value == '') {
                                                                                    formikObj.setFieldValue(getFieldName("no_of_sessions"), e.target.value)
                                                                                }
                                                                            }}
                                                                            value={data.no_of_sessions}
                                                                            placeholder="No. of Sessions"
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            }
                                                        </Row>
                                                        {(formikObj.values.plan_type_id == "1" || formikObj.values.plan_type_id == 1) &&
                                                            <Row>
                                                                <Col lg={12} className="d-flex align-items-center" style={{ justifyContent: "right" }}>
                                                                    <div className="">
                                                                        {!!data.id ?
                                                                            <button
                                                                                type="button"
                                                                                className="btn-rounded  btn btn-danger ms-2 btn-sm"
                                                                                onClick={(e) => { handleActivityDelete(e, data.id) }}>
                                                                                <i className="bx bx-trash align-middle" />
                                                                            </button>
                                                                            :
                                                                            formikObj.values?.activities_data.length > 1 && <button
                                                                                type="button"
                                                                                className="btn-rounded  btn btn-danger ms-2 btn-sm"
                                                                                onClick={() => arrayHelpersAdd.remove(index)}
                                                                            > <i className="bx bx-minus align-middle" />
                                                                            </button>
                                                                        }
                                                                    </div>
                                                                    <div className="">
                                                                        {isLastRow && <button
                                                                            type="button"
                                                                            className="btn-rounded  btn btn-success ms-2 btn-sm"
                                                                            onClick={() => {
                                                                                arrayHelpersAdd.push({
                                                                                    activity_id: "",
                                                                                    activity_type_id: "",
                                                                                    no_of_sessions: "",
                                                                                    is_primary: 0
                                                                                })
                                                                            }
                                                                            }>
                                                                            <i className="bx bx-plus align-middle" />
                                                                        </button>
                                                                        }
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        }
                                                    </React.Fragment>
                                                )
                                            })
                                            }
                                        </>
                                    )}
                                >

                                </FieldArray>
                            </FormikProvider>
                        </ModalBody>
                        <div className="modal-footer">
                            {!!isSaving ?
                                <button className="btn btn-primary " disabled>
                                    Processing
                                </button>
                                :
                                <>
                                    <button
                                        type="button"
                                        onClick={closeModal}
                                        className="btn btn-secondary "
                                        data-dismiss="modal"
                                    >
                                        Close
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn btn-primary "
                                        onClick={(e) => {
                                            e.preventDefault();
                                            formikObj.handleSubmit();
                                            return false;
                                        }}
                                    >
                                        {subscriptionId == null ? "Save" : "Update"}
                                    </button>
                                </>
                            }
                        </div>
                    </Modal>

                </Container>
            </div>
        </React.Fragment>
    );

}

export default withRouter(Subscription);

Subscription.propTypes = {
    history: PropTypes.object,
};