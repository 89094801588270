import PropTypes from "prop-types";
import React from "react";
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import SidebarContent from "./SidebarContent";

import { Link } from "react-router-dom";
import images from "assets/images";

const Sidebar = props => {
  const profileData = useSelector(state => state.Profile.data);
  const profileLoading = useSelector(state => state.Profile.loading);
  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box">
          {!profileLoading
            && <Link to="/" className="logo logo-light">
              <span className="logo-sm">
                <img
                  src={!!profileData.profile_image
                    ? profileData.profile_image
                    : images.defaultAvatar
                  }
                  alt="Profile"
                  className="rounded-circle header-profile-user"
                />
              </span>
              <span className="logo-lg">
                <img
                  src={!!profileData.profile_image
                    ? profileData.profile_image
                    : images.defaultAvatar
                  }
                  alt="profile"
                  className="avatar-sm rounded-circle img-thumbnail"
                />
                <span className="extra-space">{profileData?.name}</span>

              </span>
            </Link>
          }
        </div>
        <div data-simplebar className="h-100">
          {!profileLoading &&
            <React.Fragment>
              {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
            </React.Fragment>
          }

        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  );
};

Sidebar.propTypes = {
  type: PropTypes.string,
};

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  };
};
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)));
