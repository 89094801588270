import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { withRouter } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Flatpickr from "react-flatpickr";
import * as SubscriptionQry from "GraphQLquery/subscriptionQry";
import { standartDateFormat, userDateFormat } from "utils/date"

//api
import { apiUrls, axiosInstance, graphql } from "api"


const RenewalReport = () => {

  const [mainTrainer, setMainTrainer] = useState("");
  const [mainTrainerList, setMainTrainerList] = useState([]);
  const [processingList, setProcessingList] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [offSet, setOffSet] = useState('10');
  const [offSetSelectAll, setOffSetSelectAll] = useState(false);
  const [offSetLength, setOffSetLength] = useState('');
  const [pageURL, setPageURL] = useState('1');
  const [pageLinks, setPageLinks] = useState([]);
  const [allRenewalReportList, setAllRenewalReportList] = useState([]);
  const [activityList, setActivityList] = useState([]);
  const [activityId, setActivityId] = useState('');


  useEffect(() => {
    fetchMainTrainerList();
    fetchActivities();
  }, [])

  const fetchMainTrainerList = async () => {
    try {
      const res = await axiosInstance.post(apiUrls.trainer.allMainTrainerList)
      if (res.success == 1) {
        setMainTrainerList(res.data)
      }
      else {
        setMainTrainerList([])
      }
    } catch (error) {
      setMainTrainerList([])
    }
  }

  const fetchActivities = async () => {
    try {
      const res = await graphql({
        query: SubscriptionQry.ACTIVITIES_LIST_QUERY
      });
      if (res.data.activities) {
        let activities = res?.data?.activities || []
        setActivityList(activities)
      } else {
        setActivityList([])
      }
    } catch (error) {
      setActivityList([])
    }
  }

  useEffect(() => {
    fetchAllRenewalReportData(offSet, pageURL, mainTrainer, startDate, endDate, searchText, activityId)
  }, [])

  const fetchAllRenewalReportData = async (offSet, pageURL, mainTrainer, startDate, endDate, searchText, activityId) => {
    try {
      setProcessingList(true);
      const res = await axiosInstance.post(apiUrls.report.fetchRenewalReport + "?page=" + pageURL, { offset: offSet, search_text: searchText, main_trainer: mainTrainer, start_date: startDate, end_date: endDate, activity_id: activityId });
      if (res.success == 1) {
        if (res.data) {
          setAllRenewalReportList(!!res?.data?.data?.length > 0 ? res?.data?.data : []);
          setPageLinks(!!res?.data?.data?.length > 0 ? res?.data?.links : []);
          setOffSetLength((res?.data?.total).toString());
        } else {
          setAllRenewalReportList([]);
        }
        setProcessingList(false);
      } else {
        setAllRenewalReportList([]);
        setProcessingList(false);
      }
    } catch (error) {
      setProcessingList(false);
      setAllRenewalReportList([]);
    }
  }

  const filterRenewalReportByDateRange = (e) => {
    let startDate = '';
    let endDate = '';
    if (e[0] && e[1]) {
      startDate = standartDateFormat(e[0]);
      endDate = standartDateFormat(e[1]);
      setStartDate(standartDateFormat(e[0]))
      setEndDate(standartDateFormat(e[1]))
      fetchAllRenewalReportData(offSet, pageURL, mainTrainer, startDate, endDate, searchText, activityId)
    }
    else if (e.length == '') {
      setStartDate('');
      setEndDate('');
      fetchAllRenewalReportData(offSet, pageURL, mainTrainer, startDate, endDate, searchText, activityId)
    }
  }

  const filterRenewalReportByMainTrainer = (e) => {
    let mainTrainer = e.target.value
    setMainTrainer(mainTrainer)
    fetchAllRenewalReportData(offSet, pageURL, mainTrainer, startDate, endDate, searchText, activityId)
  }
  const filterRenewalReportByActivityId = (e) => {
    setActivityId(e.target.value)
    fetchAllRenewalReportData(offSet, pageURL, mainTrainer, startDate, endDate, searchText, e.target.value)
  }

  const handleOffsetChange = (e) => {
    let offSet = ''
    let pageURL = '1'
    if ((e.target.value) == 'All') {
      offSet = offSetLength
      setOffSet(offSet);
      setPageURL('1');
      setOffSetSelectAll(true);
    } else {
      offSet = e.target.value
      setOffSet(offSet);
      setOffSetSelectAll(false);
      if ((parseInt(offSetLength)) <= (parseInt(e.target.value))) {
        setPageURL('1');
      }
    }
    fetchAllRenewalReportData(offSet, pageURL, mainTrainer, startDate, endDate, searchText, activityId)
  }

  const getPageNumber = (e, data) => {
    e.preventDefault()
    if (parseInt(data.label)) {
      setPageURL(data.label)
      fetchAllRenewalReportData(offSet, data.label, mainTrainer, startDate, endDate, searchText, activityId)
    } else {
      let pageNumber = data.url.split("=")
      setPageURL(pageNumber[1])
      fetchAllRenewalReportData(offSet, pageNumber[1], mainTrainer, startDate, endDate, searchText, activityId)
    }
  }

  const handleSearch = (e) => {
    let searchText = e.target.value;
    setSearchText(searchText);
    fetchAllRenewalReportData(offSet, pageURL, mainTrainer, startDate, endDate, searchText, activityId)
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Renewal Report</title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs title="Report" breadcrumbItem="Renewal Report" />
          <Row>
            <Col className="col-12"></Col>
            <Card>
              <CardBody>
                <Row className="mb-4">
                  {/* 0000 */}
                  <Col lg={4}>
                    <h3>All Renewal Report list</h3>
                  </Col>
                  <Col lg={12}>
                    <div className="filter">
                      <select
                        className="ms-md-2 form-select w-auto me-2 pe-5 mb-3 mb-md-0"
                        onChange={e => filterRenewalReportByActivityId(e)}
                      >
                        <option value="">Filter by Activity</option>
                        {activityList.map((activityName, index) => (
                          <option key={index} value={activityName.id}>{activityName.activity}</option>
                        ))}
                      </select>
                      <Flatpickr
                        className="form-control filter-date-width"
                        placeholder="Filter by Date"
                        options={{
                          mode: "range",
                          dateFormat: "d-m-Y",
                        }}
                        onChange={e => filterRenewalReportByDateRange(e)}
                      />
                      <select
                        // className="btn btn-outline-info ms-md-2 form-select w-auto pe-5 mb-3 mb-md-0"
                        className="ms-md-2 form-select w-auto pe-5 mb-3 mb-md-0"
                        onChange={e => filterRenewalReportByMainTrainer(e)}
                      >
                        <option value="">Filter by Main Trainer</option>
                        {mainTrainerList.map((trainer, index) => (
                          <option key={index} value={trainer.id}>{trainer.name}</option>
                        ))}
                      </select>
                    </div>
                  </Col>
                  {/* 0000 */}
                </Row>

                <Row>
                  <Col lg={7}>
                    <>
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <Label for="search-bar-0" className="search-label">
                            <span id="search-bar-0-label" className="sr-only">Search this table</span>
                            <Input id="search-bar-0"
                              type="text"
                              aria-labelledby="search-bar-0-label"
                              className="form-control"
                              placeholder="Search"
                              value={searchText}
                              onChange={(e) => handleSearch(e)}
                            />
                          </Label>
                          <i className="bx bx-search-alt search-icon"></i>
                        </div>
                      </div>
                    </>
                  </Col>
                  <Col lg={5}>
                    <div className="d-flex mt-2">
                      <span style={{ height: '20px', width: '20px', backgroundColor: '#F7BEC0' }}>
                      </span>
                      <span className="ms-2">Members having multiple active memberships</span>
                    </div>
                  </Col>
                </Row>
                <Row>
                  {processingList ?
                    <Col lg={12} style={{ textAlign: "center" }}>
                      <h5><i className="bx bx-hourglass bx-spin font-size-24 align-middle me-2"></i>
                        Loading...</h5>
                    </Col>
                    :
                    <>
                      {allRenewalReportList.length > 0 ?
                        <Col lg={12}>
                          <Row>
                            <Col lg={12}>
                              <div className="table-responsive">
                                <Table className="table mb-0">
                                  <thead>
                                    <tr style={{ whiteSpace: "nowrap" }}>
                                      <th>Member Name</th>
                                      <th>Renewal Date</th>
                                      <th>Subscription Type</th>
                                      <th>Activities</th>
                                      <th>Paid Ammount</th>
                                      <th>Trainer Name</th>
                                      <th>Trainer Benefit</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {allRenewalReportList.map((item, index) => (
                                      <tr key={index} style={{ backgroundColor: item?.is_multiple_membership === 1 && '#F7BEC0' }}>
                                        <td>{item?.member_name}</td>
                                        <td>{item?.renewal_date ? userDateFormat(item.renewal_date) : '-'}</td>
                                        <td>{item?.subscription_plan_name}</td>
                                        <td>{item?.activity_names?.map((item, index) => (
                                          <div key={index} style={{ whiteSpace: "normal" }}>
                                            <div className="bg-primary bg-soft p-2 my-1 text-primary badge">
                                              {item?.activity}
                                            </div>
                                          </div>
                                        ))}</td>
                                        <td>{item?.paid_amount}</td>
                                        <td>{item?.trainer_name}</td>
                                        <td>{item?.trainer_benefit}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                              </div>
                            </Col>
                            <div className="mt-2">
                              <div style={{ float: "left" }}>
                                <Input
                                  type="select"
                                  id="formrow-serviceType"
                                  className="form-control"
                                  name={'offset'}
                                  onChange={(e) => handleOffsetChange(e)}
                                  value={offSetSelectAll ? "All" : offSet}
                                >
                                  <option value="10">10</option>
                                  <option value="20">20</option>
                                  <option value="50">50</option>
                                  <option value="100">100</option>
                                  <option value="All">All</option>
                                </Input>
                              </div>
                              <div style={{ justifyContent: "end", display: "flex" }}>
                                <ul className="pagination react-bootstrap-table-page-btns-ul">
                                  {pageLinks.map((btn, index) => {
                                    return (
                                      <React.Fragment key={index}>
                                        {!!btn.url &&
                                          <li className={!!btn.active ? "active page-item" : "page-item"}>
                                            <a className="page-link" href="#" onClick={(e) => getPageNumber(e, btn)}>
                                              {btn.label.includes("Next") ? ">" : btn.label.includes("Previous") ? "<" : btn.label}
                                            </a>
                                          </li>
                                        }
                                      </React.Fragment>
                                    )
                                  })
                                  }
                                </ul>
                              </div>
                            </div>
                          </Row>
                        </Col>
                        :
                        <Col lg={12} style={{ textAlign: "center" }}>
                          <h5>No Data Found</h5>
                        </Col>
                      }
                    </>
                  }
                </Row>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(RenewalReport)
