import { WORKOUT_ASSIGNED_TO_MEMBER } from "./actionTypes";

const initialState = {
    workoutAssignedStatus: false
}

const WorkoutAssignedToMember = (state = initialState, action) => {
    switch (action.type) {
        case WORKOUT_ASSIGNED_TO_MEMBER:
            state = { workoutAssignedStatus: action.workoutAssignedStatus }
            break;
        default:
            state = { workoutAssignedStatus: null };
            break;
    }
    return state;
}

export default WorkoutAssignedToMember