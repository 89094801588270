import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useEffect, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import AssignMemberDietChart from "pages/Common/AssignMemberDietChart";

const MemberDietPlan = props => {

    return (
        <React.Fragment>
            <AssignMemberDietChart
                member_id={null}
                assignDietModal={false}
                redirectPath="/members-diet-chart"
            />
        </React.Fragment>
    );
}

export default withRouter(MemberDietPlan);

MemberDietPlan.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
};