import { generateSearchQuery } from "utils/graphql"


const searchQry = generateSearchQuery([
  'body_weight'])

  export const FETCH_ALL_BODY_COMPOSITION_QUERY = `query allBodyCompositionList($perPage: Int!, $currentPage: Int, $searchText: Mixed, $member_id: Mixed)
  {
    body_compositions (
        first:$perPage,page:$currentPage,
        where:{
            AND:[{column:_member_id, operator: LIKE value: $member_id}]
            ${searchQry}
        }
        orderBy:{column:_id ,order:DESC}
    )
    {
        data{
            id
            member_id
            body_weight
            body_fat_percentage
            visceral_fat_percentage
            resting_metabolic_rate
            body_mass_index
            entry_date
            next_measurement_day
            body_height
            body_age
            member_details{
                id
                member_name
                email_id
                membership_details(orderBy :{column:_id order:ASC}){
                    beginning_date
                  }
            }
        }
        paginatorInfo {
            currentPage
            total
            perPage
        }
    } 
  }
  `


  export const FETCH_BODY_COMPOSITION_EDIT_QUERY = `query editBodyCompositionList($id: Mixed)
  {
    body_compositions (
        where:{
            AND:[{column:_id, operator: LIKE value: $id}]
        }
        orderBy:{column:_id ,order:DESC}
    )
    {
        data{
            id
            member_id
            body_weight
            body_fat_percentage
            visceral_fat_percentage
            resting_metabolic_rate
            body_mass_index
            entry_date
            next_measurement_day
            body_height
            body_age
            member_details{
                id
                member_name
                email_id
            }
        }
    } 
  }
  `