import { CREATE_BODY_COMPOSITION } from "./actionTypes";

const initialState = {
    bodyCompositionCreatedStatus: false
}

const BodyCompositionCreated = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_BODY_COMPOSITION:
            state = { bodyCompositionCreatedStatus: action.bodyCompositionCreatedStatus }
            break;
        default:
            state = { bodyCompositionCreatedStatus: null };
            break;
    }
    return state;
}

export default BodyCompositionCreated