import { withRouter } from "react-router-dom"
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useEffect, useState } from "react";
import {
    Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label, Button, Modal,
    ModalBody,
    ModalHeader,
    InputGroup, Table, CardTitle, CardHeader
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { setModalState, setReloadBatchList } from "../../store/actions";
import { userDateFormat } from "utils/date";
import { axiosInstance, apiUrls } from "api";

const BatchModal = props => {
    const { isOpenBatchModal, data } = props
    const dispatch = useDispatch();
    const [openModal, setOpenModal] = useState(false)

    useEffect(() => {
        setOpenModal(isOpenBatchModal)
    }, [isOpenBatchModal])

    const closeModal = () => {
        setOpenModal(false)
        dispatch(setModalState(false));
    }

    const handleAction = (member) => {
        return (
            <>
                <Button className="btn-danger btn-sm" onClick={(e) => { handleDelete(e, member) }} title="Drop">
                    <i className="bx bx-user-minus"></i>
                </Button>
            </>
        )
    }

    const handleDelete = (e, row) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (willDelete) => {
                if (willDelete) {
                    try {
                        const response = await axiosInstance.post(apiUrls.batch.dropMemberFromBatch, { batch_id: row.id, member_id: row.member_id });
                        if (response.success == 1) {
                            swal("Success!", response.message, "success");
                            dispatch(setReloadBatchList(true))
                        }
                        else {
                            swal("Oops!", response.message, "error");
                        }
                    } catch (err) {
                        swal("Oops!", "Something went wrong!", "error");
                    }
                }
            });
    }


    return (
        <React.Fragment>
            <Modal
                isOpen={openModal}
                toggle={closeModal}
                size="lg"
                backdrop="static"
            >
                <ModalHeader toggle={closeModal} tag="h4">
                    Batch Details
                </ModalHeader>
                <ModalBody>
                    <Card className="border border-primary">
                        <CardHeader className="alert alert-primary">
                            <Row>
                                <Col col={6}>
                                    <Label>
                                        <b>Name : </b><span className="text-dark">{data.activity}</span>
                                    </Label> 
                                </Col>
                                <Col col={6} className="text-end">
                                    <Label>
                                        <b>From : </b><span className="text-dark">{userDateFormat(data.end_date)}</span>
                                    </Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col col={6}>
                                    <Label>
                                        <b>Trainer : </b><span className="text-dark">{data.trainer_name}</span>
                                    </Label>
                                </Col>
                                <Col col={6} className="text-end">
                                    <Label>
                                        <b>To :  </b><span className="text-dark">{userDateFormat(data.start_date)}</span>
                                    </Label>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            {!!data.members && data.members.length > 0 ?
                                <Row>
                                    <Col lg={12}>
                                        <div className="table-responsive">
                                            <Table className="table mb-0">
                                                <thead>
                                                    <tr className="whitespace-no-wrap">
                                                        <th>MEMBER NAME</th>
                                                        <th>MEMBERSHIP STATUS</th>
                                                        <th>BATCH DATE</th>
                                                        <th>ALLOTMENT TYPE</th>
                                                        <th>ACTION</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {!!data.members && data?.members.map((member, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{member.member_name}</td>
                                                                <td>{member.membership_status == 1 ? <span className="badge rounded-pill bg-success px-2 py-1">Ongoing</span> : <span className="badge rounded-pill bg-danger float-start">Expired</span>}</td>
                                                                <td>{!!member.batch_date && userDateFormat(member?.batch_date)}</td>
                                                                <td>{member.alloted_status == 1 ? <span className="badge rounded-pill bg-success px-2 py-1">Full</span> : <span className="badge rounded-pill bg-warning float-start">Partial</span>}</td>
                                                                <td  className="whitespace-no-wrap">{handleAction(member)}</td>
                                                            </tr>
                                                        )
                                                    })
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Col>
                                </Row>
                                :
                                <Row>
                                    <Col lg={12} style={{ textAlign: "center" }}>
                                        <h5>No Data Found</h5>
                                    </Col>
                                </Row>
                            }
                        </CardBody>
                    </Card>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
}
export default withRouter(BatchModal);

BatchModal.propTypes = {
    history: PropTypes.object,
    isOpenBatchModal: PropTypes.bool,
    data: PropTypes.array
};