import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useEffect, useState } from "react";
import {
    Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label, Button, Modal,
    ModalBody,
    ModalHeader,
    InputGroup, Table
} from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
// Formik formikObj
import * as Yup from "yup";
import { FieldArray, FormikProvider, useFormik } from "formik";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { standartDateFormat, today, userDateFormat } from "utils/date";
import { axiosInstance, apiUrls } from "api";
import swal from 'sweetalert';
import { graphql } from "api";

// datatable related plugins

import CommonTable from "components/Common/CommonTable";
import usePaginator from "hooks/usePaginator";
import * as SubscriptionQry from "GraphQLquery/subscriptionQry";
import * as MemberQry from "GraphQLquery/memberQry";
import * as MembershipQry from "GraphQLquery/membershipQry";
import { round } from "lodash";
import moment from "moment";

import logo from "assets/images/favicon.svg";
import paid from "assets/images/paid.png";
import { setIsSubmit } from "redux-form";
import { useSelector, useDispatch } from "react-redux";
import { setMembershipUpdated } from "../../store/actions";
import Select from "react-select";

const initialMembershipData = {
    member_id: "",
    subscription_plan_id: "",
    beginning_date: "",
    end_date: "",
    number_of_sessions: "",
    subscription_plan_charges: 0,
    discount: "",
    preferred_timing: "1",
    // (1- Any time / 2-Off Peak)
    gym_timing_from: "",
    gym_timing_to: "",
    floor: [],
    // (1-Cardio /2-Strength)
    member_access_code: "",
    gst_amount: 0,
    total_amount: 0,
    status: 1,
    payment_mode_id: "",

    // activities_data: [{
    //     activity_id: "",
    //     activity_type_id: "",
    //     no_of_sessions: ""
    // }]
}

const gst_rate = 0.18
let editGrossAmount = ''
let editSubscriptionPlanType = ''
let editSubscriptionAmount = ''
let sub_total = 0
let allMember = []
let floorArr = [];

const EditMembership = props => {
    const dispatch = useDispatch();
    const [membershipData, setMembershipData] = useState({ ...initialMembershipData })
    const [membershipId, setMembershipId] = useState(null)
    const [isSaving, setIsSaving] = useState(false);
    const [addEditMembershipModal, setAddEditMembershipModal] = useState(false);
    const [invoiceModal, setInvoiceModal] = useState(false);
    const [error, setError] = useState(null);
    const [processingList, setProcessingList] = useState(false);
    const [processingMemberList, setProcessingMemberList] = useState(false);
    const [allSubscriptionList, setAllSubscriptionList] = useState([]);
    const [allMemberList, setAllMemberList] = useState([]);
    const [allMembershipsList, setAllMembershipsList] = useState([]);
    const [allFloorTypeList, setAllFloorTypeList] = useState([]);
    const [paginatorInfo, setPaginatorInfo, resetPaginatorInfo] = usePaginator();
    const [searchText, setSearchText] = useState('');
    const [beginning_date, setBeginingDate] = useState('');
    const [end_date, setEndDate] = useState('');
    const [subscriptionPlanType, setSubscriptionPlanType] = useState('')
    const [grossAmount, setGrossAmount] = useState('')
    const [subscriptionAmount, setSubscriptionAmount] = useState('')
    const [planPattern, setPlanPattern] = useState('')
    const [minDate, setMinDate] = useState("");
    const [invoiceData, setInvoiceData] = useState('')
    const [discountErrorMsg, setDiscountErrorMsg] = useState('')
    const [isSubmit, setIsSubmit] = useState(true)
    const [preferTimingType, setPreferTimingType] = useState([])
    const [activityList, setActivityList] = useState([])
    const [activityTypes, setActivityTypes] = useState([])
    const [paymenyType, setPaymenyType] = useState([]);
    const [searchMemberId, setSearchMemberId] = useState(null);
    const [tempMemberArr, setTempMemberArr]= useState([])

    const membership_id = props.membership_id

    useEffect(() => {
        setTimeout(() => {
            handleFetch(membership_id)
        }, 2000);

        fetchMemberListData()
        fetchSubscriptionListData(paginatorInfo, searchText)
        fetchFloorTypes()
        fetchPreferredTimingTypes()
        fetchPaymenyType();
    }, [])

    const handleFetch = async (membership_id) => {
        formikObj.resetForm()
        setMembershipData({ ...initialMembershipData })
        let responseData = []
        try {
            setMembershipId(membership_id)
            const res = await graphql({
                query: MembershipQry.FETCH_ALL_MEMBERSHIPS_QUERY,
                variables: {
                    ...paginatorInfo,
                    searchText: `%${searchText}%`,
                    id: membership_id
                }
            });
            responseData = !!res.data.memberships ? res.data.memberships.data[0] : []
        } catch (error) {

        }

        let floor_arr = []
        if (responseData?.membership_floors?.length > 0) {
            for (let index = 0; index < responseData?.membership_floors?.length; index++) {
                floorArr.push(responseData?.membership_floors?.[index]?.floor_details?.id)
            }
        }

        const updatedMembershipsData = {
            id: responseData.id,
            member_id: responseData.member_id,
            subscription_plan_id: responseData.subscription_plan_id,
            beginning_date: responseData.beginning_date,
            end_date: responseData.end_date,
            number_of_sessions: !!responseData.number_of_sessions ? responseData.number_of_sessions : '',
            subscription_plan_charges: responseData.subscription_plan_charges,
            discount: responseData.discount,
            gym_timing_from: responseData.gym_timing_from,
            gym_timing_to: responseData.gym_timing_to,
            floor: floorArr,
            preferred_timing: responseData.preferred_timing_details?.id,
            gst_amount: responseData.gst_amount,
            total_amount: responseData.total_amount,
            status: responseData.status,
            payment_mode_id: responseData?.payment_mode?.id,
            // activities_data: activities_data
        }
        // setBeginingDate(moment(responseData?.beginning_date).format("DD-MM-YYYY"))
        // setEndDate(moment(responseData?.end_date).format("DD-MM-YYYY"))
        setBeginingDate(userDateFormat(responseData?.beginning_date))
        setEndDate(userDateFormat(responseData?.end_date))
        setSearchMemberId({ label: responseData?.member_details?.member_name, value: responseData?.member_id })
        formikObj.setFieldValue("beginning_date", responseData?.beginning_date)
        formikObj.setFieldValue("end_date", responseData?.end_date)
        setMembershipData(updatedMembershipsData)
        openModal()
    }

    const fetchMemberListData = async () => {
        allMember = []
        try {
            setProcessingMemberList(true)
            const res = await graphql({
                query: MemberQry.FETCH_ALL_MEMBERS_FOR_MEMBERSHIP_QUERY,
                variables: {
                    // ...paginatorInfo,
                    // searchText: `%${searchText}%`,
                    // id: "%%",
                    // status_id: "%%"
                }
            });

            if (res.data.members.data) {
                setProcessingMemberList(false)
                setAllMemberList(!!res.data.members ? res.data.members.data : [])
                allMember = !!res.data.members ? res.data.members.data : []
                let tempMemberArr = []
                res.data?.members?.data.map((item) => (
                    tempMemberArr.push({ label: item.member_name, value: item.id })
                ))
                setTempMemberArr(tempMemberArr)
            }

            else {
                setProcessingMemberList(false)
                setAllMemberList([])
                allMember = []
            }
        } catch (error) {
            setProcessingMemberList(false)
            setAllMemberList([])
            allMember = []
        }
    }

    const fetchPaymenyType = async () => {
        try {
            let response = null;
            response = await graphql({
                query: SubscriptionQry.FETCH_PAYMENT_MODE_QUERY,
                variables: {}
            });
            let paymenyType = []
            paymenyType = response?.data?.payment_mode || [];
            setPaymenyType(paymenyType);
        } catch (err) {
            setPaymenyType([]);
        }
    }

    const fetchSubscriptionListData = async (paginatorInfo, searchText) => {
        try {
            setProcessingList(true)
            const res = await graphql({
                query: SubscriptionQry.FETCH_ALL_SUBSCRIPTIONS_QUERY_FOR_MEMBER
            });

            if (res.data.subscription_plans.data) {
                setProcessingList(false)
                setAllSubscriptionList(!!res.data.subscription_plans ? res.data.subscription_plans.data : [])
                
            }

            else {
                setProcessingList(false)
                setAllSubscriptionList([])
            }
        } catch (error) {
            setProcessingList(false)
            setAllSubscriptionList([])
        }
    }

    const fetchFloorTypes = async () => {
        try {
            setProcessingList(true)
            const res = await graphql({
                query: MembershipQry.FLOOR_TYPES_QUERY,
            });

            if (res.data.floors) {
                setProcessingList(false)
                setAllFloorTypeList(!!res.data.floors ? res.data.floors : [])
            }

            else {
                setProcessingList(false)
                setAllFloorTypeList([])
            }
        } catch (error) {
            setProcessingList(false)
            setAllFloorTypeList([])
        }
    }

    const fetchPreferredTimingTypes = async () => {
        try {
            // setPreferTimingType([{ id: 1, timing: "Any Time" }, { id: 2, timing: "Off Peak" }])
            setProcessingList(true)
            const res = await graphql({
                query: MembershipQry.PREFERRED_TIMINGS_TYPES_QUERY,
            });

            if (res.data.preferred_timings) {
                setProcessingList(false)
                setPreferTimingType(!!res.data.preferred_timings ? res.data.preferred_timings : [])
            }

            else {
                setProcessingList(false)
                setPreferTimingType([])
            }
        } catch (error) {
            setProcessingList(false)
            setPreferTimingType([])
        }
    }

    const formikObj = useFormik({
        enableReinitialize: true,

        initialValues: { ...membershipData },
        validationSchema: Yup.object({
            member_id: Yup.string().required("Please Select Member"),
            subscription_plan_id: Yup.string().required("Please Select Subscription Plan"),
            beginning_date: Yup.string().required("Please Select Begining Date"),
            end_date: Yup.string().required("Please Select End Date"),
            member_access_code: Yup.string().required("Please Enter Access Card No."),
            payment_mode_id: Yup.string().required("Please Select Payment Mode"),
        }),
        onSubmit: async (values) => {
            try {
                setIsSaving(true)
                const response = await axiosInstance.post(apiUrls.membership.addAndUpdateMembership, { ...values, id: membershipData.id });
                if (response.success == 1) {
                    formikObj.resetForm()
                    setIsSaving(false)
                    closeModal()
                    swal("Success!", response.message, "success");
                    setBeginingDate('')
                    setEndDate('')
                    formikObj.setFieldValue("beginning_date", "")
                    formikObj.setFieldValue("end_date", "")
                    dispatch(setMembershipUpdated(true));
                    setMembershipData([])
                    setMembershipId(null)
                }
                else {
                    setIsSaving(false)
                    swal("Oops!", response.message, "error");
                }
            } catch (err) {
                setIsSaving(false)
                swal("Oops!", "Something went wrong!", "error");
                setError(err.message)
            }

        }
    });

    const openModal = () => {
        setAddEditMembershipModal(true);
    }

    const closeModal = () => {
        formikObj.resetForm()
        formikObj.setFieldValue("beginning_date", "")
        formikObj.setFieldValue("end_date", "")
        setBeginingDate("")
        setEndDate("")
        formikObj.setFieldValue("gst_amount", 0)
        formikObj.setFieldValue("total_amount", 0)
        setAddEditMembershipModal(false);
        dispatch(setMembershipUpdated(false));
    }

    const handleSessionsChange = (e) => {
        if (/^[0-9]+$/g.test(e.target.value) || e.target.value == '') {
            formikObj.setFieldValue(e.target.name, e.target.value)
        }
    }

    const beginingDateChange = (beginingDates) => {
        if (!!beginingDates[0]) {
            setBeginingDate(beginingDates[0].getDate() + '-' + (beginingDates[0].getMonth() + 1) + '-' + beginingDates[0].getFullYear())
            formikObj.setFieldValue("beginning_date", standartDateFormat(beginingDates[0]))
            if (planPattern == 1) {
                setEndDate(moment(standartDateFormat(beginingDates[0])).add(1, 'Y').format('DD-MM-YYYY'))
                formikObj.setFieldValue("end_date", moment(standartDateFormat(beginingDates[0])).add(1, 'Y').format('YYYY-MM-DD'))
            }
            else if (planPattern == 2) {
                setEndDate(moment(standartDateFormat(beginingDates[0])).add(6, 'M').format('DD-MM-YYYY'))
                formikObj.setFieldValue("end_date", moment(standartDateFormat(beginingDates[0])).add(6, 'M').format('YYYY-MM-DD'))
            }
            else if (planPattern == 3) {
                setEndDate(moment(standartDateFormat(beginingDates[0])).add(3, 'M').format('DD-MM-YYYY'))
                formikObj.setFieldValue("end_date", moment(standartDateFormat(beginingDates[0])).add(3, 'M').format('YYYY-MM-DD'))
            }
            else if (planPattern == 4) {
                setEndDate(moment(standartDateFormat(beginingDates[0])).add(1, 'M').format('DD-MM-YYYY'))
                formikObj.setFieldValue("end_date", moment(standartDateFormat(beginingDates[0])).add(1, 'M').format('YYYY-MM-DD'))
            }
            else {
            }
        } else {
            setBeginingDate('')
            formikObj.setFieldValue("beginning_date", '')
        }

    }

    const endDateChange = (endDates) => {
        if (!!endDates[0]) {
            setEndDate(endDates[0].getDate() + '-' + (endDates[0].getMonth() + 1) + '-' + endDates[0].getFullYear())
            formikObj.setFieldValue("end_date", standartDateFormat(endDates[0]))
        } else {
            setEndDate('')
            formikObj.setFieldValue("end_date", '')
        }
    }

    const handleDiscountChange = (e) => {
        if (/^[0-9]+$/g.test(e.target.value) || e.target.value == '') {
            formikObj.setFieldValue(e.target.name, e.target.value)
        }
    }

    useEffect(() => {

        if (!!formikObj.values.member_id) {
            fetchMemberListData()
            setTimeout(() => {
                let memberArr = allMember.filter(item => item.id == formikObj.values.member_id)
                !!memberArr[0]?.member_access_code ? formikObj.setFieldValue("member_access_code", memberArr[0]?.member_access_code) : formikObj.setFieldValue("member_access_code", "")
            }, 3000);

        }
    }, [formikObj.values.member_id])

    useEffect(() => {
        formikObj.setFieldValue("total_amount", "")
        formikObj.setFieldValue("discount", membershipData.discount)
        formikObj.setFieldValue("gst_amount", "")

        if (formikObj.values.subscription_plan_id !== '') {
            let subscription = allSubscriptionList.filter(item => item.id == formikObj.values.subscription_plan_id)
            editSubscriptionAmount = subscription[0]?.total_amount
            setSubscriptionAmount(subscription[0]?.total_amount)
            formikObj.setFieldValue("subscription_plan_charges", subscription[0]?.total_amount)
            editSubscriptionPlanType = subscription[0]?.plan_type.id
            setSubscriptionPlanType(subscription[0]?.plan_type.id)
            let planPattern = subscription[0]?.plan_pattern.id
            setPlanPattern(subscription[0]?.plan_pattern.id)
            if (subscription[0]?.plan_type.id == 1) {
                let amount = parseFloat(subscription[0]?.total_amount)
                let gst_amount = amount * gst_rate
                formikObj.setFieldValue('gst_amount', gst_amount)
                amount += gst_amount
                formikObj.setFieldValue("total_amount", round(amount))
            }

            if (!!membershipData.id == false) {
                // Adjust Dates
                setBeginingDate('')
                setMinDate(moment(new Date()).format("DD-MM-YYYY"))
                formikObj.setFieldValue("beginning_date", moment(new Date()).format("YYYY-MM-DD"))
                if (planPattern == 1) {
                    setEndDate(moment().add(1, 'Y').format('DD-MM-YYYY'))
                    formikObj.setFieldValue("end_date", moment().add(1, 'Y').format('YYYY-MM-DD'))
                }
                else if (planPattern == 2) {
                    setEndDate(moment().add(6, 'M').format('DD-MM-YYYY'))
                    formikObj.setFieldValue("end_date", moment().add(6, 'M').format('YYYY-MM-DD'))
                }
                else if (planPattern == 3) {
                    setEndDate(moment().add(3, 'M').format('DD-MM-YYYY'))
                    formikObj.setFieldValue("end_date", moment().add(3, 'M').format('YYYY-MM-DD'))
                }
                else if (planPattern == 4) {
                    setEndDate(moment().add(1, 'M').format('DD-MM-YYYY'))
                    formikObj.setFieldValue("end_date", moment().add(1, 'M').format('YYYY-MM-DD'))
                }
                else {
                }
            }
        }
    }, [formikObj.values.subscription_plan_id])

    useEffect(() => {
        if (formikObj.values.number_of_sessions != '') {
            let amount = (parseInt(formikObj.values.number_of_sessions) * parseFloat(formikObj.values.subscription_plan_charges))
            sub_total = amount
            let gst_amount = amount * gst_rate
            formikObj.setFieldValue('gst_amount', gst_amount)
            editGrossAmount = amount
            setGrossAmount(amount)
            amount += gst_amount
            formikObj.setFieldValue("total_amount", round(amount))
        }
    }, [formikObj.values.number_of_sessions])

    const checkDiscountAmount = (amount) => {
        if (parseInt(formikObj.values.discount) > amount) {
            setDiscountErrorMsg('Discount amount cannot be grater than Subscription Amount')
            setIsSubmit(false)
            return false
        }
        else {
            setDiscountErrorMsg('')
            setIsSubmit(true)
            return true
        }
    }

    useEffect(() => {
        let amount = ''
        if (!!formikObj.values.discount) {
            if (editSubscriptionPlanType != 2) {
                if (checkDiscountAmount(parseInt(editSubscriptionAmount))) {
                    amount = parseInt(editSubscriptionAmount) - parseInt(formikObj.values.discount)
                }
            }
            else {
                if (checkDiscountAmount(editGrossAmount)) {
                    amount = editGrossAmount - parseInt(formikObj.values.discount)
                }

            }
        }
        else {
            if (editSubscriptionPlanType != 2) {
                amount = parseInt(editSubscriptionAmount)
            } else {
                amount = parseInt(editGrossAmount)
            }
        }
        formikObj.setFieldValue('gst_amount', (amount * gst_rate))
        amount += amount * gst_rate
        formikObj.setFieldValue("total_amount", round(amount))

    }, [formikObj.values.discount])

    const handleStartTimeChange = (timeChange) => {
        formikObj.setFieldValue("gym_timing_from", timeChange[0].getHours() + ":" + timeChange[0].getMinutes())
    }
    const handleEndTimeChange = (timeChange) => {
        formikObj.setFieldValue("gym_timing_to", timeChange[0].getHours() + ":" + timeChange[0].getMinutes())
    }

    const handlePreferredTimingChange = (e) => {
        formikObj.setFieldValue(e.target.name, e.target.value)
    }

    const handleFloorChange = (e) => {
        let value = parseInt(e.target.value)
        if (e.target.checked) {
            let index = floorArr.indexOf(value)
            floorArr.splice(index, 1)
        }
        else {
            floorArr.push(value);
        }
        formikObj.setFieldValue(e.target.name, floorArr)
    }

    const handleMemberIdSearch = (searchMemberId) => {
        setSearchMemberId(searchMemberId);
        formikObj.setFieldValue('member_id', searchMemberId.value)
    }

    return (
        <React.Fragment>
            <Modal
                isOpen={addEditMembershipModal}
                toggle={closeModal}
                size="lg"
                backdrop="static"
            // className={props.className}
            >
                <ModalHeader toggle={closeModal} tag="h4">
                    Edit Membership Plan
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-InputService">Member <span className="text-danger">*</span></Label>
                                    {/* <Input
                                        type="select"
                                        id="formrow-serviceType"
                                        className="form-control"
                                        name={'member_id'}
                                        disabled
                                        onChange={formikObj.handleChange}
                                        value={formikObj.values.member_id}
                                        invalid={
                                            formikObj.touched.member_id && formikObj.errors.member_id ? true : false
                                        }
                                    >
                                        <option value="">Select</option>
                                        {allMemberList.map((item) => (
                                            <option value={item.id} key={item.id}>{item.member_name}</option>
                                        ))}

                                    </Input> */}
                                    <Select
                                        name={'member_id'}
                                        value={searchMemberId}
                                        onChange={(member) => {
                                            handleMemberIdSearch(member);
                                        }}
                                        options={tempMemberArr}
                                        classNamePrefix="select2-selection"
                                    />
                                    {formikObj.touched.member_id && formikObj.errors.member_id ? (
                                        <FormFeedback type="invalid">{formikObj.errors.member_id}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-serviceType">Subscription Plan <span className="text-danger">*</span></Label>
                                    <Input
                                        type="select"
                                        id="formrow-serviceType"
                                        className="form-control"
                                        name={'subscription_plan_id'}
                                        onChange={formikObj.handleChange}
                                        value={formikObj.values.subscription_plan_id}
                                        invalid={
                                            formikObj.touched.subscription_plan_id && formikObj.errors.subscription_plan_id ? true : false
                                        }
                                    >
                                        <option defaultValue>Select</option>
                                        {allSubscriptionList.map((item) => (
                                            <option value={item.id} key={item.id}>{item.plan_name}&nbsp;({item.plan_pattern.plan_pattern_name})</option>
                                        ))}

                                    </Input>
                                    {formikObj.touched.subscription_plan_id && formikObj.errors.subscription_plan_id ? (
                                        <FormFeedback type="invalid">{formikObj.errors.subscription_plan_id}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-startDate-Input">Start Date <span className="text-danger">*</span></Label>
                                    <Flatpickr
                                        className="form-control d-block"
                                        name="beginning_date"
                                        placeholder="DD-MM-YYYY"
                                        options={{
                                            altInput: true,
                                            altFormat: "d-m-Y",
                                            dateFormat: "d-m-Y",
                                            // minDate: today
                                        }}
                                        onChange={beginingDateChange}
                                        value={beginning_date}
                                    />
                                    {formikObj.touched.beginning_date && formikObj.errors.beginning_date ? (
                                        <div style={{ fontSize: "smaller", color: "#f46a6a" }}>{formikObj.errors.beginning_date}</div>
                                    ) : null}
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-endDate-Input">End Date <span className="text-danger">*</span></Label>
                                    <React.Fragment>
                                        <Flatpickr
                                            className="form-control d-block"
                                            name="end_date"
                                            placeholder="DD-MM-YYYY"
                                            options={{
                                                altInput: true,
                                                altFormat: "d-m-Y",
                                                dateFormat: "d-m-Y",
                                                // minDate: today
                                            }}
                                            onChange={endDateChange}
                                            value={end_date}
                                        />
                                        {formikObj.touched.end_date && formikObj.errors.end_date ? (
                                            <div style={{ fontSize: "smaller", color: "#f46a6a" }}>{formikObj.errors.end_date}</div>
                                        ) : null}
                                    </React.Fragment>
                                    {/* {planPattern == 5 ?
                                        <React.Fragment>
                                            <Flatpickr
                                                className="form-control d-block"
                                                name="end_date"
                                                placeholder="DD-MM-YYYY"
                                                options={{
                                                    altInput: true,
                                                    altFormat: "d-m-Y",
                                                    dateFormat: "d-m-Y",
                                                    minDate: today
                                                }}
                                                onChange={endDateChange}
                                                value={end_date}
                                            />
                                            {formikObj.touched.end_date && formikObj.errors.end_date ? (
                                                <div style={{ fontSize: "smaller", color: "#f46a6a" }}>{formikObj.errors.end_date}</div>
                                            ) : null}
                                        </React.Fragment>
                                        :
                                        <Input
                                            type="text"
                                            name="end_date"
                                            placeholder="DD-MM-YYYY"
                                            value={end_date}
                                            readOnly
                                        />
                                    } */}

                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-InputService">Preferred Timing</Label>&nbsp;&nbsp;
                                    {preferTimingType.map((item, index) => (
                                        <div key={index} className="form-check form-check-inline font-size-16">
                                            <Input
                                                type="radio"
                                                value={item.id}
                                                id="customRadioInline1"
                                                name="preferred_timing"
                                                className="form-check-input"
                                                onClick={(e) => { handlePreferredTimingChange(e) }}
                                                checked={item.id == formikObj.values.preferred_timing ? true : false}
                                            />
                                            <Label
                                                className="form-check-label font-size-13"
                                                htmlFor="customRadioInline1"
                                            >
                                                {item.preferred_timing_name}
                                            </Label>
                                        </div>
                                    ))
                                    }
                                    {/* {selectPlanType == false && <span style={{ color: "red", fontSize: "10px" }}>Please Select Plan Type</span>} */}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-InputService">Time From</Label>
                                    <Flatpickr
                                        className="form-control d-block"
                                        name="gym_timing_from"
                                        options={{
                                            noCalendar: true,
                                            enableTime: true,
                                            dateFormat: 'h:i K',
                                            // minTime: formikObj.values.preferred_timing == 2 ? '10:0' : '',
                                            // maxTime: formikObj.values.preferred_timing == 2 ? '16:0' : ''
                                        }}
                                        onChange={handleStartTimeChange}
                                        value={formikObj.values.gym_timing_from}
                                    />
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-InputService">Time To</Label>
                                    <Flatpickr
                                        className="form-control d-block"
                                        name="gym_timing_to"
                                        options={{
                                            noCalendar: true,
                                            enableTime: true,
                                            dateFormat: 'h:i K',
                                            // minTime: formikObj.values.preferred_timing == 2 ? '10:0' : '',
                                            // maxTime: formikObj.values.preferred_timing == 2 ? '16:0' : ''
                                        }}
                                        onChange={handleEndTimeChange}
                                        value={formikObj.values.gym_timing_to}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-InputService">Floor</Label>&nbsp;&nbsp;
                                    {allFloorTypeList.map((item, index) => (
                                        <div className="form-check mb-3 form-check-inline" key={index}>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="defaultCheck1"
                                                name="floor"
                                                onClick={(e) => { handleFloorChange(e) }}
                                                // onChange={formikObj.handleChange}
                                                value={item.id}
                                                checked={formikObj.values?.floor?.indexOf(item.id) !== -1}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="defaultCheck1"
                                            >
                                                {item.floor_name}
                                            </label>
                                        </div>
                                    ))
                                    }
                                </div>
                            </Col>
                            {subscriptionPlanType == 2 &&
                                <Col lg={6}>
                                    <div className="mb-3">
                                        <Label htmlFor="formrow-InputService">Quantity</Label>
                                        <Input
                                            type="text"
                                            id="formrow-InputService"
                                            className="form-control"
                                            name="number_of_sessions"
                                            onChange={(e) => { handleSessionsChange(e) }}
                                            value={formikObj.values.number_of_sessions}
                                            placeholder="Enter Quantity"
                                            invalid={
                                                formikObj.touched.number_of_sessions && formikObj.errors.number_of_sessions ? true : false
                                            }
                                        />
                                        {formikObj.touched.number_of_sessions && formikObj.errors.number_of_sessions ? (
                                            <FormFeedback type="invalid">{formikObj.errors.number_of_sessions}</FormFeedback>
                                        ) : null}
                                    </div>
                                </Col>
                            }
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-InputService">Access Card No.<span className="text-danger">*</span></Label>
                                    <Input
                                        type="text"
                                        id="formrow-InputService"
                                        className="form-control"
                                        name="member_access_code"
                                        onChange={formikObj.handleChange}
                                        value={formikObj.values.member_access_code}
                                        placeholder="Enter Access Card No."
                                        invalid={
                                            formikObj.touched.member_access_code && formikObj.errors.member_access_code ? true : false
                                        }
                                    />
                                    {formikObj.touched.member_access_code && formikObj.errors.member_access_code ? (
                                        <FormFeedback type="invalid">{formikObj.errors.member_access_code}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={editSubscriptionPlanType == 2 ? 4 : 6}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-InputService">Subscription Plan Charges</Label>
                                    <Input
                                        type="text"
                                        id="formrow-InputService"
                                        className="form-control"
                                        name="subscription_plan_charges"
                                        onChange={formikObj.handleChange}
                                        value={formikObj.values.subscription_plan_charges}
                                        placeholder="Enter Subscription Plan Charges"
                                        invalid={
                                            formikObj.touched.subscription_plan_charges && formikObj.errors.subscription_plan_charges ? true : false
                                        }
                                        readOnly
                                    />
                                    {formikObj.touched.subscription_plan_charges && formikObj.errors.subscription_plan_charges ? (
                                        <FormFeedback type="invalid">{formikObj.errors.subscription_plan_charges}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            {editSubscriptionPlanType == 2 &&
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label htmlFor="formrow-InputService">Sub Total</Label>
                                        <Input
                                            type="text"
                                            id="formrow-InputService"
                                            className="form-control"
                                            value={sub_total}
                                            placeholder="Sub Total"
                                            readOnly
                                        />
                                    </div>
                                </Col>
                            }
                            <Col lg={editSubscriptionPlanType == 2 ? 4 : 6}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-InputService">Discount</Label>
                                    <Input
                                        type="text"
                                        id="formrow-InputService"
                                        className="form-control"
                                        name="discount"
                                        // onChange={formikObj.handleChange}
                                        onChange={(e) => { handleDiscountChange(e) }}
                                        value={formikObj.values.discount}
                                        placeholder="Enter Discount"
                                        invalid={
                                            formikObj.touched.discount && formikObj.errors.discount ? true : false
                                        }
                                    />
                                    <span className="text-danger">{discountErrorMsg}</span>
                                    {formikObj.touched.discount && formikObj.errors.discount ? (
                                        <FormFeedback type="invalid">{formikObj.errors.subscription_plan_charges}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-InputService">GST @18%</Label>
                                    <Input
                                        type="text"
                                        id="formrow-InputService"
                                        className="form-control"
                                        name="gst_amount"
                                        onChange={formikObj.handleChange}
                                        value={formikObj.values.gst_amount}
                                        placeholder="Enter GST"
                                        invalid={
                                            formikObj.touched.gst_amount && formikObj.errors.gst_amount ? true : false
                                        }
                                        readOnly
                                    />
                                    {formikObj.touched.gst_amount && formikObj.errors.gst_amount ? (
                                        <FormFeedback type="invalid">{formikObj.errors.subscription_plan_charges}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>

                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-InputService">Total Amount</Label>
                                    <Input
                                        type="text"
                                        id="formrow-InputService"
                                        className="form-control"
                                        name="total_amount"
                                        // onChange={formikObj.handleChange}
                                        value={formikObj.values.total_amount}
                                        placeholder="Enter Total Amount"
                                        invalid={
                                            formikObj.touched.total_amount && formikObj.errors.total_amount ? true : false
                                        }
                                        readOnly
                                    />
                                    {formikObj.touched.total_amount && formikObj.errors.total_amount ? (
                                        <FormFeedback type="invalid">{formikObj.errors.subscription_plan_charges}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-serviceType">Select Payment<span className="text-danger">*</span></Label>
                                    <Input
                                        type="select"
                                        id="formrow-serviceType"
                                        className="form-control"
                                        name={'payment_mode_id'}
                                        onChange={formikObj.handleChange}
                                        value={formikObj.values.payment_mode_id}
                                        invalid={
                                            formikObj.touched.payment_mode_id && formikObj.errors.payment_mode_id ? true : false
                                        }
                                    >
                                        <option value="">Select</option>
                                        {paymenyType.map((item) => (
                                            <option value={item.id} key={item.id}>{item.payment_mode}</option>
                                        ))}

                                    </Input>
                                    {formikObj.touched.payment_mode_id && formikObj.errors.payment_mode_id ? (
                                        <FormFeedback type="invalid">{formikObj.errors.payment_mode_id}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <div className="modal-footer">
                    {!!isSaving ?
                        <button className="btn btn-primary " disabled>
                            Processing
                        </button>
                        :
                        <>
                            <button
                                type="button"
                                onClick={closeModal}
                                className="btn btn-secondary "
                                data-dismiss="modal"
                            >
                                Close
                            </button>
                            <button
                                type="submit"
                                className="btn btn-primary "
                                onClick={(e) => {
                                    e.preventDefault();
                                    formikObj.handleSubmit();
                                    return false;
                                }}
                                disabled={!!isSubmit ? false : true}
                            >
                                Update
                            </button>
                        </>
                    }
                </div>
            </Modal>
        </React.Fragment>
    )
}


export default withRouter(EditMembership);

EditMembership.propTypes = {
    history: PropTypes.object,
    membership_id: PropTypes.number
};