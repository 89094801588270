import React from "react"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types";
import AddEditBodyComposition from "pages/Common/AddEditBodyComposition";

const ManageBodyComposition = () => {
    return (
        <React.Fragment>
            <AddEditBodyComposition
                member_id={null}
                bodyCompositionModal={false}
                redirectPath="/manage-body-composition"
            />
        </React.Fragment>
    );
}

export default withRouter(ManageBodyComposition);

ManageBodyComposition.propTypes = {
    history: PropTypes.object,
}