import { generateSearchQuery } from "utils/graphql"

// FETCH DIET CATEGORY
export const DIET_CATEGORY_QUERY = `query dietCategory($perPage: Int!, $currentPage: Int){
    diet_plan_categories(
        first:$perPage, page:$currentPage,
        orderBy : {column:_category_name order:ASC}
    ) 
    {
        data
        {
            id
            category_name
        }
        paginatorInfo 
        {
            currentPage
            total
            perPage
        }
    }
}
`

// FETCH ALL DIET PLAN
const searchQry = generateSearchQuery(['plan_name'])
export const FETCH_ALL_DIET_PLAN_QUERY = `query allDietPlan($perPage: Int!, $currentPage: Int, $id: Mixed, $searchText:Mixed)
{
    diet_plans(
        first:$perPage, page:$currentPage,
        where:{ AND:{column: _id operator:LIKE value: $id} 
                 ${searchQry}
        }
        orderBy:{column:_id, order:DESC}
    ) 
    {
        data {
            id
            plan_name
            diet_plan_category_id
            dietitian_name
            daily_ration_nutrients
            daily_ration_foods
            foods_to_avoid
            issues
            diet_goal
            pulpy_seasonal_foods
            citrus_fruits
            note
            diet_plan_category_details {
                id
                category_name
            }
            diet_plan_details {
                id
                meal
                time
            }
        }
        paginatorInfo {
            currentPage
            total
            perPage
        }
    }
}
`

// FETCH ALL DIET PLAN TO ASSIGN
export const FETCH_ALL_DIET_PLAN_TO_ASSIGN_QUERY = `query allDietPlanToAssign
{
    diet_plans(
        orderBy:{column:_id, order:DESC}
    ) 
    {
        data {
            id
            plan_name
            diet_plan_category_id
            dietitian_name
            daily_ration_nutrients
            daily_ration_foods
            foods_to_avoid
            issues
            diet_goal
            pulpy_seasonal_foods
            citrus_fruits
            note
            diet_plan_category_details {
                id
                category_name
            }
            diet_plan_details {
                id
                meal
                time
            }
        }
        paginatorInfo {
            currentPage
            total
            perPage
        }
    }
}
`

// FETCH MEMBER WISE ASSIGNED DIET PLAN LSIT
const dietSearchQry = generateSearchQuery(['plan_name'])
export const FETCH_MEMBER_WISE_ASSIGNED_DIET_PLAN_QUERY = `query allMemberwiseAssignedDietPlan($perPage: Int!, $currentPage: Int, $member_id: Mixed, $searchText:Mixed)
{
    member_wise_diet_plans(
        first:$perPage, page:$currentPage,
        where:{ AND:{column: _member_id operator:LIKE value: $member_id} 
                 ${dietSearchQry}
        }
        orderBy:{column:_id, order:DESC}
    ) 
    {
        data {
            id
            plan_name
            member_id
            note
            daily_ration_nutrients
            daily_ration_foods
            foods_to_avoid
            issues
            diet_goal
            pulpy_seasonal_foods
            citrus_fruits
            member_details{
                member_name
            }
            dietitian_name
            diet_plan_category_id
            diet_plan_category_details {
              id
              category_name
            }
            diet_plan_details {
              id
              meal
              time
            }
        }
        paginatorInfo {
            currentPage
            total
            perPage
        }
    }
}
`


export const FETCH_MEMBER_WISE_ASSIGNED_DIET_PLAN_EDIT_QUERY = `query allMemberwiseAssignedDietPlanEdit($perPage: Int!, $currentPage: Int, $id: Mixed, $searchText:Mixed)
{
    member_wise_diet_plans(
        first:$perPage, page:$currentPage,
        where:{ AND:{column: _id operator:LIKE value: $id} 
                 ${dietSearchQry}
        }
        orderBy:{column:_id, order:DESC}
    ) 
    {
        data {
            id
            plan_name
            daily_ration_nutrients
            daily_ration_foods
            foods_to_avoid
            issues
            diet_goal
            pulpy_seasonal_foods
            citrus_fruits
            member_id
            member_details{
                member_name
            }
            dietitian_name
            note
            next_review_date
            diet_plan_category_id
            diet_plan_category_details {
              id
              category_name
            }
            diet_plan_details {
              id
              meal
              time
            }
        }
        paginatorInfo {
            currentPage
            total
            perPage
        }
    }
}
`


export const FETCH_MEMBER_WISE_ASSIGNED_DIET_PLAN_VIEW_QUERY = `query memberwiseAssignedDietPlanView($perPage: Int!, $currentPage: Int, $id: Mixed)
{
    member_wise_diet_plans(
        first:$perPage, page:$currentPage,
        where:{ AND:{column: _id operator:LIKE value: $id} 
        }
        orderBy:{column:_id, order:DESC}
    ) 
    {
        data {
            id
            plan_name
            member_id
            member_details{
                member_name
            }
            dietitian_name
            note
            daily_ration_nutrients
            daily_ration_foods
            foods_to_avoid
            issues
            diet_goal
            pulpy_seasonal_foods
            citrus_fruits
            diet_plan_category_id
            diet_plan_category_details {
              id
              category_name
            }
            diet_plan_details {
              id
              meal
              time
            }
        }
        paginatorInfo {
            currentPage
            total
            perPage
        }
    }
}
`