import React, { useEffect, useState } from 'react'
import { withRouter, useLocation, Link, useParams } from 'react-router-dom'
import PropTypes, { any } from "prop-types";
import MetaTags from "react-meta-tags";
import {
    Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label, Button, Modal,
    ModalBody,
    ModalHeader,
    InputGroup,
    Spinner,
    CardTitle,
    Collapse,
    Table
} from "reactstrap";
import { apiUrls, axiosInstance, graphql } from 'api';
import usePaginator from "hooks/usePaginator";
import * as BodyCompositionQry from "GraphQLquery/bodycompositionQry";
import moment from 'moment';

const BodyCompositionView = props => {
    const [bodyCompositionDetails, setBodyCompositionDetails] = useState(null)
    const [error, setError] = useState(null);
    const [isPageLoading, setPageLoading] = useState(true);
    const [paginatorInfo, setPaginatorInfo, resetPaginatorInfo] = usePaginator();
    const [searchText, setSearchText] = useState('');

    const member_id = localStorage.getItem("bc_member_id")
    const id = localStorage.getItem("bc_id")

    useEffect(async () => {
        setPageLoading(true);
        const res = !!member_id ?
            await graphql({
                query: BodyCompositionQry.FETCH_ALL_BODY_COMPOSITION_QUERY,
                variables: {
                    ...paginatorInfo,
                    searchText: `%%`,
                    member_id: member_id
                }
            })
            :
            await graphql({
                query: BodyCompositionQry.FETCH_BODY_COMPOSITION_EDIT_QUERY,
                variables: {
                    ...paginatorInfo,
                    searchText: `%%`,
                    id: id
                }
            })

        try {
            if (!!res.data.body_compositions && res.data.body_compositions.data) {
                setBodyCompositionDetails(!!res.data.body_compositions ? res.data.body_compositions.data : [])
                setPageLoading(false)
            }
            else {
                setBodyCompositionDetails([])
                swal("Oops!", "Something went wrong!", "error");
                setPageLoading(false)
            }
        } catch (err) {
            setBodyCompositionDetails([])
            swal("Oops!", "Something went wrong!", "error");
            setError(err.message)
            setPageLoading(false)
        }
    }, [])

    const printWorkout = () => {
        window.print();
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Body Composition View</title>
                </MetaTags>
                {isPageLoading ?
                    <Row>
                        <Col lg={12} style={{ textAlign: "center" }}>
                            <h5><i className="bx bx-hourglass bx-spin font-size-24 align-middle me-2"></i>
                                Loading...</h5>
                        </Col>
                    </Row>
                    :
                    <Container fluid={true} style={{ marginTop: "-60px" }}>
                        <div className="d-print-none">
                            <div className="d-flex justify-content-end">
                                <Link
                                    to="#"
                                    onClick={printWorkout}
                                    className="btn btn-success  me-2"
                                >
                                    <i className="fa fa-print" />
                                </Link>
                            </div>
                        </div>
                        <div id="printSection">
                            <div className="main-content-wrapper">
                                <div className="main-contents pb-1">
                                    <div className="m-4">
                                        <header className="header-home mb-2">
                                            <div>
                                                <p>
                                                    <React.Fragment>
                                                        <span className="member-name">
                                                            {bodyCompositionDetails[0]?.member_details?.member_name}
                                                        </span>&nbsp;&nbsp;
                                                        <span className="badge rounded-pill" style={{ backgroundColor: "#DC2450", fontSize: "18px" }}>
                                                            ID:{bodyCompositionDetails[0]?.member_id}
                                                        </span>
                                                    </React.Fragment>
                                                </p>
                                                <a href="#" className="logo"><img
                                                    src="https://www.flexym.com/assets/images/Flexym-logo.svg" className="inline-block"
                                                    alt="Flexym Logo" />
                                                </a>
                                            </div>
                                        </header>

                                        {!!bodyCompositionDetails &&
                                            <React.Fragment>
                                                <section className="content-heading">
                                                    <h4>Body Composition Scale</h4>
                                                </section>

                                                <section className="content-details">
                                                    <Row>
                                                        <Col lg={12}>
                                                            <div className="table-responsive">
                                                                <Table className="table mb-0">
                                                                    <thead>
                                                                        <tr className="table-info">
                                                                            <th>DATE</th>
                                                                            <th>HEIGHT</th>
                                                                            <th>WEIGHT</th>
                                                                            <th>BODY FAT %</th>
                                                                            <th>VISCERAL FAT</th>
                                                                            <th>RMR</th>
                                                                            <th>BMI</th>
                                                                            <th>BODY AGE</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {bodyCompositionDetails.map((item, index) => {
                                                                            return (
                                                                                <tr key={index}>
                                                                                    <td>{!!item.entry_date ? moment(item.entry_date).format('DD-MM-YYYY') : '-'}</td>
                                                                                    <td>{item.body_height}{" cm"}</td>
                                                                                    <td>{item.body_weight}{" Kg"}</td>
                                                                                    <td>{item.body_fat_percentage}</td>
                                                                                    <td>{item.visceral_fat_percentage}</td>
                                                                                    <td>{item.resting_metabolic_rate}</td>
                                                                                    <td>{item.body_mass_index}</td>
                                                                                    <td>{item.body_age}</td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                        }
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </section>

                                                <section className="content-heading">
                                                    <h4>Body Composition Standards</h4>
                                                </section>

                                                <section className="content-details">
                                                    <Row>
                                                        <Col lg={12}>
                                                            <div className="table-responsive">
                                                                <Table className="table mb-0">
                                                                    <thead>
                                                                        <tr>
                                                                            <th className="table-info"></th>
                                                                            <th className="table-info text-uppercase">Essential</th>
                                                                            <th className="table-info text-uppercase">Athletes</th>
                                                                            <th className="table-info text-uppercase">Fitness</th>
                                                                            <th className="table-info text-uppercase">Average</th>
                                                                            <th className="table-info text-uppercase">Obes</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {/* {bodyCompositionDetails.map((item, index) => {
                                                                            return (
                                                                                <tr key={index}>
                                                                                    <td>{!!item.entry_date ? moment(item.entry_date).format('DD-MM-YYYY') : '-'}</td>
                                                                                    <td>{item.body_height}{" cm"}</td>
                                                                                    <td>{item.body_weight}{" Kg"}</td>
                                                                                    <td>{item.body_fat_percentage}</td>
                                                                                    <td>{item.visceral_fat_percentage}</td>
                                                                                    <td>{item.resting_metabolic_rate}</td>
                                                                                    <td>{item.body_mass_index}</td>
                                                                                    <td>{item.body_age}</td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                        } */}
                                                                        <tr>
                                                                            <td className="font-semibold">Body Fat % (Man)</td>
                                                                            <td>2-5%</td>
                                                                            <td>6-13%</td>
                                                                            <td>14-17%</td>
                                                                            <td>18-24%</td>
                                                                            <td>25%+</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="font-semibold">Body Fat % (Woman)</td>
                                                                            <td>10-13%</td>
                                                                            <td>14-20%</td>
                                                                            <td>21-24%</td>
                                                                            <td>25-31%</td>
                                                                            <td>32%+</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="table-info"></td>
                                                                            <td className="table-info font-semibold text-uppercase">Normal</td>
                                                                            <td className="table-info font-semibold text-uppercase">High</td>
                                                                            <td className="table-info font-semibold text-uppercase">Very High</td>
                                                                            <td className="table-info font-semibold text-uppercase"></td>
                                                                            <td className="table-info font-semibold text-uppercase"></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="font-semibold">Visceral Fat Level</td>
                                                                            <td>1-9</td>
                                                                            <td>10-14</td>
                                                                            <td>15-30</td>
                                                                            <td></td>
                                                                            <td></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="table-info font-semibold text-uppercase"></td>
                                                                            <td className="table-info font-semibold text-uppercase">Underweight</td>
                                                                            <td className="table-info font-semibold text-uppercase">Normal</td>
                                                                            <td className="table-info font-semibold text-uppercase">Over Weight</td>
                                                                            <td className="table-info font-semibold text-uppercase">Obes Class1</td>
                                                                            <td className="table-info font-semibold text-uppercase">Obes Class2</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="font-semibold">BMI</td>
                                                                            <td>&gt;18.5</td>
                                                                            <td>18.5-24.9</td>
                                                                            <td>25.0-29.9</td>
                                                                            <td>30.0-34.9</td>
                                                                            <td></td>
                                                                        </tr>
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </section>
                                            </React.Fragment>

                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Container>
                }
            </div>
        </React.Fragment>
    )
}

export default withRouter(BodyCompositionView);

BodyCompositionView.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
};