import { generateSearchQuery } from "utils/graphql"

// FETCH EXERCISE TYPES
export const EXERCISE_TYPES_QUERY = `query exercisetypes{
    exercise_types 
    {
        id
        exercise_type_name
    }
}
`
// FETCH WORKOUT PLAN CATEGORY
export const WORKOUT_PLAN_CATEGORY_QUERY = `query workoutPlanCategory{
    workout_plan_categories
    {
     data 
        {
            id
            category_name
            created_at
        }
    }
}
`

// FETCH ALL EXERCISES LIST
const searchQry = generateSearchQuery(['exercise_name'])
export const FETCH_ALL_EXERCISES_QUERY=`query allExercises($perPage: Int!, $currentPage: Int, $id: Mixed, $searchText:Mixed)
{
    workout_plan_exercises(
        first:$perPage, page:$currentPage,
        where:{ AND:{column: _id operator:LIKE value: $id} 
                 ${searchQry}
        }
        orderBy:{column:_id, order:DESC}
    ) 
    {
        data {
            id
            exercise_description
            exercise_video
            target_muscles
            workout_category {
                id
                category_name
            }  
            exercise_type {
                id
                exercise_type_name
            }
            exercise_name
        }
        paginatorInfo {
            currentPage
            total
            perPage
        }
    }
}
`

// FETCH ALL DEPENDING EXERCISES LIST
export const FETCH_ALL_EXERCISES_DEPENDING_ON_WORKOUT_CATEGORY_QUERY=`query allDependingExercises($workout_plan_category_id: Mixed)
{
    workout_plan_exercises(
        where:{ AND:{column: _workout_plan_category_id operator:LIKE value: $workout_plan_category_id} 
        }
        orderBy:{column:_id, order:DESC}
    ) 
    {
        data {
            id
            workout_category {
                id
                category_name
            }  
            exercise_type {
                id
                exercise_type_name
            }
            exercise_name
        }
    }
}
`

// FETCH ALL OR VIEW A WORKOUT PLAN LIST
const workoutSearchQry = generateSearchQuery(['workout_plan_name'])
export const FETCH_ALL_WORKOUT_PLAN_QUERY=`query allWorkoutPlanList($perPage: Int!, $currentPage: Int, $id: Mixed, $searchText:Mixed)
{
    workout_plans(
        first:$perPage, page:$currentPage,
        where:{ AND:{column: _id operator:LIKE value: $id} 
                 ${workoutSearchQry}
        }
        orderBy:{column:_id, order:DESC}
    ) 
    {
        data {
            id
            workout_plan_name
            day_one_target
            day_two_target
            day_three_target
            day_four_target
            day_five_target
            day_six_target
            description
            prepared_by
            prepared_by_id
            workout_plan_details {
              id
              workout_plan_id
              
              workout_plan_category_details {
                id
                category_name
              }
              
              workout_plan_exercise_details {
                id
                exercise_name
                exercise_type {
                  id
                  exercise_type_name
                }
              }
              no_of_set
              no_of_rep
              duration
              duration_type
              day
            }
            created_at
        }
        paginatorInfo {
            currentPage
            total
            perPage
        }
    }
}
`


// FETCH WORKOUT PLAN FOR EDIT
export const FETCH_WORKOUT_PLAN_FOR_EDIT_QUERY=`query editWorkoutPlanList($id: Mixed)
{
    workout_plans(
        where:{ AND:{column: _id operator:LIKE value: $id} }
        orderBy:{column:_id, order:DESC}
    ) 
    {
        data {
            id
            workout_plan_name
            day_one_target
            day_two_target
            day_three_target
            day_four_target
            day_five_target
            day_six_target
            description
            prepared_by
            prepared_by_id
            workout_plan_details(where : {column:_day operator:IN value:[1,2,3,4,5,6]}) {
              id
              workout_plan_id
              
              workout_plan_category_details {
                id
                category_name
              }
              
              workout_plan_exercise_details {
                id
                exercise_name
                exercise_type {
                  id
                  exercise_type_name
                }
              }
              no_of_set
              no_of_rep
              duration
              duration_type
              note
              day
              exercise_choice
            }
            created_at
        }
    }
}
`

// FETCH ALL WORKOUT PLAN FOR ASSIGNING A MEMBER
export const FETCH_ALL_WORKOUT_PLAN_TO_ASSIGN_MEMBER_QUERY=`query allWorkoutPlanListToAssign
{
    workout_plans(
        orderBy:{column:_id, order:DESC}
    ) 
    {
        data {
            id
            workout_plan_name
            day_one_target
            day_two_target
            day_three_target
            day_four_target
            description
            prepared_by
            workout_plan_details {
              id
              workout_plan_id
              
              workout_plan_category_details {
                id
                category_name
              }
              
              workout_plan_exercise_details {
                id
                exercise_name
                exercise_type {
                  id
                  exercise_type_name
                }
              }
              no_of_set
              no_of_rep
              duration
              duration_type
              day
            }
            created_at
        }
    }
}
`

// FETCH ASSIGNED MEMBER WORKOUT PLAN FOR EDIT
export const FETCH_ASSIGNED_MEMBER_WORKOUT_PLAN_FOR_EDIT_QUERY=`query editAssignedWorkoutPlanList($id: Mixed)
{
    memberwise_workout_plans(
        where:{ AND:{column: _id operator:LIKE value: $id} }
        orderBy:{column:_id, order:DESC}
    ) 
    {
        data {
            id
            workout_plan_name
            day_one_target
            day_two_target
            day_three_target
            day_four_target
            day_five_target
            day_six_target
            next_workout_chart_date
            description
            prepared_by
            prepared_by_id
            goal
            workout_plan_details(where : {column:_day operator:IN value:[1,2,3,4,5,6]}) {
              id
              workout_plan_id
              
              workout_plan_category_details {
                id
                category_name
              }
              
              workout_plan_exercise_details {
                id
                exercise_name
                exercise_type {
                  id
                  exercise_type_name
                }
              }
              no_of_set
              no_of_rep
              duration
              duration_type
              note
              day
              exercise_choice
            }
            created_at
        }
    }
}
`