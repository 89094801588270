import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useEffect, useState } from "react";
import {
    Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label, Button, Modal,
    ModalBody,
    ModalHeader,
    InputGroup,
    CardTitle,
    CardText,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Spinner,
    Collapse,
    CardHeader,
    Table
} from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import classnames from "classnames";
import Flatpickr from "react-flatpickr";

// Formik formikObj   
import * as Yup from "yup";
import { useFormik, FieldArray, FormikProvider } from "formik";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { today, standartDateFormat } from "utils/date";
import { axiosInstance, apiUrls } from "api";
import swal from 'sweetalert';
import { graphql } from "api";
import Select from "react-select";

// datatable related plugins

import CommonTable from "components/Common/CommonTable";
import usePaginator from "hooks/usePaginator";
import * as MemberQry from "GraphQLquery/memberQry";
import * as DietQry from "GraphQLquery/dietQry";
import * as TrainerQry from "GraphQLquery/trainerQry";

import moment from "moment";

import { useSelector, useDispatch } from "react-redux";
import { setDietAssignedMember } from "store/actions";
import { setTrainerAssignedMember } from "store/assignTrainerToMember/actions";

const initialTrainerData = {
    trainer_id: '',
    member_id: '',
    assign_date: '',
    trainer_type_id: '',
    main_trainer: '',
}

const AssignTrainerToMember = props => {
    const dispatch = useDispatch();
    const member_id = props.member_id;
    //const assignDietModal = props.assignDietModal;
    const assignTrainerModal = props.assignTrainerModal;
    const redirectPath = props.redirectPath;
    const [paginatorInfo, setPaginatorInfo, resetPaginatorInfo] = usePaginator();
    const [searchText, setSearchText] = useState('');
    const [trainers, setTrainers] = useState([])
    const [assignTrainerDetail, setAssignTrainerDetail] = useState([]);
    const [processingList, setProcessingList] = useState(false);
    const [trainerData, setTrainerData] = useState({ ...initialTrainerData })
    const [addEditAssignTrainerModal, setAddEditAssignTrainerModal] = useState(false);
    const [error, setError] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const [assignDate, setAssignDate] = useState('')
    const [memberId, setMemberId] = useState('')
    const [offSet, setOffSet] = useState('10')
    const [pageURL, setPageURL] = useState('1')
    const [pageLinks, setPageLinks] = useState([])
    const [edit, setEdit] = useState(false)
    const [trainerTypeList, setTrainerTypeList] = useState([])
    const [processingTrainerList, setProcessingTrainerList] = useState(false);
    const [allTrainerList, setAllTrainerList] = useState([]);
    const [options, setOptions] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);

    useEffect(() => {
        fetchAllTrainerListData()
        fetchMemberWiseTrainerList()
        fetchTrainerType()
        //editTrainerType()
        //fetchTrainer()
    }, [])


    useEffect(() => {
        !!assignTrainerModal && assignTrainer()
    }, [assignTrainerModal])

    const fetchMemberWiseTrainerList = async (id) => {
        let params
        if (id) {
            params = { offset: offSet, search_text: searchText, id: id }
        }
        else{
            params = { offset: offSet, search_text: searchText, member_id: member_id }
        }
        try {
            const res = await axiosInstance.post(apiUrls.trainer.assignedTrainerList, params);
            if (res.success == 1) {
                let data = !!res.data.data ? res.data?.data : []
                if (!!id) {
                    const trainerUpdatedData = {
                        id: data?.[0]?.id,
                        trainer_id: data?.[0]?.trainer_id,
                        member_id: data?.[0]?.member_id,
                        assign_date: data?.[0]?.assign_date,
                        trainer_type_id: data?.[0]?.trainer_type_id,
                        main_trainer: data?.[0]?.main_trainer,
                    }
                    setAssignDate(moment(data?.[0]?.assign_date).format('DD-MM-YYYY'))
                    setTrainerData(trainerUpdatedData)
                    setAddEditAssignTrainerModal(true);
                }
                else {
                    setAssignTrainerDetail(data)
                    setProcessingList(false)
                    setPageLinks(res.data.links)
                }
            }
        }
        catch (error) {
            setProcessingList(false)
        }
    }

    const fetchTrainerType = async () => {
        try {
            const res = await graphql({
                query: TrainerQry.FETCH_TRAINER_TYPE_QUERY
            });
            if (res.data.trainer_types) {
                let trainer_types = res?.data?.trainer_types || []
                setTrainerTypeList(trainer_types)
            } else {
                setTrainerTypeList([])
            }
        } catch (error) {
            setTrainerTypeList([])
        }
    }

    const fetchAllTrainerListData = async () => {
        try {
            const res = await graphql({
                query: TrainerQry.FETCH_ALL_TRAINER_LIST_QUERY,
                // variables: {
                //     ...paginatorInfo,
                //     searchText: `%${searchText}%`,
                //     id: "%%"
                // }
            });

            if (res.data.trainers.data) {
                let response = res.data.trainers.data
                setTrainers(response)
            }
        } catch (error) {
            setError(error)
        }
    }

    const handleAction = (cell, row) => {
        return (
            <>
                &nbsp;
                <Button className="btn-info btn-sm ms-2"
                    onClick={(e) => {
                        setEdit(true)
                        fetchMemberWiseTrainerList(row.id)
                    }}
                >
                    <i className="bx bx-pencil align-middle" />
                </Button>
                {!!member_id == true &&
                    <Button className="btn-danger btn-sm ms-2" onClick={(e) => { handleDelete(e, row) }}>
                        <i className="bx bx-trash align-middle" />
                    </Button>
                }
            </>
        );
    };

    const handleDelete = (e, row) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (willDelete) => {
                if (willDelete) {
                    try {
                        const response = await axiosInstance.post(apiUrls.trainer.deleteAssignTrainerToMember, { id: row.id });
                        if (response.success == 1) {
                            swal("Success!", response.message, "success");
                            fetchMemberWiseTrainerList()
                        }
                        else {
                            swal("Oops!", response.message, "error");
                        }
                    } catch (err) {
                        swal("Oops!", "Something went wrong!", "error");
                        setError(err.message)
                    }
                }
            });
    }

    const handleOffsetChange = (e) => {
        setOffSet(e.target.value)
    }

    const getPageNumber = (e, data) => {
        e.preventDefault()
        if (parseInt(data.label)) {
            setPageURL(data.label)
        } else {
            let pageNumber = data.url.split("=")
            setPageURL(pageNumber[1])
        }

    }

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    }

    useEffect(() => {
        fetchMemberWiseTrainerList()
    }, [offSet, pageURL, searchText])

    const formikObj = useFormik({
        enableReinitialize: true,

        initialValues: { ...trainerData },
        validationSchema: Yup.object({
            trainer_id: Yup.string().required("Please Select Trainer"),
        }),
        onSubmit: async (values) => {
            values.member_id = !!member_id ? member_id : values.member_id
            // return
            try {
                setIsSaving(true)
                const response = await axiosInstance.post(apiUrls.trainer.assignTrainerToMember, { ...values });
                if (response.success == 1) {
                    formikObj.resetForm()
                    setTrainerData({ ...initialTrainerData })
                    setIsSaving(false)
                    swal("Success!", response.message, "success");
                    closeModal()
                    dispatch(setTrainerAssignedMember(true));
                    fetchMemberWiseTrainerList()
                }
                else {
                    setIsSaving(false)
                    swal("Oops!", response.message, "error");
                }
            } catch (err) {
                setIsSaving(false)
                swal("Oops!", "Something went wrong!", "error");
                setError(err.message)
            }
        }
    });

    const fetchTrainers = async (trainer_type_id) => {
        try {
            setProcessingTrainerList(true)
            const res = await axiosInstance.post(apiUrls.trainer.fetchTrainerByTrainerTypeId, { trainer_type_id });
            if (res.success == 1) {
                if (res.data.length > 0) {
                    setProcessingTrainerList(false)
                    setAllTrainerList(!!res.data ? res.data : [])
                }
                else
                {
                    setProcessingTrainerList(false)
                    setAllTrainerList([])
                }
            }
            else{
                formikObj.setFieldValue("trainer_id","")
                setProcessingTrainerList(false)
                setAllTrainerList([])
            }
        } catch (error) {
            setProcessingTrainerList(false)
            setAllTrainerList([])
        }
    }

    useEffect(() => {
        if (formikObj.values.trainer_type_id !== "") {
            fetchTrainers(formikObj.values.trainer_type_id)
        }
    }, [formikObj.values.trainer_type_id])

    const assignTrainer = () => {

        formikObj.resetForm();
        openModal()
    }

    const closeModal = () => {
        setAddEditAssignTrainerModal(false);
        setEdit(false)
        dispatch(setTrainerAssignedMember(false));
    }

    const openModal = () => {
        formikObj.setFieldValue("assign_date", standartDateFormat(new Date()))
        setAddEditAssignTrainerModal(true);
    }

    const assignDateChange = (assignDate) => {
        if (!!assignDate[0]) {
            setAssignDate(assignDate[0].getDate() + '-' + (assignDate[0].getMonth() + 1) + '-' + assignDate[0].getFullYear())
            formikObj.setFieldValue("assign_date", standartDateFormat(assignDate[0]))
        } else {
            setAssignDate('')
            formikObj.setFieldValue("assign_date", '')
        }
    }

    const hadeleMainTrainerCheck = (e) => {
        if (e.target.checked) {
            formikObj.setFieldValue('main_trainer',0);
        } else {
            formikObj.setFieldValue('main_trainer',1);
        }
    }

    return (
        <React.Fragment>
            {/* Datatable view start */}
            <Row>
                <Col className="col-12"></Col>
                <Card>
                    <CardBody>

                        {assignTrainerDetail.length > 0 ?
                            <>
                                <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                        <Label for="search-bar-0" className="search-label">
                                            <span id="search-bar-0-label" className="sr-only">Search this table</span>
                                            <Input id="search-bar-0"
                                                type="text"
                                                aria-labelledby="search-bar-0-label"
                                                className="form-control"
                                                placeholder="Search"
                                                // value=""
                                                onChange={(e) => handleSearch(e)}
                                            />
                                        </Label>
                                        <i className="bx bx-search-alt search-icon"></i>
                                    </div>
                                </div>
                                <Row>
                                    <Col lg={12}>
                                        <div className="table-responsive">
                                            <Table className="table mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>TRAINER ID</th>
                                                        <th>TRAINER NAME</th>
                                                        <th>NICK NAME</th>
                                                        <th>ASSIGN DATE</th>
                                                        <th>TRAINER TYPE NAME</th>
                                                        <th>MAIN TRAINER</th>
                                                        <th>ACTION</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {assignTrainerDetail.map((item, index) => {
                                                        return (
                                                            <tr key={index}
                                                            // style={{ backgroundColor: item.body_scale_flag == 1 && '#ffecec' }}
                                                            >
                                                                <th scope="row">{item.trainer_id}</th>
                                                                <td>{item.trainer_name}</td>
                                                                <td>{item.nick_name}</td>
                                                                <td>{!!item.assign_date ? moment(item.assign_date).format('DD-MM-YYYY') : "-"}</td>
                                                                <td>{item.trainer_type}</td>
                                                                <td className="text-center">{(item.main_trainer == 1) && <div className="size-24"><i className="bx bxs-check-circle color-success font-size-24"></i></div>}</td>
                                                                <td>{handleAction(null, item)}</td>
                                                            </tr>
                                                        )
                                                    })
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Col>
                                    <div className="mt-2">
                                        <div style={{ float: "left" }}>
                                            <Input
                                                type="select"
                                                id="formrow-serviceType"
                                                className="form-control"
                                                name={'offset'}
                                                onChange={(e) => handleOffsetChange(e)}
                                            // value={formikObj.values.member_id}
                                            >
                                                <option value="10">10</option>
                                                <option value="5">5</option>
                                                <option value="10">10</option>
                                                <option value="15">15</option>
                                            </Input>
                                        </div>
                                        <div style={{ justifyContent: "end", display: "flex" }}>
                                            <ul className="pagination react-bootstrap-table-page-btns-ul">
                                                {pageLinks.map((btn, index) => {

                                                    return (
                                                        <React.Fragment key={index}>
                                                            {/* {console.log(btn.length)} */}
                                                            {!!btn.url &&

                                                                <li className={!!btn.active ? "active page-item" : "page-item"}>
                                                                    <a className="page-link" href="#" onClick={(e) => getPageNumber(e, btn)}>
                                                                        {btn.label.includes("Next") ? ">" : btn.label.includes("Previous") ? "<" : btn.label}
                                                                    </a>
                                                                </li>
                                                            }
                                                        </React.Fragment>
                                                    )
                                                })
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </Row>
                            </>
                            :
                            <Col lg={12} style={{ textAlign: "center" }}>
                                <h5>No Data Found</h5>
                            </Col>
                        }
                    </CardBody>
                </Card>
            </Row>
            {/* Datatable view end */}

            {/* Assign workout Modal start */}


            <Modal
                isOpen={addEditAssignTrainerModal}
                toggle={closeModal}
                size="lg"
                backdrop="static"
            // className={props.className}
            >
                <ModalHeader toggle={closeModal} tag="h4">
                    {edit? "Edit Trainer":"Assign Trainer"}
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col lg={12}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-serviceType">Select Trainer Type<span className="text-danger">*</span></Label>
                                    <Input
                                        type="select"
                                        id="formrow-serviceType"
                                        className="form-control"
                                        name={'trainer_type_id'}
                                        onChange={formikObj.handleChange}
                                        value={formikObj.values.trainer_type_id}
                                        invalid={
                                            formikObj.touched.trainer_type_id && formikObj.errors.trainer_type_id ? true : false
                                        }
                                        //disabled={edit}
                                    >
                                        <option value="">Select</option>
                                        {trainerTypeList?.map((item) => (
                                            <option value={item.id} key={item.id}>{item.trainer_type}</option>
                                        ))}

                                    </Input>
                                    {formikObj.touched.trainer_type_id && formikObj.errors.trainer_type_id ? (
                                        <FormFeedback type="invalid">{formikObj.errors.trainer_type_id}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-serviceType">Select Trainer<span className="text-danger">*</span></Label>
                                    <Input
                                        type="select"
                                        id="formrow-serviceType"
                                        className="form-control"
                                        name={'trainer_id'}
                                        onChange={formikObj.handleChange}
                                        value={formikObj.values.trainer_id}
                                        invalid={
                                            formikObj.touched.trainer_id && formikObj.errors.trainer_id ? true : false
                                        }
                                        // disabled={edit}
                                    >
                                        <option value="">Select</option>
                                        {allTrainerList?.map((item) => (
                                            <option value={item.id} key={item.id}>{item.name + ' (' + item.nick_name + ')'}</option>
                                        ))}

                                    </Input>
                                    {formikObj.touched.trainer_id && formikObj.errors.trainer_id ? (
                                        <FormFeedback type="invalid">{formikObj.errors.trainer_id}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-serviceType">Assign Date<span className="text-danger">*</span></Label>
                                    <Flatpickr
                                        className="form-control d-block"
                                        name="assign_date"
                                        placeholder="DD-MM-YYYY"
                                        options={{
                                            altInput: true,
                                            altFormat: "d-m-Y",
                                            dateFormat: "d-m-Y",
                                            // minDate: today
                                        }}
                                        onChange={assignDateChange}
                                        value={assignDate}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Input 
                            type="checkbox"
                            id="assingTrainerCheckId"
                            name="assingTrainerCheckName"
                            value={formikObj.values.main_trainer}
                            className="form-check-input"
                            onClick={(e) => {hadeleMainTrainerCheck(e)}}
                            checked={formikObj.values.main_trainer == 1 ? true : false }
                        />&nbsp; Main Trainer

                    </Form>
                </ModalBody>
                <div className="modal-footer">
                    {!!isSaving ?
                        <button className="btn btn-primary " disabled>
                            Processing
                        </button>
                        :
                        <>
                            <button
                                type="button"
                                onClick={closeModal}
                                className="btn btn-secondary "
                                data-dismiss="modal"
                            >
                                Close
                            </button>
                            <button
                                type="submit"
                                className="btn btn-primary "
                                onClick={(e) => {
                                    e.preventDefault();
                                    formikObj.handleSubmit();
                                    return false;
                                }}
                            >
                                Save
                            </button>
                        </>
                    }
                </div>
            </Modal>


        </React.Fragment>
    );
}

export default withRouter(AssignTrainerToMember);

AssignTrainerToMember.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    member_id: PropTypes.number,
    redirectPath: PropTypes.string,
    assignTrainerModal: PropTypes.bool
};