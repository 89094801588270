import { VIEW_DIET_APPOINTMENT } from "./actionTypes"

const initialState = {
    dietAppointmentViewingStatus: false
}

const ViewBookedAppointment = (state = initialState, action) => {
    switch (action.type) {
        case VIEW_DIET_APPOINTMENT:
            state = { dietAppointmentViewingStatus: action.payload }
            break;
        default:
            state = { dietAppointmentViewingStatus: null}
            break;
    }
    return state;
}

export default ViewBookedAppointment;