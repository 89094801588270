// FETCH ALL USER

import { generateSearchQuery } from "utils/graphql"

export const FETCH_ALL_USERS_QUERY = `query userList($perPage: Int!, $currentPage: Int) 
{
  users(first:$perPage,page:$currentPage) 
    {
    data{
      id
      profile_id
      name
      email
      address
      status
    }
}
}
`

// FETCH ALL USER FOR LISTING, FILTERED BY PROFILE_ID
const searchQry = generateSearchQuery(['name', 'email', 'address','status','primary_contact_no','zip_code'])
export const FETCH_USERS_LIST_BY_PROFILE_ID_QUERY = `query userList($perPage: Int!, $currentPage: Int, 
  $profile_id: Mixed,$searchText:Mixed, $office_id: Mixed!) 
{
  users (first:$perPage,page:$currentPage,
    where:{ AND:[{column: _pid, operator:EQ value: $profile_id} 
                {column:_office_id operator:LIKE value: $office_id}  ]
                 ${searchQry}
  }
    orderBy:{column:_name,order:DESC}
  )
  {
    data{
      id
      profile_id
      
      name
      email
      address
      status
      salutation
      primary_contact_no
      secondary_contact_no
      zip_code
      region_head_name
      {
        name
        profile_id
      }
      zonal_head_name
      {
        name
        profile_id
      }
      third_level_head_name
      {
        name 
      }
  }
  paginatorInfo {
    currentPage
    total
    perPage
  }
  }
}
`

// CUSTOMER TEMP QUERY

export const FETCH_CUSTOMERS_LIST_BY_PROFILE_ID_QUERY = `query userList($perPage: Int!, $currentPage: Int, 
  $profile_id: Mixed,$searchText:Mixed) 
{
  users (first:$perPage,page:$currentPage,
    where:{ AND:[{column: _pid, operator:EQ value: $profile_id} ]
                 ${searchQry}
  }
    orderBy:{column:_name,order:DESC}
  )
  {
    data{
      id
      profile_id
      
      name
      email
      address
      status
      salutation
      primary_contact_no
      secondary_contact_no
      zip_code
      region_head_name
      {
        name
        profile_id
      }
      zonal_head_name
      {
        name
        profile_id
      }
      third_level_head_name
      {
        name 
      }
  }
  paginatorInfo {
    currentPage
    total
    perPage
  }
  }
}
`


// FETCH USERDATA FOR VIEW/EDIT

export const FETCH_USERDATA_FOR_VIEW_QUERY = `query userList($userId: Mixed) 
{
  users(where:{ column:_id operator:EQ value:$userId}) 
    {
    data{
      id
      salutation
      name
      region
      zone
      region_head_id
      zonal_head_id
      third_level_head_id
      primary_contact_no
      secondary_contact_no
      email
      address
      city
      state
      zip_code
    }
}
}
`

// FETCH USER PROFILE TYPE

export const FETCH_REGION_HEAD_QUERY = `query userList($profile_id: Mixed) 
{
  users(where:{ column:_pid operator:EQ value:$profile_id}, 
    orderBy:{column:_name,order:DESC}) 
    {
    data{
      id
      name
      profile_id
    }
}
}
`

// FETCH DEPENDENT USER PROFILE TYPE

export const FETCH_ZONAL_HEAD_DEPENDENT_ON_REGION_HEAD_QUERY = `query userList($region_head_id: Mixed) 
{
  users(where:{ 
    AND:
    [{ column: _region_head_id, operator: EQ, value: $region_head_id }
      { column: _zonal_head_id, operator:IS_NULL value: "" }
    ]
}
orderBy:{column:_name,order:DESC}) 
    {
    data{
      id
      name
      profile_id
    }
}
}
`

export const FETCH_SERVICE_BD_OFFICE_ADMIN_HEAD_DEPENDENT_ON_ZONAL_HEAD_QUERY = `query userList($region_head_id: Mixed, $zonal_head_id: Mixed, $profile_id:Mixed) 
{
  users(where:{ 
    AND:
    [{ column: _region_head_id, operator: EQ, value: $region_head_id }
      { column: _zonal_head_id, operator:EQ value: $zonal_head_id }
      { column: _pid, operator:EQ value: $profile_id }
    ]
}
orderBy:{column:_name,order:DESC}) 
    {
    data{
      id
      name
      profile_id
    }
}
}
`

// FETCH CONFIRMED USER LIST FOR MESSAGING

export const FETCH_SUPERADMIN_FOR_MESSAGE_QUERY = `query superadminMsg($perPage: Int!, $currentPage: Int) 
{
  users (first:$perPage,page:$currentPage,
    where:{ column: _pid, operator:EQ value: 1 }
  )
  {
    data{
      id
      profile_id
      name
      email
      address
      status
      salutation
      primary_contact_no
      secondary_contact_no
      zip_code
  }
  paginatorInfo {
    currentPage
    total
    perPage
  }
  }
}
`

export const FETCH_HEADS_FOR_MESSAGE_QUERY = `query headUsersMsg($perPage: Int!, $currentPage: Int, $office_id: Mixed) 
{
  users (first:$perPage,page:$currentPage,
    where:{ AND:[{column: _pid, operator:IN value: [2,3,4,5,6,10]}
                  {column:_office_id operator:LIKE value: $office_id}
                ]  
          }
  )
  {
    data{
      id
      office_id
      profile_id
      profile{
        title
      }
      name
      email
      address
      status
      salutation
      primary_contact_no
      secondary_contact_no
      zip_code
  }
  paginatorInfo {
    currentPage
    total
    perPage
  }
  }
}
`