import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import paginationFactory, { PaginationListStandalone, PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { Col, Row } from 'reactstrap';
import debounce from 'lodash/debounce';


function CommonTable(props) {

    const {
        keyField,
        columns = {},
        custom = true,
        paginatorInfo,
        data,
        onTableChange,
        searchText,
        loading,
        selectRow,
        rowStyle
    } = props;
    const formatedPaginatorInfo = useMemo(() => {
        return {
            page: paginatorInfo?.currentPage || 0,
            sizePerPage: paginatorInfo?.perPage || 0,
            totalSize: paginatorInfo?.total || 0,
        }
    }, [paginatorInfo]);

    useEffect(() => {
        if (!loading) {
            document.body.click()
        }
    }, [loading]);
    const handleOnSearch = debounce((e) => onTableChange({
        currentPage: 1,
        perPage: sizePerPageList[0].value
    }, e.target.value), 500)

    const { SearchBar } = Search;
    // Custom Pagination Toggle
    const sizePerPageList = [
        { text: '10', value: 10 },
        { text: '15', value: 15 },
        { text: '20', value: 20 },
        { text: 'All', value: (data).length }];

    const renderSearchBox = () => (<Row className="mb-2">
        <Col md="4">
            <div className="search-box me-2 mb-2 d-inline-block">
                <div className="position-relative">

                    <label htmlFor="search-bar-0" className="search-label">
                        <span
                            id="search-bar-0-label"
                            className="sr-only">
                            Search this table
                        </span>
                        <input
                            id="search-bar-0"
                            type="text"
                            aria-labelledby="search-bar-0-label" className="form-control "
                            placeholder="Search"
                            defaultValue={searchText}
                            onChange={handleOnSearch} />
                    </label>
                    <i className="bx bx-search-alt search-icon" />
                </div>
            </div>
        </Col>
    </Row>)
    // Show Loading Indicator
    if (loading) return (<Row>
        <Col lg={12} style={{ textAlign: "center" }}>
            <h5><i className="bx bx-hourglass bx-spin font-size-24 align-middle me-2"></i>
                Loading...</h5>
        </Col>
    </Row>);

    // If  data is undefined OR Length is 0 then Show `No Data Found!`
    if (!data || data?.length === 0) return (
        <>
            {renderSearchBox()}
            <Row>
                <Col lg={12} style={{ textAlign: "center" }}>
                    <h5>No Data Found!</h5>
                </Col>
            </Row>
        </>
    )

    return (
        <PaginationProvider
            pagination={paginationFactory({
                hidePageListOnlyOnePage: true,
                ...formatedPaginatorInfo,
                sizePerPageList,
                custom

            })}
            keyField={keyField}
            columns={columns}
            data={data}
        >
            {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                    keyField={keyField}
                    columns={columns}
                    data={data}
                    search={{
                        // defaultSearch: searchText 
                    }}
                >
                    {toolkitProps => (
                        <React.Fragment>

                            {renderSearchBox()}

                            <Row>
                                <Col xl="12">
                                    <div className="table-responsive">
                                        <BootstrapTable
                                            remote
                                            keyField={keyField}
                                            selectRow={selectRow}
                                            rowStyle={rowStyle}
                                            responsive
                                            bordered={false}
                                            striped={false}
                                            classes={
                                                "table align-middle table-nowrap"
                                            }
                                            headerWrapperClasses={"thead-light"}
                                            {...toolkitProps.baseProps}
                                            {...paginationTableProps}
                                            onTableChange={(type, {
                                                page,
                                                sizePerPage
                                            }) => {
                                                const paginatorInfo = {
                                                    currentPage: page,
                                                    perPage: sizePerPage
                                                }
                                                if (type === 'pagination') {
                                                    onTableChange(paginatorInfo, searchText)
                                                }
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                    <div className="d-inline">
                                        <SizePerPageDropdownStandalone
                                            {...paginationProps}
                                        />
                                    </div>
                                    <div className="d-inline mt-2 ms-1">
                                        <PaginationTotalStandalone
                                            {...paginationProps}
                                        />
                                    </div>
                                    <div className="text-md-right ms-auto">
                                        <PaginationListStandalone
                                            {...paginationProps}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </React.Fragment>
                    )
                    }
                </ToolkitProvider>
            )
            }
        </PaginationProvider>
    )
}

CommonTable.propTypes = {
    keyField: PropTypes.any.isRequired,
    columns: PropTypes.array.isRequired,
    custom: PropTypes.any.bool,
    paginatorInfo: PropTypes.object,
    searchText: PropTypes.string,
    data: PropTypes.array.isRequired,
    loading: PropTypes.bool,
    onTableChange: PropTypes.func,
    selectRow: PropTypes.any,
    rowStyle: PropTypes.any
}

export default CommonTable
