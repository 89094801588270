import axios from "axios";
import { authStorage } from "utils/auth";
import apiUrls from "./urls.json"
const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_API
});
const isGraphqlRequest = (url) => url.includes(process.env.REACT_APP_GRAPHQL_API);
axiosInstance.interceptors.request.use(async function (config) {

    if (authStorage.getToken()) {
        config.headers["Authorization"] = `Bearer ${authStorage.getToken()}`;
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});

axiosInstance.interceptors.response.use(async function (response) {
    const originalRequest = response.config;
    if (response?.data?.errors?.[0]?.message === 'Unauthenticated.' && !originalRequest._retry) {
        originalRequest._retry = true;
        return await callRefreshToken(originalRequest);
    }
    return response.data;
}, async function (error) {
    const originalRequest = error.config;
    if (error?.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        await callRefreshToken(originalRequest);
    } else {
        if (error?.response?.data)
            return Promise.reject({ ...error.response.data, status: error.response.status });
        return Promise.reject({ msg: "Oops! Something Went wrong", status: error.response.status });
    }

});

async function callRefreshToken(originalRequest) {
    try {
        const res = await axiosInstance.get(apiUrls.auth.refreshToken);
        authStorage.setToken(res.token);
        return axiosInstance(originalRequest);
    } catch (error) {
        authStorage.removeToken();
    }
}
export default axiosInstance;