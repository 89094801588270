import Breadcrumb from "components/Common/Breadcrumb";
import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { withRouter, Link } from "react-router-dom";
import {
    Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label, Button, Modal,
    ModalBody,
    ModalHeader,
    InputGroup,
    Spinner,
    CardTitle,
    Collapse,
    CardHeader
} from "reactstrap";
import PropTypes from "prop-types";
import { apiUrls, axiosInstance } from "api";
import usePaginator from "hooks/usePaginator";
import Flatpickr from "react-flatpickr";
import swal from 'sweetalert';
import { standartDateFormat, today, userDateFormat, userTimeFormat } from 'utils/date';
import CommonTable from 'components/Common/CommonTable';
import { FieldArray, useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import SessionUpcomingCompletedModal from "pages/Common/SessionUpcomingCompletedModal";

const initialAcitivityData = {
    id: '',
    activity_id: 4,
    sessions_available: '',
    sessions_completed: '',

    alloted_batch: [],
    assign_date: '',
    timing_from: '',
    timing_to: '',
    note: '',

    gender: ""
}

const ManageSteamBath = (props) => {
    const [acitivityData, setAcitivityData] = useState({ ...initialAcitivityData })
    const [memberwiseActivityList, setMemberwiseActivityList] = useState([])
    const [processingActivityList, setProcessingActivityList] = useState(false);
    const [memberActivityPaginatorInfo, setMemberActivityPaginatorInfo, resetMemberActivityPaginatorInfo] = usePaginator();
    const [searchText, setSearchText] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const [assignSessionModal, setAssignSessionModal] = useState(false)
    const [assignedDate, setAssignedDate] = useState('');
    const [paginatorInfo, setPaginatorInfo, resetPaginatorInfo] = usePaginator();
    const [activityBatchList, setActivityBatchList] = useState([])
    const [processingList, setProcessingList] = useState(false);
    const [isHideTable, setIsHideTable] = useState(false)
    const [isUpdate, setisUpdate] = useState(false)
    const [sessionUpcomingCompletedModal, setSessionUpcomingCompletedModal] = useState(false)
    const [sessionUpcomingCompletedLog, setSessionUpcomingCompletedLog] = useState([])
    const [sessionType, setSessionType] = useState('')

    useEffect(() => {
        // fetchMemberWiseActivitiesDetails(memberActivityPaginatorInfo, searchText)
        fetchActivitywiseBatchList(paginatorInfo, searchText)
    }, [])

    const fetchMemberWiseActivitiesDetails = async (memberActivityPaginatorInfo, searchText) => {
        try {
            setProcessingActivityList(true)
            let params = {
                offset: !!memberActivityPaginatorInfo.perPage ? memberActivityPaginatorInfo.perPage : 10,
                search_text: searchText,
                // member_id: "",
                activity_id: formikObj.values.activity_id
            }
            const res = await axiosInstance.post(apiUrls.activity.fetchSteamBathAndPersonalTrainerActivities + "?page=" + memberActivityPaginatorInfo.currentPage, params)
            if (res.success == 1) {
                let data = res?.data?.data
                setMemberwiseActivityList(data)
                let memberActivityPaginatorInfo = {
                    currentPage: parseInt(res?.data?.current_page),
                    perPage: parseInt(res?.data?.per_page),
                    total: parseInt(res?.data?.total),
                }
                setMemberActivityPaginatorInfo(memberActivityPaginatorInfo);
                setSearchText(searchText);
                setProcessingActivityList(false)
            } else {
                setProcessingActivityList(false)
                resetMemberActivityPaginatorInfo()
            }
        } catch (error) {
            setProcessingActivityList(false)
            resetMemberActivityPaginatorInfo()
        }
    }

    const formikObj = useFormik({
        enableReinitialize: true,

        initialValues: { ...acitivityData },
        validationSchema: Yup.object({
            assign_date: Yup.string().required("Please Select Assigned Date"),
            // timing_from: Yup.date().required("Please Select From Time"),
            // timing_to: Yup.date().required("Please Select To Time"),
        }),
        onSubmit: async (values) => {
            try {
                setIsSaving(true)
                let param = {
                    id: values?.id,
                    activity_id: values.activity_id,
                    assign_date: values.assign_date,
                    timing_from: values.timing_from,
                    timing_to: values.timing_to,
                    note: values.note,
                    member_id: values.member_id
                }
                const res = await axiosInstance.post(apiUrls.activity.addAndUpdateSteamBathAndPersonalTrainerActivities, param);
                if (res.success == 1) {
                    formikObj.resetForm()
                    fetchActivitywiseBatchList(paginatorInfo, searchText)
                    setIsSaving(false)
                    closeModal()
                    swal("Success!", res.message, "success");
                } else {
                    setIsSaving(false)
                    swal("Oops!", res.message, "error");
                }
            } catch (error) {
                setIsSaving(false)
                swal("Oops!", "Something went wrong!", "error");
            }
        }
    });

    const openModal = () => {
        setAssignSessionModal(true)
    }

    const closeModal = () => {
        setAssignSessionModal(false)
        // fetchMemberWiseActivitiesDetails(memberActivityPaginatorInfo, searchText)
        fetchActivitywiseBatchList(paginatorInfo, searchText)
        resetFormData()
    }

    const resetFormData = () => {
        formikObj.setFieldValue("assign_date", "")
        formikObj.setFieldValue("timing_from", "")
        formikObj.setFieldValue("timing_to", "")
        formikObj.setFieldValue("id", "")
        formikObj.setFieldValue("note", "")
        formikObj.setFieldValue("member_id", "")
        setAssignedDate('')
    }

    const handleTableChange = (paginatorInfo, searchText) => {
        fetchActivitywiseBatchList(paginatorInfo, searchText)
    }

    const handleMemberAcitivityTableChange = (memberActivityPaginatorInfo, searchText) => {
        // fetchMemberWiseActivitiesDetails(memberActivityPaginatorInfo, searchText)
    }

    const handleBatchDate = (cell, row) => {
        if (row?.alloted_status == 1) {
            return <>
                <div>{userDateFormat(row?.start_date)}</div>
                <div>{userDateFormat(row?.end_date)}</div>
            </>
        } else {
            return <div>
                {userDateFormat(row?.batch_date || row?.assign_date)}
            </div>
        }
    }

    const handleAction = (cell, row) => {
        return (
            <>
                <Button className="btn-info btn-sm" onClick={(e) => { handleFetch(e, row) }}>
                    <i className="bx bx-pencil align-middle" />
                </Button>
                &nbsp;
                <Button className="btn btn-danger btn-sm ms-2" onClick={(e) => { handleDelete(e, row) }}>
                    <i className="bx bx-trash align-middle" />
                </Button>
            </>
        )
    }

    const handleDelete = (e, row) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (willDelete) => {
                if (willDelete) {
                    try {
                        let param = {
                            id: row.id,
                            member_id: row.member_id
                        }
                        const response = await axiosInstance.post(apiUrls.activity.dropMemberFromSteamBathAndPersonalTrainerActivities, param);
                        if (response.success == 1) {
                            swal("Success!", response.message, "success");
                            // fetchMemberWiseActivitiesDetails(memberActivityPaginatorInfo, searchText)
                        }
                        else {
                            swal("Oops!", response.message, "error");
                        }
                    } catch (err) {
                        swal("Oops!", "Something went wrong!", "error");
                    }
                }
            });
    }

    const handleFetch = async (e, row) => {
        let param = {
            id: row.id,
            member_id: row.member_id,
            activity_id: formikObj.values.activity_id
        }
        try {
            const res = await axiosInstance.post(apiUrls.activity.fetchSteamBathAndPersonalTrainerActivities, param)
            let resData = !!res.data ? res?.data?.[0] : []
            const data = {
                id: resData?.id,
                member_id: resData?.member_id,
                gender: formikObj.values.gender,
                activity_id: resData?.activity_id,
                assign_date: resData?.assign_date,
                timing_from: resData?.timing_from,
                timing_to: resData?.timing_to,
                note: resData?.note
            }
            setAssignedDate(userDateFormat(resData?.assign_date))
            setAcitivityData(data)
            setAssignSessionModal(true)
        } catch (error) {

        }
    }

    const handleActivityAssignAction = (cell, row) => {
        return (
            <>
                <Button className="btn-info btn-sm" onClick={(e) => {
                    openModal()
                    formikObj.setFieldValue("member_id", row.member_id)
                }}
                    title="Assign"
                >
                    <i className="bx bx-user-check" />
                </Button>
            </>
        )
    }

    // For modal, now in outer table
    const columns = [
        {
            formatter: (cell, row) => <Link to={{
                pathname: "/member-details",
                state: { member_id: row.member_id, tabType: "8", activity: formikObj.values.activity_id }
            }}>{row.member_id}</Link>,
            dataField: 'member_id',
            text: 'MEMBER ID',
            sort: true,
        },
        {
            dataField: "member_name",
            text: 'MEMBER NAME',
            sort: true,
        },
        {
            dataField: "available_session",
            text: 'AVAILABLE',
            sort: true,
        },
        {
            formatter: (cell, row) => <div className="text-center cursor-pointer"><span className="badge rounded-pill bg-success px-2 py-1" onClick={(e) => {
                setSessionUpcomingCompletedModal(true);
                setSessionUpcomingCompletedLog(row?.completed_whole_session_logs_data);
                setSessionType(1)
            }}>{row.complete_session}</span></div>,
            text: 'COMPLETED',
            sort: true,
        },
        {
            formatter: (cell, row) => <div className="text-center cursor-pointer"><span className="badge rounded-pill bg-success px-2 py-1" onClick={(e) => {
                setSessionUpcomingCompletedModal(true);
                setSessionUpcomingCompletedLog(row?.upcoming_whole_session_logs_data);
                setSessionType(2)
            }}>{row.upcoming_session}</span></div>,
            text: 'UPCOMING',
            sort: true,
        },
        {
            formatter: handleActivityAssignAction,
            text: 'ACTION'
        }
    ];

    // For outer table
    // const activityColumns = [
    //     {
    //         formatter: (cell, row) => <Link to={{
    //             pathname: "/member-details",
    //             state: { member_id: row.member_id, tabType: "8" }
    //         }}>{row.id}</Link>,
    //         dataField: 'member_id',
    //         text: 'MEMBER ID',
    //         sort: true,
    //     },
    //     {
    //         dataField: "member_name",
    //         text: 'MEMBER NAME',
    //         sort: true,
    //     },
    //     {
    //         formatter: handleBatchDate,
    //         text: 'BATCH DATE',
    //         sort: true,
    //     },
    //     {
    //         formatter: (cell, row) => row?.alloted_status == 1 ? "" : userTimeFormat(row?.timing_from),
    //         text: 'TIMING FROM',
    //         sort: true,
    //     },
    //     {
    //         formatter: (cell, row) => row?.alloted_status == 1 ? "" : userTimeFormat(row?.timing_to),
    //         text: 'TIMING TO',
    //         sort: true,
    //     },
    //     {
    //         formatter: (cell, row) => row.status == 1 ? <span className="badge rounded-pill bg-success px-2 py-1">Upcoming</span> : <span className="badge rounded-pill bg-warning float-start">Completed</span>,
    //         text: 'BATCH STATUS',
    //         sort: true,
    //     },
    //     {
    //         formatter: handleAction,
    //         text: 'ACTION'
    //     }
    // ]

    const fetchActivitywiseBatchList = async (paginatorInfo, searchText) => {
        try {
            setProcessingList(true)
            let params = {
                offset: !!paginatorInfo.perPage ? paginatorInfo.perPage : 10,
                search_text: searchText,
                gender: formikObj.values.gender
            }
            const res = await axiosInstance.post(apiUrls.activity.memberListOfSteamBath + "?page=" + (!!paginatorInfo.currentPage ? paginatorInfo.currentPage : 1), params)

            if (res.success == 1) {
                setProcessingList(false)
                setActivityBatchList(!!res?.data?.data ? res?.data?.data : [])
                let paginatorInfo = {
                    currentPage: parseInt(res?.data?.current_page),
                    perPage: parseInt(res?.data?.per_page),
                    total: parseInt(res?.data?.total),
                }
                setPaginatorInfo(paginatorInfo);
                setSearchText(searchText);
            }

            else {
                setProcessingList(false)
                setActivityBatchList([])
                resetPaginatorInfo();
            }
        } catch (error) {
            setProcessingList(false)
            setActivityBatchList([])
            resetPaginatorInfo();
        }

    }

    useEffect(() => {
        fetchActivitywiseBatchList(paginatorInfo, searchText)
    }, [formikObj.values.gender])

    const assignDateChange = (assignedDate) => {
        if (!!assignedDate[0]) {
            setAssignedDate(assignedDate[0].getDate() + '-' + (assignedDate[0].getMonth() + 1) + '-' + assignedDate[0].getFullYear())
            formikObj.setFieldValue("assign_date", standartDateFormat(assignedDate[0]))
        } else {
            setAssignedDate('')
            formikObj.setFieldValue("assign_date", '')
        }
    }

    const setFromTimeChange = (timeChange) => {
        formikObj.setFieldValue("timing_from", timeChange)
    }

    const setToTimeChange = (timeChange) => {
        formikObj.setFieldValue("timing_to", timeChange)
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Steam Bath</title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumb title="Activities" breadcrumbItem="Steam Bath" />

                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <Row className="mb-4">
                                        <Col lg={10}>
                                            <h3>Steam Bath {(formikObj.values.gender == "" && "All Members") || (formikObj.values.gender == "1" && "Male Members") || (formikObj.values.gender == "2" && "Female Members")} List</h3>
                                        </Col>
                                        <Col lg={2}>
                                            <Input
                                                type="select"
                                                id="formrow-serviceType"
                                                className="form-control"
                                                name="gender"
                                                onChange={formikObj.handleChange}
                                                value={formikObj.values.gender}
                                            >
                                                <option value="">All Members</option>
                                                <option value="1">Male</option>
                                                <option value="2">Female</option>
                                            </Input>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                            <CommonTable
                                                paginatorInfo={paginatorInfo}
                                                searchText={searchText}
                                                onTableChange={handleTableChange}
                                                keyField='unique_id'
                                                columns={columns}
                                                data={activityBatchList}
                                                loading={processingList}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Modal
                isOpen={assignSessionModal}
                toggle={closeModal}
                size="lg"
                backdrop="static"
            >
                <ModalHeader toggle={closeModal} tag="h4">
                    Assign Steam Bath
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-serviceType">Assigned Date<span className="text-danger">*</span></Label>
                                    <Flatpickr
                                        className="form-control d-block"
                                        name="assign_date"
                                        placeholder="DD-MM-YYYY"
                                        options={{
                                            altInput: true,
                                            altFormat: "d-m-Y",
                                            dateFormat: "d-m-Y",
                                            minDate: today
                                        }}
                                        onChange={assignDateChange}
                                        value={assignedDate}
                                    />
                                    {formikObj.touched.assign_date && formikObj.errors.assign_date ? (
                                        <div style={{ fontSize: "smaller", color: "#f46a6a" }}>{formikObj.errors.assign_date}</div>
                                    ) : null}
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-serviceType">Time From<span className="text-danger">*</span></Label>
                                    <Flatpickr
                                        className="form-control d-block"
                                        name={"timing_from"}
                                        options={{
                                            noCalendar: true,
                                            enableTime: true,
                                            dateFormat: 'h:i K',
                                        }}
                                        // onChange={setFromTimeChange}
                                        onChange={(e, timeChange)=>{
                                            formikObj.setFieldValue("timing_from", timeChange)
                                        }}
                                        value={formikObj.values.timing_from}
                                    />
                                    {formikObj.touched.timing_from && formikObj.errors.timing_from ? (
                                        <div style={{ fontSize: "smaller", color: "#f46a6a" }}>{formikObj.errors.timing_from}</div>
                                    ) : null}
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-serviceType">Time To<span className="text-danger">*</span></Label>
                                    <Flatpickr
                                        className="form-control d-block"
                                        name={"timing_to"}
                                        options={{
                                            noCalendar: true,
                                            enableTime: true,
                                            dateFormat: 'h:i K'
                                        }}
                                        onChange={(e, timeChange) => {
                                            formikObj.setFieldValue("timing_to", timeChange)
                                        }}
                                        // onChange={setToTimeChange}
                                        value={formikObj.values.timing_to}
                                    />
                                    {formikObj.touched.timing_to && formikObj.errors.timing_to ? (
                                        <div style={{ fontSize: "smaller", color: "#f46a6a" }}>{formikObj.errors.timing_to}</div>
                                    ) : null}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-serviceType">Note</Label>
                                    <Input
                                        type="textarea"
                                        id="formrow-InputService"
                                        className="form-control"
                                        name="note"
                                        onChange={formikObj.handleChange}
                                        value={formikObj.values.note}
                                        placeholder="Enter Note"
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <div className="modal-footer d-flex">
                                    {!!isSaving ?
                                        <button className="btn btn-primary " disabled>
                                            Processing
                                        </button>
                                        :
                                        <button
                                            type="submit"
                                            className="btn btn-primary "
                                            onClick={(e) => {
                                                e.preventDefault();
                                                formikObj.handleSubmit();
                                                return false;
                                            }}
                                        >
                                            Assign
                                        </button>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>

            <SessionUpcomingCompletedModal
                isOpenModal={sessionUpcomingCompletedModal}
                onCloseClick={() => setSessionUpcomingCompletedModal(false)}
                activityName={'Steam Bath'}
                activityId={formikObj.values.activity_id}
                sessionLog={sessionUpcomingCompletedLog}
                sessionType={sessionType}
            />

        </React.Fragment>
    );
}


export default withRouter(ManageSteamBath);

ManageSteamBath.propTypes = {
    history: PropTypes.object,
}