import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useEffect, useState } from "react";
import {
    Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label, Button, Modal,
    ModalBody,
    ModalHeader,
    InputGroup
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// Formik formikObj
import * as Yup from "yup";
import { useFormik } from "formik";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import $, { event } from "jquery";
import { standartDateFormat } from "utils/date";
import { axiosInstance, apiUrls } from "api";
import { authStorage } from "utils/auth";
import swal from 'sweetalert';
import profile_ids from "utils/profile_ids";
import { graphql } from "api";
import * as UsersQuery from "GraphQLquery/usersQry";
import usePaginator from "hooks/usePaginator";
// Form Editor
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const initialUserData = {
    name: "",
    email: "",
    confirm_password: "",
    salutation: "",
    // region: "",
    primary_contact_no: "",
    secondary_contact_no: "",
    address: "",
    city: "",
    state: "",
    zip_code: "",
}

const ComposeMessage = props => {

    const [addEditUserModal, setAddEditUserModal] = useState(false);
    const [dob, setDOB] = useState('');
    const [passwordConfirmed, setPasswordConfirmed] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [processingUserList, setProcessingUserList] = useState(false);
    const [error, setError] = useState(null);
    const [allUsersList, setAllUsersList] = useState([])
    const [userId, setUserId] = useState(null)
    const [userData, setUserData] = useState({ ...initialUserData })
    const [paginatorInfo, setPaginatorInfo, resetPaginatorInfo] = usePaginator();
    const [searchText, setSearchText] = useState('');


    useEffect(() => {
        fetchUsers(paginatorInfo, searchText)
    }, [])

    const fetchUsers = async (paginatorInfo, searchText) => {
        try {
            setProcessingUserList(true);
            const res = await graphql({
                query: UsersQuery.FETCH_USERS_LIST_BY_PROFILE_ID_QUERY,
                variables: {
                    ...paginatorInfo,
                    profile_id: profile_ids.customer,
                    searchText: `%${searchText}%`
                }
            });
            if (res.data) {
                setAllUsersList(!!res.data.users ? res.data.users.data : [])
                setPaginatorInfo(res?.data?.users?.paginatorInfo);
                setSearchText(searchText);
                setProcessingUserList(false)
            } else {
                setAllUsersList([]);
                resetPaginatorInfo();
                setProcessingUserList(false)
            }
        } catch (err) {
            setAllUsersList([]);
            resetPaginatorInfo();
            setProcessingUserList(false)
        }
    }

    const formikObj = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: { ...userData },
        validationSchema: Yup.object({
            name: Yup.string().required("Please Enter Your Name"),
            email: Yup.string().required("Please Enter Your Email"),
            salutation: Yup.string().required("Please Select Salutation"),
            primary_contact_no: Yup.number().required("Please Enter Your Contact No."),
        }),
        onSubmit: (values) => {
            if (userId == null) {
                addUserData(values)
            }
            else {
                updateUserData(values)
            }
        }
    });

    const addUserData = async (values) => {
        try {
            setIsSaving(true)
            const response = await axiosInstance.post(apiUrls.vendor.addAndUpdateVendor, { ...values, profile_id: profile_ids.vendor });
            if (response.success == 1) {
                formikObj.resetForm()
                setIsSaving(false)
                closeModal()
                swal("Success!", response.message, "success");
                fetchUsers({ ...paginatorInfo, currentPage: 1 }, searchText)
            }
            else {
                setIsSaving(false)
                swal("Oops!", response.message, "error");
            }
        } catch (err) {
            setIsSaving(false)
            swal("Oops!", "Something went wrong!", "error");
            setError(err.message)
        }
    }

    const updateUserData = async (values) => {

        try {
            setIsSaving(true)
            const response = await axiosInstance.post(apiUrls.vendor.addAndUpdateVendor, { ...values, profile_id: profile_ids.vendor, id: userData.id });
            if (response.success == 1) {
                formikObj.resetForm()
                setIsSaving(false)
                closeModal()
                swal("Success!", response.message, "success");
                fetchUsers(paginatorInfo, searchText)
                setUserData([])
                setUserId(null)
            }
            else {
                setIsSaving(false)
                swal("Oops!", response.message, "error");
            }
        } catch (err) {
            setIsSaving(false)
            swal("Oops!", "Something went wrong!", "error");
            setError(err.message)
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Compose Message</title>
                </MetaTags>
                <Container fluid={true}>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs title="Message" breadcrumbItem="Compose Message" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Form method="post">
                                        <Row>
                                            <Col lg={12}>
                                                <div className="mb-3">
                                                    <Label htmlFor="formrow-InputSalutation">Select Customer<span className="text-danger">*</span></Label>
                                                    <Input
                                                        type="select"
                                                        id="formrow-InputAddress"
                                                        className="form-control"
                                                        name="customer_id"
                                                        onChange={formikObj.handleChange}
                                                        value={formikObj.values.customer_id}
                                                        invalid={
                                                            formikObj.touched.customer_id && formikObj.errors.customer_id ? true : false
                                                        }
                                                    >
                                                        <option defaultValue>Select</option>
                                                        {allUsersList.map((item) => (
                                                            <option value={item.id} key={item.id}>{item.name}</option>
                                                        ))}

                                                    </Input>
                                                    {formikObj.touched.customer_id && formikObj.errors.customer_id ? (
                                                        <FormFeedback type="invalid">{formikObj.errors.customer_id}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={12}>
                                                <Editor
                                                    toolbarClassName="toolbarClassName"
                                                    wrapperClassName="wrapperClassName"
                                                    editorClassName="editorClassName"
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            {/* <Col lg={4}>

                                                <div>
                                                    <Input className="form-control" type="file" id="formFile" />
                                                </div>
                                            </Col> */}
                                            <Col lg={12}>
                                                <div className="text-end" style={{ marginTop: "2%" }}>
                                                    {!!isSaving ?
                                                        <button className="btn btn-primary " disabled>
                                                            Processing
                                                        </button>
                                                        :
                                                        <>
                                                            <button
                                                                type="button"
                                                                className="btn btn-secondary "
                                                            >
                                                                CANCEL
                                                            </button>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                                            <button
                                                                type="submit"
                                                                className="btn btn-primary "
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    formikObj.handleSubmit();
                                                                    return false;
                                                                }}
                                                            >
                                                                SUBMIT
                                                            </button>
                                                        </>
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
};

export default withRouter(ComposeMessage);

ComposeMessage.propTypes = {
    history: PropTypes.object,
};