import { TRAINER_ASSIGNED_TO_MEMBER } from "./actionTypes";

const initialState = {
    trainerAssignedStatus: false
}

const TrainerAssignedToMember = (state = initialState, action) => {
    switch (action.type) {
        case TRAINER_ASSIGNED_TO_MEMBER:
            state = { trainerAssignedStatus: action.trainerAssignedStatus }
            break;
        default:
            state = { trainerAssignedStatus: null };
            break;
    }
    return state;
}

export default TrainerAssignedToMember