import PropTypes, { string } from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useEffect, useState } from "react";
import {
    Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label, Button, Modal,
    ModalBody,
    ModalHeader,
    InputGroup,
    CardTitle,
    CardText,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Spinner,
    Collapse,
    CardHeader,
    Table
} from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import classnames from "classnames";
import Flatpickr from "react-flatpickr";

// Formik formikObj   
import * as Yup from "yup";
import { useFormik, FieldArray, FormikProvider } from "formik";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { today, standartDateFormat, userDateFormat, userTimeFormat } from "utils/date";
import { axiosInstance, apiUrls } from "api";
import swal from 'sweetalert';
import { graphql } from "api";

// datatable related plugins

import CommonTable from "components/Common/CommonTable";
import usePaginator from "hooks/usePaginator";
import * as MemberQry from "GraphQLquery/memberQry";
import * as DietQry from "GraphQLquery/dietQry";
import * as TrainerQry from "GraphQLquery/trainerQry";

import moment from "moment";

import { useSelector, useDispatch } from "react-redux";
import { setDietAssignedMember, setDietBookedAppointment, setViewBookedAppointment } from "store/actions";
import { values } from "lodash";
import ViewBookedAppointment from "store/viewDietAppointment/reducer";

const initialMemberDietData = {
    diet_plan_id: '',
    member_id: '',
    next_review_date: ''
}

const initialAppointmentData = {
    appointment_date: "",
    appointment_time: "",
    trainer_id: "",
    note: "",
}

const AssignMemberDietChart = props => {
    const dispatch = useDispatch();
    const member_id = props.member_id;
    const runFetchAppointmentList = props.runFetchAppointmentList;
    const viewDietAppointmentModal = props.viewDietAppointmentModal;
    const bookDietAppointmentModal = props.bookDietAppointmentModal;
    const assignDietModal = props.assignDietModal;
    const redirectPath = props.redirectPath;
    const [appointmentListPaginatorInfo, setAppointmentListPaginatorInfo, resetAppointmentListPaginatorInfo] = usePaginator();
    const [paginatorInfo, setPaginatorInfo, resetPaginatorInfo] = usePaginator();
    const [searchText, setSearchText] = useState('');
    const [searchAppointmentText, setSearchAppointmentText] = useState('');
    const [dietPlans, setDietPlans] = useState([])
    const [dietChartList, setDietChartList] = useState([])
    const [dietChartDetail, setDietChartDetail] = useState([]);
    const [trainers, setTrainers] = useState([])
    const [dietAppointmentModal, setDietAppointmentModal] = useState(false);
    const [viewAppointmentModal, setViewAppointmentModal] = useState(false);
    const [dietChartViewModal, setDietChartViewModal] = useState(false);
    const [dayFirstWorkout, setDayFirstWorkout] = useState([])
    const [daySecondWorkout, setDaySecondWorkout] = useState([])
    const [dayThirdWorkout, setDayThirdWorkout] = useState([])
    const [dayFourWorkout, setDayFourWorkout] = useState([])
    const [processingList, setProcessingList] = useState(false);
    const [memberDietData, setMemberDietData] = useState({ ...initialMemberDietData })
    const [dietBookingId, setDietBookingId] = useState(null)
    const [dietAppointmentData, setDietAppointmentData] = useState({ ...initialAppointmentData })
    const [addEditAssignDietModal, setAddEditAssignDietModal] = useState(false);
    const [error, setError] = useState(null);
    const [trainerlistError, setTrainerlistError] = useState(null);
    const [isApDeleting, setIsApDeleting] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isBooking, setIsBooking] = useState(false);
    const [processingAppointmentList, setProcessingAppointmentList] = useState(false);
    const [processingMemberList, setProcessingMemberList] = useState(false);
    const [allMemberList, setAllMemberList] = useState([]);
    const [appointmentListData, setAppointmentListData] = useState([]);
    const [col1, setcol1] = useState(true);
    const [col2, setcol2] = useState(false);
    const [col3, setcol3] = useState(false);
    const [col4, setcol4] = useState(false);
    const [nextReviewDate, setNextReviewDate] = useState('')
    const [appointmentDate, setAppointmentDate] = useState('')
    const [appointmentTime, setAppointmentTime] = useState('')
    const [memberId, setMemberId] = useState('')
    const [colIndex, setColIndex] = useState(0);
    const [offSet, setOffSet] = useState('10')
    const [pageURL, setPageURL] = useState('1')
    const [pageLinks, setPageLinks] = useState([])

    useEffect(() => {
        fetchMemberListData()
        fetchAllDietChartListData()
        fetchAllTrainerListData()
        fetchMemberWiseDietChart(paginatorInfo, searchText, null)
    }, [])

    useEffect(() => {
        !!assignDietModal && assignDiet()
    }, [assignDietModal])

    useEffect(() => {
        !!bookDietAppointmentModal && bookAppointment()
    }, [bookDietAppointmentModal]);

    useEffect(() => {
        !!viewDietAppointmentModal && viewBookAppointment()
    }, [viewDietAppointmentModal]);


    const fetchAllTrainerListData = async () => {
        try {
            const res = await axiosInstance.post(apiUrls.trainer.fetchTrainerByTrainerTypeId, { trainer_type_id: 6 });
            if (res.success == 1) {
                if (res.data.length > 0) {
                    setTrainers(!!res.data ? res.data : [])
                }
                else
                {
                    setTrainers([])
                }
            }
            else{
                setTrainers([])
            }
        } catch (error) {
            setTrainers([])
            setTrainerlistError(error)
        }
    }

    const fetchAppointmentList = async (appointmentListPaginatorInfo, searchAppointmentText, id) => {
        try {
            setProcessingAppointmentList(true);
            let params = {
                offset: !!appointmentListPaginatorInfo.perPage ? appointmentListPaginatorInfo.perPage: 5,
                search_text: searchAppointmentText,
                member_id: !!id ? id : ""
            }
            const res = await axiosInstance.post(apiUrls.diet.appointmentList + "?page=" + appointmentListPaginatorInfo.currentPage, params)
            // return 
            if (res.success == 1) {
                let data = res?.data?.data;
                setAppointmentListData(data);
                let aLPaginatorInfo = {
                    currentPage: parseInt(res?.data?.current_page),
                    perPage: parseInt(res?.data?.per_page),
                    total: parseInt(res?.data?.total),
                }
                setAppointmentListPaginatorInfo(aLPaginatorInfo);
                setSearchAppointmentText(searchAppointmentText);
                setProcessingAppointmentList(false);
            } else {
                setProcessingAppointmentList(false);
                resetAppointmentListPaginatorInfo();
                setAppointmentListData([]);
            }
        } catch (error) {
            setProcessingAppointmentList(false);
            resetAppointmentListPaginatorInfo();
        }
    }

    const fetchMemberWiseDietChart = async (paginatorInfo, searchText, id) => {
        let res
        try {
            id == null && setProcessingList(true)
            !!id ?
                res = await graphql({
                    query: DietQry.FETCH_MEMBER_WISE_ASSIGNED_DIET_PLAN_VIEW_QUERY,
                    variables: {
                        ...paginatorInfo,
                        // searchText: `%${searchText}%`,
                        id: id
                    }
                })
                :
                !!member_id ?
                    res = await graphql({
                        query: DietQry.FETCH_MEMBER_WISE_ASSIGNED_DIET_PLAN_QUERY,
                        variables: {
                            ...paginatorInfo,
                            searchText: `%${searchText}%`,
                            member_id: !!member_id ? member_id : '%%'
                        }
                    })
                    :
                    res = await axiosInstance.post(apiUrls.diet.memberDietPlanList + "?page=" + pageURL, { offset: offSet, search_text: searchText });
            // const res = await graphql(
            //     id == null ?
            //         {
            //             query: DietQry.FETCH_MEMBER_WISE_ASSIGNED_DIET_PLAN_QUERY,
            //             variables: {
            //                 ...paginatorInfo,
            //                 searchText: `%${searchText}%`,
            //                 member_id: !!member_id ? member_id : '%%'
            //             }
            //         }
            //         :
            //         {
            //             query: DietQry.FETCH_MEMBER_WISE_ASSIGNED_DIET_PLAN_VIEW_QUERY,
            //             variables: {
            //                 ...paginatorInfo,
            //                 // searchText: `%${searchText}%`,
            //                 id: id
            //             }
            //         }
            // );
            if (!!res.data.member_wise_diet_plans && res.data.member_wise_diet_plans.data) {
                if (id) {
                    setDietChartDetail(!!res.data.member_wise_diet_plans ? res.data?.member_wise_diet_plans?.data?.[0] : [])
                    setMemberId(res.data.member_wise_diet_plans.data[0].member_id)
                    setDietChartViewModal(true);
                }
                else {
                    setProcessingList(false)
                    setDietChartList(!!res.data.member_wise_diet_plans ? res.data.member_wise_diet_plans.data : [])
                    setPaginatorInfo(res?.data?.member_wise_diet_plans?.paginatorInfo);
                    setSearchText(searchText);
                }

            }
            else if (res.data) {
                setDietChartList(!!res?.data?.length > 0 ? res?.data : [])
                setPageLinks(!!res?.data?.length > 0 ? res?.links : [])
                setProcessingList(false)
            }

            else {
                if (id) {
                    setDietChartDetail([])
                }
                else {
                    setProcessingList(false)
                    setDietChartList([])
                    resetPaginatorInfo();
                }

            }
        } catch (error) {
            if (id) {
                setDietChartDetail([])
            }
            else {
                setProcessingList(false)
                setDietChartList([])
                resetPaginatorInfo();
            }
        }
    }

    const fetchMemberListData = async () => {
        let allMember = []
        try {
            setProcessingMemberList(true)
            const res = await axiosInstance.post(apiUrls.diet.membersListOfDietPlan);
            // const res = await graphql({
            //     query: MemberQry.FETCH_ALL_MEMBERS_FOR_MEMBERSHIP_QUERY,
            //     variables: {
            //         // ...paginatorInfo,
            //         // searchText: `%${searchText}%`,
            //         // id: "%%",
            //         // status_id: "%%"
            //     }
            // });

            if (res.success == 1) {
                setProcessingMemberList(false)
                setAllMemberList(!!res.data ? res.data : [])
                allMember = !!res.data ? res.data : []
            }

            else {
                setProcessingMemberList(false)
                setAllMemberList([])
                allMember = []
            }
        } catch (error) {
            setProcessingMemberList(false)
            setAllMemberList([])
            allMember = []
        }
    }

    const fetchAllDietChartListData = async () => {
        try {
            const res = await graphql({
                query: DietQry.FETCH_ALL_DIET_PLAN_TO_ASSIGN_QUERY

            });

            if (res.data.diet_plans.data) {
                let response = res.data.diet_plans.data
                setDietPlans(response)
            }
        } catch (error) {
            setError(error)
        }
    }

    const handleAppointmentTableChange = (appointmentListPaginatorInfo, searchAppointmentText) => {
        fetchAppointmentList(appointmentListPaginatorInfo, searchAppointmentText, memberId );
    }

    const handelAppointEdit = (cell, row) => {
        setDietBookingId(!!row?.id ? row.id : '');
        let fildValue = {
            appointment_date: (!!row?.appointment_date ? row.appointment_date : ""),
            appointment_time: (!!row?.appointment_time ? row.appointment_time : ""),
            trainer_id: (!!row?.trainer_id ? row?.trainer_id : ""),
            note: (!!row?.note ? row?.note : ""), 
        }
        setAppointmentDate((!!row?.appointment_date ? (moment(new Date(row.appointment_date)).format("DD-MM-YYYY")) : ""))
        setDietAppointmentData(fildValue);
        bookAppointment();

    }

    const handelAppointDelete = (cell, row) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then(async (willDelete) => {
            if (willDelete) { 
                if (!!row?.id) {
                    setIsApDeleting(true)
                    try {
                        const response = await axiosInstance.post(apiUrls.diet.deleteBookAppointment, {id: row.id})
                        if (response.success == 1) {
                            setIsApDeleting(false)
                            swal("Success!", response.message, "success");
                            runFetchAppointmentList()
                            fetchAppointmentList(appointmentListPaginatorInfo, searchAppointmentText, member_id);
                        } else {
                            setIsApDeleting(false)
                            swal("Oops!", response.message, "error");
                        }
                    } catch (err) {
                        setIsApDeleting(false)
                        swal("Oops!", "Something went wrong!", "error");
                        setError(err.message)
                    }
                }
            }
        });
    }

    const handleAppointmentAction = (cell, row) => {
        return (
            <>
                <div className="d-flex">
                    <Button 
                        className="btn-info btn-sm ms-2"
                        onClick={()=>{handelAppointEdit(cell, row)}}
                    >
                        <i className="bx bx-pencil align-middle" />
                    </Button>
                    <Button 
                        className="btn-danger btn-sm ms-2"
                        onClick={()=>{handelAppointDelete(cell, row)}}
                        disabled={isApDeleting}
                    >
                        <i className="bx bx-trash align-middle" />
                    </Button>
                </div>
            </>
        );
    }

    const appointmentColums = [
        // {
        //     dataField: 'id',
        //     text: 'SL NO',
        //     sort: true,
        // },
        // {
        //     dataField: 'trainer_id',
        //     text: 'TRAINER ID',
        //     sort: true,
        // },
        {
            dataField: 'name',
            text: 'TRAINER NAME',
            sort: true,
        },
        {
            dataField: 'nick_name',
            text: 'NICK NAME',
            sort: true,
        },
        {
            // dataField: 'appointment_date',
            formatter: (cell, row) => userDateFormat(!!row?.appointment_date ? row.appointment_date : ""),
            text: 'APPOINTMENT DATE',
            sort: true,
        },
        {
            // dataField: 'appointment_time',
            formatter: (cell, row) => (!!row?.appointment_time ? row.appointment_time : ""),
            text: 'APPOINTMENT TIME',
            sort: true,
        },
        // {
        //     dataField: 'note',
        //     text: 'NOTE',
        //     sort: true,
        // },
        {
            dataField: 'mobile_no',
            text: 'MOBILE NO',
            sort: true,
        },
        {
            dataField: 'email_id',
            text: 'EMAIL',
            sort: true,
        },
        {
            dataField: '',
            text: 'ACTION',
            sort: true,
            formatter: handleAppointmentAction
        }
    ];

    const handleAction = (cell, row) => {
        localStorage.setItem("d_memberId", "")
        localStorage.setItem("d_id", "")
        return (
            <>
                <div className="d-flex">
                    <Link
                        to="/diet-view"
                        onClick={(e) => {
                            localStorage.setItem("memberWise", true)
                            !!row.id ? localStorage.setItem("d_id", row.id) : localStorage.setItem("d_member_id", row.member_id)
                        }}
                        target="_blank"
                        className="btn btn-warning btn-sm ms-2">
                        <i className="fas fa-eye font-size-16 align-middle" />
                    </Link>
                    <Button className="btn-info btn-sm ms-2" onClick={(e) => props.history.push({ pathname: "/edit-diet-plan", state: { dietPlanId: !!row.id ? row.id : row.member_wise_diet_plan_id, assignMemberDietPlan: true, member_id: member_id, redirectPath: redirectPath } })}>
                        <i className="bx bx-pencil align-middle" />
                    </Button>
                    {!!member_id == true &&
                        <Button className="btn-danger btn-sm ms-2" onClick={(e) => { handleDelete(e, row) }}>
                            <i className="bx bx-trash align-middle" />
                        </Button>
                    }
                </div>
            </>
        );
    };

    const handleFetch = (e, row) => {
        fetchMemberWiseDietChart(paginatorInfo, searchText, row.id)
    }

    const handleDelete = (e, row) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (willDelete) => {
                if (willDelete) {
                    try {
                        const response = await axiosInstance.post(apiUrls.diet.deleteMemberWiseDietPlan, { id: row.id });
                        if (response.success == 1) {
                            swal("Success!", response.message, "success");
                            fetchMemberWiseDietChart(paginatorInfo, searchText, null)
                        }
                        else {
                            swal("Oops!", response.message, "error");
                        }
                    } catch (err) {
                        swal("Oops!", "Something went wrong!", "error");
                        setError(err.message)
                    }
                }
            });
    }

    const columns = [
        !!member_id == false && {
            // dataField: 'member_details[member_name]',
            formatter: (cell, row) => <Link to={{ pathname: "/member-details", state: { member_id: row.member_id, tabType: "5" } }}>{row.member_details.member_name}</Link>,
            text: 'MEMBER NAME',
            sort: true,
        },
        {
            dataField: 'plan_name',
            text: 'PLAN NAME',
            sort: true,
        },
        {
            dataField: 'dietitian_name',
            text: 'DIETITIAN NAME',
            sort: true,
        },
        {
            dataField: 'diet_plan_category_details[category_name]',
            text: 'CATEGORY',
            sort: true,
        },
        {
            dataField: '',
            text: 'ACTION',
            sort: true,
            formatter: handleAction
        }];

    const handleTableChange = (paginatorInfo, searchText) => {
        fetchMemberWiseDietChart(paginatorInfo, searchText, null)
    }

    const handleOffsetChange = (e) => {
        setOffSet(e.target.value)
    }

    const getPageNumber = (e, data) => {
        e.preventDefault()
        if (parseInt(data.label)) {
            setPageURL(data.label)
        } else {
            let pageNumber = data.url.split("=")
            setPageURL(pageNumber[1])
        }

    }

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    }

    useEffect(() => {
        !!member_id == false && fetchMemberWiseDietChart(paginatorInfo, searchText, null)
    }, [offSet, pageURL, searchText])

    const formikObj = useFormik({
        enableReinitialize: true,

        initialValues: { ...memberDietData },
        validationSchema: Yup.object({
            diet_plan_id: Yup.string().required("Please Select Diet Chart"),
        }),
        onSubmit: async (values) => {
            values.member_id = !!member_id ? member_id : values.member_id
            // return
            try {
                setIsSaving(true)
                const response = await axiosInstance.post(apiUrls.diet.addAndUpdateMemberWiseDietPlan, { ...values });
                if (response.success == 1) {
                    formikObj.resetForm()
                    setIsSaving(false)
                    swal("Success!", response.message, "success");
                    closeModal()
                    dispatch(setDietAssignedMember(true));
                    fetchMemberWiseDietChart(paginatorInfo, searchText, null)
                }
                else {
                    setIsSaving(false)
                    swal("Oops!", response.message, "error");
                }
            } catch (err) {
                setIsSaving(false)
                swal("Oops!", "Something went wrong!", "error");
                setError(err.message)
            }
        }
    });

    const formikAppointment = useFormik({
        enableReinitialize: true,

        initialValues: {...dietAppointmentData},
        validationSchema: Yup.object({
            appointment_date: Yup.string().required("Please Select Appointment Date"),
            appointment_time: Yup.string().required("Please Select Appointment Time"),
            trainer_id: Yup.string().required("Please Select Nutritionist"),
        }),
        onSubmit: async (values) => {
            try {
                setIsBooking(true);
                const response = await axiosInstance.post(apiUrls.diet.addAndUpdateBookAppointment, ((dietBookingId== null)? {...values, member_id: member_id} : {...values, member_id: member_id, id: dietBookingId}));
                if (response.success == 1) {
                    formikAppointment.resetForm();
                    setDietBookingId(null);
                    setDietAppointmentData({...initialAppointmentData})
                    setAppointmentDate('')
                    setIsBooking(false);
                    swal("Success!", response.message, "success");
                    runFetchAppointmentList()
                    bACloseModal();
                    {(dietBookingId !== null) && viewCloseModel()}
                } else {
                    setDietBookingId(null);
                    setDietAppointmentData({...initialAppointmentData})
                    setAppointmentDate('')
                    setIsBooking(false);
                    swal("Oops!", response.message, "error");
                }
            } catch (err) {
                setDietBookingId(null);
                setDietAppointmentData({...initialAppointmentData})
                setAppointmentDate('')
                setIsBooking(false);
                swal("Oops!", "Something went wrong!", "error");
                setError(err.message);
            }
        }
    });


    const bookAppointment = () => {
        formikAppointment.resetForm();
        bAOpenModal();
    }

    const viewBookAppointment = () => {
        fetchAppointmentList(appointmentListPaginatorInfo, searchAppointmentText, member_id);
        viewOpenModel();
    }

    const viewOpenModel = () => {
        setViewAppointmentModal(true);
    }

    const viewCloseModel = () => {
        setViewAppointmentModal(false);
        dispatch(setViewBookedAppointment(false));
    }

    const bAOpenModal = () => {
        setDietAppointmentModal(true);
    }

    const bACloseModal = () => {
        setDietBookingId(null)
        setDietAppointmentData({...initialAppointmentData})
        setAppointmentDate('')
        setDietAppointmentModal(false);
        dispatch(setDietBookedAppointment(false));
    }

    const assignDiet = () => {

        formikObj.resetForm();
        openModal()
    }

    const closeModal = () => {
        setAddEditAssignDietModal(false);
        dispatch(setDietAssignedMember(false));
    }

    const closeDietViewModal = () => {
        setDietChartViewModal(false);
    }

    const openModal = () => {
        setAddEditAssignDietModal(true);
    }

    const handleCol = (e, index) => {
        setColIndex(index)
    }

    const renderDietDetails = (dietPlanDetails) => {
        return dietPlanDetails?.map((data, index) => {
            return <div className="accordion-item" key={index}>
                <h1 className="accordion-header" id="headingOne">
                    <button
                        onClick={(e) => handleCol(e, index)}
                        data-event={index}
                        style={{ cursor: "pointer" }}
                        className={classnames(
                            "accordion-button",
                            "fw-medium",
                            { collapsed: colIndex == index ? false : true }
                        )}
                    >
                        {data?.id}&nbsp;&nbsp;
                    </button>
                </h1>
                <Collapse isOpen={colIndex === index} className="accordion-collapse">
                    <CardBody className="accordion-body">
                        <div className="text-end mb-4">
                            <Link
                                to="/diet-view"
                                onClick={(e) => {
                                    localStorage.setItem("memberWise", true)
                                    localStorage.setItem("memberId", dietChartDetail.member_id)
                                    localStorage.setItem("memberName", dietChartDetail.member_details.member_name)
                                    localStorage.setItem("dietPlanName", dietChartDetail.plan_name)
                                }}
                                target="_blank"
                                className="btn btn-info  btn btn-info btn-sm">
                                <i className="fas fa-eye" />
                            </Link>
                        </div>
                        <Row>
                            <Col lg={6}>
                                <Label className="col-form-label">
                                    <b>Time</b>
                                </Label>
                                <p>{data?.time}</p>
                            </Col>
                            <Col lg={6}>
                                <Label className="col-form-label">
                                    <b>Meal</b>
                                </Label>
                                <p>{data?.meal}</p>
                            </Col>
                        </Row>
                    </CardBody>
                </Collapse>
            </div>

        })

    }

    const nextReviewDateChange = (nextReviewDate) => {
        if (!!nextReviewDate[0]){
            setNextReviewDate(nextReviewDate[0].getDate() + '-' + (nextReviewDate[0].getMonth() + 1) + '-' + nextReviewDate[0].getFullYear())
            formikObj.setFieldValue("next_review_date", standartDateFormat(nextReviewDate[0]))
        } else {
            setNextReviewDate('');
            formikObj.setFieldValue("next_review_date", '')
        }
    }

    const handelAppointmentDateChange = (appointmentDate) => {
        if (!!appointmentDate[0]) {
            setAppointmentDate(appointmentDate[0].getDate() + '-' + (appointmentDate[0].getMonth() + 1) + '-' + appointmentDate[0].getFullYear());
            formikAppointment.setFieldValue("appointment_date", standartDateFormat(appointmentDate[0]));
        } else {
            setAppointmentDate('');
            formikAppointment.setFieldValue("appointment_date", '');
        }
    }

    const getMemberName = () => {
        let mid = !!member_id ? member_id : memberId
        let memberObj = !!allMemberList && allMemberList.find(item => item.id == mid)
        return memberObj?.member_name
    }
    return (
        <React.Fragment>
            <div className={!!member_id == false && "page-content"}>
                {!!member_id == false && <MetaTags>
                    <title>Members Diet Chart</title>
                </MetaTags>
                }
                <Container fluid={!!member_id == false && true}>
                    {!!member_id == false &&
                        <React.Fragment>
                            <Breadcrumbs title="Diet Plan" breadcrumbItem="Members Diet Chart" />
                            <Row>
                                <Col className="col-12">
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <Col lg={3}>
                                                    <Button
                                                        color="primary"
                                                        className="font-size-13 btn-block"
                                                        onClick={assignDiet}
                                                    >
                                                        <i className="mdi mdi-plus-circle-outline me-1 align-middle font-size-16" />
                                                        Assign
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </React.Fragment>
                    }

                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    {!!member_id == false && <Row>
                                        <Col lg={12}>
                                            <h3>All Members Diet Chart List</h3><br />
                                        </Col>
                                    </Row>}
                                    {!!member_id == false ?
                                        <>
                                            {!!dietChartList.length > 0 ?
                                                <>
                                                    <div className="search-box me-2 mb-2 d-inline-block">
                                                        <div className="position-relative">
                                                            <Label for="search-bar-0" className="search-label">
                                                                <span id="search-bar-0-label" className="sr-only">Search this table</span>
                                                                <Input id="search-bar-0"
                                                                    type="text"
                                                                    aria-labelledby="search-bar-0-label"
                                                                    className="form-control"
                                                                    placeholder="Search"
                                                                    // value=""
                                                                    onChange={(e) => handleSearch(e)}
                                                                />
                                                            </Label>
                                                            <i className="bx bx-search-alt search-icon"></i>
                                                        </div>
                                                    </div>
                                                    <Row>
                                                        <Col lg={12}>
                                                            <div className="table-responsive">
                                                                <Table className="table mb-0">
                                                                    <thead>
                                                                        <tr className="whitespace-no-wrap">
                                                                            <th>ID</th>
                                                                            <th>MEMBER NAME</th>
                                                                            <th>BEGIN DATE</th>
                                                                            <th>DIETITIAN NAME</th>
                                                                            <th>DIET CHART DATE</th>
                                                                            <th>ASSIGNED DIET CHART</th>
                                                                            <th>NEXT REVIEW DATE</th>
                                                                            <th>ACTION</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {dietChartList.map((item, index) => {
                                                                            return (
                                                                                <tr key={index}>
                                                                                    <th scope="row">
                                                                                        <Link to={{ pathname: "/member-details", state: { member_id: item.member_id, tabType: "5" } }}>{item.member_id}</Link>
                                                                                    </th>
                                                                                    <td>
                                                                                        <div className="d-flex align-items-center">
                                                                                            <Link to={{
                                                                                                pathname: "/member-details",
                                                                                                state: { member_id: item.member_id, tabType: "5" }
                                                                                            }}
                                                                                                style={{ color: item.diet_plan_flag == 1 && '#d50c38'}}
                                                                                            >
                                                                                                {item.member_name}
                                                                                            </Link>
                                                                                            <span className="badge rounded-pill bg-success ms-2 px-2 py-1">{item?.member_wise_diet_plans_count}</span>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>{!!item.begin_date ? moment(item.begin_date).format('DD-MM-YYYY') : '-'}</td>
                                                                                    <td>{item?.dietitian_name}</td>
                                                                                    <td>{!!item.diet_plan_diet ? moment(item.diet_plan_diet).format('DD-MM-YYYY') : "-"}</td>
                                                                                    <td>{item.assigned_diet_plan}</td>
                                                                                    <td style={{ color: item.diet_plan_flag == 1 && '#d50c38'}}>{!!item.next_review_date ? moment(item.next_review_date).format('DD-MM-YYYY') : '-'}</td>
                                                                                    <td style={{ whiteSpace: "nowrap" }}>{handleAction(null, item)}</td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                        }
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </Col>
                                                        <div className="mt-2">
                                                            <div style={{ float: "left" }}>
                                                                <Input
                                                                    type="select"
                                                                    id="formrow-serviceType"
                                                                    className="form-control"
                                                                    name={'offset'}
                                                                    onChange={(e) => handleOffsetChange(e)}
                                                                // value={formikObj.values.member_id}
                                                                >
                                                                    <option value="10">10</option>
                                                                    <option value="5">5</option>
                                                                    <option value="10">10</option>
                                                                    <option value="15">15</option>
                                                                </Input>
                                                            </div>
                                                            <div style={{ justifyContent: "end", display: "flex" }}>
                                                                <ul className="pagination react-bootstrap-table-page-btns-ul">
                                                                    {pageLinks.map((btn, index) => {
                                                                        return (
                                                                            <React.Fragment key={index}>
                                                                                {!!btn.url &&
                                                                                    <li className={!!btn.active ? "active page-item" : "page-item"}>
                                                                                        <a className="page-link" href="#" onClick={(e) => getPageNumber(e, btn)}>
                                                                                            {btn.label.includes("Next") ? ">" : btn.label.includes("Previous") ? "<" : btn.label}
                                                                                        </a>
                                                                                    </li>
                                                                                }
                                                                            </React.Fragment>
                                                                        )
                                                                    })
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </Row>
                                                </>
                                                :
                                                <Col lg={12} style={{ textAlign: "center" }}>
                                                    <h5><i className="bx bx-hourglass bx-spin font-size-24 align-middle me-2"></i>
                                                        Loading...</h5>
                                                </Col>

                                            }
                                        </>
                                        :
                                        <Row>
                                            <Col lg={12}>
                                                <CommonTable
                                                    paginatorInfo={paginatorInfo}
                                                    searchText={searchText}
                                                    onTableChange={handleTableChange}
                                                    keyField='id'
                                                    columns={columns}
                                                    data={dietChartList}
                                                    loading={processingList}
                                                />
                                            </Col>
                                        </Row>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* Assign workout Modal start */}

            <Modal
                isOpen={addEditAssignDietModal}
                toggle={closeModal}
                size="lg"
                backdrop="static"
            // className={props.className}
            >
                <ModalHeader toggle={closeModal} tag="h4">
                    Assign Diet
                </ModalHeader>
                <ModalBody>
                    <Form>
                        {!!member_id == false &&
                            <Row>
                                <Col lg={12}>
                                    <div className="mb-3">
                                        <Label htmlFor="formrow-InputService">Member<span className="text-danger">*</span></Label>
                                        <Input
                                            type="select"
                                            id="formrow-serviceType"
                                            className="form-control"
                                            name={'member_id'}
                                            onChange={formikObj.handleChange}
                                            value={formikObj.values.member_id}
                                            invalid={
                                                formikObj.touched.member_id && formikObj.errors.member_id ? true : false
                                            }
                                        >
                                            <option value="">Select</option>
                                            {allMemberList.map((item) => (
                                                <option value={item.id} key={item.id}>{item.member_name}</option>
                                            ))}

                                        </Input>
                                        {formikObj.touched.member_id && formikObj.errors.member_id ? (
                                            <FormFeedback type="invalid">{formikObj.errors.member_id}</FormFeedback>
                                        ) : null}
                                    </div>
                                </Col>
                            </Row>
                        }
                        <Row>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-serviceType">Select Diet<span className="text-danger">*</span></Label>
                                    <Input
                                        type="select"
                                        id="formrow-serviceType"
                                        className="form-control"
                                        name={'diet_plan_id'}
                                        onChange={formikObj.handleChange}
                                        value={formikObj.values.diet_plan_id}
                                        invalid={
                                            formikObj.touched.diet_plan_id && formikObj.errors.diet_plan_id ? true : false
                                        }
                                    >
                                        <option defaultValue>Select</option>
                                        {dietPlans?.map((item) => (
                                            <option value={item.id} key={item.id}>{item.plan_name}</option>
                                        ))}

                                    </Input>
                                    {formikObj.touched.diet_plan_id && formikObj.errors.diet_plan_id ? (
                                        <FormFeedback type="invalid">{formikObj.errors.diet_plan_id}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-serviceType">Next Review Date</Label>
                                    <Flatpickr
                                        className="form-control d-block"
                                        name="next_review_date"
                                        placeholder="DD-MM-YYYY"
                                        options={{
                                            altInput: true,
                                            altFormat: "d-m-Y",
                                            dateFormat: "d-m-Y",
                                            // minDate: today
                                        }}
                                        onChange={nextReviewDateChange}
                                        value={nextReviewDate}
                                    />
                                </div>
                            </Col>
                        </Row>

                    </Form>
                </ModalBody>
                <div className="modal-footer">
                    {!!isSaving ?
                        <button className="btn btn-primary " disabled>
                            Processing
                        </button>
                        :
                        <>
                            <button
                                type="button"
                                onClick={closeModal}
                                className="btn btn-secondary "
                                data-dismiss="modal"
                            >
                                Close
                            </button>
                            <button
                                type="submit"
                                className="btn btn-primary "
                                onClick={(e) => {
                                    e.preventDefault();
                                    formikObj.handleSubmit();
                                    return false;
                                }}
                            >
                                Save
                            </button>
                        </>
                    }
                </div>
            </Modal>

            {/* Assign workout Modal end */}

            {/* Book Appointment Model Start */}

            <Modal
                isOpen={dietAppointmentModal}
                toggle={bACloseModal}
                size="lg"
                backdrop="static"
            >
                <ModalHeader toggle={bACloseModal} tag="h4">
                    {(dietBookingId == null) ? 'Book Appointment' : 'Edit Appointment'}
                </ModalHeader>

                <ModalBody>
                    <Form>
                        <Row>
                        <Col lg={6}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-serviceType">Appointment Date<span className="text-danger">*</span></Label>
                                    <Flatpickr
                                        className="form-control d-block"
                                        name="appointment_date"
                                        placeholder="DD-MM-YYYY"
                                        options={{
                                            altInput: true,
                                            altFormat: "d-m-Y",
                                            dateFormat: "d-m-Y",
                                            // minDate: today
                                        }}
                                        // onChange={(e) => handelAppointmentDateChange(e)}
                                        onChange={handelAppointmentDateChange}
                                        value={appointmentDate}
                                    />
                                    {(formikAppointment.touched.appointment_date && formikAppointment.errors.appointment_date) &&
                                        <div style={{ fontSize: "smaller", color: "#f46a6a" }}>{formikAppointment.errors.appointment_date}</div>
                                    }
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-serviceType">Appointment Time<span className="text-danger">*</span></Label>
                                    <Flatpickr
                                        className="form-control d-block"
                                        name="appointment_time"
                                        placeholder="DD-MM-YYYY"
                                        options={{
                                            noCalendar: true,
                                            enableTime: true,
                                            dateFormat: 'h:i K',
                                        }}
                                        onChange={(e, timeChange) => {
                                            formikAppointment.setFieldValue("appointment_time", timeChange)
                                        }}
                                        value={formikAppointment.values.appointment_time}
                                    />
                                    {(formikAppointment.touched.appointment_time && formikAppointment.errors.appointment_time) &&
                                        <div style={{ fontSize: "smaller", color: "#f46a6a" }}>{formikAppointment.errors.appointment_time}</div>
                                    }
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-serviceType">Nutritionist<span className="text-danger">*</span></Label>
                                    <Input
                                        type="select"
                                        className="form-control"
                                        name="trainer_id"
                                        onChange={formikAppointment.handleChange}
                                        value={formikAppointment.values.trainer_id}
                                        invalid={
                                            formikAppointment.touched.trainer_id && formikAppointment.errors.trainer_id ? true : false
                                        }
                                    >
                                        <option value="">Select</option>
                                        {trainers?.map((item) => (
                                            <option value={item.id} key={item.id}>{item.name + " (" + item.nick_name + ")"}</option>
                                        ))}
                                    </Input>
                                    {(formikAppointment.touched.trainer_id && formikAppointment.errors.trainer_id) &&
                                        <FormFeedback type="invalid">{formikAppointment.errors.trainer_id}</FormFeedback>
                                    }
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-serviceType">Note</Label>
                                    <Input
                                        type="textarea"
                                        className="form-control"
                                        name="note"
                                        placeholder="Enter Note"
                                        onChange={formikAppointment.handleChange}
                                        value={formikAppointment.values.note}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>

                <div className="modal-footer">
                    { isBooking ? 
                        <><button className="btn btn-primary " disabled>
                            Processing
                        </button></> 
                    : 
                        <>
                            <button
                                type="button"
                                onClick={bACloseModal}
                                className="btn btn-secondary "
                                data-dismiss="modal"
                            >
                                Close
                            </button>
                            &nbsp;
                            <button
                                type="submit"
                                className="btn btn-primary "
                                onClick={(e) => {
                                    e.preventDefault();
                                    formikAppointment.handleSubmit();
                                    return false;
                                }}
                            >
                                {(dietBookingId == null) ? 'Save' : 'Update'}
                            </button>
                        </>
                    }
                </div>

            </Modal>

            {/* Book Appointment Model End */}

            {/* View Appointment Model Start */}

            <Modal
                isOpen={viewAppointmentModal}
                toggle={viewCloseModel}
                size="lg"
                backdrop="static"
            >
                <ModalHeader toggle={viewCloseModel} tag="h4">
                    View Appointment
                </ModalHeader>
                
                <ModalBody>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    {/* <Row className="mb-4">
                                        <Col lg={12}>
                                            <h3>All Appointment list</h3>
                                        </Col>
                                    </Row> */}
                                    <Row>
                                        <Col lg={12}>
                                            <CommonTable
                                                paginatorInfo={appointmentListPaginatorInfo}
                                                searchText={searchAppointmentText}
                                                onTableChange={handleAppointmentTableChange}
                                                keyField='id'
                                                columns={appointmentColums}
                                                data={appointmentListData}
                                                loading={processingAppointmentList}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </ModalBody>

                <div className="modal-footer">
                    <>
                    <button
                        type="button"
                        onClick={viewCloseModel}
                        className="btn btn-secondary "
                        data-dismiss="modal"
                    >
                        Close
                    </button>
                    </>
                </div>
            </Modal>

            {/* View Appointment Model End */}


            {/* Assigned Diet View Modal start*/}

            <Modal
                isOpen={dietChartViewModal}
                toggle={closeDietViewModal}
                size="lg"
                backdrop="static"
            // className={props.className}
            >
                <ModalHeader toggle={closeDietViewModal} tag="h4">
                    Diet Chart Detail
                </ModalHeader>
                <ModalBody>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col lg={12}>
                                    <CardTitle style={{ textAlign: "center", marginTop: "-3%" }}>
                                        <div className="mb-2">
                                            <span className="member-name">
                                                {getMemberName()}
                                            </span>
                                            <span className="badge rounded-pill" style={{ backgroundColor: "#DC2450", fontSize: "inherit" }}>ID:{member_id || memberId}</span>
                                        </div>
                                        <div>
                                            <label>Diet:</label>&nbsp;
                                            <span>{dietChartDetail?.plan_name}</span>
                                        </div>
                                    </CardTitle>
                                    {dietChartDetail?.diet_plan_details?.length > 0 &&
                                        <div>
                                            {renderDietDetails(dietChartDetail?.diet_plan_details)}
                                        </div>
                                    }
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </ModalBody>
            </Modal>

            {/* Assigned workout view modal end */}

        </React.Fragment>
    );
}

export default withRouter(AssignMemberDietChart);

AssignMemberDietChart.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    member_id: PropTypes.number,
    runFetchAppointmentList: PropTypes.any,
    viewDietAppointmentModal: PropTypes.bool,
    bookDietAppointmentModal: PropTypes.bool,
    assignDietModal: PropTypes.bool,
    redirectPath: PropTypes.string
};