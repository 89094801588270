import React from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"
import { authStorage } from "utils/auth"

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      if (isAuthProtected && !authStorage.getToken()) {
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
      const activePaths = authStorage.getActivePaths();
      // console.log(
      //   activePaths  , isAuthProtected, !activePaths?.includes(rest.path) , !rest.isCommon,"rest.isCommon",rest.isCommon,"res",rest.path)
    if (activePaths
      && isAuthProtected
      && !activePaths.includes(rest.path)
      && !rest.isCommon) {
        return <Redirect
          to={{ pathname: "/dashboard" }}
        />
      }
return (
  <Layout>
    <Component {...props} />
  </Layout>
)
    }}
/>
)

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
}

export default Authmiddleware;
