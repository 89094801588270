import { generateSearchQuery } from "utils/graphql"


const searchQry = generateSearchQuery([
  'member_name',
  'member_age',
  'profession',
  'gender',
  'mobile_no',
  'email_id',
  'status'])

  // FETCH ALL MEMBERS

export const FETCH_ALL_MEMBERS_QUERY = `query allMembersList($perPage: Int!, $currentPage: Int, $searchText: Mixed, $id: Mixed, $status_id: Mixed){
    members (
            first:$perPage,page:$currentPage,
            where:{
                AND:[{column:_id, operator: LIKE value: $id}{column:_membership_status operator: LIKE value: $status_id}]
                ${searchQry}
            }
            orderBy:{column:_id ,order:DESC}
        ) 
        {
          data {
            id
            member_name
            member_age
            member_access_code
            member_access_code_status
            profile_image
            id_proof
            status
            member_status
            membership_details (orderBy:{column:_id ,order:DESC})  {
              id
              invoice_num
              status
              beginning_date
              end_date
              number_of_sessions
              subscription_details {
                id
                plan_name
                plan_type {
                  plan_type_name
                }
              }
              preferred_timing_details {
                id
                preferred_timing_name
              }
            }
            profession
            gender
            mobile_no
            email_id
            address
            body_composition_applicable
            diet_chart_applicable
            workout_chart_applicable
            status
            diet_details{
              id
              member_id
              plan_name
              dietitian_name
              note
              next_review_date
              diet_plan_category_id
            }
            workout_plan_details{
              id
              workout_plan_name
            }
            body_compositions_details{
              id
              entry_date
            }
            gymbag_accessories_details (where : {column:_accessory_id operator:EQ value:1}) {
              accessory_id
            }
            shippper_accessories_details (where : {column:_accessory_id operator:EQ value:2}) {
              accessory_id
            }

            shiper_membership {
              subscription_details {
                subscription_plan_accessories (where : {column:_accessories_id operator:EQ value:2}) {
                  accessories_id
                }
              }
            }
            
            gymbag_membership {
              subscription_details {
                subscription_plan_accessories (where : {column:_accessories_id operator:EQ value:1}) {
                  accessories_id
                }
              }
            }
          }
          paginatorInfo {
            currentPage
            total
            perPage
        }
        }
        
      }
`

// FETCH ALL MEMBERS FOR MEMBERSHIP

export const FETCH_ALL_MEMBERS_FOR_MEMBERSHIP_QUERY = `query allMembersListDD{
  members (
          orderBy:{column:_member_name ,order:ASC}
      ) 
      {
        data {
          id
          member_name
          member_age
          member_access_code
          status
        }
        
      }
      
    }
`

// FETCH MEMBER WISE ASSIGNED WORKOUT PLANS

const assignWorkoutSearchQry = generateSearchQuery(['workout_plan_name'])

export const FETCH_MEMBER_WISE_ASSIGNED_WORKOUT_PLANS_QUERY = `query memberAssignedWorkoutPlan($perPage: Int!, $currentPage: Int, $searchText: Mixed, $member_id: Mixed){
  memberwise_workout_plans (
          first:$perPage,page:$currentPage,
          where:{
              AND:[{column:_member_id, operator: LIKE value: $member_id}]
              ${assignWorkoutSearchQry}
          }
          orderBy:{column:_id ,order:DESC}
      ) 
      {
        data {
          id
          workout_plan_name
          day_one_target
          day_two_target
          day_three_target
          day_four_target
          day_five_target
          day_six_target
          description
          prepared_by
          goal
          member_id
          member_details{
            id
            member_name
            member_age
            profession
            gender
            mobile_no
            email_id
            status
            address
          }
          workout_plan_details {
            id
            workout_plan_id
            
            workout_plan_category_details {
              id
              category_name
            }
            
            workout_plan_exercise_details {
              id
              exercise_name
              exercise_type {
                id
                exercise_type_name
              }
            }
            no_of_set
            no_of_rep
            duration
            day
          }
          created_at
      }
      paginatorInfo {
          currentPage
          total
          perPage
      }
      }
    }
`


export const FETCH_MEMBER_WISE_ASSIGNED_WORKOUT_PLANS_VIEW_QUERY = `query memberAssignedWorkoutPlanView($perPage: Int!, $currentPage: Int, $id: Mixed){
  memberwise_workout_plans (
          first:$perPage,page:$currentPage,
          where:{
              AND:[{column:_id, operator: LIKE value: $id}]
          }
          orderBy:{column:_id ,order:DESC}
      ) 
      {
        data {
          id
          workout_plan_name
          day_one_target
          day_two_target
          day_three_target
          day_four_target
          day_five_target
          day_six_target
          description
          prepared_by
          goal
          member_id
          member_details{
            id
            member_name
            member_age
            profession
            gender
            mobile_no
            email_id
            status
            address
          }
          workout_plan_details {
            id
            workout_plan_id
            
            workout_plan_category_details {
              id
              category_name
            }
            
            workout_plan_exercise_details {
              id
              exercise_name
              exercise_type {
                id
                exercise_type_name
              }
            }
            no_of_set
            no_of_rep
            duration
            duration_type
            day
          }
          created_at
      }
      paginatorInfo {
          currentPage
          total
          perPage
      }
      }
    }
`