export const FILETYPE = {
    IMAGE: 'image',
    PDF: 'pdf',
    OTHER: 'other'
}
export const checkFileType = (name) => {
    const image_extensions = ["jpeg", "jpg", "png", "gif", "bmp", "tiff", "tif", "svg", "webp", "heif", "heic", "psd"];
    const pdf_extensions = ["pdf"];
    const arr = name.split(".");
    const ext = arr[arr.length - 1];
    console.log('ext', ext);
    if (image_extensions.includes(ext)) {
        return FILETYPE.IMAGE;
    }
    else if (pdf_extensions.includes(ext)) {
        return FILETYPE.PDF;
    }
    else return FILETYPE.OTHER;

}