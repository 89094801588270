import { generateSearchQuery } from "utils/graphql"

const searchQry = generateSearchQuery(['name','email_id', 'nick_name','primary_contact_no'])

    export const FETCH_ALL_TRAINER_QUERY = `query allTrainerList($perPage: Int!, $currentPage: Int, $searchText: Mixed, $id: Mixed)
    {
        trainers (
            first:$perPage,page:$currentPage,
            where:{
                AND:[{column:_id, operator: LIKE value: $id}]
                ${searchQry}
            }
            orderBy:{column:_id ,order:DESC}
        )
        {
            data{
                id
                email_id
                nick_name
                name
                primary_contact_no
                secondary_contact_no
                gender
                date_of_birth
                joining_date
                address
                experience
                position
                profile_pic
                status
                trainer_type_details {
                    trainer_type_id
                    trainer_types{
                        id
                        trainer_type
                    }
                  }
            }
            paginatorInfo {
                currentPage
                total
                perPage
            }
        }
    }
    `

// FETCH ALL TRAINER TYPE
export const FETCH_TRAINER_TYPE_QUERY = `query trainer_types{
    trainer_types {
      id
      trainer_type
    }
  }
  `

  // FETCH ALL Days
export const FETCH_DAYS_QUERY = `query days {
    days  {
        id
        days
    }
  }
  `

  // FETCH ACTIVITIES
export const ACTIVITIES_LIST_QUERY = `query activitiesList{
    activities (where :{column:_id operator:NOT_IN value:[6]})  {
      id
      activity
    }
  }
  `

  export const FETCH_ALL_TRAINER_LIST_QUERY = `query allTrainerListDD
  {
      trainers (
          orderBy:{column:_id ,order:DESC}
      )
      {
          data{
              id
              email_id
              nick_name
              name
              primary_contact_no
              secondary_contact_no
              gender
              date_of_birth
              joining_date
              address
              experience
              position
              profile_pic
              status
              trainer_type_details {
                  trainer_type_id
                }
          }
          paginatorInfo {
              currentPage
              total
              perPage
          }
      }
  }
  `