import { apiUrls, axiosInstance } from "api";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { withRouter } from "react-router-dom";
import { Card, CardBody, Col, Container, Input, Label, Row, Table } from "reactstrap";
import Flatpickr from "react-flatpickr";
import Breadcrumbs from "../../components/Common/Breadcrumb";

const FinancialReport = (props) => {

    const [processingList, setProcessingList] = useState(false);
    const [totalProcessingList, setTotalProcessingList] = useState(true);
    const [searchText, setSearchText] = useState('');
    const [paymentMode, setPaymentMode] = useState('');
    const [planType, setPlanType] = useState('');
    const [dateRange, setDateRange] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [offSet, setOffSet] = useState('10');
    const [offSetSelectAll, setOffSetSelectAll] = useState(false);
    const [offSetLength, setOffSetLength] = useState('');
    const [pageURL, setPageURL] = useState('1');
    const [pageLinks, setPageLinks] = useState([]);
    const [allFinancialReportList, setAllFinancialReportList] = useState([]);
    const [totalAmount, setTotalAmount] = useState('');
    const [totalBank, setTotalBank] = useState('');
    const [totalCash, setTotalCash] = useState('');

    useEffect(() => {
        fetchAllFinancialReportData();
    }, [offSet, pageURL, searchText, paymentMode, planType, dateRange]);


    const fetchAllFinancialReportData = async () => {
        let res;
        try {
            setProcessingList(true);
            // setTotalProcessingList(true);
            res = await axiosInstance.post(apiUrls.report.financialReportList + "?page=" + pageURL, { offset: offSet, search_text: searchText, payment_mode: paymentMode, plan_type: planType, start_date: startDate, end_date: endDate });
            if (res.success == 1) {
                setTotalAmount(!!res?.total_amount_collected ? res.total_amount_collected : '');
                setTotalBank(!!res?.total_in_bank ? res.total_in_bank : '');
                setTotalCash(!!res?.total_in_cash ? res.total_in_cash : '');
                if (res.data) {
                    setAllFinancialReportList(!!res?.data?.data?.length > 0 ? res?.data?.data : []);
                    setPageLinks(!!res?.data?.data?.length > 0 ? res?.data?.links : []);
                    setOffSetLength((res?.data?.total).toString());
                } else {
                    setAllFinancialReportList([]);
                }
                setTotalProcessingList(false);
                setProcessingList(false);
            } else {
                setTotalAmount('');
                setTotalBank('');
                setTotalCash('');
                setAllFinancialReportList([]);
                setTotalProcessingList(false);
                setProcessingList(false);
            }
        } catch (error) {
            setTotalProcessingList(false);
            setProcessingList(false);
            setAllFinancialReportList([]);
        }
    }

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };

    const filterFinancialReportByDateRange = (e) => {
        if (e[0] && e[1]) {
            setStartDate((e[0].getFullYear()) + "-" + (((e[0].getMonth() + 1) / 10) < 1 ? ("0" + (e[0].getMonth() + 1)) : (e[0].getMonth() + 1)) + "-" + ((e[0].getDate() / 10) < 1 ? ("0" + (e[0].getDate())) : (e[0].getDate())));
            setEndDate((e[1].getFullYear()) + "-" + (((e[1].getMonth() + 1) / 10) < 1 ? ("0" + (e[1].getMonth() + 1)) : (e[1].getMonth() + 1)) + "-" + ((e[1].getDate() / 10) < 1 ? ("0" + (e[1].getDate())) : (e[1].getDate())));
            setDateRange(!dateRange);
        }
         else if (e.length == '') {
            setStartDate('');
            setEndDate('');
            setDateRange(!dateRange);
         }
    }

    const filterFinancialReportByPayment = (e) => {
        setPaymentMode(e.target.value);
    }

    const filterFinancialReportByPackage = (e) => {
        setPlanType(e.target.value);
    }

    const handleOffsetChange = (e) => {
        if ((e.target.value) == 'All') {
            setOffSet(offSetLength);
            setPageURL('1');
            setOffSetSelectAll(true);
        } else {
            setOffSet(e.target.value);
            setOffSetSelectAll(false);
            if ((parseInt(offSetLength)) <= (parseInt(e.target.value))) {
                setPageURL('1');
            }
        }
    }

    const getPageNumber = (e, data) => {
        e.preventDefault()
        if (parseInt(data.label)) {
            setPageURL(data.label)
        } else {
            let pageNumber = data.url.split("=")
            setPageURL(pageNumber[1])
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Financial Report</title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumbs title="Report" breadcrumbItem="Financial Report" />
                    <Row>
                        <Col className="col-12"></Col>
                        <Card>
                            <CardBody>
                                <Row className="mb-4">
                                    <Col lg={4}>
                                        <h3>All Financial Report list</h3>
                                    </Col>
                                    <Col lg={8}>
                                        <div className="filter">
                                            <Flatpickr
                                                className="form-control filter-date-width"
                                                placeholder="Enter Date Range"
                                                options={{
                                                    mode: "range",
                                                    dateFormat: "d-m-Y"
                                                }}
                                                onChange={(e) => filterFinancialReportByDateRange(e)}
                                            />
                                            <select className="btn btn-outline-info ms-md-2 form-select w-auto pe-5 mb-3 mb-md-0" onChange={(e) => filterFinancialReportByPayment(e)}>
                                                <option value="">Payment Mode</option>
                                                <option value="1">Bank</option>
                                                <option value="2">Cash</option>
                                            </select>
                                            <select className="btn btn-outline-info ms-md-2 form-select w-auto pe-5 mb-3 mb-md-0" onChange={(e) => filterFinancialReportByPackage(e)}>
                                                <option value="">Package/Item</option>
                                                <option value="1">Package</option>
                                                <option value="2">Item</option>
                                            </select>
                                        </div>
                                    </Col>
                                </Row>
                                <hr/>
                                {/* <>
                                    <div className="search-box me-2 mb-2 d-inline-block">
                                        <div className="position-relative">
                                            <Label for="search-bar-0" className="search-label">
                                                <span id="search-bar-0-label" className="sr-only">Search this table</span>
                                                <Input id="search-bar-0"
                                                    type="text"
                                                    aria-labelledby="search-bar-0-label"
                                                    className="form-control"
                                                    placeholder="Search"
                                                    value={searchText}
                                                    onChange={(e) => handleSearch(e)}
                                                />
                                            </Label>
                                            <i className="bx bx-search-alt search-icon"></i>
                                        </div>
                                    </div>
                                </> */}
                                {!totalProcessingList &&
                                <>
                                    <Row>
                                        <Col lg={4}><h5><b style={{color: '#1aa3ff'}}>Total Amount Collected - </b><b style={{color: '#1aa3ff'}}>{totalAmount}</b></h5></Col>
                                        <Col lg={4}><h5><b style={{color: '#1aa3ff'}}>Total in Bank - </b><b style={{color: '#1aa3ff'}}>{totalBank}</b></h5></Col>
                                        <Col lg={4}><h5><b style={{color: '#1aa3ff'}}>Total in Cash - </b><b style={{color: '#1aa3ff'}}>{totalCash}</b></h5></Col>
                                    </Row>
                                    <hr/>
                                </>
                                }
                                {/* database strt */}
                                <Row>
                                    {processingList &&
                                        <Col lg={12} style={{ textAlign: "center" }}>
                                            <h5><i className="bx bx-hourglass bx-spin font-size-24 align-middle me-2"></i>
                                                Loading...</h5>
                                        </Col>
                                    }
                                    {!processingList &&
                                        <div>
                                            <>
                                                {allFinancialReportList.length > 0 ?
                                                    <>
                                                        <Row>
                                                            <Col lg={12}>
                                                                <div className="table-responsive">
                                                                    <Table className="table mb-0">
                                                                        <thead>
                                                                            <tr style={{ whiteSpace: "nowrap" }}>
                                                                                <th>Date</th>
                                                                                <th>Collected from</th>
                                                                                <th>Package / Item</th>
                                                                                <th>Qty</th>
                                                                                <th>Total Amount</th>
                                                                                <th>GST Collected</th>
                                                                                <th>Payment Mode</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {allFinancialReportList.map((item, index) => (
                                                                                <tr key={index}>
                                                                                    <td>{item.date ? moment(item.date).format('DD-MM-YYYY') : '-'}</td>
                                                                                    <td>{item.member_name}</td>
                                                                                    <td>{item.plan_type_name}</td>
                                                                                    <td>{(item.plan_type_name == 'Item') ? (item.quantity) : '-'}</td>
                                                                                    <td>{item.total_amount}</td>
                                                                                    <td>{item.gst_amount}</td>
                                                                                    <td>{item.payment_mode}</td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </Table>
                                                                </div>
                                                            </Col>
                                                            <div className="mt-2">
                                                                <div style={{ float: "left" }}>
                                                                    <Input
                                                                        type="select"
                                                                        id="formrow-serviceType"
                                                                        className="form-control"
                                                                        name={'offset'}
                                                                        onChange={(e) => handleOffsetChange(e)}
                                                                        value={offSetSelectAll ? "All" : offSet}
                                                                    >
                                                                        <option value="10">10</option>
                                                                        <option value="20">20</option>
                                                                        <option value="50">50</option>
                                                                        <option value="100">100</option>
                                                                        <option value="All">All</option>
                                                                    </Input>
                                                                </div>
                                                                <div style={{ justifyContent: "end", display: "flex" }}>
                                                                    <ul className="pagination react-bootstrap-table-page-btns-ul">
                                                                        {pageLinks.map((btn, index) => {
                                                                            return (
                                                                                <React.Fragment key={index}>
                                                                                    {!!btn.url &&
                                                                                        <li className={!!btn.active ? "active page-item" : "page-item"}>
                                                                                            <a className="page-link" href="#" onClick={(e) => getPageNumber(e, btn)}>
                                                                                                {btn.label.includes("Next") ? ">" : btn.label.includes("Previous") ? "<" : btn.label}
                                                                                            </a>
                                                                                        </li>
                                                                                    }
                                                                                </React.Fragment>
                                                                            )
                                                                        })
                                                                        }
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </Row>
                                                    </>
                                                    :
                                                    <Col lg={12} style={{ textAlign: "center" }}>
                                                        <h5>No Data Found</h5>
                                                    </Col>
                                                }
                                            </>
                                        </div>
                                    }
                                </Row>
                                {/* database End */}
                            </CardBody>
                        </Card>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default withRouter(FinancialReport);