import React, { useState } from 'react'

export default function usePaginator(currentPage = 1, perPage = 10) {
    const [paginatorInfo, setPaginatorInfo] = useState({
        currentPage,
        perPage,
        total: 0
    })
    const resetPaginatorInfo = () => {
        setPaginatorInfo({
            currentPage,
            perPage,
            total: 0
        })
    }
    return [
        paginatorInfo,
        setPaginatorInfo,
        resetPaginatorInfo
    ]
}
