import { generateSearchQuery } from "utils/graphql"

// FETCH PLAN PATTERNS
export const PLAN_PATTERNS_QUERY = `query planPatterns{
    plan_patterns
    {
        id
        plan_pattern_name
    }
}
`

// FETCH ALL PAYMENT MODE
export const FETCH_PAYMENT_MODE_QUERY = `query paymenyType{
  payment_mode {
    id
    payment_mode
  }
}
`


// FETCH PLAN TYPES
export const PLAN_TYPES_QUERY = `query planTypes{
    plan_types
    {
        id
        plan_type_name
    }
}
`

// FETCH ACCESSORIES
export const PLAN_ACCESSORIES_QUERY = `query accessories{
  accessories {
    id 
   accessory_name
   }
}
`

// FETCH ALL SUBSCRIPTIONS
const searchQry = generateSearchQuery(['plan_name', 'status'])
export const FETCH_ALL_SUBSCRIPTIONS_QUERY = `query allSubscriptionList($perPage: Int!, $currentPage: Int, $searchText: Mixed, $id: Mixed){
    subscription_plans (
            first:$perPage,page:$currentPage,
            where:{
                AND:[{column:_id, operator: LIKE value: $id}]
                ${searchQry}
            }
            orderBy:{column:_id ,order:DESC}
        ) 
        {
          data {
            id
            plan_pattern {
              id
              plan_pattern_name
            }
            plan_type {
              id
              plan_type_name
            }
            
            plan_name
            status
            created_at
            total_amount

            subscription_plan_accessories {
              id
              subscription_plan_id
              accessories_id
              accessories_details {
                id
                accessory_name
              }
            }

            subscription_plan_activities {
              id
              number_of_session
              activity_details {
                id
                activity
              }
              activity_type_details
              {
                id
                activity_type
              }
            }
            
          }
          paginatorInfo {
            currentPage
            total
            perPage
        }
        }
        
      }

`

export const FETCH_ALL_SUBSCRIPTIONS_QUERY_FOR_MEMBER = `query allSubscriptionList {
  subscription_plans (
          orderBy:{column:_id ,order:DESC}
      ) 
      {
        data {
          id
          plan_pattern {
            id
            plan_pattern_name
          }
          plan_type {
            id
            plan_type_name
          }
          
          plan_name
          status
          created_at
          total_amount

          subscription_plan_accessories {
            id
            subscription_plan_id
            accessories_id
            accessories_details {
              id
              accessory_name
            }
          }

          subscription_plan_activities {
            id
            number_of_session
            activity_details {
              id
              activity
            }
            activity_type_details
            {
              id
              activity_type
            }
          }
          
        }
        paginatorInfo {
          currentPage
          total
          perPage
      }
      }
      
    }

`


export const FETCH_EDIT_SUBSCRIPTIONS_QUERY = `query editSubscriptionList($id: Mixed){
  subscription_plans (
          where:{
              AND:[{column:_id, operator: LIKE value: $id}]
          }
          orderBy:{column:_id ,order:DESC}
      ) 
      {
        data {
          id
          plan_pattern {
            id
            plan_pattern_name
          }
          plan_type {
            id
            plan_type_name
          }
          
          plan_name
          status
          created_at
          total_amount

          subscription_plan_accessories {
            id
            subscription_plan_id
            accessories_id
            accessories_details {
              id
              accessory_name
            }
          }

          subscription_plan_activities {
            id
            number_of_session
            is_primary
            activity_details {
              id
              activity
            }
            activity_type_details
            {
              id
              activity_type
            }
          }
          
        }
        paginatorInfo {
          currentPage
          total
          perPage
      }
      }
      
    }

`


// FETCH ACTIVITY TYPES
export const ACTIVITY_TYPES_QUERY = `query activityTypes{
  activity_types {
    id
    activity_type
  }
}
`

// FETCH ACTIVITIES
export const ACTIVITIES_LIST_QUERY = `query activitiesList{
  activities  {
    id
    activity
  }
}
`

export const FETCH_ALL_SUBSCRIPTIONS_QUERY_FOR_DASHBOARD = `query allSubscriptionList {
  subscription_plans (
          orderBy:{column:_id ,order:DESC}
      ) 
      {
        data {
          id
          plan_pattern {
            id
            plan_pattern_name
          }
          plan_type {
            id
            plan_type_name
          }
          
          plan_name
          status
          created_at
          total_amount
          subscription_plan_accessories {
            id
            subscription_plan_id
            accessories_id
            accessories_details {
              id
              accessory_name
            }
          }
          
        }
      }
      
    }
`