import PropTypes from 'prop-types'
import React from 'react'
import { Button } from 'reactstrap'

 function LoadingButton({
    loading,
    loadingText = 'Processing...',
    children,
    ...rest
}) {
    return (
        <Button
            disabled={loading}
            {...rest}
        >
            {loading ? loadingText : children}
        </Button>
    )
}
LoadingButton.propTypes = {
    loading: PropTypes.bool,
    loadingText: PropTypes.string,
    children:PropTypes.any
  }
  export default LoadingButton