import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { useSelector, useDispatch } from "react-redux"
import { withRouter, useHistory, Link } from "react-router-dom"

// users
import user1 from "../../../assets/images/users/avatar-1.jpg"
import { apiUrls, axiosInstance } from "api"
import { logoutSuccess } from "store/actions"
import { authStorage } from "utils/auth"
import images from "assets/images"

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const dispatch = useDispatch()
  const profileData = useSelector(state => state.Profile.data);
  const profileLoading = useSelector(state => state.Profile.loading);
  const history = useHistory()
  const [menu, setMenu] = useState(false)
  const handleLogout = async () => {
    // localStorage.clear() 
    try {
      const response = await axiosInstance.post(apiUrls.auth.logoutFromMyDevice);
      if (response.success) {
        dispatch(logoutSuccess());
        localStorage.clear() 
        authStorage.removeToken();
        history.push('/login');
      }
    } catch (error) {
    }
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          {!profileLoading && <img
            className="rounded-circle header-profile-user"
            src={profileData.profile_image
              ? profileData.profile_image
              : images.defaultAvatar
            }
            alt="Header Avatar"
          />
          }
          <span className="d-none d-xl-inline-block ms-2 me-1">{profileData?.name}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag={Link} to="/profile">
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            Profile
          </DropdownItem>
          <DropdownItem tag={Link} to="/change-password">
            <i className="bx bx-lock font-size-16 align-middle me-1" />
            Change Password
          </DropdownItem>
          <div className="dropdown-divider" />
          <DropdownItem tag="a" href="#" onClick={handleLogout}>
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>Logout</span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}



export default withRouter(ProfileMenu)
