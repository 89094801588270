import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useEffect, useState } from "react";
import {
    Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label, Button, Modal,
    ModalBody,
    ModalHeader,
    InputGroup,
    Spinner,
    CardTitle,
    Collapse
} from "reactstrap";
import { withRouter, Link } from "react-router-dom";

// Formik formikObj
import * as Yup from "yup";
import { useFormik } from "formik";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { standartDateFormat } from "utils/date";
import { axiosInstance, apiUrls } from "api";
import swal from 'sweetalert';
import { graphql } from "api";
import moment from "moment";

// datatable related plugins

import CommonTable from "components/Common/CommonTable";
import usePaginator from "hooks/usePaginator";
import * as ExerciseQry from "GraphQLquery/exerciseQry";
import * as TrainerQry from "GraphQLquery/trainerQry";
import classnames from "classnames";

const ManageWorkoutPlan = props => {

    const [error, setError] = useState(null);
    const [processingList, setProcessingList] = useState(false);
    const [allWorkoutPlanList, setAllWorkoutPlanList] = useState([]);
    const [workoutPlanDetail, setWorkoutPlanDetail] = useState([]);
    const [paginatorInfo, setPaginatorInfo, resetPaginatorInfo] = usePaginator();
    const [searchText, setSearchText] = useState('');
    const [viewWorkoutPlanModal, setViewWorkoutPlanModal] = useState(false);
    const [viewWorkoutModal, setViewWorkoutModal] = useState(false);
    const [dayFirstWorkout, setDayFirstWorkout] = useState([])
    const [daySecondWorkout, setDaySecondWorkout] = useState([])
    const [dayThirdWorkout, setDayThirdWorkout] = useState([])
    const [dayFourWorkout, setDayFourWorkout] = useState([])
    const [dayFiveWorkout, setDayFiveWorkout] = useState([])
    const [daySixWorkout, setDaySixWorkout] = useState([])
    const [workout, setWorkout] = useState(null)
    const [trainers, setTrainers] = useState([])

    const [col1, setcol1] = useState(true);
    const [col2, setcol2] = useState(false);
    const [col3, setcol3] = useState(false);
    const [col4, setcol4] = useState(false);
    const [col5, setcol5] = useState(false);
    const [col6, setcol6] = useState(false);

    useEffect(() => {
        // fetchAllWorkoutPlanListData(paginatorInfo, searchText, null)
        fetchAllTrainerListData()
        localStorage.setItem("day", null)
        localStorage.setItem("workoutPlanDetailId", null)
        localStorage.setItem("memberWise", null)
    }, [])

    const fetchAllTrainerListData = async () => {
        try {
            setProcessingList(true)
            const res = await graphql({
                query: TrainerQry.FETCH_ALL_TRAINER_LIST_QUERY
            });

            if (res.data.trainers.data) {
                let response = res?.data?.trainers?.data
                setTrainers(response)
                fetchAllWorkoutPlanListData(paginatorInfo, searchText, null)
            }
        } catch (error) {
            setError(error)
        }
    }

    const fetchAllWorkoutPlanListData = async (paginatorInfo, searchText, id) => {
        try {
            id == null && setProcessingList(true)
            const res = await graphql({
                query: ExerciseQry.FETCH_ALL_WORKOUT_PLAN_QUERY,
                variables: {
                    ...paginatorInfo,
                    searchText: `%${searchText}%`,
                    id: id == null ? "%%" : id
                }
            });

            if (res.data.workout_plans.data) {
                if (id) {
                    setWorkoutPlanDetail(!!res.data.workout_plans ? res.data.workout_plans.data[0] : [])
                    setViewWorkoutPlanModal(true);
                    let dayFirstWorkout = res.data.workout_plans.data?.[0].workout_plan_details.filter(item => item.day == 1)
                    let daySecondWorkout = res.data.workout_plans.data?.[0].workout_plan_details.filter(item => item.day == 2)
                    let dayThirdWorkout = res.data.workout_plans.data?.[0].workout_plan_details.filter(item => item.day == 3)
                    let dayFourWorkout = res.data.workout_plans.data?.[0].workout_plan_details.filter(item => item.day == 4)
                    let dayFiveWorkout = res.data.workout_plans.data?.[0].workout_plan_details.filter(item => item.day == 5)
                    let daySixWorkout = res.data.workout_plans.data?.[0].workout_plan_details.filter(item => item.day == 6)
                    setDayFirstWorkout(dayFirstWorkout)
                    setDaySecondWorkout(daySecondWorkout)
                    setDayThirdWorkout(dayThirdWorkout)
                    setDayFourWorkout(dayFourWorkout)
                    setDayFiveWorkout(dayFiveWorkout)
                    setDaySixWorkout(daySixWorkout)
                }
                else {
                    setProcessingList(false)
                    setAllWorkoutPlanList(!!res.data.workout_plans ? res.data.workout_plans.data : [])
                    setPaginatorInfo(res?.data?.workout_plans?.paginatorInfo);
                }
            }

            else {
                if (id) {
                    setWorkoutPlanDetail([])
                } else {
                    setProcessingList(false)
                    setAllWorkoutPlanList([])
                    resetPaginatorInfo();
                }
            }
        } catch (error) {
            if (id) {
                setWorkoutPlanDetail([])
            } else {
                setProcessingList(false)
                setAllWorkoutPlanList([])
                resetPaginatorInfo();
            }
        }
    }

    const closeModal = () => {
        setViewWorkoutPlanModal(false);
    }

    const handleTableChange = (paginatorInfo, searchText) => {
        fetchAllWorkoutPlanListData(paginatorInfo, searchText, null)
    }

    const handleAction = (cell, row) => {
        return (
            <>
                <div className="d-flex">
                    <Button className="btn btn-warning btn-sm ms-2" onClick={(e) => { handleFetch(e, row) }}>
                        <i className="fas fa-eye align-middle font-size-16" />
                    </Button>
                    <Button className="btn-info btn-sm ms-2" onClick={(e) => props.history.push({ pathname: "/edit-workout-plan", state: { workout_id: row.id, assignMemberWorkout: false } })}>
                        <i className="bx bx-pencil align-middle" />
                    </Button>
                    <Button className="btn-danger btn-sm ms-2" onClick={(e) => { handleDelete(e, row) }}>
                        <i className="bx bx-trash align-middle" />
                    </Button>
                </div>
            </>
        );
    };

    // Column definition for datatable
    const columns = [{
        dataField: 'workout_plan_name',
        text: 'WORKOUT PLAN NAME',
        sort: true,
    },
    // {
    //     // dataField: 'description',
    //     formatter: (cell, row) => <div style={{ whiteSpace: "normal" }}>{row.description}</div>,
    //     text: 'DESCRIPTION',
    //     sort: true,
    // },
    {
        formatter: (cell, row) => !!row.prepared_by_id ? (trainers.filter(item => item.id == row.prepared_by_id))?.[0]?.name :'',
        text: 'PREPARED BY',
        sort: true,
    },
    {
        formatter: (cell, row) => moment(row.created_at).format("DD-MM-YYYY"),
        text: 'CREATION DATE',
        sort: true,
    },
    {
        dataField: '',
        text: 'ACTION',
        sort: true,
        formatter: handleAction
    }];

    const handleDelete = (e, row) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (willDelete) => {
                if (willDelete) {
                    try {
                        const response = await axiosInstance.post(apiUrls.workoutPlan.deleteWorkoutplan, { id: row.id });
                        if (response.success == 1) {
                            swal("Success!", response.message, "success");
                            fetchAllWorkoutPlanListData(paginatorInfo, searchText, null)
                        }
                        else {
                            swal("Oops!", response.message, "error");
                        }
                    } catch (err) {
                        swal("Oops!", "Something went wrong!", "error");
                        setError(err.message)
                    }
                }
            });
    }

    const handleFetch = (e, row) => {
        fetchAllWorkoutPlanListData(paginatorInfo, searchText, row.id)
    }

    const t_col = (e, tabValue) => {
        if (tabValue == 1) {
            setcol1(!col1);
            setcol2(false);
            setcol3(false);
            setcol4(false);
            setcol5(false);
            setcol6(false);
        }
        if (tabValue == 2) {
            setcol2(!col2);
            setcol1(false);
            setcol3(false);
            setcol4(false);
            setcol5(false);
            setcol6(false);
        }
        if (tabValue == 3) {
            setcol3(!col3);
            setcol1(false);
            setcol2(false);
            setcol4(false);
            setcol5(false);
            setcol6(false);
        }
        if (tabValue == 4) {
            setcol4(!col4);
            setcol1(false);
            setcol2(false);
            setcol3(false);
            setcol5(false);
            setcol6(false);
        }
        if (tabValue == 5) {
            setcol4(false);
            setcol1(false);
            setcol2(false);
            setcol3(false);
            setcol5(!col5);
            setcol6(false);
        }
        if (tabValue == 6) {
            setcol4(false);
            setcol1(false);
            setcol2(false);
            setcol3(false);
            setcol5(false);
            setcol6(!col6);
        }
    }

    const renderWorkoutsDetails = (workout, tabValue) => {
        return <div className="accordion-item">
            <h1 className="accordion-header" id="headingOne">
                <button
                    className={classnames(
                        "accordion-button",
                        "fw-medium",
                        {
                            collapsed: tabValue == 1 && !col1 || tabValue == 2 &&
                                !col2 || tabValue == 3 && !col3 || tabValue == 4 && !col4
                                || tabValue == 5 && !col5 || tabValue == 6 && !col6
                        }
                    )}
                    type="button"
                    onClick={(e) => t_col(e, tabValue)}
                    style={{ cursor: "pointer" }}
                >
                    <strong>DAY- {workout?.[0].day}</strong> &nbsp;
                    {
                        (workout?.[0].day == 1 && !!workoutPlanDetail?.day_one_target) && "(" + workoutPlanDetail?.day_one_target + ")" ||
                        (workout?.[0].day == 2 && !!workoutPlanDetail?.day_two_target) && "(" + workoutPlanDetail?.day_two_target + ")" ||
                        (workout?.[0].day == 3 && !!workoutPlanDetail?.day_three_target) && "(" + workoutPlanDetail?.day_three_target + ")" ||
                        (workout?.[0].day == 4 && !!workoutPlanDetail?.day_four_target) && "(" + workoutPlanDetail?.day_four_target + ")" ||
                        (workout?.[0].day == 5 && !!workoutPlanDetail?.day_five_target) && "(" + workoutPlanDetail?.day_five_target + ")" ||
                        (workout?.[0].day == 6 && !!workoutPlanDetail?.day_six_target) && "(" + workoutPlanDetail?.day_six_target + ")"
                    }
                </button>
            </h1>

            <Collapse isOpen={tabValue == 1 && col1 || tabValue == 2 && col2 || tabValue == 3 && col3 || tabValue == 4 && col4 || tabValue == 5 && col5 || tabValue == 6 && col6} className="accordion-collapse">
                <div className="accordion-body">
                    <div className="text-end mb-4">
                        <Link
                            to="/workout-view"
                            onClick={(e) => {
                                localStorage.setItem("day", workout?.[0].day)
                                localStorage.setItem("workoutPlanDetailId", workoutPlanDetail.id)
                                localStorage.setItem("memberWise", false)
                                localStorage.setItem("memberId", "")
                                localStorage.setItem("memberName", "")
                                localStorage.setItem("workoutPlanName", "")
                            }}
                            target="_blank"
                            className="btn btn-info  btn btn-info btn-sm">
                            <i className="fas fa-eye" />
                        </Link>
                    </div>
                    <div className="text-primary">
                        <Row>
                            <Col lg={4}>
                                <Label><strong>CATEGORY</strong></Label>
                            </Col>
                            <Col lg={4}>
                                <Label><strong>EXERCISE NAME</strong></Label>
                            </Col>
                            <Col lg={1}>
                                <Label><strong>#SET</strong></Label>
                            </Col>
                            <Col lg={1}>
                                <Label><strong>#REP</strong></Label>
                            </Col>
                            <Col lg={2}>
                                <Label><strong>DURATION ({workout?.[0]?.duration_type == 1 ? 'mins' : 'sec'} )</strong></Label>
                            </Col>
                        </Row>

                    </div>

                    {workout?.map((details, index) => {
                        return (
                            <>
                                <Row key={index}>
                                    <Col lg={4}>
                                        {details?.workout_plan_category_details?.category_name}
                                    </Col>
                                    <Col lg={4}>
                                        {details?.workout_plan_exercise_details?.exercise_name}
                                    </Col>
                                    <Col lg={1}>
                                        {details?.no_of_set || '-'}
                                    </Col>
                                    <Col lg={1}>
                                        {details?.no_of_rep || '-'}
                                    </Col>
                                    <Col lg={2}>
                                        {details?.duration || '-'}
                                    </Col>
                                </Row>
                                {workout.length - 1 != index && <hr />}
                            </>
                        )
                    })}
                </div>
            </Collapse>
        </div>
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Manage Workout</title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumbs title="Workout Plan" breadcrumbItem="Manage Workout" />
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col lg={3}>
                                            <Link to="/create-workout-plan">
                                                <Button
                                                    color="primary"
                                                    className="font-size-13 btn-block"
                                                >
                                                    <i className="mdi mdi-plus-circle-outline me-1 align-middle font-size-16" />Create
                                                </Button>
                                            </Link>

                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    {/* Datatable view start */}

                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col lg={12}>
                                            <h3>All Workout List</h3><br />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                            <CommonTable
                                                paginatorInfo={paginatorInfo}
                                                searchText={searchText}
                                                onTableChange={handleTableChange}
                                                keyField='id'
                                                columns={columns}
                                                data={allWorkoutPlanList}
                                                loading={processingList}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    {/* Datatable view end */}

                    <Modal
                        isOpen={viewWorkoutPlanModal}
                        toggle={closeModal}
                        size="lg"
                        backdrop="static"
                    // className={props.className}
                    >
                        <ModalHeader toggle={closeModal} tag="h4">
                            Workout Plan Detail
                        </ModalHeader>
                        <ModalBody>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col lg={12}>
                                            <CardTitle className="h4 mb-4 text-primary">
                                                Plan Name-&nbsp;&nbsp;{workoutPlanDetail?.workout_plan_name}
                                            </CardTitle>

                                            <div className="accordion" id="accordion">
                                                {dayFirstWorkout.length > 0 &&
                                                    <>
                                                        {renderWorkoutsDetails(dayFirstWorkout, 1)}
                                                    </>
                                                }
                                                {daySecondWorkout.length > 0 &&
                                                    <>
                                                        {renderWorkoutsDetails(daySecondWorkout, 2)}
                                                    </>
                                                }
                                                {dayThirdWorkout.length > 0 &&
                                                    <>
                                                        {renderWorkoutsDetails(dayThirdWorkout, 3)}
                                                    </>
                                                }
                                                {dayFourWorkout.length > 0 &&
                                                    <>
                                                        {renderWorkoutsDetails(dayFourWorkout, 4)}
                                                    </>
                                                }
                                                {dayFiveWorkout.length > 0 &&
                                                    <>
                                                        {renderWorkoutsDetails(dayFiveWorkout, 5)}
                                                    </>
                                                }
                                                {daySixWorkout.length > 0 &&
                                                    <>
                                                        {renderWorkoutsDetails(daySixWorkout, 6)}
                                                    </>
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </ModalBody>
                    </Modal>

                </Container>
            </div>
        </React.Fragment>
    );

}

export default withRouter(ManageWorkoutPlan);

ManageWorkoutPlan.propTypes = {
    history: PropTypes.object,
};