import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useEffect, useState } from "react";
import {
    Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label, Button, Modal,
    ModalBody,
    ModalHeader,
    InputGroup,
    CardTitle
} from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import Select from "react-select";
// Formik formikObj
import * as Yup from "yup";
import { FieldArray, useFormik, FormikProvider } from "formik";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { standartDateFormat, today, userDateFormat } from "utils/date";
import { axiosInstance, apiUrls } from "api";
import swal from 'sweetalert';
import { graphql } from "api";

import CommonTable from "components/Common/CommonTable";
import usePaginator from "hooks/usePaginator";
import * as ExerciseQry from "GraphQLquery/exerciseQry";
import Flatpickr from "react-flatpickr";
import * as SubscriptionQry from "GraphQLquery/subscriptionQry";
import * as TrainerQry from "GraphQLquery/trainerQry";
import * as MemberQry from "GraphQLquery/memberQry";
import moment from "moment";

const initialBatchData = {
    activity_id: "",
    conduct_by: "",
    start_date: "",
    end_date: "",
    members: [],
    capacity: '',
    batch_details: [{
        location: "",
        timing_from: "",
        timing_to: "",
        day_id: "",
    }
    ]
}

let daysArr = [];
let allMember = []
let editMemberList = []

const AddEditBatch = props => {
    const batch_id = !!props.location.state ? props.location.state.batch_id : ''
    const [batchData, setBatchData] = useState({ ...initialBatchData })
    const [isSaving, setIsSaving] = useState(false);
    const [error, setError] = useState(null);
    const [editId, setEditId] = useState('')
    const [activityList, setActivityList] = useState([])
    const [trainers, setTrainers] = useState([])
    const [paginatorInfo, setPaginatorInfo, resetPaginatorInfo] = usePaginator();
    const [searchText, setSearchText] = useState('');
    const [start_date, setBeginingDate] = useState('');
    const [planPattern, setPlanPattern] = useState('')
    const [end_date, setEndDate] = useState('');
    const [allDaysList, setAllDaysList] = useState([]);
    const [processingList, setProcessingList] = useState(false);
    const [processingMemberList, setProcessingMemberList] = useState(false);
    const [editBatchData, setEditBatchData] = useState([]);
    const [selectedMembers, setSelectedMembers] = useState(null);
    const [pageLoading, setPageLoading] = useState(true)

    const [memberListOptions, setMemberListOptions] = useState(null);

    useEffect(() => {
        fetchAllTrainerListData()
        fetchActivities();
        fetchDays()
        // initAutocomplete(0)
        !!batch_id && fetchBatchDetails()
    }, [])

    const fetchBatchDetails = async (searchText) => {
        try {
            setProcessingList(true)
            setTimeout(() => {
                setPageLoading(false)
            }, 3000);
            const res = await axiosInstance.post(apiUrls.batch.batchList, { id: batch_id });

            if (res.success == 1) {
                setProcessingList(false)
                setBatchData(res?.data?.[0])
                let members = []
                let batch_details = []
                res?.data?.[0]?.members.forEach(item => {
                    if(item?.alloted_status == 1){
                        members.push(parseInt(item?.member_id))
                    }
                })
                res?.data?.[0]?.batch_details.forEach(item => {
                    batch_details.push({
                        id: item.id,
                        batch_id: item.batch_id,
                        location: item.location,
                        timing_from: item.timing_from,
                        timing_to: item.timing_to,
                        day_id: item.day_id,
                    })
                })
                editMemberList = members
                setBatchData(prev => {
                    return ({ ...prev, members, batch_details })
                })
                setBeginingDate(userDateFormat(res?.data?.[0]?.start_date))
                setEndDate(moment(res?.data?.[0]?.end_date).format("DD-MM-YYYY"))
            }

            else {
                setProcessingList(false)
                setEditBatchData([])
            }
        } catch (error) {
            setProcessingList(false)
            setEditBatchData([])
        }
    }

    const fetchActivities = async () => {
        try {
            const res = await graphql({
                query: SubscriptionQry.ACTIVITIES_LIST_QUERY
            });
            if (res.data.activities) {
                let activities = res?.data?.activities || []
                activities = activities.filter(item => item.id == 2 || item.id == 3 || item.id == 7)
                setActivityList(activities)
            } else {
                setActivityList([])
            }
        } catch (error) {
            setActivityList([])
        }
    }

    const fetchAllTrainerListData = async () => {
        try {
            const res = await graphql({
                query: TrainerQry.FETCH_ALL_TRAINER_LIST_QUERY,
                // variables: {
                //     ...paginatorInfo,
                //     searchText: `%${searchText}%`,
                //     id: "%%"
                // }
            });

            if (res.data.trainers.data) {
                let response = res.data.trainers.data
                setTrainers(response)
            }
        } catch (error) {
            setError(error)
        }
    }

    const fetchDays = async () => {
        try {
            setProcessingList(true)
            const res = await graphql({
                query: TrainerQry.FETCH_DAYS_QUERY,
            });

            if (res.data.days) {
                setProcessingList(false)
                setAllDaysList(!!res.data.days ? res.data.days : [])
            }

            else {
                setProcessingList(false)
                setAllDaysList([])
            }
        } catch (error) {
            setProcessingList(false)
            setAllDaysList([])
        }
    }

    const handleDaysChange = (e, i) => {
        if (e.target.checked) {
            formikObj.values.batch_details.push({
                location: "",
                timing_from: "",
                timing_to: "",
                day_id: parseInt(e.target.value),
            })

        } else {
            let index = formikObj.values.batch_details.indexOf(parseInt(e.target.value))
            formikObj.values.batch_details.splice(index, 1)
        }

    }

    const handleFromTime = (timeChange) => {
        formikObj.setFieldValue("timing_from", timeChange[0].getHours() + ":" + timeChange[0].getMinutes())
    }
    const handleToTime = (timeChange) => {
        formikObj.setFieldValue("timing_to", timeChange[0].getHours() + ":" + timeChange[0].getMinutes())
    }

    const fetchMemberListData = async (activity_id) => {
        allMember = []
        let param={
            batch_id:  !!batch_id && batch_id,
            activity_id: activity_id
        }
        try {
            setProcessingMemberList(true)
            const res = await axiosInstance.post(apiUrls.batch.fetchMemberByActivityId, param);
            if (res.success == 1) {
                if (res.data.length > 0) {
                    setProcessingMemberList(false)
                    allMember = !!res.data ? res.data : []
                    let memberListOptions = [];
                    for (let index = 0; index < allMember.length; index++) {
                        memberListOptions.push({ label: allMember[index].member_name, value: allMember[index].member_id })
                    }
                    setMemberListOptions(memberListOptions)
                    if (!!batch_id) {
                        try {
                            let selectedMembers = allMember.filter(item => editMemberList.includes(item.member_id))
                            let memberListOptions = [];
                            for (let index = 0; index < selectedMembers.length; index++) {
                                memberListOptions.push({ label: selectedMembers[index].member_name, value: selectedMembers[index].member_id })
                            }
                            setSelectedMembers(memberListOptions)
                        } catch (error) {
                        }

                    }
                }
                else {
                    setProcessingMemberList(false)
                    allMember = []
                }
            }
            else {
                setProcessingMemberList(false)
                allMember = []
                setMemberListOptions([])
            }
        } catch (error) {
            setProcessingMemberList(false)
            allMember = []
        }
    }


    const beginingDateChange = (beginingDates) => {
        if (!!beginingDates[0]) {
            setBeginingDate(beginingDates[0].getDate() + '-' + (beginingDates[0].getMonth() + 1) + '-' + beginingDates[0].getFullYear())
            formikObj.setFieldValue("start_date", standartDateFormat(beginingDates[0]))
        } else {
            setBeginingDate('')
            formikObj.setFieldValue("start_date", '')
        }
    }

    const endDateChange = (endDates) => {
        if (!!endDates[0]) {
            setEndDate(endDates[0].getDate() + '-' + (endDates[0].getMonth() + 1) + '-' + endDates[0].getFullYear())
            formikObj.setFieldValue("end_date", standartDateFormat(endDates[0]))
        } else {
            setEndDate('')
            formikObj.setFieldValue("end_date", '')
        }
    }
    let formatted_address = ''

    const initAutocomplete = (index) => {
        let autocomplete = new window.google.maps.places.Autocomplete(
            /** @type {!HTMLInputElement} */(document.getElementById("location" + index)),
            { types: [] });
        console.log(autocomplete);
        window.google.maps.event.addListener(autocomplete, 'place_changed', () => {
            let place = autocomplete.getPlace();
            formatted_address = place
        })
    }


    const formikObj = useFormik({
        enableReinitialize: true,

        initialValues: { ...batchData },
        validationSchema: Yup.object({
            activity_id: Yup.string().required("Please Select Activity Name"),
            conduct_by: Yup.string().required("Please Select Conduct By"),
            // members: Yup.string().required("Please Select Member"),
        }),
        onSubmit: (values) => {
            addUpdateBatchData(values)
        }
    });

    const addUpdateBatchData = async (values) => {
        try {
            setIsSaving(true)
            const response = await axiosInstance.post(apiUrls.batch.addAndUpdateBatch, { ...values });
            if (response.success == 1) {
                formikObj.resetForm()
                setIsSaving(false)
                swal("Success!", response.message, "success");
                props.history.goBack()
            }
            else {
                setIsSaving(false)
                swal("Oops!", response.message, "error");
            }
        } catch (err) {
            setIsSaving(false)
            swal("Oops!", "Something went wrong!", "error");
            setError(err.message)
        }
    }

    useEffect(() => {
        if (formikObj.values.activity_id !== "") {
            fetchMemberListData(formikObj.values.activity_id)
        }
    }, [formikObj.values.activity_id])

    const handleMultiselect = (selectedMembers) => {
        setSelectedMembers(selectedMembers);
        let members = []
        selectedMembers.forEach(item => {
            members.push(item.value)
        })
        formikObj.setFieldValue("members", members)
    }

    const handleAddress = (loc) => {
        formikObj.setFieldValue(loc, formatted_address)
    }

    const handleDelete = async (e, id) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (willDelete) => {
                if (willDelete) {
                    try {
                        setIsSaving(true)
                        const response = await axiosInstance.post(apiUrls.batch.deleteBatchDetails, { id });
                        if (response.success == 1) {
                            setIsSaving(false)
                            fetchBatchDetails()
                            swal("Success!", response.message, "success");
                        }
                        else {
                            setIsSaving(false)
                            swal("Oops!", response.message, "error");
                        }
                    } catch (err) {
                        setIsSaving(false)
                        swal("Oops!", "Something went wrong!", "error");
                        setError(err.message)
                    }
                }
            });
    }

    const handleCapacity = (e) => {
        if (/^[0-9]+$/g.test(e.target.value) || e.target.value == '') {
            formikObj.setFieldValue(e.target.name, e.target.value)
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{!!batch_id ? "Edit Batch" : "Add Batch"}</title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumbs title="Manage Batch" breadcrumbItem={!!batch_id ? "Edit Batch" : "Add Batch"} />
                    {(!!batch_id && pageLoading) ?
                        <Col lg={12} style={{ textAlign: "center" }}>
                            <h5><i className="bx bx-hourglass bx-spin font-size-24 align-middle me-2"></i>
                                Loading...</h5>
                        </Col>
                        :
                        <Card>
                            <CardBody>
                                <Form>
                                    <div className="row mb-3">
                                        <div className="col-lg-6 mb-3 mb-lg-0">
                                            <Label className="form-label">
                                                Select Activity <span className="text-danger">*</span>
                                            </Label>
                                            <Input
                                                type="select"
                                                id="formrow-serviceType"
                                                className="form-control"
                                                name={"activity_id"}
                                                onChange={formikObj.handleChange}
                                                value={formikObj.values.activity_id}
                                                invalid={
                                                    formikObj.touched.activity_id && formikObj.errors.activity_id ? true : false
                                                }
                                            >
                                                <option value="">Select</option>
                                                {activityList.map((item) => (
                                                    <option value={item.id} key={item.id}>{item.activity}</option>
                                                ))}

                                            </Input>
                                            {formikObj.touched.activity_id && formikObj.errors.activity_id ? (
                                                <FormFeedback type="invalid">{formikObj.errors.activity_id}</FormFeedback>
                                            ) : null}
                                        </div>

                                        <div className="col-lg-6">
                                            <Label className="form-label">
                                                Conducted By <span className="text-danger">*</span>
                                            </Label>
                                            <Input
                                                type="select"
                                                id="formrow-serviceType"
                                                className="form-control"
                                                name={'conduct_by'}
                                                onChange={formikObj.handleChange}
                                                value={formikObj.values.conduct_by}
                                                invalid={
                                                    formikObj.touched.conduct_by && formikObj.errors.conduct_by ? true : false
                                                }
                                            // disabled={edit}
                                            >
                                                <option value="">Select</option>
                                                {trainers?.map((item) => (
                                                    <option value={item.id} key={item.id}>{item.name}</option>
                                                ))}

                                            </Input>
                                            {formikObj.touched.conduct_by && formikObj.errors.conduct_by ? (
                                                <FormFeedback type="invalid">{formikObj.errors.conduct_by}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </div>
                                    <Row>
                                        <Col lg={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-startDate-Input">Start Date</Label>
                                                <Flatpickr
                                                    className="form-control d-block"
                                                    name="start_date"
                                                    placeholder="DD-MM-YYYY"
                                                    options={{
                                                        altInput: true,
                                                        altFormat: "d-m-Y",
                                                        dateFormat: "d-m-Y",
                                                        //minDate: today
                                                    }}
                                                    onChange={beginingDateChange}
                                                    value={start_date}
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-endDate-Input">End Date</Label>
                                                <Flatpickr
                                                    className="form-control d-block"
                                                    name="end_date"
                                                    placeholder="DD-MM-YYYY"
                                                    options={{
                                                        altInput: true,
                                                        altFormat: "d-m-Y",
                                                        dateFormat: "d-m-Y",
                                                        //minDate: entry_date
                                                    }}
                                                    onChange={endDateChange}
                                                    value={end_date}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>

                                        <Col xs={3}>
                                            <Label htmlFor="formrow-InputService"><b>Meeting Days</b></Label>
                                        </Col>
                                        <Col xs={3}>
                                            <Label htmlFor="formrow-InputService"><b>Time From</b></Label>
                                        </Col>
                                        <Col xs={3}>
                                            <Label htmlFor="formrow-InputService"><b>Time To</b></Label>
                                        </Col>
                                        <Col xs={3}>
                                            <Label htmlFor="formrow-InputService"><b>Location</b></Label>
                                        </Col>
                                        <FormikProvider value={formikObj}>
                                            <FieldArray
                                                name="batch_details"
                                                render={arrayHelpersAdd => (
                                                    <>
                                                        {formikObj.values.batch_details.map((item, index) => {
                                                            const isLastRow = (formikObj.values?.batch_details?.length - 1) === index;
                                                            const getFieldName = name => `${arrayHelpersAdd.name}[${index}][${name}]`;
                                                            return (
                                                                <div className="mb-3 form-check-inline" key={index}>
                                                                    <Row>
                                                                        <Col xs={3}>
                                                                            <Input
                                                                                type="select"
                                                                                id="formrow-serviceType"
                                                                                className="form-control"
                                                                                name={getFieldName('day_id')}
                                                                                value={item.day_id}
                                                                                onChange={(e) => {
                                                                                    formikObj.handleChange(e)
                                                                                }}
                                                                            >
                                                                                <option value="">Select</option>
                                                                                {allDaysList.map((day) => (
                                                                                    <option value={day.id} key={day.id}>{day.days}</option>
                                                                                ))}
                                                                            </Input>
                                                                        </Col>
                                                                        <Col xs={3}>
                                                                            <Flatpickr
                                                                                className="form-control d-block"
                                                                                name={getFieldName('timing_from')}
                                                                                // name="timing_from"
                                                                                options={{
                                                                                    noCalendar: true,
                                                                                    enableTime: true,
                                                                                    dateFormat: 'h:i K',
                                                                                    // minTime: formikObj.values.preferred_timing == 2 ? '10:0' : '',
                                                                                    // maxTime: formikObj.values.preferred_timing == 2 ? '16:0' : ''
                                                                                }}
                                                                                // onChange={handleFromTime}
                                                                                onChange={(e, timeChange) => {
                                                                                    formikObj.setFieldValue(getFieldName("timing_from"), timeChange)
                                                                                }}
                                                                                value={item.timing_from}
                                                                            />
                                                                        </Col>
                                                                        <Col xs={3}>
                                                                            <Flatpickr
                                                                                className="form-control d-block"
                                                                                name={getFieldName('timing_to')}
                                                                                options={{
                                                                                    noCalendar: true,
                                                                                    enableTime: true,
                                                                                    dateFormat: 'h:i K',
                                                                                    // minTime: formikObj.values.preferred_timing == 2 ? '10:0' : '',
                                                                                    // maxTime: formikObj.values.preferred_timing == 2 ? '16:0' : ''
                                                                                }}
                                                                                // onChange={handleToTime}
                                                                                onChange={(e, timeChange) => {
                                                                                    formikObj.setFieldValue(getFieldName("timing_to"), timeChange)
                                                                                }}
                                                                                value={item.timing_to}
                                                                            />
                                                                        </Col>
                                                                        <Col xs={3}>
                                                                            <Input
                                                                                type="text"
                                                                                className="form-control"
                                                                                id={"location" + [index]}
                                                                                placeholder="Enter Location"
                                                                                name={getFieldName('location')}
                                                                                // -------- for autocomplete --------
                                                                                // onChange={(e) => {
                                                                                //     handleAddress(getFieldName("location"))
                                                                                // }}
                                                                                onChange={(e) => {
                                                                                    formikObj.setFieldValue(getFieldName("location"), e.target.value)
                                                                                }}
                                                                                value={item.location}
                                                                            // autoComplete={"location" + [index]}
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                    <br />
                                                                    <Row>
                                                                        <Col lg={12} className="d-flex align-items-center" style={{ justifyContent: "right" }}>
                                                                            <div className="">
                                                                                {!!item.id ?
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn-rounded  btn btn-warning mx-2 btn-sm"
                                                                                        onClick={(e) => { handleDelete(e, item.id) }}
                                                                                    >
                                                                                        <i className="bx bx-trash" />
                                                                                    </button>
                                                                                    :
                                                                                    <>
                                                                                        {formikObj.values?.batch_details.length > 1 && <button
                                                                                            type="button"
                                                                                            className="btn-rounded  btn btn-danger ms-2 btn-sm"
                                                                                            onClick={() => arrayHelpersAdd.remove(index)}
                                                                                        > <i className="bx bx-minus align-middle" />
                                                                                        </button>
                                                                                        }
                                                                                    </>
                                                                                }
                                                                            </div>
                                                                            <div className="">
                                                                                {isLastRow && <button
                                                                                    type="button"
                                                                                    className="btn-rounded  btn btn-success ms-2 btn-sm"
                                                                                    onClick={() => {
                                                                                        // initAutocomplete(index)
                                                                                        arrayHelpersAdd.push({
                                                                                            location: "",
                                                                                            timing_from: "",
                                                                                            timing_to: "",
                                                                                            day_id: "",
                                                                                        })
                                                                                    }
                                                                                    }>
                                                                                    <i className="bx bx-plus align-middle" />
                                                                                </button>
                                                                                }
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            )
                                                        })
                                                        }

                                                    </>
                                                )}
                                            />
                                        </FormikProvider>
                                    </Row>
                                    <Row className="mb-3">
                                        <div className="col-lg-6 mb-3 mb-lg-0">
                                            <Label className="form-label">
                                                Capacity
                                            </Label>
                                            <Input
                                                type="text"
                                                id="formrow-InputService"
                                                className="form-control"
                                                name="capacity"
                                                onChange={(e) => { handleCapacity(e) }}
                                                value={formikObj.values.capacity}
                                                placeholder="Enter Capacity"
                                            // invalid={
                                            //     formikObj.touched.capacity && formikObj.errors.capacity ? true : false
                                            // }
                                            />
                                        </div>
                                        <div className="col-lg-6">
                                            <Label className="form-label">
                                                Select Member <span className="text-danger">*</span>
                                            </Label>
                                            <Select
                                                value={selectedMembers}
                                                isMulti={true}
                                                onChange={(selectedMembers) => {
                                                    handleMultiselect(selectedMembers);
                                                }}
                                                options={memberListOptions}
                                                classNamePrefix="select2-selection"
                                            />
                                            {formikObj.touched.members && formikObj.errors.members ? (
                                                <FormFeedback type="invalid">{formikObj.errors.members}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="d-flex justify-content-end mt-3">
                                                {!!isSaving ?
                                                    <Button className="btn btn-primary " disabled>
                                                        Processing
                                                    </Button>
                                                    :
                                                    <Button
                                                        type="submit"
                                                        color="primary"
                                                        className="w-md"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            formikObj.handleSubmit();
                                                            return false;
                                                        }}
                                                    >
                                                        Save
                                                    </Button>
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    }
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(AddEditBatch);

AddEditBatch.propTypes = {
    history: PropTypes.object,
    location: PropTypes.any,
    batch_id: PropTypes.number
};