import axiosInstance from "./axiosInstance";

export default function graphql({
    query = ``,
    variables = {},
    operationName = ''
}) {
    const url = process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_GRAPHQL_API;
    return axiosInstance
        .post(url, {
            query,
            variables
        });
}