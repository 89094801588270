import { CLOSE_MODAL, CLOSE_SCHEDULE_MODAL, RELOAD_BATCH_LIST, CLOSE_ASSIGNED_MEMBER_MODAL } from "./actionTypes";

const initialState = {
    modalStatus: false,
    scheduleModalStatus: false,
    reloadBatchList: false,
    assignedMemberModalStatus: false
}

const BatchModalState = (state = initialState, action) => {
    switch (action.type) {
        case CLOSE_MODAL:
            state = { modalStatus: action.modalStatus }
            break;
        case CLOSE_SCHEDULE_MODAL:
            state = { scheduleModalStatus: action.scheduleModalStatus }
            break;
        case RELOAD_BATCH_LIST:
            state = { reloadBatchList: action.reloadBatchList }
            break;
        case CLOSE_ASSIGNED_MEMBER_MODAL:
            state = { assignedMemberModalStatus: action.assignedMemberModalStatus }
            break;
        default:
            state = {
                modalStatus: null,
                scheduleModalStatus: null,
                reloadBatchList: null,
                assignedMemberModalStatus: null
            };
            break;
    }
    return state;
}

export default BatchModalState