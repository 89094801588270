import { CLOSE_MODAL, CLOSE_SCHEDULE_MODAL, RELOAD_BATCH_LIST, CLOSE_ASSIGNED_MEMBER_MODAL } from "./actionTypes"

export const setModalState = modalStatus => {
  return {
    type: CLOSE_MODAL,
    modalStatus
  }
}

export const setScheduleModalState = scheduleModalStatus => {
  return {
    type: CLOSE_SCHEDULE_MODAL,
    scheduleModalStatus
  }
}

export const setReloadBatchList = reloadBatchList => {
  return {
    type: RELOAD_BATCH_LIST,
    reloadBatchList
  }
}

export const setAssignedMemberModalState = assignedMemberModalStatus => {
  return {
    type: CLOSE_ASSIGNED_MEMBER_MODAL,
    assignedMemberModalStatus
  }
}

