import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useEffect, useState } from "react";
import {
    Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label, Button, Modal,
    ModalBody,
    ModalHeader,
    InputGroup,
    CardTitle,
    CardText,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Spinner,
    Collapse,
    CardHeader,
    Table
} from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import classnames from "classnames";
import Flatpickr from "react-flatpickr";
// Formik formikObj   
import * as Yup from "yup";
import { useFormik, FieldArray, FormikProvider } from "formik";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { standartDateFormat, today } from "utils/date";
import { axiosInstance, apiUrls } from "api";
import swal from 'sweetalert';
import { graphql } from "api";
import usePaginator from "hooks/usePaginator";
import * as SubscriptionQry from "GraphQLquery/subscriptionQry";

import moment from "moment";

import CommonTable from "components/Common/CommonTable";

const initialAccessoryData = {
    member_id: "",
    accessories_type_id: [],
    gym_bag_handover_date: "",
    shipper_handover_date: "",
    accessory_data: []
}
let accessoryArr = [];

const AddEditAccessory = props => {
    const member_id = props.member_id
    const [accessoryData, setAccessoryData] = useState({ ...initialAccessoryData })
    const [accessories, setAccessories] = useState([]);
    const [gymBagHandoverDate, setGymBagHandoverDate] = useState("")
    const [shipperHandoverDate, setShipperHandoverDate] = useState("")
    const [offSet, setOffSet] = useState(10)
    const [memberAccessoryList, setMemberAccessoryList] = useState([])
    const [edit, setEdit] = useState(false)
    const [memberAccessories, setMemberAccessories] = useState([])
    const [searchText, setSearchText] = useState('');
    const [error, setError] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const [processingList, setProcessingList] = useState(false);
    const [addEditAccessoriesModal, setAddEditAccessoriesModal] = useState(false)
    const [paginatorInfo, setPaginatorInfo, resetPaginatorInfo] = usePaginator();
    const [editTypeAccessory, setEditTypeAccessory] = useState(0)

    useEffect(() => {
        checkAccessories();
        fetchMemberAccessoriesList(paginatorInfo, searchText);
        return ()=>{
            accessoryArr = [];
        }
    }, [])

    const checkAccessories = async () => {
        try {
            let memberAccessories = []
            if (edit) {
                memberAccessories.push(
                    { flag: editTypeAccessory == 1 ? 1 : 0 },
                    { flag: editTypeAccessory == 2 ? 2 : 0 }
                )
                setMemberAccessories(memberAccessories)
                fetchAccessories(memberAccessories);
            } else {
                const response = await axiosInstance.post(apiUrls.accessories.checkAccessoriesInAssignedMembership, { member_id });
                if (response.success == 1) {
                    memberAccessories.push(
                        { flag: response.gym_bag_flag },
                        { flag: response.shipper_flag == 1 ? 2 : 0 }
                    )
                    setMemberAccessories(memberAccessories)
                    fetchAccessories(memberAccessories);
                }
            }
        } catch (error) {
            setError(error)
        }
    }

    const fetchAccessories = async (memberAccessories) => {
        try {
            let response = null;
            response = await graphql({
                query: SubscriptionQry.PLAN_ACCESSORIES_QUERY,
                variables: {}
            });
            let accessories = []
            accessories = response?.data?.accessories || [];
            accessories.forEach((el, index) => {
                el.flag = memberAccessories[index].flag
            });
            setAccessories(accessories);
        } catch (err) {
            setAccessories([]);
        }
    }

    const fetchMemberAccessoriesList = async (paginatorInfo, searchText) => {
        setProcessingList(true)
        try {
            const response = await axiosInstance.post(apiUrls.accessories.accessoriesListOfMember + "?page=" + paginatorInfo.currentPage, { offset: !!paginatorInfo.perPage ? paginatorInfo.perPage : offSet, search_text: searchText, member_id: member_id });
            if (response?.success == 1) {
                setMemberAccessoryList(response?.data?.data)
                setProcessingList(false)
                let paginatorInfo = {
                    currentPage: parseInt(response?.data?.current_page),
                    perPage: parseInt(response?.data?.per_page),
                    total: parseInt(response?.data?.total),
                }
                setPaginatorInfo(paginatorInfo);
            }
            else {
                setProcessingList(false)
                setMemberAccessoryList([])
                resetPaginatorInfo();
            }
        } catch (error) {
            setError(error)
            setProcessingList(false)
            setMemberAccessoryList([])
            resetPaginatorInfo();
        }
    }

    const addEditAccessories = () => {
        setAddEditAccessoriesModal(true)
    }

    const closeAddEditAccessoriesModal = () => {
        setGymBagHandoverDate("")
        setShipperHandoverDate("")
        setAddEditAccessoriesModal(false)
    }

    const formikAccessoriesObj = useFormik({
        enableReinitialize: true,
        initialValues: { ...accessoryData },
        validationSchema: Yup.object({
        }),
        onSubmit: (values) => {

            try {
                values.member_id = member_id
                if (values.accessories_type_id.length == 1) {
                    if (values.accessories_type_id[0] == 1) {
                        values.accessory_data.push({
                            accessory_id: 1,
                            handover_date: values.gym_bag_handover_date,
                            id: values?.accessory_id == 1 ? values.id : ""
                        })
                    }
                    if (values.accessories_type_id[0] == 2) {
                        values.accessory_data.push({
                            accessory_id: 2,
                            handover_date: values.shipper_handover_date,
                            id: values?.accessory_id == 2 ? values.id : ""
                        })
                    }
                }
                else {
                    values.accessory_data.push({
                        accessory_id: 1,
                        handover_date: values.gym_bag_handover_date,
                        id: values?.accessory_id == 1 ? values.id : ""
                    })
                    values.accessory_data.push({
                        accessory_id: 2,
                        handover_date: values.shipper_handover_date,
                        id: values?.accessory_id == 2 ? values.id : ""
                    })
                }

                addUpdateAccessory(values)
            } catch (error) {
                setError(error)
            }


        }
    })

    const addUpdateAccessory = async (values) => {
        try {
            setIsSaving(true)
            const response = await axiosInstance.post(apiUrls.accessories.assignAccessoriesToMember, { ...values });
            if (response.success == 1) {
                formikAccessoriesObj.resetForm()
                setAccessoryData({
                    member_id: "",
                    accessories_type_id: [],
                    gym_bag_handover_date: "",
                    shipper_handover_date: "",
                    accessory_data: []
                })
                setIsSaving(false)
                closeAddEditAccessoriesModal()
                swal("Success!", response.message, "success");
                fetchMemberAccessoriesList(paginatorInfo, searchText);
                setEdit(false)
            }
            else {
                setIsSaving(false)
                swal("Oops!", response.message, "error");
            }
        } catch (err) {
            setIsSaving(false)
            swal("Oops!", "Something went wrong!", "error");
            setError(err.message)
        }
    }

    const handleAccessoryChange = (e) => {
        let value = parseInt(e.target.value)
        if (e.target.checked) {
            let index = accessoryArr.indexOf(value)
            accessoryArr.splice(index, 1)
            if (value == 1) {
                formikAccessoriesObj.setFieldValue("gym_bag_handover_date", "")
                setGymBagHandoverDate("")
            } else {
                formikAccessoriesObj.setFieldValue("shipper_handover_date", "")
                setShipperHandoverDate("")
            }
        }
        else {
            // accessoryArr = []
            accessoryArr.push(value);
        }
        formikAccessoriesObj.setFieldValue(e.target.name, accessoryArr)
        formikAccessoriesObj.setFieldValue("accessory_data", [])
    }

    const gymBagDateChange = (dates) => {
        if (!!dates[0]) {
            setGymBagHandoverDate(dates[0].getDate() + '-' + (dates[0].getMonth() + 1) + '-' + dates[0].getFullYear())
            formikAccessoriesObj.setFieldValue("gym_bag_handover_date", standartDateFormat(dates[0]))
        } else {
            setGymBagHandoverDate('')
            formikAccessoriesObj.setFieldValue("gym_bag_handover_date", '')
        }
    }

    const shipperDateChange = (dates) => {
        if (!!dates[0]) {
            setShipperHandoverDate(dates[0].getDate() + '-' + (dates[0].getMonth() + 1) + '-' + dates[0].getFullYear())
            formikAccessoriesObj.setFieldValue("shipper_handover_date", standartDateFormat(dates[0]))
        } else {
            setShipperHandoverDate('')
            formikAccessoriesObj.setFieldValue("shipper_handover_date", '')
        }
    }

    const handleAccessoryAction = (cell, row) => {
        return (
            <>

                <Button className="btn-info btn-sm ms-2" onClick={(e) => { handleAccessoryFetch(e, row) }}>
                    <i className="bx bx-pencil align-middle" />
                </Button>

                <Button className="btn-danger btn-sm ms-2" onClick={(e) => { handleAccessoryDelete(e, row) }}>
                    <i className="bx bx-trash align-middle" />
                </Button>
            </>
        );
    };

    const handleAccessoryFetch = async (e, row) => {
        try {
            const response = await axiosInstance.post(apiUrls.accessories.accessoriesListOfMember, { id: row.id });
            if (response.success == 1) {
                setEdit(true)
                let accessories_type_ids = []
                let res = response?.data?.[0]
                accessories_type_ids.push(res.accessory_id)
                const data = {
                    id: res.id,
                    accessory_id: res.accessory_id,
                    member_id: res.member_id,
                    accessories_type_id: accessories_type_ids,
                    gym_bag_handover_date: res.accessory_id == 1 && res.handover_date,
                    shipper_handover_date: res.accessory_id == 2 && res.handover_date,
                    accessory_data: []
                }

                setEditTypeAccessory(res.accessory_id)

                setAccessoryData(data)
                res.accessory_id == 1 && setGymBagHandoverDate(moment(res.handover_date).format('DD-MM-YYYY'))
                res.accessory_id == 2 && setShipperHandoverDate(moment(res.handover_date).format('DD-MM-YYYY'))

                addEditAccessories()
            }
        } catch (error) {

            setError(error)
        }
    }

    useEffect(() => {
        checkAccessories()
    }, [edit, editTypeAccessory])

    const handleAccessoryDelete = (e, row) => {
        swal({
            title: "Are you sure?",
            text: "you want to delete this recored",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (willDelete) => {
                if (willDelete) {
                    try {
                        const response = await axiosInstance.post(apiUrls.accessories.deleteAccessoriesOfMember, { id: row.id });
                        if (response.success == 1) {
                            swal("Success!", response.message, "success");
                            fetchMemberAccessoriesList(paginatorInfo, searchText);
                        }
                        else {
                            swal("Oops!", response.message, "error");
                        }
                    } catch (err) {
                        swal("Oops!", "Something went wrong!", "error");
                        setError(err.message)
                    }
                }
            });
    }

    const columns = [{
        dataField: 'accessory_name',
        text: 'ACCESSORY NAME',
        sort: true,
    },
    {
        formatter: (cell, row) => moment(row.handover_date).format("DD-MM-YYYY"),
        //dataField: 'handover_date',
        text: 'HANDOVER DATE',
        sort: true,
    },
    {
        dataField: '',
        text: 'ACTION',
        sort: true,
        formatter: handleAccessoryAction
    }];

    const handleTableChange = (paginatorInfo, searchText) => {
        fetchMemberAccessoriesList(paginatorInfo, searchText)
    }

    return (
        <React.Fragment>
            <Row>
                <Col lg={12}>
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <CardTitle className="h4 text-info mb-0">Accessories</CardTitle>

                        </Col>
                        {(memberAccessories?.[0]?.flag !== 0 || memberAccessories?.[1]?.flag !== 0) &&
                            <Col sm={6}>
                                <div className="d-flex justify-content-end">

                                    <Button
                                        color="primary"
                                        className="btn-block"
                                        onClick={addEditAccessories}
                                    >
                                        <i className="mdi mdi-plus-circle-outline align-middle me-2" />
                                        Add
                                    </Button>
                                </div>
                            </Col>
                        }
                    </Row>
                    <hr />

                    {(memberAccessories?.[0]?.flag == 0 && memberAccessories?.[1]?.flag == 0) ?
                        <Col lg={12} style={{ textAlign: "center" }}>
                            <h5>Not Applicable</h5>
                        </Col>
                        :
                        <CommonTable
                            paginatorInfo={paginatorInfo}
                            searchText={searchText}
                            onTableChange={handleTableChange}
                            keyField='id'
                            columns={columns}
                            data={memberAccessoryList}
                            loading={processingList}
                        />
                    }
                </Col>
            </Row>

            {/* Add/Edit Accessories Modal */}

            <Modal
                isOpen={addEditAccessoriesModal}
                toggle={closeAddEditAccessoriesModal}
                size="lg"
                backdrop="static"
                className="modal-md"
            // className={props.className}
            >
                <ModalHeader toggle={closeAddEditAccessoriesModal} tag="h4">
                    {edit ? "Edit Accessories" : "Add Accessories"}
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <div className="mb-4">
                            <Row>
                                {accessories.map((item, index) => (
                                    <React.Fragment key={index}>
                                        <Col lg={6} style={{ display: item.id == item.flag ? 'block' : 'none' }}>
                                            <div className="form-check form-check-inline font-size-16">
                                                <Input
                                                    type="checkbox"
                                                    value={item.id}
                                                    id="customRadioInline"
                                                    name="accessories_type_id"
                                                    className="form-check-input"
                                                    onClick={(e) => { handleAccessoryChange(e) }}
                                                    checked={formikAccessoriesObj.values?.accessories_type_id?.indexOf(item.id) > -1}
                                                />
                                                <Label
                                                    className="form-check-label font-size-13"
                                                    htmlFor="customRadioInline"
                                                >
                                                    {item.accessory_name}
                                                </Label>
                                            </div>
                                        </Col>
                                    </React.Fragment>
                                ))
                                }
                            </Row>
                        </div>
                        <Row>
                            {(memberAccessories?.[0]?.flag !== 0) &&
                                <Col lg={6}>
                                    <div className="mb-3">
                                        <Label htmlFor="formrow-startDate-Input">Gym Bag Handover date</Label>
                                        <Flatpickr
                                            className="form-control d-block"
                                            name="gym_bag_handover_date"
                                            placeholder="DD-MM-YYYY"
                                            options={{
                                                altInput: true,
                                                altFormat: "d-m-Y",
                                                dateFormat: "d-m-Y",
                                                // minDate: today
                                            }}
                                            onChange={gymBagDateChange}
                                            value={gymBagHandoverDate}
                                        // disabled={formikAccessoriesObj.values.accessories_type_id.indexOf(1) == 0 ? false : true}
                                        />
                                    </div>
                                </Col>
                            }
                            {(memberAccessories?.[1]?.flag) !== 0 &&
                                <Col lg={6}>
                                    <div className="mb-3">
                                        <Label htmlFor="formrow-startDate-Input">Shipper Handover date</Label>
                                        <Flatpickr
                                            className="form-control d-block"
                                            name="shipper_handover_date"
                                            placeholder="DD-MM-YYYY"
                                            options={{
                                                altInput: true,
                                                altFormat: "d-m-Y",
                                                dateFormat: "d-m-Y",
                                                // minDate: today
                                            }}
                                            onChange={shipperDateChange}
                                            value={shipperHandoverDate}
                                        // disabled={formikAccessoriesObj.values.accessories_type_id.indexOf(2) == 0 ? false : true}
                                        />
                                    </div>
                                </Col>
                            }
                        </Row>
                    </Form>
                </ModalBody>
                <div className="modal-footer">
                    {!!isSaving ?
                        <button className="btn btn-primary " disabled>
                            Processing
                        </button>
                        :
                        <>
                            <button
                                type="button"
                                onClick={closeAddEditAccessoriesModal}
                                className="btn btn-secondary "
                                data-dismiss="modal"
                            >
                                Close
                            </button>
                            <button
                                type="submit"
                                className="btn btn-primary "
                                onClick={(e) => {
                                    e.preventDefault();
                                    formikAccessoriesObj.handleSubmit();
                                    return false;
                                }}
                            >
                                {/* {subscriptionId == null ? "Save" : "Update"} */}
                                Save
                            </button>
                        </>
                    }
                </div>
            </Modal>
        </React.Fragment>
    );

}

export default withRouter(AddEditAccessory);

AddEditAccessory.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    member_id: PropTypes.number
};