import React, { useEffect, useState } from 'react'
import PropTypes, { any } from "prop-types";
import {
    Row, Col, CardBody, Card, Form, Label, Modal,
    ModalBody,
    ModalHeader,
    CardHeader
} from "reactstrap";
import { userDateFormat } from 'utils/date';

const SessionUpcomingCompletedModal = ({
    isOpenModal,
    onCloseClick,
    activityName,
    activityId,
    sessionLog,
    sessionType
}) => {

    return (
        <React.Fragment>
            <Modal
                isOpen={isOpenModal}
                toggle={onCloseClick}
                size="lg"
                backdrop="static"
            >
                <ModalHeader toggle={onCloseClick} tag="h4">
                    {sessionType == 1 ? "Completed" : "Upcoming"} Session
                </ModalHeader>
                <ModalBody>
                    <Card className="border border-primary">
                        <Form>
                            <CardHeader className="alert alert-primary">
                                <Row className="mb-2">
                                    <Col lg={6}>
                                        <Label>
                                            <b>Activity : </b>{' '}{activityName}
                                        </Label>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>

                                <div className="mb-2">
                                    <Row>
                                        {(activityId != 4 && activityId != 5) &&
                                            <Col xs={3}>
                                                <Label htmlFor="formrow-InputService"><b>Meeting Days</b></Label>&nbsp;&nbsp;
                                            </Col>
                                        }
                                        {(activityId != 5) &&
                                            <>
                                                <Col xs={3}>
                                                    <Label htmlFor="formrow-InputService"><b>Time From</b></Label>&nbsp;&nbsp;
                                                </Col>
                                                <Col xs={3}>
                                                    <Label htmlFor="formrow-InputService"><b>Time To</b></Label>&nbsp;&nbsp;
                                                </Col>
                                            </>
                                        }
                                        <Col xs={3}>
                                            <Label htmlFor="formrow-InputService"><b>Date</b></Label>&nbsp;&nbsp;
                                        </Col>
                                    </Row>
                                    {!!sessionLog && sessionLog.map((batch, index) => {
                                        return (
                                            <Row key={index}>
                                                {(activityId != 4 && activityId != 5) &&
                                                    <Col xs={3}>
                                                        <Label htmlFor="formrow-InputService">{batch?.day_name}</Label>&nbsp;&nbsp;
                                                    </Col>
                                                }
                                                {(activityId != 5) &&
                                                    <>
                                                        <Col xs={3}>
                                                            <Label htmlFor="formrow-InputService">{batch?.timing_from}</Label>&nbsp;&nbsp;
                                                        </Col>
                                                        <Col xs={3}>
                                                            <Label htmlFor="formrow-InputService">{batch?.timing_to}</Label>&nbsp;&nbsp;
                                                        </Col>
                                                    </>
                                                }
                                                <Col xs={3}>
                                                    <Label htmlFor="formrow-InputService">{userDateFormat(batch?.batch_date)}</Label>&nbsp;&nbsp;
                                                </Col>
                                            </Row>
                                        )
                                    })
                                    }
                                </div>
                            </CardBody>
                        </Form>
                    </Card>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

export default SessionUpcomingCompletedModal

SessionUpcomingCompletedModal.propTypes = {
    isOpenModal: PropTypes.bool,
    onCloseClick: PropTypes.func,
    activityName: PropTypes.any,
    activityId: PropTypes.any,
    sessionLog: PropTypes.any,
    sessionType: PropTypes.any
};