export const VIEW_PROFILE_QUERY = `query Profile {
    me{
        id
        salutation
        name
        profile_image
        primary_contact_no
        email
        status
    }
}`
