import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useEffect, useState } from "react";
import {
    Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label, Button, Modal,
    ModalBody,
    ModalHeader,
    InputGroup
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// Formik formikObj
import * as Yup from "yup";
import { useFormik } from "formik";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import $, { event } from "jquery";
import { standartDateFormat } from "utils/date";
import { axiosInstance, apiUrls } from "api";
import { authStorage } from "utils/auth";
import swal from 'sweetalert';
import profile_ids from "utils/profile_ids";
import { graphql } from "api";
// import * as UsersQuery from "GraphQLquery/usersQry";
import * as VendorsQuery from "GraphQLquery/vendorQry";
import usePaginator from "hooks/usePaginator";

import moment from "moment";

const initialUserData = {
    message_type: "inbox"
}

const Inbox = props => {

    const [addEditUserModal, setAddEditUserModal] = useState(false);
    const [dob, setDOB] = useState('');
    const [passwordConfirmed, setPasswordConfirmed] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [processingUserList, setProcessingUserList] = useState(false);
    const [error, setError] = useState(null);
    const [allUsersList, setAllUsersList] = useState([])
    const [userId, setUserId] = useState(null)
    const [userData, setUserData] = useState({ ...initialUserData })
    const [paginatorInfo, setPaginatorInfo, resetPaginatorInfo] = usePaginator();
    const [searchText, setSearchText] = useState('');


    useEffect(() => {
        fetchUsers(paginatorInfo, searchText)
    }, [])

    const fetchUsers = async (paginatorInfo, searchText) => {
        try {
            setProcessingUserList(true);
            const res = await graphql({
                query: VendorsQuery.FETCH_USERS_LIST_BY_PROFILE_ID_QUERY,
                variables: {
                    ...paginatorInfo,
                    profile_id: profile_ids.vendor,
                    searchText: `%${searchText}%`
                }
            });
            if (res.data) {
                setAllUsersList(!!res.data.vendors ? res.data.vendors.data : [])
                setPaginatorInfo(res?.data?.vendors?.paginatorInfo);
                setSearchText(searchText);
                setProcessingUserList(false)
            } else {
                setAllUsersList([]);
                resetPaginatorInfo();
                setProcessingUserList(false)
            }
        } catch (err) {
            setAllUsersList([]);
            resetPaginatorInfo();
            setProcessingUserList(false)
        }
    }

    const handleTableChange = (paginatorInfo, searchText) => {
        fetchUsers(paginatorInfo, searchText)
    }



    const formikObj = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: { ...userData },
        validationSchema: Yup.object({

        }),
        onSubmit: (values) => {
            if (userId == null) {
                addUserData(values)
            }
            else {
                updateUserData(values)
            }
        }
    });



    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Messages</title>
                </MetaTags>
                <Container fluid={true}>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs title="Dashboard" breadcrumbItem="Messages" />
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col lg={3}>
                                            <Input
                                                type="select"
                                                id="formrow-InputMessage"
                                                className="form-control"
                                                name="message_type"
                                                onChange={formikObj.handleChange}
                                                value={formikObj.values.message_type}
                                            >
                                                <option value="inbox">Inbox</option>
                                                <option value="sent_messages">Sent Messages</option>
                                            </Input>
                                        </Col>
                                        <Col lg={1}>
                                            <i className="fas fa-redo"></i>
                                        </Col>
                                        <Col lg={8} style={{ display: "flex", "justify-content": "flex-end" }}>
                                            <Button
                                                color="primary"
                                                className="font-16 btn-block"
                                                onClick={() => { props.history.push('/compose-message') }}
                                            >
                                                <i className="mdi mdi-plus-circle-outline me-1" />
                                                Compose Message
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    {/* Datatable view start */}

                    <Row>
                        <Col className="col-12"></Col>
                        <Card>
                            <CardBody>
                                <Link to={{
                                    pathname: "/inbox/inbox-details",
                                    messageId: 1
                                }}>
                                    <Row>
                                        <Col lg={12}>
                                            <div className="d-flex">
                                                <div className="align-self-center me-3">
                                                    <img src="https://camptra.com/stagingmicro/microsolApi/public/images/profile/Ravi1645165037.png" className="rounded-circle avatar-xs" alt="" />
                                                </div>
                                                <div className="flex-grow-1 overflow-hidden">
                                                    <h5 className="text-truncate font-size-14 mb-1">Adam Miller</h5>
                                                    <p className="text-truncate mb-0">I have finished it! See you so</p>
                                                </div>
                                                <div className="font-size-14">{moment().format('lll')}</div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Link>

                                <hr />

                                <Link to={{
                                    pathname: "/inbox/inbox-details",
                                    messageId: 1
                                }}>
                                    <Row>
                                        <Col lg={12}>
                                            <div className="d-flex">
                                                <div className="align-self-center me-3">
                                                    <img src="https://camptra.com/stagingmicro/microsolApi/public/images/profile/Ravi1645165037.png" className="rounded-circle avatar-xs" alt="" />
                                                </div>
                                                <div className="flex-grow-1 overflow-hidden">
                                                    <h5 className="text-truncate font-size-14 mb-1">Adam Miller</h5>
                                                    <p className="text-truncate mb-0">I have finished it! See you so</p>
                                                </div>
                                                <div className="font-size-14">{moment().format('lll')}</div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Link>

                            </CardBody>
                        </Card>
                    </Row>


                </Container>
            </div>
        </React.Fragment>
    );
};

export default withRouter(Inbox);

Inbox.propTypes = {
    history: PropTypes.object,
};