import { withRouter } from "react-router-dom"
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useEffect, useState } from "react";
import {
    Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label, Button, Modal,
    ModalBody,
    ModalHeader,
    InputGroup, Table, CardTitle, CardHeader
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { setModalState } from "../../store/actions";
import { userDateFormat } from "utils/date";

const ScheduleModal = props => {
    const { isOpenScheduleModal, data } = props
    const dispatch = useDispatch();
    const [openModal, setOpenModal] = useState(false)

    useEffect(() => {
        setOpenModal(isOpenScheduleModal)
    }, [isOpenScheduleModal])

    const closeModal = () => {
        setOpenModal(false)
        dispatch(setModalState(false));
    }

    return (
        <React.Fragment>
            <Modal
                isOpen={openModal}
                toggle={closeModal}
                size="lg"
                backdrop="static"
            >
                <ModalHeader toggle={closeModal} tag="h4">
                    Batch Details
                </ModalHeader>
                <ModalBody>
                    <Card className="border border-primary">
                        <Form>
                            <CardHeader className="alert alert-primary">
                                <Row className="mb-2">
                                    <Col lg={6}>
                                        <Label>
                                            <b>Activity : </b>{' '}{data?.activity}
                                        </Label>
                                    </Col>
                                    <Col lg={6} className="text-end">
                                        <Label htmlFor="formrow-startDate-Input"><b>Start Date : </b></Label>{' '}
                                        {userDateFormat(data.start_date)}
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col lg={6}>
                                        <Label>
                                            <b>Conducted By : </b>{' '}{data?.trainer_name}
                                        </Label>
                                    </Col>
                                    <Col lg={6} className="text-end">
                                        <Label htmlFor="formrow-endDate-Input"><b>End Date : </b></Label>{' '}
                                        {userDateFormat(data.end_date)}
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                
                                <div className="mb-2">
                                    <Row>
                                        <Col xs={3}>
                                            <Label htmlFor="formrow-InputService"><b>Meeting Days</b></Label>&nbsp;&nbsp;
                                        </Col>
                                        <Col xs={3}>
                                            <Label htmlFor="formrow-InputService"><b>Time From</b></Label>&nbsp;&nbsp;
                                        </Col>
                                        <Col xs={3}>
                                            <Label htmlFor="formrow-InputService"><b>Time To</b></Label>&nbsp;&nbsp;
                                        </Col>
                                        <Col xs={3}>
                                            <Label htmlFor="formrow-InputService"><b>Location</b></Label>&nbsp;&nbsp;
                                        </Col>
                                    </Row>
                                    {!!data.batch_details && data.batch_details.map((batch, index) => {
                                        return (
                                            <Row key={index}>
                                                <Col xs={3}>
                                                    <Label htmlFor="formrow-InputService">{batch?.day_name}</Label>&nbsp;&nbsp;
                                                </Col>
                                                <Col xs={3}>
                                                    <Label htmlFor="formrow-InputService">{batch?.timing_from}</Label>&nbsp;&nbsp;
                                                </Col>
                                                <Col xs={3}>
                                                    <Label htmlFor="formrow-InputService">{batch?.timing_to}</Label>&nbsp;&nbsp;
                                                </Col>
                                                <Col xs={3}>
                                                    <Label htmlFor="formrow-InputService">{batch?.location}</Label>&nbsp;&nbsp;
                                                </Col>
                                            </Row>
                                        )
                                    })
                                    }
                                </div>
                                <Row className="mb-2">
                                    <Label className="col-sm-2 col-form-label">
                                        <b>Capacity</b>{'  '}{data?.capacity}
                                    </Label>
                                </Row>
                            </CardBody>
                        </Form>
                    </Card>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
}
export default withRouter(ScheduleModal);

ScheduleModal.propTypes = {
    history: PropTypes.object,
    isOpenScheduleModal: PropTypes.bool,
    data: PropTypes.array
};