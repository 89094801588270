import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useEffect, useState } from "react";
import {
    Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label, Button, Modal,
    ModalBody,
    ModalHeader,
    InputGroup,
    CardTitle,
    CardText,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Spinner,
    Collapse,
    CardHeader,
    Table
} from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import classnames from "classnames";
import Flatpickr from "react-flatpickr";

// Formik formikObj   
import * as Yup from "yup";
import { useFormik, FieldArray, FormikProvider } from "formik";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { today, standartDateFormat } from "utils/date";
import { axiosInstance, apiUrls } from "api";
import swal from 'sweetalert';
import { graphql } from "api";

// datatable related plugins

import CommonTable from "components/Common/CommonTable";
import usePaginator from "hooks/usePaginator";
import * as ExerciseQry from "GraphQLquery/exerciseQry";
import * as MemberQry from "GraphQLquery/memberQry";

import moment from "moment";

import { useSelector, useDispatch } from "react-redux";
import { setWorkoutAssignedMember } from "store/actions";

const initialMemberWorkoutData = {
    workout_plan_id: '',
    member_id: '',
    goal: '',
    next_workout_chart_date: ''
}

const AssignMemberWorkoutPlan = props => {
    const dispatch = useDispatch();
    const member_id = props.member_id
    const assignWorkoutModal = props.assignWorkoutModal
    const redirectPath = props.redirectPath;
    const [paginatorInfo, setPaginatorInfo, resetPaginatorInfo] = usePaginator();
    const [searchText, setSearchText] = useState('');
    const [memberTiming, setMemberTiming] = useState("")
    const [mainTrainer, setMainTrainer] = useState("")
    const [workoutPlans, setWorkoutPlans] = useState([])
    const [workoutList, setWorkoutList] = useState([])
    const [mainTrainerList, setMainTrainerList] = useState([])
    const [workoutPlanDetail, setWorkoutPlanDetail] = useState([]);
    const [viewWorkoutPlanModal, setViewWorkoutPlanModal] = useState(false);
    const [dayFirstWorkout, setDayFirstWorkout] = useState([])
    const [daySecondWorkout, setDaySecondWorkout] = useState([])
    const [dayThirdWorkout, setDayThirdWorkout] = useState([])
    const [dayFourWorkout, setDayFourWorkout] = useState([])
    const [dayFiveWorkout, setDayFiveWorkout] = useState([])
    const [daySixWorkout, setDaySixWorkout] = useState([])
    const [processingList, setProcessingList] = useState(false);
    const [memberWorkoutData, setMemberWorkoutData] = useState({ ...initialMemberWorkoutData })
    const [addEditAssignWorkoutModal, setAddEditAssignWorkoutModal] = useState(false);
    const [error, setError] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const [processingMemberList, setProcessingMemberList] = useState(false);
    const [allMemberList, setAllMemberList] = useState([]);
    const [col1, setcol1] = useState(true);
    const [col2, setcol2] = useState(false);
    const [col3, setcol3] = useState(false);
    const [col4, setcol4] = useState(false);
    const [col5, setcol5] = useState(false);
    const [col6, setcol6] = useState(false);
    const [nextWorkoutChartDate, setNextWorkoutChartDate] = useState('')
    const [memberId, setMemberId] = useState('')
    const [offSet, setOffSet] = useState('10')
    const [pageURL, setPageURL] = useState('1')
    const [pageLinks, setPageLinks] = useState([])
    const [memberPaginatorInfo, setMemberPaginatorInfo, resetMemberPaginatorInfo] = usePaginator();
    const [memberSearchText, setMemberSearchText] = useState('');

    useEffect(() => {
        fetchMemberListData()
        fetchAllWorkoutPlanListData()
        fetchMainTrainerList()
        fetchMemberWiseWorkoutPlan(paginatorInfo, searchText, mainTrainer, memberTiming, null)
        localStorage.setItem("day", null)
        localStorage.setItem("workoutPlanDetailId", null)
        localStorage.setItem("memberWise", null)
    }, [])

    useEffect(() => {
        !!assignWorkoutModal && assignWorkout()
    }, [assignWorkoutModal])

    const fetchMemberWiseWorkoutPlan = async (paginatorInfo, searchText, main_trainer, timing, id) => {
        let res;
        try {
            (id == null || !!member_id == false) && setProcessingList(true)
            !!id ?
                res = await graphql({
                    query: MemberQry.FETCH_MEMBER_WISE_ASSIGNED_WORKOUT_PLANS_VIEW_QUERY,
                    variables: {
                        ...paginatorInfo,
                        // searchText: `%${searchText}%`,
                        id: id
                    }
                })
                :
                !!member_id ?
                    res = await graphql({
                        query: MemberQry.FETCH_MEMBER_WISE_ASSIGNED_WORKOUT_PLANS_QUERY,
                        variables: {
                            ...paginatorInfo,
                            searchText: `%${searchText}%`,
                            member_id: !!member_id ? member_id : '%%'
                        }
                    })
                    :
                    res = await axiosInstance.post(apiUrls.workoutPlan.memberWorkoutPlanList + "?page=" + pageURL, { offset: offSet, search_text: searchText, main_trainer: main_trainer, timing: timing });

            if (!!res.data.memberwise_workout_plans && res.data.memberwise_workout_plans.data) {

                if (id) {
                    setWorkoutPlanDetail(!!res.data.memberwise_workout_plans ? res.data?.memberwise_workout_plans?.data?.[0] : [])
                    setMemberId(res.data.memberwise_workout_plans.data[0].member_id)
                    // setViewWorkoutPlanModal(true);
                    let dayFirstWorkout = res.data.memberwise_workout_plans.data?.[0].workout_plan_details.filter(item => item.day == 1)
                    let daySecondWorkout = res.data.memberwise_workout_plans.data?.[0].workout_plan_details.filter(item => item.day == 2)
                    let dayThirdWorkout = res.data.memberwise_workout_plans.data?.[0].workout_plan_details.filter(item => item.day == 3)
                    let dayFourWorkout = res.data.memberwise_workout_plans.data?.[0].workout_plan_details.filter(item => item.day == 4)
                    let dayFiveWorkout = res.data.memberwise_workout_plans.data?.[0].workout_plan_details.filter(item => item.day == 5)
                    let daySixWorkout = res.data.memberwise_workout_plans.data?.[0].workout_plan_details.filter(item => item.day == 6)
                    setDayFirstWorkout(dayFirstWorkout)
                    setDaySecondWorkout(daySecondWorkout)
                    setDayThirdWorkout(dayThirdWorkout)
                    setDayFourWorkout(dayFourWorkout)
                    setDayFiveWorkout(dayFiveWorkout)
                    setDaySixWorkout(daySixWorkout)
                }
                else {
                    setProcessingList(false)
                    setWorkoutList(!!res.data.memberwise_workout_plans ? res.data.memberwise_workout_plans.data : [])
                    setPaginatorInfo(res?.data?.memberwise_workout_plans?.paginatorInfo);
                    setSearchText(searchText);

                    setWorkoutPlanDetail(!!res.data.memberwise_workout_plans ? res.data?.memberwise_workout_plans?.data?.[0] : [])
                    setMemberId(res.data.memberwise_workout_plans.data[0].member_id)
                    // setViewWorkoutPlanModal(true);
                    let dayFirstWorkout = res.data.memberwise_workout_plans.data?.[0].workout_plan_details.filter(item => item.day == 1)
                    let daySecondWorkout = res.data.memberwise_workout_plans.data?.[0].workout_plan_details.filter(item => item.day == 2)
                    let dayThirdWorkout = res.data.memberwise_workout_plans.data?.[0].workout_plan_details.filter(item => item.day == 3)
                    let dayFourWorkout = res.data.memberwise_workout_plans.data?.[0].workout_plan_details.filter(item => item.day == 4)
                    let dayFiveWorkout = res.data.memberwise_workout_plans.data?.[0].workout_plan_details.filter(item => item.day == 5)
                    let daySixWorkout = res.data.memberwise_workout_plans.data?.[0].workout_plan_details.filter(item => item.day == 6)
                    setDayFirstWorkout(dayFirstWorkout)
                    setDaySecondWorkout(daySecondWorkout)
                    setDayThirdWorkout(dayThirdWorkout)
                    setDayFourWorkout(dayFourWorkout)
                    setDayFiveWorkout(dayFiveWorkout)
                    setDaySixWorkout(daySixWorkout)
                }

            }

            else if (res.data) {
                setWorkoutList(!!res?.data?.length > 0 ? res?.data : [])
                setPageLinks(!!res?.data?.length > 0 ? res?.links : [])
                setProcessingList(false)
            }

            else {

                if (id) {
                    setWorkoutPlanDetail([])
                }
                else {
                    setProcessingList(false)
                    setWorkoutList([])
                    resetPaginatorInfo();
                }

            }
        } catch (error) {
            if (id) {
                setWorkoutPlanDetail([])
            }
            else {
                setProcessingList(false)
                setWorkoutList([])
                resetPaginatorInfo();
            }
        }
    }

    const fetchMainTrainerList = async () => {
        try {
            const res = await axiosInstance.post(apiUrls.trainer.allMainTrainerList)
            if (res.success == 1) {
                setMainTrainerList(res.data)
            }
            else {
                setMainTrainerList([])
            }
        } catch (error) {
            setMainTrainerList([])
        }
    }

    const fetchMemberListData = async () => {
        let allMember = []
        try {
            setProcessingMemberList(true)
            const res = await graphql({
                query: MemberQry.FETCH_ALL_MEMBERS_FOR_MEMBERSHIP_QUERY,
                variables: {
                    // ...paginatorInfo,
                    // searchText: `%${searchText}%`,
                    // id: "%%",
                    // status_id: "%%"
                }
            });

            if (res.data.members.data) {
                setProcessingMemberList(false)
                setAllMemberList(!!res.data.members ? res.data.members.data : [])
                allMember = !!res.data.members ? res.data.members.data : []
            }

            else {
                setProcessingMemberList(false)
                setAllMemberList([])
                allMember = []
            }
        } catch (error) {
            setProcessingMemberList(false)
            setAllMemberList([])
            allMember = []
        }
    }

    const fetchAllWorkoutPlanListData = async () => {
        try {
            const res = await graphql({
                query: ExerciseQry.FETCH_ALL_WORKOUT_PLAN_TO_ASSIGN_MEMBER_QUERY
            });

            if (res.data.workout_plans.data) {
                let response = res.data.workout_plans.data
                setWorkoutPlans(response)
            }
        } catch (error) {
            setError(error)
        }
    }

    const handleAction = (cell, row) => {
        return (
            <>
                <Button className="btn btn-warning  btn btn-info btn-sm ms-2" onClick={(e) => { handleFetch(e, row) }}>
                    <i className="fas fa-eye font-size-16 align-middle" />
                </Button>
                <Button className="btn-info btn-sm ms-2" onClick={(e) =>
                    props.history.push({
                        pathname: "/edit-workout-plan",
                        state: {
                            workout_id: !!row.id ? row.id : row.memberwise_workout_plan_id,
                            assignMemberWorkout: true,
                            member_id: row.member_id,
                            redirectPath: redirectPath
                        }
                    })
                }
                >
                    <i className="bx bx-pencil align-middle" />
                </Button>
                {!!member_id == true &&
                    <Button className="btn-danger btn-sm ms-2" onClick={(e) => { handleDelete(e, row) }}>
                        <i className="bx bx-trash align-middle" />
                    </Button>
                }
            </>
        );
    };

    const handleFetch = (e, row) => {
        setWorkoutPlanDetail([])
        setViewWorkoutPlanModal(true);
        let id = !!row.id ? row.id : row.memberwise_workout_plan_id
        fetchMemberWiseWorkoutPlan(paginatorInfo, searchText, mainTrainer, memberTiming, id)
    }

    const handleDelete = (e, row) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (willDelete) => {
                if (willDelete) {
                    try {
                        const response = await axiosInstance.post(apiUrls.member.deleteMemberWiseWorkoutPlan, { id: row.id });
                        if (response.success == 1) {
                            swal("Success!", response.message, "success");
                            fetchMemberWiseWorkoutPlan(paginatorInfo, searchText, mainTrainer, memberTiming, null)
                        }
                        else {
                            swal("Oops!", response.message, "error");
                        }
                    } catch (err) {
                        swal("Oops!", "Something went wrong!", "error");
                        setError(err.message)
                    }
                }
            });
    }

    const columns = [
        !!member_id == false && {
            // dataField: 'member_details[member_name]',
            formatter: (cell, row) => <Link to={{ pathname: "/member-details", state: { member_id: row.member_id, tabType: "4" } }}>{row.member_details.member_name}</Link>,
            text: 'MEMBER NAME',
            sort: true,
        },
        {
            dataField: 'workout_plan_name',
            text: 'WORKOUT PLAN NAME',
            sort: true,
        },
        {
            dataField: 'goal',
            text: 'GOAL',
            sort: true,
        },
        {
            // dataField: 'description',
            formatter: (cell, row) => <div style={{ whiteSpace: "normal" }}>{row.description}</div>,
            text: 'DESCRIPTION',
            sort: true,
        },
        {
            dataField: 'prepared_by',
            text: 'PREPARED BY',
            sort: true,
        },
        {
            formatter: (cell, row) => moment(row.created_at).format("DD-MM-YYYY"),
            text: 'CREATION DATE',
            sort: true,
        },
        {
            dataField: '',
            text: 'ACTION',
            sort: true,
            formatter: handleAction
        }];

    const handleTableChange = (paginatorInfo, searchText) => {
        fetchMemberWiseWorkoutPlan(paginatorInfo, searchText, mainTrainer, memberTiming, null)
    }

    const handleOffsetChange = (e) => {
        setOffSet(e.target.value)
    }

    const getPageNumber = (e, data) => {
        e.preventDefault()
        if (parseInt(data.label)) {
            setPageURL(data.label)
        } else {
            let pageNumber = data.url.split("=")
            setPageURL(pageNumber[1])
        }

    }

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    }

    useEffect(() => {
        !!member_id == false && fetchMemberWiseWorkoutPlan(paginatorInfo, searchText, mainTrainer, memberTiming, null)
    }, [offSet, pageURL, searchText, mainTrainer, memberTiming])

    const formikObj = useFormik({
        enableReinitialize: true,

        initialValues: { ...memberWorkoutData },
        validationSchema: Yup.object({
            workout_plan_id: Yup.string().required("Please Select Workout Plan"),
            member_id: !!member_id == false && Yup.string().required("Please Select Member"),
        }),
        onSubmit: async (values) => {
            values.member_id = !!member_id ? member_id : values.member_id
            // return
            try {
                setIsSaving(true)
                const response = await axiosInstance.post(apiUrls.member.addAndUpdateMemberWiseWorkoutPlan, { ...values });
                if (response.success == 1) {
                    formikObj.resetForm()
                    setIsSaving(false)
                    swal("Success!", response.message, "success");
                    closeModal()
                    dispatch(setWorkoutAssignedMember(true));
                    fetchMemberWiseWorkoutPlan(paginatorInfo, searchText, mainTrainer, memberTiming, null)
                }
                else {
                    setIsSaving(false)
                    swal("Oops!", response.message, "error");
                }
            } catch (err) {
                setIsSaving(false)
                swal("Oops!", "Something went wrong!", "error");
                setError(err.message)
            }
        }
    });

    const assignWorkout = () => {

        formikObj.resetForm();
        openModal()
    }

    const closeModal = () => {
        setAddEditAssignWorkoutModal(false);
        dispatch(setWorkoutAssignedMember(false));
    }

    const closeWorkoutViewModal = () => {
        setViewWorkoutPlanModal(false);
    }

    const openModal = () => {
        setAddEditAssignWorkoutModal(true);
    }

    const t_col = (e, tabValue) => {
        if (tabValue == 1) {
            setcol1(!col1);
            setcol2(false);
            setcol3(false);
            setcol4(false);
            setcol5(false);
            setcol6(false);
        }
        if (tabValue == 2) {
            setcol2(!col2);
            setcol1(false);
            setcol3(false);
            setcol4(false);
            setcol5(false);
            setcol6(false);
        }
        if (tabValue == 3) {
            setcol3(!col3);
            setcol1(false);
            setcol2(false);
            setcol4(false);
            setcol5(false);
            setcol6(false);
        }
        if (tabValue == 4) {
            setcol4(!col4);
            setcol1(false);
            setcol2(false);
            setcol3(false);
            setcol5(false);
            setcol6(false);
        }
        if (tabValue == 5) {
            setcol4(false);
            setcol1(false);
            setcol2(false);
            setcol3(false);
            setcol5(!col5);
            setcol6(false);
        }
        if (tabValue == 6) {
            setcol4(false);
            setcol1(false);
            setcol2(false);
            setcol3(false);
            setcol5(false);
            setcol6(!col6);
        }
    }

    const renderWorkoutsDetails = (workout, tabValue) => {
        return <div className="accordion-item">
            <h1 className="accordion-header" id="headingOne">
                <button
                    className={classnames(
                        "accordion-button",
                        "fw-medium",
                        { collapsed: tabValue == 1 && !col1 || tabValue == 2 && !col2 || tabValue == 3 && !col3 || tabValue == 4 && !col4 || tabValue == 5 && !col5 || tabValue == 6 && !col6 }
                    )}
                    type="button"
                    onClick={(e) => t_col(e, tabValue)}
                    style={{ cursor: "pointer" }}
                >
                    <strong>DAY- {workout?.[0].day}</strong> &nbsp;
                    {
                        (workout?.[0].day == 1 && !!workoutPlanDetail?.day_one_target) && "(" + workoutPlanDetail?.day_one_target + ")" ||
                        (workout?.[0].day == 2 && !!workoutPlanDetail?.day_two_target) && "(" + workoutPlanDetail?.day_two_target + ")" ||
                        (workout?.[0].day == 3 && !!workoutPlanDetail?.day_three_target) && "(" + workoutPlanDetail?.day_three_target + ")" ||
                        (workout?.[0].day == 4 && !!workoutPlanDetail?.day_four_target) && "(" + workoutPlanDetail?.day_four_target + ")" ||
                        (workout?.[0].day == 5 && !!workoutPlanDetail?.day_five_target) && "(" + workoutPlanDetail?.day_five_target + ")" ||
                        (workout?.[0].day == 6 && !!workoutPlanDetail?.day_six_target) && "(" + workoutPlanDetail?.day_six_target + ")"
                    }
                </button>
            </h1>

            <Collapse isOpen={tabValue == 1 && col1 || tabValue == 2 && col2 || tabValue == 3 && col3 || tabValue == 4 && col4 || tabValue == 5 && col5 || tabValue == 6 && col6} className="accordion-collapse">
                <div className="accordion-body">
                    <div className="text-end mb-4">
                        <Link
                            to="/workout-view"
                            onClick={(e) => {
                                localStorage.setItem("day", workout?.[0].day)
                                localStorage.setItem("workoutPlanDetailId", workoutPlanDetail.id)
                                localStorage.setItem("memberWise", true)
                                localStorage.setItem("memberId", workoutPlanDetail.member_details.id)
                                localStorage.setItem("memberName", workoutPlanDetail.member_details.member_name)
                                localStorage.setItem("workoutPlanName", workoutPlanDetail.workout_plan_name)
                            }}
                            target="_blank"
                            className="btn btn-warning btn-sm">
                            <i className="fas fa-eye" />
                        </Link>
                    </div>
                        {/* <div className="text-primary">
                            <Row>
                                <Col col={4}>
                                    <Label><strong>CATEGORY</strong></Label>
                                </Col>
                                <Col col={4}>
                                    <Label><strong>EXERCISE NAME</strong></Label>
                                </Col>
                                <Col col={1}>
                                    <Label><strong>#SET</strong></Label>
                                </Col>
                                <Col col={1}>
                                    <Label><strong>#REP</strong></Label>
                                </Col>
                                <Col col={2}>
                                    <Label><strong>DURATION ({workout?.[0]?.duration_type == 1 ? 'mins' : 'sec'} )</strong></Label>
                                </Col>
                            </Row>

                        </div>

                        {workout?.map((details, index) => {
                            return (
                                <>
                                    <Row key={index}>
                                        <Col col={4}>
                                            {details?.workout_plan_category_details?.category_name}
                                        </Col>
                                        <Col col={4}>
                                            {details?.workout_plan_exercise_details?.exercise_name}
                                        </Col>
                                        <Col col={1}>
                                            {details?.no_of_set || '-'}
                                        </Col>
                                        <Col col={1}>
                                            {details?.no_of_rep || '-'}
                                        </Col>
                                        <Col col={2}>
                                            {details?.duration || '-'}
                                        </Col>
                                    </Row>
                                    {workout.length - 1 != index && <hr />}
                                </>
                            )
                        })} */}
                        <div className="table-responsive">
                            <table className="table mb-0">
                                <thead>
                                    <tr>
                                        <th><Label className="text-primary"><strong>CATEGORY</strong></Label></th>
                                        <th><Label className="text-primary"><strong>EXERCISE NAME</strong></Label></th>
                                        <th><Label className="text-primary"><strong>#SET</strong></Label></th>
                                        <th><Label className="text-primary"><strong>#REP</strong></Label></th>
                                        <th><Label className="text-primary"><strong>DURATION ({workout?.[0]?.duration_type == 1 ? 'mins' : 'sec'} )</strong></Label></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {workout?.map((details, index) => {
                                return (
                                    <>
                                        <tr key={index}>
                                            <td>
                                                {details?.workout_plan_category_details?.category_name}
                                            </td>
                                            <td>
                                                {details?.workout_plan_exercise_details?.exercise_name}
                                            </td>
                                            <td>
                                                {details?.no_of_set || '-'}
                                            </td>
                                            <td>
                                                {details?.no_of_rep || '-'}
                                            </td>
                                            <td>
                                                {details?.duration || '-'}
                                            </td>
                                        </tr>
                                    </>
                                )
                            })}
                                </tbody>
                            </table>
                        </div>
                </div>
            </Collapse>
        </div>
    }

    const nextWorkoutChartDateChange = (nextWorkoutDate) => {
        if(!!nextWorkoutDate[0]){
            setNextWorkoutChartDate(nextWorkoutDate[0].getDate() + '-' + (nextWorkoutDate[0].getMonth() + 1) + '-' + nextWorkoutDate[0].getFullYear())
            formikObj.setFieldValue("next_workout_chart_date", standartDateFormat(nextWorkoutDate[0]))
        }
        else{
            setNextWorkoutChartDate('')
            formikObj.setFieldValue("next_workout_chart_date", "")
        }
    }

    const getMemberName = () => {
        let mid = !!member_id ? member_id : memberId
        let memberObj = !!allMemberList && allMemberList.find(item => item.id == mid)
        return memberObj?.member_name
    }

    const filterMemberWorkoutByMainTrainer = (e) => {
        let mainTrainer = ""
        let paginator = paginatorInfo
        paginator.currentPage = 1
        if (e.target.value != "") {
            mainTrainer = parseInt(e.target.value)
            setMainTrainer(mainTrainer)
        }
        else {
            setMainTrainer(mainTrainer)
        }
        // fetchMemberWiseWorkoutPlan(paginator, searchText, mainTrainer, memberTiming, null)
    }

    const filterMemberWorkoutByTiming = (e) => {
        let memberTiming = ""
        let paginator = paginatorInfo
        paginator.currentPage = 1
        if (e.target.value != "") {
            memberTiming = parseInt(e.target.value)
            setMemberTiming(memberTiming)
        }
        else {
            setMemberTiming(memberTiming)
        }
        // fetchMemberWiseWorkoutPlan(paginator, searchText, mainTrainer, memberTiming, null)
    }

    return (
        <React.Fragment>
            <div className={!!member_id == false && "page-content"}>
                {!!member_id == false && <MetaTags>
                    <title>Members Workout Plan</title>
                </MetaTags>
                }
                <Container fluid={!!member_id == false && true}>
                    {!!member_id == false &&
                        <React.Fragment>
                            <Breadcrumbs title="Members Workout Plan" breadcrumbItem="Members Workout Plan" />
                            <Row>
                                <Col className="col-12">
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <Col lg={3}>
                                                    <Button
                                                        color="primary"
                                                        className="font-size-13 btn-block"
                                                        onClick={assignWorkout}
                                                    >
                                                        <i className="mdi mdi-plus-circle-outline me-1 align-middle font-size-16" />
                                                        Assign
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </React.Fragment>
                    }

                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    {!!member_id == false && <Row>
                                        <Col lg={8}>
                                            <h3>All Members Workout Plan List</h3><br />
                                        </Col>
                                        <Col lg={2}>
                                        {/* <div className="d-flex justify-content-end flex-sm-column flex-md-row mb-3 flex-wrap"> */}
                                            {/* <select className="btn btn-outline-primary ms-md-2 form-select w-auto pe-5 mb-3 mb-md-0" onChange={(e) => filterMemberWorkoutByMainTrainer(e)}>
                                                <option value=""> Main Trainer</option>
                                                {mainTrainerList.map((trainer, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <option value={trainer.id}>{trainer.name}</option>
                                                        </React.Fragment>
                                                    )
                                                })
                                                }
                                            </select> */}
                                            {/* <div className="ms-md-2 w-auto pe-5 mb-3"> */}
                                            <Input
                                                type="select"
                                                id="formrow-serviceType"
                                                className="form-control"
                                                onChange={(e) => filterMemberWorkoutByMainTrainer(e)}
                                            >
                                                <option value=""> Main Trainer</option>
                                                {mainTrainerList.map((trainer, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <option value={trainer.id}>{trainer.name}</option>
                                                        </React.Fragment>
                                                    )
                                                })
                                                }
                                            </Input>
                                        </Col>
                                            {/* </div>
                                            <div className="ms-md-2 w-auto pe-5 mb-3"> */}
                                        <Col lg={2}>
                                            <Input
                                                type="select"
                                                id="formrow-serviceType"
                                                className="form-control"
                                                onChange={(e) => filterMemberWorkoutByTiming(e)}
                                            >
                                                <option value="">Timing</option>
                                                <option value="1">Morning</option>
                                                <option value="2">Evening</option>
                                            </Input>
                                            {/* </div> */}


                                            {/* <select className="btn btn-outline-primary ms-md-2 form-select w-auto pe-5 mb-3 mb-md-0" onChange={(e) => filterMemberWorkoutByTiming(e)}>
                                                <option value="">Timing</option>
                                                <option value="1">Morning</option>
                                                <option value="2">Evening</option>
                                            </select> */}
                                        </Col>
                                        {/* </div> */}
                                        
                                    </Row>}
                                    {!!member_id == false ?
                                        <>
                                            {workoutList.length > 0 ?
                                                <>
                                                    <div className="search-box me-2 mb-2 d-inline-block">
                                                        <div className="position-relative">
                                                            <Label for="search-bar-0" className="search-label">
                                                                <span id="search-bar-0-label" className="sr-only">Search this table</span>
                                                                <Input id="search-bar-0"
                                                                    type="text"
                                                                    aria-labelledby="search-bar-0-label"
                                                                    className="form-control"
                                                                    placeholder="Search"
                                                                    // value=""
                                                                    onChange={(e) => handleSearch(e)}
                                                                />
                                                            </Label>
                                                            <i className="bx bx-search-alt search-icon"></i>
                                                        </div>
                                                    </div>

                                                    <Row>
                                                        <Col lg={12}>
                                                            <div className="table-responsive col-fixed">
                                                                <Table className="table mb-0">
                                                                    <thead>
                                                                        <tr className="whitespace-no-wrap">
                                                                            <th>ID</th>
                                                                            <th>MEMBER NAME</th>
                                                                            <th>BEGIN DATE</th>
                                                                            <th>MAIN TRAINER</th>
                                                                            <th>MEMBERSHIP PLAN</th>
                                                                            <th>TIMING</th>
                                                                            <th>LAST WORKOUT CHART DATE</th>
                                                                            <th>ASSIGNED WORKOUT CHART</th>
                                                                            <th>NEXT WORKOUT CHART DATE</th>
                                                                            <th>ACTION</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {workoutList.map((item, index) => {
                                                                            return (
                                                                                <tr key={index}>
                                                                                    <th scope="row">
                                                                                        <Link to={{
                                                                                            pathname: "/member-details",
                                                                                            state: { member_id: item.member_id, tabType: "4" }
                                                                                        }}
                                                                                        >
                                                                                            {item.member_id}
                                                                                        </Link>
                                                                                    </th>
                                                                                    <td>
                                                                                        <div className="d-flex align-items-center">
                                                                                            <Link to={{
                                                                                                pathname: "/member-details",
                                                                                                state: { member_id: item.member_id, tabType: "4" }
                                                                                            }}
                                                                                                style={{ color: item.workout_chart_flag == 1 && '#d50c38' }}
                                                                                            >
                                                                                                {item.member_name}
                                                                                            </Link>
                                                                                            <span className="badge rounded-pill bg-success ms-2 px-2 py-1">{item?.member_wise_workout_plans_count}</span>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td >{!!item.begin_date ? moment(item.begin_date).format('DD-MM-YYYY') : '-'}</td>
                                                                                    <td>{item.main_trainer_details ?
                                                                                            (item.main_trainer_details.map((mTrainer, index) => (
                                                                                               <div key={index}>
                                                                                                    <div className="bg-primary bg-soft p-2 my-1 text-primary badge">{mTrainer.nick_name}</div>
                                                                                               </div> 
                                                                                            ))) 
                                                                                        : 
                                                                                            "-"
                                                                                    }</td>
                                                                                    <td>{!!item.membership_details ? 
                                                                                        (item.membership_details.map((details, index) => (
                                                                                        <div key={index} style={{ whiteSpace: "normal" }} >
                                                                                            <div className="bg-primary bg-soft p-2 my-1 text-primary badge">
                                                                                                {details.plan_name}
                                                                                            </div>
                                                                                        </div>
                                                                                        ))) 
                                                                                    : 
                                                                                        ""
                                                                                    }</td>
                                                                                    <td>{!!item.membership_details ? 
                                                                                        (item.membership_details.map((details, index) => (
                                                                                        <div key={index} style={{ whiteSpace: "normal" }} >
                                                                                            <div className="badge bg-primary bg-soft p-2 my-1 text-primary">
                                                                                                <span style={{ fontWeight: "bold", color: "#00008B" }}>
                                                                                                    {details.preferred_timing_name}
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                        ))) 
                                                                                    : 
                                                                                        ""
                                                                                    }</td>
                                                                                    {/* <td>{!!item.timing ? <div className="badge bg-primary bg-soft p-2 my-1 text-primary"><span style={{ fontWeight: "bold", color: "#00008B" }}>{((item.timing == 1) ? "Morning" : "Evening")}</span></div> : "-" }</td> */}
                                                                                    <td>{!!item.workout_chart_date ? moment(item.workout_chart_date).format('DD-MM-YYYY') : "-"}</td>
                                                                                    <td>{item.workout_plan_name}</td>
                                                                                    <td style={{ color: item.workout_chart_flag == 1 && '#d50c38' }}>{!!item.next_workout_chart_date ? moment(item.next_workout_chart_date).format('DD-MM-YYYY') : '-'}</td>
                                                                                    <td className="whitespace-no-wrap">{handleAction(null, item)}</td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                        }
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </Col>
                                                        <div className="mt-2">
                                                            <div style={{ float: "left" }}>
                                                                <Input
                                                                    type="select"
                                                                    id="formrow-serviceType"
                                                                    className="form-control"
                                                                    name={'offset'}
                                                                    onChange={(e) => handleOffsetChange(e)}
                                                                // value={formikObj.values.member_id}
                                                                >
                                                                    <option value="10">10</option>
                                                                    <option value="5">5</option>
                                                                    <option value="10">10</option>
                                                                    <option value="15">15</option>
                                                                </Input>
                                                            </div>
                                                            <div style={{ justifyContent: "end", display: "flex" }}>
                                                                <ul className="pagination react-bootstrap-table-page-btns-ul">
                                                                    {pageLinks.map((btn, index) => {
                                                                        return (
                                                                            <React.Fragment key={index}>
                                                                                {!!btn.url &&
                                                                                    <li className={!!btn.active ? "active page-item" : "page-item"}>
                                                                                        <a className="page-link" href="#" onClick={(e) => getPageNumber(e, btn)}>
                                                                                            {btn.label.includes("Next") ? ">" : btn.label.includes("Previous") ? "<" : btn.label}
                                                                                        </a>
                                                                                    </li>
                                                                                }
                                                                            </React.Fragment>
                                                                        )
                                                                    })
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </Row>
                                                </>
                                                :
                                                <Col lg={12} style={{ textAlign: "center" }}>
                                                    <h5><i className="bx bx-hourglass bx-spin font-size-24 align-middle me-2"></i>
                                                        Loading...</h5>
                                                </Col>
                                            }
                                        </>
                                        :
                                        <Row>
                                            <Col lg={12}>
                                                <CommonTable
                                                    paginatorInfo={paginatorInfo}
                                                    searchText={searchText}
                                                    onTableChange={handleTableChange}
                                                    keyField='id'
                                                    columns={columns}
                                                    data={workoutList}
                                                    loading={processingList}
                                                />
                                            </Col>
                                        </Row>
                                    }

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* Assign workout Modal start */}

            <Modal
                isOpen={addEditAssignWorkoutModal}
                toggle={closeModal}
                size="lg"
                backdrop="static"
            // className={props.className}
            >
                <ModalHeader toggle={closeModal} tag="h4">
                    Assign Workout
                </ModalHeader>
                <ModalBody>
                    <Form>
                        {!!member_id == false &&
                            <Row>
                                <Col lg={12}>
                                    <div className="mb-3">
                                        <Label htmlFor="formrow-InputService">Member <span className="text-danger">*</span></Label>
                                        <Input
                                            type="select"
                                            id="formrow-serviceType"
                                            className="form-control"
                                            name={'member_id'}
                                            onChange={formikObj.handleChange}
                                            value={formikObj.values.member_id}
                                            invalid={
                                                formikObj.touched.member_id && formikObj.errors.member_id ? true : false
                                            }
                                        >
                                            <option value="">Select</option>
                                            {allMemberList.map((item) => (
                                                <option value={item.id} key={item.id}>{item.member_name}</option>
                                            ))}

                                        </Input>
                                        {formikObj.touched.member_id && formikObj.errors.member_id ? (
                                            <FormFeedback type="invalid">{formikObj.errors.member_id}</FormFeedback>
                                        ) : null}
                                    </div>
                                </Col>
                            </Row>
                        }
                        <Row>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-serviceType">Select Workout <span className="text-danger">*</span></Label>
                                    <Input
                                        type="select"
                                        id="formrow-serviceType"
                                        className="form-control"
                                        name={'workout_plan_id'}
                                        onChange={formikObj.handleChange}
                                        value={formikObj.values.workout_plan_id}
                                        invalid={
                                            formikObj.touched.workout_plan_id && formikObj.errors.workout_plan_id ? true : false
                                        }
                                    >
                                        <option defaultValue>Select</option>
                                        {workoutPlans?.map((item) => (
                                            <option value={item.id} key={item.id}>{item.workout_plan_name}</option>
                                        ))}

                                    </Input>
                                    {formikObj.touched.workout_plan_id && formikObj.errors.workout_plan_id ? (
                                        <FormFeedback type="invalid">{formikObj.errors.workout_plan_id}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-serviceType">Next Workout Chart Date</Label>
                                    <Flatpickr
                                        className="form-control d-block"
                                        name="next_workout_chart_date"
                                        placeholder="DD-MM-YYYY"
                                        options={{
                                            altInput: true,
                                            altFormat: "d-m-Y",
                                            dateFormat: "d-m-Y",
                                            // minDate: today
                                        }}
                                        onChange={nextWorkoutChartDateChange}
                                        value={nextWorkoutChartDate}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-InputService">Goal</Label>
                                    <Input
                                        type="textarea"
                                        id="formrow-InputService"
                                        className="form-control"
                                        name="goal"
                                        onChange={formikObj.handleChange}
                                        value={formikObj.values.goal}
                                        placeholder="Enter Goal"
                                        invalid={
                                            formikObj.touched.goal && formikObj.errors.goal ? true : false
                                        }
                                    />
                                    {formikObj.touched.goal && formikObj.errors.goal ? (
                                        <FormFeedback type="invalid">{formikObj.errors.goal}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <div className="modal-footer">
                    {!!isSaving ?
                        <button className="btn btn-primary " disabled>
                            Processing
                        </button>
                        :
                        <>
                            <button
                                type="button"
                                onClick={closeModal}
                                className="btn btn-secondary "
                                data-dismiss="modal"
                            >
                                Close
                            </button>
                            <button
                                type="submit"
                                className="btn btn-primary "
                                onClick={(e) => {
                                    e.preventDefault();
                                    formikObj.handleSubmit();
                                    return false;
                                }}
                            >
                                Save
                            </button>
                        </>
                    }
                </div>
            </Modal>

            {/* Assign workout Modal end */}

            {/* Assigned Workout View Modal start*/}

            <Modal
                isOpen={viewWorkoutPlanModal}
                toggle={closeWorkoutViewModal}
                size="lg"
                backdrop="static"
            // className={props.className}
            >
                <ModalHeader toggle={closeWorkoutViewModal} tag="h4">
                    Workout Plan Detail
                </ModalHeader>
                <ModalBody>
                    <Card>
                        <CardBody>
                            {(!!workoutPlanDetail && Object.keys(workoutPlanDetail).length > 0) ?
                                <Row>
                                    <Col lg={12}>
                                        <CardTitle style={{ textAlign: "center", marginTop: "-3%" }}>
                                            <div className="mb-2">
                                                <span className="member-name">
                                                    {getMemberName()}
                                                </span>&nbsp;
                                                <span className="badge rounded-pill" style={{ backgroundColor: "#DC2450", fontSize: "inherit" }}>ID:{member_id || memberId}</span>
                                            </div>
                                            <div>
                                                <label>Plan:</label>&nbsp;
                                                <span>{workoutPlanDetail?.workout_plan_name}</span>
                                            </div>
                                        </CardTitle>

                                        <div className="accordion" id="accordion">
                                            {dayFirstWorkout.length > 0 &&
                                                <>
                                                    {renderWorkoutsDetails(dayFirstWorkout, 1)}
                                                </>
                                            }
                                            {daySecondWorkout.length > 0 &&
                                                <>
                                                    {renderWorkoutsDetails(daySecondWorkout, 2)}
                                                </>
                                            }
                                            {dayThirdWorkout.length > 0 &&
                                                <>
                                                    {renderWorkoutsDetails(dayThirdWorkout, 3)}
                                                </>
                                            }
                                            {dayFourWorkout.length > 0 &&
                                                <>
                                                    {renderWorkoutsDetails(dayFourWorkout, 4)}
                                                </>
                                            }
                                            {dayFiveWorkout.length > 0 &&
                                                <>
                                                    {renderWorkoutsDetails(dayFiveWorkout, 5)}
                                                </>
                                            }
                                            {daySixWorkout.length > 0 &&
                                                <>
                                                    {renderWorkoutsDetails(daySixWorkout, 6)}
                                                </>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                                :
                                <Col lg={12} style={{ textAlign: "center" }}>
                                    <h5><i className="bx bx-hourglass bx-spin font-size-24 align-middle me-2"></i>
                                        Loading...</h5>
                                </Col>
                            }
                        </CardBody>
                    </Card>
                </ModalBody>
            </Modal>

            {/* Assigned workout view modal end */}

        </React.Fragment >
    );
}

export default withRouter(AssignMemberWorkoutPlan);

AssignMemberWorkoutPlan.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    member_id: PropTypes.number,
    assignWorkoutModal: PropTypes.bool,
    redirectPath: PropTypes.string
};