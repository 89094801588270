import moment from 'moment';

export const standartDateFormat = (date) => {
    return moment(date).format("YYYY-MM-DD")
}

export const standartDateTimeFormat = (date) => {
    return moment(date).format("YYYY-MM-DD H:mm")
}

export const today = moment(new Date()).format("DD-MM-YYYY")

export const userDateFormat = (date) => {
    return moment(date).format("DD-MM-YYYY")
}

export const userTimeFormat = (time) => {
    return moment(time, "hh:mm").format('LT')
}