import React, { Fragment } from "react";
import PropTypes, { any } from "prop-types";
import {
    Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label, Button, Modal,
    ModalBody,
    ModalHeader,
    InputGroup, Table
} from "reactstrap";
import { Link } from "react-router-dom";
import logo from "assets/images/favicon.svg";
import paid from "assets/images/paid.png";
import moment from "moment";
import { round } from "lodash";

const Invoice = ({ isOpenModal, onCloseClick, invoiceData }) => {

    const printInvoice = () => {
        window.print();
    };

    return (
        <React.Fragment>
            <Modal
                isOpen={isOpenModal}
                toggle={onCloseClick}
                size="lg"
                backdrop="static"
            >
                <ModalHeader toggle={onCloseClick} tag="h4">
                    Invoice
                </ModalHeader>
                <ModalBody>
                    <div id="printSection">
                        <Form>
                            <Row>
                                <Col lg="12">
                                    <Card>
                                        <CardBody>
                                            <div className="invoice-title">
                                                <h4 className="float-end font-size-16">
                                                    Inv No.:&nbsp;{invoiceData?.invoice_num}
                                                    <p className="gst-title">GSTIN: 19AACCN8354K2ZX <br />
                                                        Fitness Tower Pvt. Ltd.
                                                    </p>
                                                </h4>
                                                <div className="mb-4">
                                                    <img src={logo} alt="logo" height="50" />
                                                </div>
                                                <hr />
                                                <div className="mb-4" style={{ "text-align": "center" }}>
                                                    <span>AI81, Street No. 27
                                                        (beside Ahirini Market)
                                                        New Town, Kolkata - 700156
                                                    </span>
                                                </div>
                                            </div>
                                            <hr />
                                            <Row>
                                                <Col xs="6">
                                                    <address>
                                                        <strong>Billed To:</strong>
                                                        <br />
                                                        {invoiceData?.member_details?.member_name}
                                                        <br />
                                                        {invoiceData?.member_details?.address}
                                                        <br />
                                                        {invoiceData?.member_details?.mobile_no}
                                                    </address>
                                                </Col>
                                                <Col xs="6" className="text-end">
                                                    <address>
                                                        <strong>Invoice Date:</strong>
                                                        <br />
                                                        {moment(invoiceData?.beginning_date).format('DD-MM-YYYY')}
                                                        <br />
                                                        <br />
                                                    </address>
                                                </Col>
                                            </Row>
                                            <div className="py-2 mt-3">
                                                <h3 className="font-size-15 fw-bold">Order summary</h3>
                                            </div>
                                            <div className="table-responsive">
                                                <Table className="table-nowrap">
                                                    <thead>
                                                        <tr>
                                                            <th style={{ width: "100px" }}>Gym Type</th>
                                                            <th>Membership Plan</th>
                                                            {invoiceData?.subscription_details?.plan_type?.id == 2 &&
                                                                <>
                                                                    <th># Sessions</th>
                                                                    {/* <th>Charges/Session</th> */}
                                                                </>
                                                            }
                                                            <th>Start Date</th>
                                                            <th>End Date</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                {invoiceData?.subscription_details?.plan_type?.plan_type_name}
                                                            </td>
                                                            <td>
                                                                {invoiceData?.subscription_details?.plan_name}
                                                            </td>
                                                            {invoiceData?.subscription_details?.plan_type?.id == 2 &&
                                                                <>
                                                                    <td>
                                                                        {invoiceData?.number_of_sessions}
                                                                    </td>
                                                                    {/* <td>
                                                                                {invoiceData?.subscription_plan_charges}
                                                                            </td> */}
                                                                </>

                                                            }
                                                            <td>
                                                                {moment(invoiceData?.beginning_date).format('DD-MM-YYYY')}
                                                            </td>
                                                            <td>
                                                                {moment(invoiceData?.end_date).format('DD-MM-YYYY')}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </div>
                                            <div className="py-2 mt-3">
                                                <h3 className="font-size-15 fw-bold">Pricing summary</h3>
                                            </div>
                                            <div className="table-responsive">
                                                <Table className="table-nowrap">
                                                    <thead>
                                                        {invoiceData?.payment_mode?.payment_mode?.trim() === 'Bank' ?
                                                            <tr>
                                                                <th >Sub Total</th>
                                                                <th>Discount</th>
                                                                <th>GST @18%</th>
                                                                <th style={{ width: "70px" }}>Amount Paid</th>
                                                                <th style={{ width: "70px" }}>Paid Via</th>
                                                            </tr>
                                                            :
                                                            <tr>
                                                                <th style={{ width: "70px" }}>Sub Total</th>
                                                                <th style={{ width: "70px" }}>Amount Paid</th>
                                                                <th style={{ width: "70px" }}>Paid Via</th>
                                                            </tr>
                                                        }
                                                    </thead>
                                                    <tbody>
                                                        {invoiceData?.payment_mode?.payment_mode?.trim() === 'Bank' ?
                                                            <tr>
                                                                {invoiceData?.subscription_details?.plan_type?.id == 2 ?
                                                                    <td>
                                                                        {invoiceData?.subscription_plan_charges * invoiceData?.number_of_sessions}
                                                                    </td>
                                                                    :
                                                                    <td>
                                                                        {invoiceData?.subscription_plan_charges}
                                                                    </td>
                                                                }
                                                                <td>
                                                                    {invoiceData?.discount}

                                                                </td>
                                                                <td>
                                                                    {invoiceData?.gst_amount}
                                                                </td>
                                                                <td className="border-0 text-end">
                                                                    <h4 className="m-0">
                                                                        Rs.&nbsp;{round(invoiceData?.total_amount, 2)}
                                                                    </h4>
                                                                </td>
                                                                <td className="text-end">{invoiceData?.payment_mode?.payment_mode}</td>
                                                            </tr>
                                                            :
                                                            <tr>
                                                                <td className="">
                                                                    {round(invoiceData?.total_amount, 2)}
                                                                </td>
                                                                <td className="">
                                                                    <h4 className="m-0">
                                                                        {round(invoiceData?.total_amount, 2)}
                                                                    </h4>
                                                                </td>
                                                                <td className="">{invoiceData?.payment_mode?.payment_mode}</td>
                                                            </tr>
                                                        }
                                                    </tbody>
                                                </Table>
                                            </div>
                                            <div style={{ "textAlign": "center" }}>
                                                <img src={paid} height="150px" />
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </ModalBody>
                <div className="modal-footer">
                    <div className="d-print-none">
                        <div className="float-end">
                            <Button
                                onClick={printInvoice}
                                className="btn btn-success  me-2"
                            >
                                <i className="fa fa-print" />
                            </Button>
                            <Button className="btn btn-primary w-md ">
                                Send
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    );
}

export default Invoice;

Invoice.propTypes = {
    isOpenModal: PropTypes.bool,
    onCloseClick: PropTypes.func,
    invoiceData: PropTypes.any
}