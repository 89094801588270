let profile_ids
export default profile_ids = {
    "super_admin": 1,
    "region_head": 2,
    "zonal_head": 3,
    "service_head": 4,
    "business_development": 5,
    "office_admin": 6,
    "service_engineer": 7,
    "business_development_executive": 8,
    "billing_staff": 9,
    "customer_care_staff": 10,
    "customer": 11,
    "vendor": 12
}

export const actionPathsByProfileId = {
    [profile_ids.super_admin]: ['/order-details'],
    [profile_ids.customer_care_staff]: ['/create-order', '/edit-order/:order_id', '/order-details'],
    [profile_ids.service_head]: ['/create-order', '/edit-order/:order_id', '/order-details'],
    [profile_ids.customer]: ['/create-order', '/edit-order/:order_id', '/order-details'],
}

export const activities = [
    {
        "id": 1,
        "activity": "Multi Gym"
    },
    {
        "id": 2,
        "activity": "Zumba"
    },
    {
        "id": 3,
        "activity": "Yoga"
    },
    {
        "id": 4,
        "activity": "Steam Bath"
    },
    {
        "id": 5,
        "activity": "Personal Training"
    },
    {
        "id": 6,
        "activity": "Diet Plan"
    },
    {
        "id": 7,
        "activity": "Crossfit"
    }
]