import React, { useEffect, useState } from 'react'
import { withRouter, useLocation, Link, useParams } from 'react-router-dom'
import PropTypes, { any } from "prop-types";
import MetaTags from "react-meta-tags";
import {
    Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label, Button, Modal,
    ModalBody,
    ModalHeader,
    InputGroup,
    Spinner,
    CardTitle,
    Collapse
} from "reactstrap";
import { apiUrls, axiosInstance } from 'api';

const WorkoutView = props => {
    const [workoutSchedule, setWorkoutSchedule] = useState(null)
    const [error, setError] = useState(null);
    const [isPageLoading, setPageLoading] = useState(true);

    const day = localStorage.getItem("day")
    const workoutPlanDetailId = localStorage.getItem("workoutPlanDetailId")
    const memberWise = JSON.parse(localStorage.getItem("memberWise"))
    const memberId = localStorage.getItem("memberId")
    const memberName = localStorage.getItem("memberName")
    const workoutPlanName = localStorage.getItem("workoutPlanName")

    useEffect(async () => {
        setPageLoading(true);
        let params = ""
        let url = null
        if (memberWise) {
            url = apiUrls.workoutPlan.MemberWiseWorkoutPlanDayWise
            params = {
                member_wise_workout_plan_id: workoutPlanDetailId,
                day: day
            }
        } else {
            url = apiUrls.workoutPlan.workoutPlanDayWise
            params = {
                workout_plan_id: workoutPlanDetailId,
                day: day
            }
        }

        try {
            const response = await axiosInstance.post(url, params);
            if (response.success == 1) {
                let data = response?.data
                setWorkoutSchedule(data)
                setPageLoading(false)
            }
            else {
                swal("Oops!", response.message, "error");
                setPageLoading(false)
            }
        } catch (err) {
            swal("Oops!", "Something went wrong!", "error");
            setError(err.message)
            setPageLoading(false)
        }
    }, [])

    const printWorkout = () => {
        window.print();
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Workout View</title>
                </MetaTags>
                {isPageLoading ?
                    <Row>
                        <Col lg={12} style={{ textAlign: "center" }}>
                            <h5><i className="bx bx-hourglass bx-spin font-size-24 align-middle me-2"></i>
                                Loading...</h5>
                        </Col>
                    </Row>
                    :
                    <Container fluid={true} style={{ marginTop: "-60px" }}>
                        <div className="d-print-none">
                            <div className="d-flex justify-content-end">
                                <Link
                                    to="#"
                                    onClick={printWorkout}
                                    className="btn btn-success  me-2"
                                >
                                    <i className="fa fa-print" />
                                </Link>
                            </div>
                        </div>
                        <div id="printSection">
                            <div className="main-content-wrapper">
                                <div className="main-contents pb-1">
                                    <div className="m-4">
                                        <header className="header-home">
                                            <div>
                                                <p>Workout Plan
                                                    <br />
                                                    {memberWise &&
                                                        <React.Fragment>
                                                            <span className="member-name">
                                                                {memberName}
                                                            </span>&nbsp;&nbsp;
                                                            <span className="badge rounded-pill" style={{ backgroundColor: "#DC2450", fontSize: "18px" }}>ID:{memberId}</span>
                                                        </React.Fragment>
                                                    }
                                                </p>
                                                <a href="#" className="logo"><img
                                                    src="https://www.flexym.com/assets/images/Flexym-logo.svg" className="inline-block"
                                                    alt="Flexym Logo" />
                                                </a>
                                            </div>
                                        </header>

                                        {!!workoutSchedule &&
                                            <React.Fragment>
                                                <section className="content-heading">
                                                    <h4>{workoutSchedule?.workout_plan_name}</h4>
                                                    <p>{workoutSchedule?.description}</p>
                                                    <p>Prepared By-&nbsp;{workoutSchedule?.trainer_name || workoutSchedule?.prepared_by}</p>
                                                </section>

                                                <section className="content-details">

                                                    <h2 className=""><b>Day {day}</b>
                                                        <span>
                                                            (
                                                            {
                                                                (day == 1 && workoutSchedule?.day_one_target) ||
                                                                (day == 2 && workoutSchedule?.day_two_target) ||
                                                                (day == 3 && workoutSchedule?.day_three_target) ||
                                                                (day == 4 && workoutSchedule?.day_four_target) ||
                                                                (day == 5 && workoutSchedule?.day_five_target) ||
                                                                (day == 6 && workoutSchedule?.day_six_target)
                                                            }
                                                            )
                                                        </span>
                                                    </h2>
                                                    {workoutSchedule?.workout_category?.map((item, index) => {
                                                        return (
                                                            <div className="plan" key={index}>
                                                                <div className="plan-name">
                                                                    <h4>{item?.category_name}</h4>
                                                                </div>
                                                                {item?.workout_exercise?.map((exercise, index) => {
                                                                    return (
                                                                        <div key={index} className="position-relative">
                                                                            <div className="plan-details">
                                                                                <p className='mt-1'>{exercise?.exercise_name}</p>
                                                                                <p>
                                                                                    {(!!exercise?.duration && exercise?.duration + " " + (exercise?.duration_type == 1 ? " Min" : "Sec")) ||
                                                                                        (!!exercise?.no_of_rep && exercise?.no_of_rep + " Rep")} /Set x {exercise?.no_of_set}
                                                                                </p>
                                                                            </div>
                                                                            {!!exercise?.note &&
                                                                                <div>
                                                                                    <span><i><strong>Note:</strong>{' '}{exercise?.note}</i></span>
                                                                                </div>
                                                                            }
                                                                            {!!exercise?.exercise_choice == 1 &&
                                                                                <div className="exercise-choice mb-3">
                                                                                    <span>OR</span>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    )
                                                                })
                                                                }
                                                            </div>
                                                        )
                                                    })}
                                                </section>
                                            </React.Fragment>

                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Container>
                }
            </div>
        </React.Fragment>
    )
}

export default withRouter(WorkoutView);

WorkoutView.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
};