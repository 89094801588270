import { DIET_ASSIGNED_TO_MEMBER } from "./actionTypes";

const initialState = {
    dietAssignedStatus: false
}

const DietAssignedToMember = (state = initialState, action) => {
    switch (action.type) {
        case DIET_ASSIGNED_TO_MEMBER:
            state = { dietAssignedStatus: action.dietAssignedStatus }
            break;
        default:
            state = { dietAssignedStatus: null };
            break;
    }
    return state;
}

export default DietAssignedToMember