import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useEffect, useState } from "react";
import {
    Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label, Button, Modal,
    ModalBody,
    ModalHeader,
    InputGroup, Table
} from "reactstrap";
import { withRouter, Link } from "react-router-dom";

// Formik formikObj
import * as Yup from "yup";
import { useFormik } from "formik";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { standartDateFormat } from "utils/date";
import { axiosInstance, apiUrls } from "api";
import swal from 'sweetalert';
import { graphql } from "api";

// datatable related plugins

import CommonTable from "components/Common/CommonTable";
import usePaginator from "hooks/usePaginator";
import * as ExerciseQry from "GraphQLquery/exerciseQry";
import moment from "moment";
import BatchModal from "./BatchModal";
import { useSelector, useDispatch } from "react-redux";
import ScheduleModal from "./ScheduleModal";
import AssignedMemberModal from "./AssignedMemberModal";

const ManageBatch = props => {
    const batchModalState = useSelector(state => state.BatchModalState.modalStatus);
    const scheduleBatchModalState = useSelector(state => state.BatchModalState.scheduleModalStatus);
    const reloadBatchList = useSelector(state => state.BatchModalState.reloadBatchList);
    const assignedMemberModalStatus = useSelector(state => state.BatchModalState.assignedMemberModalStatus);
    const [error, setError] = useState(null);
    const [processingList, setProcessingList] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [allBatchList, setAllBatchList] = useState([]);
    const [offSet, setOffSet] = useState('10')
    const [pageURL, setPageURL] = useState('1')
    const [pageLinks, setPageLinks] = useState([])
    const [isOpenBatchModal, setIsOpenBatchModal] = useState(false)
    const [isOpenScheduleModal, setIsOpenScheduleModal] = useState(false)
    const [isOpenAssignedMemberModal, setIsOpenAssignedMemberModal] = useState(false)
    const [data, setData] = useState([])
    const [batchStatus, setBatchStatus]= useState("1")

    useEffect(() => {
        fetchAllBatchListData(searchText)
    }, [])

    useEffect(() => {
        setIsOpenBatchModal(false)
        setData([])
    }, [batchModalState])

    useEffect(() => {
        !!reloadBatchList && fetchAllBatchListData(searchText)
    }, [reloadBatchList])

    useEffect(() => {
        setIsOpenScheduleModal(false)
        setData([])
    }, [scheduleBatchModalState])

    useEffect(() => {
        setIsOpenAssignedMemberModal(false)
        setData([])
    }, [assignedMemberModalStatus])

    const fetchAllBatchListData = async (searchText) => {
        try {
            setProcessingList(true)
            const res = await axiosInstance.post(apiUrls.batch.batchList + "?page=" + pageURL, { offset: offSet, search_text: searchText, status: batchStatus });

            if (res.success == 1) {
                setProcessingList(false)
                setAllBatchList(!!res.data.data ? res?.data?.data : [])
                setPageLinks(!!res?.data?.length > 0 ? res?.links : [])
            }

            else {
                setProcessingList(false)
                setAllBatchList([])
            }
        } catch (error) {
            setProcessingList(false)
            setAllBatchList([])
        }
    }

    const handleAction = (cell, row) => {
        return (
            <>
                <div className="d-flex">
                    <Button className="btn-info btn-sm ms-2" onClick={(e) =>
                        props.history.push({
                            pathname: "/add-update-batch",
                            state: {
                                batch_id: row.id
                            }
                        }
                        )}>
                        <i className="bx bx-pencil align-middle" />
                    </Button>
                    &nbsp;
                    <Button className="btn-danger btn-sm ms-2" onClick={(e) => { handleDelete(e, row) }}>
                        <i className="bx bx-trash align-middle" />
                    </Button>
                    {/* &nbsp;
                    <Button className="btn btn-info  btn btn-info btn-sm" onClick={(e) => { handleChangeStatus(e, row) }}>
                        {row.status == 0 ? "Active" : "Inactive"}
                    </Button> */}
                </div>
            </>
        );
    };

    const handleOffsetChange = (e) => {
        setOffSet(e.target.value)
    }

    const getPageNumber = (e, data) => {
        e.preventDefault()
        if (parseInt(data.label)) {
            setPageURL(data.label)
        } else {
            let pageNumber = data.url.split("=")
            setPageURL(pageNumber[1])
        }

    }

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    }

    const handleDelete = (e, row) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (willDelete) => {
                if (willDelete) {
                    try {
                        const response = await axiosInstance.post(apiUrls.batch.deleteBatch, { id: row.id });
                        if (response.success == 1) {
                            swal("Success!", response.message, "success");
                            fetchAllBatchListData(searchText)
                        }
                        else {
                            swal("Oops!", response.message, "error");
                        }
                    } catch (err) {
                        swal("Oops!", "Something went wrong!", "error");
                        setError(err.message)
                    }
                }
            });
    }

    useEffect(()=>{
        fetchAllBatchListData(searchText)
    },[batchStatus])

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Manage Batch</title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumbs title="Manage Batch" breadcrumbItem="Manage Batch" />
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col lg={3}>
                                            <Link to="/add-update-batch">
                                                <Button
                                                    color="primary"
                                                    className="font-size-13 btn-block"
                                                >
                                                    <i className="mdi mdi-plus-circle-outline me-1 align-middle font-size-16" />Create
                                                </Button>
                                            </Link>

                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    {/* Datatable view start */}

                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col lg={10}>
                                            <h3>All Batch List</h3><br />
                                        </Col>
                                        <Col lg={2}>
                                            <Input
                                                type="select"
                                                id="formrow-serviceType"
                                                className="form-control"
                                                onChange={(e) => { setBatchStatus(e.target.value) }}
                                            >
                                                <option value="">Status</option>
                                                <option value="1" selected>Upcoming</option>
                                                <option value="2">Completed</option>
                                            </Input>
                                        </Col>
                                    </Row>
                                    {processingList ?
                                        <Col lg={12} style={{ textAlign: "center" }}>
                                            <h5><i className="bx bx-hourglass bx-spin font-size-24 align-middle me-2"></i>
                                                Loading...</h5>
                                        </Col>
                                        :
                                        <Row>
                                            {allBatchList.length > 0 ?
                                                <>
                                                    <div className="search-box me-2 mb-2 d-inline-block">
                                                        <div className="position-relative">
                                                            <Label for="search-bar-0" className="search-label" style={{ float: "left" }}>
                                                                <span id="search-bar-0-label" className="sr-only">Search this table</span>
                                                                <Input id="search-bar-0"
                                                                    type="text"
                                                                    aria-labelledby="search-bar-0-label"
                                                                    className="form-control"
                                                                    placeholder="Search"
                                                                    // value=""
                                                                    onChange={(e) => handleSearch(e)}
                                                                />
                                                            </Label>
                                                            <i className="bx bx-search-alt search-icon"></i>
                                                        </div>
                                                    </div>
                                                    <Row>
                                                        <Col lg={12}>
                                                            <div className="table-responsive">
                                                                <Table className="table mb-0">
                                                                    <thead>
                                                                        <tr className="whitespace-no-wrap">
                                                                            <th>BATCH ID</th>
                                                                            <th>ACTIVITY NAME</th>
                                                                            <th>START DATE</th>
                                                                            <th>END DATE</th>
                                                                            <th>ASSIGNED MEMBERS</th>
                                                                            <th>BATCH SCHEDULE</th>
                                                                            <th>STATUS</th>
                                                                            <th>ACTION</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {allBatchList.map((item, index) => {
                                                                            return (
                                                                                <tr key={index}>
                                                                                    <th scope="row" className="cursor-pointer" onClick={(e) => {
                                                                                        setIsOpenBatchModal(true)
                                                                                        let data = {
                                                                                            batch_id: item?.id,
                                                                                            activity: item?.activity,
                                                                                            start_date: item?.start_date,
                                                                                            end_date: item?.end_date,
                                                                                            trainer_name: item?.trainer_name,
                                                                                            members: item?.members,
                                                                                            batch_details: item?.batch_details
                                                                                        }
                                                                                        setData(data)
                                                                                    }}><a href="#">{item.id}</a></th>
                                                                                    <td>{item.activity}</td>
                                                                                    <td>{!!item.start_date ? moment(item.start_date).format('DD-MM-YYYY') : "-"}</td>
                                                                                    <td>{!!item.end_date ? moment(item.end_date).format('DD-MM-YYYY') : "-"}</td>
                                                                                    <td className="text-center cursor-pointer"><span className="badge rounded-pill bg-success px-2 py-1" onClick={(e) => {
                                                                                        setIsOpenAssignedMemberModal(true)
                                                                                        let members = item?.members
                                                                                        setData(members)
                                                                                    }}>{item?.total_member_count}</span></td>
                                                                                    <td className="text-center cursor-pointer"><i className="bx bx-calendar" style={{ fontSize: "x-large" }} onClick={(e) => {
                                                                                        setIsOpenScheduleModal(true)
                                                                                        let batch_data = {
                                                                                            activity: item?.activity,
                                                                                            start_date: item?.start_date,
                                                                                            end_date: item?.end_date,
                                                                                            trainer_name: item?.trainer_name,
                                                                                            members: item?.members,
                                                                                            batch_details: item?.batch_details,
                                                                                            capacity: item?.capacity
                                                                                        }
                                                                                        setData(batch_data)
                                                                                    }}></i></td>
                                                                                    <td>
                                                                                        {!!item.batch_status ?
                                                                                            <>
                                                                                                {
                                                                                                    (item.batch_status == 1) ?
                                                                                                        <span className="badge rounded-pill bg-warning px-2 py-1">Upcoming</span>
                                                                                                        :
                                                                                                        (item.batch_status == 2) ?
                                                                                                            <span className="badge rounded-pill bg-secondary px-2 py-1">Completed</span>
                                                                                                            :
                                                                                                            <span className="badge rounded-pill bg-success px-2 py-1">Ongoing</span>
                                                                                                }
                                                                                            </>
                                                                                            :
                                                                                            ""
                                                                                        }
                                                                                    </td>
                                                                                    <td className="whitespace-no-wrap">{handleAction(null, item)}</td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                        }
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </Col>
                                                        <div className="mt-2">
                                                            <div style={{ float: "left" }}>
                                                                <Input
                                                                    type="select"
                                                                    id="formrow-serviceType"
                                                                    className="form-control"
                                                                    name={'offset'}
                                                                    onChange={(e) => handleOffsetChange(e)}
                                                                    value={offSet}
                                                                >
                                                                    <option value="10">10</option>
                                                                    <option value="5">5</option>
                                                                    <option value="10">10</option>
                                                                    <option value="15">15</option>
                                                                </Input>
                                                            </div>
                                                            <div style={{ justifyContent: "end", display: "flex" }}>
                                                                <ul className="pagination react-bootstrap-table-page-btns-ul">
                                                                    {pageLinks.map((btn, index) => {
                                                                        return (
                                                                            <React.Fragment key={index}>
                                                                                {!!btn.url &&
                                                                                    <li className={!!btn.active ? "active page-item" : "page-item"}>
                                                                                        <a className="page-link" href="#" onClick={(e) => getPageNumber(e, btn)}>
                                                                                            {btn.label.includes("Next") ? ">" : btn.label.includes("Previous") ? "<" : btn.label}
                                                                                        </a>
                                                                                    </li>
                                                                                }
                                                                            </React.Fragment>
                                                                        )
                                                                    })
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </Row>
                                                </>
                                                :
                                                <Col lg={12} style={{ textAlign: "center" }}>
                                                    <h5>No Data Found</h5>
                                                </Col>
                                            }
                                        </Row>
                                    }

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    {/* Datatable view end */}
                </Container>
            </div>

            <BatchModal
                isOpenBatchModal={isOpenBatchModal}
                data={data}
            />

            <ScheduleModal
                isOpenScheduleModal={isOpenScheduleModal}
                data={data}
            />

            <AssignedMemberModal
                isOpenAssignedMemberModal={isOpenAssignedMemberModal}
                data={data}
            />
        </React.Fragment>
    );

}

export default withRouter(ManageBatch);

ManageBatch.propTypes = {
    history: PropTypes.object,
};