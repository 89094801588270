import React, { useEffect, useState } from 'react'
import { withRouter, useLocation, Link, useParams } from 'react-router-dom'
import PropTypes, { any } from "prop-types";
import MetaTags from "react-meta-tags";
import {
    Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label, Button, Modal,
    ModalBody,
    ModalHeader,
    InputGroup,
    Spinner,
    CardTitle,
    Collapse
} from "reactstrap";
import { apiUrls, axiosInstance, graphql } from 'api';
import usePaginator from "hooks/usePaginator";
import * as DietQry from "GraphQLquery/dietQry";

const DietView = props => {
    const [dietPlanDetails, setDietPlanDetails] = useState(null)
    const [error, setError] = useState(null);
    const [isPageLoading, setPageLoading] = useState(true);
    const [paginatorInfo, setPaginatorInfo, resetPaginatorInfo] = usePaginator();
    const [searchText, setSearchText] = useState('');

    const memberWise = JSON.parse(localStorage.getItem("memberWise"))
    const member_id = localStorage.getItem("d_member_id")
    const id = localStorage.getItem("d_id")

    useEffect(async () => {
        setPageLoading(true);
        let res
        if (memberWise) {
            if (id) {
                res = await graphql({
                    query: DietQry.FETCH_MEMBER_WISE_ASSIGNED_DIET_PLAN_VIEW_QUERY,
                    variables: {
                        ...paginatorInfo,
                        // searchText: `%${searchText}%`,
                        id: id
                    }
                })
            }
            else {
                res = await graphql({
                    query: DietQry.FETCH_MEMBER_WISE_ASSIGNED_DIET_PLAN_QUERY,
                    variables: {
                        ...paginatorInfo,
                        searchText: `%${searchText}%`,
                        member_id: !!member_id ? member_id : '%%'
                    }
                })
            }

        } else {
            res = await graphql({
                query: DietQry.FETCH_ALL_DIET_PLAN_QUERY,
                variables: {
                    ...paginatorInfo,
                    searchText: `%${searchText}%`,
                    id: id
                }
            })
        }

        try {
            if (res.data?.diet_plans?.data || res.data?.member_wise_diet_plans?.data) {
                let data = memberWise == false ? res?.data?.diet_plans?.data[0] : res?.data?.member_wise_diet_plans?.data[0]
                setDietPlanDetails(data)
                setPageLoading(false)
            }
            else {
                setDietPlanDetails([])
                swal("Oops!", "Something went wrong!", "error");
                setPageLoading(false)
            }
        } catch (err) {
            setDietPlanDetails([])
            swal("Oops!", "Something went wrong!", "error");
            setError(err.message)
            setPageLoading(false)
        }
    }, [])

    const printWorkout = () => {
        window.print();
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Diet View</title>
                </MetaTags>
                {isPageLoading ?
                    <Row>
                        <Col lg={12} style={{ textAlign: "center" }}>
                            <h5><i className="bx bx-hourglass bx-spin font-size-24 align-middle me-2"></i>
                                Loading...</h5>
                        </Col>
                    </Row>
                    :
                    <Container fluid={true} style={{ marginTop: "-60px" }}>
                        <div className="d-print-none">
                            <div className="d-flex justify-content-end">
                                <Link
                                    to="#"
                                    onClick={printWorkout}
                                    className="btn btn-success  me-2"
                                >
                                    <i className="fa fa-print" />
                                </Link>
                            </div>
                        </div>
                        <div id="printSection">
                            <div className="main-content-wrapper">
                                <div className="main-contents pb-1">
                                    <div className="m-4">
                                        <header className="header-home">
                                            <div>
                                                <p>Diet Plan
                                                    <br />
                                                    {memberWise &&
                                                        <React.Fragment>
                                                            <span className="member-name">
                                                                {dietPlanDetails?.member_details?.member_name}
                                                            </span>&nbsp;&nbsp;
                                                            <span className="badge rounded-pill" style={{ backgroundColor: "#DC2450", fontSize: "18px" }}>ID:{dietPlanDetails?.member_id}</span>
                                                        </React.Fragment>
                                                    }
                                                </p>
                                                <a href="#" className="logo"><img
                                                    src="https://www.flexym.com/assets/images/Flexym-logo.svg" className="inline-block"
                                                    alt="Flexym Logo" />
                                                </a>
                                            </div>
                                        </header>

                                        {!!dietPlanDetails &&
                                            <React.Fragment>
                                                <section className="content-heading">
                                                    <h4>{dietPlanDetails?.plan_name}</h4>
                                                    <p>Dietitian-&nbsp;{dietPlanDetails?.dietitian_name}</p>
                                                    <p>{dietPlanDetails?.note}</p>
                                                </section>

                                                <section className="content-details">

                                                    {!!dietPlanDetails?.issues &&
                                                        <div className='nutrients-wrapper'>
                                                            <div className='nutrients-title'>
                                                                <h5 className='m-0'>Issues</h5>
                                                            </div>
                                                            <div className='nutrients-details'>
                                                                <p>{dietPlanDetails?.issues}</p>
                                                            </div>
                                                        </div>
                                                    }

                                                    {!!dietPlanDetails?.diet_goal &&
                                                        <div className='nutrients-wrapper'>
                                                            <div className='nutrients-title'>
                                                                <h5 className='m-0'>Diet Goal</h5>
                                                            </div>
                                                            <div className='nutrients-details'>
                                                                <p>{dietPlanDetails?.diet_goal}</p>
                                                            </div>
                                                        </div>
                                                    }

                                                    {!!dietPlanDetails?.daily_ration_nutrients &&
                                                        <div className='nutrients-wrapper'>
                                                            <div className='nutrients-title'>
                                                                <h5 className='m-0'>Daily Ration (Nutrients)</h5>
                                                            </div>
                                                            <div className='nutrients-details'>
                                                                <p dangerouslySetInnerHTML={{ __html: dietPlanDetails?.daily_ration_nutrients }}></p>
                                                            </div>
                                                        </div>
                                                    }
                                                    {!!dietPlanDetails?.daily_ration_foods &&
                                                        <div className='nutrients-wrapper'>
                                                            <div className='nutrients-title'>
                                                                <h5 className='m-0'>Daily Ration (Foods)</h5>
                                                            </div>
                                                            <div className='nutrients-details'>
                                                                <p dangerouslySetInnerHTML={{ __html: dietPlanDetails?.daily_ration_foods }}></p>
                                                            </div>
                                                        </div>
                                                    }

                                                    {!!dietPlanDetails?.pulpy_seasonal_foods &&
                                                        <div className='nutrients-wrapper'>
                                                            <div className='nutrients-title'>
                                                                <h5 className='m-0'>Pulpy Seasonal Foods</h5>
                                                            </div>
                                                            <div className='nutrients-details'>
                                                                <p>{dietPlanDetails?.pulpy_seasonal_foods}</p>
                                                            </div>
                                                        </div>
                                                    }

                                                    {!!dietPlanDetails?.citrus_fruits &&
                                                        <div className='nutrients-wrapper'>
                                                            <div className='nutrients-title'>
                                                                <h5 className='m-0'>Citrus Fruits</h5>
                                                            </div>
                                                            <div className='nutrients-details'>
                                                                <p>{dietPlanDetails?.citrus_fruits}</p>
                                                            </div>
                                                        </div>
                                                    }

                                                    {!!dietPlanDetails?.foods_to_avoid &&
                                                        <div className='nutrients-wrapper'>
                                                            <div className='nutrients-title'>
                                                                <h5 className='m-0'>Foods To Avoid</h5>
                                                            </div>
                                                            <div className='nutrients-details'>
                                                                <p>{dietPlanDetails?.foods_to_avoid}</p>
                                                            </div>
                                                        </div>
                                                    }

                                                    <h2 className="pt-4">
                                                        <b>
                                                            {/* Category-{dietPlanDetails?.diet_plan_category_details?.category_name} */}
                                                            Distribution
                                                        </b>
                                                    </h2>

                                                    {dietPlanDetails?.diet_plan_details?.map((item, index) => {
                                                        return (
                                                            <div className="plan diet-plan" key={index}>
                                                                <div className="plan-details" key={index}>
                                                                    <p>{item?.time}</p>
                                                                    <p>{item?.meal}</p>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </section>
                                            </React.Fragment>

                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Container>
                }
            </div>
        </React.Fragment>
    )
}

export default withRouter(DietView);

DietView.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
};