import { MEMBERSHIP_UPDATED } from "./actionTypes";

const initialState = {
    membershipUpdateStatus: false
}

const MembershipUpdated = (state = initialState, action) => {
    switch (action.type) {
        case MEMBERSHIP_UPDATED:
            state = { membershipUpdateStatus: action.membershipUpdateStatus }
            break;
        default:
            state = { membershipUpdateStatus: null };
            break;
    }
    return state;
}

export default MembershipUpdated