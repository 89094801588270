import { generateSearchQuery } from "utils/graphql"

const searchQry = generateSearchQuery(['name', 'email', 'address','status','primary_contact_no','zip_code'])

// FETCH ALL USER FOR LISTING, FILTERED BY PROFILE_ID
export const FETCH_USERS_LIST_BY_PROFILE_ID_QUERY = `query userListVendor($perPage: Int!, $currentPage: Int, $profile_id: Mixed,$searchText:Mixed) 
{
    vendors(first:$perPage,page:$currentPage,
    where:{ AND:{column: _pid, operator:EQ value: $profile_id} 
                 ${searchQry}
  }
    orderBy:{column:_name,order:DESC}
  )
  {
    data{
      id
      profile_id
      name
      email
      address
      status
      salutation
      primary_contact_no
      secondary_contact_no
      zip_code
  }
  paginatorInfo {
    currentPage
    total
    perPage
  }
  }
}
`

// FETCH USERDATA FOR VIEW/EDIT

export const FETCH_USERDATA_FOR_VIEW_QUERY = `query userList($userId: Mixed) 
{
    vendors (where:{column:_id operator:EQ value:$userId}){
      data
      {
        id
        profile_id
        salutation
        name
        email
        address
        city
        state
        zip_code
        primary_contact_no
        secondary_contact_no
        bank_ac_no
        ac_holder_name
        ifsc_code
        bank_name
        gst_no
        secondary_contact_person_name
        primary_contact_person_name
      }
    }
  }
`