import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useEffect, useState } from "react";
import {
    Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label, Button, Modal,
    ModalBody,
    ModalHeader,
    InputGroup
} from "reactstrap";
import { withRouter, Link } from "react-router-dom";

// Formik formikObj
import * as Yup from "yup";
import { useFormik } from "formik";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { today, standartDateFormat } from "utils/date";
import { axiosInstance, apiUrls } from "api";
import { authStorage } from "utils/auth";
import swal from 'sweetalert';
import profile_ids from "utils/profile_ids";
import { graphql } from "api";
import CommonTable from "components/Common/CommonTable";
import usePaginator from "hooks/usePaginator";
import * as TrainerQry from "GraphQLquery/trainerQry";
import EditTrainer from "../Common/EditTrainer";
import { useSelector, useDispatch } from "react-redux";
import Flatpickr from "react-flatpickr";
import images from "assets/images";
import moment from "moment";



const initialTrainerData = {
    name: "",
    nick_name: "",
    //trainer_age: "",
    //profession: "",
    gender: "",
    // (1-Male /2-Female/3-transgender)
    address: "",
    primary_contact_no: "",
    secondary_contact_no: "",
    email_id: "",
    status: 1,
    //trainer_type_id: "",
    date_of_birth: "",
    joining_date: "",
    trainer_type_details: [],
    experience: "",
    position: "",
    profile_pic: "",
    //type: "",
}

let imageSrc = '';

const baseURL = process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_TRAINER_IMAGE;

const AddTrainer = props => {
    const trainerUpdated = useSelector(state => state.TrainerUpdated.trainerUpdateStatus);
    const [trainerData, setTrainerData] = useState({ ...initialTrainerData })
    const [trainerId, setTrainerId] = useState(null)
    const [isSaving, setIsSaving] = useState(false);
    const [addEditTrainerModal, setAddEditTrainerModal] = useState(false);
    const [error, setError] = useState(null);
    const [processingList, setProcessingList] = useState(false);
    const [allTrainerList, setAllTrainerList] = useState([]);
    // const [categoryList, setCategoryList] = useState([]);
    // const [serviceTypes, setServiceTypes] = useState([]);
    const [invalidInputGender, setInvalidInputGender] = useState(true);
    const [invalidInputTrainer, setInvalidInputTrainer] = useState(true);
    const [paginatorInfo, setPaginatorInfo, resetPaginatorInfo] = usePaginator();
    const [searchText, setSearchText] = useState('');
    const [editTrainerModal, setEditTrainerModal] = useState(false)
    const [trainerType, setTrainerType] = useState([]);
    const [dateOfBirthDate, setDateOfBirth] = useState('');
    const [joiningDate, setJoiningDate] = useState('');
    const [allTrainerType, setAllTrainerTypeList] = useState([]);
    const [activityList, setActivityList] = useState([])


    useEffect(() => {
        fetchActivities();
        fetchTrainerType();
        fetchTrainerListData(paginatorInfo, searchText, status)
        setEditTrainerModal(false)
    }, [trainerUpdated])

    const fetchTrainerListData = async (paginatorInfo, searchText, status) => {
        try {
            setProcessingList(true)
            const res = await graphql({
                query: TrainerQry.FETCH_ALL_TRAINER_QUERY,
                variables: {
                    ...paginatorInfo,
                    searchText: `%${searchText}%`,
                    id: "%%"
                }
            });

            if (res.data.trainers.data) {
                setProcessingList(false)
                setAllTrainerList(!!res.data.trainers ? res.data.trainers.data : [])
                setPaginatorInfo(res?.data?.trainers?.paginatorInfo);
                setSearchText(searchText);
            }

            else {
                setProcessingList(false)
                setAllTrainerList([])
                resetPaginatorInfo();
            }
        } catch (error) {
            setProcessingList(false)
            setAllTrainerList([])
            resetPaginatorInfo();
        }
    }

    const handleChangeFile = (e) => {
        imageSrc = URL.createObjectURL(e.target.files[0])
        if (e.target.files[0])
            formikObj.setFieldValue('profile_pic', e.target.files[0]);
    }

    const dateOfBirthChange = (dateOfBirthDate) => {
        if (!!dateOfBirthDate[0]) {
            setDateOfBirth(dateOfBirthDate[0].getDate() + '-' + (dateOfBirthDate[0].getMonth() + 1) + '-' + dateOfBirthDate[0].getFullYear())
            formikObj.setFieldValue("date_of_birth", standartDateFormat(dateOfBirthDate[0]))
        } else {
            setDateOfBirth('')
            formikObj.setFieldValue("date_of_birth", '')
        }
    }

    const joiningDateChange = (joiningDate) => {
        if (!!joiningDate[0]) {
            setJoiningDate(joiningDate[0].getDate() + '-' + (joiningDate[0].getMonth() + 1) + '-' + joiningDate[0].getFullYear())
            formikObj.setFieldValue("joining_date", standartDateFormat(joiningDate[0]))
        } else {
            setJoiningDate('')
            formikObj.setFieldValue("joining_date", '')
        }
    }

    const filterTrainerByStatus = (e, status) => {
        fetchTrainerListData(paginatorInfo, searchText, status)
    }

    const handleTableChange = (paginatorInfo, searchText) => {
        fetchTrainerListData(paginatorInfo, searchText, status)
    }

    const fetchTrainerType = async () => {
        try {
            const res = await graphql({
                query: TrainerQry.FETCH_TRAINER_TYPE_QUERY,
            });

            if (res.data.trainer_types) {
                setProcessingList(false)
                setAllTrainerTypeList(!!res.data.trainer_types ? res.data.trainer_types : [])
            }
            else {
                setProcessingList(false)
                setAllTrainerTypeList([])
            }
        }
        catch (err) {
            setProcessingList(false)
            setAllTrainerTypeList([]);
        }
        // try {
        //     let response = null;
        //     response = await graphql({
        //         query: TrainerQry.FETCH_TRAINER_TYPE_QUERY,
        //         variables: {}
        //     });
        //     let trainerType = []
        //     trainerType = response?.data?.trainer_type || [];
        //     setTrainerType(trainerType);
        // } catch (err) {
        //     setTrainerType([]);
        // }
    }

    const fetchActivities = async () => {
        try {
            const res = await graphql({
                query: TrainerQry.ACTIVITIES_LIST_QUERY
            });
            if (res.data.activities) {
                let activities = res?.data?.activities || []
                setActivityList(activities)
            } else {
                setActivityList([])
            }
        } catch (error) {
            setActivityList([])
        }
    }


    const handleAction = (cell, row) => {
        return (
            <>
                <div className="d-flex">
                    <Button className="btn-info btn-sm ms-2" onClick={(e) => { handleFetch(e, row) }} title="Edit">
                        <i className="bx bx-pencil align-middle" />
                    </Button>
                    <Button className="btn-danger btn-sm ms-2" onClick={(e) => { handleDelete(e, row) }}>
                        <i className="bx bx-trash align-middle" />
                    </Button>
                    {/* &nbsp;
                    <Button className="btn btn-info  btn btn-info btn-sm" onClick={(e) => { handleChangeStatus(e, row) }}>
                        {row.status == 0 ? "Active" : "Inactive"}
                    </Button> */}
                </div>
            </>
        );
    };

    // Column definition for datatable


    const columns = [
        // {
        //     formatter: (cell, row) => <Link to={{ pathname: "/member-details", state: { member_id: row.id, tabType: "1" } }}>{row.id}</Link>,
        //     dataField: 'id',
        //     text: 'ID',
        //     sort: true,
        // },
        {
            formatter: (cell, row) => <><img src={!!row?.profile_pic ? baseURL + row?.profile_pic : images.defaultAvatar} height={100} width={100} alt="profile" className="avatar-sm rounded-circle img-thumbnail" /></>,
            text: 'Profile Pic',
            sort: true,
        },
        {
            dataField: 'nick_name',
            text: 'Nick Name',
            sort: true,
        },
        {
            dataField: 'name',
            text: 'NAME',
            sort: true,
        },
        {
            formatter: (cell, row) => row.trainer_type_details.map((item, index) => (
                <div key={index} style={{ whiteSpace: "normal" }}>
                    <div className="bg-primary bg-soft p-2 my-1 text-primary badge">
                        {item?.trainer_types?.trainer_type}
                    </div>
                </div>
            )),
            text: "TRAINER TYPE",
            sort: true
        },
        {
            formatter: (cell, row) => !!row?.date_of_birth ? moment(row?.date_of_birth).format("DD-MM-YYYY") : '',
            text: 'DOB',
            sort: true,
        },
        {
            formatter: (cell, row) => ((row.gender == 1 && <span>Male</span>) || (row.gender == 2 && <span >Female</span>) || (row.gender == 3 && <span >Transgender</span>)),
            text: 'GENDER',
            sort: true,
        },
        {
            dataField: 'primary_contact_no',
            text: 'PRIMARY MOBILE NO.',
            sort: true,
        },
        {
            dataField: 'experience',
            text: 'Experience',
            sort: true,
        },
        {
            formatter: (cell, row) => !!row?.joining_date ? moment(row?.joining_date).format("DD-MM-YYYY") : '',
            //dataField: 'joining_date',
            text: 'Joining Date',
            sort: true,
        },
        {
            dataField: 'position',
            text: 'Position',
            sort: true,
        },
        {
            dataField: '',
            text: 'ACTION',
            sort: true,
            formatter: handleAction
        }];

    const handleFetch = async (e, row) => {
        setTrainerId(row.id)
        setEditTrainerModal(true)
    }

    const handleDelete = (e, row) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (willDelete) => {
                if (willDelete) {
                    try {
                        const response = await axiosInstance.post(apiUrls.trainer.deleteTrainer, { id: row.id });
                        if (response.success == 1) {
                            swal("Success!", response.message, "success");
                            fetchTrainerListData(paginatorInfo, searchText, status)
                        }
                        else {
                            swal("Oops!", response.message, "error");
                        }
                    } catch (err) {
                        swal("Oops!", "Something went wrong!", "error");
                        setError(err.message)
                    }
                }
            });
    }

    const handleContact = (e) => {
        if (/^[0-9]+$/g.test(e.target.value) || e.target.value == '') {
            formikObj.setFieldValue(e.target.name, e.target.value)
        }
    }
    const handleGenderChange = (e) => {
        formikObj.setFieldValue(e.target.name, e.target.value)
    }

    const initAutocomplete = () => {

        let autocomplete = new window.google.maps.places.Autocomplete(
            /** @type {!HTMLInputElement} */(document.getElementById("address")),
            { types: [] });

        window.google.maps.event.addListener(autocomplete, 'place_changed', () => {
            let place = autocomplete.getPlace();
            formikObj.setFieldValue("address", place.formatted_address)
            var componentForm = {
                country: 'long_name',
                locality: 'long_name',
                administrative_area_level_1: 'long_name',
                postal_code: 'short_name'
            };
            for (var i = 0; i < place.address_components.length; i++) {
                var addressType = place.address_components[i].types[0];
                if (componentForm[addressType]) {
                    var val = place.address_components[i][componentForm[addressType]];
                    if (addressType == "administrative_area_level_1") {
                        formikObj.setFieldValue("state", val)
                    }
                    if (addressType == "locality") {
                        formikObj.setFieldValue("city", val)
                    }
                    if (addressType == "postal_code") {
                        formikObj.setFieldValue("zip_code", val)
                    }
                    else {
                        formikObj.setFieldValue("zip_code", '')
                    }
                }
            }
        })
    }

    const formikObj = useFormik({
        enableReinitialize: true,

        initialValues: { ...trainerData },
        validationSchema: Yup.object({
            nick_name: Yup.string().required("Please Enter Trainer Nick Name"),
            name: Yup.string().required("Please Enter Trainer Name"),
            trainer_type_details: Yup.array().required("Please Select Trainer"),
            primary_contact_no: Yup.number().required("Please Enter Contact No."),
            email_id: Yup.string().required("Please Enter Email Id"),
        }),
        onSubmit: (values) => {
            if (values.gender == '') {
                setInvalidInputGender(false)
            }
            else if (values.trainer_type_details.length == 0) {
                setInvalidInputTrainer(false)
            }
            else {
                setInvalidInputGender(true)
                setInvalidInputTrainer(true)
                addTrainerData(values)
            }
        }
    });

    const addTrainerData = async (values) => {
        try {
            setIsSaving(true)
            const formData = new FormData();
            for (let [key, value] of Object.entries(values)) {
                // if (!!value) {
                //     if (key == "trainer_type_details") {
                //         formData.append(key, "[" + value + "]")
                //     }
                //     else {
                //         formData.append(key, value)
                //     }
                // }
                // else {
                //     formData.append(key, "")
                // }
                !!value ? formData.append(key, value) : formData.append(key, "")
            }
            const response = await axiosInstance.post(apiUrls.trainer.addAndUpdateTrainer, formData);
            if (response.success == 1) {
                formikObj.resetForm()
                setIsSaving(false)
                closeModal()
                swal("Success!", response.message, "success");
                fetchTrainerListData(paginatorInfo, searchText, status)
                setTrainerData([])
            }
            else {
                setIsSaving(false)
                swal("Oops!", response.message, "error");
            }
        } catch (err) {
            setIsSaving(false)
            swal("Oops!", "Something went wrong!", "error");
            setError(err.message)
        }
    }

    const openModal = () => {
        imageSrc = images.defaultAvatar;
        setTimeout(() => {
            setAddEditTrainerModal(true);
            initAutocomplete()
        }, 2000);
    }

    const closeModal = () => {
        imageSrc = ''
        setTrainerData({ ...initialTrainerData });
        formikObj.resetForm();
        formikObj.setFieldValue("date_of_birth", "")
        formikObj.setFieldValue("joining_date", "")
        setDateOfBirth('')
        setJoiningDate('')
        setAddEditTrainerModal(false);
    }

    const createTrainer = () => {
        imageSrc = ''
        setTrainerId(null)
        setTrainerData({ ...initialTrainerData });
        formikObj.resetForm();
        openModal()
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Trainer</title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumbs title="Trainer" breadcrumbItem="Trainer" />
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col lg={3}>
                                            <Button
                                                color="primary"
                                                className="font-size-13 btn-block"
                                                onClick={createTrainer}
                                            >
                                                <i className="mdi mdi-plus-circle-outline me-1 align-middle font-size-16" />
                                                Create
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    {/* Datatable view start */}

                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col lg={4}>
                                            <h3>All Trainer List</h3><br />
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                            <CommonTable
                                                paginatorInfo={paginatorInfo}
                                                searchText={searchText}
                                                onTableChange={handleTableChange}
                                                keyField='id'
                                                columns={columns}
                                                data={allTrainerList}
                                                loading={processingList}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    {/* Datatable view end */}

                    {editTrainerModal && <EditTrainer trainer_id={trainerId} />}

                    <Modal
                        isOpen={addEditTrainerModal}
                        toggle={closeModal}
                        size="lg"
                        backdrop="static"
                    // className={props.className}
                    >
                        <ModalHeader toggle={closeModal} tag="h4">
                            Create New Trainer
                        </ModalHeader>
                        <ModalBody>
                            <Form>
                                <Row>
                                    <Col lg={12}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-InputService">Trainer Nick Name <span className="text-danger">*</span></Label>
                                            <Input
                                                type="text"
                                                id="formrow-InputService"
                                                className="form-control"
                                                name="nick_name"
                                                onChange={formikObj.handleChange}
                                                value={formikObj.values.nick_name}
                                                placeholder="Enter Trainer Nick Name"
                                                invalid={
                                                    formikObj.touched.nick_name && formikObj.errors.nick_name ? true : false
                                                }
                                            />
                                            {formikObj.touched.nick_name && formikObj.errors.nick_name ? (
                                                <FormFeedback type="invalid">{formikObj.errors.nick_name}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-InputService">Trainer Full Name <span className="text-danger">*</span></Label>
                                            <Input
                                                type="text"
                                                id="formrow-InputService"
                                                className="form-control"
                                                name="name"
                                                onChange={formikObj.handleChange}
                                                value={formikObj.values.name}
                                                placeholder="Enter Trainer Full Name"
                                                invalid={
                                                    formikObj.touched.name && formikObj.errors.name ? true : false
                                                }
                                            />
                                            {formikObj.touched.name && formikObj.errors.name ? (
                                                <FormFeedback type="invalid">{formikObj.errors.name}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-InputService">Primary Contact No. <span className="text-danger">*</span></Label>
                                            <Input
                                                type="text"
                                                id="formrow-InputService"
                                                className="form-control"
                                                name="primary_contact_no"
                                                onChange={(e) => { handleContact(e) }}
                                                value={formikObj.values.primary_contact_no}
                                                placeholder="Enter Primary Contact No."
                                                maxLength={10}
                                                invalid={
                                                    formikObj.touched.primary_contact_no && formikObj.errors.primary_contact_no ? true : false
                                                }
                                            />
                                            {formikObj.touched.primary_contact_no && formikObj.errors.primary_contact_no ? (
                                                <FormFeedback type="invalid">{formikObj.errors.primary_contact_no}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-InputService">Secondary Contact No.</Label>
                                            <Input
                                                type="text"
                                                id="formrow-InputService"
                                                className="form-control"
                                                name="secondary_contact_no"
                                                onChange={(e) => { handleContact(e) }}
                                                value={formikObj.values.secondary_contact_no}
                                                placeholder="Enter Secondary Contact No."
                                                maxLength={10}
                                                invalid={
                                                    formikObj.touched.secondary_contact_no && formikObj.errors.secondary_contact_no ? true : false
                                                }
                                            />
                                            {formikObj.touched.secondary_contact_no && formikObj.errors.secondary_contact_no ? (
                                                <FormFeedback type="invalid">{formikObj.errors.secondary_contact_no}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-InputService">Email Address <span className="text-danger">*</span></Label>
                                            <Input
                                                type="email"
                                                id="formrow-InputService"
                                                className="form-control"
                                                name="email_id"
                                                onChange={formikObj.handleChange}
                                                value={formikObj.values.email_id}
                                                placeholder="Enter Email Address"
                                                invalid={
                                                    formikObj.touched.email_id && formikObj.errors.email_id ? true : false
                                                }
                                            />
                                            {formikObj.touched.email_id && formikObj.errors.email_id ? (
                                                <FormFeedback type="invalid">{formikObj.errors.email_id}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-InputService">Select Gender <span className="text-danger">*</span></Label>
                                            <div>
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="gender"
                                                        id="exampleRadios2"
                                                        value="1"
                                                        onClick={(e) => { handleGenderChange(e) }}
                                                        checked={formikObj.values.gender == 1}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="exampleRadios2"
                                                    >
                                                        Male
                                                    </label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="gender"
                                                        id="exampleRadios2"
                                                        value="2"
                                                        onClick={(e) => { handleGenderChange(e) }}
                                                        checked={formikObj.values.gender == 2}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="exampleRadios2"
                                                    >
                                                        Female
                                                    </label>
                                                </div>
                                            </div>
                                            {invalidInputGender == false && <div className="custom-invalid">Please Select Gender</div>}
                                        </div>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-InputService">Joining Date</Label>
                                            <Flatpickr
                                                className="form-control d-block"
                                                name="joining_date"
                                                placeholder="DD-MM-YYYY"
                                                options={{
                                                    altInput: true,
                                                    altFormat: "d-m-Y",
                                                    dateFormat: "d-m-Y",
                                                    //minDate: today
                                                }}
                                                onChange={joiningDateChange}
                                                value={joiningDate}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-serviceType">Date Of Birth</Label>
                                            <Flatpickr
                                                className="form-control d-block"
                                                name="date_of_birth"
                                                placeholder="DD-MM-YYYY"
                                                options={{
                                                    altInput: true,
                                                    altFormat: "d-m-Y",
                                                    dateFormat: "d-m-Y",
                                                    //minDate: today
                                                }}
                                                onChange={dateOfBirthChange}
                                                value={dateOfBirthDate}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-InputAddress">Address</Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                id="address"
                                                placeholder="Enter Address"
                                                name="address"

                                                // -------- for autocomplete --------
                                                onChange={formikObj.handleChange}
                                                value={formikObj.values.address}
                                                autoComplete="address"
                                                invalid={
                                                    formikObj.touched.address && formikObj.errors.address ? true : false
                                                }
                                            />
                                            {formikObj.touched.address && formikObj.errors.address ? (
                                                <FormFeedback type="invalid">{formikObj.errors.address}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-InputAddress">Profile Image</Label>
                                            <Input
                                                type="file"
                                                className="form-control"
                                                name="profile_pic"
                                                onChange={(e) => handleChangeFile(e)}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <span className="logo-lg">
                                                <img src={imageSrc} height={100} width={100} alt="profile" className="avatar-sm rounded-circle img-thumbnail" />
                                                <span className="extra-space"></span>
                                            </span>
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col lg={12}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-InputService">Trainer Type <span className="text-danger">*</span></Label>
                                            <div>
                                                {allTrainerType.map((item, index) => (
                                                    <div className="form-check mb-3 form-check-inline" key={index}>
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="defaultCheck1"
                                                            name="trainer_type_details"
                                                            // onClick={(e) => { handleFloorChange(e) }}
                                                            onChange={formikObj.handleChange}
                                                            value={item.id}
                                                            defaultChecked={formikObj.values?.trainer_type_details?.indexOf(item.id) !== -1}
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="defaultCheck1"
                                                        >
                                                            {item.trainer_type}
                                                        </label>
                                                    </div>
                                                ))
                                                }
                                            </div>
                                            {invalidInputTrainer == false && <div className="custom-invalid">Please Select Trainer(s)</div>}
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-InputService">Experience</Label>
                                            <Input
                                                type="text"
                                                id="formrow-InputService"
                                                className="form-control"
                                                name="experience"
                                                onChange={formikObj.handleChange}
                                                value={formikObj.values.experience}
                                                placeholder="Enter Your Experience"
                                                invalid={
                                                    formikObj.touched.experience && formikObj.errors.experience ? true : false
                                                }
                                            />
                                            {formikObj.touched.experience && formikObj.errors.experience ? (
                                                <FormFeedback type="invalid">{formikObj.errors.experience}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="formrow-InputService">Position</Label>
                                            <Input
                                                type="text"
                                                id="formrow-InputService"
                                                className="form-control"
                                                name="position"
                                                onChange={formikObj.handleChange}
                                                value={formikObj.values.position}
                                                placeholder="Enter Position"
                                                invalid={
                                                    formikObj.touched.position && formikObj.errors.position ? true : false
                                                }
                                            />
                                            {formikObj.touched.position && formikObj.errors.position ? (
                                                <FormFeedback type="invalid">{formikObj.errors.position}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </ModalBody>
                        <div className="modal-footer">
                            {!!isSaving ?
                                <button className="btn btn-primary " disabled>
                                    Processing
                                </button>
                                :
                                <>
                                    <button
                                        type="button"
                                        onClick={closeModal}
                                        className="btn btn-secondary "
                                        data-dismiss="modal"
                                    >
                                        Close
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn btn-primary "
                                        onClick={(e) => {
                                            e.preventDefault();
                                            formikObj.handleSubmit();
                                            return false;
                                        }}
                                    >
                                        Save
                                    </button>
                                </>
                            }
                        </div>
                    </Modal>

                </Container>
            </div>
        </React.Fragment>
    );

}

export default withRouter(AddTrainer);

AddTrainer.propTypes = {
    history: PropTypes.object,
};
