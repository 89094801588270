import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useEffect, useState } from "react";
import {
    Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label, Button, Modal,
    ModalBody,
    ModalHeader,
    InputGroup
} from "reactstrap";
import { withRouter, Link } from "react-router-dom";

// Formik formikObj
import * as Yup from "yup";
import { useFormik } from "formik";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { standartDateFormat } from "utils/date";
import { axiosInstance, apiUrls } from "api";
import { authStorage } from "utils/auth";
import swal from 'sweetalert';
import profile_ids from "utils/profile_ids";
import { graphql } from "api";
import CommonTable from "components/Common/CommonTable";
import usePaginator from "hooks/usePaginator";
import * as MemberQry from "GraphQLquery/memberQry";
import { useSelector, useDispatch } from "react-redux";
import { setMemberUpdated } from "../../store/actions";
import images from "assets/images";
import { FILETYPE, checkFileType } from "utils/fileType";

const initialMemberData = {
    member_name: "",
    member_age: "",
    profession: "",
    gender: "",
    // (1-Male /2-Female/3-transgender)
    address: "",
    mobile_no: "",
    email_id: "",
    status: 1,
    member_access_code: "",
    member_access_code_status: "",
    member_status: "",
    body_composition_applicable: 1,
    diet_chart_applicable: 1,
    workout_chart_applicable: 1,
    profile_image: "",
}

const accessCardStatusList = [{ id: "1", name: "Assigned" }, { id: "2", name: "Unassigned" }]
const memberStatusList = [{ id: "1", name: "Irregular" }, { id: "2", name: "Discontinued" }]
let imageSrc = ''
let idProofSrc = ''
const baseURL = process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_MEMBER_PROFILE_IMAGE
const idProofURL = process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_MEMBER_ID_PROOF;

const EditMember = props => {
    const dispatch = useDispatch();
    const [memberData, setMemberData] = useState({ ...initialMemberData })
    const [memberId, setMemberId] = useState(null)
    const [isSaving, setIsSaving] = useState(false);
    const [addEditMemberModal, setAddEditMemberModal] = useState(false);
    const [error, setError] = useState(null);
    const [hasGender, setHasGender] = useState(true);
    const [paginatorInfo, setPaginatorInfo, resetPaginatorInfo] = usePaginator();
    const [searchText, setSearchText] = useState('');
    const [isPageLoading, setPageLoading] = useState(true);
    const [toggleSwitch, settoggleSwitch] = useState(true);

    const member_id = props.member_id

    useEffect(() => {
        initAutocomplete()
        handleFetch(member_id)
    }, [])

    const handleFetch = async (member_id) => {
        setMemberId(member_id)
        const res = await graphql({
            query: MemberQry.FETCH_ALL_MEMBERS_QUERY,
            variables: {
                ...paginatorInfo,
                searchText: `%${searchText}%`,
                id: member_id,
                status_id: "%%"
            }
        });
        let responseData = !!res.data.members ? res.data.members.data[0] : []
        const fetchMemberData = {
            id: responseData.id,
            member_name: responseData.member_name,
            member_age: responseData.member_age,
            profession: responseData.profession,
            gender: responseData.gender,
            address: responseData.address,
            mobile_no: responseData.mobile_no,
            email_id: responseData.email_id,
            status: responseData.status,
            member_status: responseData.member_status,
            member_access_code: responseData.member_access_code,
            member_access_code_status: responseData.member_access_code_status,
            body_composition_applicable: responseData?.body_composition_applicable,
            diet_chart_applicable: responseData?.diet_chart_applicable,
            workout_chart_applicable: responseData?.workout_chart_applicable,
        }
        imageSrc = !!responseData.profile_image ? baseURL + responseData?.profile_image : images.defaultAvatar;
        // idProofSrc = !!responseData.id_proof ? idProofURL + responseData?.id_proof : images.defaultDocument;
        if (!!responseData.id_proof) {
            if (checkFileType(responseData.id_proof) === FILETYPE.IMAGE){
                idProofSrc = idProofURL + responseData?.id_proof;
            }
            else if (checkFileType(responseData.id_proof) === FILETYPE.PDF){
                idProofSrc = images.pdf;
            }
            else {
                idProofSrc = images.otherFile;
            }
        }
        else {
            idProofSrc = images.defaultDocument;
        }
        setMemberData(fetchMemberData)
        openModal()
    }

    const initAutocomplete = () => {

        let autocomplete = new window.google.maps.places.Autocomplete(
            /** @type {!HTMLInputElement} */(document.getElementById("address")),
            { types: [] });

        window.google.maps.event.addListener(autocomplete, 'place_changed', () => {
            let place = autocomplete.getPlace();
            formikObj.setFieldValue("address", place.formatted_address)
            var componentForm = {
                country: 'long_name',
                locality: 'long_name',
                administrative_area_level_1: 'long_name',
                postal_code: 'short_name'
            };
            for (var i = 0; i < place.address_components.length; i++) {
                var addressType = place.address_components[i].types[0];
                if (componentForm[addressType]) {
                    var val = place.address_components[i][componentForm[addressType]];
                    if (addressType == "administrative_area_level_1") {
                        formikObj.setFieldValue("state", val)
                    }
                    if (addressType == "locality") {
                        formikObj.setFieldValue("city", val)
                    }
                    if (addressType == "postal_code") {
                        formikObj.setFieldValue("zip_code", val)
                    }
                    else {
                        formikObj.setFieldValue("zip_code", '')
                    }
                }
            }
        })

    }

    const formikObj = useFormik({
        enableReinitialize: true,

        initialValues: { ...memberData },
        validationSchema: Yup.object({
            member_name: Yup.string().required("Please Enter Member Name"),
            member_age: Yup.number().required("Please Enter Age"),
            mobile_no: Yup.number().required("Please Enter Contact No."),
            email_id: Yup.string().required("Please Enter Email Id"),
        }),
        onSubmit: (values) => {
            if (values.gender == '') {
                setHasGender(false)
            }
            else {
                setHasGender(true)
                updateMemberData(values)
            }

        }
    });

    const updateMemberData = async (values) => {
        try {
            setIsSaving(true)
            const formData = new FormData();
            for (let [key, value] of Object.entries(values)) {
                !!value ? formData.append(key, value) : formData.append(key, "")
            }
            const response = await axiosInstance.post(apiUrls.member.addAndUpdateMember, formData);
            if (response.success == 1) {
                formikObj.resetForm()
                setIsSaving(false)
                closeModal()
                swal("Success!", response.message, "success");
                dispatch(setMemberUpdated(true));
                setMemberData([])
                setMemberId(null)
            }
            else {
                setIsSaving(false)
                swal("Oops!", response.message, "error");
            }
        } catch (err) {
            setIsSaving(false)
            swal("Oops!", "Something went wrong!", "error");
            setError(err.message)
        }
    }
    const handleChangeFile = (e) => {
        imageSrc = URL.createObjectURL(e.target.files[0])
        if (e.target.files[0])
            formikObj.setFieldValue('profile_image', e.target.files[0]);
        console.log(e.target.files[0]);
    }

    const handleIDProofChangeFile = (e) => {
        if (checkFileType(e.target.files[0].name) === FILETYPE.IMAGE){
            idProofSrc = URL.createObjectURL(e.target.files[0]);
        }
        else if (checkFileType(e.target.files[0].name) === FILETYPE.PDF){
            idProofSrc = images.pdf;
        }
        else {
            idProofSrc = images.otherFile;
        }
        if (e.target.files[0])
            formikObj.setFieldValue('id_proof', e.target.files[0]);
    }

    const openModal = () => {
        // imageSrc=''
        setTimeout(() => {
            setAddEditMemberModal(true);
            initAutocomplete()
        }, 2000);
    }

    const closeModal = () => {
        imageSrc = '';
        idProofSrc = '';
        setAddEditMemberModal(false);
        dispatch(setMemberUpdated(false));
    }

    const handleNumber = (e) => {
        if (/^[0-9]+$/g.test(e.target.value) || e.target.value == '') {
            formikObj.setFieldValue(e.target.name, e.target.value)
        }
    }

    const handleGenderChange = (e) => {
        formikObj.setFieldValue(e.target.name, e.target.value)
    }

    return (
        <React.Fragment>
            <Modal
                isOpen={addEditMemberModal}
                toggle={closeModal}
                size="lg"
                backdrop="static"
            // className={props.className}
            >
                <ModalHeader toggle={closeModal} tag="h4">
                    Edit Member
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-InputService">Enter Member Name <span className="text-danger">*</span></Label>
                                    <Input
                                        type="text"
                                        id="formrow-InputService"
                                        className="form-control"
                                        name="member_name"
                                        onChange={formikObj.handleChange}
                                        value={formikObj.values.member_name}
                                        placeholder="Enter Member Name"
                                        invalid={
                                            formikObj.touched.member_name && formikObj.errors.member_name ? true : false
                                        }
                                    />
                                    {formikObj.touched.member_name && formikObj.errors.member_name ? (
                                        <FormFeedback type="invalid">{formikObj.errors.member_name}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-InputService">Enter Contact No. <span className="text-danger">*</span></Label>
                                    <Input
                                        type="text"
                                        id="formrow-InputService"
                                        className="form-control"
                                        name="mobile_no"
                                        onChange={(e) => { handleNumber(e) }}
                                        value={formikObj.values.mobile_no}
                                        placeholder="Enter Contact No."
                                        maxLength={10}
                                        invalid={
                                            formikObj.touched.mobile_no && formikObj.errors.mobile_no ? true : false
                                        }
                                    />
                                    {formikObj.touched.mobile_no && formikObj.errors.mobile_no ? (
                                        <FormFeedback type="invalid">{formikObj.errors.mobile_no}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-InputService">Enter Email Address <span className="text-danger">*</span></Label>
                                    <Input
                                        type="email"
                                        id="formrow-InputService"
                                        className="form-control"
                                        name="email_id"
                                        onChange={formikObj.handleChange}
                                        value={formikObj.values.email_id}
                                        placeholder="Enter Email Address"
                                        invalid={
                                            formikObj.touched.email_id && formikObj.errors.email_id ? true : false
                                        }
                                    />
                                    {formikObj.touched.email_id && formikObj.errors.email_id ? (
                                        <FormFeedback type="invalid">{formikObj.errors.email_id}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-InputService">Enter Age <span className="text-danger">*</span></Label>
                                    <Input
                                        type="text"
                                        id="formrow-InputService"
                                        className="form-control"
                                        name="member_age"
                                        onChange={(e) => { handleNumber(e) }}
                                        value={formikObj.values.member_age}
                                        placeholder="Enter Age"
                                        maxLength={2}
                                        invalid={
                                            formikObj.touched.member_age && formikObj.errors.member_age ? true : false
                                        }
                                    />
                                    {formikObj.touched.member_age && formikObj.errors.member_age ? (
                                        <FormFeedback type="invalid">{formikObj.errors.member_age}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-InputService">Select Gender <span className="text-danger">*</span></Label>
                                    <div>
                                        <div className="form-check form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="gender"
                                                id="exampleRadios2"
                                                value="1"
                                                onClick={(e) => { handleGenderChange(e) }}
                                                checked={formikObj.values.gender == 1}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="exampleRadios2"
                                            >
                                                Male
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="gender"
                                                id="exampleRadios2"
                                                value="2"
                                                onClick={(e) => { handleGenderChange(e) }}
                                                checked={formikObj.values.gender == 2}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="exampleRadios2"
                                            >
                                                Female
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="gender"
                                                id="exampleRadios2"
                                                value="3"
                                                onClick={(e) => { handleGenderChange(e) }}
                                                checked={formikObj.values.gender == 3}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="exampleRadios2"
                                            >
                                                Transgender
                                            </label>
                                        </div>
                                    </div>
                                    {hasGender == false && <span className="mt-3" style={{ color: "red", fontSize: "10px" }}>Please Select Gender</span>}
                                </div>
                            </Col>

                            <Col lg={6}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-InputService">Enter Profession</Label>
                                    <Input
                                        type="text"
                                        id="formrow-InputService"
                                        className="form-control"
                                        name="profession"
                                        onChange={formikObj.handleChange}
                                        value={formikObj.values.profession}
                                        placeholder="Enter Profession"
                                    />
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-InputAddress">Enter Access Card No.</Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        id="formrow-InputMemberAccessCode"
                                        placeholder="Enter Access Card No."
                                        name="member_access_code"
                                        onChange={formikObj.handleChange}
                                        value={formikObj.values.member_access_code}
                                        invalid={
                                            formikObj.touched.member_access_code && formikObj.errors.member_access_code ? true : false
                                        }
                                    />
                                    {formikObj.touched.member_access_code && formikObj.errors.member_access_code ? (
                                        <FormFeedback type="invalid">{formikObj.errors.member_access_code}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-serviceType">Access Card No. Status</Label>
                                    <Input
                                        type="select"
                                        id="formrow-serviceType"
                                        className="form-control"
                                        name={'member_access_code_status'}
                                        onChange={formikObj.handleChange}
                                        value={formikObj.values.member_access_code_status}
                                        invalid={
                                            formikObj.touched.member_access_code_status && formikObj.errors.member_access_code_status ? true : false
                                        }
                                    >
                                        <option value="">Select</option>
                                        {accessCardStatusList.map((item, index) => {
                                            return (
                                                <option value={item.id} key={item.id}>{item.name}</option>
                                            )
                                        })
                                        }
                                    </Input>

                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-InputAddress">Profile Image</Label>
                                    <Input
                                        type="file"
                                        className="form-control"
                                        name="profile_image"
                                        onChange={(e) => handleChangeFile(e)}
                                    />
                                </div>
                            </Col>
                            <Col lg={2}>
                                <div className="mb-3">
                                    <span className="logo-lg">
                                        <img src={imageSrc} height={100} width={100} alt="profile" className="avatar-lg rounded-circle img-thumbnail" />
                                        <span className="extra-space"></span>
                                    </span>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-member-status">Member Status</Label>
                                    <Input
                                        type="select"
                                        id="formrow-member-status"
                                        className="form-control"
                                        name={'member_status'}
                                        onChange={formikObj.handleChange}
                                        value={formikObj.values.member_status}
                                        invalid={
                                            formikObj.touched.member_status && formikObj.errors.member_status ? true : false
                                        }
                                    >
                                        <option value="">Select</option>
                                        {memberStatusList.map((item, index) => {
                                            return (
                                                <option value={item.id} key={item.id}>{item.name}</option>
                                            )
                                        })
                                        }
                                    </Input>

                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-InputAddress">ID Proof</Label>
                                    <Input
                                        type="file"
                                        className="form-control"
                                        name="id_proof"
                                        onChange={(e) => handleIDProofChangeFile(e)}
                                    />
                                </div>
                            </Col>
                            <Col lg={2}>
                                <div className="mb-3">
                                    <span className="logo-lg">
                                        <img src={idProofSrc} height={100} width={100} alt="id_proof" className="avatar-lg rounded-circle img-thumbnail" />
                                        <span className="extra-space"></span>
                                    </span>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-InputAddress">Address</Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        id="address"
                                        placeholder="Enter Address"
                                        name="address"

                                        // -------- for autocomplete --------
                                        onChange={formikObj.handleChange}
                                        value={formikObj.values.address}
                                        autoComplete="address"
                                        invalid={
                                            formikObj.touched.address && formikObj.errors.address ? true : false
                                        }
                                    />
                                    {formikObj.touched.address && formikObj.errors.address ? (
                                        <FormFeedback type="invalid">{formikObj.errors.address}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <div className="d-flex">
                                    <label
                                        className="form-check-label"
                                        htmlFor="customSwitch2"
                                    >
                                        Is Diet Chart Not Applicable
                                    </label>&nbsp;&nbsp;
                                    <div className="form-check form-switch mb-3" >
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="customSwitch2"
                                            name="diet_chart_applicable"
                                            value={formikObj.values.diet_chart_applicable}
                                            checked={formikObj.values.diet_chart_applicable == 1 ? true : false}
                                            onClick={e => {
                                                formikObj.setFieldValue("diet_chart_applicable", e.target.checked ? 0 : 1)
                                            }}
                                        />
                                    </div>
                                </div>
                                <div style={{ display: "flex" }}>
                                    <label
                                        className="form-check-label"
                                        htmlFor="customSwitch2"
                                    >
                                        Is Workout Chart Not Applicable
                                    </label>&nbsp;&nbsp;
                                    <div className="form-check form-switch mb-3" >
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="customSwitch2"
                                            name="workout_chart_applicable"
                                            value={formikObj.values.workout_chart_applicable}
                                            checked={formikObj.values.workout_chart_applicable == 1 ? true : false}
                                            onClick={e => {
                                                formikObj.setFieldValue("workout_chart_applicable", e.target.checked ? 0 : 1)
                                            }}
                                        />
                                    </div>
                                </div>
                                <div style={{ display: "flex" }}>
                                    <label
                                        className="form-check-label"
                                        htmlFor="customSwitch2"
                                    >
                                        Is Body Composition Not Applicable
                                    </label>&nbsp;&nbsp;
                                    <div className="form-check form-switch mb-3" >
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="customSwitch2"
                                            name="body_composition_applicable"
                                            value={formikObj.values.body_composition_applicable}
                                            checked={formikObj.values.body_composition_applicable == 1 ? true : false}
                                            onClick={e => {
                                                formikObj.setFieldValue("body_composition_applicable", e.target.checked ? 0 : 1)
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Form>

                </ModalBody>
                <div className="modal-footer">
                    {!!isSaving ?
                        <button className="btn btn-primary " disabled>
                            Processing
                        </button>
                        :
                        <>
                            <button
                                type="button"
                                onClick={closeModal}
                                className="btn btn-secondary "
                                data-dismiss="modal"
                            >
                                Close
                            </button>
                            <button
                                type="submit"
                                className="btn btn-primary "
                                onClick={(e) => {
                                    e.preventDefault();
                                    formikObj.handleSubmit();
                                    return false;
                                }}
                            >
                                {memberId == null ? "Save" : "Update"}
                            </button>
                        </>
                    }
                </div>
            </Modal>
        </React.Fragment>
    )
}

export default withRouter(EditMember);

EditMember.propTypes = {
    history: PropTypes.object,
    member_id: PropTypes.number
};