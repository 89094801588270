import { PROFILE_LOADING, SET_PROFILE_DATA } from "./actionTypes"

export const setProfileData = user => {
  return {
    type: SET_PROFILE_DATA,
    user
  }
}
export const setProfileLoading = loading => {
  return {
    type: PROFILE_LOADING,
    loading
  }
}
