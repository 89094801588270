import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useEffect, useState } from "react";
import {
    Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label, Button, Modal,
    ModalBody,
    ModalHeader,
    InputGroup,
    CardTitle,
    CardText,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Spinner
} from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import classnames from "classnames";
import Flatpickr from "react-flatpickr";
// Formik formikObj   
import * as Yup from "yup";
import { useFormik, FieldArray, FormikProvider } from "formik";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { standartDateFormat } from "utils/date";
import { axiosInstance, apiUrls } from "api";
import swal from 'sweetalert';
import { graphql } from "api";

// datatable related plugins

import CommonTable from "components/Common/CommonTable";
import usePaginator from "hooks/usePaginator";
import * as ExerciseQry from "GraphQLquery/exerciseQry";
import * as TrainerQry from "GraphQLquery/trainerQry";

const initialWorkoutPlanData = {
    workout_plan_name: "",
    day_one_target: "",
    day_two_target: "",
    day_three_target: "",
    day_four_target: "",
    day_five_target: "",
    day_six_target: "",
    description: "",
    // prepared_by: "",
    prepared_by_id: "",
    workoutPlanDay1: [{
        workout_plan_category_id: '',
        workout_plan_exercise_id: '',
        exercise_type_id: '',
        exercise_options: [],
        service_options_loading: false,
        no_of_set: '',
        no_of_rep: '',
        duration: '',
        day: 1,
        duration_type: '',
        note: '',
        exercise_choice: 0,
        duration_type_options: [
            {
                id: 1,
                type: "min"
            },
            {
                id: 2,
                type: "sec"
            }
        ]
    }],
    workoutPlanDay2: [{
        workout_plan_category_id: '',
        workout_plan_exercise_id: '',
        exercise_type_id: '',
        exercise_options: [],
        service_options_loading: false,
        no_of_set: '',
        no_of_rep: '',
        duration: '',
        day: 2,
        duration_type: '',
        note: '',
        exercise_choice: 0,
        duration_type_options: [
            {
                id: 1,
                type: "min"
            },
            {
                id: 2,
                type: "sec"
            }
        ]
    }],
    workoutPlanDay3: [{
        workout_plan_category_id: '',
        workout_plan_exercise_id: '',
        exercise_type_id: '',
        exercise_options: [],
        service_options_loading: false,
        no_of_set: '',
        no_of_rep: '',
        duration: '',
        day: 3,
        duration_type: '',
        note: '',
        exercise_choice: 0,
        duration_type_options: [
            {
                id: 1,
                type: "min"
            },
            {
                id: 2,
                type: "sec"
            }
        ]
    }],
    workoutPlanDay4: [{
        workout_plan_category_id: '',
        workout_plan_exercise_id: '',
        exercise_type_id: '',
        exercise_options: [],
        service_options_loading: false,
        no_of_set: '',
        no_of_rep: '',
        duration: '',
        day: 4,
        duration_type: '',
        note: '',
        exercise_choice: 0,
        duration_type_options: [
            {
                id: 1,
                type: "min"
            },
            {
                id: 2,
                type: "sec"
            }
        ]
    }],
    workoutPlanDay5: [{
        workout_plan_category_id: '',
        workout_plan_exercise_id: '',
        exercise_type_id: '',
        exercise_options: [],
        service_options_loading: false,
        no_of_set: '',
        no_of_rep: '',
        duration: '',
        day: 5,
        duration_type: '',
        note: '',
        exercise_choice: 0,
        duration_type_options: [
            {
                id: 1,
                type: "min"
            },
            {
                id: 2,
                type: "sec"
            }
        ]
    }],
    workoutPlanDay6: [{
        workout_plan_category_id: '',
        workout_plan_exercise_id: '',
        exercise_type_id: '',
        exercise_options: [],
        service_options_loading: false,
        no_of_set: '',
        no_of_rep: '',
        duration: '',
        day: 6,
        duration_type: '',
        note: '',
        exercise_choice: 0,
        duration_type_options: [
            {
                id: 1,
                type: "min"
            },
            {
                id: 2,
                type: "sec"
            }
        ]
    }]
}

const numberOfSet = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

const AddWorkoutPlan = props => {

    const [customActiveTab, setcustomActiveTab] = useState("1");
    const [activeTab, setactiveTab] = useState("1");

    const [workoutPlanData, setWorkoutPlanData] = useState({ ...initialWorkoutPlanData })
    const [error, setError] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const [processingList, setProcessingList] = useState(false);
    const [allExerciseList, setAllExerciseList] = useState([]);
    const [paginatorInfo, setPaginatorInfo, resetPaginatorInfo] = usePaginator();
    const [searchText, setSearchText] = useState('');

    const [workoutPlanCategory, setWorkoutPlanCategory] = useState([])
    const [exerciseTypes, setExerciseTypes] = useState([])
    const [trainers, setTrainers] = useState([])

    useEffect(() => {
        fetchWorkoutPlanCategory();
        fetchExerciseTypes();
        fetchAllTrainerListData()
    }, [])

    const fetchExerciseTypes = async () => {
        try {
            let response = null;
            response = await graphql({
                query: ExerciseQry.EXERCISE_TYPES_QUERY,
                variables: {}
            });
            let exerciseTypes = []
            exerciseTypes = response?.data?.exercise_types || [];
            setExerciseTypes(exerciseTypes);
        } catch (err) {
            setExerciseTypes([]);
        }
    }

    const fetchWorkoutPlanCategory = async () => {
        const category_items = []
        try {
            let response = null;
            response = await graphql({
                query: ExerciseQry.WORKOUT_PLAN_CATEGORY_QUERY,
                variables: {}
            });
            let workoutPlanCategory = []
            workoutPlanCategory = response?.data?.workout_plan_categories?.data || [];
            setWorkoutPlanCategory(workoutPlanCategory);

        } catch (err) {
            setWorkoutPlanCategory([]);
        }
    }

    const fetchAllTrainerListData = async () => {
        try {
            const res = await graphql({
                query: TrainerQry.FETCH_ALL_TRAINER_LIST_QUERY
            });

            if (res.data.trainers.data) {
                let response = res?.data?.trainers?.data
                setTrainers(response)
            }
        } catch (error) {
            setError(error)
        }
    }

    const formikObj = useFormik({
        enableReinitialize: true,

        initialValues: { ...workoutPlanData },
        validationSchema: Yup.object({
            workout_plan_name: Yup.string().required("Please Enter Workout Plan Name"),
            workoutPlanDay1: customActiveTab == 1 && Yup.array().of(Yup.object({
                workout_plan_category_id: Yup.string().required("Please Select Category"),
                workout_plan_exercise_id: Yup.string().required("Please Select Exercise"),
                no_of_set: Yup.string().required("Please Select Set(s)"),
                // duration: Yup.string().required("Please Enter Duration"),
                // duration_type: Yup.string().required("Please Select Duration Type"),
            })),
            workoutPlanDay2: customActiveTab == 2 && Yup.array().of(Yup.object({
                workout_plan_category_id: Yup.string().required("Please Select Category"),
                workout_plan_exercise_id: Yup.string().required("Please Select Exercise"),
                no_of_set: Yup.string().required("Please Select Set(s)"),
                // duration: Yup.string().required("Please Enter Duration"),
                // duration_type: Yup.string().required("Please Select Duration Type"),
            })),
            workoutPlanDay3: customActiveTab == 3 && Yup.array().of(Yup.object({
                workout_plan_category_id: Yup.string().required("Please Select Category"),
                workout_plan_exercise_id: Yup.string().required("Please Select Exercise"),
                no_of_set: Yup.string().required("Please Select Set(s)"),
                // duration: Yup.string().required("Please Enter Duration"),
                // duration_type: Yup.string().required("Please Select Duration Type"),
            })),
            workoutPlanDay4: customActiveTab == 4 && Yup.array().of(Yup.object({
                workout_plan_category_id: Yup.string().required("Please Select Category"),
                workout_plan_exercise_id: Yup.string().required("Please Select Exercise"),
                no_of_set: Yup.string().required("Please Select Set(s)"),
                // duration: Yup.string().required("Please Enter Duration"),
                // duration_type: Yup.string().required("Please Select Duration Type"),
            })),
        }),
        onSubmit: async (values) => {
            // return
            try {
                setIsSaving(true)
                const response = await axiosInstance.post(apiUrls.workoutPlan.addAndUpdateWorkoutplan, { ...values });
                if (response.success == 1) {
                    formikObj.resetForm()
                    setIsSaving(false)
                    swal("Success!", response.message, "success");
                    props.history.push("/manage-workout-plan")
                }
                else {
                    setIsSaving(false)
                    swal("Oops!", response.message, "error");
                }
            } catch (err) {
                setIsSaving(false)
                swal("Oops!", "Something went wrong!", "error");
                setError(err.message)
            }
        }
    });

    const handleRepSetChange = (e) => {
        if (/^[0-9]+$/g.test(e.target.value) || e.target.value == '') {
            formikObj.setFieldValue(e.target.name, e.target.value)
        }
    }

    const handleStartTimeChange = (e, timeChange) => {
        formikObj.setFieldValue(e.target.name, timeChange[0].getMinutes() + ":" + timeChange[0].getSeconds())
    }

    const fetchExerciseByCategoryId = async (categoryId, rowIndex, exerciseIdFieldName, exerciseOptionsFieldName, serviceLoadingFieldName) => {
        const { setFieldValue } = formikObj;
        setFieldValue(serviceLoadingFieldName, true);
        try {
            const res = await graphql({
                query: ExerciseQry.FETCH_ALL_EXERCISES_DEPENDING_ON_WORKOUT_CATEGORY_QUERY,
                variables: {
                    // ...paginatorInfo,
                    // searchText: `%${searchText}%`,
                    workout_plan_category_id: categoryId
                }
            });

            if (res.data.workout_plan_exercises.data) {
                const exercise_options = res?.data?.workout_plan_exercises?.data
                setFieldValue(exerciseOptionsFieldName, exercise_options)
                setFieldValue(exerciseIdFieldName, '')
                setAllExerciseList(!!res.data.workout_plan_exercises ? res?.data?.workout_plan_exercises?.data : [])
                setFieldValue(serviceLoadingFieldName, false);
            }

            else {
                setAllExerciseList([])
                setFieldValue(serviceLoadingFieldName, false);
            }
        } catch (error) {
            setAllExerciseList([])
            setFieldValue(serviceLoadingFieldName, false);
        }
    }

    // functions for Tab Display

    const toggleDay = tab => {

        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Add Workout</title>
                </MetaTags>
                <Container fluid={true}>
                    <Card>
                        <CardBody>
                            <CardTitle className="mb-4 text-primary">Add Workout</CardTitle>
                            <div>
                                <div className="row mb-4">
                                    <Label className="col-sm-3 col-form-label">
                                        Workout Plan Name <span className="text-danger">*</span>
                                    </Label>
                                    <Col sm={9}>
                                        <Input
                                            type="text"
                                            id="formrow-InputService"
                                            className="form-control"
                                            name="workout_plan_name"
                                            onChange={formikObj.handleChange}
                                            value={formikObj.values.workout_plan_name}
                                            placeholder="Enter Workout Plan Name"
                                            invalid={
                                                formikObj.touched.workout_plan_name && formikObj.errors.workout_plan_name ? true : false
                                            }
                                        />
                                        {formikObj.touched.workout_plan_name && formikObj.errors.workout_plan_name ? (
                                            <FormFeedback type="invalid">{formikObj.errors.workout_plan_name}</FormFeedback>
                                        ) : null}
                                    </Col>
                                </div>
                                <div className="row mb-4">
                                    <Label className="col-sm-3 col-form-label">
                                        Description
                                    </Label>
                                    <Col sm={9}>
                                        <Input
                                            type="textarea"
                                            id="formrow-InputService"
                                            className="form-control"
                                            name="description"
                                            onChange={formikObj.handleChange}
                                            value={formikObj.values.description}
                                            placeholder="Enter Description"
                                            invalid={
                                                formikObj.touched.description && formikObj.errors.description ? true : false
                                            }
                                        />
                                        {formikObj.touched.description && formikObj.errors.description ? (
                                            <FormFeedback type="invalid">{formikObj.errors.description}</FormFeedback>
                                        ) : null}
                                    </Col>
                                </div>
                                {/* <div className="row mb-4">
                                    <Label className="col-sm-3 col-form-label">
                                        Prepared By
                                    </Label>
                                    <Col sm={9}>
                                        <Input
                                            type="text"
                                            id="formrow-InputService"
                                            className="form-control"
                                            name="prepared_by"
                                            onChange={formikObj.handleChange}
                                            value={formikObj.values.prepared_by}
                                            placeholder="Enter Prepared By"
                                            invalid={
                                                formikObj.touched.prepared_by && formikObj.errors.prepared_by ? true : false
                                            }
                                        />
                                        {formikObj.touched.prepared_by && formikObj.errors.prepared_by ? (
                                            <FormFeedback type="invalid">{formikObj.errors.prepared_by}</FormFeedback>
                                        ) : null}
                                    </Col>
                                </div> */}
                                <div className="row mb-4">
                                    <Label className="col-sm-3 col-form-label">
                                        Prepared By
                                    </Label>
                                    <Col sm={9}>
                                        <Input
                                            type="select"
                                            id="formrow-serviceType"
                                            className="form-control"
                                            name="prepared_by_id"
                                            onChange={formikObj.handleChange}
                                            value={formikObj.values.prepared_by_id}
                                        >
                                            <option value="">Select</option>
                                            {trainers.map((item) => (
                                                <option value={item.id} key={item.id}>{item.name+" ("+item.nick_name+")"}</option>
                                            ))}
                                        </Input>
                                    </Col>
                                </div>
                                <hr />

                                <Nav tabs className="nav-tabs-custom nav-justified custom-tab">
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: customActiveTab === "1",
                                            })}
                                            onClick={() => {
                                                toggleDay("1");
                                            }}
                                        >
                                            <span className="d-block d-sm-none">
                                                <i className="fas fa-home"></i>
                                            </span>
                                            <span className="d-none d-sm-block">DAY 1</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: customActiveTab === "2",
                                            })}
                                            onClick={() => {
                                                toggleDay("2");
                                            }}
                                        >
                                            <span className="d-block d-sm-none">
                                                <i className="far fa-user"></i>
                                            </span>
                                            <span className="d-none d-sm-block">DAY 2</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: customActiveTab === "3",
                                            })}
                                            onClick={() => {
                                                toggleDay("3");
                                            }}
                                        >
                                            <span className="d-block d-sm-none">
                                                <i className="far fa-envelope"></i>
                                            </span>
                                            <span className="d-none d-sm-block">DAY 3</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: customActiveTab === "4",
                                            })}
                                            onClick={() => {
                                                toggleDay("4");
                                            }}
                                        >
                                            <span className="d-block d-sm-none">
                                                <i className="fas fa-cog"></i>
                                            </span>
                                            <span className="d-none d-sm-block">DAY 4</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: customActiveTab === "5",
                                            })}
                                            onClick={() => {
                                                toggleDay("5");
                                            }}
                                        >
                                            <span className="d-block d-sm-none">
                                                <i className="fas fa-cog"></i>
                                            </span>
                                            <span className="d-none d-sm-block">DAY 5</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: customActiveTab === "6",
                                            })}
                                            onClick={() => {
                                                toggleDay("6");
                                            }}
                                        >
                                            <span className="d-block d-sm-none">
                                                <i className="fas fa-cog"></i>
                                            </span>
                                            <span className="d-none d-sm-block">DAY 6</span>
                                        </NavLink>
                                    </NavItem>
                                </Nav>

                                <TabContent
                                    activeTab={customActiveTab}
                                    className="py-3 text-muted"
                                >
                                    <TabPane tabId="1">
                                        <FormikProvider value={formikObj}>
                                            <Row className="mb-3">
                                                <Col lg={6}>
                                                    <Label className="col-form-label">
                                                        Target
                                                    </Label>
                                                    <Input
                                                        type="text"
                                                        id="formrow-InputService"
                                                        className="form-control"
                                                        name="day_one_target"
                                                        onChange={formikObj.handleChange}
                                                        value={formikObj.values.day_one_target}
                                                        placeholder="Enter Day One Target"
                                                        invalid={
                                                            formikObj.touched.day_one_target && formikObj.errors.day_one_target ? true : false
                                                        }
                                                    />
                                                    {formikObj.touched.day_one_target && formikObj.errors.day_one_target ? (
                                                        <FormFeedback type="invalid">{formikObj.errors.day_one_target}</FormFeedback>
                                                    ) : null}
                                                </Col>
                                            </Row>
                                            {console.log(formikObj.values.workoutPlanDay1)}
                                            <FieldArray
                                                name="workoutPlanDay1"
                                                render={arrayHelpersAdd => (
                                                    <>
                                                        {formikObj.values.workoutPlanDay1.map((data, index) => {
                                                            const isLastRow = (formikObj.values?.workoutPlanDay1?.length - 1) === index;
                                                            const getFieldName = name => `${arrayHelpersAdd.name}[${index}][${name}]`;
                                                            const getErrorMsg = name => (formikObj.errors.workoutPlanDay1?.[index]?.[name] && formikObj.touched.workoutPlanDay1?.[index]?.[name]
                                                                ? formikObj.errors.workoutPlanDay1?.[index]?.[name]
                                                                : ""
                                                            )

                                                            return (
                                                                <>

                                                                    <Row key={index} className="mb-3">
                                                                        <Col lg={6} className="mb-3 mb-lg-0">
                                                                            <Label className="col-form-label">
                                                                                Select Category <span className="text-danger">*</span>
                                                                            </Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="formrow-serviceType"
                                                                                className="form-control"
                                                                                name={getFieldName('workout_plan_category_id')}
                                                                                onChange={(e) => {
                                                                                    formikObj.handleChange(e)
                                                                                    const exerciseIdFieldName = getFieldName("workout_plan_exercise_id")
                                                                                    const exerciseOptionsFieldName = getFieldName("exercise_options")
                                                                                    const serviceLoadingFieldName = getFieldName('service_options_loading');
                                                                                    fetchExerciseByCategoryId(
                                                                                        e.target.value,
                                                                                        index,
                                                                                        exerciseIdFieldName,
                                                                                        exerciseOptionsFieldName,
                                                                                        serviceLoadingFieldName
                                                                                    )
                                                                                }}
                                                                                value={data.workout_plan_category_id}
                                                                                invalid={!!getErrorMsg('workout_plan_category_id')}
                                                                            >
                                                                                <option value="">Select</option>
                                                                                {workoutPlanCategory.map((item) => (
                                                                                    <option value={item.id} key={item.id}>{item.category_name}</option>
                                                                                ))}

                                                                            </Input>
                                                                            {getErrorMsg('workout_plan_category_id') && <FormFeedback type="invalid">{getErrorMsg('workout_plan_category_id')}</FormFeedback>}

                                                                        </Col>
                                                                        <Col lg={6}>
                                                                            <Label className="col-form-label">
                                                                                Select Exercise Name <span className="text-danger">*</span>
                                                                                {data.service_options_loading && <Spinner animation="border" size="sm" color="primary" />}
                                                                            </Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="formrow-serviceType"
                                                                                className="form-control"
                                                                                value={data.id}
                                                                                name={getFieldName("workout_plan_exercise_id")}
                                                                                onChange={(e) => {
                                                                                    formikObj.handleChange(e);
                                                                                    const findExerciseObj = data.exercise_options?.find(exercise => exercise.id == e.target.value);
                                                                                    formikObj.setFieldValue(getFieldName('exercise_type_id'), findExerciseObj?.exercise_type?.id);
                                                                                    // setTimeout(() => {
                                                                                    //     formikObj.setFieldValue('total_price', calculateTotalPrice())
                                                                                    // }, 1000)

                                                                                }}
                                                                            >
                                                                                <option value="">Select</option>
                                                                                {data.exercise_options?.map((item) => (
                                                                                    <option value={item.id} key={item.id}>{item.exercise_name}</option>
                                                                                ))}
                                                                            </Input>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className="mb-3">
                                                                        <Col lg={6} className="mb-3 mb-lg-0">
                                                                            <Label className="col-form-label">
                                                                                No. of SET
                                                                            </Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="formrow-InputService"
                                                                                className="form-control"
                                                                                name={getFieldName("no_of_set")}
                                                                                onChange={(e) => formikObj.handleChange(e)}
                                                                                value={data.no_of_set}
                                                                                placeholder="Enter No. of SET"
                                                                                invalid={!!getErrorMsg('no_of_set')}
                                                                            >
                                                                                <option value="">Select</option>
                                                                                {numberOfSet.map((item) => (
                                                                                    <option value={item} key={item}>{item}</option>
                                                                                ))}
                                                                            </Input>
                                                                            {getErrorMsg('no_of_set') && <FormFeedback type="invalid">{getErrorMsg('no_of_set')}</FormFeedback>}
                                                                        </Col>

                                                                        {formikObj.values.workoutPlanDay1[index].exercise_type_id == 1 ?
                                                                            <Col lg={6}>
                                                                                <Label className="col-form-label">
                                                                                    No. of REP/SET
                                                                                </Label>
                                                                                <Input
                                                                                    type="text"
                                                                                    id="formrow-InputService"
                                                                                    className="form-control"
                                                                                    name={getFieldName("no_of_rep")}
                                                                                    onChange={(e) => { handleRepSetChange(e) }}
                                                                                    value={data.no_of_rep}
                                                                                    placeholder="Enter No. of REP/SET"
                                                                                    invalid={!!getErrorMsg('no_of_rep')}
                                                                                />
                                                                                {getErrorMsg('no_of_rep') && <FormFeedback type="invalid">{getErrorMsg('no_of_rep')}</FormFeedback>}
                                                                            </Col>
                                                                            :
                                                                            <Col col={6}>
                                                                                <Label className="col-form-label">
                                                                                    Duration
                                                                                </Label>
                                                                                <Row>
                                                                                    <Col lg={6}>
                                                                                        <Input
                                                                                            type="text"
                                                                                            id="formrow-InputService"
                                                                                            className="form-control"
                                                                                            name={getFieldName("duration")}
                                                                                            onChange={(e) => { handleRepSetChange(e) }}
                                                                                            value={data.duration}
                                                                                            placeholder="Enter Duration"
                                                                                            invalid={!!getErrorMsg('duration')}
                                                                                        />
                                                                                        {getErrorMsg('duration') && <FormFeedback type="invalid">{getErrorMsg('duration')}</FormFeedback>}
                                                                                    </Col>
                                                                                    <Col col={6}>
                                                                                        <Input
                                                                                            type="select"
                                                                                            id="formrow-serviceType"
                                                                                            className="form-control"
                                                                                            value={data.id}
                                                                                            name={getFieldName("duration_type")}
                                                                                            onChange={(e) => {
                                                                                                formikObj.handleChange(e);
                                                                                            }}
                                                                                        >
                                                                                            <option value="">Select</option>
                                                                                            {data.duration_type_options?.map((item) => (
                                                                                                <option value={item.id} key={item.id}>{item.type}</option>
                                                                                            ))}
                                                                                        </Input>
                                                                                        {getErrorMsg('duration_type') && <FormFeedback type="invalid">{getErrorMsg('duration_type')}</FormFeedback>}
                                                                                    </Col>
                                                                                </Row>

                                                                                {/* <Input
                                                                                    type="text"
                                                                                    id="formrow-InputService"
                                                                                    className="form-control"
                                                                                    name={getFieldName("duration")}
                                                                                    onChange={formikObj.handleChange}
                                                                                    value={data.duration}
                                                                                    placeholder="Enter Duration"
                                                                                    invalid={!!getErrorMsg('duration')}
                                                                                /> */}
                                                                                {/* <Flatpickr
                                                                                    className="form-control d-block"
                                                                                    name={getFieldName("duration")}
                                                                                    options={{
                                                                                        noCalendar: true,
                                                                                        enableTime: true,
                                                                                        enableSeconds: true,
                                                                                        time_24hr: false,
                                                                                        dateFormat: 'i:s'
                                                                                    }}
                                                                                    onChange={(e, timeChange) => {
                                                                                        formikObj.setFieldValue(getFieldName("duration"), timeChange)
                                                                                    }}
                                                                                    value={data.duration}
                                                                                /> */}
                                                                            </Col>
                                                                        }
                                                                    </Row>
                                                                    <Row className="mb-3">
                                                                        <Col lg={12}>
                                                                            <Label className="col-form-label">
                                                                                Note
                                                                            </Label>
                                                                            <Input
                                                                                type="textarea"
                                                                                id="formrow-InputService"
                                                                                className="form-control"
                                                                                name={getFieldName("note")}
                                                                                onChange={(e) => formikObj.handleChange(e)}
                                                                                value={data.note}
                                                                                placeholder="Enter Note"
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                    <br />
                                                                    <Row className="mb-3">
                                                                        <Col lg={6}>
                                                                            {!isLastRow &&
                                                                                <div className="d-flex align-items-center">
                                                                                    <label
                                                                                        className="form-check-label"
                                                                                        htmlFor="customSwitchsizemd"
                                                                                    >
                                                                                        AND
                                                                                    </label>
                                                                                    <div className="form-check form-switch form-switch-md ms-1">
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            className="form-check-input"
                                                                                            id="customSwitchsizemd"
                                                                                            name={getFieldName("exercise_choice")}
                                                                                            onChange={(e) => formikObj.setFieldValue(getFieldName("exercise_choice"), 1)}
                                                                                        />
                                                                                    </div>
                                                                                    <label
                                                                                        className="form-check-label"
                                                                                        htmlFor="customSwitchsizemd"
                                                                                    >
                                                                                        OR
                                                                                    </label>
                                                                                </div>
                                                                            }
                                                                        </Col>
                                                                        <Col lg={6} className="d-flex align-items-center" style={{ justifyContent: "right" }}>
                                                                            <div className="">
                                                                                {formikObj.values?.workoutPlanDay1.length > 1 && <button
                                                                                    type="button"
                                                                                    className="btn-rounded  btn btn-danger ms-2 btn-sm"
                                                                                    onClick={() => arrayHelpersAdd.remove(index)}
                                                                                > <i className="bx bx-minus align-middle" />
                                                                                </button>
                                                                                }
                                                                            </div>
                                                                            <div className="">
                                                                                {/* {isLastRow && */}
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn-rounded  btn btn-success ms-2 btn-sm"
                                                                                    onClick={(e) => {
                                                                                        // setDayIndex(index++)
                                                                                        let arr = formikObj.values.workoutPlanDay1
                                                                                        arr.splice(index + 1, 0,
                                                                                            {
                                                                                                workout_plan_category_id: '',
                                                                                                workout_plan_exercise_id: '',
                                                                                                exercise_options: [],
                                                                                                service_options_loading: false,
                                                                                                no_of_set: '',
                                                                                                no_of_rep: '',
                                                                                                duration: '',
                                                                                                day: 1,
                                                                                                duration_type: '',
                                                                                                note: '',
                                                                                                exercise_choice: 0,
                                                                                                duration_type_options: [
                                                                                                    {
                                                                                                        id: 1,
                                                                                                        type: "min"
                                                                                                    },
                                                                                                    {
                                                                                                        id: 2,
                                                                                                        type: "sec"
                                                                                                    }
                                                                                                ]
                                                                                            })
                                                                                        formikObj.setFieldValue('workoutPlanDay1', arr)

                                                                                        // arrayHelpersAdd.push({
                                                                                        //     workout_plan_category_id: '',
                                                                                        //     workout_plan_exercise_id: '',
                                                                                        //     exercise_options: [],
                                                                                        //     service_options_loading: false,
                                                                                        //     no_of_set: '',
                                                                                        //     no_of_rep: '',
                                                                                        //     duration: '',
                                                                                        //     day: 1,
                                                                                        //     duration_type: '',
                                                                                        //     note: '',
                                                                                        //     exercise_choice: 0,
                                                                                        //     duration_type_options: [
                                                                                        //         {
                                                                                        //             id: 1,
                                                                                        //             type: "min"
                                                                                        //         },
                                                                                        //         {
                                                                                        //             id: 2,
                                                                                        //             type: "sec"
                                                                                        //         }
                                                                                        //     ]
                                                                                        // })
                                                                                    }
                                                                                    }>
                                                                                    <i className="bx bx-plus align-middle" />
                                                                                </button>
                                                                                {/* // } */}
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </>
                                                            )
                                                        })}
                                                    </>
                                                )}
                                            />
                                        </FormikProvider>
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <FormikProvider value={formikObj}>
                                            <Row className="mb-3">
                                                <Col lg={6}>
                                                    <Label className="col-form-label">
                                                        Target
                                                    </Label>
                                                    <Input
                                                        type="text"
                                                        id="formrow-InputService"
                                                        className="form-control"
                                                        name="day_two_target"
                                                        onChange={formikObj.handleChange}
                                                        value={formikObj.values.day_two_target}
                                                        placeholder="Enter Day Two Target"
                                                        invalid={
                                                            formikObj.touched.day_two_target && formikObj.errors.day_two_target ? true : false
                                                        }
                                                    />
                                                    {formikObj.touched.day_two_target && formikObj.errors.day_two_target ? (
                                                        <FormFeedback type="invalid">{formikObj.errors.day_two_target}</FormFeedback>
                                                    ) : null}
                                                </Col>
                                            </Row>
                                            <FieldArray
                                                name="workoutPlanDay2"
                                                render={arrayHelpersAdd => (
                                                    <>
                                                        {formikObj.values.workoutPlanDay2.map((data, index) => {
                                                            const isLastRow = (formikObj.values?.workoutPlanDay2?.length - 1) === index;
                                                            const getFieldName = name => `${arrayHelpersAdd.name}[${index}][${name}]`;
                                                            const getErrorMsg = name => (formikObj.errors.workoutPlanDay2?.[index]?.[name] && formikObj.touched.workoutPlanDay2?.[index]?.[name]
                                                                ? formikObj.errors.workoutPlanDay2?.[index]?.[name]
                                                                : ""
                                                            )

                                                            return (
                                                                <>

                                                                    <Row key={index} className="mb-3">
                                                                        <Col lg={6} className="mb-3 mb-lg-0">
                                                                            <Label className="col-form-label">
                                                                                Select Category <span className="text-danger">*</span>
                                                                            </Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="formrow-serviceType"
                                                                                className="form-control"
                                                                                name={getFieldName('workout_plan_category_id')}
                                                                                onChange={(e) => {
                                                                                    formikObj.handleChange(e)
                                                                                    const exerciseIdFieldName = getFieldName("workout_plan_exercise_id")
                                                                                    const exerciseOptionsFieldName = getFieldName("exercise_options")
                                                                                    const serviceLoadingFieldName = getFieldName('service_options_loading');
                                                                                    fetchExerciseByCategoryId(
                                                                                        e.target.value,
                                                                                        index,
                                                                                        exerciseIdFieldName,
                                                                                        exerciseOptionsFieldName,
                                                                                        serviceLoadingFieldName
                                                                                    )
                                                                                }}
                                                                                value={data.workout_plan_category_id}
                                                                                invalid={!!getErrorMsg('workout_plan_category_id')}
                                                                            >
                                                                                <option value="">Select</option>
                                                                                {workoutPlanCategory.map((item) => (
                                                                                    <option value={item.id} key={item.id}>{item.category_name}</option>
                                                                                ))}

                                                                            </Input>
                                                                            {getErrorMsg('workout_plan_category_id') && <FormFeedback type="invalid">{getErrorMsg('workout_plan_category_id')}</FormFeedback>}

                                                                        </Col>
                                                                        <Col lg={6}>
                                                                            <Label className="col-form-label">
                                                                                Select Exercise Name <span className="text-danger">*</span>
                                                                                {data.service_options_loading && <Spinner animation="border" size="sm" color="primary" />}
                                                                            </Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="formrow-serviceType"
                                                                                className="form-control"
                                                                                value={data.id}
                                                                                name={getFieldName("workout_plan_exercise_id")}
                                                                                onChange={(e) => {
                                                                                    formikObj.handleChange(e);
                                                                                    const findExerciseObj = data.exercise_options?.find(exercise => exercise.id == e.target.value);
                                                                                    formikObj.setFieldValue(getFieldName('exercise_type_id'), findExerciseObj?.exercise_type?.id);
                                                                                    // setTimeout(() => {
                                                                                    //     formikObj.setFieldValue('total_price', calculateTotalPrice())
                                                                                    // }, 1000)

                                                                                }}
                                                                            >
                                                                                <option value="">Select</option>
                                                                                {data.exercise_options?.map((item) => (
                                                                                    <option value={item.id} key={item.id}>{item.exercise_name}</option>
                                                                                ))}
                                                                            </Input>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className="mb-3">
                                                                        <Col lg={6} className="mb-3 mb-lg-0">
                                                                            <Label className="col-form-label">
                                                                                No. of SET
                                                                            </Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="formrow-InputService"
                                                                                className="form-control"
                                                                                name={getFieldName("no_of_set")}
                                                                                onChange={(e) => formikObj.handleChange(e)}
                                                                                value={data.no_of_set}
                                                                                placeholder="Enter No. of SET"
                                                                                invalid={!!getErrorMsg('no_of_set')}
                                                                            >
                                                                                <option value="">Select</option>
                                                                                {numberOfSet.map((item) => (
                                                                                    <option value={item} key={item}>{item}</option>
                                                                                ))}
                                                                            </Input>
                                                                            {getErrorMsg('no_of_set') && <FormFeedback type="invalid">{getErrorMsg('no_of_set')}</FormFeedback>}
                                                                        </Col>
                                                                        {formikObj.values.workoutPlanDay2[index].exercise_type_id == 1 ?
                                                                            <Col lg={6}>
                                                                                <Label className="col-form-label">
                                                                                    No. of REP/SET
                                                                                </Label>
                                                                                <Input
                                                                                    type="text"
                                                                                    id="formrow-InputService"
                                                                                    className="form-control"
                                                                                    name={getFieldName("no_of_rep")}
                                                                                    onChange={(e) => { handleRepSetChange(e) }}
                                                                                    value={data.no_of_rep}
                                                                                    placeholder="Enter No. of REP/SET"
                                                                                    invalid={!!getErrorMsg('no_of_rep')}
                                                                                />
                                                                                {getErrorMsg('no_of_rep') && <FormFeedback type="invalid">{getErrorMsg('no_of_rep')}</FormFeedback>}
                                                                            </Col>
                                                                            :
                                                                            <Col lg={6}>
                                                                                <Label className="col-form-label">
                                                                                    Duration
                                                                                </Label>
                                                                                <Row>
                                                                                    <Col col={6}>
                                                                                        <Input
                                                                                            type="text"
                                                                                            id="formrow-InputService"
                                                                                            className="form-control"
                                                                                            name={getFieldName("duration")}
                                                                                            onChange={(e) => { handleRepSetChange(e) }}
                                                                                            value={data.duration}
                                                                                            placeholder="Enter Duration"
                                                                                            invalid={!!getErrorMsg('duration')}
                                                                                        />
                                                                                        {getErrorMsg('duration') && <FormFeedback type="invalid">{getErrorMsg('duration')}</FormFeedback>}
                                                                                    </Col>
                                                                                    <Col col={6}>
                                                                                        <Input
                                                                                            type="select"
                                                                                            id="formrow-serviceType"
                                                                                            className="form-control"
                                                                                            value={data.id}
                                                                                            name={getFieldName("duration_type")}
                                                                                            onChange={(e) => {
                                                                                                formikObj.handleChange(e);
                                                                                            }}
                                                                                        >
                                                                                            <option value="">Select</option>
                                                                                            {data.duration_type_options?.map((item) => (
                                                                                                <option value={item.id} key={item.id}>{item.type}</option>
                                                                                            ))}
                                                                                        </Input>
                                                                                        {getErrorMsg('duration_type') && <FormFeedback type="invalid">{getErrorMsg('duration_type')}</FormFeedback>}
                                                                                    </Col>
                                                                                </Row>
                                                                                {/* <Input
                                                                                    type="text"
                                                                                    id="formrow-InputService"
                                                                                    className="form-control"
                                                                                    name={getFieldName("duration")}
                                                                                    onChange={formikObj.handleChange}
                                                                                    value={data.duration}
                                                                                    placeholder="Enter Duration"
                                                                                    invalid={!!getErrorMsg('duration')}
                                                                                /> */}
                                                                                {/* <Flatpickr
                                                                                    className="form-control d-block"
                                                                                    name={getFieldName("duration")}
                                                                                    options={{
                                                                                        noCalendar: true,
                                                                                        enableTime: true,
                                                                                        enableSeconds: true,
                                                                                        time_24hr: false,
                                                                                        dateFormat: 'i:s'
                                                                                    }}
                                                                                    onChange={(e, timeChange) => {
                                                                                        formikObj.setFieldValue(getFieldName("duration"), timeChange)
                                                                                    }}
                                                                                    value={data.duration}
                                                                                /> */}
                                                                            </Col>
                                                                        }
                                                                    </Row>
                                                                    <Row className="mb-3">
                                                                        <Col lg={12}>
                                                                            <Label className="col-form-label">
                                                                                Note
                                                                            </Label>
                                                                            <Input
                                                                                type="textarea"
                                                                                id="formrow-InputService"
                                                                                className="form-control"
                                                                                name={getFieldName("note")}
                                                                                onChange={(e) => formikObj.handleChange(e)}
                                                                                value={data.note}
                                                                                placeholder="Enter Note"
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                    <br />
                                                                    <Row className="mb-3">
                                                                        <Col lg={6}>
                                                                            {!isLastRow &&
                                                                                <div className="d-flex align-items-center">
                                                                                    <label
                                                                                        className="form-check-label"
                                                                                        htmlFor="customSwitchsizemd"
                                                                                    >
                                                                                        AND
                                                                                    </label>
                                                                                    <div className="form-check form-switch form-switch-md ms-1">
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            className="form-check-input"
                                                                                            id="customSwitchsizemd"
                                                                                            name={getFieldName("exercise_choice")}
                                                                                            onChange={(e) => formikObj.setFieldValue(getFieldName("exercise_choice"), 1)}
                                                                                        />
                                                                                    </div>
                                                                                    <label
                                                                                        className="form-check-label"
                                                                                        htmlFor="customSwitchsizemd"
                                                                                    >
                                                                                        OR
                                                                                    </label>
                                                                                </div>
                                                                            }
                                                                        </Col>
                                                                        <Col lg={6} className="d-flex align-items-center" style={{ justifyContent: "right" }}>
                                                                            <div className="">
                                                                                {formikObj.values?.workoutPlanDay2.length > 1 && <button
                                                                                    type="button"
                                                                                    className="btn-rounded  btn btn-danger ms-2 btn-sm"
                                                                                    onClick={() => arrayHelpersAdd.remove(index)}
                                                                                > <i className="bx bx-minus align-middle" />
                                                                                </button>
                                                                                }
                                                                            </div>
                                                                            <div className="">
                                                                                {/* {isLastRow &&  */}
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn-rounded  btn btn-success ms-2 btn-sm"
                                                                                    onClick={(e) => {
                                                                                        let arr = formikObj.values.workoutPlanDay2
                                                                                        arr.splice(index + 1, 0,
                                                                                            {
                                                                                                workout_plan_category_id: '',
                                                                                                workout_plan_exercise_id: '',
                                                                                                exercise_options: [],
                                                                                                service_options_loading: false,
                                                                                                no_of_set: '',
                                                                                                no_of_rep: '',
                                                                                                duration: '',
                                                                                                day: 2,
                                                                                                duration_type: '',
                                                                                                note: '',
                                                                                                exercise_choice: 0,
                                                                                                duration_type_options: [
                                                                                                    {
                                                                                                        id: 1,
                                                                                                        type: "min"
                                                                                                    },
                                                                                                    {
                                                                                                        id: 2,
                                                                                                        type: "sec"
                                                                                                    }
                                                                                                ]
                                                                                            })
                                                                                        formikObj.setFieldValue('workoutPlanDay2', arr)
                                                                                        // arrayHelpersAdd.push({
                                                                                        //     workout_plan_category_id: '',
                                                                                        //     workout_plan_exercise_id: '',
                                                                                        //     exercise_options: [],
                                                                                        //     service_options_loading: false,
                                                                                        //     no_of_set: '',
                                                                                        //     no_of_rep: '',
                                                                                        //     duration: '',
                                                                                        //     day: 2,
                                                                                        //     duration_type: '',
                                                                                        //     note: '',
                                                                                        //     exercise_choice: 0,
                                                                                        //     duration_type_options: [
                                                                                        //         {
                                                                                        //             id: 1,
                                                                                        //             type: "min"
                                                                                        //         },
                                                                                        //         {
                                                                                        //             id: 2,
                                                                                        //             type: "sec"
                                                                                        //         }
                                                                                        //     ]
                                                                                        // })
                                                                                    }
                                                                                    }>
                                                                                    <i className="bx bx-plus align-middle" />
                                                                                </button>
                                                                                {/* } */}
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </>
                                                            )
                                                        })}
                                                    </>
                                                )}
                                            />
                                        </FormikProvider>
                                    </TabPane>
                                    <TabPane tabId="3">
                                        <FormikProvider value={formikObj}>
                                            <Row className="mb-3">
                                                <Col lg={6}>
                                                    <Label className="col-form-label">
                                                        Target
                                                    </Label>
                                                    <Input
                                                        type="text"
                                                        id="formrow-InputService"
                                                        className="form-control"
                                                        name="day_three_target"
                                                        onChange={formikObj.handleChange}
                                                        value={formikObj.values.day_three_target}
                                                        placeholder="Enter Day Three Target"
                                                        invalid={
                                                            formikObj.touched.day_three_target && formikObj.errors.day_three_target ? true : false
                                                        }
                                                    />
                                                    {formikObj.touched.day_three_target && formikObj.errors.day_three_target ? (
                                                        <FormFeedback type="invalid">{formikObj.errors.day_three_target}</FormFeedback>
                                                    ) : null}
                                                </Col>
                                            </Row>
                                            <FieldArray
                                                name="workoutPlanDay3"
                                                render={arrayHelpersAdd => (
                                                    <>
                                                        {formikObj.values.workoutPlanDay3.map((data, index) => {
                                                            const isLastRow = (formikObj.values?.workoutPlanDay3?.length - 1) === index;
                                                            const getFieldName = name => `${arrayHelpersAdd.name}[${index}][${name}]`;
                                                            const getErrorMsg = name => (formikObj.errors.workoutPlanDay3?.[index]?.[name] && formikObj.touched.workoutPlanDay3?.[index]?.[name]
                                                                ? formikObj.errors.workoutPlanDay3?.[index]?.[name]
                                                                : ""
                                                            )

                                                            return (
                                                                <>

                                                                    <Row key={index} className="mb-3">
                                                                        <Col lg={6} className="mb-3 lg-mb-0">
                                                                            <Label className="col-form-label">
                                                                                Select Category <span className="text-danger">*</span>
                                                                            </Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="formrow-serviceType"
                                                                                className="form-control"
                                                                                name={getFieldName('workout_plan_category_id')}
                                                                                onChange={(e) => {
                                                                                    formikObj.handleChange(e)
                                                                                    const exerciseIdFieldName = getFieldName("workout_plan_exercise_id")
                                                                                    const exerciseOptionsFieldName = getFieldName("exercise_options")
                                                                                    const serviceLoadingFieldName = getFieldName('service_options_loading');
                                                                                    fetchExerciseByCategoryId(
                                                                                        e.target.value,
                                                                                        index,
                                                                                        exerciseIdFieldName,
                                                                                        exerciseOptionsFieldName,
                                                                                        serviceLoadingFieldName
                                                                                    )
                                                                                }}
                                                                                value={data.workout_plan_category_id}
                                                                                invalid={!!getErrorMsg('workout_plan_category_id')}
                                                                            >
                                                                                <option value="">Select</option>
                                                                                {workoutPlanCategory.map((item) => (
                                                                                    <option value={item.id} key={item.id}>{item.category_name}</option>
                                                                                ))}

                                                                            </Input>
                                                                            {getErrorMsg('workout_plan_category_id') && <FormFeedback type="invalid">{getErrorMsg('workout_plan_category_id')}</FormFeedback>}

                                                                        </Col>
                                                                        <Col lg={6}>
                                                                            <Label className="col-form-label">
                                                                                Select Exercise Name <span className="text-danger">*</span>
                                                                                {data.service_options_loading && <Spinner animation="border" size="sm" color="primary" />}
                                                                            </Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="formrow-serviceType"
                                                                                className="form-control"
                                                                                value={data.id}
                                                                                name={getFieldName("workout_plan_exercise_id")}
                                                                                onChange={(e) => {
                                                                                    formikObj.handleChange(e);
                                                                                    const findExerciseObj = data.exercise_options?.find(exercise => exercise.id == e.target.value);
                                                                                    formikObj.setFieldValue(getFieldName('exercise_type_id'), findExerciseObj?.exercise_type?.id);
                                                                                    // setTimeout(() => {
                                                                                    //     formikObj.setFieldValue('total_price', calculateTotalPrice())
                                                                                    // }, 1000)

                                                                                }}
                                                                            >
                                                                                <option value="">Select</option>
                                                                                {data.exercise_options?.map((item) => (
                                                                                    <option value={item.id} key={item.id}>{item.exercise_name}</option>
                                                                                ))}
                                                                            </Input>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className="mb-3">
                                                                        <Col lg={6} className="mb-3 mb-lg-0">
                                                                            <Label className="col-form-label">
                                                                                No. of SET
                                                                            </Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="formrow-InputService"
                                                                                className="form-control"
                                                                                name={getFieldName("no_of_set")}
                                                                                onChange={(e) => formikObj.handleChange(e)}
                                                                                value={data.no_of_set}
                                                                                placeholder="Enter No. of SET"
                                                                                invalid={!!getErrorMsg('no_of_set')}
                                                                            >
                                                                                <option value="">Select</option>
                                                                                {numberOfSet.map((item) => (
                                                                                    <option value={item} key={item}>{item}</option>
                                                                                ))}
                                                                            </Input>
                                                                            {getErrorMsg('no_of_set') && <FormFeedback type="invalid">{getErrorMsg('no_of_set')}</FormFeedback>}
                                                                        </Col>
                                                                        {formikObj.values.workoutPlanDay3[index].exercise_type_id == 1 ?
                                                                            <Col lg={6}>
                                                                                <Label className="col-form-label">
                                                                                    No. of REP/SET
                                                                                </Label>
                                                                                <Input
                                                                                    type="text"
                                                                                    id="formrow-InputService"
                                                                                    className="form-control"
                                                                                    name={getFieldName("no_of_rep")}
                                                                                    onChange={(e) => { handleRepSetChange(e) }}
                                                                                    value={data.no_of_rep}
                                                                                    placeholder="Enter No. of REP/SET"
                                                                                    invalid={!!getErrorMsg('no_of_rep')}
                                                                                />
                                                                                {getErrorMsg('no_of_rep') && <FormFeedback type="invalid">{getErrorMsg('no_of_rep')}</FormFeedback>}
                                                                            </Col>
                                                                            :
                                                                            <Col lg={6}>
                                                                                <Label className="col-form-label">
                                                                                    Duration
                                                                                </Label>
                                                                                <Row>
                                                                                    <Col col={6}>
                                                                                        <Input
                                                                                            type="text"
                                                                                            id="formrow-InputService"
                                                                                            className="form-control"
                                                                                            name={getFieldName("duration")}
                                                                                            onChange={(e) => { handleRepSetChange(e) }}
                                                                                            value={data.duration}
                                                                                            placeholder="Enter Duration"
                                                                                            invalid={!!getErrorMsg('duration')}
                                                                                        />
                                                                                        {getErrorMsg('duration') && <FormFeedback type="invalid">{getErrorMsg('duration')}</FormFeedback>}
                                                                                    </Col>
                                                                                    <Col col={6}>
                                                                                        <Input
                                                                                            type="select"
                                                                                            id="formrow-serviceType"
                                                                                            className="form-control"
                                                                                            value={data.id}
                                                                                            name={getFieldName("duration_type")}
                                                                                            onChange={(e) => {
                                                                                                formikObj.handleChange(e);
                                                                                            }}
                                                                                        >
                                                                                            <option value="">Select</option>
                                                                                            {data.duration_type_options?.map((item) => (
                                                                                                <option value={item.id} key={item.id}>{item.type}</option>
                                                                                            ))}
                                                                                        </Input>
                                                                                        {getErrorMsg('duration_type') && <FormFeedback type="invalid">{getErrorMsg('duration_type')}</FormFeedback>}
                                                                                    </Col>
                                                                                </Row>
                                                                                {/* <Input
                                                                                    type="text"
                                                                                    id="formrow-InputService"
                                                                                    className="form-control"
                                                                                    name={getFieldName("duration")}
                                                                                    onChange={formikObj.handleChange}
                                                                                    value={data.duration}
                                                                                    placeholder="Enter Duration"
                                                                                    invalid={!!getErrorMsg('duration')}
                                                                                /> */}
                                                                                {/* <Flatpickr
                                                                                    className="form-control d-block"
                                                                                    name={getFieldName("duration")}
                                                                                    options={{
                                                                                        noCalendar: true,
                                                                                        enableTime: true,
                                                                                        enableSeconds: true,
                                                                                        time_24hr: false,
                                                                                        dateFormat: 'i:s'
                                                                                    }}
                                                                                    onChange={(e, timeChange) => {
                                                                                        formikObj.setFieldValue(getFieldName("duration"), timeChange)
                                                                                    }}
                                                                                    value={data.duration}
                                                                                /> */}
                                                                            </Col>
                                                                        }
                                                                    </Row>
                                                                    <Row className="mb-3">
                                                                        <Col lg={12}>
                                                                            <Label className="col-form-label">
                                                                                Note
                                                                            </Label>
                                                                            <Input
                                                                                type="textarea"
                                                                                id="formrow-InputService"
                                                                                className="form-control"
                                                                                name={getFieldName("note")}
                                                                                onChange={(e) => formikObj.handleChange(e)}
                                                                                value={data.note}
                                                                                placeholder="Enter Note"
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                    <br />
                                                                    <Row className="mb-3">
                                                                        <Col lg={6}>
                                                                            {!isLastRow &&
                                                                                <div className="d-flex align-items-center">
                                                                                    <label
                                                                                        className="form-check-label"
                                                                                        htmlFor="customSwitchsizemd"
                                                                                    >
                                                                                        AND
                                                                                    </label>
                                                                                    <div className="form-check form-switch form-switch-md ms-1">
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            className="form-check-input"
                                                                                            id="customSwitchsizemd"
                                                                                            name={getFieldName("exercise_choice")}
                                                                                            onChange={(e) => formikObj.setFieldValue(getFieldName("exercise_choice"), 1)}
                                                                                        />
                                                                                    </div>
                                                                                    <label
                                                                                        className="form-check-label"
                                                                                        htmlFor="customSwitchsizemd"
                                                                                    >
                                                                                        OR
                                                                                    </label>
                                                                                </div>
                                                                            }
                                                                        </Col>
                                                                        <Col lg={6} className="d-flex align-items-center" style={{ justifyContent: "right" }}>
                                                                            <div className="">
                                                                                {formikObj.values?.workoutPlanDay3.length > 1 && <button
                                                                                    type="button"
                                                                                    className="btn-rounded  btn btn-danger ms-2 btn-sm"
                                                                                    onClick={() => arrayHelpersAdd.remove(index)}
                                                                                > <i className="bx bx-minus align-middle" />
                                                                                </button>
                                                                                }
                                                                            </div>
                                                                            <div className="">
                                                                                {/* {isLastRow &&  */}
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn-rounded  btn btn-success ms-2 btn-sm"
                                                                                    onClick={(e) => {
                                                                                        let arr = formikObj.values.workoutPlanDay3
                                                                                        arr.splice(index + 1, 0,
                                                                                            {
                                                                                                workout_plan_category_id: '',
                                                                                                workout_plan_exercise_id: '',
                                                                                                exercise_options: [],
                                                                                                service_options_loading: false,
                                                                                                no_of_set: '',
                                                                                                no_of_rep: '',
                                                                                                duration: '',
                                                                                                day: 3,
                                                                                                duration_type: '',
                                                                                                note: '',
                                                                                                exercise_choice: 0,
                                                                                                duration_type_options: [
                                                                                                    {
                                                                                                        id: 1,
                                                                                                        type: "min"
                                                                                                    },
                                                                                                    {
                                                                                                        id: 2,
                                                                                                        type: "sec"
                                                                                                    }
                                                                                                ]
                                                                                            })
                                                                                        formikObj.setFieldValue('workoutPlanDay3', arr)
                                                                                        // arrayHelpersAdd.push({
                                                                                        //     workout_plan_category_id: '',
                                                                                        //     workout_plan_exercise_id: '',
                                                                                        //     exercise_options: [],
                                                                                        //     service_options_loading: false,
                                                                                        //     no_of_set: '',
                                                                                        //     no_of_rep: '',
                                                                                        //     duration: '',
                                                                                        //     day: 3,
                                                                                        //     duration_type: '',
                                                                                        //     note: '',
                                                                                        //     exercise_choice: 0,
                                                                                        //     duration_type_options: [
                                                                                        //         {
                                                                                        //             id: 1,
                                                                                        //             type: "min"
                                                                                        //         },
                                                                                        //         {
                                                                                        //             id: 2,
                                                                                        //             type: "sec"
                                                                                        //         }
                                                                                        //     ]
                                                                                        // })
                                                                                    }
                                                                                    }>
                                                                                    <i className="bx bx-plus align-middle" />
                                                                                </button>
                                                                                {/* } */}
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </>
                                                            )
                                                        })}
                                                    </>
                                                )}
                                            />
                                        </FormikProvider>
                                    </TabPane>
                                    <TabPane tabId="4">
                                        <FormikProvider value={formikObj}>
                                            <Row className="mb-3">
                                                <Col lg={6}>
                                                    <Label className="col-form-label">
                                                        Target
                                                    </Label>
                                                    <Input
                                                        type="text"
                                                        id="formrow-InputService"
                                                        className="form-control"
                                                        name="day_four_target"
                                                        onChange={formikObj.handleChange}
                                                        value={formikObj.values.day_four_target}
                                                        placeholder="Enter Day Four Target"
                                                        invalid={
                                                            formikObj.touched.day_four_target && formikObj.errors.day_four_target ? true : false
                                                        }
                                                    />
                                                    {formikObj.touched.day_four_target && formikObj.errors.day_four_target ? (
                                                        <FormFeedback type="invalid">{formikObj.errors.day_four_target}</FormFeedback>
                                                    ) : null}
                                                </Col>
                                            </Row>
                                            <FieldArray
                                                name="workoutPlanDay4"
                                                render={arrayHelpersAdd => (
                                                    <>
                                                        {formikObj.values.workoutPlanDay4.map((data, index) => {
                                                            const isLastRow = (formikObj.values?.workoutPlanDay4?.length - 1) === index;
                                                            const getFieldName = name => `${arrayHelpersAdd.name}[${index}][${name}]`;
                                                            const getErrorMsg = name => (formikObj.errors.workoutPlanDay4?.[index]?.[name] && formikObj.touched.workoutPlanDay4?.[index]?.[name]
                                                                ? formikObj.errors.workoutPlanDay4?.[index]?.[name]
                                                                : ""
                                                            )

                                                            return (
                                                                <>

                                                                    <Row key={index} className="mb-3">
                                                                        <Col lg={6} className="mb-3 mb-lg-0">
                                                                            <Label className="col-form-label">
                                                                                Select Category <span className="text-danger">*</span>
                                                                            </Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="formrow-serviceType"
                                                                                className="form-control"
                                                                                name={getFieldName('workout_plan_category_id')}
                                                                                onChange={(e) => {
                                                                                    formikObj.handleChange(e)
                                                                                    const exerciseIdFieldName = getFieldName("workout_plan_exercise_id")
                                                                                    const exerciseOptionsFieldName = getFieldName("exercise_options")
                                                                                    const serviceLoadingFieldName = getFieldName('service_options_loading');
                                                                                    fetchExerciseByCategoryId(
                                                                                        e.target.value,
                                                                                        index,
                                                                                        exerciseIdFieldName,
                                                                                        exerciseOptionsFieldName,
                                                                                        serviceLoadingFieldName
                                                                                    )
                                                                                }}
                                                                                value={data.workout_plan_category_id}
                                                                                invalid={!!getErrorMsg('workout_plan_category_id')}
                                                                            >
                                                                                <option value="">Select</option>
                                                                                {workoutPlanCategory.map((item) => (
                                                                                    <option value={item.id} key={item.id}>{item.category_name}</option>
                                                                                ))}

                                                                            </Input>
                                                                            {getErrorMsg('workout_plan_category_id') && <FormFeedback type="invalid">{getErrorMsg('workout_plan_category_id')}</FormFeedback>}

                                                                        </Col>
                                                                        <Col lg={6}>
                                                                            <Label className="col-form-label">
                                                                                Select Exercise Name <span className="text-danger">*</span>
                                                                                {data.service_options_loading && <Spinner animation="border" size="sm" color="primary" />}
                                                                            </Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="formrow-serviceType"
                                                                                className="form-control"
                                                                                value={data.id}
                                                                                name={getFieldName("workout_plan_exercise_id")}
                                                                                onChange={(e) => {
                                                                                    formikObj.handleChange(e);
                                                                                    const findExerciseObj = data.exercise_options?.find(exercise => exercise.id == e.target.value);
                                                                                    formikObj.setFieldValue(getFieldName('exercise_type_id'), findExerciseObj?.exercise_type?.id);
                                                                                    // setTimeout(() => {
                                                                                    //     formikObj.setFieldValue('total_price', calculateTotalPrice())
                                                                                    // }, 1000)

                                                                                }}
                                                                            >
                                                                                <option value="">Select</option>
                                                                                {data.exercise_options?.map((item) => (
                                                                                    <option value={item.id} key={item.id}>{item.exercise_name}</option>
                                                                                ))}
                                                                            </Input>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className="mb-3">
                                                                        <Col lg={6} className="mb-3 mb-lg-0">
                                                                            <Label className="col-form-label">
                                                                                No. of SET
                                                                            </Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="formrow-InputService"
                                                                                className="form-control"
                                                                                name={getFieldName("no_of_set")}
                                                                                onChange={(e) => formikObj.handleChange(e)}
                                                                                value={data.no_of_set}
                                                                                placeholder="Enter No. of SET"
                                                                                invalid={!!getErrorMsg('no_of_set')}
                                                                            >
                                                                                <option value="">Select</option>
                                                                                {numberOfSet.map((item) => (
                                                                                    <option value={item} key={item}>{item}</option>
                                                                                ))}
                                                                            </Input>
                                                                            {getErrorMsg('no_of_set') && <FormFeedback type="invalid">{getErrorMsg('no_of_set')}</FormFeedback>}
                                                                        </Col>
                                                                        {formikObj.values.workoutPlanDay4[index].exercise_type_id == 1 ?
                                                                            <Col lg={6}>
                                                                                <Label className="col-form-label">
                                                                                    No. of REP/SET
                                                                                </Label>
                                                                                <Input
                                                                                    type="text"
                                                                                    id="formrow-InputService"
                                                                                    className="form-control"
                                                                                    name={getFieldName("no_of_rep")}
                                                                                    onChange={(e) => { handleRepSetChange(e) }}
                                                                                    value={data.no_of_rep}
                                                                                    placeholder="Enter No. of REP/SET"
                                                                                    invalid={!!getErrorMsg('no_of_rep')}
                                                                                />
                                                                                {getErrorMsg('no_of_rep') && <FormFeedback type="invalid">{getErrorMsg('no_of_rep')}</FormFeedback>}
                                                                            </Col>
                                                                            :
                                                                            <Col lg={6}>
                                                                                <Label className="col-form-label">
                                                                                    Duration
                                                                                </Label>
                                                                                <Row>
                                                                                    <Col col={6}>
                                                                                        <Input
                                                                                            type="text"
                                                                                            id="formrow-InputService"
                                                                                            className="form-control"
                                                                                            name={getFieldName("duration")}
                                                                                            onChange={(e) => { handleRepSetChange(e) }}
                                                                                            value={data.duration}
                                                                                            placeholder="Enter Duration"
                                                                                            invalid={!!getErrorMsg('duration')}
                                                                                        />
                                                                                        {getErrorMsg('duration') && <FormFeedback type="invalid">{getErrorMsg('duration')}</FormFeedback>}
                                                                                    </Col>
                                                                                    <Col col={6}>
                                                                                        <Input
                                                                                            type="select"
                                                                                            id="formrow-serviceType"
                                                                                            className="form-control"
                                                                                            value={data.id}
                                                                                            name={getFieldName("duration_type")}
                                                                                            onChange={(e) => {
                                                                                                formikObj.handleChange(e);
                                                                                            }}
                                                                                        >
                                                                                            <option value="">Select</option>
                                                                                            {data.duration_type_options?.map((item) => (
                                                                                                <option value={item.id} key={item.id}>{item.type}</option>
                                                                                            ))}
                                                                                        </Input>
                                                                                        {getErrorMsg('duration_type') && <FormFeedback type="invalid">{getErrorMsg('duration_type')}</FormFeedback>}
                                                                                    </Col>
                                                                                </Row>
                                                                                {/* <Input
                                                                                    type="text"
                                                                                    id="formrow-InputService"
                                                                                    className="form-control"
                                                                                    name={getFieldName("duration")}
                                                                                    onChange={formikObj.handleChange}
                                                                                    value={data.duration}
                                                                                    placeholder="Enter Duration"
                                                                                    invalid={!!getErrorMsg('duration')}
                                                                                /> */}
                                                                                {/* <Flatpickr
                                                                                    className="form-control d-block"
                                                                                    name={getFieldName("duration")}
                                                                                    options={{
                                                                                        noCalendar: true,
                                                                                        enableTime: true,
                                                                                        enableSeconds: true,
                                                                                        time_24hr: false,
                                                                                        dateFormat: 'i:s'
                                                                                    }}
                                                                                    onChange={(e, timeChange) => {
                                                                                        formikObj.setFieldValue(getFieldName("duration"), timeChange)
                                                                                    }}
                                                                                    value={data.duration}
                                                                                /> */}
                                                                            </Col>
                                                                        }
                                                                    </Row>
                                                                    <Row className="mb-3">
                                                                        <Col lg={12}>
                                                                            <Label className="col-form-label">
                                                                                Note
                                                                            </Label>
                                                                            <Input
                                                                                type="textarea"
                                                                                id="formrow-InputService"
                                                                                className="form-control"
                                                                                name={getFieldName("note")}
                                                                                onChange={(e) => formikObj.handleChange(e)}
                                                                                value={data.note}
                                                                                placeholder="Enter Note"
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                    <br />
                                                                    <Row className="mb-3">
                                                                        <Col lg={6}>
                                                                            {!isLastRow &&
                                                                                <div className="d-flex align-items-center">
                                                                                    <label
                                                                                        className="form-check-label"
                                                                                        htmlFor="customSwitchsizemd"
                                                                                    >
                                                                                        AND
                                                                                    </label>
                                                                                    <div className="form-check form-switch form-switch-md ms-1">
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            className="form-check-input"
                                                                                            id="customSwitchsizemd"
                                                                                            name={getFieldName("exercise_choice")}
                                                                                            onChange={(e) => formikObj.setFieldValue(getFieldName("exercise_choice"), 1)}
                                                                                        />
                                                                                    </div>
                                                                                    <label
                                                                                        className="form-check-label"
                                                                                        htmlFor="customSwitchsizemd"
                                                                                    >
                                                                                        OR
                                                                                    </label>
                                                                                </div>
                                                                            }
                                                                        </Col>
                                                                        <Col lg={6} className="d-flex align-items-center" style={{ justifyContent: "right" }}>
                                                                            <div className="">
                                                                                {formikObj.values?.workoutPlanDay4.length > 1 && <button
                                                                                    type="button"
                                                                                    className="btn-rounded  btn btn-danger ms-2 btn-sm"
                                                                                    onClick={() => arrayHelpersAdd.remove(index)}
                                                                                > <i className="bx bx-minus align-middle" />
                                                                                </button>
                                                                                }
                                                                            </div>
                                                                            <div className="">
                                                                                {/* {isLastRow &&  */}
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn-rounded  btn btn-success ms-2 btn-sm"
                                                                                    onClick={(e) => {
                                                                                        let arr = formikObj.values.workoutPlanDay4
                                                                                        arr.splice(index + 1, 0,
                                                                                            {
                                                                                                workout_plan_category_id: '',
                                                                                                workout_plan_exercise_id: '',
                                                                                                exercise_options: [],
                                                                                                service_options_loading: false,
                                                                                                no_of_set: '',
                                                                                                no_of_rep: '',
                                                                                                duration: '',
                                                                                                day: 4,
                                                                                                duration_type: '',
                                                                                                note: '',
                                                                                                exercise_choice: 0,
                                                                                                duration_type_options: [
                                                                                                    {
                                                                                                        id: 1,
                                                                                                        type: "min"
                                                                                                    },
                                                                                                    {
                                                                                                        id: 2,
                                                                                                        type: "sec"
                                                                                                    }
                                                                                                ]
                                                                                            })
                                                                                        formikObj.setFieldValue('workoutPlanDay4', arr)
                                                                                        // arrayHelpersAdd.push({
                                                                                        //     workout_plan_category_id: '',
                                                                                        //     workout_plan_exercise_id: '',
                                                                                        //     exercise_options: [],
                                                                                        //     service_options_loading: false,
                                                                                        //     no_of_set: '',
                                                                                        //     no_of_rep: '',
                                                                                        //     duration: '',
                                                                                        //     day: 4,
                                                                                        //     duration_type: '',
                                                                                        //     note: '',
                                                                                        //     exercise_choice: 0,
                                                                                        //     duration_type_options: [
                                                                                        //         {
                                                                                        //             id: 1,
                                                                                        //             type: "min"
                                                                                        //         },
                                                                                        //         {
                                                                                        //             id: 2,
                                                                                        //             type: "sec"
                                                                                        //         }
                                                                                        //     ]
                                                                                        // })
                                                                                    }
                                                                                    }>
                                                                                    <i className="bx bx-plus align-middle" />
                                                                                </button>
                                                                                {/* } */}
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </>
                                                            )
                                                        })}
                                                    </>
                                                )}
                                            />
                                        </FormikProvider>
                                    </TabPane>
                                    <TabPane tabId="5">
                                        <FormikProvider value={formikObj}>
                                            <Row className="mb-3">
                                                <Col lg={6}>
                                                    <Label className="col-form-label">
                                                        Target
                                                    </Label>
                                                    <Input
                                                        type="text"
                                                        id="formrow-InputService"
                                                        className="form-control"
                                                        name="day_five_target"
                                                        onChange={formikObj.handleChange}
                                                        value={formikObj.values.day_five_target}
                                                        placeholder="Enter Day Five Target"
                                                        invalid={
                                                            formikObj.touched.day_five_target && formikObj.errors.day_five_target ? true : false
                                                        }
                                                    />
                                                    {formikObj.touched.day_five_target && formikObj.errors.day_five_target ? (
                                                        <FormFeedback type="invalid">{formikObj.errors.day_five_target}</FormFeedback>
                                                    ) : null}
                                                </Col>
                                            </Row>
                                            <FieldArray
                                                name="workoutPlanDay5"
                                                render={arrayHelpersAdd => (
                                                    <>
                                                        {formikObj.values.workoutPlanDay5.map((data, index) => {
                                                            const isLastRow = (formikObj.values?.workoutPlanDay5?.length - 1) === index;
                                                            const getFieldName = name => `${arrayHelpersAdd.name}[${index}][${name}]`;
                                                            const getErrorMsg = name => (formikObj.errors.workoutPlanDay5?.[index]?.[name] && formikObj.touched.workoutPlanDay5?.[index]?.[name]
                                                                ? formikObj.errors.workoutPlanDay5?.[index]?.[name]
                                                                : ""
                                                            )

                                                            return (
                                                                <>

                                                                    <Row key={index} className="mb-3">
                                                                        <Col lg={6} className="mb-3 mb-lg-0">
                                                                            <Label className="col-form-label">
                                                                                Select Category <span className="text-danger">*</span>
                                                                            </Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="formrow-serviceType"
                                                                                className="form-control"
                                                                                name={getFieldName('workout_plan_category_id')}
                                                                                onChange={(e) => {
                                                                                    formikObj.handleChange(e)
                                                                                    const exerciseIdFieldName = getFieldName("workout_plan_exercise_id")
                                                                                    const exerciseOptionsFieldName = getFieldName("exercise_options")
                                                                                    const serviceLoadingFieldName = getFieldName('service_options_loading');
                                                                                    fetchExerciseByCategoryId(
                                                                                        e.target.value,
                                                                                        index,
                                                                                        exerciseIdFieldName,
                                                                                        exerciseOptionsFieldName,
                                                                                        serviceLoadingFieldName
                                                                                    )
                                                                                }}
                                                                                value={data.workout_plan_category_id}
                                                                                invalid={!!getErrorMsg('workout_plan_category_id')}
                                                                            >
                                                                                <option value="">Select</option>
                                                                                {workoutPlanCategory.map((item) => (
                                                                                    <option value={item.id} key={item.id}>{item.category_name}</option>
                                                                                ))}

                                                                            </Input>
                                                                            {getErrorMsg('workout_plan_category_id') && <FormFeedback type="invalid">{getErrorMsg('workout_plan_category_id')}</FormFeedback>}

                                                                        </Col>
                                                                        <Col lg={6}>
                                                                            <Label className="col-form-label">
                                                                                Select Exercise Name <span className="text-danger">*</span>
                                                                                {data.service_options_loading && <Spinner animation="border" size="sm" color="primary" />}
                                                                            </Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="formrow-serviceType"
                                                                                className="form-control"
                                                                                value={data.id}
                                                                                name={getFieldName("workout_plan_exercise_id")}
                                                                                onChange={(e) => {
                                                                                    formikObj.handleChange(e);
                                                                                    const findExerciseObj = data.exercise_options?.find(exercise => exercise.id == e.target.value);
                                                                                    formikObj.setFieldValue(getFieldName('exercise_type_id'), findExerciseObj?.exercise_type?.id);
                                                                                    // setTimeout(() => {
                                                                                    //     formikObj.setFieldValue('total_price', calculateTotalPrice())
                                                                                    // }, 1000)

                                                                                }}
                                                                            >
                                                                                <option value="">Select</option>
                                                                                {data.exercise_options?.map((item) => (
                                                                                    <option value={item.id} key={item.id}>{item.exercise_name}</option>
                                                                                ))}
                                                                            </Input>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className="mb-3">
                                                                        <Col lg={6} className="mb-3 mb-lg-0">
                                                                            <Label className="col-form-label">
                                                                                No. of SET
                                                                            </Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="formrow-InputService"
                                                                                className="form-control"
                                                                                name={getFieldName("no_of_set")}
                                                                                onChange={(e) => formikObj.handleChange(e)}
                                                                                value={data.no_of_set}
                                                                                placeholder="Enter No. of SET"
                                                                                invalid={!!getErrorMsg('no_of_set')}
                                                                            >
                                                                                <option value="">Select</option>
                                                                                {numberOfSet.map((item) => (
                                                                                    <option value={item} key={item}>{item}</option>
                                                                                ))}
                                                                            </Input>
                                                                            {getErrorMsg('no_of_set') && <FormFeedback type="invalid">{getErrorMsg('no_of_set')}</FormFeedback>}
                                                                        </Col>
                                                                        {formikObj.values.workoutPlanDay5[index].exercise_type_id == 1 ?
                                                                            <Col lg={6}>
                                                                                <Label className="col-form-label">
                                                                                    No. of REP/SET
                                                                                </Label>
                                                                                <Input
                                                                                    type="text"
                                                                                    id="formrow-InputService"
                                                                                    className="form-control"
                                                                                    name={getFieldName("no_of_rep")}
                                                                                    onChange={(e) => { handleRepSetChange(e) }}
                                                                                    value={data.no_of_rep}
                                                                                    placeholder="Enter No. of REP/SET"
                                                                                    invalid={!!getErrorMsg('no_of_rep')}
                                                                                />
                                                                                {getErrorMsg('no_of_rep') && <FormFeedback type="invalid">{getErrorMsg('no_of_rep')}</FormFeedback>}
                                                                            </Col>
                                                                            :
                                                                            <Col lg={6}>
                                                                                <Label className="col-form-label">
                                                                                    Duration
                                                                                </Label>
                                                                                <Row>
                                                                                    <Col col={6}>
                                                                                        <Input
                                                                                            type="text"
                                                                                            id="formrow-InputService"
                                                                                            className="form-control"
                                                                                            name={getFieldName("duration")}
                                                                                            onChange={(e) => { handleRepSetChange(e) }}
                                                                                            value={data.duration}
                                                                                            placeholder="Enter Duration"
                                                                                            invalid={!!getErrorMsg('duration')}
                                                                                        />
                                                                                        {getErrorMsg('duration') && <FormFeedback type="invalid">{getErrorMsg('duration')}</FormFeedback>}
                                                                                    </Col>
                                                                                    <Col col={6}>
                                                                                        <Input
                                                                                            type="select"
                                                                                            id="formrow-serviceType"
                                                                                            className="form-control"
                                                                                            value={data.id}
                                                                                            name={getFieldName("duration_type")}
                                                                                            onChange={(e) => {
                                                                                                formikObj.handleChange(e);
                                                                                            }}
                                                                                        >
                                                                                            <option value="">Select</option>
                                                                                            {data.duration_type_options?.map((item) => (
                                                                                                <option value={item.id} key={item.id}>{item.type}</option>
                                                                                            ))}
                                                                                        </Input>
                                                                                        {getErrorMsg('duration_type') && <FormFeedback type="invalid">{getErrorMsg('duration_type')}</FormFeedback>}
                                                                                    </Col>
                                                                                </Row>
                                                                            </Col>
                                                                        }
                                                                    </Row>
                                                                    <Row className="mb-3">
                                                                        <Col lg={12}>
                                                                            <Label className="col-form-label">
                                                                                Note
                                                                            </Label>
                                                                            <Input
                                                                                type="textarea"
                                                                                id="formrow-InputService"
                                                                                className="form-control"
                                                                                name={getFieldName("note")}
                                                                                onChange={(e) => formikObj.handleChange(e)}
                                                                                value={data.note}
                                                                                placeholder="Enter Note"
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                    <br />
                                                                    <Row className="mb-3">
                                                                        <Col lg={6}>
                                                                            {!isLastRow &&
                                                                                <div className="d-flex align-items-center">
                                                                                    <label
                                                                                        className="form-check-label"
                                                                                        htmlFor="customSwitchsizemd"
                                                                                    >
                                                                                        AND
                                                                                    </label>
                                                                                    <div className="form-check form-switch form-switch-md ms-1">
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            className="form-check-input"
                                                                                            id="customSwitchsizemd"
                                                                                            name={getFieldName("exercise_choice")}
                                                                                            onChange={(e) => formikObj.setFieldValue(getFieldName("exercise_choice"), 1)}
                                                                                        />
                                                                                    </div>
                                                                                    <label
                                                                                        className="form-check-label"
                                                                                        htmlFor="customSwitchsizemd"
                                                                                    >
                                                                                        OR
                                                                                    </label>
                                                                                </div>
                                                                            }
                                                                        </Col>
                                                                        <Col lg={6} className="d-flex align-items-center" style={{ justifyContent: "right" }}>
                                                                            <div className="">
                                                                                {formikObj.values?.workoutPlanDay5.length > 1 && <button
                                                                                    type="button"
                                                                                    className="btn-rounded  btn btn-danger ms-2 btn-sm"
                                                                                    onClick={() => arrayHelpersAdd.remove(index)}
                                                                                > <i className="bx bx-minus align-middle" />
                                                                                </button>
                                                                                }
                                                                            </div>
                                                                            <div className="">
                                                                                {/* {isLastRow &&  */}
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn-rounded  btn btn-success ms-2 btn-sm"
                                                                                    onClick={(e) => {
                                                                                        let arr = formikObj.values.workoutPlanDay5
                                                                                        arr.splice(index + 1, 0,
                                                                                            {
                                                                                                workout_plan_category_id: '',
                                                                                                workout_plan_exercise_id: '',
                                                                                                exercise_options: [],
                                                                                                service_options_loading: false,
                                                                                                no_of_set: '',
                                                                                                no_of_rep: '',
                                                                                                duration: '',
                                                                                                day: 5,
                                                                                                duration_type: '',
                                                                                                note: '',
                                                                                                exercise_choice: 0,
                                                                                                duration_type_options: [
                                                                                                    {
                                                                                                        id: 1,
                                                                                                        type: "min"
                                                                                                    },
                                                                                                    {
                                                                                                        id: 2,
                                                                                                        type: "sec"
                                                                                                    }
                                                                                                ]
                                                                                            })
                                                                                        formikObj.setFieldValue('workoutPlanDay5', arr)
                                                                                        // arrayHelpersAdd.push({
                                                                                        //     workout_plan_category_id: '',
                                                                                        //     workout_plan_exercise_id: '',
                                                                                        //     exercise_options: [],
                                                                                        //     service_options_loading: false,
                                                                                        //     no_of_set: '',
                                                                                        //     no_of_rep: '',
                                                                                        //     duration: '',
                                                                                        //     day: 5,
                                                                                        //     duration_type: '',
                                                                                        //     note: '',
                                                                                        //     exercise_choice: 0,
                                                                                        //     duration_type_options: [
                                                                                        //         {
                                                                                        //             id: 1,
                                                                                        //             type: "min"
                                                                                        //         },
                                                                                        //         {
                                                                                        //             id: 2,
                                                                                        //             type: "sec"
                                                                                        //         }
                                                                                        //     ]
                                                                                        // })
                                                                                    }
                                                                                    }>
                                                                                    <i className="bx bx-plus align-middle" />
                                                                                </button>
                                                                                {/* } */}
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </>
                                                            )
                                                        })}
                                                    </>
                                                )}
                                            />
                                        </FormikProvider>
                                    </TabPane>
                                    <TabPane tabId="6">
                                        <FormikProvider value={formikObj}>
                                            <Row className="mb-3">
                                                <Col lg={6}>
                                                    <Label className="col-form-label">
                                                        Target
                                                    </Label>
                                                    <Input
                                                        type="text"
                                                        id="formrow-InputService"
                                                        className="form-control"
                                                        name="day_six_target"
                                                        onChange={formikObj.handleChange}
                                                        value={formikObj.values.day_six_target}
                                                        placeholder="Enter Day Six Target"
                                                        invalid={
                                                            formikObj.touched.day_six_target && formikObj.errors.day_six_target ? true : false
                                                        }
                                                    />
                                                    {formikObj.touched.day_six_target && formikObj.errors.day_six_target ? (
                                                        <FormFeedback type="invalid">{formikObj.errors.day_six_target}</FormFeedback>
                                                    ) : null}
                                                </Col>
                                            </Row>
                                            <FieldArray
                                                name="workoutPlanDay6"
                                                render={arrayHelpersAdd => (
                                                    <>
                                                        {formikObj.values.workoutPlanDay6.map((data, index) => {
                                                            const isLastRow = (formikObj.values?.workoutPlanDay6?.length - 1) === index;
                                                            const getFieldName = name => `${arrayHelpersAdd.name}[${index}][${name}]`;
                                                            const getErrorMsg = name => (formikObj.errors.workoutPlanDay6?.[index]?.[name] && formikObj.touched.workoutPlanDay6?.[index]?.[name]
                                                                ? formikObj.errors.workoutPlanDay6?.[index]?.[name]
                                                                : ""
                                                            )

                                                            return (
                                                                <>

                                                                    <Row key={index} className="mb-3">
                                                                        <Col lg={6} className="mb-3 mb-lg-0">
                                                                            <Label className="col-form-label">
                                                                                Select Category <span className="text-danger">*</span>
                                                                            </Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="formrow-serviceType"
                                                                                className="form-control"
                                                                                name={getFieldName('workout_plan_category_id')}
                                                                                onChange={(e) => {
                                                                                    formikObj.handleChange(e)
                                                                                    const exerciseIdFieldName = getFieldName("workout_plan_exercise_id")
                                                                                    const exerciseOptionsFieldName = getFieldName("exercise_options")
                                                                                    const serviceLoadingFieldName = getFieldName('service_options_loading');
                                                                                    fetchExerciseByCategoryId(
                                                                                        e.target.value,
                                                                                        index,
                                                                                        exerciseIdFieldName,
                                                                                        exerciseOptionsFieldName,
                                                                                        serviceLoadingFieldName
                                                                                    )
                                                                                }}
                                                                                value={data.workout_plan_category_id}
                                                                                invalid={!!getErrorMsg('workout_plan_category_id')}
                                                                            >
                                                                                <option value="">Select</option>
                                                                                {workoutPlanCategory.map((item) => (
                                                                                    <option value={item.id} key={item.id}>{item.category_name}</option>
                                                                                ))}

                                                                            </Input>
                                                                            {getErrorMsg('workout_plan_category_id') && <FormFeedback type="invalid">{getErrorMsg('workout_plan_category_id')}</FormFeedback>}

                                                                        </Col>
                                                                        <Col lg={6}>
                                                                            <Label className="col-form-label">
                                                                                Select Exercise Name <span className="text-danger">*</span>
                                                                                {data.service_options_loading && <Spinner animation="border" size="sm" color="primary" />}
                                                                            </Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="formrow-serviceType"
                                                                                className="form-control"
                                                                                value={data.id}
                                                                                name={getFieldName("workout_plan_exercise_id")}
                                                                                onChange={(e) => {
                                                                                    formikObj.handleChange(e);
                                                                                    const findExerciseObj = data.exercise_options?.find(exercise => exercise.id == e.target.value);
                                                                                    formikObj.setFieldValue(getFieldName('exercise_type_id'), findExerciseObj?.exercise_type?.id);
                                                                                    // setTimeout(() => {
                                                                                    //     formikObj.setFieldValue('total_price', calculateTotalPrice())
                                                                                    // }, 1000)

                                                                                }}
                                                                            >
                                                                                <option value="">Select</option>
                                                                                {data.exercise_options?.map((item) => (
                                                                                    <option value={item.id} key={item.id}>{item.exercise_name}</option>
                                                                                ))}
                                                                            </Input>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className="mb-3">
                                                                        <Col lg={6} className="mb-3 mb-lg-0">
                                                                            <Label className="col-form-label">
                                                                                No. of SET
                                                                            </Label>
                                                                            <Input
                                                                                type="select"
                                                                                id="formrow-InputService"
                                                                                className="form-control"
                                                                                name={getFieldName("no_of_set")}
                                                                                onChange={(e) => formikObj.handleChange(e)}
                                                                                value={data.no_of_set}
                                                                                placeholder="Enter No. of SET"
                                                                                invalid={!!getErrorMsg('no_of_set')}
                                                                            >
                                                                                <option value="">Select</option>
                                                                                {numberOfSet.map((item) => (
                                                                                    <option value={item} key={item}>{item}</option>
                                                                                ))}
                                                                            </Input>
                                                                            {getErrorMsg('no_of_set') && <FormFeedback type="invalid">{getErrorMsg('no_of_set')}</FormFeedback>}
                                                                        </Col>
                                                                        {formikObj.values.workoutPlanDay6[index].exercise_type_id == 1 ?
                                                                            <Col lg={6}>
                                                                                <Label className="col-form-label">
                                                                                    No. of REP/SET
                                                                                </Label>
                                                                                <Input
                                                                                    type="text"
                                                                                    id="formrow-InputService"
                                                                                    className="form-control"
                                                                                    name={getFieldName("no_of_rep")}
                                                                                    onChange={(e) => { handleRepSetChange(e) }}
                                                                                    value={data.no_of_rep}
                                                                                    placeholder="Enter No. of REP/SET"
                                                                                    invalid={!!getErrorMsg('no_of_rep')}
                                                                                />
                                                                                {getErrorMsg('no_of_rep') && <FormFeedback type="invalid">{getErrorMsg('no_of_rep')}</FormFeedback>}
                                                                            </Col>
                                                                            :
                                                                            <Col lg={6}>
                                                                                <Label className="col-form-label">
                                                                                    Duration
                                                                                </Label>
                                                                                <Row>
                                                                                    <Col col={6}>
                                                                                        <Input
                                                                                            type="text"
                                                                                            id="formrow-InputService"
                                                                                            className="form-control"
                                                                                            name={getFieldName("duration")}
                                                                                            onChange={(e) => { handleRepSetChange(e) }}
                                                                                            value={data.duration}
                                                                                            placeholder="Enter Duration"
                                                                                            invalid={!!getErrorMsg('duration')}
                                                                                        />
                                                                                        {getErrorMsg('duration') && <FormFeedback type="invalid">{getErrorMsg('duration')}</FormFeedback>}
                                                                                    </Col>
                                                                                    <Col col={6}>
                                                                                        <Input
                                                                                            type="select"
                                                                                            id="formrow-serviceType"
                                                                                            className="form-control"
                                                                                            value={data.id}
                                                                                            name={getFieldName("duration_type")}
                                                                                            onChange={(e) => {
                                                                                                formikObj.handleChange(e);
                                                                                            }}
                                                                                        >
                                                                                            <option value="">Select</option>
                                                                                            {data.duration_type_options?.map((item) => (
                                                                                                <option value={item.id} key={item.id}>{item.type}</option>
                                                                                            ))}
                                                                                        </Input>
                                                                                        {getErrorMsg('duration_type') && <FormFeedback type="invalid">{getErrorMsg('duration_type')}</FormFeedback>}
                                                                                    </Col>
                                                                                </Row>
                                                                            </Col>
                                                                        }
                                                                    </Row>
                                                                    <Row className="mb-3">
                                                                        <Col lg={12}>
                                                                            <Label className="col-form-label">
                                                                                Note
                                                                            </Label>
                                                                            <Input
                                                                                type="textarea"
                                                                                id="formrow-InputService"
                                                                                className="form-control"
                                                                                name={getFieldName("note")}
                                                                                onChange={(e) => formikObj.handleChange(e)}
                                                                                value={data.note}
                                                                                placeholder="Enter Note"
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                    <br />
                                                                    <Row className="mb-3">
                                                                        <Col lg={6}>
                                                                            {!isLastRow &&
                                                                                <div className="d-flex align-items-center">
                                                                                    <label
                                                                                        className="form-check-label"
                                                                                        htmlFor="customSwitchsizemd"
                                                                                    >
                                                                                        AND
                                                                                    </label>
                                                                                    <div className="form-check form-switch form-switch-md ms-1">
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            className="form-check-input"
                                                                                            id="customSwitchsizemd"
                                                                                            name={getFieldName("exercise_choice")}
                                                                                            onChange={(e) => formikObj.setFieldValue(getFieldName("exercise_choice"), 1)}
                                                                                        />
                                                                                    </div>
                                                                                    <label
                                                                                        className="form-check-label"
                                                                                        htmlFor="customSwitchsizemd"
                                                                                    >
                                                                                        OR
                                                                                    </label>
                                                                                </div>
                                                                            }
                                                                        </Col>
                                                                        <Col lg={6} className="d-flex align-items-center" style={{ justifyContent: "right" }}>
                                                                            <div className="">
                                                                                {formikObj.values?.workoutPlanDay6.length > 1 && <button
                                                                                    type="button"
                                                                                    className="btn-rounded  btn btn-danger mx-2 btn-sm"
                                                                                    onClick={() => arrayHelpersAdd.remove(index)}
                                                                                > <i className="bx bx-minus align-middle" />
                                                                                </button>
                                                                                }
                                                                            </div>
                                                                            <div className="">
                                                                                {/* {isLastRow &&  */}
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn-rounded  btn btn-success ms-2 btn-sm"
                                                                                    onClick={(e) => {
                                                                                        let arr = formikObj.values.workoutPlanDay6
                                                                                        arr.splice(index + 1, 0,
                                                                                            {
                                                                                                workout_plan_category_id: '',
                                                                                                workout_plan_exercise_id: '',
                                                                                                exercise_options: [],
                                                                                                service_options_loading: false,
                                                                                                no_of_set: '',
                                                                                                no_of_rep: '',
                                                                                                duration: '',
                                                                                                day: 6,
                                                                                                duration_type: '',
                                                                                                note: '',
                                                                                                exercise_choice: 0,
                                                                                                duration_type_options: [
                                                                                                    {
                                                                                                        id: 1,
                                                                                                        type: "min"
                                                                                                    },
                                                                                                    {
                                                                                                        id: 2,
                                                                                                        type: "sec"
                                                                                                    }
                                                                                                ]
                                                                                            })
                                                                                        formikObj.setFieldValue('workoutPlanDay6', arr)
                                                                                        // arrayHelpersAdd.push({
                                                                                        //     workout_plan_category_id: '',
                                                                                        //     workout_plan_exercise_id: '',
                                                                                        //     exercise_options: [],
                                                                                        //     service_options_loading: false,
                                                                                        //     no_of_set: '',
                                                                                        //     no_of_rep: '',
                                                                                        //     duration: '',
                                                                                        //     day: 6,
                                                                                        //     duration_type: '',
                                                                                        //     note: '',
                                                                                        //     exercise_choice: 0,
                                                                                        //     duration_type_options: [
                                                                                        //         {
                                                                                        //             id: 1,
                                                                                        //             type: "min"
                                                                                        //         },
                                                                                        //         {
                                                                                        //             id: 2,
                                                                                        //             type: "sec"
                                                                                        //         }
                                                                                        //     ]
                                                                                        // })
                                                                                    }
                                                                                    }>
                                                                                    <i className="bx bx-plus align-middle" />
                                                                                </button>
                                                                                {/* } */}
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </>
                                                            )
                                                        })}
                                                    </>
                                                )}
                                            />
                                        </FormikProvider>
                                    </TabPane>
                                    <br /><br />
                                    <div className="align-items-center" style={{ textAlign: "center" }}>
                                        {!!isSaving ?
                                            <button className="btn btn-primary " disabled>
                                                Processing
                                            </button>
                                            :
                                            <button
                                                type="submit"
                                                className="btn btn-primary "
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    formikObj.handleSubmit();
                                                    return false;
                                                }}
                                            >
                                                SAVE
                                            </button>
                                        }
                                    </div>
                                </TabContent>
                            </div>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );

}

export default withRouter(AddWorkoutPlan);

AddWorkoutPlan.propTypes = {
    history: PropTypes.object,
};