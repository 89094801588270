import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useEffect, useState } from "react";
import {
    Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label, Button, Modal,
    ModalBody,
    ModalHeader,
    InputGroup,
    CardTitle,
    CardText,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Spinner,
    Collapse,
    CardHeader,
    Table
} from "reactstrap";
import { withRouter, Link, useLocation } from "react-router-dom";
import classnames from "classnames";
import Flatpickr from "react-flatpickr";
// Formik formikObj   
import * as Yup from "yup";
import { useFormik, FieldArray, FormikProvider } from "formik";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { standartDateFormat, today } from "utils/date";
import { axiosInstance, apiUrls } from "api";
import swal from 'sweetalert';
import { graphql } from "api";

// datatable related plugins

import CommonTable from "components/Common/CommonTable";
import usePaginator from "hooks/usePaginator";
import * as ExerciseQry from "GraphQLquery/exerciseQry";
import * as MemberQry from "GraphQLquery/memberQry";
import * as SubscriptionQry from "GraphQLquery/subscriptionQry";
import images from "assets/images";
import moment from "moment";
import EditMember from "../Common/EditMember";
import { useSelector } from "react-redux";
import AssignMemberWorkoutPlan from "pages/Common/AssignMemberWorkoutPlan";
import AssignMemberDietChart from "pages/Common/AssignMemberDietChart";
import AddEditBodyComposition from "pages/Common/AddEditBodyComposition";
import AssignTrainerToMember from "pages/Common/AssignTrainerToMember";
import EditMembership from "pages/Common/EditMembership";
import AddEditAccessory from "pages/Common/AddEditAccessory";
import Activity from "pages/Common/Activity";
import * as MembershipQry from "GraphQLquery/membershipQry";
import Invoice from "pages/Common/Invoice";
import { FILETYPE, checkFileType } from "utils/fileType";

const initialMemberData = {
    member_name: "",
    member_age: "",
    profession: "",
    gender: "",
    // (1-Male /2-Female/3-transgender)
    address: "",
    mobile_no: "",
    email_id: "",
    member_access_code: "",
    status: 1,
    membership_details: []
}

const imageURL = process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_MEMBER_PROFILE_IMAGE;
const idProofURL = process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_MEMBER_ID_PROOF;

let member_id, tabType;

const MemberDetails = props => {

    // member_id = !!(props.location.state) ? props.location.state?.member_id : props
    // tabType = !!(props.location.state) ? props.location.state?.tabType : props
    const activity = !!(props.location.state) ? props.location.state?.activity : props
    const location = useLocation();

    const memberUpdated = useSelector(state => state.MemberUpdated.memberUpdateStatus);
    const membershipUpdated = useSelector(state => state.MembershipUpdated.membershipUpdateStatus);
    const workoutAssigned = useSelector(state => state.WorkoutAssignedToMember.workoutAssignedStatus)
    const dietAssigned = useSelector(state => state.DietAssignedToMember.dietAssignedStatus)
    const dietAppointment = useSelector(state => state.DietBookedAppointment.dietAppointmentBookingStatus)
    const viewAppointment = useSelector(state => state.ViewBookedAppointment.dietAppointmentViewingStatus)
    const bodyCompositionCreated = useSelector(state => state.BodyCompositionCreated.bodyCompositionCreatedStatus)

    const trainerAssigned = useSelector(state => state.TrainerAssignedToMember.trainerAssignedStatus)

    const [customActiveTab, setcustomActiveTab] = useState(tabType);
    const [activeTab, setactiveTab] = useState("1");
    const [error, setError] = useState(null);
    const [paginatorInfo, setPaginatorInfo, resetPaginatorInfo] = usePaginator();
    const [searchText, setSearchText] = useState('');

    const [memberData, setMemberData] = useState({ ...initialMemberData })
    const [gender, setGender] = useState("")
    const [colIndex, setColIndex] = useState(0);
    const [editMemberModal, setEditMemberModal] = useState(false)
    const [editMemberShipModal, setEditMemberShipModal] = useState(false)
    const [assignWorkoutModal, setAssignWorkoutModal] = useState(false)
    const [bodyCompositionModal, setBodyCompositionModal] = useState(false)
    const [viewDietAppointmentModal, setViewDietAppointmentModal] = useState(false)
    const [bookDietAppointmentModal, setBookDietAppointmentModal] = useState(false)
    const [assignDietModal, setAssignDietModal] = useState(false)
    const [assignTrainerModal, setAssignTrainerModal] = useState(false)
    const [membershipId, setMembershipId] = useState('')
    const [appointmentListData, setAppointmentListData] = useState(false)
    const [processingAppointmentList, setProcessingAppointmentList] = useState(false)
    const [dietChartStatus, setDietChartStatus] = useState(false)
    const [invoiceData, setInvoiceData] = useState('')
    const [invoiceModalOpen, setInvoiceModalOpen] = useState(false);

    let colCount = []
    // let memberAccessories = []

    useEffect(() => {
        if (!!(props.location.state)) {
            member_id = props.location.state?.member_id;
            tabType = props.location.state?.tabType;
        }
        else {
            const searchParams = new URLSearchParams(location.search);
            member_id = searchParams.get('member_id');
            tabType = searchParams.get('tabType');
        }
        setcustomActiveTab(tabType);

    }, [])

    useEffect(() => {
        fetchMemberData()
        setEditMemberModal(false)
        setEditMemberShipModal(false)
    }, [memberUpdated, membershipUpdated])

    useEffect(() => {
        if (customActiveTab == 5) {
            fetchAppointmentList()
            fetchDietChartInAssignedMembership(member_id)
        }
    }, [customActiveTab])

    const fetchMemberData = async () => {
        try {
            const res = await graphql({
                query: MemberQry.FETCH_ALL_MEMBERS_QUERY,
                variables: {
                    ...paginatorInfo,
                    searchText: `%${searchText}%`,
                    id: member_id,
                    status_id: "%%"
                }
            });
            let responseData = !!res.data.members ? res.data.members.data[0] : []
            if (responseData.gender == 1) {
                setGender("Mr.")
            } else if (responseData.gender == 2) {
                setGender("Mrs./Miss.")
            }
            else {
                setGender("Mx.")
            }

            if (responseData?.membership_details?.length > 0) {
                for (let index = 0; index < responseData?.membership_details?.length; index++) {
                    colCount.push(index)
                }
            }

            const memberData = {
                id: responseData.id,
                member_name: responseData.member_name,
                member_age: responseData.member_age,
                profession: responseData.profession,
                gender: responseData.gender,
                address: responseData.address,
                mobile_no: responseData.mobile_no,
                member_access_code: responseData.member_access_code,
                email_id: responseData.email_id,
                status: responseData.status,
                member_status: responseData.member_status,
                membership_details: responseData.membership_details,
                profile_image: responseData.profile_image,
                id_proof: responseData.id_proof
            }
            setMemberData(memberData)
        } catch (error) {
            setError(error)
        }
    }

    const runFetchAppointmentList = () => {
        fetchAppointmentList()
    }

    const fetchAppointmentList = async () => {
        try {
            setProcessingAppointmentList(true);
            const res = await axiosInstance.post(apiUrls.diet.appointmentList + "?page=" + 1, { offset: 10, member_id: member_id })
            if (res.success == 1) {
                // let data = res?.data?.data;
                setAppointmentListData(true);
                setProcessingAppointmentList(false);
            } else {
                setAppointmentListData(false);
                setProcessingAppointmentList(false);
            }
        } catch (error) {
            setProcessingAppointmentList(false);
            setAppointmentListData(false);
        }
    }


    const fetchDietChartInAssignedMembership = async (id) => {
        try {
            const res = await axiosInstance.post(apiUrls.diet.checkDietChartInAssignedMembership, { member_id: id })
            if (res.success == 1) {
                if (res.diet_chart_status == 1) {
                    setDietChartStatus(true)
                } else {
                    setDietChartStatus(false)
                }
            } else {
                setDietChartStatus(false)
            }
        } catch (error) {
            setDietChartStatus(false)
        }
    }

    const toggleDay = tab => {

        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    };

    const assignWorkout = () => {
        setAssignWorkoutModal(true)
    }

    const viewDietAppointment = () => {
        setViewDietAppointmentModal(true);
    }

    const bookDietAppointment = () => {
        setBookDietAppointmentModal(true);
    }

    const assignDiet = () => {
        setAssignDietModal(true)
    }

    const createBodyComposition = () => {
        setBodyCompositionModal(true)
    }

    // Subhadip Start
    const assignTrainer = () => {
        setAssignTrainerModal(true)
    }
    useEffect(() => {
        setAssignTrainerModal(false)
    }, [trainerAssigned])
    //Sunhadip End


    useEffect(() => {
        setAssignWorkoutModal(false)
    }, [workoutAssigned])

    useEffect(() => {
        setBookDietAppointmentModal(false)
    }, [dietAppointment])

    useEffect(() => {
        setViewDietAppointmentModal(false);
    }, [viewAppointment])

    useEffect(() => {
        setAssignDietModal(false)
    }, [dietAssigned])

    useEffect(() => {
        setBodyCompositionModal(false)
    }, [bodyCompositionCreated])

    const handleCol = (e, index) => {
        setColIndex(index)
    }

    const renderMembershipDetails = (membership_details) => {
        return membership_details.map((data, index) => {
            return <div className="accordion-item" key={index}>
                <h1 className="accordion-header" id="headingOne">
                    <button
                        onClick={(e) => handleCol(e, index)}
                        data-event={index}
                        style={{ cursor: "pointer" }}
                        className={classnames(
                            "accordion-button",
                            "fw-medium",
                            { collapsed: colIndex == index ? false : true }
                        )}
                    >
                        {data?.subscription_details?.plan_name}
                        {data?.status == 1 ? <span className="badge rounded-pill bg-success ms-2 px-2 py-1">Ongoing</span> : <span className="badge rounded-pill bg-danger ms-2 px-2 py-1">Expired</span>}
                    </button>
                </h1>
                <Collapse isOpen={colIndex === index} className="accordion-collapse">
                    <CardBody className="accordion-body">
                        <Row>
                            <div className="d-flex justify-content-end">
                                <button className="btn btn-primary font-16 btn-block"
                                    onClick={(e) => {
                                        setMembershipId(data.id)
                                        handleEditMemberShip(e)
                                    }}
                                    title="Edit"
                                >
                                    <i className="bx bx-pencil align-middle" />
                                </button>
                                <Button className="btn-primary btn-sm ms-2" onClick={(e) => { handleFetchMembership(e, data.id) }} title="Invoice">
                                    <i className="bx bx-receipt"></i>
                                </Button>
                            </div>
                        </Row>
                        <Row>
                            <Col lg={(data?.subscription_details?.plan_type?.plan_type_name == 'Item') ? 2 : 3}>
                                <Label className="col-form-label">
                                    <b>Start Date</b>
                                </Label>
                                <p>{moment(data?.beginning_date).format('DD-MM-YYYY')}</p>
                            </Col>
                            <Col lg={(data?.subscription_details?.plan_type?.plan_type_name == 'Item') ? 2 : 3}>
                                <Label className="col-form-label">
                                    <b>End Date</b>
                                </Label>
                                <p>{moment(data?.end_date).format('DD-MM-YYYY')}</p>
                            </Col>
                            {(data?.subscription_details?.plan_type?.plan_type_name == 'Item') &&
                                <Col sm={6} lg={2}>
                                    <Label className="col-form-label">
                                        <b>Quantity</b>
                                    </Label>
                                    <p>{(data?.subscription_details?.plan_type?.plan_type_name == 'Item') && (data?.number_of_sessions)}</p>
                                </Col>
                            }
                            <Col lg={(data?.subscription_details?.plan_type?.plan_type_name == 'Item') ? 2 : 3}>
                                <Label className="col-form-label">
                                    <b>Timing</b>
                                </Label>
                                <p>{data?.preferred_timing_details?.preferred_timing_name}</p>
                            </Col>
                            <Col lg={(data?.subscription_details?.plan_type?.plan_type_name == 'Item') ? 2 : 3}>
                                <Label className="col-form-label">
                                    <b>Invoice No.</b>
                                </Label>
                                <p>{data?.invoice_num}</p>
                            </Col>
                        </Row>
                    </CardBody>
                </Collapse>
            </div>

        })

    }

    const handleEditMember = (e) => {
        setEditMemberModal(true)
    }

    const handleEditMemberShip = (e) => {
        setEditMemberShipModal(true)
    }

    const handleFetchMembership = async (e, membershipId) => {
        let responseData = []
        try {
            const res = await graphql({
                query: MembershipQry.FETCH_ALL_MEMBERSHIPS_QUERY,
                variables: {
                    ...paginatorInfo,
                    searchText: `%${searchText}%`,
                    id: membershipId
                }
            });
            responseData = !!res.data.memberships ? res.data.memberships.data[0] : []
            setInvoiceData(responseData)
            setTimeout(() => {
                setInvoiceModalOpen(true)
            }, 0);

        } catch (error) {

        }
    }

    const renderIDproof = (file) => {
        if (checkFileType(file) === FILETYPE.IMAGE) {
            return <img
                src={idProofURL + file}
                alt="profile"
                className="img-thumbnail h-w-5"
            />
        }
        else if (checkFileType(file) === FILETYPE.PDF) {
            return <img
                src={images.pdf}
                alt="profile"
                className="img-thumbnail h-w-5"
            />
        }
        else {
            return <img
                src={images.otherFile}
                alt="profile"
                className="img-thumbnail h-w-5"
            />
        }

    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Member Details</title>
                </MetaTags>
                <Container fluid={true}>
                    <Card>
                        <CardBody>
                            <CardTitle className="mb-4">
                                <span className="member-name">
                                    {!!memberData.member_name && memberData.member_name}
                                </span>&nbsp;&nbsp;
                                <span className="badge rounded-pill" style={{ backgroundColor: "#DC2450", fontSize: "inherit" }}>ID:{member_id}</span>

                            </CardTitle>

                            <Nav tabs className="nav-tabs-custom nav-justified custom-tab">
                                <NavItem className="whitespace-no-wrap">
                                    <NavLink
                                        className={classnames({
                                            'cursor-pointer': true, active: customActiveTab === "1",
                                        })}
                                        onClick={() => {
                                            toggleDay("1");
                                        }}
                                    >
                                        <span className="d-block d-sm-none">
                                            <i className="fas fa-home"></i>
                                        </span>
                                        <span className="d-none d-sm-block">Member Info</span>
                                    </NavLink>
                                </NavItem>

                                <NavItem className="whitespace-no-wrap">
                                    <NavLink className={classnames({
                                        'cursor-pointer': true, active: customActiveTab === "2",
                                    })}
                                        onClick={() => {
                                            toggleDay("2");
                                        }}
                                    >
                                        <span className="d-block d-sm-none">
                                            <i className="far fa-user"></i>
                                        </span>
                                        <span className="d-none d-sm-block">Membership Info</span>
                                    </NavLink>
                                </NavItem>

                                <NavItem className="whitespace-no-wrap">
                                    <NavLink className={classnames({
                                        'cursor-pointer': true, active: customActiveTab === "3",
                                    })}
                                        onClick={() => {
                                            toggleDay("3");
                                        }}
                                    >
                                        <span className="d-block d-sm-none">
                                            <i className="far fa-user"></i>
                                        </span>
                                        <span className="d-none d-sm-block">Accessories</span>
                                    </NavLink>
                                </NavItem>

                                <NavItem className="whitespace-no-wrap">
                                    <NavLink className={classnames({
                                        'cursor-pointer': true, active: customActiveTab === "4",
                                    })}
                                        onClick={() => {
                                            toggleDay("4");
                                        }}
                                    >
                                        <span className="d-block d-sm-none">
                                            <i className="far fa-envelope"></i>
                                        </span>
                                        <span className="d-none d-sm-block">Workout Chart</span>
                                    </NavLink>
                                </NavItem>

                                <NavItem className="whitespace-no-wrap">
                                    <NavLink className={classnames({
                                        'cursor-pointer': true, active: customActiveTab === "5",
                                    })}
                                        onClick={() => {
                                            toggleDay("5");
                                        }}
                                    >
                                        <span className="d-block d-sm-none">
                                            <i className="fas fa-cog"></i>
                                        </span>
                                        <span className="d-none d-sm-block">Diet Chart</span>
                                    </NavLink>
                                </NavItem>

                                <NavItem className="whitespace-no-wrap">
                                    <NavLink className={classnames({
                                        'cursor-pointer': true, active: customActiveTab === "6",
                                    })}
                                        onClick={() => {
                                            toggleDay("6");
                                        }}
                                    >
                                        <span className="d-block d-sm-none">
                                            <i className="fas fa-cog"></i>
                                        </span>
                                        <span className="d-none d-sm-block">Body Composition</span>
                                    </NavLink>
                                </NavItem>

                                <NavItem className="whitespace-no-wrap">
                                    <NavLink className={classnames({
                                        'cursor-pointer': true, active: customActiveTab === "7",
                                    })}
                                        onClick={() => {
                                            toggleDay("7");
                                        }}
                                    >
                                        <span className="d-block d-sm-none">
                                            <i className="far fa-user"></i>
                                        </span>
                                        <span className="d-none d-sm-block">Trainer</span>
                                    </NavLink>
                                </NavItem>

                                <NavItem className="whitespace-no-wrap">
                                    <NavLink className={classnames({
                                        'cursor-pointer': true, active: customActiveTab === "8",
                                    })}
                                        onClick={() => {
                                            toggleDay("8");
                                        }}
                                    >
                                        <span className="d-block d-sm-none">
                                            <i className="far fa-user"></i>
                                        </span>
                                        <span className="d-none d-sm-block">Activities</span>
                                    </NavLink>
                                </NavItem>
                            </Nav>

                            <TabContent
                                activeTab={customActiveTab}
                                className="py-3 mt-4 text-muted"
                            >
                                <TabPane tabId="1">
                                    <Row>
                                        <Col lg={12}>
                                            <Row className="align-items-center">
                                                <Col sm={6}>
                                                    <CardTitle className="h4 text-info mb-0">Basic Details</CardTitle>
                                                </Col>
                                                <Col sm={6}>
                                                    <div className="d-flex justify-content-end">
                                                        <button className="btn btn-primary btn-block"
                                                            onClick={(e) => { handleEditMember(e) }} title="Edit"
                                                        >
                                                            <i className="bx bx-pencil font-size-16 align-middle me-2" />
                                                            Edit
                                                        </button>
                                                    </div>
                                                </Col>
                                            </Row>
                                            {editMemberModal && <EditMember member_id={member_id} />}
                                            <hr />
                                            <Row className="mb-2 ml-2">
                                                <Col lg={6}>
                                                    <Row>
                                                        <Col sm={4}>
                                                            <b>Name</b>
                                                        </Col>
                                                        <Col sm={8}>
                                                            {/* <p>{!!memberData.member_name ? (gender + " " + memberData.member_name) : "--"}</p> */}
                                                            <p>{!!memberData.member_name ? memberData.member_name : "--"}</p>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={4}>
                                                            <b>Email</b>
                                                        </Col>
                                                        <Col sm={8}>
                                                            <p>{!!memberData.email_id ? memberData.email_id : "--"}</p>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={4}>
                                                            <b>Contact No.</b>
                                                        </Col>
                                                        <Col sm={8}>
                                                            <p>{!!memberData.mobile_no ? memberData.mobile_no : "--"}</p>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={4}>
                                                            <b>Age</b>
                                                        </Col>
                                                        <Col sm={8}>
                                                            <p>{!!memberData.member_age ? memberData.member_age : "--"}</p>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={4}>
                                                            <b>Profession</b>
                                                        </Col>
                                                        <Col sm={8}>
                                                            <p>{!!memberData.profession ? memberData.profession : "--"}</p>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={4}>
                                                            <b>Access Card No.</b>
                                                        </Col>
                                                        <Col sm={8}>
                                                            <p>{!!memberData.member_access_code ? memberData.member_access_code : "--"}</p>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={4}>
                                                            <b>Member Status</b>
                                                        </Col>
                                                        <Col sm={8}>
                                                            <p>{!!memberData.member_status ? (memberData.member_status == 1 ? "Irregular" : "Discontinued") : "--"}</p>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={4}>
                                                            <b>Address</b>
                                                        </Col>
                                                        <Col sm={8}>
                                                            <p>{!!memberData.address ? memberData.address : "--"}</p>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={4}>
                                                            <b>ID Proof</b>
                                                        </Col>
                                                        <Col sm={8}>
                                                            <div className="me-3">
                                                                {!!memberData.id_proof ?
                                                                    <a href={idProofURL + memberData.id_proof} target="_blank" rel="noreferrer">
                                                                        {renderIDproof(memberData.id_proof)}
                                                                    </a>
                                                                    :
                                                                    <img
                                                                        src={images.defaultDocument}
                                                                        alt="profile"
                                                                        className="img-thumbnail h-w-5"
                                                                    />
                                                                }
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col lg={6}>
                                                    <div className="me-3">
                                                        <img
                                                            src={!!memberData.profile_image
                                                                ? imageURL + memberData.profile_image
                                                                : images.defaultAvatar
                                                            }
                                                            alt="profile"
                                                            className="img-thumbnail h-w-5"
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </TabPane>

                                <TabPane tabId="2">
                                    <Row>
                                        <Col lg={12}>
                                            <Row>
                                                <Col lg={8}>
                                                    <CardTitle className="h4 text-info">Membership Details</CardTitle>
                                                </Col>
                                            </Row>
                                            {editMemberShipModal && <EditMembership membership_id={membershipId} />}
                                            <hr />
                                            <div className="mb-2">
                                                {renderMembershipDetails(memberData?.membership_details)}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Invoice
                                        isOpenModal={invoiceModalOpen}
                                        onCloseClick={() => setInvoiceModalOpen(false)}
                                        invoiceData={invoiceData}
                                    />
                                </TabPane>

                                {customActiveTab == 3 &&
                                    <TabPane tabId="3">
                                        <AddEditAccessory member_id={member_id} />
                                    </TabPane>
                                }

                                {customActiveTab == 4 &&
                                    <TabPane tabId="4">
                                        <Row>
                                            <Col lg={12}>
                                                <Row className="align-items-center">
                                                    <Col sm={6}>
                                                        <CardTitle className="h4 text-info mb-0">Workout Chart</CardTitle>

                                                    </Col>
                                                    <Col sm={6}>
                                                        <div className="d-flex justify-content-end">

                                                            <Button
                                                                color="primary"
                                                                className="btn-block"
                                                                onClick={assignWorkout}
                                                            >
                                                                <i className="mdi mdi-plus-circle-outline align-middle me-2" />
                                                                Assign
                                                            </Button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <hr />
                                                <div className="row mb-10 workout-chart">
                                                    <AssignMemberWorkoutPlan
                                                        member_id={member_id}
                                                        assignWorkoutModal={assignWorkoutModal}
                                                        redirectPath="/member-details"
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                }

                                {customActiveTab == 5 &&
                                    <TabPane tabId="5">
                                        <Row>
                                            <Col lg={12}>
                                                <Row className="align-items-center">
                                                    <Col sm={6}>
                                                        <CardTitle className="h4 text-info mb-0">Diet Chart</CardTitle>

                                                    </Col>
                                                    <Col sm={6}>
                                                        <div className="d-flex justify-content-end">
                                                            {appointmentListData &&
                                                                <>
                                                                    <Button
                                                                        color="primary"
                                                                        className="btn-block"
                                                                        onClick={viewDietAppointment}
                                                                    >
                                                                        <i className="bx bx-show-alt align-middle me-1" />
                                                                        View Appointment
                                                                    </Button>
                                                                    &nbsp; &nbsp;
                                                                </>
                                                            }
                                                            {dietChartStatus &&
                                                                <>
                                                                    <Button
                                                                        color="primary"
                                                                        className="btn-block"
                                                                        onClick={bookDietAppointment}
                                                                    >
                                                                        <i className="bx bx-window-open align-middle me-1" />
                                                                        Book Appointment
                                                                    </Button>
                                                                    &nbsp;&nbsp;
                                                                </>
                                                            }

                                                            <Button
                                                                color="primary"
                                                                className="btn-block"
                                                                onClick={assignDiet}
                                                            >
                                                                <i className="mdi mdi-plus-circle-outline align-middle me-1" />
                                                                Assign
                                                            </Button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <hr />
                                                <div className="row mb-10 diet-chart-table">
                                                    <AssignMemberDietChart
                                                        member_id={member_id}
                                                        runFetchAppointmentList={runFetchAppointmentList}
                                                        viewDietAppointmentModal={viewDietAppointmentModal}
                                                        bookDietAppointmentModal={bookDietAppointmentModal}
                                                        assignDietModal={assignDietModal}
                                                        redirectPath="/member-details"
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                }

                                {customActiveTab == 6 &&
                                    <TabPane tabId="6">
                                        <Row>
                                            <Col lg={12}>
                                                <Row className="align-items-center">
                                                    <Col sm={6}>
                                                        <CardTitle className="h4 text-info mb-0">Body Composition</CardTitle>

                                                    </Col>
                                                    <Col sm={6}>
                                                        <div className="d-flex justify-content-end">

                                                            <Button
                                                                color="primary"
                                                                className="btn-block"
                                                                onClick={createBodyComposition}
                                                            >
                                                                <i className="mdi mdi-plus-circle-outline align-middle me-1" />
                                                                Create
                                                            </Button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <hr />
                                                <div className="row mb-2 body-composition-table">
                                                    <AddEditBodyComposition
                                                        member_id={member_id}
                                                        bodyCompositionModal={bodyCompositionModal}
                                                        redirectPath="/member-details"
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                }

                                {customActiveTab == 7 &&
                                    <TabPane tabId="7">
                                        <Row>
                                            <Col lg={12}>
                                                <Row className="align-items-center">
                                                    <Col sm={6}>
                                                        <CardTitle className="h4 text-info mb-0">Trainer</CardTitle>

                                                    </Col>
                                                    <Col sm={6}>
                                                        <div className="d-flex justify-content-end">

                                                            <Button
                                                                color="primary"
                                                                className="btn-block"
                                                                onClick={assignTrainer}
                                                            >
                                                                <i className="mdi mdi-plus-circle-outline align-middle me-1" />
                                                                Assign
                                                            </Button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <hr />
                                                <div className="row mb-10 trainer-table">
                                                    <AssignTrainerToMember
                                                        member_id={member_id}
                                                        assignTrainerModal={assignTrainerModal}
                                                        redirectPath="/member-details"
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                }

                                {customActiveTab == 8 &&
                                    <TabPane tabId="8">
                                        <Row className="mb-2">
                                            <Col lg={12}>
                                                <Activity
                                                    member_id={member_id}
                                                    activity={activity}
                                                    redirectPath="/member-details"
                                                />
                                            </Col>
                                        </Row>
                                    </TabPane>
                                }
                            </TabContent>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment >
    );

}

export default withRouter(MemberDetails);

MemberDetails.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
};