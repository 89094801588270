import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useEffect, useState } from "react";
import {
    Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label, Button, Modal,
    ModalBody,
    ModalHeader,
    InputGroup
} from "reactstrap";
import { withRouter, Link } from "react-router-dom";

// Formik formikObj
import * as Yup from "yup";
import { useFormik } from "formik";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { standartDateFormat } from "utils/date";
import { axiosInstance, apiUrls } from "api";
import swal from 'sweetalert';
import { graphql } from "api";

// datatable related plugins

import CommonTable from "components/Common/CommonTable";
import usePaginator from "hooks/usePaginator";
import * as ExerciseQry from "GraphQLquery/exerciseQry";

const ManageExercise = props => {

    const [error, setError] = useState(null);
    const [processingList, setProcessingList] = useState(false);
    const [allExerciseList, setAllExerciseList] = useState([]);
    const [paginatorInfo, setPaginatorInfo, resetPaginatorInfo] = usePaginator();
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        fetchAllExerciseListData(paginatorInfo, searchText)
    }, [])

    const fetchAllExerciseListData = async (paginatorInfo, searchText) => {
        try {
            setProcessingList(true)
            const res = await graphql({
                query: ExerciseQry.FETCH_ALL_EXERCISES_QUERY,
                variables: {
                    ...paginatorInfo,
                    searchText: `%${searchText}%`,
                    id: "%%"
                }
            });

            if (res.data.workout_plan_exercises.data) {
                setProcessingList(false)
                setAllExerciseList(!!res.data.workout_plan_exercises ? res.data.workout_plan_exercises.data : [])
                setPaginatorInfo(res?.data?.workout_plan_exercises?.paginatorInfo);
            }

            else {
                setProcessingList(false)
                setAllExerciseList([])
                resetPaginatorInfo();
            }
        } catch (error) {
            setProcessingList(false)
            setAllExerciseList([])
            resetPaginatorInfo();
        }
    }

    const handleTableChange = (paginatorInfo, searchText) => {
        fetchAllExerciseListData(paginatorInfo, searchText)
    }

    const handleAction = (cell, row) => {
        return (
            <>
                <Button className="btn-info btn-sm ms-2" onClick={(e) => props.history.push("/edit-exercise/" + row.id)}>
                    <i className="bx bx-pencil align-middle" />
                </Button>
                <Button className="btn-danger btn-sm ms-2" onClick={(e) => { handleDelete(e, row) }}>
                    <i className="bx bx-trash align-middle" />
                </Button>
                {/* &nbsp;
                <Button className="btn btn-info  btn btn-info btn-sm" onClick={(e) => { handleChangeStatus(e, row) }}>
                    {row.status == 0 ? "Active" : "Inactive"}
                </Button> */}
            </>
        );
    };

    // Column definition for datatable
    const columns = [{
        dataField: 'exercise_name',
        text: 'EXERCISE NAME',
        sort: true,
    },
        {
        dataField: 'workout_category[category_name]',
        text: 'WORKOUT CATEGORY',
        sort: true,
    },
    {
        dataField: 'exercise_type[exercise_type_name]',
        text: 'EXERCISE TYPE',
        sort: true,
    },
    
    {
        dataField: '',
        text: 'ACTION',
        sort: true,
        formatter: handleAction
    }];

    const handleDelete = (e, row) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (willDelete) => {
                if (willDelete) {
                    try {
                        const response = await axiosInstance.post(apiUrls.workoutPlan.deleteWorkoutPlanExercise, { id: row.id });
                        if (response.success == 1) {
                            swal("Success!", response.message, "success");
                            fetchAllExerciseListData(paginatorInfo, searchText)
                        }
                        else {
                            swal("Oops!", response.message, "error");
                        }
                    } catch (err) {
                        swal("Oops!", "Something went wrong!", "error");
                        setError(err.message)
                    }
                }
            });
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Manage Exercise</title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumbs title="Workout Plan" breadcrumbItem="Manage Exercise" />
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col lg={3}>
                                            <Link to="/create-exercise">
                                                <Button
                                                    color="primary"
                                                    className="font-size-13 btn-block"
                                                >
                                                    <i className="mdi mdi-plus-circle-outline me-1 align-middle font-size-16" />Create 
                                                </Button>
                                            </Link>

                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    {/* Datatable view start */}

                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col lg={12}>
                                            <h3>All Exercise List</h3><br />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                            <CommonTable
                                                paginatorInfo={paginatorInfo}
                                                searchText={searchText}
                                                onTableChange={handleTableChange}
                                                keyField='id'
                                                columns={columns}
                                                data={allExerciseList}
                                                loading={processingList}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    {/* Datatable view end */}
                </Container>
            </div>
        </React.Fragment>
    );

}

export default withRouter(ManageExercise);

ManageExercise.propTypes = {
    history: PropTypes.object,
};