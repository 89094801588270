import {
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS
} from "./actionTypes"

export const loginSuccess = (response) => {
  return {
    type: LOGIN_SUCCESS,
    payload: response,
  }
}
export const logoutSuccess = () => {
  return {
    type: LOGOUT_SUCCESS
  }
}
