import profile_ids, { actionPathsByProfileId } from "./profile_ids";

class AuthStorage {
    TOKEN_STORE_KEY = 'flexym_token';
    ACTIVE_PATH_KEY = 'flexym_active_path';
    setToken = (token) => {
        if (token)
            localStorage.setItem(this.TOKEN_STORE_KEY, token);
    }
    getToken = () => {
        return localStorage.getItem(this.TOKEN_STORE_KEY);
    }
    removeToken = () => {
        localStorage.removeItem(this.TOKEN_STORE_KEY);
    }
    setActivePaths = (paths) => {
        try {
            let updatePaths = paths;
            const profileId = localStorage.getItem("profile_id");
            if (actionPathsByProfileId[+profileId]) {
                updatePaths = [...paths, ...actionPathsByProfileId[+profileId]]
            }
            // if( profileId == 1){
            //     localStorage.setItem(this.ACTIVE_PATH_KEY, JSON.stringify(paths));    
            // }
            localStorage.setItem(this.ACTIVE_PATH_KEY, JSON.stringify(updatePaths));
        } catch (err) {
        }
    }
    getActivePaths = () => {
        try {
            return JSON.parse(localStorage.getItem(this.ACTIVE_PATH_KEY));
        } catch (err) {
            return null;
        }
    }
    deleteActivePaths = () => {
        localStorage.removeItem(this.ACTIVE_PATH_KEY);
    }
}

export const authStorage = new AuthStorage();