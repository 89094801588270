import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useEffect, useState } from "react";
import { Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { userForgetPassword } from "../../store/actions";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";
import { axiosInstance, apiUrls } from "api";
import swal from 'sweetalert';

let interval = null

const ForgetPasswordPage = props => {
  const dispatch = useDispatch();

  const [showEmailForm, setShowEmailForm] = useState(true);
  const [showPasswordOTPForm, setShowOTPForm] = useState(false);
  const [disableResendBtn, setDisableResendBtn] = useState(false);
  const [resetCounter, setResetCounter] = useState(null)
  const [processPasswordOTP, setProcessPasswordOTP] = useState(false)
  const [processEmail, setProcessEmail] = useState(false)
  const [email, setEmail] = useState(null)
  //const [profile_id, sendProfileID] = useState(null)

  const validateEmail = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      profile_id: 1,
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: (values) => {
      setEmail(values.email)
      sendOTP(values.email)
    }
  });

  const sendOTP = async (email) => {
    setProcessEmail(true)
    try {
      const response = await axiosInstance.post(apiUrls.forgotPassword.forgotPasswordOTP, { email, profile_id:1 });
      if (response.success == 1) {
        validateEmail.resetForm()
        setProcessEmail(false)
        setShowEmailForm(false)
        setShowOTPForm(true)
        swal("Success!", response.message, "success");
      }
      else {
        setProcessEmail(false)
        swal("Oops!", response.message, "error");
      }
    } catch (err) {
      setProcessEmail(false)
      swal("Oops!", "Something went wrong!", "error");
    }
  }

  const validatePasswordOTP = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      otp: '',
      password: "",
      confirm_password: "",
    },
    validationSchema: Yup.object({
      otp: Yup
        .string()
        .required("Please Enter Your OTP"),
      password: Yup
        .string()
        .required("Please Enter Your Password"),
      confirm_password: Yup
        .string()
        .required("Please Enter Confirm Password")
        .oneOf([Yup.ref('password')], 'Passwords must match'),
    }),
    onSubmit: async (values) => {
      // dispatch(userForgetPassword(values, props.history));
      // setShowPasswordForm(true)
      setProcessPasswordOTP(true)
      setShowEmailForm(false)

      try {
        const response = await axiosInstance.post(apiUrls.resetPasswordWithOTP.resetPassword,
          {
            otp: values.otp,
            password: values.password,
            confirm_password: values.confirm_password,
            profile_id: 1,
          });
        if (response.success == 1) {
          setProcessPasswordOTP(false)
          validatePasswordOTP.resetForm()
          props.history.push('/login')
          swal("Success!", response.message, "success");
        }
        else {
          setProcessPasswordOTP(false)
          swal("Oops!", response.message, "error");
        }
      } catch (err) {
        setProcessPasswordOTP(false)
        swal("Oops!", "Something went wrong!", "error");
      }
    }
  });

  useEffect(() => {
    if (resetCounter === 0) {
      clearInterval(interval)
      setDisableResendBtn(false)
    }

  }, [resetCounter])

  const handleResend = (e) => {
    var count = 11
    interval = setInterval(() => {
      setResetCounter(count -= 1)
    }, 1000);
    setDisableResendBtn(true)
    sendOTP(email)
  }

  const { forgetError, forgetSuccessMsg } = useSelector(state => ({
    forgetError: state.ForgetPassword.forgetError,
    forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
  }));

  return (
    <React.Fragment>
      <MetaTags>
        <title>
          Forget Password
        </title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Reset Password !</h5>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {forgetError && forgetError ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {forgetError}
                      </Alert>
                    ) : null}
                    {forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {forgetSuccessMsg}
                      </Alert>
                    ) : null}

                    {!!showEmailForm &&
                      <Form
                        className="form-horizontal"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validateEmail.handleSubmit();
                          return false;
                        }}
                      >
                        <div className="mb-3">
                          <Label className="form-label">Email<span className="text-danger">*</span></Label>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            onChange={validateEmail.handleChange}
                            onBlur={validateEmail.handleBlur}
                            value={validateEmail.values.email || ""}
                            invalid={
                              validateEmail.touched.email && validateEmail.errors.email ? true : false
                            }
                          />
                          {validateEmail.touched.email && validateEmail.errors.email ? (
                            <FormFeedback type="invalid">{validateEmail.errors.email}</FormFeedback>
                          ) : null}
                        </div>
                        <Row className="mb-3">
                          <Col className="text-end">
                            <button
                              className="btn btn-primary w-md "
                              type="submit"
                              disabled={processEmail ? true : false}
                            >
                              {processEmail ? "Processing" : "Submit"}
                            </button>
                          </Col>
                        </Row>
                      </Form>}

                    {!!showPasswordOTPForm &&
                      <Form
                        className="form-horizontal"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validatePasswordOTP.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col lg={12}>
                            <div className="mb-3">
                              <Label className="form-label">Enter OTP<span className="text-danger">*</span></Label>
                              <Input
                                name="otp"
                                className="form-control"
                                placeholder="Enter OTP"
                                type="text"
                                // maxLength={4}
                                onChange={validatePasswordOTP.handleChange}
                                onBlur={validatePasswordOTP.handleBlur}
                                value={validatePasswordOTP.values.otp || ""}
                                invalid={
                                  validatePasswordOTP.touched.otp && validatePasswordOTP.errors.otp ? true : false
                                }
                              />
                              {validatePasswordOTP.touched.otp && validatePasswordOTP.errors.otp ? (
                                <FormFeedback type="invalid">{validatePasswordOTP.errors.otp}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-password-Input">Password<span className="text-danger">*</span></Label>
                              <Input
                                type="password"
                                name="password"
                                className="form-control"
                                id="formrow-password-Input"
                                placeholder="Enter Your Password"
                                onChange={validatePasswordOTP.handleChange}
                                value={validatePasswordOTP.values.password || ""}
                                invalid={
                                  validatePasswordOTP.touched.password && validatePasswordOTP.errors.password ? true : false
                                }
                              />
                              {validatePasswordOTP.touched.password && validatePasswordOTP.errors.password ? (
                                <FormFeedback type="invalid">{validatePasswordOTP.errors.password}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-confirm-password-Input">Confirm Password<span className="text-danger">*</span></Label>
                              <Input
                                type="password"
                                name="confirm_password"
                                className="form-control"
                                id="formrow-confirm-password-Input"
                                placeholder="Confirm Your Password"
                                onChange={validatePasswordOTP.handleChange}
                                value={validatePasswordOTP.values.confirm_password || ""}
                                invalid={
                                  validatePasswordOTP.touched.confirm_password && validatePasswordOTP.errors.confirm_password ? true : false
                                }
                              />
                              {validatePasswordOTP.touched.confirm_password && validatePasswordOTP.errors.confirm_password ? (
                                <FormFeedback type="invalid">{validatePasswordOTP.errors.confirm_password}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <div className="text-end">
                          <Row>
                            <Col lg={4}>
                              {resetCounter > 0 ?
                                <p>Resend in {resetCounter}s</p>
                                :
                                ''
                              }
                            </Col>
                            <Col lg={8}>
                              {!processPasswordOTP &&
                                <button
                                  className="btn btn-secondary w-md"
                                  type="button"
                                  style={{ marginRight: "10px" }}
                                  disabled={disableResendBtn}
                                  onClick={handleResend}
                                >
                                  Resend OTP
                                </button>}
                              <button
                                className="btn btn-primary w-md"
                                type="submit"
                                disabled={processPasswordOTP ? true : false}
                              >
                                {processPasswordOTP ? "Processing" : "Submit"}
                              </button>
                            </Col>
                          </Row>
                        </div>
                      </Form>
                    }
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Go back to{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    Login
                  </Link>{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ForgetPasswordPage);
