import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import { Link } from "react-router-dom";

import classNames from "classnames";

//import Charts
import StackedColumnChart from "./StackedColumnChart";

//import action
import { getChartsData as onGetChartsData } from "../../store/actions";

import modalimage1 from "../../assets/images/product/img-7.png";
import modalimage2 from "../../assets/images/product/img-4.png";

// Pages Components
import WelcomeComp from "./WelcomeComp";
import MonthlyEarning from "./MonthlyEarning";
import SocialSource from "./SocialSource";
import ActivityComp from "./ActivityComp";
import TopCities from "./TopCities";
import LatestTranaction from "./LatestTranaction";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";
//Api
import { apiUrls, axiosInstance, graphql } from "api";
import { ALL_USERS_QUERY } from "GraphQLquery/usersQry";
import * as SubscriptionQry from "GraphQLquery/subscriptionQry";

let maxYearOffset = 60;

const staticYears = ["2022", "2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030"];

const Dashboard = props => {
  const [modal, setmodal] = useState(false);
  const [subscribemodal, setSubscribemodal] = useState(false);

  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData
  }));

  const reports = [
    { title: "Orders", iconClass: "bx-copy-alt", description: "1,235" },
    { title: "Revenue", iconClass: "bx-archive-in", description: "$35, 723" },
    {
      title: "Average Price",
      iconClass: "bx-purchase-tag-alt",
      description: "$16.2",
    },
  ];

  const initialAdmissionData = [{
    name: "Admission",
    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  }]

  const initialRenewalData = [{
    name: "Renewal",
    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  }]

  const initialCategoryData = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

  useEffect(() => {
    setTimeout(() => {
      setSubscribemodal(true);
    }, 2000);
  }, []);

  const [renewalData, setRenewalData] = useState([...initialRenewalData]);
  const [admissionData, setAdmissionData] = useState([...initialAdmissionData]);
  const [categories, setCategories] = useState([...initialCategoryData])
  const [periodType, setPeriodType] = useState('');
  const [processingList, setProcessingList] = useState(false);
  const [allSubscriptionList, setAllSubscriptionList] = useState([]);
  const [activityList, setActivityList] = useState([]);
  const [yearList, setYearList] = useState([]);
  const [subscriptionPlan, setSubscriptionPlan] = useState('');
  const [activity, setActivity] = useState('');
  const [year, setYear] = useState('');

  const onChangeChartPeriod = pType => {
    setPeriodType(pType);
  };

  useEffect(() => {
    creatYearList()
    fetchSubscriptionListData()
    fetchActivities()
    featchAdmissionData(activity, subscriptionPlan, year)
  }, [])

  const filterRenewalDashboardByYears = (e) => {
    if (e.target.value != '') {
      setYear(e.target.value)
      onChangeChartPeriod('yearly');
    } else {
      setYear(e.target.value)
      onChangeChartPeriod('');
    }
  }

const filterRenewalDashboardByActivity = (e) => {
  let activity = '';
  if (e.target.value != '') {
    activity = parseInt(e.target.value);
    setActivity(activity);
    onChangeChartPeriod('activity');
  } else {
    setActivity(activity);
    onChangeChartPeriod('');
  }
}
const filterRenewalDashboardBySubscriptionPlan = (e) => {
  let subscriptionPlan = '';
  if (e.target.value != '') {
    subscriptionPlan = parseInt(e.target.value);
    setSubscriptionPlan(subscriptionPlan);
    onChangeChartPeriod('subscription');
  } else {
    setSubscriptionPlan(subscriptionPlan);
    onChangeChartPeriod('');
  }
}

useEffect (() =>{
  fetchMemberListData();
},[activity, subscriptionPlan, year]
)

  const fetchMemberListData = async () => {
    try {
      const res = await axiosInstance.post(apiUrls.dashboard.dashboardRenewalGraphDetails, { activity: activity, subscription_plan: subscriptionPlan, year: year});
      if (res.success == 1) {
        let data = []
        let cat = []
        res.admission_report.map((item) => {
          cat.push(item?.month_name)
          data.push(item?.total_member_admission)
        })
        setRenewalData([{
          name: "Renewal",
          data: [...data],
        }])
        setCategories([...cat])
      } else {
        setRenewalData([ ...initialRenewalData ])
        setCategories([...initialCategoryData])
      }
    } catch (error) {
      setRenewalData([ ...initialRenewalData ])
      setCategories([...initialCategoryData])
    }
  }

  const featchAdmissionData = async (activity, subscriptionPlan, year) => {
    try {
      const res = await axiosInstance.post(apiUrls.dashboard.dashboardGraphDetails, { activity: activity, subscription_plan: subscriptionPlan, year: year });
      if (res.success == 1) {
        let data = []
        let cat = []
        res.admission_report.map((item) => {
          cat.push(item?.month_name)
          data.push(item?.total_member_admission)
        })
        setAdmissionData([{
          name: "Admission",
          data: [...data],
        }])
        setCategories([...cat])
      } else {
        setAdmissionData([ ...initialAdmissionData ])
        setCategories([...initialCategoryData])
      }
    } catch (error) {
      setAdmissionData([ ...initialAdmissionData ])
      setCategories([...initialCategoryData])
    }
  }

  const fetchSubscriptionListData = async () => {
    try {
      setProcessingList(true)
      const res = await graphql({
        query: SubscriptionQry.FETCH_ALL_SUBSCRIPTIONS_QUERY_FOR_DASHBOARD
      });

      if (res.data.subscription_plans.data) {
        setProcessingList(false)
        setAllSubscriptionList(!!res.data.subscription_plans ? res.data.subscription_plans.data : [])
      }

      else {
        setProcessingList(false)
        setAllSubscriptionList([])
      }
    } catch (error) {
      setProcessingList(false)
      setAllSubscriptionList([])
    }
  }

  const fetchActivities = async () => {
    setProcessingList(true)
    try {
      const res = await graphql({
        query: SubscriptionQry.ACTIVITIES_LIST_QUERY
      });
      if (res.data.activities) {
        let activities = res?.data?.activities || []
        setActivityList(activities)
        setProcessingList(false)
      } else {
        setActivityList([])
        setProcessingList(false)
      }
    } catch (error) {
      setActivityList([])
      setProcessingList(false)
    }
  }

  const creatYearList = () => {
    let currentYear = (new Date()).getFullYear()
    let years = []
    for (let i = 0; i <= maxYearOffset; i++) {
      years.push(currentYear - i)
    }
    setYearList(years)
  }

  const filterDashboardByYears = (e) => {
    // let year = '';
    if (e.target.value != '') {
      setYear(e.target.value)
      onChangeChartPeriod('yearly');
    } else {
      setYear(e.target.value)
      onChangeChartPeriod('');
    }
    featchAdmissionData(activity, subscriptionPlan, e.target.value)
  }

  const filterDashboardBySubscriptionPlan = (e) => {
    let subscriptionPlan = '';
    if (e.target.value != '') {
      subscriptionPlan = parseInt(e.target.value);
      setSubscriptionPlan(subscriptionPlan);
      onChangeChartPeriod('subscription');
    } else {
      setSubscriptionPlan(subscriptionPlan);
      onChangeChartPeriod('');
    }
    featchAdmissionData(activity, subscriptionPlan, year)
  }

  const filterDashboardByActivity = (e) => {
    let activity = '';
    if (e.target.value != '') {
      activity = parseInt(e.target.value);
      setActivity(activity);
      onChangeChartPeriod('activity');
    } else {
      setActivity(activity);
      onChangeChartPeriod('');
    }
    featchAdmissionData(activity, subscriptionPlan, year)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col lg={12}>
                      <Row>
                        <Col lg={12}>
                          <div className="d-sm-flex flex-wrap">
                            <h4 className="card-title mb-4">Admission Report</h4>
                            <div className="ms-auto">
                              <ul className="nav nav-pills">
                                <li className="nav-item">
                                  <select
                                    className={classNames({ active: periodType === "yearly" }, "nav-link btn btn-outline-primary")}
                                    onChange={(e) => {
                                      filterDashboardByYears(e)
                                    }}
                                  >
                                    <option value="">Years</option>
                                    {staticYears.map((singleYear, index) => (
                                      <option value={singleYear} key={index}>{singleYear}</option>
                                    ))}
                                  </select>
                                </li>
                                <li>&nbsp;&nbsp;</li>
                                <li className="nav-item">
                                  <select
                                    className={classNames({ active: periodType === "activity" }, "nav-link btn btn-outline-primary")}
                                    onChange={(e) => {
                                      filterDashboardByActivity(e)
                                    }}
                                  >
                                    <option value="">Activity</option>
                                    {activityList.map((item) => (
                                      <option value={item.id} key={item.id}>{item.activity}</option>
                                    ))}
                                  </select>
                                </li>
                                <li>&nbsp;&nbsp;</li>
                                <li className="nav-item">
                                  <select
                                    className={classNames({ active: periodType === "subscription" }, "nav-link btn btn-outline-primary")}
                                    onChange={(e) => {
                                      filterDashboardBySubscriptionPlan(e)
                                    }}
                                  >
                                    <option value="">Subscription Plan</option>
                                    {allSubscriptionList.map((item) => (
                                      <option value={item.id} key={item.id}>{item.plan_name}</option>
                                    ))}
                                  </select>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12}>
                          <StackedColumnChart periodData={admissionData} categories={categories} />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col lg={12}>
                      <div className="d-sm-flex flex-wrap">
                        <h4 className="card-title mb-4">Renewal Report</h4>
                          <div className="ms-auto">
                            <ul className="nav nav-pills">
                              <li className="nav-item">
                                <select
                                  className={classNames({ active: periodType === "yearly" }, "nav-link btn btn-outline-primary")}
                                  onChange={(e) => {
                                    filterRenewalDashboardByYears(e)
                                  }}
                                  >
                                  <option value="">Years</option>
                                  {staticYears.map((singleYear, index) => (
                                    <option value={singleYear} key={index}>{singleYear}</option>
                                  ))}
                                </select>
                              </li>
                              <li>&nbsp;&nbsp;</li>
                              <li className="nav-item">
                                <select
                                  className={classNames({ active: periodType === "activity" }, "nav-link btn btn-outline-primary")}
                                  onChange={(e) => {
                                    filterRenewalDashboardByActivity(e)
                                  }}
                                  >
                                  <option value="">Activity</option>
                                  {activityList.map((item) => (
                                    <option value={item.id} key={item.id}>{item.activity}</option>
                                  ))}
                                </select>
                              </li>
                              <li>&nbsp;&nbsp;</li>
                              <li className="nav-item">
                                <select
                                  className={classNames({ active: periodType === "subscription" }, "nav-link btn btn-outline-primary")}
                                  onChange={(e) => {
                                    filterRenewalDashboardBySubscriptionPlan(e)
                                  }}
                                  >
                                  <option value="">Subscription Plan</option>
                                  {allSubscriptionList.map((item) => (
                                    <option value={item.id} key={item.id}>{item.plan_name}</option>
                                  ))}
                                </select>
                              </li>
                              <li>&nbsp;&nbsp;</li>
                            </ul>
                          </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      {console.log(renewalData)}
                      <StackedColumnChart periodData={renewalData} categories={categories} />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* subscribe ModalHeader */}
      {/* <Modal
        isOpen={subscribemodal}
        role="dialog"
        autoFocus={true}
        centered
        data-toggle="modal"
        toggle={() => {
          setSubscribemodal(!subscribemodal);
        }}
      >
        <div>
          <ModalHeader
            className="border-bottom-0"
            toggle={() => {
              setSubscribemodal(!subscribemodal);
            }}
          ></ModalHeader>
        </div>
        <div className="modal-body">
          <div className="text-center mb-4">
            <div className="avatar-md mx-auto mb-4">
              <div className="avatar-title bg-light  rounded-circle text-primary h1">
                <i className="mdi mdi-email-open"></i>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-xl-10">
                <h4 className="text-primary">Subscribe !</h4>
                <p className="text-muted font-size-14 mb-4">
                  Subscribe our newletter and get notification to stay update.
                </p>

                <div
                  className="input-group rounded bg-light"
                >
                  <Input
                    type="email"
                    className="form-control bg-transparent border-0"
                    placeholder="Enter Email address"
                  />
                  <Button color="primary" type="button" id="button-addon2">
                    <i className="bx bxs-paper-plane"></i>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal> */}

      <Modal
        isOpen={modal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setmodal(!modal);
        }}
      >
        <div>
          <ModalHeader
            toggle={() => {
              setmodal(!modal);
            }}
          >
            Order Details
          </ModalHeader>
          <ModalBody>
            <p className="mb-2">
              Product id: <span className="text-primary">#SK2540</span>
            </p>
            <p className="mb-4">
              Billing Name: <span className="text-primary">Neal Matthews</span>
            </p>

            <div className="table-responsive">
              <Table className="table table-centered table-nowrap">
                <thead>
                  <tr>
                    <th scope="col">Product</th>
                    <th scope="col">Product Name</th>
                    <th scope="col">Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">
                      <div>
                        <img src={modalimage1} alt="" className="avatar-sm" />
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className="text-truncate font-size-14">
                          Wireless Headphone (Black)
                        </h5>
                        <p className="text-muted mb-0">$ 225 x 1</p>
                      </div>
                    </td>
                    <td>$ 255</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <div>
                        <img src={modalimage2} alt="" className="avatar-sm" />
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className="text-truncate font-size-14">
                          Hoodie (Blue)
                        </h5>
                        <p className="text-muted mb-0">$ 145 x 1</p>
                      </div>
                    </td>
                    <td>$ 145</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Sub Total:</h6>
                    </td>
                    <td>$ 400</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Shipping:</h6>
                    </td>
                    <td>Free</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Total:</h6>
                    </td>
                    <td>$ 400</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                setmodal(!modal);
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
