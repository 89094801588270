import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useEffect, useState } from "react";
import {
    Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label, Button, Modal,
    ModalBody,
    ModalHeader,
    InputGroup,
    CardTitle
} from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import Select from "react-select";
// Formik formikObj
import * as Yup from "yup";
import { FieldArray, useFormik, FormikProvider } from "formik";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { standartDateFormat } from "utils/date";
import { axiosInstance, apiUrls } from "api";
import swal from 'sweetalert';
import { graphql } from "api";

import CommonTable from "components/Common/CommonTable";
import usePaginator from "hooks/usePaginator";
import * as ExerciseQry from "GraphQLquery/exerciseQry";

const initialExerciseData = {
    exercise_categories: [],
    exercise_name: "",
    exercise_type_id: "",
    exercise_description: "",
    exercise_video: "",
    target_muscles: "",
}

const initialCategoryData = {
    category_items: [{
        id: "",
        category_name: ""
    }]
}

const initialCategoryDataAdd = {
    category_items: [{
        category_name: ""
    }]
}

const AddExercise = props => {
    const [exerciseData, setExerciseData] = useState({ ...initialExerciseData })
    const [categoryData, setCategoryData] = useState({ ...initialCategoryData })
    const [categoryDataAdd, setCategoryDataAdd] = useState({ ...initialCategoryDataAdd })
    const [exerciseId, setExerciseId] = useState(null)
    const [isSaving, setIsSaving] = useState(false);
    const [exerciseTypes, setExerciseTypes] = useState([])
    const [workoutPlanCategory, setWorkoutPlanCategory] = useState([])
    const [selectExerciseType, setSelectExerciseType] = useState(true)
    const [exerciseCategories, setExerciseCategories] = useState(true)
    const [error, setError] = useState(null);
    const [addEditCategoryModal, setAddEditCategoryModal] = useState(false);
    const [editId, setEditId] = useState('')
    const [addingNewCategory, setAddingNewCategory] = useState(false)
    const [updatingCategory, setUpdatingCategory] = useState(false)
    const [categoryDelete, setCategoryDelete] = useState(false)
    const [selectedOption, setSelectedOption] = useState(null);

    const [options, setOptions] = useState(null);


    useEffect(() => {
        fetchExerciseTypes();
        fetchWorkoutPlanCategory();
    }, [])

    const fetchExerciseTypes = async () => {
        try {
            let response = null;
            response = await graphql({
                query: ExerciseQry.EXERCISE_TYPES_QUERY,
                variables: {}
            });
            let exerciseTypes = []
            exerciseTypes = response?.data?.exercise_types || [];
            setExerciseTypes(exerciseTypes);
        } catch (err) {
            setExerciseTypes([]);
        }
    }

    const fetchWorkoutPlanCategory = async () => {
        const category_items = []
        try {
            let response = null;
            response = await graphql({
                query: ExerciseQry.WORKOUT_PLAN_CATEGORY_QUERY,
                variables: {}
            });
            let workoutPlanCategory = []
            workoutPlanCategory = response?.data?.workout_plan_categories?.data || [];
            setWorkoutPlanCategory(workoutPlanCategory);

            let options = [];

            for (let index = 0; index < workoutPlanCategory.length; index++) {
                category_items.push({
                    id: workoutPlanCategory[index].id,
                    category_name: workoutPlanCategory[index].category_name
                })
                options.push({ label: workoutPlanCategory[index].category_name, value: workoutPlanCategory[index].id })
            }
            setOptions(options)
            setCategoryData(prev => {
                return ({ ...prev, category_items: category_items })
            }
            )

        } catch (err) {
            setWorkoutPlanCategory([]);
        }
    }

    const formikObj = useFormik({
        enableReinitialize: true,

        initialValues: { ...exerciseData },
        validationSchema: Yup.object({
            exercise_categories: Yup.array().of(
                Yup.object().shape({
                    workout_plan_category_id: Yup.string().required("Please Select Workout Plan Category"),
                })
            ),
            exercise_name: Yup.string().required("Please Enter Exercise Name"),
            exercise_type_id: Yup.string().required("Please Select Exercise Type"),
        }),
        onSubmit: (values) => {
            if (values.exercise_type_id == '') {
                setSelectExerciseType(false)
            }
            else if (values.exercise_categories.length == 0) {
                setExerciseCategories(false)
            }
            else {
                setSelectExerciseType(true)
                setExerciseCategories(true)
                addExerciseData(values)
            }
        }
    });

    const addExerciseData = async (values) => {
        try {
            setIsSaving(true)
            const response = await axiosInstance.post(apiUrls.workoutPlan.addAndUpdateWorkoutPlanExercise, { ...values });
            if (response.success == 1) {
                formikObj.resetForm()
                setIsSaving(false)
                swal("Success!", response.message, "success");
                props.history.goBack()
            }
            else {
                setIsSaving(false)
                swal("Oops!", response.message, "error");
            }
        } catch (err) {
            setIsSaving(false)
            swal("Oops!", "Something went wrong!", "error");
            setError(err.message)
        }
    }

    const handleExerciseTypeChange = (e) => {
        formikObj.setFieldValue(e.target.name, e.target.value)
    }

    const formikCategoryObj = useFormik({
        enableReinitialize: true,
        initialValues: { ...categoryData },
        validationSchema: Yup.object({

        }),
        onSubmit: async (values) => {
        }
    })

    const formikCategoryObjAdd = useFormik({
        enableReinitialize: true,
        initialValues: { ...categoryDataAdd },
        validationSchema: Yup.object({

        }),
        onSubmit: async (values) => {
        }
    })

    const openModal = () => {
        setAddEditCategoryModal(true);
    }

    const closeModal = () => {
        setAddEditCategoryModal(false);
    }

    const createCategory = () => {
        setEditId('')
        setUpdatingCategory(false)
        setAddingNewCategory(false)
        setCategoryDelete(false)
        formikCategoryObj.resetForm();
        formikCategoryObjAdd.resetForm();
        openModal()
    }

    const getEditDeleteId = async (e, item, isDelete) => {
        setCategoryDelete(false)
        let editId = ''
        let res = formikCategoryObj.values.category_items.filter(data => data.id == item.id)
        editId = res[0].id
        setEditId(editId)
        if (isDelete == true) {
            setCategoryDelete(true)
            swal({
                title: "Are you sure?",
                text: "Once deleted, you will not be able to recover",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then(async (willDelete) => {
                    if (willDelete) {
                        try {
                            setIsSaving(true)
                            const response = await axiosInstance.post(apiUrls.workoutCategory.deleteWorkoutCategoryName, { id: item.id });
                            if (response.success == 1) {
                                setIsSaving(false)
                                closeModal()
                                fetchWorkoutPlanCategory();
                                swal("Success!", response.message, "success");
                            }
                            else {
                                setIsSaving(false)
                                swal("Oops!", response.message, "error");
                            }
                        } catch (err) {
                            setIsSaving(false)
                            swal("Oops!", "Something went wrong!", "error");
                            setError(err.message)
                        }
                    }
                });
        }
    }

    const addEditWorkCategory = async (editValues, addValues) => {
        let values
        if (addingNewCategory == true && updatingCategory == false) {
            values = addValues
        }
        if (addingNewCategory == false && updatingCategory == true) {
            values = editValues
        }
        if (addingNewCategory == true && updatingCategory == true) {
            addValues.category_items.forEach(element => {
                editValues.category_items.push(element)
            });
            values = editValues
        }
        // return
        try {
            setIsSaving(true)
            const response = await axiosInstance.post(apiUrls.workoutCategory.addAndUpdateWorkoutCategoryName, { ...values });
            if (response.success == 1) {
                setIsSaving(false)
                closeModal()
                fetchWorkoutPlanCategory();
                swal("Success!", response.message, "success");
            }
            else {
                setIsSaving(false)
                swal("Oops!", response.message, "error");
            }
        } catch (err) {
            setIsSaving(false)
            swal("Oops!", "Something went wrong!", "error");
            setError(err.message)
        }

    }

    const handleMultiselect = (selectedOption) => {
        setSelectedOption(selectedOption);
        let exercise_categories = []
        selectedOption.forEach(item => {
            exercise_categories.push({
                workout_plan_category_id: item.value
            })
        })
        formikObj.setFieldValue("exercise_categories", exercise_categories)
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Add Exercise</title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumbs title="Manage Exercise" breadcrumbItem="Add Exercise" />
                    <Card>
                        <CardBody>
                            {/* <CardTitle className="mb-4 text-primary">Add Exercise</CardTitle> */}
                            <Form>
                                <div className="row mb-4">
                                    <Label className="col-sm-3 col-form-label">
                                        Exercise Name <span className="text-danger">*</span>
                                    </Label>
                                    <Col sm={9}>
                                        <Input
                                            type="text"
                                            id="formrow-InputService"
                                            className="form-control"
                                            name="exercise_name"
                                            onChange={formikObj.handleChange}
                                            value={formikObj.values.exercise_name}
                                            placeholder="Enter Exercise Name"
                                            invalid={
                                                formikObj.touched.exercise_name && formikObj.errors.exercise_name ? true : false
                                            }
                                        />
                                        {formikObj.touched.exercise_name && formikObj.errors.exercise_name ? (
                                            <FormFeedback type="invalid">{formikObj.errors.exercise_name}</FormFeedback>
                                        ) : null}
                                    </Col>
                                </div>
                                <div className="row mb-4">
                                    <Label className="col-sm-3 col-form-label">
                                        Select Category <span className="text-danger">*</span>
                                    </Label>
                                    <Col sm={7} md={8}>
                                        <Select
                                            value={selectedOption}
                                            isMulti={true}
                                            onChange={(selectedOption) => {
                                                handleMultiselect(selectedOption);
                                            }}
                                            options={options}
                                            classNamePrefix="select2-selection"
                                        />

                                        {formikObj.touched.workout_plan_category_id && formikObj.errors.workout_plan_category_id ? (
                                            <FormFeedback type="invalid">{formikObj.errors.workout_plan_category_id}</FormFeedback>
                                        ) : null}
                                        {exerciseCategories == false && <span style={{ color: "red", fontSize: "10px" }}>Please Select Category</span>}
                                    </Col>
                                    <Col sm={2} md={1} className="d-flex d-sm-block justify-content-end justify-content-sm-start">
                                        <button
                                            type="button"
                                            className="btn-rounded  btn btn-primary mx-2 btn-sm mt-2 mt-sm-0"
                                            onClick={createCategory}
                                        >
                                            <i className="bx bxs-cog align-middle" />
                                        </button>
                                    </Col>
                                </div>
                                <div className="row mb-4">
                                    <Label className="col-sm-3 col-form-label">
                                        Select Exercise Type <span className="text-danger">*</span>
                                    </Label>
                                    <Col sm={9}>
                                        {exerciseTypes.map((item, index) => (
                                            <div key={index} className="form-check form-check-inline font-size-16">
                                                <Input
                                                    type="radio"
                                                    value={item.id}
                                                    id="customRadioInline1"
                                                    name="exercise_type_id"
                                                    className="form-check-input"
                                                    onClick={(e) => { handleExerciseTypeChange(e) }}
                                                    checked={item.id == formikObj.values.exercise_type_id ? true : false}
                                                />
                                                <Label
                                                    className="form-check-label font-size-13"
                                                    htmlFor="customRadioInline1"
                                                >
                                                    {item.exercise_type_name}
                                                </Label>
                                            </div>
                                        ))
                                        }
                                        {selectExerciseType == false && <span style={{ color: "red", fontSize: "10px" }}>Please Select Plan Type</span>}
                                    </Col>
                                </div>

                                <div className="row mb-4">
                                    <Label className="col-sm-3 col-form-label">
                                        Exercise Description
                                    </Label>
                                    <Col sm={9}>
                                        <Input
                                            type="textarea"
                                            id="formrow-InputService"
                                            className="form-control"
                                            name="exercise_description"
                                            onChange={formikObj.handleChange}
                                            value={formikObj.values.exercise_description}
                                            placeholder="Enter Exercise Description"
                                            invalid={
                                                formikObj.touched.exercise_description && formikObj.errors.exercise_description ? true : false
                                            }
                                        />
                                        {formikObj.touched.exercise_description && formikObj.errors.exercise_description ? (
                                            <FormFeedback type="invalid">{formikObj.errors.exercise_description}</FormFeedback>
                                        ) : null}
                                    </Col>
                                </div>

                                <div className="row mb-4">
                                    <Label className="col-sm-3 col-form-label">
                                        Exercise Video Link
                                    </Label>
                                    <Col sm={9}>
                                        <Input
                                            type="text"
                                            id="formrow-InputService"
                                            className="form-control"
                                            name="exercise_video"
                                            onChange={formikObj.handleChange}
                                            value={formikObj.values.exercise_video}
                                            placeholder="Enter Exercise Video Link"
                                            invalid={
                                                formikObj.touched.exercise_video && formikObj.errors.exercise_video ? true : false
                                            }
                                        />
                                        {formikObj.touched.exercise_video && formikObj.errors.exercise_video ? (
                                            <FormFeedback type="invalid">{formikObj.errors.exercise_video}</FormFeedback>
                                        ) : null}
                                    </Col>
                                </div>

                                <div className="row mb-4">
                                    <Label className="col-sm-3 col-form-label">
                                        Target Muscless
                                    </Label>
                                    <Col sm={9}>
                                        <Input
                                            type="text"
                                            id="formrow-InputService"
                                            className="form-control"
                                            name="target_muscles"
                                            onChange={formikObj.handleChange}
                                            value={formikObj.values.target_muscles}
                                            placeholder="Enter Target Muscless"
                                            invalid={
                                                formikObj.touched.target_muscles && formikObj.errors.target_muscles ? true : false
                                            }
                                        />
                                        {formikObj.touched.target_muscles && formikObj.errors.target_muscles ? (
                                            <FormFeedback type="invalid">{formikObj.errors.target_muscles}</FormFeedback>
                                        ) : null}
                                    </Col>
                                </div>




                                <div className="row justify-content-end">
                                    <Col sm={9}>
                                        {!!isSaving ?
                                            <div>
                                                <button className="btn btn-primary " disabled>
                                                    Processing
                                                </button>
                                            </div>
                                            :
                                            <div className="d-flex justify-content-end">
                                                <Button
                                                    type="submit"
                                                    color="primary"
                                                    className="w-md"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        formikObj.handleSubmit();
                                                        return false;
                                                    }}
                                                >
                                                    Submit
                                                </Button>
                                            </div>
                                        }


                                    </Col>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </Container>
                <Modal
                    isOpen={addEditCategoryModal}
                    toggle={closeModal}
                    size="lg"
                    backdrop="static"
                // className={props.className}
                >
                    <ModalHeader toggle={closeModal} tag="h4">
                        Manage Category
                    </ModalHeader>
                    <ModalBody>
                        <FormikProvider value={formikCategoryObj}>

                            {/* EDIT CATEGORY */}

                            <Form>
                                <FieldArray
                                    name="category_items"
                                    render={arrayHelpers => (
                                        <>
                                            {formikCategoryObj.values.category_items.map((item, index) => {
                                                const getFieldName = name => `${arrayHelpers.name}[${index}][${name}]`;
                                                return (
                                                    <Row key={index}>
                                                        <Col lg={10}>
                                                            <div className="mb-3">
                                                                <Input
                                                                    type="text"
                                                                    id={item.id}
                                                                    className="form-control"
                                                                    name={getFieldName('category_name')}
                                                                    onChange={(e) => { formikCategoryObj.handleChange(e); setUpdatingCategory(true); }}
                                                                    value={item.category_name}
                                                                    readOnly={categoryDelete ? true : editId == item.id ? false : true}
                                                                    invalid={
                                                                        formikCategoryObj.touched.category_name && formikCategoryObj.errors.category_name ? true : false
                                                                    }
                                                                >
                                                                </Input>
                                                            </div>
                                                        </Col>
                                                        <Col lg={2}>
                                                            <button
                                                                type="button"
                                                                className="btn-rounded  btn btn-primary mx-2 btn-sm"
                                                                onClick={(e) => getEditDeleteId(e, item, false)}
                                                            >
                                                                <i className="bx bx-pencil" />
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn-rounded  btn btn-danger mx-2 btn-sm"
                                                                onClick={(e) => getEditDeleteId(e, item, true)}
                                                            >
                                                                <i className="bx bx-trash" />
                                                            </button>
                                                        </Col>
                                                    </Row>
                                                )
                                            })}
                                        </>
                                    )}
                                />
                            </Form>
                        </FormikProvider>

                        {/* ADD NEW CATEGORY */}

                        <FormikProvider value={formikCategoryObjAdd}>
                            <Form>
                                <FieldArray
                                    name="category_items"
                                    render={arrayHelpersAdd => (
                                        <>
                                            {formikCategoryObjAdd.values.category_items.map((item, index) => {
                                                const isLastRow = (formikCategoryObjAdd.values?.category_items?.length - 1) === index;
                                                const getFieldName = name => `${arrayHelpersAdd.name}[${index}][${name}]`;
                                                return (
                                                    <Row key={index}>
                                                        <Col lg={10}>
                                                            <div className="mb-3">
                                                                <Input
                                                                    type="text"
                                                                    id={item.id}
                                                                    className="form-control"
                                                                    name={getFieldName('category_name')}
                                                                    onChange={(e) => { formikCategoryObjAdd.handleChange(e); setAddingNewCategory(true) }}
                                                                    value={item.category_name}
                                                                    placeholder="Enter Category Name"
                                                                    invalid={
                                                                        formikCategoryObjAdd.touched.category_name && formikCategoryObjAdd.errors.category_name ? true : false
                                                                    }
                                                                >
                                                                </Input>
                                                            </div>
                                                        </Col>
                                                        <Col lg={2} className="d-flex align-items-center">
                                                            <div className="">
                                                                {formikCategoryObjAdd.values?.category_items.length > 1 && <button
                                                                    type="button"
                                                                    className="btn-rounded  btn btn-danger mx-2 btn-sm"
                                                                    onClick={() => arrayHelpersAdd.remove(index)}
                                                                > <i className="bx bx-message-alt-minus" />
                                                                </button>
                                                                }
                                                            </div>
                                                            <div className="">
                                                                {/* ADD Button */}
                                                                {isLastRow && <button
                                                                    type="button"
                                                                    className="btn-rounded  btn btn-success mx-2 btn-sm"
                                                                    onClick={() => arrayHelpersAdd.push({
                                                                        category_name: ""
                                                                    })}>
                                                                    <i className="bx bx-plus-medical" />
                                                                </button>
                                                                }
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                )
                                            })}
                                        </>
                                    )}
                                />
                            </Form>
                        </FormikProvider>
                    </ModalBody>
                    <div className="modal-footer">
                        {!!isSaving ?
                            <button className="btn btn-primary " disabled>
                                Processing
                            </button>
                            :
                            <>
                                <button
                                    type="submit"
                                    className="btn btn-primary "
                                    onClick={(e) => {
                                        e.preventDefault();
                                        // formikCategoryObj.handleSubmit();
                                        // formikCategoryObjAdd.handleSubmit();
                                        addEditWorkCategory(formikCategoryObj.values, formikCategoryObjAdd.values)
                                        return false;
                                    }}
                                >
                                    SAVE
                                    {/* {subscriptionId == null ? "Save" : "Update"} */}
                                </button>
                            </>
                        }
                    </div>
                </Modal>
            </div>
        </React.Fragment>
    )
}

export default withRouter(AddExercise);

AddExercise.propTypes = {
    history: PropTypes.object,
};